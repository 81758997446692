/* tslint:disable */
/* eslint-disable */
/**
 * Jellyfin API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface RemoteSubtitleInfo
 */
export interface RemoteSubtitleInfo {
    /**
     * 
     * @type {string}
     * @memberof RemoteSubtitleInfo
     */
    ThreeLetterISOLanguageName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RemoteSubtitleInfo
     */
    Id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RemoteSubtitleInfo
     */
    ProviderName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RemoteSubtitleInfo
     */
    Name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RemoteSubtitleInfo
     */
    Format?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RemoteSubtitleInfo
     */
    Author?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RemoteSubtitleInfo
     */
    Comment?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RemoteSubtitleInfo
     */
    DateCreated?: string | null;
    /**
     * 
     * @type {number}
     * @memberof RemoteSubtitleInfo
     */
    CommunityRating?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RemoteSubtitleInfo
     */
    DownloadCount?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof RemoteSubtitleInfo
     */
    IsHashMatch?: boolean | null;
}


