/* tslint:disable */
/* eslint-disable */
/**
 * Jellyfin API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { SubtitleDeliveryMethod } from './subtitle-delivery-method';

/**
 * 
 * @export
 * @interface SubtitleProfile
 */
export interface SubtitleProfile {
    /**
     * 
     * @type {string}
     * @memberof SubtitleProfile
     */
    Format?: string | null;
    /**
     * 
     * @type {SubtitleDeliveryMethod}
     * @memberof SubtitleProfile
     */
    Method?: SubtitleDeliveryMethod;
    /**
     * 
     * @type {string}
     * @memberof SubtitleProfile
     */
    DidlMode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SubtitleProfile
     */
    Language?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SubtitleProfile
     */
    Container?: string | null;
}


