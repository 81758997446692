/* tslint:disable */
/* eslint-disable */
/**
 * Jellyfin API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * Enum MediaStreamType.
 * @export
 * @enum {string}
 */
export enum MediaStreamType {
    Audio = 'Audio',
    Video = 'Video',
    Subtitle = 'Subtitle',
    EmbeddedImage = 'EmbeddedImage'
}



