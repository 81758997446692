/* tslint:disable */
/* eslint-disable */
/**
 * Jellyfin API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { BaseItemDto } from '../models';
// @ts-ignore
import { BaseItemDtoQueryResult } from '../models';
// @ts-ignore
import { ImageType } from '../models';
// @ts-ignore
import { UserItemDataDto } from '../models';
/**
 * UserLibraryApi - axios parameter creator
 * @export
 */
export const UserLibraryApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Deletes a user\'s saved personal rating for an item.
         * @param {string} userId User id.
         * @param {string} itemId Item id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserItemRating: async (userId: string, itemId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId','Required parameter userId was null or undefined when calling deleteUserItemRating.');
            }
            // verify required parameter 'itemId' is not null or undefined
            if (itemId === null || itemId === undefined) {
                throw new RequiredError('itemId','Required parameter itemId was null or undefined when calling deleteUserItemRating.');
            }
            const localVarPath = `/Users/{userId}/Items/{itemId}/Rating`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"itemId"}}`, encodeURIComponent(String(itemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets intros to play before the main media item plays.
         * @param {string} userId User id.
         * @param {string} itemId Item id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIntros: async (userId: string, itemId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId','Required parameter userId was null or undefined when calling getIntros.');
            }
            // verify required parameter 'itemId' is not null or undefined
            if (itemId === null || itemId === undefined) {
                throw new RequiredError('itemId','Required parameter itemId was null or undefined when calling getIntros.');
            }
            const localVarPath = `/Users/{userId}/Items/{itemId}/Intros`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"itemId"}}`, encodeURIComponent(String(itemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets an item from a user\'s library.
         * @param {string} userId User id.
         * @param {string} itemId Item id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getItem: async (userId: string, itemId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId','Required parameter userId was null or undefined when calling getItem.');
            }
            // verify required parameter 'itemId' is not null or undefined
            if (itemId === null || itemId === undefined) {
                throw new RequiredError('itemId','Required parameter itemId was null or undefined when calling getItem.');
            }
            const localVarPath = `/Users/{userId}/Items/{itemId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"itemId"}}`, encodeURIComponent(String(itemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets latest media.
         * @param {string} userId User id.
         * @param {string} [parentId] Specify this to localize the search to a specific item or folder. Omit to use the root.
         * @param {string} [fields] Optional. Specify additional fields of information to return in the output. This allows multiple, comma delimeted. Options: Chapters, DateCreated, Genres, HomePageUrl, IndexOptions, MediaStreams, Overview, ParentId, Path, People, ProviderIds, PrimaryImageAspectRatio, SortName, Studios, Taglines.
         * @param {string} [includeItemTypes] Optional. If specified, results will be filtered based on item type. This allows multiple, comma delimeted.
         * @param {boolean} [isPlayed] Filter by items that are played, or not.
         * @param {boolean} [enableImages] Optional. include image information in output.
         * @param {number} [imageTypeLimit] Optional. the max number of images to return, per image type.
         * @param {Array<ImageType>} [enableImageTypes] Optional. The image types to include in the output.
         * @param {boolean} [enableUserData] Optional. include user data.
         * @param {number} [limit] Return item limit.
         * @param {boolean} [groupItems] Whether or not to group items into a parent container.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLatestMedia: async (userId: string, parentId?: string, fields?: string, includeItemTypes?: string, isPlayed?: boolean, enableImages?: boolean, imageTypeLimit?: number, enableImageTypes?: Array<ImageType>, enableUserData?: boolean, limit?: number, groupItems?: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId','Required parameter userId was null or undefined when calling getLatestMedia.');
            }
            const localVarPath = `/Users/{userId}/Items/Latest`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }

            if (parentId !== undefined) {
                localVarQueryParameter['parentId'] = parentId;
            }

            if (fields !== undefined) {
                localVarQueryParameter['fields'] = fields;
            }

            if (includeItemTypes !== undefined) {
                localVarQueryParameter['includeItemTypes'] = includeItemTypes;
            }

            if (isPlayed !== undefined) {
                localVarQueryParameter['isPlayed'] = isPlayed;
            }

            if (enableImages !== undefined) {
                localVarQueryParameter['enableImages'] = enableImages;
            }

            if (imageTypeLimit !== undefined) {
                localVarQueryParameter['imageTypeLimit'] = imageTypeLimit;
            }

            if (enableImageTypes) {
                localVarQueryParameter['enableImageTypes'] = enableImageTypes;
            }

            if (enableUserData !== undefined) {
                localVarQueryParameter['enableUserData'] = enableUserData;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (groupItems !== undefined) {
                localVarQueryParameter['groupItems'] = groupItems;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets local trailers for an item.
         * @param {string} userId User id.
         * @param {string} itemId Item id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLocalTrailers: async (userId: string, itemId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId','Required parameter userId was null or undefined when calling getLocalTrailers.');
            }
            // verify required parameter 'itemId' is not null or undefined
            if (itemId === null || itemId === undefined) {
                throw new RequiredError('itemId','Required parameter itemId was null or undefined when calling getLocalTrailers.');
            }
            const localVarPath = `/Users/{userId}/Items/{itemId}/LocalTrailers`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"itemId"}}`, encodeURIComponent(String(itemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets the root folder from a user\'s library.
         * @param {string} userId User id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRootFolder: async (userId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId','Required parameter userId was null or undefined when calling getRootFolder.');
            }
            const localVarPath = `/Users/{userId}/Items/Root`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets special features for an item.
         * @param {string} userId User id.
         * @param {string} itemId Item id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSpecialFeatures: async (userId: string, itemId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId','Required parameter userId was null or undefined when calling getSpecialFeatures.');
            }
            // verify required parameter 'itemId' is not null or undefined
            if (itemId === null || itemId === undefined) {
                throw new RequiredError('itemId','Required parameter itemId was null or undefined when calling getSpecialFeatures.');
            }
            const localVarPath = `/Users/{userId}/Items/{itemId}/SpecialFeatures`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"itemId"}}`, encodeURIComponent(String(itemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Marks an item as a favorite.
         * @param {string} userId User id.
         * @param {string} itemId Item id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markFavoriteItem: async (userId: string, itemId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId','Required parameter userId was null or undefined when calling markFavoriteItem.');
            }
            // verify required parameter 'itemId' is not null or undefined
            if (itemId === null || itemId === undefined) {
                throw new RequiredError('itemId','Required parameter itemId was null or undefined when calling markFavoriteItem.');
            }
            const localVarPath = `/Users/{userId}/FavoriteItems/{itemId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"itemId"}}`, encodeURIComponent(String(itemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Unmarks item as a favorite.
         * @param {string} userId User id.
         * @param {string} itemId Item id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unmarkFavoriteItem: async (userId: string, itemId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId','Required parameter userId was null or undefined when calling unmarkFavoriteItem.');
            }
            // verify required parameter 'itemId' is not null or undefined
            if (itemId === null || itemId === undefined) {
                throw new RequiredError('itemId','Required parameter itemId was null or undefined when calling unmarkFavoriteItem.');
            }
            const localVarPath = `/Users/{userId}/FavoriteItems/{itemId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"itemId"}}`, encodeURIComponent(String(itemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates a user\'s rating for an item.
         * @param {string} userId User id.
         * @param {string} itemId Item id.
         * @param {boolean} [likes] Whether this M:Jellyfin.Api.Controllers.UserLibraryController.UpdateUserItemRating(System.Guid,System.Guid,System.Nullable{System.Boolean}) is likes.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserItemRating: async (userId: string, itemId: string, likes?: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId','Required parameter userId was null or undefined when calling updateUserItemRating.');
            }
            // verify required parameter 'itemId' is not null or undefined
            if (itemId === null || itemId === undefined) {
                throw new RequiredError('itemId','Required parameter itemId was null or undefined when calling updateUserItemRating.');
            }
            const localVarPath = `/Users/{userId}/Items/{itemId}/Rating`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"itemId"}}`, encodeURIComponent(String(itemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }

            if (likes !== undefined) {
                localVarQueryParameter['likes'] = likes;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserLibraryApi - functional programming interface
 * @export
 */
export const UserLibraryApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Deletes a user\'s saved personal rating for an item.
         * @param {string} userId User id.
         * @param {string} itemId Item id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteUserItemRating(userId: string, itemId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserItemDataDto>> {
            const localVarAxiosArgs = await UserLibraryApiAxiosParamCreator(configuration).deleteUserItemRating(userId, itemId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Gets intros to play before the main media item plays.
         * @param {string} userId User id.
         * @param {string} itemId Item id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getIntros(userId: string, itemId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BaseItemDtoQueryResult>> {
            const localVarAxiosArgs = await UserLibraryApiAxiosParamCreator(configuration).getIntros(userId, itemId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Gets an item from a user\'s library.
         * @param {string} userId User id.
         * @param {string} itemId Item id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getItem(userId: string, itemId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BaseItemDto>> {
            const localVarAxiosArgs = await UserLibraryApiAxiosParamCreator(configuration).getItem(userId, itemId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Gets latest media.
         * @param {string} userId User id.
         * @param {string} [parentId] Specify this to localize the search to a specific item or folder. Omit to use the root.
         * @param {string} [fields] Optional. Specify additional fields of information to return in the output. This allows multiple, comma delimeted. Options: Chapters, DateCreated, Genres, HomePageUrl, IndexOptions, MediaStreams, Overview, ParentId, Path, People, ProviderIds, PrimaryImageAspectRatio, SortName, Studios, Taglines.
         * @param {string} [includeItemTypes] Optional. If specified, results will be filtered based on item type. This allows multiple, comma delimeted.
         * @param {boolean} [isPlayed] Filter by items that are played, or not.
         * @param {boolean} [enableImages] Optional. include image information in output.
         * @param {number} [imageTypeLimit] Optional. the max number of images to return, per image type.
         * @param {Array<ImageType>} [enableImageTypes] Optional. The image types to include in the output.
         * @param {boolean} [enableUserData] Optional. include user data.
         * @param {number} [limit] Return item limit.
         * @param {boolean} [groupItems] Whether or not to group items into a parent container.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLatestMedia(userId: string, parentId?: string, fields?: string, includeItemTypes?: string, isPlayed?: boolean, enableImages?: boolean, imageTypeLimit?: number, enableImageTypes?: Array<ImageType>, enableUserData?: boolean, limit?: number, groupItems?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BaseItemDto>>> {
            const localVarAxiosArgs = await UserLibraryApiAxiosParamCreator(configuration).getLatestMedia(userId, parentId, fields, includeItemTypes, isPlayed, enableImages, imageTypeLimit, enableImageTypes, enableUserData, limit, groupItems, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Gets local trailers for an item.
         * @param {string} userId User id.
         * @param {string} itemId Item id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLocalTrailers(userId: string, itemId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BaseItemDto>>> {
            const localVarAxiosArgs = await UserLibraryApiAxiosParamCreator(configuration).getLocalTrailers(userId, itemId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Gets the root folder from a user\'s library.
         * @param {string} userId User id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRootFolder(userId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BaseItemDto>> {
            const localVarAxiosArgs = await UserLibraryApiAxiosParamCreator(configuration).getRootFolder(userId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Gets special features for an item.
         * @param {string} userId User id.
         * @param {string} itemId Item id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSpecialFeatures(userId: string, itemId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BaseItemDto>>> {
            const localVarAxiosArgs = await UserLibraryApiAxiosParamCreator(configuration).getSpecialFeatures(userId, itemId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Marks an item as a favorite.
         * @param {string} userId User id.
         * @param {string} itemId Item id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async markFavoriteItem(userId: string, itemId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserItemDataDto>> {
            const localVarAxiosArgs = await UserLibraryApiAxiosParamCreator(configuration).markFavoriteItem(userId, itemId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Unmarks item as a favorite.
         * @param {string} userId User id.
         * @param {string} itemId Item id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async unmarkFavoriteItem(userId: string, itemId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserItemDataDto>> {
            const localVarAxiosArgs = await UserLibraryApiAxiosParamCreator(configuration).unmarkFavoriteItem(userId, itemId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Updates a user\'s rating for an item.
         * @param {string} userId User id.
         * @param {string} itemId Item id.
         * @param {boolean} [likes] Whether this M:Jellyfin.Api.Controllers.UserLibraryController.UpdateUserItemRating(System.Guid,System.Guid,System.Nullable{System.Boolean}) is likes.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUserItemRating(userId: string, itemId: string, likes?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserItemDataDto>> {
            const localVarAxiosArgs = await UserLibraryApiAxiosParamCreator(configuration).updateUserItemRating(userId, itemId, likes, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * UserLibraryApi - factory interface
 * @export
 */
export const UserLibraryApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Deletes a user\'s saved personal rating for an item.
         * @param {string} userId User id.
         * @param {string} itemId Item id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserItemRating(userId: string, itemId: string, options?: any): AxiosPromise<UserItemDataDto> {
            return UserLibraryApiFp(configuration).deleteUserItemRating(userId, itemId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets intros to play before the main media item plays.
         * @param {string} userId User id.
         * @param {string} itemId Item id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIntros(userId: string, itemId: string, options?: any): AxiosPromise<BaseItemDtoQueryResult> {
            return UserLibraryApiFp(configuration).getIntros(userId, itemId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets an item from a user\'s library.
         * @param {string} userId User id.
         * @param {string} itemId Item id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getItem(userId: string, itemId: string, options?: any): AxiosPromise<BaseItemDto> {
            return UserLibraryApiFp(configuration).getItem(userId, itemId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets latest media.
         * @param {string} userId User id.
         * @param {string} [parentId] Specify this to localize the search to a specific item or folder. Omit to use the root.
         * @param {string} [fields] Optional. Specify additional fields of information to return in the output. This allows multiple, comma delimeted. Options: Chapters, DateCreated, Genres, HomePageUrl, IndexOptions, MediaStreams, Overview, ParentId, Path, People, ProviderIds, PrimaryImageAspectRatio, SortName, Studios, Taglines.
         * @param {string} [includeItemTypes] Optional. If specified, results will be filtered based on item type. This allows multiple, comma delimeted.
         * @param {boolean} [isPlayed] Filter by items that are played, or not.
         * @param {boolean} [enableImages] Optional. include image information in output.
         * @param {number} [imageTypeLimit] Optional. the max number of images to return, per image type.
         * @param {Array<ImageType>} [enableImageTypes] Optional. The image types to include in the output.
         * @param {boolean} [enableUserData] Optional. include user data.
         * @param {number} [limit] Return item limit.
         * @param {boolean} [groupItems] Whether or not to group items into a parent container.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLatestMedia(userId: string, parentId?: string, fields?: string, includeItemTypes?: string, isPlayed?: boolean, enableImages?: boolean, imageTypeLimit?: number, enableImageTypes?: Array<ImageType>, enableUserData?: boolean, limit?: number, groupItems?: boolean, options?: any): AxiosPromise<Array<BaseItemDto>> {
            return UserLibraryApiFp(configuration).getLatestMedia(userId, parentId, fields, includeItemTypes, isPlayed, enableImages, imageTypeLimit, enableImageTypes, enableUserData, limit, groupItems, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets local trailers for an item.
         * @param {string} userId User id.
         * @param {string} itemId Item id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLocalTrailers(userId: string, itemId: string, options?: any): AxiosPromise<Array<BaseItemDto>> {
            return UserLibraryApiFp(configuration).getLocalTrailers(userId, itemId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets the root folder from a user\'s library.
         * @param {string} userId User id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRootFolder(userId: string, options?: any): AxiosPromise<BaseItemDto> {
            return UserLibraryApiFp(configuration).getRootFolder(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets special features for an item.
         * @param {string} userId User id.
         * @param {string} itemId Item id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSpecialFeatures(userId: string, itemId: string, options?: any): AxiosPromise<Array<BaseItemDto>> {
            return UserLibraryApiFp(configuration).getSpecialFeatures(userId, itemId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Marks an item as a favorite.
         * @param {string} userId User id.
         * @param {string} itemId Item id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markFavoriteItem(userId: string, itemId: string, options?: any): AxiosPromise<UserItemDataDto> {
            return UserLibraryApiFp(configuration).markFavoriteItem(userId, itemId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Unmarks item as a favorite.
         * @param {string} userId User id.
         * @param {string} itemId Item id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unmarkFavoriteItem(userId: string, itemId: string, options?: any): AxiosPromise<UserItemDataDto> {
            return UserLibraryApiFp(configuration).unmarkFavoriteItem(userId, itemId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates a user\'s rating for an item.
         * @param {string} userId User id.
         * @param {string} itemId Item id.
         * @param {boolean} [likes] Whether this M:Jellyfin.Api.Controllers.UserLibraryController.UpdateUserItemRating(System.Guid,System.Guid,System.Nullable{System.Boolean}) is likes.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserItemRating(userId: string, itemId: string, likes?: boolean, options?: any): AxiosPromise<UserItemDataDto> {
            return UserLibraryApiFp(configuration).updateUserItemRating(userId, itemId, likes, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for deleteUserItemRating operation in UserLibraryApi.
 * @export
 * @interface UserLibraryApiDeleteUserItemRatingRequest
 */
export interface UserLibraryApiDeleteUserItemRatingRequest {
    /**
     * User id.
     * @type {string}
     * @memberof UserLibraryApiDeleteUserItemRating
     */
    readonly userId: string

    /**
     * Item id.
     * @type {string}
     * @memberof UserLibraryApiDeleteUserItemRating
     */
    readonly itemId: string
}

/**
 * Request parameters for getIntros operation in UserLibraryApi.
 * @export
 * @interface UserLibraryApiGetIntrosRequest
 */
export interface UserLibraryApiGetIntrosRequest {
    /**
     * User id.
     * @type {string}
     * @memberof UserLibraryApiGetIntros
     */
    readonly userId: string

    /**
     * Item id.
     * @type {string}
     * @memberof UserLibraryApiGetIntros
     */
    readonly itemId: string
}

/**
 * Request parameters for getItem operation in UserLibraryApi.
 * @export
 * @interface UserLibraryApiGetItemRequest
 */
export interface UserLibraryApiGetItemRequest {
    /**
     * User id.
     * @type {string}
     * @memberof UserLibraryApiGetItem
     */
    readonly userId: string

    /**
     * Item id.
     * @type {string}
     * @memberof UserLibraryApiGetItem
     */
    readonly itemId: string
}

/**
 * Request parameters for getLatestMedia operation in UserLibraryApi.
 * @export
 * @interface UserLibraryApiGetLatestMediaRequest
 */
export interface UserLibraryApiGetLatestMediaRequest {
    /**
     * User id.
     * @type {string}
     * @memberof UserLibraryApiGetLatestMedia
     */
    readonly userId: string

    /**
     * Specify this to localize the search to a specific item or folder. Omit to use the root.
     * @type {string}
     * @memberof UserLibraryApiGetLatestMedia
     */
    readonly parentId?: string

    /**
     * Optional. Specify additional fields of information to return in the output. This allows multiple, comma delimeted. Options: Chapters, DateCreated, Genres, HomePageUrl, IndexOptions, MediaStreams, Overview, ParentId, Path, People, ProviderIds, PrimaryImageAspectRatio, SortName, Studios, Taglines.
     * @type {string}
     * @memberof UserLibraryApiGetLatestMedia
     */
    readonly fields?: string

    /**
     * Optional. If specified, results will be filtered based on item type. This allows multiple, comma delimeted.
     * @type {string}
     * @memberof UserLibraryApiGetLatestMedia
     */
    readonly includeItemTypes?: string

    /**
     * Filter by items that are played, or not.
     * @type {boolean}
     * @memberof UserLibraryApiGetLatestMedia
     */
    readonly isPlayed?: boolean

    /**
     * Optional. include image information in output.
     * @type {boolean}
     * @memberof UserLibraryApiGetLatestMedia
     */
    readonly enableImages?: boolean

    /**
     * Optional. the max number of images to return, per image type.
     * @type {number}
     * @memberof UserLibraryApiGetLatestMedia
     */
    readonly imageTypeLimit?: number

    /**
     * Optional. The image types to include in the output.
     * @type {Array<ImageType>}
     * @memberof UserLibraryApiGetLatestMedia
     */
    readonly enableImageTypes?: Array<ImageType>

    /**
     * Optional. include user data.
     * @type {boolean}
     * @memberof UserLibraryApiGetLatestMedia
     */
    readonly enableUserData?: boolean

    /**
     * Return item limit.
     * @type {number}
     * @memberof UserLibraryApiGetLatestMedia
     */
    readonly limit?: number

    /**
     * Whether or not to group items into a parent container.
     * @type {boolean}
     * @memberof UserLibraryApiGetLatestMedia
     */
    readonly groupItems?: boolean
}

/**
 * Request parameters for getLocalTrailers operation in UserLibraryApi.
 * @export
 * @interface UserLibraryApiGetLocalTrailersRequest
 */
export interface UserLibraryApiGetLocalTrailersRequest {
    /**
     * User id.
     * @type {string}
     * @memberof UserLibraryApiGetLocalTrailers
     */
    readonly userId: string

    /**
     * Item id.
     * @type {string}
     * @memberof UserLibraryApiGetLocalTrailers
     */
    readonly itemId: string
}

/**
 * Request parameters for getRootFolder operation in UserLibraryApi.
 * @export
 * @interface UserLibraryApiGetRootFolderRequest
 */
export interface UserLibraryApiGetRootFolderRequest {
    /**
     * User id.
     * @type {string}
     * @memberof UserLibraryApiGetRootFolder
     */
    readonly userId: string
}

/**
 * Request parameters for getSpecialFeatures operation in UserLibraryApi.
 * @export
 * @interface UserLibraryApiGetSpecialFeaturesRequest
 */
export interface UserLibraryApiGetSpecialFeaturesRequest {
    /**
     * User id.
     * @type {string}
     * @memberof UserLibraryApiGetSpecialFeatures
     */
    readonly userId: string

    /**
     * Item id.
     * @type {string}
     * @memberof UserLibraryApiGetSpecialFeatures
     */
    readonly itemId: string
}

/**
 * Request parameters for markFavoriteItem operation in UserLibraryApi.
 * @export
 * @interface UserLibraryApiMarkFavoriteItemRequest
 */
export interface UserLibraryApiMarkFavoriteItemRequest {
    /**
     * User id.
     * @type {string}
     * @memberof UserLibraryApiMarkFavoriteItem
     */
    readonly userId: string

    /**
     * Item id.
     * @type {string}
     * @memberof UserLibraryApiMarkFavoriteItem
     */
    readonly itemId: string
}

/**
 * Request parameters for unmarkFavoriteItem operation in UserLibraryApi.
 * @export
 * @interface UserLibraryApiUnmarkFavoriteItemRequest
 */
export interface UserLibraryApiUnmarkFavoriteItemRequest {
    /**
     * User id.
     * @type {string}
     * @memberof UserLibraryApiUnmarkFavoriteItem
     */
    readonly userId: string

    /**
     * Item id.
     * @type {string}
     * @memberof UserLibraryApiUnmarkFavoriteItem
     */
    readonly itemId: string
}

/**
 * Request parameters for updateUserItemRating operation in UserLibraryApi.
 * @export
 * @interface UserLibraryApiUpdateUserItemRatingRequest
 */
export interface UserLibraryApiUpdateUserItemRatingRequest {
    /**
     * User id.
     * @type {string}
     * @memberof UserLibraryApiUpdateUserItemRating
     */
    readonly userId: string

    /**
     * Item id.
     * @type {string}
     * @memberof UserLibraryApiUpdateUserItemRating
     */
    readonly itemId: string

    /**
     * Whether this M:Jellyfin.Api.Controllers.UserLibraryController.UpdateUserItemRating(System.Guid,System.Guid,System.Nullable{System.Boolean}) is likes.
     * @type {boolean}
     * @memberof UserLibraryApiUpdateUserItemRating
     */
    readonly likes?: boolean
}

/**
 * UserLibraryApi - object-oriented interface
 * @export
 * @class UserLibraryApi
 * @extends {BaseAPI}
 */
export class UserLibraryApi extends BaseAPI {
    /**
     * 
     * @summary Deletes a user\'s saved personal rating for an item.
     * @param {UserLibraryApiDeleteUserItemRatingRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserLibraryApi
     */
    public deleteUserItemRating(requestParameters: UserLibraryApiDeleteUserItemRatingRequest, options?: any) {
        return UserLibraryApiFp(this.configuration).deleteUserItemRating(requestParameters.userId, requestParameters.itemId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets intros to play before the main media item plays.
     * @param {UserLibraryApiGetIntrosRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserLibraryApi
     */
    public getIntros(requestParameters: UserLibraryApiGetIntrosRequest, options?: any) {
        return UserLibraryApiFp(this.configuration).getIntros(requestParameters.userId, requestParameters.itemId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets an item from a user\'s library.
     * @param {UserLibraryApiGetItemRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserLibraryApi
     */
    public getItem(requestParameters: UserLibraryApiGetItemRequest, options?: any) {
        return UserLibraryApiFp(this.configuration).getItem(requestParameters.userId, requestParameters.itemId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets latest media.
     * @param {UserLibraryApiGetLatestMediaRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserLibraryApi
     */
    public getLatestMedia(requestParameters: UserLibraryApiGetLatestMediaRequest, options?: any) {
        return UserLibraryApiFp(this.configuration).getLatestMedia(requestParameters.userId, requestParameters.parentId, requestParameters.fields, requestParameters.includeItemTypes, requestParameters.isPlayed, requestParameters.enableImages, requestParameters.imageTypeLimit, requestParameters.enableImageTypes, requestParameters.enableUserData, requestParameters.limit, requestParameters.groupItems, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets local trailers for an item.
     * @param {UserLibraryApiGetLocalTrailersRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserLibraryApi
     */
    public getLocalTrailers(requestParameters: UserLibraryApiGetLocalTrailersRequest, options?: any) {
        return UserLibraryApiFp(this.configuration).getLocalTrailers(requestParameters.userId, requestParameters.itemId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets the root folder from a user\'s library.
     * @param {UserLibraryApiGetRootFolderRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserLibraryApi
     */
    public getRootFolder(requestParameters: UserLibraryApiGetRootFolderRequest, options?: any) {
        return UserLibraryApiFp(this.configuration).getRootFolder(requestParameters.userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets special features for an item.
     * @param {UserLibraryApiGetSpecialFeaturesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserLibraryApi
     */
    public getSpecialFeatures(requestParameters: UserLibraryApiGetSpecialFeaturesRequest, options?: any) {
        return UserLibraryApiFp(this.configuration).getSpecialFeatures(requestParameters.userId, requestParameters.itemId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Marks an item as a favorite.
     * @param {UserLibraryApiMarkFavoriteItemRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserLibraryApi
     */
    public markFavoriteItem(requestParameters: UserLibraryApiMarkFavoriteItemRequest, options?: any) {
        return UserLibraryApiFp(this.configuration).markFavoriteItem(requestParameters.userId, requestParameters.itemId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Unmarks item as a favorite.
     * @param {UserLibraryApiUnmarkFavoriteItemRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserLibraryApi
     */
    public unmarkFavoriteItem(requestParameters: UserLibraryApiUnmarkFavoriteItemRequest, options?: any) {
        return UserLibraryApiFp(this.configuration).unmarkFavoriteItem(requestParameters.userId, requestParameters.itemId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates a user\'s rating for an item.
     * @param {UserLibraryApiUpdateUserItemRatingRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserLibraryApi
     */
    public updateUserItemRating(requestParameters: UserLibraryApiUpdateUserItemRatingRequest, options?: any) {
        return UserLibraryApiFp(this.configuration).updateUserItemRating(requestParameters.userId, requestParameters.itemId, requestParameters.likes, options).then((request) => request(this.axios, this.basePath));
    }
}
