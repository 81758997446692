/* tslint:disable */
/* eslint-disable */
/**
 * Jellyfin API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * The authenticate user by name request body.
 * @export
 * @interface AuthenticateUserByName
 */
export interface AuthenticateUserByName {
    /**
     * Gets or sets the username.
     * @type {string}
     * @memberof AuthenticateUserByName
     */
    Username?: string | null;
    /**
     * Gets or sets the plain text password.
     * @type {string}
     * @memberof AuthenticateUserByName
     */
    Pw?: string | null;
    /**
     * Gets or sets the sha1-hashed password.
     * @type {string}
     * @memberof AuthenticateUserByName
     */
    Password?: string | null;
}


