/* tslint:disable */
/* eslint-disable */
/**
 * Jellyfin API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { BaseItemDtoQueryResult } from '../models';
/**
 * SuggestionsApi - axios parameter creator
 * @export
 */
export const SuggestionsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Gets suggestions.
         * @param {string} userId The user id.
         * @param {string} [mediaType] The media types.
         * @param {string} [type] The type.
         * @param {number} [startIndex] Optional. The start index.
         * @param {number} [limit] Optional. The limit.
         * @param {boolean} [enableTotalRecordCount] Whether to enable the total record count.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSuggestions: async (userId: string, mediaType?: string, type?: string, startIndex?: number, limit?: number, enableTotalRecordCount?: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId','Required parameter userId was null or undefined when calling getSuggestions.');
            }
            const localVarPath = `/Users/{userId}/Suggestions`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }

            if (mediaType !== undefined) {
                localVarQueryParameter['mediaType'] = mediaType;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (startIndex !== undefined) {
                localVarQueryParameter['startIndex'] = startIndex;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (enableTotalRecordCount !== undefined) {
                localVarQueryParameter['enableTotalRecordCount'] = enableTotalRecordCount;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SuggestionsApi - functional programming interface
 * @export
 */
export const SuggestionsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Gets suggestions.
         * @param {string} userId The user id.
         * @param {string} [mediaType] The media types.
         * @param {string} [type] The type.
         * @param {number} [startIndex] Optional. The start index.
         * @param {number} [limit] Optional. The limit.
         * @param {boolean} [enableTotalRecordCount] Whether to enable the total record count.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSuggestions(userId: string, mediaType?: string, type?: string, startIndex?: number, limit?: number, enableTotalRecordCount?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BaseItemDtoQueryResult>> {
            const localVarAxiosArgs = await SuggestionsApiAxiosParamCreator(configuration).getSuggestions(userId, mediaType, type, startIndex, limit, enableTotalRecordCount, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * SuggestionsApi - factory interface
 * @export
 */
export const SuggestionsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Gets suggestions.
         * @param {string} userId The user id.
         * @param {string} [mediaType] The media types.
         * @param {string} [type] The type.
         * @param {number} [startIndex] Optional. The start index.
         * @param {number} [limit] Optional. The limit.
         * @param {boolean} [enableTotalRecordCount] Whether to enable the total record count.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSuggestions(userId: string, mediaType?: string, type?: string, startIndex?: number, limit?: number, enableTotalRecordCount?: boolean, options?: any): AxiosPromise<BaseItemDtoQueryResult> {
            return SuggestionsApiFp(configuration).getSuggestions(userId, mediaType, type, startIndex, limit, enableTotalRecordCount, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getSuggestions operation in SuggestionsApi.
 * @export
 * @interface SuggestionsApiGetSuggestionsRequest
 */
export interface SuggestionsApiGetSuggestionsRequest {
    /**
     * The user id.
     * @type {string}
     * @memberof SuggestionsApiGetSuggestions
     */
    readonly userId: string

    /**
     * The media types.
     * @type {string}
     * @memberof SuggestionsApiGetSuggestions
     */
    readonly mediaType?: string

    /**
     * The type.
     * @type {string}
     * @memberof SuggestionsApiGetSuggestions
     */
    readonly type?: string

    /**
     * Optional. The start index.
     * @type {number}
     * @memberof SuggestionsApiGetSuggestions
     */
    readonly startIndex?: number

    /**
     * Optional. The limit.
     * @type {number}
     * @memberof SuggestionsApiGetSuggestions
     */
    readonly limit?: number

    /**
     * Whether to enable the total record count.
     * @type {boolean}
     * @memberof SuggestionsApiGetSuggestions
     */
    readonly enableTotalRecordCount?: boolean
}

/**
 * SuggestionsApi - object-oriented interface
 * @export
 * @class SuggestionsApi
 * @extends {BaseAPI}
 */
export class SuggestionsApi extends BaseAPI {
    /**
     * 
     * @summary Gets suggestions.
     * @param {SuggestionsApiGetSuggestionsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SuggestionsApi
     */
    public getSuggestions(requestParameters: SuggestionsApiGetSuggestionsRequest, options?: any) {
        return SuggestionsApiFp(this.configuration).getSuggestions(requestParameters.userId, requestParameters.mediaType, requestParameters.type, requestParameters.startIndex, requestParameters.limit, requestParameters.enableTotalRecordCount, options).then((request) => request(this.axios, this.basePath));
    }
}
