/* tslint:disable */
/* eslint-disable */
/**
 * Jellyfin API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { NotificationDto } from './notification-dto';

/**
 * A list of notifications with the total record count for pagination.
 * @export
 * @interface NotificationResultDto
 */
export interface NotificationResultDto {
    /**
     * Gets or sets the current page of notifications.
     * @type {Array<NotificationDto>}
     * @memberof NotificationResultDto
     */
    Notifications?: Array<NotificationDto> | null;
    /**
     * Gets or sets the total number of notifications.
     * @type {number}
     * @memberof NotificationResultDto
     */
    TotalRecordCount?: number;
}


