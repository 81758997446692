/* tslint:disable */
/* eslint-disable */
/**
 * Jellyfin API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * The specific media type of an MediaBrowser.Model.Providers.ExternalIdInfo.
 * @export
 * @enum {string}
 */
export enum ExternalIdMediaType {
    Album = 'Album',
    AlbumArtist = 'AlbumArtist',
    Artist = 'Artist',
    BoxSet = 'BoxSet',
    Episode = 'Episode',
    Movie = 'Movie',
    OtherArtist = 'OtherArtist',
    Person = 'Person',
    ReleaseGroup = 'ReleaseGroup',
    Season = 'Season',
    Series = 'Series',
    Track = 'Track'
}



