export enum AppStatus {
    Audio = 'Audio',
    Backdrop = 'Backdrop',
    Details = 'Details',
    Loading = 'Loading',
    PlayingWithControls = 'PlayingWithControls',
    Playing = 'Playing',
    Unset = '',
    Waiting = 'Waiting',
}

