/* tslint:disable */
/* eslint-disable */
/**
 * Jellyfin API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { BaseItemDto } from '../models';
// @ts-ignore
import { BaseItemDtoQueryResult } from '../models';
// @ts-ignore
import { ChannelMappingOptionsDto } from '../models';
// @ts-ignore
import { ChannelType } from '../models';
// @ts-ignore
import { GetProgramsDto } from '../models';
// @ts-ignore
import { GuideInfo } from '../models';
// @ts-ignore
import { ImageType } from '../models';
// @ts-ignore
import { ListingsProviderInfo } from '../models';
// @ts-ignore
import { LiveTvInfo } from '../models';
// @ts-ignore
import { NameIdPair } from '../models';
// @ts-ignore
import { ProblemDetails } from '../models';
// @ts-ignore
import { RecordingStatus } from '../models';
// @ts-ignore
import { SeriesTimerInfoDto } from '../models';
// @ts-ignore
import { SeriesTimerInfoDtoQueryResult } from '../models';
// @ts-ignore
import { SortOrder } from '../models';
// @ts-ignore
import { TimerInfoDto } from '../models';
// @ts-ignore
import { TimerInfoDtoQueryResult } from '../models';
// @ts-ignore
import { TunerChannelMapping } from '../models';
// @ts-ignore
import { TunerHostInfo } from '../models';
/**
 * LiveTvApi - axios parameter creator
 * @export
 */
export const LiveTvApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Adds a listings provider.
         * @param {string} [pw] Password.
         * @param {boolean} [validateListings] Validate listings.
         * @param {boolean} [validateLogin] Validate login.
         * @param {ListingsProviderInfo} [listingsProviderInfo] New listings info.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addListingProvider: async (pw?: string, validateListings?: boolean, validateLogin?: boolean, listingsProviderInfo?: ListingsProviderInfo, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/LiveTv/ListingProviders`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }

            if (pw !== undefined) {
                localVarQueryParameter['pw'] = pw;
            }

            if (validateListings !== undefined) {
                localVarQueryParameter['validateListings'] = validateListings;
            }

            if (validateLogin !== undefined) {
                localVarQueryParameter['validateLogin'] = validateLogin;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof listingsProviderInfo !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(listingsProviderInfo !== undefined ? listingsProviderInfo : {}) : (listingsProviderInfo || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Adds a tuner host.
         * @param {TunerHostInfo} [tunerHostInfo] New tuner host.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addTunerHost: async (tunerHostInfo?: TunerHostInfo, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/LiveTv/TunerHosts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof tunerHostInfo !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(tunerHostInfo !== undefined ? tunerHostInfo : {}) : (tunerHostInfo || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Cancels a live tv series timer.
         * @param {string} timerId Timer id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelSeriesTimer: async (timerId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'timerId' is not null or undefined
            if (timerId === null || timerId === undefined) {
                throw new RequiredError('timerId','Required parameter timerId was null or undefined when calling cancelSeriesTimer.');
            }
            const localVarPath = `/LiveTv/SeriesTimers/{timerId}`
                .replace(`{${"timerId"}}`, encodeURIComponent(String(timerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Cancels a live tv timer.
         * @param {string} timerId Timer id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelTimer: async (timerId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'timerId' is not null or undefined
            if (timerId === null || timerId === undefined) {
                throw new RequiredError('timerId','Required parameter timerId was null or undefined when calling cancelTimer.');
            }
            const localVarPath = `/LiveTv/Timers/{timerId}`
                .replace(`{${"timerId"}}`, encodeURIComponent(String(timerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates a live tv series timer.
         * @param {SeriesTimerInfoDto} [seriesTimerInfoDto] New series timer info.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSeriesTimer: async (seriesTimerInfoDto?: SeriesTimerInfoDto, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/LiveTv/SeriesTimers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof seriesTimerInfoDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(seriesTimerInfoDto !== undefined ? seriesTimerInfoDto : {}) : (seriesTimerInfoDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates a live tv timer.
         * @param {TimerInfoDto} [timerInfoDto] New timer info.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTimer: async (timerInfoDto?: TimerInfoDto, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/LiveTv/Timers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof timerInfoDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(timerInfoDto !== undefined ? timerInfoDto : {}) : (timerInfoDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete listing provider.
         * @param {string} [id] Listing provider id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteListingProvider: async (id?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/LiveTv/ListingProviders`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deletes a live tv recording.
         * @param {string} recordingId Recording id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRecording: async (recordingId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'recordingId' is not null or undefined
            if (recordingId === null || recordingId === undefined) {
                throw new RequiredError('recordingId','Required parameter recordingId was null or undefined when calling deleteRecording.');
            }
            const localVarPath = `/LiveTv/Recordings/{recordingId}`
                .replace(`{${"recordingId"}}`, encodeURIComponent(String(recordingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deletes a tuner host.
         * @param {string} [id] Tuner host id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTunerHost: async (id?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/LiveTv/TunerHosts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Discover tuners.
         * @param {boolean} [newDevicesOnly] Only discover new tuners.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        discoverTuners: async (newDevicesOnly?: boolean, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/LiveTv/Tuners/Discvover`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }

            if (newDevicesOnly !== undefined) {
                localVarQueryParameter['newDevicesOnly'] = newDevicesOnly;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets a live tv channel.
         * @param {string} channelId Channel id.
         * @param {string} [userId] Optional. Attach user data.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChannel: async (channelId: string, userId?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'channelId' is not null or undefined
            if (channelId === null || channelId === undefined) {
                throw new RequiredError('channelId','Required parameter channelId was null or undefined when calling getChannel.');
            }
            const localVarPath = `/LiveTv/Channels/{channelId}`
                .replace(`{${"channelId"}}`, encodeURIComponent(String(channelId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get channel mapping options.
         * @param {string} [providerId] Provider id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChannelMappingOptions: async (providerId?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/LiveTv/ChannelMappingOptions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }

            if (providerId !== undefined) {
                localVarQueryParameter['providerId'] = providerId;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets default listings provider info.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDefaultListingProvider: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/LiveTv/ListingProviders/Default`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets the default values for a new timer.
         * @param {string} [programId] Optional. To attach default values based on a program.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDefaultTimer: async (programId?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/LiveTv/Timers/Defaults`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }

            if (programId !== undefined) {
                localVarQueryParameter['programId'] = programId;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get guid info.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGuideInfo: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/LiveTv/GuideInfo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets available lineups.
         * @param {string} [id] Provider id.
         * @param {string} [type] Provider type.
         * @param {string} [location] Location.
         * @param {string} [country] Country.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLineups: async (id?: string, type?: string, location?: string, country?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/LiveTv/ListingProviders/Lineups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (location !== undefined) {
                localVarQueryParameter['location'] = location;
            }

            if (country !== undefined) {
                localVarQueryParameter['country'] = country;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets a live tv recording stream.
         * @param {string} recordingId Recording id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLiveRecordingFile: async (recordingId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'recordingId' is not null or undefined
            if (recordingId === null || recordingId === undefined) {
                throw new RequiredError('recordingId','Required parameter recordingId was null or undefined when calling getLiveRecordingFile.');
            }
            const localVarPath = `/LiveTv/LiveRecordings/{recordingId}/stream`
                .replace(`{${"recordingId"}}`, encodeURIComponent(String(recordingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets a live tv channel stream.
         * @param {string} streamId Stream id.
         * @param {string} container Container type.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLiveStreamFile: async (streamId: string, container: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'streamId' is not null or undefined
            if (streamId === null || streamId === undefined) {
                throw new RequiredError('streamId','Required parameter streamId was null or undefined when calling getLiveStreamFile.');
            }
            // verify required parameter 'container' is not null or undefined
            if (container === null || container === undefined) {
                throw new RequiredError('container','Required parameter container was null or undefined when calling getLiveStreamFile.');
            }
            const localVarPath = `/LiveTv/LiveStreamFiles/{streamId}/stream.{container}`
                .replace(`{${"streamId"}}`, encodeURIComponent(String(streamId)))
                .replace(`{${"container"}}`, encodeURIComponent(String(container)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets available live tv channels.
         * @param {ChannelType} [type] Optional. Filter by channel type.
         * @param {string} [userId] Optional. Filter by user and attach user data.
         * @param {number} [startIndex] Optional. The record index to start at. All items with a lower index will be dropped from the results.
         * @param {boolean} [isMovie] Optional. Filter for movies.
         * @param {boolean} [isSeries] Optional. Filter for series.
         * @param {boolean} [isNews] Optional. Filter for news.
         * @param {boolean} [isKids] Optional. Filter for kids.
         * @param {boolean} [isSports] Optional. Filter for sports.
         * @param {number} [limit] Optional. The maximum number of records to return.
         * @param {boolean} [isFavorite] Optional. Filter by channels that are favorites, or not.
         * @param {boolean} [isLiked] Optional. Filter by channels that are liked, or not.
         * @param {boolean} [isDisliked] Optional. Filter by channels that are disliked, or not.
         * @param {boolean} [enableImages] Optional. Include image information in output.
         * @param {number} [imageTypeLimit] Optional. The max number of images to return, per image type.
         * @param {Array<ImageType>} [enableImageTypes] \&quot;Optional. The image types to include in the output.
         * @param {string} [fields] Optional. Specify additional fields of information to return in the output. This allows multiple, comma delimited. Options: Budget, Chapters, DateCreated, Genres, HomePageUrl, IndexOptions, MediaStreams, Overview, ParentId, Path, People, ProviderIds, PrimaryImageAspectRatio, Revenue, SortName, Studios, Taglines.
         * @param {boolean} [enableUserData] Optional. Include user data.
         * @param {string} [sortBy] Optional. Key to sort by.
         * @param {SortOrder} [sortOrder] Optional. Sort order.
         * @param {boolean} [enableFavoriteSorting] Optional. Incorporate favorite and like status into channel sorting.
         * @param {boolean} [addCurrentProgram] Optional. Adds current program info to each channel.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLiveTvChannels: async (type?: ChannelType, userId?: string, startIndex?: number, isMovie?: boolean, isSeries?: boolean, isNews?: boolean, isKids?: boolean, isSports?: boolean, limit?: number, isFavorite?: boolean, isLiked?: boolean, isDisliked?: boolean, enableImages?: boolean, imageTypeLimit?: number, enableImageTypes?: Array<ImageType>, fields?: string, enableUserData?: boolean, sortBy?: string, sortOrder?: SortOrder, enableFavoriteSorting?: boolean, addCurrentProgram?: boolean, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/LiveTv/Channels`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }

            if (startIndex !== undefined) {
                localVarQueryParameter['startIndex'] = startIndex;
            }

            if (isMovie !== undefined) {
                localVarQueryParameter['isMovie'] = isMovie;
            }

            if (isSeries !== undefined) {
                localVarQueryParameter['isSeries'] = isSeries;
            }

            if (isNews !== undefined) {
                localVarQueryParameter['isNews'] = isNews;
            }

            if (isKids !== undefined) {
                localVarQueryParameter['isKids'] = isKids;
            }

            if (isSports !== undefined) {
                localVarQueryParameter['isSports'] = isSports;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (isFavorite !== undefined) {
                localVarQueryParameter['isFavorite'] = isFavorite;
            }

            if (isLiked !== undefined) {
                localVarQueryParameter['isLiked'] = isLiked;
            }

            if (isDisliked !== undefined) {
                localVarQueryParameter['isDisliked'] = isDisliked;
            }

            if (enableImages !== undefined) {
                localVarQueryParameter['enableImages'] = enableImages;
            }

            if (imageTypeLimit !== undefined) {
                localVarQueryParameter['imageTypeLimit'] = imageTypeLimit;
            }

            if (enableImageTypes) {
                localVarQueryParameter['enableImageTypes'] = enableImageTypes;
            }

            if (fields !== undefined) {
                localVarQueryParameter['fields'] = fields;
            }

            if (enableUserData !== undefined) {
                localVarQueryParameter['enableUserData'] = enableUserData;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (sortOrder !== undefined) {
                localVarQueryParameter['sortOrder'] = sortOrder;
            }

            if (enableFavoriteSorting !== undefined) {
                localVarQueryParameter['enableFavoriteSorting'] = enableFavoriteSorting;
            }

            if (addCurrentProgram !== undefined) {
                localVarQueryParameter['addCurrentProgram'] = addCurrentProgram;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets available live tv services.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLiveTvInfo: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/LiveTv/Info`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets available live tv epgs.
         * @param {string} [channelIds] The channels to return guide information for.
         * @param {string} [userId] Optional. Filter by user id.
         * @param {string} [minStartDate] Optional. The minimum premiere start date.
         * @param {boolean} [hasAired] Optional. Filter by programs that have completed airing, or not.
         * @param {boolean} [isAiring] Optional. Filter by programs that are currently airing, or not.
         * @param {string} [maxStartDate] Optional. The maximum premiere start date.
         * @param {string} [minEndDate] Optional. The minimum premiere end date.
         * @param {string} [maxEndDate] Optional. The maximum premiere end date.
         * @param {boolean} [isMovie] Optional. Filter for movies.
         * @param {boolean} [isSeries] Optional. Filter for series.
         * @param {boolean} [isNews] Optional. Filter for news.
         * @param {boolean} [isKids] Optional. Filter for kids.
         * @param {boolean} [isSports] Optional. Filter for sports.
         * @param {number} [startIndex] Optional. The record index to start at. All items with a lower index will be dropped from the results.
         * @param {number} [limit] Optional. The maximum number of records to return.
         * @param {string} [sortBy] Optional. Specify one or more sort orders, comma delimited. Options: Name, StartDate.
         * @param {string} [sortOrder] Sort Order - Ascending,Descending.
         * @param {string} [genres] The genres to return guide information for.
         * @param {string} [genreIds] The genre ids to return guide information for.
         * @param {boolean} [enableImages] Optional. Include image information in output.
         * @param {number} [imageTypeLimit] Optional. The max number of images to return, per image type.
         * @param {Array<ImageType>} [enableImageTypes] Optional. The image types to include in the output.
         * @param {boolean} [enableUserData] Optional. Include user data.
         * @param {string} [seriesTimerId] Optional. Filter by series timer id.
         * @param {string} [librarySeriesId] Optional. Filter by library series id.
         * @param {string} [fields] Optional. Specify additional fields of information to return in the output. This allows multiple, comma delimited. Options: Budget, Chapters, DateCreated, Genres, HomePageUrl, IndexOptions, MediaStreams, Overview, ParentId, Path, People, ProviderIds, PrimaryImageAspectRatio, Revenue, SortName, Studios, Taglines.
         * @param {boolean} [enableTotalRecordCount] Retrieve total record count.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLiveTvPrograms: async (channelIds?: string, userId?: string, minStartDate?: string, hasAired?: boolean, isAiring?: boolean, maxStartDate?: string, minEndDate?: string, maxEndDate?: string, isMovie?: boolean, isSeries?: boolean, isNews?: boolean, isKids?: boolean, isSports?: boolean, startIndex?: number, limit?: number, sortBy?: string, sortOrder?: string, genres?: string, genreIds?: string, enableImages?: boolean, imageTypeLimit?: number, enableImageTypes?: Array<ImageType>, enableUserData?: boolean, seriesTimerId?: string, librarySeriesId?: string, fields?: string, enableTotalRecordCount?: boolean, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/LiveTv/Programs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }

            if (channelIds !== undefined) {
                localVarQueryParameter['channelIds'] = channelIds;
            }

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }

            if (minStartDate !== undefined) {
                localVarQueryParameter['minStartDate'] = (minStartDate as any instanceof Date) ?
                    (minStartDate as any).toISOString() :
                    minStartDate;
            }

            if (hasAired !== undefined) {
                localVarQueryParameter['hasAired'] = hasAired;
            }

            if (isAiring !== undefined) {
                localVarQueryParameter['isAiring'] = isAiring;
            }

            if (maxStartDate !== undefined) {
                localVarQueryParameter['maxStartDate'] = (maxStartDate as any instanceof Date) ?
                    (maxStartDate as any).toISOString() :
                    maxStartDate;
            }

            if (minEndDate !== undefined) {
                localVarQueryParameter['minEndDate'] = (minEndDate as any instanceof Date) ?
                    (minEndDate as any).toISOString() :
                    minEndDate;
            }

            if (maxEndDate !== undefined) {
                localVarQueryParameter['maxEndDate'] = (maxEndDate as any instanceof Date) ?
                    (maxEndDate as any).toISOString() :
                    maxEndDate;
            }

            if (isMovie !== undefined) {
                localVarQueryParameter['isMovie'] = isMovie;
            }

            if (isSeries !== undefined) {
                localVarQueryParameter['isSeries'] = isSeries;
            }

            if (isNews !== undefined) {
                localVarQueryParameter['isNews'] = isNews;
            }

            if (isKids !== undefined) {
                localVarQueryParameter['isKids'] = isKids;
            }

            if (isSports !== undefined) {
                localVarQueryParameter['isSports'] = isSports;
            }

            if (startIndex !== undefined) {
                localVarQueryParameter['startIndex'] = startIndex;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (sortOrder !== undefined) {
                localVarQueryParameter['sortOrder'] = sortOrder;
            }

            if (genres !== undefined) {
                localVarQueryParameter['genres'] = genres;
            }

            if (genreIds !== undefined) {
                localVarQueryParameter['genreIds'] = genreIds;
            }

            if (enableImages !== undefined) {
                localVarQueryParameter['enableImages'] = enableImages;
            }

            if (imageTypeLimit !== undefined) {
                localVarQueryParameter['imageTypeLimit'] = imageTypeLimit;
            }

            if (enableImageTypes) {
                localVarQueryParameter['enableImageTypes'] = enableImageTypes;
            }

            if (enableUserData !== undefined) {
                localVarQueryParameter['enableUserData'] = enableUserData;
            }

            if (seriesTimerId !== undefined) {
                localVarQueryParameter['seriesTimerId'] = seriesTimerId;
            }

            if (librarySeriesId !== undefined) {
                localVarQueryParameter['librarySeriesId'] = librarySeriesId;
            }

            if (fields !== undefined) {
                localVarQueryParameter['fields'] = fields;
            }

            if (enableTotalRecordCount !== undefined) {
                localVarQueryParameter['enableTotalRecordCount'] = enableTotalRecordCount;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets a live tv program.
         * @param {string} programId Program id.
         * @param {string} [userId] Optional. Attach user data.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProgram: async (programId: string, userId?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'programId' is not null or undefined
            if (programId === null || programId === undefined) {
                throw new RequiredError('programId','Required parameter programId was null or undefined when calling getProgram.');
            }
            const localVarPath = `/LiveTv/Programs/{programId}`
                .replace(`{${"programId"}}`, encodeURIComponent(String(programId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets available live tv epgs.
         * @param {GetProgramsDto} [getProgramsDto] Request body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPrograms: async (getProgramsDto?: GetProgramsDto, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/LiveTv/Programs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof getProgramsDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(getProgramsDto !== undefined ? getProgramsDto : {}) : (getProgramsDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets recommended live tv epgs.
         * @param {string} [userId] Optional. filter by user id.
         * @param {number} [limit] Optional. The maximum number of records to return.
         * @param {boolean} [isAiring] Optional. Filter by programs that are currently airing, or not.
         * @param {boolean} [hasAired] Optional. Filter by programs that have completed airing, or not.
         * @param {boolean} [isSeries] Optional. Filter for series.
         * @param {boolean} [isMovie] Optional. Filter for movies.
         * @param {boolean} [isNews] Optional. Filter for news.
         * @param {boolean} [isKids] Optional. Filter for kids.
         * @param {boolean} [isSports] Optional. Filter for sports.
         * @param {boolean} [enableImages] Optional. Include image information in output.
         * @param {number} [imageTypeLimit] Optional. The max number of images to return, per image type.
         * @param {Array<ImageType>} [enableImageTypes] Optional. The image types to include in the output.
         * @param {string} [genreIds] The genres to return guide information for.
         * @param {string} [fields] Optional. Specify additional fields of information to return in the output. This allows multiple, comma delimited. Options: Budget, Chapters, DateCreated, Genres, HomePageUrl, IndexOptions, MediaStreams, Overview, ParentId, Path, People, ProviderIds, PrimaryImageAspectRatio, Revenue, SortName, Studios, Taglines.
         * @param {boolean} [enableUserData] Optional. include user data.
         * @param {boolean} [enableTotalRecordCount] Retrieve total record count.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRecommendedPrograms: async (userId?: string, limit?: number, isAiring?: boolean, hasAired?: boolean, isSeries?: boolean, isMovie?: boolean, isNews?: boolean, isKids?: boolean, isSports?: boolean, enableImages?: boolean, imageTypeLimit?: number, enableImageTypes?: Array<ImageType>, genreIds?: string, fields?: string, enableUserData?: boolean, enableTotalRecordCount?: boolean, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/LiveTv/Programs/Recommended`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (isAiring !== undefined) {
                localVarQueryParameter['isAiring'] = isAiring;
            }

            if (hasAired !== undefined) {
                localVarQueryParameter['hasAired'] = hasAired;
            }

            if (isSeries !== undefined) {
                localVarQueryParameter['isSeries'] = isSeries;
            }

            if (isMovie !== undefined) {
                localVarQueryParameter['isMovie'] = isMovie;
            }

            if (isNews !== undefined) {
                localVarQueryParameter['isNews'] = isNews;
            }

            if (isKids !== undefined) {
                localVarQueryParameter['isKids'] = isKids;
            }

            if (isSports !== undefined) {
                localVarQueryParameter['isSports'] = isSports;
            }

            if (enableImages !== undefined) {
                localVarQueryParameter['enableImages'] = enableImages;
            }

            if (imageTypeLimit !== undefined) {
                localVarQueryParameter['imageTypeLimit'] = imageTypeLimit;
            }

            if (enableImageTypes) {
                localVarQueryParameter['enableImageTypes'] = enableImageTypes;
            }

            if (genreIds !== undefined) {
                localVarQueryParameter['genreIds'] = genreIds;
            }

            if (fields !== undefined) {
                localVarQueryParameter['fields'] = fields;
            }

            if (enableUserData !== undefined) {
                localVarQueryParameter['enableUserData'] = enableUserData;
            }

            if (enableTotalRecordCount !== undefined) {
                localVarQueryParameter['enableTotalRecordCount'] = enableTotalRecordCount;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets a live tv recording.
         * @param {string} recordingId Recording id.
         * @param {string} [userId] Optional. Attach user data.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRecording: async (recordingId: string, userId?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'recordingId' is not null or undefined
            if (recordingId === null || recordingId === undefined) {
                throw new RequiredError('recordingId','Required parameter recordingId was null or undefined when calling getRecording.');
            }
            const localVarPath = `/LiveTv/Recordings/{recordingId}`
                .replace(`{${"recordingId"}}`, encodeURIComponent(String(recordingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets recording folders.
         * @param {string} [userId] Optional. Filter by user and attach user data.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRecordingFolders: async (userId?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/LiveTv/Recordings/Folders`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get recording group.
         * @param {string} groupId Group id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRecordingGroup: async (groupId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'groupId' is not null or undefined
            if (groupId === null || groupId === undefined) {
                throw new RequiredError('groupId','Required parameter groupId was null or undefined when calling getRecordingGroup.');
            }
            const localVarPath = `/LiveTv/Recordings/Groups/{groupId}`
                .replace(`{${"groupId"}}`, encodeURIComponent(String(groupId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets live tv recording groups.
         * @param {string} [userId] Optional. Filter by user and attach user data.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRecordingGroups: async (userId?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/LiveTv/Recordings/Groups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets live tv recordings.
         * @param {string} [channelId] Optional. Filter by channel id.
         * @param {string} [userId] Optional. Filter by user and attach user data.
         * @param {number} [startIndex] Optional. The record index to start at. All items with a lower index will be dropped from the results.
         * @param {number} [limit] Optional. The maximum number of records to return.
         * @param {RecordingStatus} [status] Optional. Filter by recording status.
         * @param {boolean} [isInProgress] Optional. Filter by recordings that are in progress, or not.
         * @param {string} [seriesTimerId] Optional. Filter by recordings belonging to a series timer.
         * @param {boolean} [enableImages] Optional. Include image information in output.
         * @param {number} [imageTypeLimit] Optional. The max number of images to return, per image type.
         * @param {Array<ImageType>} [enableImageTypes] Optional. The image types to include in the output.
         * @param {string} [fields] Optional. Specify additional fields of information to return in the output. This allows multiple, comma delimited. Options: Budget, Chapters, DateCreated, Genres, HomePageUrl, IndexOptions, MediaStreams, Overview, ParentId, Path, People, ProviderIds, PrimaryImageAspectRatio, Revenue, SortName, Studios, Taglines.
         * @param {boolean} [enableUserData] Optional. Include user data.
         * @param {boolean} [isMovie] Optional. Filter for movies.
         * @param {boolean} [isSeries] Optional. Filter for series.
         * @param {boolean} [isKids] Optional. Filter for kids.
         * @param {boolean} [isSports] Optional. Filter for sports.
         * @param {boolean} [isNews] Optional. Filter for news.
         * @param {boolean} [isLibraryItem] Optional. Filter for is library item.
         * @param {boolean} [enableTotalRecordCount] Optional. Return total record count.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRecordings: async (channelId?: string, userId?: string, startIndex?: number, limit?: number, status?: RecordingStatus, isInProgress?: boolean, seriesTimerId?: string, enableImages?: boolean, imageTypeLimit?: number, enableImageTypes?: Array<ImageType>, fields?: string, enableUserData?: boolean, isMovie?: boolean, isSeries?: boolean, isKids?: boolean, isSports?: boolean, isNews?: boolean, isLibraryItem?: boolean, enableTotalRecordCount?: boolean, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/LiveTv/Recordings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }

            if (channelId !== undefined) {
                localVarQueryParameter['channelId'] = channelId;
            }

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }

            if (startIndex !== undefined) {
                localVarQueryParameter['startIndex'] = startIndex;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (isInProgress !== undefined) {
                localVarQueryParameter['isInProgress'] = isInProgress;
            }

            if (seriesTimerId !== undefined) {
                localVarQueryParameter['seriesTimerId'] = seriesTimerId;
            }

            if (enableImages !== undefined) {
                localVarQueryParameter['enableImages'] = enableImages;
            }

            if (imageTypeLimit !== undefined) {
                localVarQueryParameter['imageTypeLimit'] = imageTypeLimit;
            }

            if (enableImageTypes) {
                localVarQueryParameter['enableImageTypes'] = enableImageTypes;
            }

            if (fields !== undefined) {
                localVarQueryParameter['fields'] = fields;
            }

            if (enableUserData !== undefined) {
                localVarQueryParameter['enableUserData'] = enableUserData;
            }

            if (isMovie !== undefined) {
                localVarQueryParameter['isMovie'] = isMovie;
            }

            if (isSeries !== undefined) {
                localVarQueryParameter['isSeries'] = isSeries;
            }

            if (isKids !== undefined) {
                localVarQueryParameter['isKids'] = isKids;
            }

            if (isSports !== undefined) {
                localVarQueryParameter['isSports'] = isSports;
            }

            if (isNews !== undefined) {
                localVarQueryParameter['isNews'] = isNews;
            }

            if (isLibraryItem !== undefined) {
                localVarQueryParameter['isLibraryItem'] = isLibraryItem;
            }

            if (enableTotalRecordCount !== undefined) {
                localVarQueryParameter['enableTotalRecordCount'] = enableTotalRecordCount;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets live tv recording series.
         * @param {string} [channelId] Optional. Filter by channel id.
         * @param {string} [userId] Optional. Filter by user and attach user data.
         * @param {string} [groupId] Optional. Filter by recording group.
         * @param {number} [startIndex] Optional. The record index to start at. All items with a lower index will be dropped from the results.
         * @param {number} [limit] Optional. The maximum number of records to return.
         * @param {RecordingStatus} [status] Optional. Filter by recording status.
         * @param {boolean} [isInProgress] Optional. Filter by recordings that are in progress, or not.
         * @param {string} [seriesTimerId] Optional. Filter by recordings belonging to a series timer.
         * @param {boolean} [enableImages] Optional. Include image information in output.
         * @param {number} [imageTypeLimit] Optional. The max number of images to return, per image type.
         * @param {Array<ImageType>} [enableImageTypes] Optional. The image types to include in the output.
         * @param {string} [fields] Optional. Specify additional fields of information to return in the output. This allows multiple, comma delimited. Options: Budget, Chapters, DateCreated, Genres, HomePageUrl, IndexOptions, MediaStreams, Overview, ParentId, Path, People, ProviderIds, PrimaryImageAspectRatio, Revenue, SortName, Studios, Taglines.
         * @param {boolean} [enableUserData] Optional. Include user data.
         * @param {boolean} [enableTotalRecordCount] Optional. Return total record count.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRecordingsSeries: async (channelId?: string, userId?: string, groupId?: string, startIndex?: number, limit?: number, status?: RecordingStatus, isInProgress?: boolean, seriesTimerId?: string, enableImages?: boolean, imageTypeLimit?: number, enableImageTypes?: Array<ImageType>, fields?: string, enableUserData?: boolean, enableTotalRecordCount?: boolean, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/LiveTv/Recordings/Series`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }

            if (channelId !== undefined) {
                localVarQueryParameter['channelId'] = channelId;
            }

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }

            if (groupId !== undefined) {
                localVarQueryParameter['groupId'] = groupId;
            }

            if (startIndex !== undefined) {
                localVarQueryParameter['startIndex'] = startIndex;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (isInProgress !== undefined) {
                localVarQueryParameter['isInProgress'] = isInProgress;
            }

            if (seriesTimerId !== undefined) {
                localVarQueryParameter['seriesTimerId'] = seriesTimerId;
            }

            if (enableImages !== undefined) {
                localVarQueryParameter['enableImages'] = enableImages;
            }

            if (imageTypeLimit !== undefined) {
                localVarQueryParameter['imageTypeLimit'] = imageTypeLimit;
            }

            if (enableImageTypes) {
                localVarQueryParameter['enableImageTypes'] = enableImageTypes;
            }

            if (fields !== undefined) {
                localVarQueryParameter['fields'] = fields;
            }

            if (enableUserData !== undefined) {
                localVarQueryParameter['enableUserData'] = enableUserData;
            }

            if (enableTotalRecordCount !== undefined) {
                localVarQueryParameter['enableTotalRecordCount'] = enableTotalRecordCount;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets available countries.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSchedulesDirectCountries: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/LiveTv/ListingProviders/SchedulesDirect/Countries`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets a live tv series timer.
         * @param {string} timerId Timer id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSeriesTimer: async (timerId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'timerId' is not null or undefined
            if (timerId === null || timerId === undefined) {
                throw new RequiredError('timerId','Required parameter timerId was null or undefined when calling getSeriesTimer.');
            }
            const localVarPath = `/LiveTv/SeriesTimers/{timerId}`
                .replace(`{${"timerId"}}`, encodeURIComponent(String(timerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets live tv series timers.
         * @param {string} [sortBy] Optional. Sort by SortName or Priority.
         * @param {SortOrder} [sortOrder] Optional. Sort in Ascending or Descending order.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSeriesTimers: async (sortBy?: string, sortOrder?: SortOrder, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/LiveTv/SeriesTimers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (sortOrder !== undefined) {
                localVarQueryParameter['sortOrder'] = sortOrder;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets a timer.
         * @param {string} timerId Timer id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTimer: async (timerId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'timerId' is not null or undefined
            if (timerId === null || timerId === undefined) {
                throw new RequiredError('timerId','Required parameter timerId was null or undefined when calling getTimer.');
            }
            const localVarPath = `/LiveTv/Timers/{timerId}`
                .replace(`{${"timerId"}}`, encodeURIComponent(String(timerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets the live tv timers.
         * @param {string} [channelId] Optional. Filter by channel id.
         * @param {string} [seriesTimerId] Optional. Filter by timers belonging to a series timer.
         * @param {boolean} [isActive] Optional. Filter by timers that are active.
         * @param {boolean} [isScheduled] Optional. Filter by timers that are scheduled.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTimers: async (channelId?: string, seriesTimerId?: string, isActive?: boolean, isScheduled?: boolean, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/LiveTv/Timers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }

            if (channelId !== undefined) {
                localVarQueryParameter['channelId'] = channelId;
            }

            if (seriesTimerId !== undefined) {
                localVarQueryParameter['seriesTimerId'] = seriesTimerId;
            }

            if (isActive !== undefined) {
                localVarQueryParameter['isActive'] = isActive;
            }

            if (isScheduled !== undefined) {
                localVarQueryParameter['isScheduled'] = isScheduled;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get tuner host types.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTunerHostTypes: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/LiveTv/TunerHosts/Types`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Resets a tv tuner.
         * @param {string} tunerId Tuner id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetTuner: async (tunerId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tunerId' is not null or undefined
            if (tunerId === null || tunerId === undefined) {
                throw new RequiredError('tunerId','Required parameter tunerId was null or undefined when calling resetTuner.');
            }
            const localVarPath = `/LiveTv/Tuners/{tunerId}/Reset`
                .replace(`{${"tunerId"}}`, encodeURIComponent(String(tunerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Set channel mappings.
         * @param {string} [providerId] Provider id.
         * @param {string} [tunerChannelId] Tuner channel id.
         * @param {string} [providerChannelId] Provider channel id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setChannelMapping: async (providerId?: string, tunerChannelId?: string, providerChannelId?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/LiveTv/ChannelMappings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }

            if (providerId !== undefined) {
                localVarQueryParameter['providerId'] = providerId;
            }

            if (tunerChannelId !== undefined) {
                localVarQueryParameter['tunerChannelId'] = tunerChannelId;
            }

            if (providerChannelId !== undefined) {
                localVarQueryParameter['providerChannelId'] = providerChannelId;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates a live tv series timer.
         * @param {string} timerId Timer id.
         * @param {SeriesTimerInfoDto} [seriesTimerInfoDto] New series timer info.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSeriesTimer: async (timerId: string, seriesTimerInfoDto?: SeriesTimerInfoDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'timerId' is not null or undefined
            if (timerId === null || timerId === undefined) {
                throw new RequiredError('timerId','Required parameter timerId was null or undefined when calling updateSeriesTimer.');
            }
            const localVarPath = `/LiveTv/SeriesTimers/{timerId}`
                .replace(`{${"timerId"}}`, encodeURIComponent(String(timerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof seriesTimerInfoDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(seriesTimerInfoDto !== undefined ? seriesTimerInfoDto : {}) : (seriesTimerInfoDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates a live tv timer.
         * @param {string} timerId Timer id.
         * @param {TimerInfoDto} [timerInfoDto] New timer info.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTimer: async (timerId: string, timerInfoDto?: TimerInfoDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'timerId' is not null or undefined
            if (timerId === null || timerId === undefined) {
                throw new RequiredError('timerId','Required parameter timerId was null or undefined when calling updateTimer.');
            }
            const localVarPath = `/LiveTv/Timers/{timerId}`
                .replace(`{${"timerId"}}`, encodeURIComponent(String(timerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof timerInfoDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(timerInfoDto !== undefined ? timerInfoDto : {}) : (timerInfoDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LiveTvApi - functional programming interface
 * @export
 */
export const LiveTvApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Adds a listings provider.
         * @param {string} [pw] Password.
         * @param {boolean} [validateListings] Validate listings.
         * @param {boolean} [validateLogin] Validate login.
         * @param {ListingsProviderInfo} [listingsProviderInfo] New listings info.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addListingProvider(pw?: string, validateListings?: boolean, validateLogin?: boolean, listingsProviderInfo?: ListingsProviderInfo, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListingsProviderInfo>> {
            const localVarAxiosArgs = await LiveTvApiAxiosParamCreator(configuration).addListingProvider(pw, validateListings, validateLogin, listingsProviderInfo, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Adds a tuner host.
         * @param {TunerHostInfo} [tunerHostInfo] New tuner host.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addTunerHost(tunerHostInfo?: TunerHostInfo, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TunerHostInfo>> {
            const localVarAxiosArgs = await LiveTvApiAxiosParamCreator(configuration).addTunerHost(tunerHostInfo, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Cancels a live tv series timer.
         * @param {string} timerId Timer id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cancelSeriesTimer(timerId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await LiveTvApiAxiosParamCreator(configuration).cancelSeriesTimer(timerId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Cancels a live tv timer.
         * @param {string} timerId Timer id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cancelTimer(timerId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await LiveTvApiAxiosParamCreator(configuration).cancelTimer(timerId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Creates a live tv series timer.
         * @param {SeriesTimerInfoDto} [seriesTimerInfoDto] New series timer info.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createSeriesTimer(seriesTimerInfoDto?: SeriesTimerInfoDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await LiveTvApiAxiosParamCreator(configuration).createSeriesTimer(seriesTimerInfoDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Creates a live tv timer.
         * @param {TimerInfoDto} [timerInfoDto] New timer info.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createTimer(timerInfoDto?: TimerInfoDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await LiveTvApiAxiosParamCreator(configuration).createTimer(timerInfoDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Delete listing provider.
         * @param {string} [id] Listing provider id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteListingProvider(id?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await LiveTvApiAxiosParamCreator(configuration).deleteListingProvider(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Deletes a live tv recording.
         * @param {string} recordingId Recording id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteRecording(recordingId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await LiveTvApiAxiosParamCreator(configuration).deleteRecording(recordingId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Deletes a tuner host.
         * @param {string} [id] Tuner host id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteTunerHost(id?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await LiveTvApiAxiosParamCreator(configuration).deleteTunerHost(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Discover tuners.
         * @param {boolean} [newDevicesOnly] Only discover new tuners.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async discoverTuners(newDevicesOnly?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TunerHostInfo>>> {
            const localVarAxiosArgs = await LiveTvApiAxiosParamCreator(configuration).discoverTuners(newDevicesOnly, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Gets a live tv channel.
         * @param {string} channelId Channel id.
         * @param {string} [userId] Optional. Attach user data.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getChannel(channelId: string, userId?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BaseItemDto>> {
            const localVarAxiosArgs = await LiveTvApiAxiosParamCreator(configuration).getChannel(channelId, userId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get channel mapping options.
         * @param {string} [providerId] Provider id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getChannelMappingOptions(providerId?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChannelMappingOptionsDto>> {
            const localVarAxiosArgs = await LiveTvApiAxiosParamCreator(configuration).getChannelMappingOptions(providerId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Gets default listings provider info.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDefaultListingProvider(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListingsProviderInfo>> {
            const localVarAxiosArgs = await LiveTvApiAxiosParamCreator(configuration).getDefaultListingProvider(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Gets the default values for a new timer.
         * @param {string} [programId] Optional. To attach default values based on a program.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDefaultTimer(programId?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SeriesTimerInfoDto>> {
            const localVarAxiosArgs = await LiveTvApiAxiosParamCreator(configuration).getDefaultTimer(programId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get guid info.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getGuideInfo(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GuideInfo>> {
            const localVarAxiosArgs = await LiveTvApiAxiosParamCreator(configuration).getGuideInfo(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Gets available lineups.
         * @param {string} [id] Provider id.
         * @param {string} [type] Provider type.
         * @param {string} [location] Location.
         * @param {string} [country] Country.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLineups(id?: string, type?: string, location?: string, country?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<NameIdPair>>> {
            const localVarAxiosArgs = await LiveTvApiAxiosParamCreator(configuration).getLineups(id, type, location, country, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Gets a live tv recording stream.
         * @param {string} recordingId Recording id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLiveRecordingFile(recordingId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await LiveTvApiAxiosParamCreator(configuration).getLiveRecordingFile(recordingId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Gets a live tv channel stream.
         * @param {string} streamId Stream id.
         * @param {string} container Container type.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLiveStreamFile(streamId: string, container: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await LiveTvApiAxiosParamCreator(configuration).getLiveStreamFile(streamId, container, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Gets available live tv channels.
         * @param {ChannelType} [type] Optional. Filter by channel type.
         * @param {string} [userId] Optional. Filter by user and attach user data.
         * @param {number} [startIndex] Optional. The record index to start at. All items with a lower index will be dropped from the results.
         * @param {boolean} [isMovie] Optional. Filter for movies.
         * @param {boolean} [isSeries] Optional. Filter for series.
         * @param {boolean} [isNews] Optional. Filter for news.
         * @param {boolean} [isKids] Optional. Filter for kids.
         * @param {boolean} [isSports] Optional. Filter for sports.
         * @param {number} [limit] Optional. The maximum number of records to return.
         * @param {boolean} [isFavorite] Optional. Filter by channels that are favorites, or not.
         * @param {boolean} [isLiked] Optional. Filter by channels that are liked, or not.
         * @param {boolean} [isDisliked] Optional. Filter by channels that are disliked, or not.
         * @param {boolean} [enableImages] Optional. Include image information in output.
         * @param {number} [imageTypeLimit] Optional. The max number of images to return, per image type.
         * @param {Array<ImageType>} [enableImageTypes] \&quot;Optional. The image types to include in the output.
         * @param {string} [fields] Optional. Specify additional fields of information to return in the output. This allows multiple, comma delimited. Options: Budget, Chapters, DateCreated, Genres, HomePageUrl, IndexOptions, MediaStreams, Overview, ParentId, Path, People, ProviderIds, PrimaryImageAspectRatio, Revenue, SortName, Studios, Taglines.
         * @param {boolean} [enableUserData] Optional. Include user data.
         * @param {string} [sortBy] Optional. Key to sort by.
         * @param {SortOrder} [sortOrder] Optional. Sort order.
         * @param {boolean} [enableFavoriteSorting] Optional. Incorporate favorite and like status into channel sorting.
         * @param {boolean} [addCurrentProgram] Optional. Adds current program info to each channel.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLiveTvChannels(type?: ChannelType, userId?: string, startIndex?: number, isMovie?: boolean, isSeries?: boolean, isNews?: boolean, isKids?: boolean, isSports?: boolean, limit?: number, isFavorite?: boolean, isLiked?: boolean, isDisliked?: boolean, enableImages?: boolean, imageTypeLimit?: number, enableImageTypes?: Array<ImageType>, fields?: string, enableUserData?: boolean, sortBy?: string, sortOrder?: SortOrder, enableFavoriteSorting?: boolean, addCurrentProgram?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BaseItemDtoQueryResult>> {
            const localVarAxiosArgs = await LiveTvApiAxiosParamCreator(configuration).getLiveTvChannels(type, userId, startIndex, isMovie, isSeries, isNews, isKids, isSports, limit, isFavorite, isLiked, isDisliked, enableImages, imageTypeLimit, enableImageTypes, fields, enableUserData, sortBy, sortOrder, enableFavoriteSorting, addCurrentProgram, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Gets available live tv services.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLiveTvInfo(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LiveTvInfo>> {
            const localVarAxiosArgs = await LiveTvApiAxiosParamCreator(configuration).getLiveTvInfo(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Gets available live tv epgs.
         * @param {string} [channelIds] The channels to return guide information for.
         * @param {string} [userId] Optional. Filter by user id.
         * @param {string} [minStartDate] Optional. The minimum premiere start date.
         * @param {boolean} [hasAired] Optional. Filter by programs that have completed airing, or not.
         * @param {boolean} [isAiring] Optional. Filter by programs that are currently airing, or not.
         * @param {string} [maxStartDate] Optional. The maximum premiere start date.
         * @param {string} [minEndDate] Optional. The minimum premiere end date.
         * @param {string} [maxEndDate] Optional. The maximum premiere end date.
         * @param {boolean} [isMovie] Optional. Filter for movies.
         * @param {boolean} [isSeries] Optional. Filter for series.
         * @param {boolean} [isNews] Optional. Filter for news.
         * @param {boolean} [isKids] Optional. Filter for kids.
         * @param {boolean} [isSports] Optional. Filter for sports.
         * @param {number} [startIndex] Optional. The record index to start at. All items with a lower index will be dropped from the results.
         * @param {number} [limit] Optional. The maximum number of records to return.
         * @param {string} [sortBy] Optional. Specify one or more sort orders, comma delimited. Options: Name, StartDate.
         * @param {string} [sortOrder] Sort Order - Ascending,Descending.
         * @param {string} [genres] The genres to return guide information for.
         * @param {string} [genreIds] The genre ids to return guide information for.
         * @param {boolean} [enableImages] Optional. Include image information in output.
         * @param {number} [imageTypeLimit] Optional. The max number of images to return, per image type.
         * @param {Array<ImageType>} [enableImageTypes] Optional. The image types to include in the output.
         * @param {boolean} [enableUserData] Optional. Include user data.
         * @param {string} [seriesTimerId] Optional. Filter by series timer id.
         * @param {string} [librarySeriesId] Optional. Filter by library series id.
         * @param {string} [fields] Optional. Specify additional fields of information to return in the output. This allows multiple, comma delimited. Options: Budget, Chapters, DateCreated, Genres, HomePageUrl, IndexOptions, MediaStreams, Overview, ParentId, Path, People, ProviderIds, PrimaryImageAspectRatio, Revenue, SortName, Studios, Taglines.
         * @param {boolean} [enableTotalRecordCount] Retrieve total record count.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLiveTvPrograms(channelIds?: string, userId?: string, minStartDate?: string, hasAired?: boolean, isAiring?: boolean, maxStartDate?: string, minEndDate?: string, maxEndDate?: string, isMovie?: boolean, isSeries?: boolean, isNews?: boolean, isKids?: boolean, isSports?: boolean, startIndex?: number, limit?: number, sortBy?: string, sortOrder?: string, genres?: string, genreIds?: string, enableImages?: boolean, imageTypeLimit?: number, enableImageTypes?: Array<ImageType>, enableUserData?: boolean, seriesTimerId?: string, librarySeriesId?: string, fields?: string, enableTotalRecordCount?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BaseItemDtoQueryResult>> {
            const localVarAxiosArgs = await LiveTvApiAxiosParamCreator(configuration).getLiveTvPrograms(channelIds, userId, minStartDate, hasAired, isAiring, maxStartDate, minEndDate, maxEndDate, isMovie, isSeries, isNews, isKids, isSports, startIndex, limit, sortBy, sortOrder, genres, genreIds, enableImages, imageTypeLimit, enableImageTypes, enableUserData, seriesTimerId, librarySeriesId, fields, enableTotalRecordCount, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Gets a live tv program.
         * @param {string} programId Program id.
         * @param {string} [userId] Optional. Attach user data.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProgram(programId: string, userId?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BaseItemDto>> {
            const localVarAxiosArgs = await LiveTvApiAxiosParamCreator(configuration).getProgram(programId, userId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Gets available live tv epgs.
         * @param {GetProgramsDto} [getProgramsDto] Request body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPrograms(getProgramsDto?: GetProgramsDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BaseItemDtoQueryResult>> {
            const localVarAxiosArgs = await LiveTvApiAxiosParamCreator(configuration).getPrograms(getProgramsDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Gets recommended live tv epgs.
         * @param {string} [userId] Optional. filter by user id.
         * @param {number} [limit] Optional. The maximum number of records to return.
         * @param {boolean} [isAiring] Optional. Filter by programs that are currently airing, or not.
         * @param {boolean} [hasAired] Optional. Filter by programs that have completed airing, or not.
         * @param {boolean} [isSeries] Optional. Filter for series.
         * @param {boolean} [isMovie] Optional. Filter for movies.
         * @param {boolean} [isNews] Optional. Filter for news.
         * @param {boolean} [isKids] Optional. Filter for kids.
         * @param {boolean} [isSports] Optional. Filter for sports.
         * @param {boolean} [enableImages] Optional. Include image information in output.
         * @param {number} [imageTypeLimit] Optional. The max number of images to return, per image type.
         * @param {Array<ImageType>} [enableImageTypes] Optional. The image types to include in the output.
         * @param {string} [genreIds] The genres to return guide information for.
         * @param {string} [fields] Optional. Specify additional fields of information to return in the output. This allows multiple, comma delimited. Options: Budget, Chapters, DateCreated, Genres, HomePageUrl, IndexOptions, MediaStreams, Overview, ParentId, Path, People, ProviderIds, PrimaryImageAspectRatio, Revenue, SortName, Studios, Taglines.
         * @param {boolean} [enableUserData] Optional. include user data.
         * @param {boolean} [enableTotalRecordCount] Retrieve total record count.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRecommendedPrograms(userId?: string, limit?: number, isAiring?: boolean, hasAired?: boolean, isSeries?: boolean, isMovie?: boolean, isNews?: boolean, isKids?: boolean, isSports?: boolean, enableImages?: boolean, imageTypeLimit?: number, enableImageTypes?: Array<ImageType>, genreIds?: string, fields?: string, enableUserData?: boolean, enableTotalRecordCount?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BaseItemDtoQueryResult>> {
            const localVarAxiosArgs = await LiveTvApiAxiosParamCreator(configuration).getRecommendedPrograms(userId, limit, isAiring, hasAired, isSeries, isMovie, isNews, isKids, isSports, enableImages, imageTypeLimit, enableImageTypes, genreIds, fields, enableUserData, enableTotalRecordCount, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Gets a live tv recording.
         * @param {string} recordingId Recording id.
         * @param {string} [userId] Optional. Attach user data.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRecording(recordingId: string, userId?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BaseItemDto>> {
            const localVarAxiosArgs = await LiveTvApiAxiosParamCreator(configuration).getRecording(recordingId, userId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Gets recording folders.
         * @param {string} [userId] Optional. Filter by user and attach user data.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRecordingFolders(userId?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BaseItemDtoQueryResult>> {
            const localVarAxiosArgs = await LiveTvApiAxiosParamCreator(configuration).getRecordingFolders(userId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get recording group.
         * @param {string} groupId Group id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRecordingGroup(groupId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await LiveTvApiAxiosParamCreator(configuration).getRecordingGroup(groupId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Gets live tv recording groups.
         * @param {string} [userId] Optional. Filter by user and attach user data.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRecordingGroups(userId?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BaseItemDtoQueryResult>> {
            const localVarAxiosArgs = await LiveTvApiAxiosParamCreator(configuration).getRecordingGroups(userId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Gets live tv recordings.
         * @param {string} [channelId] Optional. Filter by channel id.
         * @param {string} [userId] Optional. Filter by user and attach user data.
         * @param {number} [startIndex] Optional. The record index to start at. All items with a lower index will be dropped from the results.
         * @param {number} [limit] Optional. The maximum number of records to return.
         * @param {RecordingStatus} [status] Optional. Filter by recording status.
         * @param {boolean} [isInProgress] Optional. Filter by recordings that are in progress, or not.
         * @param {string} [seriesTimerId] Optional. Filter by recordings belonging to a series timer.
         * @param {boolean} [enableImages] Optional. Include image information in output.
         * @param {number} [imageTypeLimit] Optional. The max number of images to return, per image type.
         * @param {Array<ImageType>} [enableImageTypes] Optional. The image types to include in the output.
         * @param {string} [fields] Optional. Specify additional fields of information to return in the output. This allows multiple, comma delimited. Options: Budget, Chapters, DateCreated, Genres, HomePageUrl, IndexOptions, MediaStreams, Overview, ParentId, Path, People, ProviderIds, PrimaryImageAspectRatio, Revenue, SortName, Studios, Taglines.
         * @param {boolean} [enableUserData] Optional. Include user data.
         * @param {boolean} [isMovie] Optional. Filter for movies.
         * @param {boolean} [isSeries] Optional. Filter for series.
         * @param {boolean} [isKids] Optional. Filter for kids.
         * @param {boolean} [isSports] Optional. Filter for sports.
         * @param {boolean} [isNews] Optional. Filter for news.
         * @param {boolean} [isLibraryItem] Optional. Filter for is library item.
         * @param {boolean} [enableTotalRecordCount] Optional. Return total record count.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRecordings(channelId?: string, userId?: string, startIndex?: number, limit?: number, status?: RecordingStatus, isInProgress?: boolean, seriesTimerId?: string, enableImages?: boolean, imageTypeLimit?: number, enableImageTypes?: Array<ImageType>, fields?: string, enableUserData?: boolean, isMovie?: boolean, isSeries?: boolean, isKids?: boolean, isSports?: boolean, isNews?: boolean, isLibraryItem?: boolean, enableTotalRecordCount?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BaseItemDtoQueryResult>> {
            const localVarAxiosArgs = await LiveTvApiAxiosParamCreator(configuration).getRecordings(channelId, userId, startIndex, limit, status, isInProgress, seriesTimerId, enableImages, imageTypeLimit, enableImageTypes, fields, enableUserData, isMovie, isSeries, isKids, isSports, isNews, isLibraryItem, enableTotalRecordCount, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Gets live tv recording series.
         * @param {string} [channelId] Optional. Filter by channel id.
         * @param {string} [userId] Optional. Filter by user and attach user data.
         * @param {string} [groupId] Optional. Filter by recording group.
         * @param {number} [startIndex] Optional. The record index to start at. All items with a lower index will be dropped from the results.
         * @param {number} [limit] Optional. The maximum number of records to return.
         * @param {RecordingStatus} [status] Optional. Filter by recording status.
         * @param {boolean} [isInProgress] Optional. Filter by recordings that are in progress, or not.
         * @param {string} [seriesTimerId] Optional. Filter by recordings belonging to a series timer.
         * @param {boolean} [enableImages] Optional. Include image information in output.
         * @param {number} [imageTypeLimit] Optional. The max number of images to return, per image type.
         * @param {Array<ImageType>} [enableImageTypes] Optional. The image types to include in the output.
         * @param {string} [fields] Optional. Specify additional fields of information to return in the output. This allows multiple, comma delimited. Options: Budget, Chapters, DateCreated, Genres, HomePageUrl, IndexOptions, MediaStreams, Overview, ParentId, Path, People, ProviderIds, PrimaryImageAspectRatio, Revenue, SortName, Studios, Taglines.
         * @param {boolean} [enableUserData] Optional. Include user data.
         * @param {boolean} [enableTotalRecordCount] Optional. Return total record count.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRecordingsSeries(channelId?: string, userId?: string, groupId?: string, startIndex?: number, limit?: number, status?: RecordingStatus, isInProgress?: boolean, seriesTimerId?: string, enableImages?: boolean, imageTypeLimit?: number, enableImageTypes?: Array<ImageType>, fields?: string, enableUserData?: boolean, enableTotalRecordCount?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BaseItemDtoQueryResult>> {
            const localVarAxiosArgs = await LiveTvApiAxiosParamCreator(configuration).getRecordingsSeries(channelId, userId, groupId, startIndex, limit, status, isInProgress, seriesTimerId, enableImages, imageTypeLimit, enableImageTypes, fields, enableUserData, enableTotalRecordCount, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Gets available countries.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSchedulesDirectCountries(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await LiveTvApiAxiosParamCreator(configuration).getSchedulesDirectCountries(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Gets a live tv series timer.
         * @param {string} timerId Timer id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSeriesTimer(timerId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SeriesTimerInfoDto>> {
            const localVarAxiosArgs = await LiveTvApiAxiosParamCreator(configuration).getSeriesTimer(timerId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Gets live tv series timers.
         * @param {string} [sortBy] Optional. Sort by SortName or Priority.
         * @param {SortOrder} [sortOrder] Optional. Sort in Ascending or Descending order.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSeriesTimers(sortBy?: string, sortOrder?: SortOrder, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SeriesTimerInfoDtoQueryResult>> {
            const localVarAxiosArgs = await LiveTvApiAxiosParamCreator(configuration).getSeriesTimers(sortBy, sortOrder, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Gets a timer.
         * @param {string} timerId Timer id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTimer(timerId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TimerInfoDto>> {
            const localVarAxiosArgs = await LiveTvApiAxiosParamCreator(configuration).getTimer(timerId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Gets the live tv timers.
         * @param {string} [channelId] Optional. Filter by channel id.
         * @param {string} [seriesTimerId] Optional. Filter by timers belonging to a series timer.
         * @param {boolean} [isActive] Optional. Filter by timers that are active.
         * @param {boolean} [isScheduled] Optional. Filter by timers that are scheduled.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTimers(channelId?: string, seriesTimerId?: string, isActive?: boolean, isScheduled?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TimerInfoDtoQueryResult>> {
            const localVarAxiosArgs = await LiveTvApiAxiosParamCreator(configuration).getTimers(channelId, seriesTimerId, isActive, isScheduled, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get tuner host types.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTunerHostTypes(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<NameIdPair>>> {
            const localVarAxiosArgs = await LiveTvApiAxiosParamCreator(configuration).getTunerHostTypes(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Resets a tv tuner.
         * @param {string} tunerId Tuner id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resetTuner(tunerId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await LiveTvApiAxiosParamCreator(configuration).resetTuner(tunerId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Set channel mappings.
         * @param {string} [providerId] Provider id.
         * @param {string} [tunerChannelId] Tuner channel id.
         * @param {string} [providerChannelId] Provider channel id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setChannelMapping(providerId?: string, tunerChannelId?: string, providerChannelId?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TunerChannelMapping>> {
            const localVarAxiosArgs = await LiveTvApiAxiosParamCreator(configuration).setChannelMapping(providerId, tunerChannelId, providerChannelId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Updates a live tv series timer.
         * @param {string} timerId Timer id.
         * @param {SeriesTimerInfoDto} [seriesTimerInfoDto] New series timer info.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSeriesTimer(timerId: string, seriesTimerInfoDto?: SeriesTimerInfoDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await LiveTvApiAxiosParamCreator(configuration).updateSeriesTimer(timerId, seriesTimerInfoDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Updates a live tv timer.
         * @param {string} timerId Timer id.
         * @param {TimerInfoDto} [timerInfoDto] New timer info.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateTimer(timerId: string, timerInfoDto?: TimerInfoDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await LiveTvApiAxiosParamCreator(configuration).updateTimer(timerId, timerInfoDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * LiveTvApi - factory interface
 * @export
 */
export const LiveTvApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Adds a listings provider.
         * @param {string} [pw] Password.
         * @param {boolean} [validateListings] Validate listings.
         * @param {boolean} [validateLogin] Validate login.
         * @param {ListingsProviderInfo} [listingsProviderInfo] New listings info.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addListingProvider(pw?: string, validateListings?: boolean, validateLogin?: boolean, listingsProviderInfo?: ListingsProviderInfo, options?: any): AxiosPromise<ListingsProviderInfo> {
            return LiveTvApiFp(configuration).addListingProvider(pw, validateListings, validateLogin, listingsProviderInfo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Adds a tuner host.
         * @param {TunerHostInfo} [tunerHostInfo] New tuner host.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addTunerHost(tunerHostInfo?: TunerHostInfo, options?: any): AxiosPromise<TunerHostInfo> {
            return LiveTvApiFp(configuration).addTunerHost(tunerHostInfo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Cancels a live tv series timer.
         * @param {string} timerId Timer id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelSeriesTimer(timerId: string, options?: any): AxiosPromise<void> {
            return LiveTvApiFp(configuration).cancelSeriesTimer(timerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Cancels a live tv timer.
         * @param {string} timerId Timer id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelTimer(timerId: string, options?: any): AxiosPromise<void> {
            return LiveTvApiFp(configuration).cancelTimer(timerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates a live tv series timer.
         * @param {SeriesTimerInfoDto} [seriesTimerInfoDto] New series timer info.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSeriesTimer(seriesTimerInfoDto?: SeriesTimerInfoDto, options?: any): AxiosPromise<void> {
            return LiveTvApiFp(configuration).createSeriesTimer(seriesTimerInfoDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates a live tv timer.
         * @param {TimerInfoDto} [timerInfoDto] New timer info.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTimer(timerInfoDto?: TimerInfoDto, options?: any): AxiosPromise<void> {
            return LiveTvApiFp(configuration).createTimer(timerInfoDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete listing provider.
         * @param {string} [id] Listing provider id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteListingProvider(id?: string, options?: any): AxiosPromise<void> {
            return LiveTvApiFp(configuration).deleteListingProvider(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Deletes a live tv recording.
         * @param {string} recordingId Recording id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRecording(recordingId: string, options?: any): AxiosPromise<void> {
            return LiveTvApiFp(configuration).deleteRecording(recordingId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Deletes a tuner host.
         * @param {string} [id] Tuner host id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTunerHost(id?: string, options?: any): AxiosPromise<void> {
            return LiveTvApiFp(configuration).deleteTunerHost(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Discover tuners.
         * @param {boolean} [newDevicesOnly] Only discover new tuners.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        discoverTuners(newDevicesOnly?: boolean, options?: any): AxiosPromise<Array<TunerHostInfo>> {
            return LiveTvApiFp(configuration).discoverTuners(newDevicesOnly, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets a live tv channel.
         * @param {string} channelId Channel id.
         * @param {string} [userId] Optional. Attach user data.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChannel(channelId: string, userId?: string, options?: any): AxiosPromise<BaseItemDto> {
            return LiveTvApiFp(configuration).getChannel(channelId, userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get channel mapping options.
         * @param {string} [providerId] Provider id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChannelMappingOptions(providerId?: string, options?: any): AxiosPromise<ChannelMappingOptionsDto> {
            return LiveTvApiFp(configuration).getChannelMappingOptions(providerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets default listings provider info.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDefaultListingProvider(options?: any): AxiosPromise<ListingsProviderInfo> {
            return LiveTvApiFp(configuration).getDefaultListingProvider(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets the default values for a new timer.
         * @param {string} [programId] Optional. To attach default values based on a program.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDefaultTimer(programId?: string, options?: any): AxiosPromise<SeriesTimerInfoDto> {
            return LiveTvApiFp(configuration).getDefaultTimer(programId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get guid info.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGuideInfo(options?: any): AxiosPromise<GuideInfo> {
            return LiveTvApiFp(configuration).getGuideInfo(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets available lineups.
         * @param {string} [id] Provider id.
         * @param {string} [type] Provider type.
         * @param {string} [location] Location.
         * @param {string} [country] Country.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLineups(id?: string, type?: string, location?: string, country?: string, options?: any): AxiosPromise<Array<NameIdPair>> {
            return LiveTvApiFp(configuration).getLineups(id, type, location, country, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets a live tv recording stream.
         * @param {string} recordingId Recording id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLiveRecordingFile(recordingId: string, options?: any): AxiosPromise<any> {
            return LiveTvApiFp(configuration).getLiveRecordingFile(recordingId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets a live tv channel stream.
         * @param {string} streamId Stream id.
         * @param {string} container Container type.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLiveStreamFile(streamId: string, container: string, options?: any): AxiosPromise<any> {
            return LiveTvApiFp(configuration).getLiveStreamFile(streamId, container, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets available live tv channels.
         * @param {ChannelType} [type] Optional. Filter by channel type.
         * @param {string} [userId] Optional. Filter by user and attach user data.
         * @param {number} [startIndex] Optional. The record index to start at. All items with a lower index will be dropped from the results.
         * @param {boolean} [isMovie] Optional. Filter for movies.
         * @param {boolean} [isSeries] Optional. Filter for series.
         * @param {boolean} [isNews] Optional. Filter for news.
         * @param {boolean} [isKids] Optional. Filter for kids.
         * @param {boolean} [isSports] Optional. Filter for sports.
         * @param {number} [limit] Optional. The maximum number of records to return.
         * @param {boolean} [isFavorite] Optional. Filter by channels that are favorites, or not.
         * @param {boolean} [isLiked] Optional. Filter by channels that are liked, or not.
         * @param {boolean} [isDisliked] Optional. Filter by channels that are disliked, or not.
         * @param {boolean} [enableImages] Optional. Include image information in output.
         * @param {number} [imageTypeLimit] Optional. The max number of images to return, per image type.
         * @param {Array<ImageType>} [enableImageTypes] \&quot;Optional. The image types to include in the output.
         * @param {string} [fields] Optional. Specify additional fields of information to return in the output. This allows multiple, comma delimited. Options: Budget, Chapters, DateCreated, Genres, HomePageUrl, IndexOptions, MediaStreams, Overview, ParentId, Path, People, ProviderIds, PrimaryImageAspectRatio, Revenue, SortName, Studios, Taglines.
         * @param {boolean} [enableUserData] Optional. Include user data.
         * @param {string} [sortBy] Optional. Key to sort by.
         * @param {SortOrder} [sortOrder] Optional. Sort order.
         * @param {boolean} [enableFavoriteSorting] Optional. Incorporate favorite and like status into channel sorting.
         * @param {boolean} [addCurrentProgram] Optional. Adds current program info to each channel.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLiveTvChannels(type?: ChannelType, userId?: string, startIndex?: number, isMovie?: boolean, isSeries?: boolean, isNews?: boolean, isKids?: boolean, isSports?: boolean, limit?: number, isFavorite?: boolean, isLiked?: boolean, isDisliked?: boolean, enableImages?: boolean, imageTypeLimit?: number, enableImageTypes?: Array<ImageType>, fields?: string, enableUserData?: boolean, sortBy?: string, sortOrder?: SortOrder, enableFavoriteSorting?: boolean, addCurrentProgram?: boolean, options?: any): AxiosPromise<BaseItemDtoQueryResult> {
            return LiveTvApiFp(configuration).getLiveTvChannels(type, userId, startIndex, isMovie, isSeries, isNews, isKids, isSports, limit, isFavorite, isLiked, isDisliked, enableImages, imageTypeLimit, enableImageTypes, fields, enableUserData, sortBy, sortOrder, enableFavoriteSorting, addCurrentProgram, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets available live tv services.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLiveTvInfo(options?: any): AxiosPromise<LiveTvInfo> {
            return LiveTvApiFp(configuration).getLiveTvInfo(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets available live tv epgs.
         * @param {string} [channelIds] The channels to return guide information for.
         * @param {string} [userId] Optional. Filter by user id.
         * @param {string} [minStartDate] Optional. The minimum premiere start date.
         * @param {boolean} [hasAired] Optional. Filter by programs that have completed airing, or not.
         * @param {boolean} [isAiring] Optional. Filter by programs that are currently airing, or not.
         * @param {string} [maxStartDate] Optional. The maximum premiere start date.
         * @param {string} [minEndDate] Optional. The minimum premiere end date.
         * @param {string} [maxEndDate] Optional. The maximum premiere end date.
         * @param {boolean} [isMovie] Optional. Filter for movies.
         * @param {boolean} [isSeries] Optional. Filter for series.
         * @param {boolean} [isNews] Optional. Filter for news.
         * @param {boolean} [isKids] Optional. Filter for kids.
         * @param {boolean} [isSports] Optional. Filter for sports.
         * @param {number} [startIndex] Optional. The record index to start at. All items with a lower index will be dropped from the results.
         * @param {number} [limit] Optional. The maximum number of records to return.
         * @param {string} [sortBy] Optional. Specify one or more sort orders, comma delimited. Options: Name, StartDate.
         * @param {string} [sortOrder] Sort Order - Ascending,Descending.
         * @param {string} [genres] The genres to return guide information for.
         * @param {string} [genreIds] The genre ids to return guide information for.
         * @param {boolean} [enableImages] Optional. Include image information in output.
         * @param {number} [imageTypeLimit] Optional. The max number of images to return, per image type.
         * @param {Array<ImageType>} [enableImageTypes] Optional. The image types to include in the output.
         * @param {boolean} [enableUserData] Optional. Include user data.
         * @param {string} [seriesTimerId] Optional. Filter by series timer id.
         * @param {string} [librarySeriesId] Optional. Filter by library series id.
         * @param {string} [fields] Optional. Specify additional fields of information to return in the output. This allows multiple, comma delimited. Options: Budget, Chapters, DateCreated, Genres, HomePageUrl, IndexOptions, MediaStreams, Overview, ParentId, Path, People, ProviderIds, PrimaryImageAspectRatio, Revenue, SortName, Studios, Taglines.
         * @param {boolean} [enableTotalRecordCount] Retrieve total record count.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLiveTvPrograms(channelIds?: string, userId?: string, minStartDate?: string, hasAired?: boolean, isAiring?: boolean, maxStartDate?: string, minEndDate?: string, maxEndDate?: string, isMovie?: boolean, isSeries?: boolean, isNews?: boolean, isKids?: boolean, isSports?: boolean, startIndex?: number, limit?: number, sortBy?: string, sortOrder?: string, genres?: string, genreIds?: string, enableImages?: boolean, imageTypeLimit?: number, enableImageTypes?: Array<ImageType>, enableUserData?: boolean, seriesTimerId?: string, librarySeriesId?: string, fields?: string, enableTotalRecordCount?: boolean, options?: any): AxiosPromise<BaseItemDtoQueryResult> {
            return LiveTvApiFp(configuration).getLiveTvPrograms(channelIds, userId, minStartDate, hasAired, isAiring, maxStartDate, minEndDate, maxEndDate, isMovie, isSeries, isNews, isKids, isSports, startIndex, limit, sortBy, sortOrder, genres, genreIds, enableImages, imageTypeLimit, enableImageTypes, enableUserData, seriesTimerId, librarySeriesId, fields, enableTotalRecordCount, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets a live tv program.
         * @param {string} programId Program id.
         * @param {string} [userId] Optional. Attach user data.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProgram(programId: string, userId?: string, options?: any): AxiosPromise<BaseItemDto> {
            return LiveTvApiFp(configuration).getProgram(programId, userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets available live tv epgs.
         * @param {GetProgramsDto} [getProgramsDto] Request body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPrograms(getProgramsDto?: GetProgramsDto, options?: any): AxiosPromise<BaseItemDtoQueryResult> {
            return LiveTvApiFp(configuration).getPrograms(getProgramsDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets recommended live tv epgs.
         * @param {string} [userId] Optional. filter by user id.
         * @param {number} [limit] Optional. The maximum number of records to return.
         * @param {boolean} [isAiring] Optional. Filter by programs that are currently airing, or not.
         * @param {boolean} [hasAired] Optional. Filter by programs that have completed airing, or not.
         * @param {boolean} [isSeries] Optional. Filter for series.
         * @param {boolean} [isMovie] Optional. Filter for movies.
         * @param {boolean} [isNews] Optional. Filter for news.
         * @param {boolean} [isKids] Optional. Filter for kids.
         * @param {boolean} [isSports] Optional. Filter for sports.
         * @param {boolean} [enableImages] Optional. Include image information in output.
         * @param {number} [imageTypeLimit] Optional. The max number of images to return, per image type.
         * @param {Array<ImageType>} [enableImageTypes] Optional. The image types to include in the output.
         * @param {string} [genreIds] The genres to return guide information for.
         * @param {string} [fields] Optional. Specify additional fields of information to return in the output. This allows multiple, comma delimited. Options: Budget, Chapters, DateCreated, Genres, HomePageUrl, IndexOptions, MediaStreams, Overview, ParentId, Path, People, ProviderIds, PrimaryImageAspectRatio, Revenue, SortName, Studios, Taglines.
         * @param {boolean} [enableUserData] Optional. include user data.
         * @param {boolean} [enableTotalRecordCount] Retrieve total record count.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRecommendedPrograms(userId?: string, limit?: number, isAiring?: boolean, hasAired?: boolean, isSeries?: boolean, isMovie?: boolean, isNews?: boolean, isKids?: boolean, isSports?: boolean, enableImages?: boolean, imageTypeLimit?: number, enableImageTypes?: Array<ImageType>, genreIds?: string, fields?: string, enableUserData?: boolean, enableTotalRecordCount?: boolean, options?: any): AxiosPromise<BaseItemDtoQueryResult> {
            return LiveTvApiFp(configuration).getRecommendedPrograms(userId, limit, isAiring, hasAired, isSeries, isMovie, isNews, isKids, isSports, enableImages, imageTypeLimit, enableImageTypes, genreIds, fields, enableUserData, enableTotalRecordCount, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets a live tv recording.
         * @param {string} recordingId Recording id.
         * @param {string} [userId] Optional. Attach user data.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRecording(recordingId: string, userId?: string, options?: any): AxiosPromise<BaseItemDto> {
            return LiveTvApiFp(configuration).getRecording(recordingId, userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets recording folders.
         * @param {string} [userId] Optional. Filter by user and attach user data.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRecordingFolders(userId?: string, options?: any): AxiosPromise<BaseItemDtoQueryResult> {
            return LiveTvApiFp(configuration).getRecordingFolders(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get recording group.
         * @param {string} groupId Group id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRecordingGroup(groupId: string, options?: any): AxiosPromise<void> {
            return LiveTvApiFp(configuration).getRecordingGroup(groupId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets live tv recording groups.
         * @param {string} [userId] Optional. Filter by user and attach user data.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRecordingGroups(userId?: string, options?: any): AxiosPromise<BaseItemDtoQueryResult> {
            return LiveTvApiFp(configuration).getRecordingGroups(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets live tv recordings.
         * @param {string} [channelId] Optional. Filter by channel id.
         * @param {string} [userId] Optional. Filter by user and attach user data.
         * @param {number} [startIndex] Optional. The record index to start at. All items with a lower index will be dropped from the results.
         * @param {number} [limit] Optional. The maximum number of records to return.
         * @param {RecordingStatus} [status] Optional. Filter by recording status.
         * @param {boolean} [isInProgress] Optional. Filter by recordings that are in progress, or not.
         * @param {string} [seriesTimerId] Optional. Filter by recordings belonging to a series timer.
         * @param {boolean} [enableImages] Optional. Include image information in output.
         * @param {number} [imageTypeLimit] Optional. The max number of images to return, per image type.
         * @param {Array<ImageType>} [enableImageTypes] Optional. The image types to include in the output.
         * @param {string} [fields] Optional. Specify additional fields of information to return in the output. This allows multiple, comma delimited. Options: Budget, Chapters, DateCreated, Genres, HomePageUrl, IndexOptions, MediaStreams, Overview, ParentId, Path, People, ProviderIds, PrimaryImageAspectRatio, Revenue, SortName, Studios, Taglines.
         * @param {boolean} [enableUserData] Optional. Include user data.
         * @param {boolean} [isMovie] Optional. Filter for movies.
         * @param {boolean} [isSeries] Optional. Filter for series.
         * @param {boolean} [isKids] Optional. Filter for kids.
         * @param {boolean} [isSports] Optional. Filter for sports.
         * @param {boolean} [isNews] Optional. Filter for news.
         * @param {boolean} [isLibraryItem] Optional. Filter for is library item.
         * @param {boolean} [enableTotalRecordCount] Optional. Return total record count.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRecordings(channelId?: string, userId?: string, startIndex?: number, limit?: number, status?: RecordingStatus, isInProgress?: boolean, seriesTimerId?: string, enableImages?: boolean, imageTypeLimit?: number, enableImageTypes?: Array<ImageType>, fields?: string, enableUserData?: boolean, isMovie?: boolean, isSeries?: boolean, isKids?: boolean, isSports?: boolean, isNews?: boolean, isLibraryItem?: boolean, enableTotalRecordCount?: boolean, options?: any): AxiosPromise<BaseItemDtoQueryResult> {
            return LiveTvApiFp(configuration).getRecordings(channelId, userId, startIndex, limit, status, isInProgress, seriesTimerId, enableImages, imageTypeLimit, enableImageTypes, fields, enableUserData, isMovie, isSeries, isKids, isSports, isNews, isLibraryItem, enableTotalRecordCount, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets live tv recording series.
         * @param {string} [channelId] Optional. Filter by channel id.
         * @param {string} [userId] Optional. Filter by user and attach user data.
         * @param {string} [groupId] Optional. Filter by recording group.
         * @param {number} [startIndex] Optional. The record index to start at. All items with a lower index will be dropped from the results.
         * @param {number} [limit] Optional. The maximum number of records to return.
         * @param {RecordingStatus} [status] Optional. Filter by recording status.
         * @param {boolean} [isInProgress] Optional. Filter by recordings that are in progress, or not.
         * @param {string} [seriesTimerId] Optional. Filter by recordings belonging to a series timer.
         * @param {boolean} [enableImages] Optional. Include image information in output.
         * @param {number} [imageTypeLimit] Optional. The max number of images to return, per image type.
         * @param {Array<ImageType>} [enableImageTypes] Optional. The image types to include in the output.
         * @param {string} [fields] Optional. Specify additional fields of information to return in the output. This allows multiple, comma delimited. Options: Budget, Chapters, DateCreated, Genres, HomePageUrl, IndexOptions, MediaStreams, Overview, ParentId, Path, People, ProviderIds, PrimaryImageAspectRatio, Revenue, SortName, Studios, Taglines.
         * @param {boolean} [enableUserData] Optional. Include user data.
         * @param {boolean} [enableTotalRecordCount] Optional. Return total record count.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRecordingsSeries(channelId?: string, userId?: string, groupId?: string, startIndex?: number, limit?: number, status?: RecordingStatus, isInProgress?: boolean, seriesTimerId?: string, enableImages?: boolean, imageTypeLimit?: number, enableImageTypes?: Array<ImageType>, fields?: string, enableUserData?: boolean, enableTotalRecordCount?: boolean, options?: any): AxiosPromise<BaseItemDtoQueryResult> {
            return LiveTvApiFp(configuration).getRecordingsSeries(channelId, userId, groupId, startIndex, limit, status, isInProgress, seriesTimerId, enableImages, imageTypeLimit, enableImageTypes, fields, enableUserData, enableTotalRecordCount, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets available countries.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSchedulesDirectCountries(options?: any): AxiosPromise<any> {
            return LiveTvApiFp(configuration).getSchedulesDirectCountries(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets a live tv series timer.
         * @param {string} timerId Timer id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSeriesTimer(timerId: string, options?: any): AxiosPromise<SeriesTimerInfoDto> {
            return LiveTvApiFp(configuration).getSeriesTimer(timerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets live tv series timers.
         * @param {string} [sortBy] Optional. Sort by SortName or Priority.
         * @param {SortOrder} [sortOrder] Optional. Sort in Ascending or Descending order.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSeriesTimers(sortBy?: string, sortOrder?: SortOrder, options?: any): AxiosPromise<SeriesTimerInfoDtoQueryResult> {
            return LiveTvApiFp(configuration).getSeriesTimers(sortBy, sortOrder, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets a timer.
         * @param {string} timerId Timer id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTimer(timerId: string, options?: any): AxiosPromise<TimerInfoDto> {
            return LiveTvApiFp(configuration).getTimer(timerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets the live tv timers.
         * @param {string} [channelId] Optional. Filter by channel id.
         * @param {string} [seriesTimerId] Optional. Filter by timers belonging to a series timer.
         * @param {boolean} [isActive] Optional. Filter by timers that are active.
         * @param {boolean} [isScheduled] Optional. Filter by timers that are scheduled.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTimers(channelId?: string, seriesTimerId?: string, isActive?: boolean, isScheduled?: boolean, options?: any): AxiosPromise<TimerInfoDtoQueryResult> {
            return LiveTvApiFp(configuration).getTimers(channelId, seriesTimerId, isActive, isScheduled, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get tuner host types.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTunerHostTypes(options?: any): AxiosPromise<Array<NameIdPair>> {
            return LiveTvApiFp(configuration).getTunerHostTypes(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Resets a tv tuner.
         * @param {string} tunerId Tuner id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetTuner(tunerId: string, options?: any): AxiosPromise<void> {
            return LiveTvApiFp(configuration).resetTuner(tunerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Set channel mappings.
         * @param {string} [providerId] Provider id.
         * @param {string} [tunerChannelId] Tuner channel id.
         * @param {string} [providerChannelId] Provider channel id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setChannelMapping(providerId?: string, tunerChannelId?: string, providerChannelId?: string, options?: any): AxiosPromise<TunerChannelMapping> {
            return LiveTvApiFp(configuration).setChannelMapping(providerId, tunerChannelId, providerChannelId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates a live tv series timer.
         * @param {string} timerId Timer id.
         * @param {SeriesTimerInfoDto} [seriesTimerInfoDto] New series timer info.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSeriesTimer(timerId: string, seriesTimerInfoDto?: SeriesTimerInfoDto, options?: any): AxiosPromise<void> {
            return LiveTvApiFp(configuration).updateSeriesTimer(timerId, seriesTimerInfoDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates a live tv timer.
         * @param {string} timerId Timer id.
         * @param {TimerInfoDto} [timerInfoDto] New timer info.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTimer(timerId: string, timerInfoDto?: TimerInfoDto, options?: any): AxiosPromise<void> {
            return LiveTvApiFp(configuration).updateTimer(timerId, timerInfoDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for addListingProvider operation in LiveTvApi.
 * @export
 * @interface LiveTvApiAddListingProviderRequest
 */
export interface LiveTvApiAddListingProviderRequest {
    /**
     * Password.
     * @type {string}
     * @memberof LiveTvApiAddListingProvider
     */
    readonly pw?: string

    /**
     * Validate listings.
     * @type {boolean}
     * @memberof LiveTvApiAddListingProvider
     */
    readonly validateListings?: boolean

    /**
     * Validate login.
     * @type {boolean}
     * @memberof LiveTvApiAddListingProvider
     */
    readonly validateLogin?: boolean

    /**
     * New listings info.
     * @type {ListingsProviderInfo}
     * @memberof LiveTvApiAddListingProvider
     */
    readonly listingsProviderInfo?: ListingsProviderInfo
}

/**
 * Request parameters for addTunerHost operation in LiveTvApi.
 * @export
 * @interface LiveTvApiAddTunerHostRequest
 */
export interface LiveTvApiAddTunerHostRequest {
    /**
     * New tuner host.
     * @type {TunerHostInfo}
     * @memberof LiveTvApiAddTunerHost
     */
    readonly tunerHostInfo?: TunerHostInfo
}

/**
 * Request parameters for cancelSeriesTimer operation in LiveTvApi.
 * @export
 * @interface LiveTvApiCancelSeriesTimerRequest
 */
export interface LiveTvApiCancelSeriesTimerRequest {
    /**
     * Timer id.
     * @type {string}
     * @memberof LiveTvApiCancelSeriesTimer
     */
    readonly timerId: string
}

/**
 * Request parameters for cancelTimer operation in LiveTvApi.
 * @export
 * @interface LiveTvApiCancelTimerRequest
 */
export interface LiveTvApiCancelTimerRequest {
    /**
     * Timer id.
     * @type {string}
     * @memberof LiveTvApiCancelTimer
     */
    readonly timerId: string
}

/**
 * Request parameters for createSeriesTimer operation in LiveTvApi.
 * @export
 * @interface LiveTvApiCreateSeriesTimerRequest
 */
export interface LiveTvApiCreateSeriesTimerRequest {
    /**
     * New series timer info.
     * @type {SeriesTimerInfoDto}
     * @memberof LiveTvApiCreateSeriesTimer
     */
    readonly seriesTimerInfoDto?: SeriesTimerInfoDto
}

/**
 * Request parameters for createTimer operation in LiveTvApi.
 * @export
 * @interface LiveTvApiCreateTimerRequest
 */
export interface LiveTvApiCreateTimerRequest {
    /**
     * New timer info.
     * @type {TimerInfoDto}
     * @memberof LiveTvApiCreateTimer
     */
    readonly timerInfoDto?: TimerInfoDto
}

/**
 * Request parameters for deleteListingProvider operation in LiveTvApi.
 * @export
 * @interface LiveTvApiDeleteListingProviderRequest
 */
export interface LiveTvApiDeleteListingProviderRequest {
    /**
     * Listing provider id.
     * @type {string}
     * @memberof LiveTvApiDeleteListingProvider
     */
    readonly id?: string
}

/**
 * Request parameters for deleteRecording operation in LiveTvApi.
 * @export
 * @interface LiveTvApiDeleteRecordingRequest
 */
export interface LiveTvApiDeleteRecordingRequest {
    /**
     * Recording id.
     * @type {string}
     * @memberof LiveTvApiDeleteRecording
     */
    readonly recordingId: string
}

/**
 * Request parameters for deleteTunerHost operation in LiveTvApi.
 * @export
 * @interface LiveTvApiDeleteTunerHostRequest
 */
export interface LiveTvApiDeleteTunerHostRequest {
    /**
     * Tuner host id.
     * @type {string}
     * @memberof LiveTvApiDeleteTunerHost
     */
    readonly id?: string
}

/**
 * Request parameters for discoverTuners operation in LiveTvApi.
 * @export
 * @interface LiveTvApiDiscoverTunersRequest
 */
export interface LiveTvApiDiscoverTunersRequest {
    /**
     * Only discover new tuners.
     * @type {boolean}
     * @memberof LiveTvApiDiscoverTuners
     */
    readonly newDevicesOnly?: boolean
}

/**
 * Request parameters for getChannel operation in LiveTvApi.
 * @export
 * @interface LiveTvApiGetChannelRequest
 */
export interface LiveTvApiGetChannelRequest {
    /**
     * Channel id.
     * @type {string}
     * @memberof LiveTvApiGetChannel
     */
    readonly channelId: string

    /**
     * Optional. Attach user data.
     * @type {string}
     * @memberof LiveTvApiGetChannel
     */
    readonly userId?: string
}

/**
 * Request parameters for getChannelMappingOptions operation in LiveTvApi.
 * @export
 * @interface LiveTvApiGetChannelMappingOptionsRequest
 */
export interface LiveTvApiGetChannelMappingOptionsRequest {
    /**
     * Provider id.
     * @type {string}
     * @memberof LiveTvApiGetChannelMappingOptions
     */
    readonly providerId?: string
}

/**
 * Request parameters for getDefaultTimer operation in LiveTvApi.
 * @export
 * @interface LiveTvApiGetDefaultTimerRequest
 */
export interface LiveTvApiGetDefaultTimerRequest {
    /**
     * Optional. To attach default values based on a program.
     * @type {string}
     * @memberof LiveTvApiGetDefaultTimer
     */
    readonly programId?: string
}

/**
 * Request parameters for getLineups operation in LiveTvApi.
 * @export
 * @interface LiveTvApiGetLineupsRequest
 */
export interface LiveTvApiGetLineupsRequest {
    /**
     * Provider id.
     * @type {string}
     * @memberof LiveTvApiGetLineups
     */
    readonly id?: string

    /**
     * Provider type.
     * @type {string}
     * @memberof LiveTvApiGetLineups
     */
    readonly type?: string

    /**
     * Location.
     * @type {string}
     * @memberof LiveTvApiGetLineups
     */
    readonly location?: string

    /**
     * Country.
     * @type {string}
     * @memberof LiveTvApiGetLineups
     */
    readonly country?: string
}

/**
 * Request parameters for getLiveRecordingFile operation in LiveTvApi.
 * @export
 * @interface LiveTvApiGetLiveRecordingFileRequest
 */
export interface LiveTvApiGetLiveRecordingFileRequest {
    /**
     * Recording id.
     * @type {string}
     * @memberof LiveTvApiGetLiveRecordingFile
     */
    readonly recordingId: string
}

/**
 * Request parameters for getLiveStreamFile operation in LiveTvApi.
 * @export
 * @interface LiveTvApiGetLiveStreamFileRequest
 */
export interface LiveTvApiGetLiveStreamFileRequest {
    /**
     * Stream id.
     * @type {string}
     * @memberof LiveTvApiGetLiveStreamFile
     */
    readonly streamId: string

    /**
     * Container type.
     * @type {string}
     * @memberof LiveTvApiGetLiveStreamFile
     */
    readonly container: string
}

/**
 * Request parameters for getLiveTvChannels operation in LiveTvApi.
 * @export
 * @interface LiveTvApiGetLiveTvChannelsRequest
 */
export interface LiveTvApiGetLiveTvChannelsRequest {
    /**
     * Optional. Filter by channel type.
     * @type {ChannelType}
     * @memberof LiveTvApiGetLiveTvChannels
     */
    readonly type?: ChannelType

    /**
     * Optional. Filter by user and attach user data.
     * @type {string}
     * @memberof LiveTvApiGetLiveTvChannels
     */
    readonly userId?: string

    /**
     * Optional. The record index to start at. All items with a lower index will be dropped from the results.
     * @type {number}
     * @memberof LiveTvApiGetLiveTvChannels
     */
    readonly startIndex?: number

    /**
     * Optional. Filter for movies.
     * @type {boolean}
     * @memberof LiveTvApiGetLiveTvChannels
     */
    readonly isMovie?: boolean

    /**
     * Optional. Filter for series.
     * @type {boolean}
     * @memberof LiveTvApiGetLiveTvChannels
     */
    readonly isSeries?: boolean

    /**
     * Optional. Filter for news.
     * @type {boolean}
     * @memberof LiveTvApiGetLiveTvChannels
     */
    readonly isNews?: boolean

    /**
     * Optional. Filter for kids.
     * @type {boolean}
     * @memberof LiveTvApiGetLiveTvChannels
     */
    readonly isKids?: boolean

    /**
     * Optional. Filter for sports.
     * @type {boolean}
     * @memberof LiveTvApiGetLiveTvChannels
     */
    readonly isSports?: boolean

    /**
     * Optional. The maximum number of records to return.
     * @type {number}
     * @memberof LiveTvApiGetLiveTvChannels
     */
    readonly limit?: number

    /**
     * Optional. Filter by channels that are favorites, or not.
     * @type {boolean}
     * @memberof LiveTvApiGetLiveTvChannels
     */
    readonly isFavorite?: boolean

    /**
     * Optional. Filter by channels that are liked, or not.
     * @type {boolean}
     * @memberof LiveTvApiGetLiveTvChannels
     */
    readonly isLiked?: boolean

    /**
     * Optional. Filter by channels that are disliked, or not.
     * @type {boolean}
     * @memberof LiveTvApiGetLiveTvChannels
     */
    readonly isDisliked?: boolean

    /**
     * Optional. Include image information in output.
     * @type {boolean}
     * @memberof LiveTvApiGetLiveTvChannels
     */
    readonly enableImages?: boolean

    /**
     * Optional. The max number of images to return, per image type.
     * @type {number}
     * @memberof LiveTvApiGetLiveTvChannels
     */
    readonly imageTypeLimit?: number

    /**
     * \&quot;Optional. The image types to include in the output.
     * @type {Array<ImageType>}
     * @memberof LiveTvApiGetLiveTvChannels
     */
    readonly enableImageTypes?: Array<ImageType>

    /**
     * Optional. Specify additional fields of information to return in the output. This allows multiple, comma delimited. Options: Budget, Chapters, DateCreated, Genres, HomePageUrl, IndexOptions, MediaStreams, Overview, ParentId, Path, People, ProviderIds, PrimaryImageAspectRatio, Revenue, SortName, Studios, Taglines.
     * @type {string}
     * @memberof LiveTvApiGetLiveTvChannels
     */
    readonly fields?: string

    /**
     * Optional. Include user data.
     * @type {boolean}
     * @memberof LiveTvApiGetLiveTvChannels
     */
    readonly enableUserData?: boolean

    /**
     * Optional. Key to sort by.
     * @type {string}
     * @memberof LiveTvApiGetLiveTvChannels
     */
    readonly sortBy?: string

    /**
     * Optional. Sort order.
     * @type {SortOrder}
     * @memberof LiveTvApiGetLiveTvChannels
     */
    readonly sortOrder?: SortOrder

    /**
     * Optional. Incorporate favorite and like status into channel sorting.
     * @type {boolean}
     * @memberof LiveTvApiGetLiveTvChannels
     */
    readonly enableFavoriteSorting?: boolean

    /**
     * Optional. Adds current program info to each channel.
     * @type {boolean}
     * @memberof LiveTvApiGetLiveTvChannels
     */
    readonly addCurrentProgram?: boolean
}

/**
 * Request parameters for getLiveTvPrograms operation in LiveTvApi.
 * @export
 * @interface LiveTvApiGetLiveTvProgramsRequest
 */
export interface LiveTvApiGetLiveTvProgramsRequest {
    /**
     * The channels to return guide information for.
     * @type {string}
     * @memberof LiveTvApiGetLiveTvPrograms
     */
    readonly channelIds?: string

    /**
     * Optional. Filter by user id.
     * @type {string}
     * @memberof LiveTvApiGetLiveTvPrograms
     */
    readonly userId?: string

    /**
     * Optional. The minimum premiere start date.
     * @type {string}
     * @memberof LiveTvApiGetLiveTvPrograms
     */
    readonly minStartDate?: string

    /**
     * Optional. Filter by programs that have completed airing, or not.
     * @type {boolean}
     * @memberof LiveTvApiGetLiveTvPrograms
     */
    readonly hasAired?: boolean

    /**
     * Optional. Filter by programs that are currently airing, or not.
     * @type {boolean}
     * @memberof LiveTvApiGetLiveTvPrograms
     */
    readonly isAiring?: boolean

    /**
     * Optional. The maximum premiere start date.
     * @type {string}
     * @memberof LiveTvApiGetLiveTvPrograms
     */
    readonly maxStartDate?: string

    /**
     * Optional. The minimum premiere end date.
     * @type {string}
     * @memberof LiveTvApiGetLiveTvPrograms
     */
    readonly minEndDate?: string

    /**
     * Optional. The maximum premiere end date.
     * @type {string}
     * @memberof LiveTvApiGetLiveTvPrograms
     */
    readonly maxEndDate?: string

    /**
     * Optional. Filter for movies.
     * @type {boolean}
     * @memberof LiveTvApiGetLiveTvPrograms
     */
    readonly isMovie?: boolean

    /**
     * Optional. Filter for series.
     * @type {boolean}
     * @memberof LiveTvApiGetLiveTvPrograms
     */
    readonly isSeries?: boolean

    /**
     * Optional. Filter for news.
     * @type {boolean}
     * @memberof LiveTvApiGetLiveTvPrograms
     */
    readonly isNews?: boolean

    /**
     * Optional. Filter for kids.
     * @type {boolean}
     * @memberof LiveTvApiGetLiveTvPrograms
     */
    readonly isKids?: boolean

    /**
     * Optional. Filter for sports.
     * @type {boolean}
     * @memberof LiveTvApiGetLiveTvPrograms
     */
    readonly isSports?: boolean

    /**
     * Optional. The record index to start at. All items with a lower index will be dropped from the results.
     * @type {number}
     * @memberof LiveTvApiGetLiveTvPrograms
     */
    readonly startIndex?: number

    /**
     * Optional. The maximum number of records to return.
     * @type {number}
     * @memberof LiveTvApiGetLiveTvPrograms
     */
    readonly limit?: number

    /**
     * Optional. Specify one or more sort orders, comma delimited. Options: Name, StartDate.
     * @type {string}
     * @memberof LiveTvApiGetLiveTvPrograms
     */
    readonly sortBy?: string

    /**
     * Sort Order - Ascending,Descending.
     * @type {string}
     * @memberof LiveTvApiGetLiveTvPrograms
     */
    readonly sortOrder?: string

    /**
     * The genres to return guide information for.
     * @type {string}
     * @memberof LiveTvApiGetLiveTvPrograms
     */
    readonly genres?: string

    /**
     * The genre ids to return guide information for.
     * @type {string}
     * @memberof LiveTvApiGetLiveTvPrograms
     */
    readonly genreIds?: string

    /**
     * Optional. Include image information in output.
     * @type {boolean}
     * @memberof LiveTvApiGetLiveTvPrograms
     */
    readonly enableImages?: boolean

    /**
     * Optional. The max number of images to return, per image type.
     * @type {number}
     * @memberof LiveTvApiGetLiveTvPrograms
     */
    readonly imageTypeLimit?: number

    /**
     * Optional. The image types to include in the output.
     * @type {Array<ImageType>}
     * @memberof LiveTvApiGetLiveTvPrograms
     */
    readonly enableImageTypes?: Array<ImageType>

    /**
     * Optional. Include user data.
     * @type {boolean}
     * @memberof LiveTvApiGetLiveTvPrograms
     */
    readonly enableUserData?: boolean

    /**
     * Optional. Filter by series timer id.
     * @type {string}
     * @memberof LiveTvApiGetLiveTvPrograms
     */
    readonly seriesTimerId?: string

    /**
     * Optional. Filter by library series id.
     * @type {string}
     * @memberof LiveTvApiGetLiveTvPrograms
     */
    readonly librarySeriesId?: string

    /**
     * Optional. Specify additional fields of information to return in the output. This allows multiple, comma delimited. Options: Budget, Chapters, DateCreated, Genres, HomePageUrl, IndexOptions, MediaStreams, Overview, ParentId, Path, People, ProviderIds, PrimaryImageAspectRatio, Revenue, SortName, Studios, Taglines.
     * @type {string}
     * @memberof LiveTvApiGetLiveTvPrograms
     */
    readonly fields?: string

    /**
     * Retrieve total record count.
     * @type {boolean}
     * @memberof LiveTvApiGetLiveTvPrograms
     */
    readonly enableTotalRecordCount?: boolean
}

/**
 * Request parameters for getProgram operation in LiveTvApi.
 * @export
 * @interface LiveTvApiGetProgramRequest
 */
export interface LiveTvApiGetProgramRequest {
    /**
     * Program id.
     * @type {string}
     * @memberof LiveTvApiGetProgram
     */
    readonly programId: string

    /**
     * Optional. Attach user data.
     * @type {string}
     * @memberof LiveTvApiGetProgram
     */
    readonly userId?: string
}

/**
 * Request parameters for getPrograms operation in LiveTvApi.
 * @export
 * @interface LiveTvApiGetProgramsRequest
 */
export interface LiveTvApiGetProgramsRequest {
    /**
     * Request body.
     * @type {GetProgramsDto}
     * @memberof LiveTvApiGetPrograms
     */
    readonly getProgramsDto?: GetProgramsDto
}

/**
 * Request parameters for getRecommendedPrograms operation in LiveTvApi.
 * @export
 * @interface LiveTvApiGetRecommendedProgramsRequest
 */
export interface LiveTvApiGetRecommendedProgramsRequest {
    /**
     * Optional. filter by user id.
     * @type {string}
     * @memberof LiveTvApiGetRecommendedPrograms
     */
    readonly userId?: string

    /**
     * Optional. The maximum number of records to return.
     * @type {number}
     * @memberof LiveTvApiGetRecommendedPrograms
     */
    readonly limit?: number

    /**
     * Optional. Filter by programs that are currently airing, or not.
     * @type {boolean}
     * @memberof LiveTvApiGetRecommendedPrograms
     */
    readonly isAiring?: boolean

    /**
     * Optional. Filter by programs that have completed airing, or not.
     * @type {boolean}
     * @memberof LiveTvApiGetRecommendedPrograms
     */
    readonly hasAired?: boolean

    /**
     * Optional. Filter for series.
     * @type {boolean}
     * @memberof LiveTvApiGetRecommendedPrograms
     */
    readonly isSeries?: boolean

    /**
     * Optional. Filter for movies.
     * @type {boolean}
     * @memberof LiveTvApiGetRecommendedPrograms
     */
    readonly isMovie?: boolean

    /**
     * Optional. Filter for news.
     * @type {boolean}
     * @memberof LiveTvApiGetRecommendedPrograms
     */
    readonly isNews?: boolean

    /**
     * Optional. Filter for kids.
     * @type {boolean}
     * @memberof LiveTvApiGetRecommendedPrograms
     */
    readonly isKids?: boolean

    /**
     * Optional. Filter for sports.
     * @type {boolean}
     * @memberof LiveTvApiGetRecommendedPrograms
     */
    readonly isSports?: boolean

    /**
     * Optional. Include image information in output.
     * @type {boolean}
     * @memberof LiveTvApiGetRecommendedPrograms
     */
    readonly enableImages?: boolean

    /**
     * Optional. The max number of images to return, per image type.
     * @type {number}
     * @memberof LiveTvApiGetRecommendedPrograms
     */
    readonly imageTypeLimit?: number

    /**
     * Optional. The image types to include in the output.
     * @type {Array<ImageType>}
     * @memberof LiveTvApiGetRecommendedPrograms
     */
    readonly enableImageTypes?: Array<ImageType>

    /**
     * The genres to return guide information for.
     * @type {string}
     * @memberof LiveTvApiGetRecommendedPrograms
     */
    readonly genreIds?: string

    /**
     * Optional. Specify additional fields of information to return in the output. This allows multiple, comma delimited. Options: Budget, Chapters, DateCreated, Genres, HomePageUrl, IndexOptions, MediaStreams, Overview, ParentId, Path, People, ProviderIds, PrimaryImageAspectRatio, Revenue, SortName, Studios, Taglines.
     * @type {string}
     * @memberof LiveTvApiGetRecommendedPrograms
     */
    readonly fields?: string

    /**
     * Optional. include user data.
     * @type {boolean}
     * @memberof LiveTvApiGetRecommendedPrograms
     */
    readonly enableUserData?: boolean

    /**
     * Retrieve total record count.
     * @type {boolean}
     * @memberof LiveTvApiGetRecommendedPrograms
     */
    readonly enableTotalRecordCount?: boolean
}

/**
 * Request parameters for getRecording operation in LiveTvApi.
 * @export
 * @interface LiveTvApiGetRecordingRequest
 */
export interface LiveTvApiGetRecordingRequest {
    /**
     * Recording id.
     * @type {string}
     * @memberof LiveTvApiGetRecording
     */
    readonly recordingId: string

    /**
     * Optional. Attach user data.
     * @type {string}
     * @memberof LiveTvApiGetRecording
     */
    readonly userId?: string
}

/**
 * Request parameters for getRecordingFolders operation in LiveTvApi.
 * @export
 * @interface LiveTvApiGetRecordingFoldersRequest
 */
export interface LiveTvApiGetRecordingFoldersRequest {
    /**
     * Optional. Filter by user and attach user data.
     * @type {string}
     * @memberof LiveTvApiGetRecordingFolders
     */
    readonly userId?: string
}

/**
 * Request parameters for getRecordingGroup operation in LiveTvApi.
 * @export
 * @interface LiveTvApiGetRecordingGroupRequest
 */
export interface LiveTvApiGetRecordingGroupRequest {
    /**
     * Group id.
     * @type {string}
     * @memberof LiveTvApiGetRecordingGroup
     */
    readonly groupId: string
}

/**
 * Request parameters for getRecordingGroups operation in LiveTvApi.
 * @export
 * @interface LiveTvApiGetRecordingGroupsRequest
 */
export interface LiveTvApiGetRecordingGroupsRequest {
    /**
     * Optional. Filter by user and attach user data.
     * @type {string}
     * @memberof LiveTvApiGetRecordingGroups
     */
    readonly userId?: string
}

/**
 * Request parameters for getRecordings operation in LiveTvApi.
 * @export
 * @interface LiveTvApiGetRecordingsRequest
 */
export interface LiveTvApiGetRecordingsRequest {
    /**
     * Optional. Filter by channel id.
     * @type {string}
     * @memberof LiveTvApiGetRecordings
     */
    readonly channelId?: string

    /**
     * Optional. Filter by user and attach user data.
     * @type {string}
     * @memberof LiveTvApiGetRecordings
     */
    readonly userId?: string

    /**
     * Optional. The record index to start at. All items with a lower index will be dropped from the results.
     * @type {number}
     * @memberof LiveTvApiGetRecordings
     */
    readonly startIndex?: number

    /**
     * Optional. The maximum number of records to return.
     * @type {number}
     * @memberof LiveTvApiGetRecordings
     */
    readonly limit?: number

    /**
     * Optional. Filter by recording status.
     * @type {RecordingStatus}
     * @memberof LiveTvApiGetRecordings
     */
    readonly status?: RecordingStatus

    /**
     * Optional. Filter by recordings that are in progress, or not.
     * @type {boolean}
     * @memberof LiveTvApiGetRecordings
     */
    readonly isInProgress?: boolean

    /**
     * Optional. Filter by recordings belonging to a series timer.
     * @type {string}
     * @memberof LiveTvApiGetRecordings
     */
    readonly seriesTimerId?: string

    /**
     * Optional. Include image information in output.
     * @type {boolean}
     * @memberof LiveTvApiGetRecordings
     */
    readonly enableImages?: boolean

    /**
     * Optional. The max number of images to return, per image type.
     * @type {number}
     * @memberof LiveTvApiGetRecordings
     */
    readonly imageTypeLimit?: number

    /**
     * Optional. The image types to include in the output.
     * @type {Array<ImageType>}
     * @memberof LiveTvApiGetRecordings
     */
    readonly enableImageTypes?: Array<ImageType>

    /**
     * Optional. Specify additional fields of information to return in the output. This allows multiple, comma delimited. Options: Budget, Chapters, DateCreated, Genres, HomePageUrl, IndexOptions, MediaStreams, Overview, ParentId, Path, People, ProviderIds, PrimaryImageAspectRatio, Revenue, SortName, Studios, Taglines.
     * @type {string}
     * @memberof LiveTvApiGetRecordings
     */
    readonly fields?: string

    /**
     * Optional. Include user data.
     * @type {boolean}
     * @memberof LiveTvApiGetRecordings
     */
    readonly enableUserData?: boolean

    /**
     * Optional. Filter for movies.
     * @type {boolean}
     * @memberof LiveTvApiGetRecordings
     */
    readonly isMovie?: boolean

    /**
     * Optional. Filter for series.
     * @type {boolean}
     * @memberof LiveTvApiGetRecordings
     */
    readonly isSeries?: boolean

    /**
     * Optional. Filter for kids.
     * @type {boolean}
     * @memberof LiveTvApiGetRecordings
     */
    readonly isKids?: boolean

    /**
     * Optional. Filter for sports.
     * @type {boolean}
     * @memberof LiveTvApiGetRecordings
     */
    readonly isSports?: boolean

    /**
     * Optional. Filter for news.
     * @type {boolean}
     * @memberof LiveTvApiGetRecordings
     */
    readonly isNews?: boolean

    /**
     * Optional. Filter for is library item.
     * @type {boolean}
     * @memberof LiveTvApiGetRecordings
     */
    readonly isLibraryItem?: boolean

    /**
     * Optional. Return total record count.
     * @type {boolean}
     * @memberof LiveTvApiGetRecordings
     */
    readonly enableTotalRecordCount?: boolean
}

/**
 * Request parameters for getRecordingsSeries operation in LiveTvApi.
 * @export
 * @interface LiveTvApiGetRecordingsSeriesRequest
 */
export interface LiveTvApiGetRecordingsSeriesRequest {
    /**
     * Optional. Filter by channel id.
     * @type {string}
     * @memberof LiveTvApiGetRecordingsSeries
     */
    readonly channelId?: string

    /**
     * Optional. Filter by user and attach user data.
     * @type {string}
     * @memberof LiveTvApiGetRecordingsSeries
     */
    readonly userId?: string

    /**
     * Optional. Filter by recording group.
     * @type {string}
     * @memberof LiveTvApiGetRecordingsSeries
     */
    readonly groupId?: string

    /**
     * Optional. The record index to start at. All items with a lower index will be dropped from the results.
     * @type {number}
     * @memberof LiveTvApiGetRecordingsSeries
     */
    readonly startIndex?: number

    /**
     * Optional. The maximum number of records to return.
     * @type {number}
     * @memberof LiveTvApiGetRecordingsSeries
     */
    readonly limit?: number

    /**
     * Optional. Filter by recording status.
     * @type {RecordingStatus}
     * @memberof LiveTvApiGetRecordingsSeries
     */
    readonly status?: RecordingStatus

    /**
     * Optional. Filter by recordings that are in progress, or not.
     * @type {boolean}
     * @memberof LiveTvApiGetRecordingsSeries
     */
    readonly isInProgress?: boolean

    /**
     * Optional. Filter by recordings belonging to a series timer.
     * @type {string}
     * @memberof LiveTvApiGetRecordingsSeries
     */
    readonly seriesTimerId?: string

    /**
     * Optional. Include image information in output.
     * @type {boolean}
     * @memberof LiveTvApiGetRecordingsSeries
     */
    readonly enableImages?: boolean

    /**
     * Optional. The max number of images to return, per image type.
     * @type {number}
     * @memberof LiveTvApiGetRecordingsSeries
     */
    readonly imageTypeLimit?: number

    /**
     * Optional. The image types to include in the output.
     * @type {Array<ImageType>}
     * @memberof LiveTvApiGetRecordingsSeries
     */
    readonly enableImageTypes?: Array<ImageType>

    /**
     * Optional. Specify additional fields of information to return in the output. This allows multiple, comma delimited. Options: Budget, Chapters, DateCreated, Genres, HomePageUrl, IndexOptions, MediaStreams, Overview, ParentId, Path, People, ProviderIds, PrimaryImageAspectRatio, Revenue, SortName, Studios, Taglines.
     * @type {string}
     * @memberof LiveTvApiGetRecordingsSeries
     */
    readonly fields?: string

    /**
     * Optional. Include user data.
     * @type {boolean}
     * @memberof LiveTvApiGetRecordingsSeries
     */
    readonly enableUserData?: boolean

    /**
     * Optional. Return total record count.
     * @type {boolean}
     * @memberof LiveTvApiGetRecordingsSeries
     */
    readonly enableTotalRecordCount?: boolean
}

/**
 * Request parameters for getSeriesTimer operation in LiveTvApi.
 * @export
 * @interface LiveTvApiGetSeriesTimerRequest
 */
export interface LiveTvApiGetSeriesTimerRequest {
    /**
     * Timer id.
     * @type {string}
     * @memberof LiveTvApiGetSeriesTimer
     */
    readonly timerId: string
}

/**
 * Request parameters for getSeriesTimers operation in LiveTvApi.
 * @export
 * @interface LiveTvApiGetSeriesTimersRequest
 */
export interface LiveTvApiGetSeriesTimersRequest {
    /**
     * Optional. Sort by SortName or Priority.
     * @type {string}
     * @memberof LiveTvApiGetSeriesTimers
     */
    readonly sortBy?: string

    /**
     * Optional. Sort in Ascending or Descending order.
     * @type {SortOrder}
     * @memberof LiveTvApiGetSeriesTimers
     */
    readonly sortOrder?: SortOrder
}

/**
 * Request parameters for getTimer operation in LiveTvApi.
 * @export
 * @interface LiveTvApiGetTimerRequest
 */
export interface LiveTvApiGetTimerRequest {
    /**
     * Timer id.
     * @type {string}
     * @memberof LiveTvApiGetTimer
     */
    readonly timerId: string
}

/**
 * Request parameters for getTimers operation in LiveTvApi.
 * @export
 * @interface LiveTvApiGetTimersRequest
 */
export interface LiveTvApiGetTimersRequest {
    /**
     * Optional. Filter by channel id.
     * @type {string}
     * @memberof LiveTvApiGetTimers
     */
    readonly channelId?: string

    /**
     * Optional. Filter by timers belonging to a series timer.
     * @type {string}
     * @memberof LiveTvApiGetTimers
     */
    readonly seriesTimerId?: string

    /**
     * Optional. Filter by timers that are active.
     * @type {boolean}
     * @memberof LiveTvApiGetTimers
     */
    readonly isActive?: boolean

    /**
     * Optional. Filter by timers that are scheduled.
     * @type {boolean}
     * @memberof LiveTvApiGetTimers
     */
    readonly isScheduled?: boolean
}

/**
 * Request parameters for resetTuner operation in LiveTvApi.
 * @export
 * @interface LiveTvApiResetTunerRequest
 */
export interface LiveTvApiResetTunerRequest {
    /**
     * Tuner id.
     * @type {string}
     * @memberof LiveTvApiResetTuner
     */
    readonly tunerId: string
}

/**
 * Request parameters for setChannelMapping operation in LiveTvApi.
 * @export
 * @interface LiveTvApiSetChannelMappingRequest
 */
export interface LiveTvApiSetChannelMappingRequest {
    /**
     * Provider id.
     * @type {string}
     * @memberof LiveTvApiSetChannelMapping
     */
    readonly providerId?: string

    /**
     * Tuner channel id.
     * @type {string}
     * @memberof LiveTvApiSetChannelMapping
     */
    readonly tunerChannelId?: string

    /**
     * Provider channel id.
     * @type {string}
     * @memberof LiveTvApiSetChannelMapping
     */
    readonly providerChannelId?: string
}

/**
 * Request parameters for updateSeriesTimer operation in LiveTvApi.
 * @export
 * @interface LiveTvApiUpdateSeriesTimerRequest
 */
export interface LiveTvApiUpdateSeriesTimerRequest {
    /**
     * Timer id.
     * @type {string}
     * @memberof LiveTvApiUpdateSeriesTimer
     */
    readonly timerId: string

    /**
     * New series timer info.
     * @type {SeriesTimerInfoDto}
     * @memberof LiveTvApiUpdateSeriesTimer
     */
    readonly seriesTimerInfoDto?: SeriesTimerInfoDto
}

/**
 * Request parameters for updateTimer operation in LiveTvApi.
 * @export
 * @interface LiveTvApiUpdateTimerRequest
 */
export interface LiveTvApiUpdateTimerRequest {
    /**
     * Timer id.
     * @type {string}
     * @memberof LiveTvApiUpdateTimer
     */
    readonly timerId: string

    /**
     * New timer info.
     * @type {TimerInfoDto}
     * @memberof LiveTvApiUpdateTimer
     */
    readonly timerInfoDto?: TimerInfoDto
}

/**
 * LiveTvApi - object-oriented interface
 * @export
 * @class LiveTvApi
 * @extends {BaseAPI}
 */
export class LiveTvApi extends BaseAPI {
    /**
     * 
     * @summary Adds a listings provider.
     * @param {LiveTvApiAddListingProviderRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LiveTvApi
     */
    public addListingProvider(requestParameters: LiveTvApiAddListingProviderRequest = {}, options?: any) {
        return LiveTvApiFp(this.configuration).addListingProvider(requestParameters.pw, requestParameters.validateListings, requestParameters.validateLogin, requestParameters.listingsProviderInfo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Adds a tuner host.
     * @param {LiveTvApiAddTunerHostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LiveTvApi
     */
    public addTunerHost(requestParameters: LiveTvApiAddTunerHostRequest = {}, options?: any) {
        return LiveTvApiFp(this.configuration).addTunerHost(requestParameters.tunerHostInfo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Cancels a live tv series timer.
     * @param {LiveTvApiCancelSeriesTimerRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LiveTvApi
     */
    public cancelSeriesTimer(requestParameters: LiveTvApiCancelSeriesTimerRequest, options?: any) {
        return LiveTvApiFp(this.configuration).cancelSeriesTimer(requestParameters.timerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Cancels a live tv timer.
     * @param {LiveTvApiCancelTimerRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LiveTvApi
     */
    public cancelTimer(requestParameters: LiveTvApiCancelTimerRequest, options?: any) {
        return LiveTvApiFp(this.configuration).cancelTimer(requestParameters.timerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Creates a live tv series timer.
     * @param {LiveTvApiCreateSeriesTimerRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LiveTvApi
     */
    public createSeriesTimer(requestParameters: LiveTvApiCreateSeriesTimerRequest = {}, options?: any) {
        return LiveTvApiFp(this.configuration).createSeriesTimer(requestParameters.seriesTimerInfoDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Creates a live tv timer.
     * @param {LiveTvApiCreateTimerRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LiveTvApi
     */
    public createTimer(requestParameters: LiveTvApiCreateTimerRequest = {}, options?: any) {
        return LiveTvApiFp(this.configuration).createTimer(requestParameters.timerInfoDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete listing provider.
     * @param {LiveTvApiDeleteListingProviderRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LiveTvApi
     */
    public deleteListingProvider(requestParameters: LiveTvApiDeleteListingProviderRequest = {}, options?: any) {
        return LiveTvApiFp(this.configuration).deleteListingProvider(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Deletes a live tv recording.
     * @param {LiveTvApiDeleteRecordingRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LiveTvApi
     */
    public deleteRecording(requestParameters: LiveTvApiDeleteRecordingRequest, options?: any) {
        return LiveTvApiFp(this.configuration).deleteRecording(requestParameters.recordingId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Deletes a tuner host.
     * @param {LiveTvApiDeleteTunerHostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LiveTvApi
     */
    public deleteTunerHost(requestParameters: LiveTvApiDeleteTunerHostRequest = {}, options?: any) {
        return LiveTvApiFp(this.configuration).deleteTunerHost(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Discover tuners.
     * @param {LiveTvApiDiscoverTunersRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LiveTvApi
     */
    public discoverTuners(requestParameters: LiveTvApiDiscoverTunersRequest = {}, options?: any) {
        return LiveTvApiFp(this.configuration).discoverTuners(requestParameters.newDevicesOnly, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets a live tv channel.
     * @param {LiveTvApiGetChannelRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LiveTvApi
     */
    public getChannel(requestParameters: LiveTvApiGetChannelRequest, options?: any) {
        return LiveTvApiFp(this.configuration).getChannel(requestParameters.channelId, requestParameters.userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get channel mapping options.
     * @param {LiveTvApiGetChannelMappingOptionsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LiveTvApi
     */
    public getChannelMappingOptions(requestParameters: LiveTvApiGetChannelMappingOptionsRequest = {}, options?: any) {
        return LiveTvApiFp(this.configuration).getChannelMappingOptions(requestParameters.providerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets default listings provider info.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LiveTvApi
     */
    public getDefaultListingProvider(options?: any) {
        return LiveTvApiFp(this.configuration).getDefaultListingProvider(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets the default values for a new timer.
     * @param {LiveTvApiGetDefaultTimerRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LiveTvApi
     */
    public getDefaultTimer(requestParameters: LiveTvApiGetDefaultTimerRequest = {}, options?: any) {
        return LiveTvApiFp(this.configuration).getDefaultTimer(requestParameters.programId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get guid info.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LiveTvApi
     */
    public getGuideInfo(options?: any) {
        return LiveTvApiFp(this.configuration).getGuideInfo(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets available lineups.
     * @param {LiveTvApiGetLineupsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LiveTvApi
     */
    public getLineups(requestParameters: LiveTvApiGetLineupsRequest = {}, options?: any) {
        return LiveTvApiFp(this.configuration).getLineups(requestParameters.id, requestParameters.type, requestParameters.location, requestParameters.country, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets a live tv recording stream.
     * @param {LiveTvApiGetLiveRecordingFileRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LiveTvApi
     */
    public getLiveRecordingFile(requestParameters: LiveTvApiGetLiveRecordingFileRequest, options?: any) {
        return LiveTvApiFp(this.configuration).getLiveRecordingFile(requestParameters.recordingId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets a live tv channel stream.
     * @param {LiveTvApiGetLiveStreamFileRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LiveTvApi
     */
    public getLiveStreamFile(requestParameters: LiveTvApiGetLiveStreamFileRequest, options?: any) {
        return LiveTvApiFp(this.configuration).getLiveStreamFile(requestParameters.streamId, requestParameters.container, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets available live tv channels.
     * @param {LiveTvApiGetLiveTvChannelsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LiveTvApi
     */
    public getLiveTvChannels(requestParameters: LiveTvApiGetLiveTvChannelsRequest = {}, options?: any) {
        return LiveTvApiFp(this.configuration).getLiveTvChannels(requestParameters.type, requestParameters.userId, requestParameters.startIndex, requestParameters.isMovie, requestParameters.isSeries, requestParameters.isNews, requestParameters.isKids, requestParameters.isSports, requestParameters.limit, requestParameters.isFavorite, requestParameters.isLiked, requestParameters.isDisliked, requestParameters.enableImages, requestParameters.imageTypeLimit, requestParameters.enableImageTypes, requestParameters.fields, requestParameters.enableUserData, requestParameters.sortBy, requestParameters.sortOrder, requestParameters.enableFavoriteSorting, requestParameters.addCurrentProgram, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets available live tv services.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LiveTvApi
     */
    public getLiveTvInfo(options?: any) {
        return LiveTvApiFp(this.configuration).getLiveTvInfo(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets available live tv epgs.
     * @param {LiveTvApiGetLiveTvProgramsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LiveTvApi
     */
    public getLiveTvPrograms(requestParameters: LiveTvApiGetLiveTvProgramsRequest = {}, options?: any) {
        return LiveTvApiFp(this.configuration).getLiveTvPrograms(requestParameters.channelIds, requestParameters.userId, requestParameters.minStartDate, requestParameters.hasAired, requestParameters.isAiring, requestParameters.maxStartDate, requestParameters.minEndDate, requestParameters.maxEndDate, requestParameters.isMovie, requestParameters.isSeries, requestParameters.isNews, requestParameters.isKids, requestParameters.isSports, requestParameters.startIndex, requestParameters.limit, requestParameters.sortBy, requestParameters.sortOrder, requestParameters.genres, requestParameters.genreIds, requestParameters.enableImages, requestParameters.imageTypeLimit, requestParameters.enableImageTypes, requestParameters.enableUserData, requestParameters.seriesTimerId, requestParameters.librarySeriesId, requestParameters.fields, requestParameters.enableTotalRecordCount, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets a live tv program.
     * @param {LiveTvApiGetProgramRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LiveTvApi
     */
    public getProgram(requestParameters: LiveTvApiGetProgramRequest, options?: any) {
        return LiveTvApiFp(this.configuration).getProgram(requestParameters.programId, requestParameters.userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets available live tv epgs.
     * @param {LiveTvApiGetProgramsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LiveTvApi
     */
    public getPrograms(requestParameters: LiveTvApiGetProgramsRequest = {}, options?: any) {
        return LiveTvApiFp(this.configuration).getPrograms(requestParameters.getProgramsDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets recommended live tv epgs.
     * @param {LiveTvApiGetRecommendedProgramsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LiveTvApi
     */
    public getRecommendedPrograms(requestParameters: LiveTvApiGetRecommendedProgramsRequest = {}, options?: any) {
        return LiveTvApiFp(this.configuration).getRecommendedPrograms(requestParameters.userId, requestParameters.limit, requestParameters.isAiring, requestParameters.hasAired, requestParameters.isSeries, requestParameters.isMovie, requestParameters.isNews, requestParameters.isKids, requestParameters.isSports, requestParameters.enableImages, requestParameters.imageTypeLimit, requestParameters.enableImageTypes, requestParameters.genreIds, requestParameters.fields, requestParameters.enableUserData, requestParameters.enableTotalRecordCount, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets a live tv recording.
     * @param {LiveTvApiGetRecordingRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LiveTvApi
     */
    public getRecording(requestParameters: LiveTvApiGetRecordingRequest, options?: any) {
        return LiveTvApiFp(this.configuration).getRecording(requestParameters.recordingId, requestParameters.userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets recording folders.
     * @param {LiveTvApiGetRecordingFoldersRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LiveTvApi
     */
    public getRecordingFolders(requestParameters: LiveTvApiGetRecordingFoldersRequest = {}, options?: any) {
        return LiveTvApiFp(this.configuration).getRecordingFolders(requestParameters.userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get recording group.
     * @param {LiveTvApiGetRecordingGroupRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LiveTvApi
     */
    public getRecordingGroup(requestParameters: LiveTvApiGetRecordingGroupRequest, options?: any) {
        return LiveTvApiFp(this.configuration).getRecordingGroup(requestParameters.groupId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets live tv recording groups.
     * @param {LiveTvApiGetRecordingGroupsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LiveTvApi
     */
    public getRecordingGroups(requestParameters: LiveTvApiGetRecordingGroupsRequest = {}, options?: any) {
        return LiveTvApiFp(this.configuration).getRecordingGroups(requestParameters.userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets live tv recordings.
     * @param {LiveTvApiGetRecordingsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LiveTvApi
     */
    public getRecordings(requestParameters: LiveTvApiGetRecordingsRequest = {}, options?: any) {
        return LiveTvApiFp(this.configuration).getRecordings(requestParameters.channelId, requestParameters.userId, requestParameters.startIndex, requestParameters.limit, requestParameters.status, requestParameters.isInProgress, requestParameters.seriesTimerId, requestParameters.enableImages, requestParameters.imageTypeLimit, requestParameters.enableImageTypes, requestParameters.fields, requestParameters.enableUserData, requestParameters.isMovie, requestParameters.isSeries, requestParameters.isKids, requestParameters.isSports, requestParameters.isNews, requestParameters.isLibraryItem, requestParameters.enableTotalRecordCount, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets live tv recording series.
     * @param {LiveTvApiGetRecordingsSeriesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LiveTvApi
     */
    public getRecordingsSeries(requestParameters: LiveTvApiGetRecordingsSeriesRequest = {}, options?: any) {
        return LiveTvApiFp(this.configuration).getRecordingsSeries(requestParameters.channelId, requestParameters.userId, requestParameters.groupId, requestParameters.startIndex, requestParameters.limit, requestParameters.status, requestParameters.isInProgress, requestParameters.seriesTimerId, requestParameters.enableImages, requestParameters.imageTypeLimit, requestParameters.enableImageTypes, requestParameters.fields, requestParameters.enableUserData, requestParameters.enableTotalRecordCount, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets available countries.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LiveTvApi
     */
    public getSchedulesDirectCountries(options?: any) {
        return LiveTvApiFp(this.configuration).getSchedulesDirectCountries(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets a live tv series timer.
     * @param {LiveTvApiGetSeriesTimerRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LiveTvApi
     */
    public getSeriesTimer(requestParameters: LiveTvApiGetSeriesTimerRequest, options?: any) {
        return LiveTvApiFp(this.configuration).getSeriesTimer(requestParameters.timerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets live tv series timers.
     * @param {LiveTvApiGetSeriesTimersRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LiveTvApi
     */
    public getSeriesTimers(requestParameters: LiveTvApiGetSeriesTimersRequest = {}, options?: any) {
        return LiveTvApiFp(this.configuration).getSeriesTimers(requestParameters.sortBy, requestParameters.sortOrder, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets a timer.
     * @param {LiveTvApiGetTimerRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LiveTvApi
     */
    public getTimer(requestParameters: LiveTvApiGetTimerRequest, options?: any) {
        return LiveTvApiFp(this.configuration).getTimer(requestParameters.timerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets the live tv timers.
     * @param {LiveTvApiGetTimersRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LiveTvApi
     */
    public getTimers(requestParameters: LiveTvApiGetTimersRequest = {}, options?: any) {
        return LiveTvApiFp(this.configuration).getTimers(requestParameters.channelId, requestParameters.seriesTimerId, requestParameters.isActive, requestParameters.isScheduled, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get tuner host types.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LiveTvApi
     */
    public getTunerHostTypes(options?: any) {
        return LiveTvApiFp(this.configuration).getTunerHostTypes(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Resets a tv tuner.
     * @param {LiveTvApiResetTunerRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LiveTvApi
     */
    public resetTuner(requestParameters: LiveTvApiResetTunerRequest, options?: any) {
        return LiveTvApiFp(this.configuration).resetTuner(requestParameters.tunerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Set channel mappings.
     * @param {LiveTvApiSetChannelMappingRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LiveTvApi
     */
    public setChannelMapping(requestParameters: LiveTvApiSetChannelMappingRequest = {}, options?: any) {
        return LiveTvApiFp(this.configuration).setChannelMapping(requestParameters.providerId, requestParameters.tunerChannelId, requestParameters.providerChannelId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates a live tv series timer.
     * @param {LiveTvApiUpdateSeriesTimerRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LiveTvApi
     */
    public updateSeriesTimer(requestParameters: LiveTvApiUpdateSeriesTimerRequest, options?: any) {
        return LiveTvApiFp(this.configuration).updateSeriesTimer(requestParameters.timerId, requestParameters.seriesTimerInfoDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates a live tv timer.
     * @param {LiveTvApiUpdateTimerRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LiveTvApi
     */
    public updateTimer(requestParameters: LiveTvApiUpdateTimerRequest, options?: any) {
        return LiveTvApiFp(this.configuration).updateTimer(requestParameters.timerId, requestParameters.timerInfoDto, options).then((request) => request(this.axios, this.basePath));
    }
}
