/* tslint:disable */
/* eslint-disable */
/**
 * Jellyfin API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * The startup user DTO.
 * @export
 * @interface StartupUserDto
 */
export interface StartupUserDto {
    /**
     * Gets or sets the username.
     * @type {string}
     * @memberof StartupUserDto
     */
    Name?: string | null;
    /**
     * Gets or sets the user\'s password.
     * @type {string}
     * @memberof StartupUserDto
     */
    Password?: string | null;
}


