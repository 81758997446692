/* tslint:disable */
/* eslint-disable */
/**
 * Jellyfin API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ProblemDetails } from '../models';
// @ts-ignore
import { RemoteSubtitleInfo } from '../models';
/**
 * SubtitleApi - axios parameter creator
 * @export
 */
export const SubtitleApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Deletes an external subtitle file.
         * @param {string} itemId The item id.
         * @param {number} index The index of the subtitle file.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSubtitle: async (itemId: string, index: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'itemId' is not null or undefined
            if (itemId === null || itemId === undefined) {
                throw new RequiredError('itemId','Required parameter itemId was null or undefined when calling deleteSubtitle.');
            }
            // verify required parameter 'index' is not null or undefined
            if (index === null || index === undefined) {
                throw new RequiredError('index','Required parameter index was null or undefined when calling deleteSubtitle.');
            }
            const localVarPath = `/Videos/{itemId}/Subtitles/{index}`
                .replace(`{${"itemId"}}`, encodeURIComponent(String(itemId)))
                .replace(`{${"index"}}`, encodeURIComponent(String(index)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Downloads a remote subtitle.
         * @param {string} itemId The item id.
         * @param {string} subtitleId The subtitle id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadRemoteSubtitles: async (itemId: string, subtitleId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'itemId' is not null or undefined
            if (itemId === null || itemId === undefined) {
                throw new RequiredError('itemId','Required parameter itemId was null or undefined when calling downloadRemoteSubtitles.');
            }
            // verify required parameter 'subtitleId' is not null or undefined
            if (subtitleId === null || subtitleId === undefined) {
                throw new RequiredError('subtitleId','Required parameter subtitleId was null or undefined when calling downloadRemoteSubtitles.');
            }
            const localVarPath = `/Items/{itemId}/RemoteSearch/Subtitles/{subtitleId}`
                .replace(`{${"itemId"}}`, encodeURIComponent(String(itemId)))
                .replace(`{${"subtitleId"}}`, encodeURIComponent(String(subtitleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets the remote subtitles.
         * @param {string} id The item id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRemoteSubtitles: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getRemoteSubtitles.');
            }
            const localVarPath = `/Providers/Subtitles/Subtitles/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets subtitles in a specified format.
         * @param {string} itemId The item id.
         * @param {string} mediaSourceId The media source id.
         * @param {number} index The subtitle stream index.
         * @param {string} format The format of the returned subtitle.
         * @param {number} startPositionTicks Optional. The start position of the subtitle in ticks.
         * @param {number} [endPositionTicks] Optional. The end position of the subtitle in ticks.
         * @param {boolean} [copyTimestamps] Optional. Whether to copy the timestamps.
         * @param {boolean} [addVttTimeMap] Optional. Whether to add a VTT time map.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubtitle: async (itemId: string, mediaSourceId: string, index: number, format: string, startPositionTicks: number, endPositionTicks?: number, copyTimestamps?: boolean, addVttTimeMap?: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'itemId' is not null or undefined
            if (itemId === null || itemId === undefined) {
                throw new RequiredError('itemId','Required parameter itemId was null or undefined when calling getSubtitle.');
            }
            // verify required parameter 'mediaSourceId' is not null or undefined
            if (mediaSourceId === null || mediaSourceId === undefined) {
                throw new RequiredError('mediaSourceId','Required parameter mediaSourceId was null or undefined when calling getSubtitle.');
            }
            // verify required parameter 'index' is not null or undefined
            if (index === null || index === undefined) {
                throw new RequiredError('index','Required parameter index was null or undefined when calling getSubtitle.');
            }
            // verify required parameter 'format' is not null or undefined
            if (format === null || format === undefined) {
                throw new RequiredError('format','Required parameter format was null or undefined when calling getSubtitle.');
            }
            // verify required parameter 'startPositionTicks' is not null or undefined
            if (startPositionTicks === null || startPositionTicks === undefined) {
                throw new RequiredError('startPositionTicks','Required parameter startPositionTicks was null or undefined when calling getSubtitle.');
            }
            const localVarPath = `/Videos/{itemId}/{mediaSourceId}/Subtitles/{index}/Stream.{format}`
                .replace(`{${"itemId"}}`, encodeURIComponent(String(itemId)))
                .replace(`{${"mediaSourceId"}}`, encodeURIComponent(String(mediaSourceId)))
                .replace(`{${"index"}}`, encodeURIComponent(String(index)))
                .replace(`{${"format"}}`, encodeURIComponent(String(format)))
                .replace(`{${"startPositionTicks"}}`, encodeURIComponent(String(startPositionTicks)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }

            if (endPositionTicks !== undefined) {
                localVarQueryParameter['endPositionTicks'] = endPositionTicks;
            }

            if (copyTimestamps !== undefined) {
                localVarQueryParameter['copyTimestamps'] = copyTimestamps;
            }

            if (addVttTimeMap !== undefined) {
                localVarQueryParameter['addVttTimeMap'] = addVttTimeMap;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets subtitles in a specified format.
         * @param {string} itemId The item id.
         * @param {string} mediaSourceId The media source id.
         * @param {number} index The subtitle stream index.
         * @param {string} format The format of the returned subtitle.
         * @param {number} startPositionTicks Optional. The start position of the subtitle in ticks.
         * @param {number} [endPositionTicks] Optional. The end position of the subtitle in ticks.
         * @param {boolean} [copyTimestamps] Optional. Whether to copy the timestamps.
         * @param {boolean} [addVttTimeMap] Optional. Whether to add a VTT time map.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubtitle2: async (itemId: string, mediaSourceId: string, index: number, format: string, startPositionTicks: number, endPositionTicks?: number, copyTimestamps?: boolean, addVttTimeMap?: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'itemId' is not null or undefined
            if (itemId === null || itemId === undefined) {
                throw new RequiredError('itemId','Required parameter itemId was null or undefined when calling getSubtitle2.');
            }
            // verify required parameter 'mediaSourceId' is not null or undefined
            if (mediaSourceId === null || mediaSourceId === undefined) {
                throw new RequiredError('mediaSourceId','Required parameter mediaSourceId was null or undefined when calling getSubtitle2.');
            }
            // verify required parameter 'index' is not null or undefined
            if (index === null || index === undefined) {
                throw new RequiredError('index','Required parameter index was null or undefined when calling getSubtitle2.');
            }
            // verify required parameter 'format' is not null or undefined
            if (format === null || format === undefined) {
                throw new RequiredError('format','Required parameter format was null or undefined when calling getSubtitle2.');
            }
            // verify required parameter 'startPositionTicks' is not null or undefined
            if (startPositionTicks === null || startPositionTicks === undefined) {
                throw new RequiredError('startPositionTicks','Required parameter startPositionTicks was null or undefined when calling getSubtitle2.');
            }
            const localVarPath = `/Videos/{itemId}/{mediaSourceId}/Subtitles/{index}/{startPositionTicks}/Stream.{format}`
                .replace(`{${"itemId"}}`, encodeURIComponent(String(itemId)))
                .replace(`{${"mediaSourceId"}}`, encodeURIComponent(String(mediaSourceId)))
                .replace(`{${"index"}}`, encodeURIComponent(String(index)))
                .replace(`{${"format"}}`, encodeURIComponent(String(format)))
                .replace(`{${"startPositionTicks"}}`, encodeURIComponent(String(startPositionTicks)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }

            if (endPositionTicks !== undefined) {
                localVarQueryParameter['endPositionTicks'] = endPositionTicks;
            }

            if (copyTimestamps !== undefined) {
                localVarQueryParameter['copyTimestamps'] = copyTimestamps;
            }

            if (addVttTimeMap !== undefined) {
                localVarQueryParameter['addVttTimeMap'] = addVttTimeMap;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets an HLS subtitle playlist.
         * @param {string} itemId The item id.
         * @param {number} index The subtitle stream index.
         * @param {string} mediaSourceId The media source id.
         * @param {number} segmentLength The subtitle segment length.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubtitlePlaylist: async (itemId: string, index: number, mediaSourceId: string, segmentLength: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'itemId' is not null or undefined
            if (itemId === null || itemId === undefined) {
                throw new RequiredError('itemId','Required parameter itemId was null or undefined when calling getSubtitlePlaylist.');
            }
            // verify required parameter 'index' is not null or undefined
            if (index === null || index === undefined) {
                throw new RequiredError('index','Required parameter index was null or undefined when calling getSubtitlePlaylist.');
            }
            // verify required parameter 'mediaSourceId' is not null or undefined
            if (mediaSourceId === null || mediaSourceId === undefined) {
                throw new RequiredError('mediaSourceId','Required parameter mediaSourceId was null or undefined when calling getSubtitlePlaylist.');
            }
            // verify required parameter 'segmentLength' is not null or undefined
            if (segmentLength === null || segmentLength === undefined) {
                throw new RequiredError('segmentLength','Required parameter segmentLength was null or undefined when calling getSubtitlePlaylist.');
            }
            const localVarPath = `/Videos/{itemId}/{mediaSourceId}/Subtitles/{index}/subtitles.m3u8`
                .replace(`{${"itemId"}}`, encodeURIComponent(String(itemId)))
                .replace(`{${"index"}}`, encodeURIComponent(String(index)))
                .replace(`{${"mediaSourceId"}}`, encodeURIComponent(String(mediaSourceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }

            if (segmentLength !== undefined) {
                localVarQueryParameter['segmentLength'] = segmentLength;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Search remote subtitles.
         * @param {string} itemId The item id.
         * @param {string} language The language of the subtitles.
         * @param {boolean} [isPerfectMatch] Optional. Only show subtitles which are a perfect match.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchRemoteSubtitles: async (itemId: string, language: string, isPerfectMatch?: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'itemId' is not null or undefined
            if (itemId === null || itemId === undefined) {
                throw new RequiredError('itemId','Required parameter itemId was null or undefined when calling searchRemoteSubtitles.');
            }
            // verify required parameter 'language' is not null or undefined
            if (language === null || language === undefined) {
                throw new RequiredError('language','Required parameter language was null or undefined when calling searchRemoteSubtitles.');
            }
            const localVarPath = `/Items/{itemId}/RemoteSearch/Subtitles/{language}`
                .replace(`{${"itemId"}}`, encodeURIComponent(String(itemId)))
                .replace(`{${"language"}}`, encodeURIComponent(String(language)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }

            if (isPerfectMatch !== undefined) {
                localVarQueryParameter['isPerfectMatch'] = isPerfectMatch;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SubtitleApi - functional programming interface
 * @export
 */
export const SubtitleApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Deletes an external subtitle file.
         * @param {string} itemId The item id.
         * @param {number} index The index of the subtitle file.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteSubtitle(itemId: string, index: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await SubtitleApiAxiosParamCreator(configuration).deleteSubtitle(itemId, index, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Downloads a remote subtitle.
         * @param {string} itemId The item id.
         * @param {string} subtitleId The subtitle id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadRemoteSubtitles(itemId: string, subtitleId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await SubtitleApiAxiosParamCreator(configuration).downloadRemoteSubtitles(itemId, subtitleId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Gets the remote subtitles.
         * @param {string} id The item id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRemoteSubtitles(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await SubtitleApiAxiosParamCreator(configuration).getRemoteSubtitles(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Gets subtitles in a specified format.
         * @param {string} itemId The item id.
         * @param {string} mediaSourceId The media source id.
         * @param {number} index The subtitle stream index.
         * @param {string} format The format of the returned subtitle.
         * @param {number} startPositionTicks Optional. The start position of the subtitle in ticks.
         * @param {number} [endPositionTicks] Optional. The end position of the subtitle in ticks.
         * @param {boolean} [copyTimestamps] Optional. Whether to copy the timestamps.
         * @param {boolean} [addVttTimeMap] Optional. Whether to add a VTT time map.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSubtitle(itemId: string, mediaSourceId: string, index: number, format: string, startPositionTicks: number, endPositionTicks?: number, copyTimestamps?: boolean, addVttTimeMap?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await SubtitleApiAxiosParamCreator(configuration).getSubtitle(itemId, mediaSourceId, index, format, startPositionTicks, endPositionTicks, copyTimestamps, addVttTimeMap, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Gets subtitles in a specified format.
         * @param {string} itemId The item id.
         * @param {string} mediaSourceId The media source id.
         * @param {number} index The subtitle stream index.
         * @param {string} format The format of the returned subtitle.
         * @param {number} startPositionTicks Optional. The start position of the subtitle in ticks.
         * @param {number} [endPositionTicks] Optional. The end position of the subtitle in ticks.
         * @param {boolean} [copyTimestamps] Optional. Whether to copy the timestamps.
         * @param {boolean} [addVttTimeMap] Optional. Whether to add a VTT time map.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSubtitle2(itemId: string, mediaSourceId: string, index: number, format: string, startPositionTicks: number, endPositionTicks?: number, copyTimestamps?: boolean, addVttTimeMap?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await SubtitleApiAxiosParamCreator(configuration).getSubtitle2(itemId, mediaSourceId, index, format, startPositionTicks, endPositionTicks, copyTimestamps, addVttTimeMap, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Gets an HLS subtitle playlist.
         * @param {string} itemId The item id.
         * @param {number} index The subtitle stream index.
         * @param {string} mediaSourceId The media source id.
         * @param {number} segmentLength The subtitle segment length.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSubtitlePlaylist(itemId: string, index: number, mediaSourceId: string, segmentLength: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await SubtitleApiAxiosParamCreator(configuration).getSubtitlePlaylist(itemId, index, mediaSourceId, segmentLength, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Search remote subtitles.
         * @param {string} itemId The item id.
         * @param {string} language The language of the subtitles.
         * @param {boolean} [isPerfectMatch] Optional. Only show subtitles which are a perfect match.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchRemoteSubtitles(itemId: string, language: string, isPerfectMatch?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RemoteSubtitleInfo>>> {
            const localVarAxiosArgs = await SubtitleApiAxiosParamCreator(configuration).searchRemoteSubtitles(itemId, language, isPerfectMatch, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * SubtitleApi - factory interface
 * @export
 */
export const SubtitleApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Deletes an external subtitle file.
         * @param {string} itemId The item id.
         * @param {number} index The index of the subtitle file.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSubtitle(itemId: string, index: number, options?: any): AxiosPromise<void> {
            return SubtitleApiFp(configuration).deleteSubtitle(itemId, index, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Downloads a remote subtitle.
         * @param {string} itemId The item id.
         * @param {string} subtitleId The subtitle id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadRemoteSubtitles(itemId: string, subtitleId: string, options?: any): AxiosPromise<void> {
            return SubtitleApiFp(configuration).downloadRemoteSubtitles(itemId, subtitleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets the remote subtitles.
         * @param {string} id The item id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRemoteSubtitles(id: string, options?: any): AxiosPromise<any> {
            return SubtitleApiFp(configuration).getRemoteSubtitles(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets subtitles in a specified format.
         * @param {string} itemId The item id.
         * @param {string} mediaSourceId The media source id.
         * @param {number} index The subtitle stream index.
         * @param {string} format The format of the returned subtitle.
         * @param {number} startPositionTicks Optional. The start position of the subtitle in ticks.
         * @param {number} [endPositionTicks] Optional. The end position of the subtitle in ticks.
         * @param {boolean} [copyTimestamps] Optional. Whether to copy the timestamps.
         * @param {boolean} [addVttTimeMap] Optional. Whether to add a VTT time map.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubtitle(itemId: string, mediaSourceId: string, index: number, format: string, startPositionTicks: number, endPositionTicks?: number, copyTimestamps?: boolean, addVttTimeMap?: boolean, options?: any): AxiosPromise<any> {
            return SubtitleApiFp(configuration).getSubtitle(itemId, mediaSourceId, index, format, startPositionTicks, endPositionTicks, copyTimestamps, addVttTimeMap, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets subtitles in a specified format.
         * @param {string} itemId The item id.
         * @param {string} mediaSourceId The media source id.
         * @param {number} index The subtitle stream index.
         * @param {string} format The format of the returned subtitle.
         * @param {number} startPositionTicks Optional. The start position of the subtitle in ticks.
         * @param {number} [endPositionTicks] Optional. The end position of the subtitle in ticks.
         * @param {boolean} [copyTimestamps] Optional. Whether to copy the timestamps.
         * @param {boolean} [addVttTimeMap] Optional. Whether to add a VTT time map.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubtitle2(itemId: string, mediaSourceId: string, index: number, format: string, startPositionTicks: number, endPositionTicks?: number, copyTimestamps?: boolean, addVttTimeMap?: boolean, options?: any): AxiosPromise<any> {
            return SubtitleApiFp(configuration).getSubtitle2(itemId, mediaSourceId, index, format, startPositionTicks, endPositionTicks, copyTimestamps, addVttTimeMap, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets an HLS subtitle playlist.
         * @param {string} itemId The item id.
         * @param {number} index The subtitle stream index.
         * @param {string} mediaSourceId The media source id.
         * @param {number} segmentLength The subtitle segment length.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubtitlePlaylist(itemId: string, index: number, mediaSourceId: string, segmentLength: number, options?: any): AxiosPromise<any> {
            return SubtitleApiFp(configuration).getSubtitlePlaylist(itemId, index, mediaSourceId, segmentLength, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Search remote subtitles.
         * @param {string} itemId The item id.
         * @param {string} language The language of the subtitles.
         * @param {boolean} [isPerfectMatch] Optional. Only show subtitles which are a perfect match.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchRemoteSubtitles(itemId: string, language: string, isPerfectMatch?: boolean, options?: any): AxiosPromise<Array<RemoteSubtitleInfo>> {
            return SubtitleApiFp(configuration).searchRemoteSubtitles(itemId, language, isPerfectMatch, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for deleteSubtitle operation in SubtitleApi.
 * @export
 * @interface SubtitleApiDeleteSubtitleRequest
 */
export interface SubtitleApiDeleteSubtitleRequest {
    /**
     * The item id.
     * @type {string}
     * @memberof SubtitleApiDeleteSubtitle
     */
    readonly itemId: string

    /**
     * The index of the subtitle file.
     * @type {number}
     * @memberof SubtitleApiDeleteSubtitle
     */
    readonly index: number
}

/**
 * Request parameters for downloadRemoteSubtitles operation in SubtitleApi.
 * @export
 * @interface SubtitleApiDownloadRemoteSubtitlesRequest
 */
export interface SubtitleApiDownloadRemoteSubtitlesRequest {
    /**
     * The item id.
     * @type {string}
     * @memberof SubtitleApiDownloadRemoteSubtitles
     */
    readonly itemId: string

    /**
     * The subtitle id.
     * @type {string}
     * @memberof SubtitleApiDownloadRemoteSubtitles
     */
    readonly subtitleId: string
}

/**
 * Request parameters for getRemoteSubtitles operation in SubtitleApi.
 * @export
 * @interface SubtitleApiGetRemoteSubtitlesRequest
 */
export interface SubtitleApiGetRemoteSubtitlesRequest {
    /**
     * The item id.
     * @type {string}
     * @memberof SubtitleApiGetRemoteSubtitles
     */
    readonly id: string
}

/**
 * Request parameters for getSubtitle operation in SubtitleApi.
 * @export
 * @interface SubtitleApiGetSubtitleRequest
 */
export interface SubtitleApiGetSubtitleRequest {
    /**
     * The item id.
     * @type {string}
     * @memberof SubtitleApiGetSubtitle
     */
    readonly itemId: string

    /**
     * The media source id.
     * @type {string}
     * @memberof SubtitleApiGetSubtitle
     */
    readonly mediaSourceId: string

    /**
     * The subtitle stream index.
     * @type {number}
     * @memberof SubtitleApiGetSubtitle
     */
    readonly index: number

    /**
     * The format of the returned subtitle.
     * @type {string}
     * @memberof SubtitleApiGetSubtitle
     */
    readonly format: string

    /**
     * Optional. The start position of the subtitle in ticks.
     * @type {number}
     * @memberof SubtitleApiGetSubtitle
     */
    readonly startPositionTicks: number

    /**
     * Optional. The end position of the subtitle in ticks.
     * @type {number}
     * @memberof SubtitleApiGetSubtitle
     */
    readonly endPositionTicks?: number

    /**
     * Optional. Whether to copy the timestamps.
     * @type {boolean}
     * @memberof SubtitleApiGetSubtitle
     */
    readonly copyTimestamps?: boolean

    /**
     * Optional. Whether to add a VTT time map.
     * @type {boolean}
     * @memberof SubtitleApiGetSubtitle
     */
    readonly addVttTimeMap?: boolean
}

/**
 * Request parameters for getSubtitle2 operation in SubtitleApi.
 * @export
 * @interface SubtitleApiGetSubtitle2Request
 */
export interface SubtitleApiGetSubtitle2Request {
    /**
     * The item id.
     * @type {string}
     * @memberof SubtitleApiGetSubtitle2
     */
    readonly itemId: string

    /**
     * The media source id.
     * @type {string}
     * @memberof SubtitleApiGetSubtitle2
     */
    readonly mediaSourceId: string

    /**
     * The subtitle stream index.
     * @type {number}
     * @memberof SubtitleApiGetSubtitle2
     */
    readonly index: number

    /**
     * The format of the returned subtitle.
     * @type {string}
     * @memberof SubtitleApiGetSubtitle2
     */
    readonly format: string

    /**
     * Optional. The start position of the subtitle in ticks.
     * @type {number}
     * @memberof SubtitleApiGetSubtitle2
     */
    readonly startPositionTicks: number

    /**
     * Optional. The end position of the subtitle in ticks.
     * @type {number}
     * @memberof SubtitleApiGetSubtitle2
     */
    readonly endPositionTicks?: number

    /**
     * Optional. Whether to copy the timestamps.
     * @type {boolean}
     * @memberof SubtitleApiGetSubtitle2
     */
    readonly copyTimestamps?: boolean

    /**
     * Optional. Whether to add a VTT time map.
     * @type {boolean}
     * @memberof SubtitleApiGetSubtitle2
     */
    readonly addVttTimeMap?: boolean
}

/**
 * Request parameters for getSubtitlePlaylist operation in SubtitleApi.
 * @export
 * @interface SubtitleApiGetSubtitlePlaylistRequest
 */
export interface SubtitleApiGetSubtitlePlaylistRequest {
    /**
     * The item id.
     * @type {string}
     * @memberof SubtitleApiGetSubtitlePlaylist
     */
    readonly itemId: string

    /**
     * The subtitle stream index.
     * @type {number}
     * @memberof SubtitleApiGetSubtitlePlaylist
     */
    readonly index: number

    /**
     * The media source id.
     * @type {string}
     * @memberof SubtitleApiGetSubtitlePlaylist
     */
    readonly mediaSourceId: string

    /**
     * The subtitle segment length.
     * @type {number}
     * @memberof SubtitleApiGetSubtitlePlaylist
     */
    readonly segmentLength: number
}

/**
 * Request parameters for searchRemoteSubtitles operation in SubtitleApi.
 * @export
 * @interface SubtitleApiSearchRemoteSubtitlesRequest
 */
export interface SubtitleApiSearchRemoteSubtitlesRequest {
    /**
     * The item id.
     * @type {string}
     * @memberof SubtitleApiSearchRemoteSubtitles
     */
    readonly itemId: string

    /**
     * The language of the subtitles.
     * @type {string}
     * @memberof SubtitleApiSearchRemoteSubtitles
     */
    readonly language: string

    /**
     * Optional. Only show subtitles which are a perfect match.
     * @type {boolean}
     * @memberof SubtitleApiSearchRemoteSubtitles
     */
    readonly isPerfectMatch?: boolean
}

/**
 * SubtitleApi - object-oriented interface
 * @export
 * @class SubtitleApi
 * @extends {BaseAPI}
 */
export class SubtitleApi extends BaseAPI {
    /**
     * 
     * @summary Deletes an external subtitle file.
     * @param {SubtitleApiDeleteSubtitleRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubtitleApi
     */
    public deleteSubtitle(requestParameters: SubtitleApiDeleteSubtitleRequest, options?: any) {
        return SubtitleApiFp(this.configuration).deleteSubtitle(requestParameters.itemId, requestParameters.index, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Downloads a remote subtitle.
     * @param {SubtitleApiDownloadRemoteSubtitlesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubtitleApi
     */
    public downloadRemoteSubtitles(requestParameters: SubtitleApiDownloadRemoteSubtitlesRequest, options?: any) {
        return SubtitleApiFp(this.configuration).downloadRemoteSubtitles(requestParameters.itemId, requestParameters.subtitleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets the remote subtitles.
     * @param {SubtitleApiGetRemoteSubtitlesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubtitleApi
     */
    public getRemoteSubtitles(requestParameters: SubtitleApiGetRemoteSubtitlesRequest, options?: any) {
        return SubtitleApiFp(this.configuration).getRemoteSubtitles(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets subtitles in a specified format.
     * @param {SubtitleApiGetSubtitleRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubtitleApi
     */
    public getSubtitle(requestParameters: SubtitleApiGetSubtitleRequest, options?: any) {
        return SubtitleApiFp(this.configuration).getSubtitle(requestParameters.itemId, requestParameters.mediaSourceId, requestParameters.index, requestParameters.format, requestParameters.startPositionTicks, requestParameters.endPositionTicks, requestParameters.copyTimestamps, requestParameters.addVttTimeMap, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets subtitles in a specified format.
     * @param {SubtitleApiGetSubtitle2Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubtitleApi
     */
    public getSubtitle2(requestParameters: SubtitleApiGetSubtitle2Request, options?: any) {
        return SubtitleApiFp(this.configuration).getSubtitle2(requestParameters.itemId, requestParameters.mediaSourceId, requestParameters.index, requestParameters.format, requestParameters.startPositionTicks, requestParameters.endPositionTicks, requestParameters.copyTimestamps, requestParameters.addVttTimeMap, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets an HLS subtitle playlist.
     * @param {SubtitleApiGetSubtitlePlaylistRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubtitleApi
     */
    public getSubtitlePlaylist(requestParameters: SubtitleApiGetSubtitlePlaylistRequest, options?: any) {
        return SubtitleApiFp(this.configuration).getSubtitlePlaylist(requestParameters.itemId, requestParameters.index, requestParameters.mediaSourceId, requestParameters.segmentLength, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Search remote subtitles.
     * @param {SubtitleApiSearchRemoteSubtitlesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubtitleApi
     */
    public searchRemoteSubtitles(requestParameters: SubtitleApiSearchRemoteSubtitlesRequest, options?: any) {
        return SubtitleApiFp(this.configuration).searchRemoteSubtitles(requestParameters.itemId, requestParameters.language, requestParameters.isPerfectMatch, options).then((request) => request(this.axios, this.basePath));
    }
}
