/* tslint:disable */
/* eslint-disable */
/**
 * Jellyfin API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * Enum ImageType.
 * @export
 * @enum {string}
 */
export enum ImageType {
    Primary = 'Primary',
    Art = 'Art',
    Backdrop = 'Backdrop',
    Banner = 'Banner',
    Logo = 'Logo',
    Thumb = 'Thumb',
    Disc = 'Disc',
    Box = 'Box',
    Screenshot = 'Screenshot',
    Menu = 'Menu',
    Chapter = 'Chapter',
    BoxRear = 'BoxRear',
    Profile = 'Profile'
}



