/* tslint:disable */
/* eslint-disable */
/**
 * Jellyfin API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { StartupConfigurationDto } from '../models';
// @ts-ignore
import { StartupRemoteAccessDto } from '../models';
// @ts-ignore
import { StartupUserDto } from '../models';
/**
 * StartupApi - axios parameter creator
 * @export
 */
export const StartupApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Completes the startup wizard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        completeWizard: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/Startup/Complete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets the first user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFirstUser: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/Startup/User`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets the first user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFirstUser2: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/Startup/FirstUser`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets the initial startup wizard configuration.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStartupConfiguration: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/Startup/Configuration`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Sets remote access and UPnP.
         * @param {StartupRemoteAccessDto} startupRemoteAccessDto The startup remote access dto.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setRemoteAccess: async (startupRemoteAccessDto: StartupRemoteAccessDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'startupRemoteAccessDto' is not null or undefined
            if (startupRemoteAccessDto === null || startupRemoteAccessDto === undefined) {
                throw new RequiredError('startupRemoteAccessDto','Required parameter startupRemoteAccessDto was null or undefined when calling setRemoteAccess.');
            }
            const localVarPath = `/Startup/RemoteAccess`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof startupRemoteAccessDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(startupRemoteAccessDto !== undefined ? startupRemoteAccessDto : {}) : (startupRemoteAccessDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Sets the initial startup wizard configuration.
         * @param {StartupConfigurationDto} startupConfigurationDto The updated startup configuration.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateInitialConfiguration: async (startupConfigurationDto: StartupConfigurationDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'startupConfigurationDto' is not null or undefined
            if (startupConfigurationDto === null || startupConfigurationDto === undefined) {
                throw new RequiredError('startupConfigurationDto','Required parameter startupConfigurationDto was null or undefined when calling updateInitialConfiguration.');
            }
            const localVarPath = `/Startup/Configuration`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof startupConfigurationDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(startupConfigurationDto !== undefined ? startupConfigurationDto : {}) : (startupConfigurationDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Sets the user name and password.
         * @param {StartupUserDto} [startupUserDto] The DTO containing username and password.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateStartupUser: async (startupUserDto?: StartupUserDto, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/Startup/User`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof startupUserDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(startupUserDto !== undefined ? startupUserDto : {}) : (startupUserDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StartupApi - functional programming interface
 * @export
 */
export const StartupApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Completes the startup wizard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async completeWizard(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await StartupApiAxiosParamCreator(configuration).completeWizard(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Gets the first user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFirstUser(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StartupUserDto>> {
            const localVarAxiosArgs = await StartupApiAxiosParamCreator(configuration).getFirstUser(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Gets the first user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFirstUser2(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StartupUserDto>> {
            const localVarAxiosArgs = await StartupApiAxiosParamCreator(configuration).getFirstUser2(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Gets the initial startup wizard configuration.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStartupConfiguration(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StartupConfigurationDto>> {
            const localVarAxiosArgs = await StartupApiAxiosParamCreator(configuration).getStartupConfiguration(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Sets remote access and UPnP.
         * @param {StartupRemoteAccessDto} startupRemoteAccessDto The startup remote access dto.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setRemoteAccess(startupRemoteAccessDto: StartupRemoteAccessDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await StartupApiAxiosParamCreator(configuration).setRemoteAccess(startupRemoteAccessDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Sets the initial startup wizard configuration.
         * @param {StartupConfigurationDto} startupConfigurationDto The updated startup configuration.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateInitialConfiguration(startupConfigurationDto: StartupConfigurationDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await StartupApiAxiosParamCreator(configuration).updateInitialConfiguration(startupConfigurationDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Sets the user name and password.
         * @param {StartupUserDto} [startupUserDto] The DTO containing username and password.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateStartupUser(startupUserDto?: StartupUserDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await StartupApiAxiosParamCreator(configuration).updateStartupUser(startupUserDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * StartupApi - factory interface
 * @export
 */
export const StartupApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Completes the startup wizard.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        completeWizard(options?: any): AxiosPromise<void> {
            return StartupApiFp(configuration).completeWizard(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets the first user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFirstUser(options?: any): AxiosPromise<StartupUserDto> {
            return StartupApiFp(configuration).getFirstUser(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets the first user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFirstUser2(options?: any): AxiosPromise<StartupUserDto> {
            return StartupApiFp(configuration).getFirstUser2(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets the initial startup wizard configuration.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStartupConfiguration(options?: any): AxiosPromise<StartupConfigurationDto> {
            return StartupApiFp(configuration).getStartupConfiguration(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Sets remote access and UPnP.
         * @param {StartupRemoteAccessDto} startupRemoteAccessDto The startup remote access dto.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setRemoteAccess(startupRemoteAccessDto: StartupRemoteAccessDto, options?: any): AxiosPromise<void> {
            return StartupApiFp(configuration).setRemoteAccess(startupRemoteAccessDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Sets the initial startup wizard configuration.
         * @param {StartupConfigurationDto} startupConfigurationDto The updated startup configuration.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateInitialConfiguration(startupConfigurationDto: StartupConfigurationDto, options?: any): AxiosPromise<void> {
            return StartupApiFp(configuration).updateInitialConfiguration(startupConfigurationDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Sets the user name and password.
         * @param {StartupUserDto} [startupUserDto] The DTO containing username and password.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateStartupUser(startupUserDto?: StartupUserDto, options?: any): AxiosPromise<void> {
            return StartupApiFp(configuration).updateStartupUser(startupUserDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for setRemoteAccess operation in StartupApi.
 * @export
 * @interface StartupApiSetRemoteAccessRequest
 */
export interface StartupApiSetRemoteAccessRequest {
    /**
     * The startup remote access dto.
     * @type {StartupRemoteAccessDto}
     * @memberof StartupApiSetRemoteAccess
     */
    readonly startupRemoteAccessDto: StartupRemoteAccessDto
}

/**
 * Request parameters for updateInitialConfiguration operation in StartupApi.
 * @export
 * @interface StartupApiUpdateInitialConfigurationRequest
 */
export interface StartupApiUpdateInitialConfigurationRequest {
    /**
     * The updated startup configuration.
     * @type {StartupConfigurationDto}
     * @memberof StartupApiUpdateInitialConfiguration
     */
    readonly startupConfigurationDto: StartupConfigurationDto
}

/**
 * Request parameters for updateStartupUser operation in StartupApi.
 * @export
 * @interface StartupApiUpdateStartupUserRequest
 */
export interface StartupApiUpdateStartupUserRequest {
    /**
     * The DTO containing username and password.
     * @type {StartupUserDto}
     * @memberof StartupApiUpdateStartupUser
     */
    readonly startupUserDto?: StartupUserDto
}

/**
 * StartupApi - object-oriented interface
 * @export
 * @class StartupApi
 * @extends {BaseAPI}
 */
export class StartupApi extends BaseAPI {
    /**
     * 
     * @summary Completes the startup wizard.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StartupApi
     */
    public completeWizard(options?: any) {
        return StartupApiFp(this.configuration).completeWizard(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets the first user.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StartupApi
     */
    public getFirstUser(options?: any) {
        return StartupApiFp(this.configuration).getFirstUser(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets the first user.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StartupApi
     */
    public getFirstUser2(options?: any) {
        return StartupApiFp(this.configuration).getFirstUser2(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets the initial startup wizard configuration.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StartupApi
     */
    public getStartupConfiguration(options?: any) {
        return StartupApiFp(this.configuration).getStartupConfiguration(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Sets remote access and UPnP.
     * @param {StartupApiSetRemoteAccessRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StartupApi
     */
    public setRemoteAccess(requestParameters: StartupApiSetRemoteAccessRequest, options?: any) {
        return StartupApiFp(this.configuration).setRemoteAccess(requestParameters.startupRemoteAccessDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Sets the initial startup wizard configuration.
     * @param {StartupApiUpdateInitialConfigurationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StartupApi
     */
    public updateInitialConfiguration(requestParameters: StartupApiUpdateInitialConfigurationRequest, options?: any) {
        return StartupApiFp(this.configuration).updateInitialConfiguration(requestParameters.startupConfigurationDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Sets the user name and password.
     * @param {StartupApiUpdateStartupUserRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StartupApi
     */
    public updateStartupUser(requestParameters: StartupApiUpdateStartupUserRequest = {}, options?: any) {
        return StartupApiFp(this.configuration).updateStartupUser(requestParameters.startupUserDto, options).then((request) => request(this.axios, this.basePath));
    }
}
