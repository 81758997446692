/* tslint:disable */
/* eslint-disable */
/**
 * Jellyfin API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { BaseItemDtoQueryResult } from '../models';
/**
 * AlbumsApi - axios parameter creator
 * @export
 */
export const AlbumsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Finds albums similar to a given album.
         * @param {string} albumId The album id.
         * @param {string} [userId] Optional. Filter by user id, and attach user data.
         * @param {string} [excludeArtistIds] Optional. Ids of artists to exclude.
         * @param {number} [limit] Optional. The maximum number of records to return.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSimilarAlbums: async (albumId: string, userId?: string, excludeArtistIds?: string, limit?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'albumId' is not null or undefined
            if (albumId === null || albumId === undefined) {
                throw new RequiredError('albumId','Required parameter albumId was null or undefined when calling getSimilarAlbums.');
            }
            const localVarPath = `/Albums/{albumId}/Similar`
                .replace(`{${"albumId"}}`, encodeURIComponent(String(albumId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }

            if (excludeArtistIds !== undefined) {
                localVarQueryParameter['excludeArtistIds'] = excludeArtistIds;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Finds artists similar to a given artist.
         * @param {string} artistId The artist id.
         * @param {string} [userId] Optional. Filter by user id, and attach user data.
         * @param {string} [excludeArtistIds] Optional. Ids of artists to exclude.
         * @param {number} [limit] Optional. The maximum number of records to return.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSimilarArtists: async (artistId: string, userId?: string, excludeArtistIds?: string, limit?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'artistId' is not null or undefined
            if (artistId === null || artistId === undefined) {
                throw new RequiredError('artistId','Required parameter artistId was null or undefined when calling getSimilarArtists.');
            }
            const localVarPath = `/Artists/{artistId}/Similar`
                .replace(`{${"artistId"}}`, encodeURIComponent(String(artistId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }

            if (excludeArtistIds !== undefined) {
                localVarQueryParameter['excludeArtistIds'] = excludeArtistIds;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AlbumsApi - functional programming interface
 * @export
 */
export const AlbumsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Finds albums similar to a given album.
         * @param {string} albumId The album id.
         * @param {string} [userId] Optional. Filter by user id, and attach user data.
         * @param {string} [excludeArtistIds] Optional. Ids of artists to exclude.
         * @param {number} [limit] Optional. The maximum number of records to return.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSimilarAlbums(albumId: string, userId?: string, excludeArtistIds?: string, limit?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BaseItemDtoQueryResult>> {
            const localVarAxiosArgs = await AlbumsApiAxiosParamCreator(configuration).getSimilarAlbums(albumId, userId, excludeArtistIds, limit, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Finds artists similar to a given artist.
         * @param {string} artistId The artist id.
         * @param {string} [userId] Optional. Filter by user id, and attach user data.
         * @param {string} [excludeArtistIds] Optional. Ids of artists to exclude.
         * @param {number} [limit] Optional. The maximum number of records to return.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSimilarArtists(artistId: string, userId?: string, excludeArtistIds?: string, limit?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BaseItemDtoQueryResult>> {
            const localVarAxiosArgs = await AlbumsApiAxiosParamCreator(configuration).getSimilarArtists(artistId, userId, excludeArtistIds, limit, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * AlbumsApi - factory interface
 * @export
 */
export const AlbumsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Finds albums similar to a given album.
         * @param {string} albumId The album id.
         * @param {string} [userId] Optional. Filter by user id, and attach user data.
         * @param {string} [excludeArtistIds] Optional. Ids of artists to exclude.
         * @param {number} [limit] Optional. The maximum number of records to return.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSimilarAlbums(albumId: string, userId?: string, excludeArtistIds?: string, limit?: number, options?: any): AxiosPromise<BaseItemDtoQueryResult> {
            return AlbumsApiFp(configuration).getSimilarAlbums(albumId, userId, excludeArtistIds, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Finds artists similar to a given artist.
         * @param {string} artistId The artist id.
         * @param {string} [userId] Optional. Filter by user id, and attach user data.
         * @param {string} [excludeArtistIds] Optional. Ids of artists to exclude.
         * @param {number} [limit] Optional. The maximum number of records to return.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSimilarArtists(artistId: string, userId?: string, excludeArtistIds?: string, limit?: number, options?: any): AxiosPromise<BaseItemDtoQueryResult> {
            return AlbumsApiFp(configuration).getSimilarArtists(artistId, userId, excludeArtistIds, limit, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getSimilarAlbums operation in AlbumsApi.
 * @export
 * @interface AlbumsApiGetSimilarAlbumsRequest
 */
export interface AlbumsApiGetSimilarAlbumsRequest {
    /**
     * The album id.
     * @type {string}
     * @memberof AlbumsApiGetSimilarAlbums
     */
    readonly albumId: string

    /**
     * Optional. Filter by user id, and attach user data.
     * @type {string}
     * @memberof AlbumsApiGetSimilarAlbums
     */
    readonly userId?: string

    /**
     * Optional. Ids of artists to exclude.
     * @type {string}
     * @memberof AlbumsApiGetSimilarAlbums
     */
    readonly excludeArtistIds?: string

    /**
     * Optional. The maximum number of records to return.
     * @type {number}
     * @memberof AlbumsApiGetSimilarAlbums
     */
    readonly limit?: number
}

/**
 * Request parameters for getSimilarArtists operation in AlbumsApi.
 * @export
 * @interface AlbumsApiGetSimilarArtistsRequest
 */
export interface AlbumsApiGetSimilarArtistsRequest {
    /**
     * The artist id.
     * @type {string}
     * @memberof AlbumsApiGetSimilarArtists
     */
    readonly artistId: string

    /**
     * Optional. Filter by user id, and attach user data.
     * @type {string}
     * @memberof AlbumsApiGetSimilarArtists
     */
    readonly userId?: string

    /**
     * Optional. Ids of artists to exclude.
     * @type {string}
     * @memberof AlbumsApiGetSimilarArtists
     */
    readonly excludeArtistIds?: string

    /**
     * Optional. The maximum number of records to return.
     * @type {number}
     * @memberof AlbumsApiGetSimilarArtists
     */
    readonly limit?: number
}

/**
 * AlbumsApi - object-oriented interface
 * @export
 * @class AlbumsApi
 * @extends {BaseAPI}
 */
export class AlbumsApi extends BaseAPI {
    /**
     * 
     * @summary Finds albums similar to a given album.
     * @param {AlbumsApiGetSimilarAlbumsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AlbumsApi
     */
    public getSimilarAlbums(requestParameters: AlbumsApiGetSimilarAlbumsRequest, options?: any) {
        return AlbumsApiFp(this.configuration).getSimilarAlbums(requestParameters.albumId, requestParameters.userId, requestParameters.excludeArtistIds, requestParameters.limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Finds artists similar to a given artist.
     * @param {AlbumsApiGetSimilarArtistsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AlbumsApi
     */
    public getSimilarArtists(requestParameters: AlbumsApiGetSimilarArtistsRequest, options?: any) {
        return AlbumsApiFp(this.configuration).getSimilarArtists(requestParameters.artistId, requestParameters.userId, requestParameters.excludeArtistIds, requestParameters.limit, options).then((request) => request(this.axios, this.basePath));
    }
}
