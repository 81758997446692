/* tslint:disable */
/* eslint-disable */
/**
 * Jellyfin API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { RecommendationDto } from '../models';
/**
 * MoviesApi - axios parameter creator
 * @export
 */
export const MoviesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Gets movie recommendations.
         * @param {string} [userId] Optional. Filter by user id, and attach user data.
         * @param {string} [parentId] Specify this to localize the search to a specific item or folder. Omit to use the root.
         * @param {string} [fields] Optional. The fields to return.
         * @param {number} [categoryLimit] The max number of categories to return.
         * @param {number} [itemLimit] The max number of items to return per category.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMovieRecommendations: async (userId?: string, parentId?: string, fields?: string, categoryLimit?: number, itemLimit?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/Movies/Recommendations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }

            if (parentId !== undefined) {
                localVarQueryParameter['parentId'] = parentId;
            }

            if (fields !== undefined) {
                localVarQueryParameter['fields'] = fields;
            }

            if (categoryLimit !== undefined) {
                localVarQueryParameter['categoryLimit'] = categoryLimit;
            }

            if (itemLimit !== undefined) {
                localVarQueryParameter['itemLimit'] = itemLimit;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MoviesApi - functional programming interface
 * @export
 */
export const MoviesApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Gets movie recommendations.
         * @param {string} [userId] Optional. Filter by user id, and attach user data.
         * @param {string} [parentId] Specify this to localize the search to a specific item or folder. Omit to use the root.
         * @param {string} [fields] Optional. The fields to return.
         * @param {number} [categoryLimit] The max number of categories to return.
         * @param {number} [itemLimit] The max number of items to return per category.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMovieRecommendations(userId?: string, parentId?: string, fields?: string, categoryLimit?: number, itemLimit?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RecommendationDto>>> {
            const localVarAxiosArgs = await MoviesApiAxiosParamCreator(configuration).getMovieRecommendations(userId, parentId, fields, categoryLimit, itemLimit, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * MoviesApi - factory interface
 * @export
 */
export const MoviesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Gets movie recommendations.
         * @param {string} [userId] Optional. Filter by user id, and attach user data.
         * @param {string} [parentId] Specify this to localize the search to a specific item or folder. Omit to use the root.
         * @param {string} [fields] Optional. The fields to return.
         * @param {number} [categoryLimit] The max number of categories to return.
         * @param {number} [itemLimit] The max number of items to return per category.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMovieRecommendations(userId?: string, parentId?: string, fields?: string, categoryLimit?: number, itemLimit?: number, options?: any): AxiosPromise<Array<RecommendationDto>> {
            return MoviesApiFp(configuration).getMovieRecommendations(userId, parentId, fields, categoryLimit, itemLimit, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getMovieRecommendations operation in MoviesApi.
 * @export
 * @interface MoviesApiGetMovieRecommendationsRequest
 */
export interface MoviesApiGetMovieRecommendationsRequest {
    /**
     * Optional. Filter by user id, and attach user data.
     * @type {string}
     * @memberof MoviesApiGetMovieRecommendations
     */
    readonly userId?: string

    /**
     * Specify this to localize the search to a specific item or folder. Omit to use the root.
     * @type {string}
     * @memberof MoviesApiGetMovieRecommendations
     */
    readonly parentId?: string

    /**
     * Optional. The fields to return.
     * @type {string}
     * @memberof MoviesApiGetMovieRecommendations
     */
    readonly fields?: string

    /**
     * The max number of categories to return.
     * @type {number}
     * @memberof MoviesApiGetMovieRecommendations
     */
    readonly categoryLimit?: number

    /**
     * The max number of items to return per category.
     * @type {number}
     * @memberof MoviesApiGetMovieRecommendations
     */
    readonly itemLimit?: number
}

/**
 * MoviesApi - object-oriented interface
 * @export
 * @class MoviesApi
 * @extends {BaseAPI}
 */
export class MoviesApi extends BaseAPI {
    /**
     * 
     * @summary Gets movie recommendations.
     * @param {MoviesApiGetMovieRecommendationsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MoviesApi
     */
    public getMovieRecommendations(requestParameters: MoviesApiGetMovieRecommendationsRequest = {}, options?: any) {
        return MoviesApiFp(this.configuration).getMovieRecommendations(requestParameters.userId, requestParameters.parentId, requestParameters.fields, requestParameters.categoryLimit, requestParameters.itemLimit, options).then((request) => request(this.axios, this.basePath));
    }
}
