/* tslint:disable */
/* eslint-disable */
/**
 * Jellyfin API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AuthenticateUserByName } from '../models';
// @ts-ignore
import { AuthenticationResult } from '../models';
// @ts-ignore
import { CreateUserByName } from '../models';
// @ts-ignore
import { ForgotPasswordDto } from '../models';
// @ts-ignore
import { ForgotPasswordResult } from '../models';
// @ts-ignore
import { PinRedeemResult } from '../models';
// @ts-ignore
import { ProblemDetails } from '../models';
// @ts-ignore
import { QuickConnectDto } from '../models';
// @ts-ignore
import { UpdateUserEasyPassword } from '../models';
// @ts-ignore
import { UpdateUserPassword } from '../models';
// @ts-ignore
import { UserConfiguration } from '../models';
// @ts-ignore
import { UserDto } from '../models';
// @ts-ignore
import { UserPolicy } from '../models';
/**
 * UserApi - axios parameter creator
 * @export
 */
export const UserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Authenticates a user.
         * @param {string} userId The user id.
         * @param {string} pw The password as plain text.
         * @param {string} [password] The password sha1-hash.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticateUser: async (userId: string, pw: string, password?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId','Required parameter userId was null or undefined when calling authenticateUser.');
            }
            // verify required parameter 'pw' is not null or undefined
            if (pw === null || pw === undefined) {
                throw new RequiredError('pw','Required parameter pw was null or undefined when calling authenticateUser.');
            }
            const localVarPath = `/Users/{userId}/Authenticate`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }

            if (pw !== undefined) {
                localVarQueryParameter['pw'] = pw;
            }

            if (password !== undefined) {
                localVarQueryParameter['password'] = password;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Authenticates a user by name.
         * @param {AuthenticateUserByName} authenticateUserByName The M:Jellyfin.Api.Controllers.UserController.AuthenticateUserByName(Jellyfin.Api.Models.UserDtos.AuthenticateUserByName) request.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticateUserByName: async (authenticateUserByName: AuthenticateUserByName, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'authenticateUserByName' is not null or undefined
            if (authenticateUserByName === null || authenticateUserByName === undefined) {
                throw new RequiredError('authenticateUserByName','Required parameter authenticateUserByName was null or undefined when calling authenticateUserByName.');
            }
            const localVarPath = `/Users/AuthenticateByName`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof authenticateUserByName !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(authenticateUserByName !== undefined ? authenticateUserByName : {}) : (authenticateUserByName || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Authenticates a user with quick connect.
         * @param {QuickConnectDto} quickConnectDto The Jellyfin.Api.Models.UserDtos.QuickConnectDto request.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticateWithQuickConnect: async (quickConnectDto: QuickConnectDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'quickConnectDto' is not null or undefined
            if (quickConnectDto === null || quickConnectDto === undefined) {
                throw new RequiredError('quickConnectDto','Required parameter quickConnectDto was null or undefined when calling authenticateWithQuickConnect.');
            }
            const localVarPath = `/Users/AuthenticateWithQuickConnect`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof quickConnectDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(quickConnectDto !== undefined ? quickConnectDto : {}) : (quickConnectDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates a user.
         * @param {CreateUserByName} [createUserByName] The create user by name request body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUserByName: async (createUserByName?: CreateUserByName, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/Users/New`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof createUserByName !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(createUserByName !== undefined ? createUserByName : {}) : (createUserByName || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deletes a user.
         * @param {string} userId The user id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUser: async (userId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId','Required parameter userId was null or undefined when calling deleteUser.');
            }
            const localVarPath = `/Users/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Initiates the forgot password process for a local user.
         * @param {ForgotPasswordDto} forgotPasswordDto The forgot password request containing the entered username.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forgotPassword: async (forgotPasswordDto: ForgotPasswordDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'forgotPasswordDto' is not null or undefined
            if (forgotPasswordDto === null || forgotPasswordDto === undefined) {
                throw new RequiredError('forgotPasswordDto','Required parameter forgotPasswordDto was null or undefined when calling forgotPassword.');
            }
            const localVarPath = `/Users/ForgotPassword`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof forgotPasswordDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(forgotPasswordDto !== undefined ? forgotPasswordDto : {}) : (forgotPasswordDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Redeems a forgot password pin.
         * @param {string} [body] The pin.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forgotPasswordPin: async (body?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/Users/ForgotPassword/Pin`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets a list of publicly visible users for display on a login screen.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPublicUsers: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/Users/Public`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets a user by Id.
         * @param {string} userId The user id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserById: async (userId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId','Required parameter userId was null or undefined when calling getUserById.');
            }
            const localVarPath = `/Users/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets a list of users.
         * @param {boolean} [isHidden] Optional filter by IsHidden&#x3D;true or false.
         * @param {boolean} [isDisabled] Optional filter by IsDisabled&#x3D;true or false.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsers: async (isHidden?: boolean, isDisabled?: boolean, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/Users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }

            if (isHidden !== undefined) {
                localVarQueryParameter['isHidden'] = isHidden;
            }

            if (isDisabled !== undefined) {
                localVarQueryParameter['isDisabled'] = isDisabled;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates a user.
         * @param {string} userId The user id.
         * @param {UserDto} [userDto] The updated user model.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUser: async (userId: string, userDto?: UserDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId','Required parameter userId was null or undefined when calling updateUser.');
            }
            const localVarPath = `/Users/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof userDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(userDto !== undefined ? userDto : {}) : (userDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates a user configuration.
         * @param {string} userId The user id.
         * @param {UserConfiguration} [userConfiguration] The new user configuration.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserConfiguration: async (userId: string, userConfiguration?: UserConfiguration, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId','Required parameter userId was null or undefined when calling updateUserConfiguration.');
            }
            const localVarPath = `/Users/{userId}/Configuration`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof userConfiguration !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(userConfiguration !== undefined ? userConfiguration : {}) : (userConfiguration || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates a user\'s easy password.
         * @param {string} userId The user id.
         * @param {UpdateUserEasyPassword} [updateUserEasyPassword] The M:Jellyfin.Api.Controllers.UserController.UpdateUserEasyPassword(System.Guid,Jellyfin.Api.Models.UserDtos.UpdateUserEasyPassword) request.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserEasyPassword: async (userId: string, updateUserEasyPassword?: UpdateUserEasyPassword, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId','Required parameter userId was null or undefined when calling updateUserEasyPassword.');
            }
            const localVarPath = `/Users/{userId}/EasyPassword`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof updateUserEasyPassword !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(updateUserEasyPassword !== undefined ? updateUserEasyPassword : {}) : (updateUserEasyPassword || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates a user\'s password.
         * @param {string} userId The user id.
         * @param {UpdateUserPassword} [updateUserPassword] The M:Jellyfin.Api.Controllers.UserController.UpdateUserPassword(System.Guid,Jellyfin.Api.Models.UserDtos.UpdateUserPassword) request.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserPassword: async (userId: string, updateUserPassword?: UpdateUserPassword, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId','Required parameter userId was null or undefined when calling updateUserPassword.');
            }
            const localVarPath = `/Users/{userId}/Password`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof updateUserPassword !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(updateUserPassword !== undefined ? updateUserPassword : {}) : (updateUserPassword || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates a user policy.
         * @param {string} userId The user id.
         * @param {UserPolicy} [userPolicy] The new user policy.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserPolicy: async (userId: string, userPolicy?: UserPolicy, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId','Required parameter userId was null or undefined when calling updateUserPolicy.');
            }
            const localVarPath = `/Users/{userId}/Policy`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof userPolicy !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(userPolicy !== undefined ? userPolicy : {}) : (userPolicy || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Authenticates a user.
         * @param {string} userId The user id.
         * @param {string} pw The password as plain text.
         * @param {string} [password] The password sha1-hash.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authenticateUser(userId: string, pw: string, password?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthenticationResult>> {
            const localVarAxiosArgs = await UserApiAxiosParamCreator(configuration).authenticateUser(userId, pw, password, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Authenticates a user by name.
         * @param {AuthenticateUserByName} authenticateUserByName The M:Jellyfin.Api.Controllers.UserController.AuthenticateUserByName(Jellyfin.Api.Models.UserDtos.AuthenticateUserByName) request.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authenticateUserByName(authenticateUserByName: AuthenticateUserByName, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthenticationResult>> {
            const localVarAxiosArgs = await UserApiAxiosParamCreator(configuration).authenticateUserByName(authenticateUserByName, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Authenticates a user with quick connect.
         * @param {QuickConnectDto} quickConnectDto The Jellyfin.Api.Models.UserDtos.QuickConnectDto request.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authenticateWithQuickConnect(quickConnectDto: QuickConnectDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthenticationResult>> {
            const localVarAxiosArgs = await UserApiAxiosParamCreator(configuration).authenticateWithQuickConnect(quickConnectDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Creates a user.
         * @param {CreateUserByName} [createUserByName] The create user by name request body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createUserByName(createUserByName?: CreateUserByName, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDto>> {
            const localVarAxiosArgs = await UserApiAxiosParamCreator(configuration).createUserByName(createUserByName, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Deletes a user.
         * @param {string} userId The user id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteUser(userId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await UserApiAxiosParamCreator(configuration).deleteUser(userId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Initiates the forgot password process for a local user.
         * @param {ForgotPasswordDto} forgotPasswordDto The forgot password request containing the entered username.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forgotPassword(forgotPasswordDto: ForgotPasswordDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ForgotPasswordResult>> {
            const localVarAxiosArgs = await UserApiAxiosParamCreator(configuration).forgotPassword(forgotPasswordDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Redeems a forgot password pin.
         * @param {string} [body] The pin.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forgotPasswordPin(body?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PinRedeemResult>> {
            const localVarAxiosArgs = await UserApiAxiosParamCreator(configuration).forgotPasswordPin(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Gets a list of publicly visible users for display on a login screen.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPublicUsers(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserDto>>> {
            const localVarAxiosArgs = await UserApiAxiosParamCreator(configuration).getPublicUsers(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Gets a user by Id.
         * @param {string} userId The user id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserById(userId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDto>> {
            const localVarAxiosArgs = await UserApiAxiosParamCreator(configuration).getUserById(userId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Gets a list of users.
         * @param {boolean} [isHidden] Optional filter by IsHidden&#x3D;true or false.
         * @param {boolean} [isDisabled] Optional filter by IsDisabled&#x3D;true or false.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUsers(isHidden?: boolean, isDisabled?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserDto>>> {
            const localVarAxiosArgs = await UserApiAxiosParamCreator(configuration).getUsers(isHidden, isDisabled, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Updates a user.
         * @param {string} userId The user id.
         * @param {UserDto} [userDto] The updated user model.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUser(userId: string, userDto?: UserDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await UserApiAxiosParamCreator(configuration).updateUser(userId, userDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Updates a user configuration.
         * @param {string} userId The user id.
         * @param {UserConfiguration} [userConfiguration] The new user configuration.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUserConfiguration(userId: string, userConfiguration?: UserConfiguration, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await UserApiAxiosParamCreator(configuration).updateUserConfiguration(userId, userConfiguration, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Updates a user\'s easy password.
         * @param {string} userId The user id.
         * @param {UpdateUserEasyPassword} [updateUserEasyPassword] The M:Jellyfin.Api.Controllers.UserController.UpdateUserEasyPassword(System.Guid,Jellyfin.Api.Models.UserDtos.UpdateUserEasyPassword) request.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUserEasyPassword(userId: string, updateUserEasyPassword?: UpdateUserEasyPassword, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await UserApiAxiosParamCreator(configuration).updateUserEasyPassword(userId, updateUserEasyPassword, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Updates a user\'s password.
         * @param {string} userId The user id.
         * @param {UpdateUserPassword} [updateUserPassword] The M:Jellyfin.Api.Controllers.UserController.UpdateUserPassword(System.Guid,Jellyfin.Api.Models.UserDtos.UpdateUserPassword) request.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUserPassword(userId: string, updateUserPassword?: UpdateUserPassword, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await UserApiAxiosParamCreator(configuration).updateUserPassword(userId, updateUserPassword, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Updates a user policy.
         * @param {string} userId The user id.
         * @param {UserPolicy} [userPolicy] The new user policy.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUserPolicy(userId: string, userPolicy?: UserPolicy, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await UserApiAxiosParamCreator(configuration).updateUserPolicy(userId, userPolicy, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Authenticates a user.
         * @param {string} userId The user id.
         * @param {string} pw The password as plain text.
         * @param {string} [password] The password sha1-hash.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticateUser(userId: string, pw: string, password?: string, options?: any): AxiosPromise<AuthenticationResult> {
            return UserApiFp(configuration).authenticateUser(userId, pw, password, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Authenticates a user by name.
         * @param {AuthenticateUserByName} authenticateUserByName The M:Jellyfin.Api.Controllers.UserController.AuthenticateUserByName(Jellyfin.Api.Models.UserDtos.AuthenticateUserByName) request.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticateUserByName(authenticateUserByName: AuthenticateUserByName, options?: any): AxiosPromise<AuthenticationResult> {
            return UserApiFp(configuration).authenticateUserByName(authenticateUserByName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Authenticates a user with quick connect.
         * @param {QuickConnectDto} quickConnectDto The Jellyfin.Api.Models.UserDtos.QuickConnectDto request.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticateWithQuickConnect(quickConnectDto: QuickConnectDto, options?: any): AxiosPromise<AuthenticationResult> {
            return UserApiFp(configuration).authenticateWithQuickConnect(quickConnectDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates a user.
         * @param {CreateUserByName} [createUserByName] The create user by name request body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUserByName(createUserByName?: CreateUserByName, options?: any): AxiosPromise<UserDto> {
            return UserApiFp(configuration).createUserByName(createUserByName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Deletes a user.
         * @param {string} userId The user id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUser(userId: string, options?: any): AxiosPromise<void> {
            return UserApiFp(configuration).deleteUser(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Initiates the forgot password process for a local user.
         * @param {ForgotPasswordDto} forgotPasswordDto The forgot password request containing the entered username.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forgotPassword(forgotPasswordDto: ForgotPasswordDto, options?: any): AxiosPromise<ForgotPasswordResult> {
            return UserApiFp(configuration).forgotPassword(forgotPasswordDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Redeems a forgot password pin.
         * @param {string} [body] The pin.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forgotPasswordPin(body?: string, options?: any): AxiosPromise<PinRedeemResult> {
            return UserApiFp(configuration).forgotPasswordPin(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets a list of publicly visible users for display on a login screen.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPublicUsers(options?: any): AxiosPromise<Array<UserDto>> {
            return UserApiFp(configuration).getPublicUsers(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets a user by Id.
         * @param {string} userId The user id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserById(userId: string, options?: any): AxiosPromise<UserDto> {
            return UserApiFp(configuration).getUserById(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets a list of users.
         * @param {boolean} [isHidden] Optional filter by IsHidden&#x3D;true or false.
         * @param {boolean} [isDisabled] Optional filter by IsDisabled&#x3D;true or false.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsers(isHidden?: boolean, isDisabled?: boolean, options?: any): AxiosPromise<Array<UserDto>> {
            return UserApiFp(configuration).getUsers(isHidden, isDisabled, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates a user.
         * @param {string} userId The user id.
         * @param {UserDto} [userDto] The updated user model.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUser(userId: string, userDto?: UserDto, options?: any): AxiosPromise<void> {
            return UserApiFp(configuration).updateUser(userId, userDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates a user configuration.
         * @param {string} userId The user id.
         * @param {UserConfiguration} [userConfiguration] The new user configuration.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserConfiguration(userId: string, userConfiguration?: UserConfiguration, options?: any): AxiosPromise<void> {
            return UserApiFp(configuration).updateUserConfiguration(userId, userConfiguration, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates a user\'s easy password.
         * @param {string} userId The user id.
         * @param {UpdateUserEasyPassword} [updateUserEasyPassword] The M:Jellyfin.Api.Controllers.UserController.UpdateUserEasyPassword(System.Guid,Jellyfin.Api.Models.UserDtos.UpdateUserEasyPassword) request.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserEasyPassword(userId: string, updateUserEasyPassword?: UpdateUserEasyPassword, options?: any): AxiosPromise<void> {
            return UserApiFp(configuration).updateUserEasyPassword(userId, updateUserEasyPassword, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates a user\'s password.
         * @param {string} userId The user id.
         * @param {UpdateUserPassword} [updateUserPassword] The M:Jellyfin.Api.Controllers.UserController.UpdateUserPassword(System.Guid,Jellyfin.Api.Models.UserDtos.UpdateUserPassword) request.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserPassword(userId: string, updateUserPassword?: UpdateUserPassword, options?: any): AxiosPromise<void> {
            return UserApiFp(configuration).updateUserPassword(userId, updateUserPassword, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates a user policy.
         * @param {string} userId The user id.
         * @param {UserPolicy} [userPolicy] The new user policy.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserPolicy(userId: string, userPolicy?: UserPolicy, options?: any): AxiosPromise<void> {
            return UserApiFp(configuration).updateUserPolicy(userId, userPolicy, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for authenticateUser operation in UserApi.
 * @export
 * @interface UserApiAuthenticateUserRequest
 */
export interface UserApiAuthenticateUserRequest {
    /**
     * The user id.
     * @type {string}
     * @memberof UserApiAuthenticateUser
     */
    readonly userId: string

    /**
     * The password as plain text.
     * @type {string}
     * @memberof UserApiAuthenticateUser
     */
    readonly pw: string

    /**
     * The password sha1-hash.
     * @type {string}
     * @memberof UserApiAuthenticateUser
     */
    readonly password?: string
}

/**
 * Request parameters for authenticateUserByName operation in UserApi.
 * @export
 * @interface UserApiAuthenticateUserByNameRequest
 */
export interface UserApiAuthenticateUserByNameRequest {
    /**
     * The M:Jellyfin.Api.Controllers.UserController.AuthenticateUserByName(Jellyfin.Api.Models.UserDtos.AuthenticateUserByName) request.
     * @type {AuthenticateUserByName}
     * @memberof UserApiAuthenticateUserByName
     */
    readonly authenticateUserByName: AuthenticateUserByName
}

/**
 * Request parameters for authenticateWithQuickConnect operation in UserApi.
 * @export
 * @interface UserApiAuthenticateWithQuickConnectRequest
 */
export interface UserApiAuthenticateWithQuickConnectRequest {
    /**
     * The Jellyfin.Api.Models.UserDtos.QuickConnectDto request.
     * @type {QuickConnectDto}
     * @memberof UserApiAuthenticateWithQuickConnect
     */
    readonly quickConnectDto: QuickConnectDto
}

/**
 * Request parameters for createUserByName operation in UserApi.
 * @export
 * @interface UserApiCreateUserByNameRequest
 */
export interface UserApiCreateUserByNameRequest {
    /**
     * The create user by name request body.
     * @type {CreateUserByName}
     * @memberof UserApiCreateUserByName
     */
    readonly createUserByName?: CreateUserByName
}

/**
 * Request parameters for deleteUser operation in UserApi.
 * @export
 * @interface UserApiDeleteUserRequest
 */
export interface UserApiDeleteUserRequest {
    /**
     * The user id.
     * @type {string}
     * @memberof UserApiDeleteUser
     */
    readonly userId: string
}

/**
 * Request parameters for forgotPassword operation in UserApi.
 * @export
 * @interface UserApiForgotPasswordRequest
 */
export interface UserApiForgotPasswordRequest {
    /**
     * The forgot password request containing the entered username.
     * @type {ForgotPasswordDto}
     * @memberof UserApiForgotPassword
     */
    readonly forgotPasswordDto: ForgotPasswordDto
}

/**
 * Request parameters for forgotPasswordPin operation in UserApi.
 * @export
 * @interface UserApiForgotPasswordPinRequest
 */
export interface UserApiForgotPasswordPinRequest {
    /**
     * The pin.
     * @type {string}
     * @memberof UserApiForgotPasswordPin
     */
    readonly body?: string
}

/**
 * Request parameters for getUserById operation in UserApi.
 * @export
 * @interface UserApiGetUserByIdRequest
 */
export interface UserApiGetUserByIdRequest {
    /**
     * The user id.
     * @type {string}
     * @memberof UserApiGetUserById
     */
    readonly userId: string
}

/**
 * Request parameters for getUsers operation in UserApi.
 * @export
 * @interface UserApiGetUsersRequest
 */
export interface UserApiGetUsersRequest {
    /**
     * Optional filter by IsHidden&#x3D;true or false.
     * @type {boolean}
     * @memberof UserApiGetUsers
     */
    readonly isHidden?: boolean

    /**
     * Optional filter by IsDisabled&#x3D;true or false.
     * @type {boolean}
     * @memberof UserApiGetUsers
     */
    readonly isDisabled?: boolean
}

/**
 * Request parameters for updateUser operation in UserApi.
 * @export
 * @interface UserApiUpdateUserRequest
 */
export interface UserApiUpdateUserRequest {
    /**
     * The user id.
     * @type {string}
     * @memberof UserApiUpdateUser
     */
    readonly userId: string

    /**
     * The updated user model.
     * @type {UserDto}
     * @memberof UserApiUpdateUser
     */
    readonly userDto?: UserDto
}

/**
 * Request parameters for updateUserConfiguration operation in UserApi.
 * @export
 * @interface UserApiUpdateUserConfigurationRequest
 */
export interface UserApiUpdateUserConfigurationRequest {
    /**
     * The user id.
     * @type {string}
     * @memberof UserApiUpdateUserConfiguration
     */
    readonly userId: string

    /**
     * The new user configuration.
     * @type {UserConfiguration}
     * @memberof UserApiUpdateUserConfiguration
     */
    readonly userConfiguration?: UserConfiguration
}

/**
 * Request parameters for updateUserEasyPassword operation in UserApi.
 * @export
 * @interface UserApiUpdateUserEasyPasswordRequest
 */
export interface UserApiUpdateUserEasyPasswordRequest {
    /**
     * The user id.
     * @type {string}
     * @memberof UserApiUpdateUserEasyPassword
     */
    readonly userId: string

    /**
     * The M:Jellyfin.Api.Controllers.UserController.UpdateUserEasyPassword(System.Guid,Jellyfin.Api.Models.UserDtos.UpdateUserEasyPassword) request.
     * @type {UpdateUserEasyPassword}
     * @memberof UserApiUpdateUserEasyPassword
     */
    readonly updateUserEasyPassword?: UpdateUserEasyPassword
}

/**
 * Request parameters for updateUserPassword operation in UserApi.
 * @export
 * @interface UserApiUpdateUserPasswordRequest
 */
export interface UserApiUpdateUserPasswordRequest {
    /**
     * The user id.
     * @type {string}
     * @memberof UserApiUpdateUserPassword
     */
    readonly userId: string

    /**
     * The M:Jellyfin.Api.Controllers.UserController.UpdateUserPassword(System.Guid,Jellyfin.Api.Models.UserDtos.UpdateUserPassword) request.
     * @type {UpdateUserPassword}
     * @memberof UserApiUpdateUserPassword
     */
    readonly updateUserPassword?: UpdateUserPassword
}

/**
 * Request parameters for updateUserPolicy operation in UserApi.
 * @export
 * @interface UserApiUpdateUserPolicyRequest
 */
export interface UserApiUpdateUserPolicyRequest {
    /**
     * The user id.
     * @type {string}
     * @memberof UserApiUpdateUserPolicy
     */
    readonly userId: string

    /**
     * The new user policy.
     * @type {UserPolicy}
     * @memberof UserApiUpdateUserPolicy
     */
    readonly userPolicy?: UserPolicy
}

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI {
    /**
     * 
     * @summary Authenticates a user.
     * @param {UserApiAuthenticateUserRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public authenticateUser(requestParameters: UserApiAuthenticateUserRequest, options?: any) {
        return UserApiFp(this.configuration).authenticateUser(requestParameters.userId, requestParameters.pw, requestParameters.password, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Authenticates a user by name.
     * @param {UserApiAuthenticateUserByNameRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public authenticateUserByName(requestParameters: UserApiAuthenticateUserByNameRequest, options?: any) {
        return UserApiFp(this.configuration).authenticateUserByName(requestParameters.authenticateUserByName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Authenticates a user with quick connect.
     * @param {UserApiAuthenticateWithQuickConnectRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public authenticateWithQuickConnect(requestParameters: UserApiAuthenticateWithQuickConnectRequest, options?: any) {
        return UserApiFp(this.configuration).authenticateWithQuickConnect(requestParameters.quickConnectDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Creates a user.
     * @param {UserApiCreateUserByNameRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public createUserByName(requestParameters: UserApiCreateUserByNameRequest = {}, options?: any) {
        return UserApiFp(this.configuration).createUserByName(requestParameters.createUserByName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Deletes a user.
     * @param {UserApiDeleteUserRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public deleteUser(requestParameters: UserApiDeleteUserRequest, options?: any) {
        return UserApiFp(this.configuration).deleteUser(requestParameters.userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Initiates the forgot password process for a local user.
     * @param {UserApiForgotPasswordRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public forgotPassword(requestParameters: UserApiForgotPasswordRequest, options?: any) {
        return UserApiFp(this.configuration).forgotPassword(requestParameters.forgotPasswordDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Redeems a forgot password pin.
     * @param {UserApiForgotPasswordPinRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public forgotPasswordPin(requestParameters: UserApiForgotPasswordPinRequest = {}, options?: any) {
        return UserApiFp(this.configuration).forgotPasswordPin(requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets a list of publicly visible users for display on a login screen.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getPublicUsers(options?: any) {
        return UserApiFp(this.configuration).getPublicUsers(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets a user by Id.
     * @param {UserApiGetUserByIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getUserById(requestParameters: UserApiGetUserByIdRequest, options?: any) {
        return UserApiFp(this.configuration).getUserById(requestParameters.userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets a list of users.
     * @param {UserApiGetUsersRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getUsers(requestParameters: UserApiGetUsersRequest = {}, options?: any) {
        return UserApiFp(this.configuration).getUsers(requestParameters.isHidden, requestParameters.isDisabled, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates a user.
     * @param {UserApiUpdateUserRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public updateUser(requestParameters: UserApiUpdateUserRequest, options?: any) {
        return UserApiFp(this.configuration).updateUser(requestParameters.userId, requestParameters.userDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates a user configuration.
     * @param {UserApiUpdateUserConfigurationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public updateUserConfiguration(requestParameters: UserApiUpdateUserConfigurationRequest, options?: any) {
        return UserApiFp(this.configuration).updateUserConfiguration(requestParameters.userId, requestParameters.userConfiguration, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates a user\'s easy password.
     * @param {UserApiUpdateUserEasyPasswordRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public updateUserEasyPassword(requestParameters: UserApiUpdateUserEasyPasswordRequest, options?: any) {
        return UserApiFp(this.configuration).updateUserEasyPassword(requestParameters.userId, requestParameters.updateUserEasyPassword, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates a user\'s password.
     * @param {UserApiUpdateUserPasswordRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public updateUserPassword(requestParameters: UserApiUpdateUserPasswordRequest, options?: any) {
        return UserApiFp(this.configuration).updateUserPassword(requestParameters.userId, requestParameters.updateUserPassword, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates a user policy.
     * @param {UserApiUpdateUserPolicyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public updateUserPolicy(requestParameters: UserApiUpdateUserPolicyRequest, options?: any) {
        return UserApiFp(this.configuration).updateUserPolicy(requestParameters.userId, requestParameters.userPolicy, options).then((request) => request(this.axios, this.basePath));
    }
}
