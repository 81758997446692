/* tslint:disable */
/* eslint-disable */
/**
 * Jellyfin API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * This is a serializable stub class that is used by the api to provide information about installed plugins.
 * @export
 * @interface PluginInfo
 */
export interface PluginInfo {
    /**
     * Gets or sets the name.
     * @type {string}
     * @memberof PluginInfo
     */
    Name?: string | null;
    /**
     * Gets or sets the version.
     * @type {string}
     * @memberof PluginInfo
     */
    Version?: string | null;
    /**
     * Gets or sets the name of the configuration file.
     * @type {string}
     * @memberof PluginInfo
     */
    ConfigurationFileName?: string | null;
    /**
     * Gets or sets the description.
     * @type {string}
     * @memberof PluginInfo
     */
    Description?: string | null;
    /**
     * Gets or sets the unique id.
     * @type {string}
     * @memberof PluginInfo
     */
    Id?: string | null;
    /**
     * Gets or sets a value indicating whether the plugin can be uninstalled.
     * @type {boolean}
     * @memberof PluginInfo
     */
    CanUninstall?: boolean;
    /**
     * Gets or sets the image URL.
     * @type {string}
     * @memberof PluginInfo
     */
    ImageUrl?: string | null;
}


