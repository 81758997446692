/* tslint:disable */
/* eslint-disable */
/**
 * Jellyfin API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */
export enum TranscodeReason {
    ContainerNotSupported = 'ContainerNotSupported',
    VideoCodecNotSupported = 'VideoCodecNotSupported',
    AudioCodecNotSupported = 'AudioCodecNotSupported',
    ContainerBitrateExceedsLimit = 'ContainerBitrateExceedsLimit',
    AudioBitrateNotSupported = 'AudioBitrateNotSupported',
    AudioChannelsNotSupported = 'AudioChannelsNotSupported',
    VideoResolutionNotSupported = 'VideoResolutionNotSupported',
    UnknownVideoStreamInfo = 'UnknownVideoStreamInfo',
    UnknownAudioStreamInfo = 'UnknownAudioStreamInfo',
    AudioProfileNotSupported = 'AudioProfileNotSupported',
    AudioSampleRateNotSupported = 'AudioSampleRateNotSupported',
    AnamorphicVideoNotSupported = 'AnamorphicVideoNotSupported',
    InterlacedVideoNotSupported = 'InterlacedVideoNotSupported',
    SecondaryAudioNotSupported = 'SecondaryAudioNotSupported',
    RefFramesNotSupported = 'RefFramesNotSupported',
    VideoBitDepthNotSupported = 'VideoBitDepthNotSupported',
    VideoBitrateNotSupported = 'VideoBitrateNotSupported',
    VideoFramerateNotSupported = 'VideoFramerateNotSupported',
    VideoLevelNotSupported = 'VideoLevelNotSupported',
    VideoProfileNotSupported = 'VideoProfileNotSupported',
    AudioBitDepthNotSupported = 'AudioBitDepthNotSupported',
    SubtitleCodecNotSupported = 'SubtitleCodecNotSupported',
    DirectPlayError = 'DirectPlayError'
}



