/* tslint:disable */
/* eslint-disable */
/**
 * Jellyfin API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { BaseItemDtoQueryResult } from '../models';
// @ts-ignore
import { EncodingContext } from '../models';
// @ts-ignore
import { ProblemDetails } from '../models';
// @ts-ignore
import { SubtitleDeliveryMethod } from '../models';
/**
 * VideosApi - axios parameter creator
 * @export
 */
export const VideosApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Removes alternate video sources.
         * @param {string} itemId The item id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAlternateSources: async (itemId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'itemId' is not null or undefined
            if (itemId === null || itemId === undefined) {
                throw new RequiredError('itemId','Required parameter itemId was null or undefined when calling deleteAlternateSources.');
            }
            const localVarPath = `/Videos/{itemId}/AlternateSources`
                .replace(`{${"itemId"}}`, encodeURIComponent(String(itemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets additional parts for a video.
         * @param {string} itemId The item id.
         * @param {string} [userId] Optional. Filter by user id, and attach user data.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdditionalPart: async (itemId: string, userId?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'itemId' is not null or undefined
            if (itemId === null || itemId === undefined) {
                throw new RequiredError('itemId','Required parameter itemId was null or undefined when calling getAdditionalPart.');
            }
            const localVarPath = `/Videos/{itemId}/AdditionalParts`
                .replace(`{${"itemId"}}`, encodeURIComponent(String(itemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets a video stream.
         * @param {string} itemId The item id.
         * @param {string} container The video container. Possible values are: ts, webm, asf, wmv, ogv, mp4, m4v, mkv, mpeg, mpg, avi, 3gp, wmv, wtv, m2ts, mov, iso, flv.
         * @param {boolean} [_static] Optional. If true, the original file will be streamed statically without any encoding. Use either no url extension or the original file extension. true/false.
         * @param {string} [params] The streaming parameters.
         * @param {string} [tag] The tag.
         * @param {string} [deviceProfileId] Optional. The dlna device profile id to utilize.
         * @param {string} [playSessionId] The play session id.
         * @param {string} [segmentContainer] The segment container.
         * @param {number} [segmentLength] The segment lenght.
         * @param {number} [minSegments] The minimum number of segments.
         * @param {string} [mediaSourceId] The media version id, if playing an alternate version.
         * @param {string} [deviceId] The device id of the client requesting. Used to stop encoding processes when needed.
         * @param {string} [audioCodec] Optional. Specify a audio codec to encode to, e.g. mp3. If omitted the server will auto-select using the url\&#39;s extension. Options: aac, mp3, vorbis, wma.
         * @param {boolean} [enableAutoStreamCopy] Whether or not to allow automatic stream copy if requested values match the original source. Defaults to true.
         * @param {boolean} [allowVideoStreamCopy] Whether or not to allow copying of the video stream url.
         * @param {boolean} [allowAudioStreamCopy] Whether or not to allow copying of the audio stream url.
         * @param {boolean} [breakOnNonKeyFrames] Optional. Whether to break on non key frames.
         * @param {number} [audioSampleRate] Optional. Specify a specific audio sample rate, e.g. 44100.
         * @param {number} [maxAudioBitDepth] Optional. The maximum audio bit depth.
         * @param {number} [audioBitRate] Optional. Specify an audio bitrate to encode to, e.g. 128000. If omitted this will be left to encoder defaults.
         * @param {number} [audioChannels] Optional. Specify a specific number of audio channels to encode to, e.g. 2.
         * @param {number} [maxAudioChannels] Optional. Specify a maximum number of audio channels to encode to, e.g. 2.
         * @param {string} [profile] Optional. Specify a specific an encoder profile (varies by encoder), e.g. main, baseline, high.
         * @param {string} [level] Optional. Specify a level for the encoder profile (varies by encoder), e.g. 3, 3.1.
         * @param {number} [framerate] Optional. A specific video framerate to encode to, e.g. 23.976. Generally this should be omitted unless the device has specific requirements.
         * @param {number} [maxFramerate] Optional. A specific maximum video framerate to encode to, e.g. 23.976. Generally this should be omitted unless the device has specific requirements.
         * @param {boolean} [copyTimestamps] Whether or not to copy timestamps when transcoding with an offset. Defaults to false.
         * @param {number} [startTimeTicks] Optional. Specify a starting offset, in ticks. 1 tick &#x3D; 10000 ms.
         * @param {number} [width] Optional. The fixed horizontal resolution of the encoded video.
         * @param {number} [height] Optional. The fixed vertical resolution of the encoded video.
         * @param {number} [videoBitRate] Optional. Specify a video bitrate to encode to, e.g. 500000. If omitted this will be left to encoder defaults.
         * @param {number} [subtitleStreamIndex] Optional. The index of the subtitle stream to use. If omitted no subtitles will be used.
         * @param {SubtitleDeliveryMethod} [subtitleMethod] Optional. Specify the subtitle delivery method.
         * @param {number} [maxRefFrames] Optional.
         * @param {number} [maxVideoBitDepth] Optional. The maximum video bit depth.
         * @param {boolean} [requireAvc] Optional. Whether to require avc.
         * @param {boolean} [deInterlace] Optional. Whether to deinterlace the video.
         * @param {boolean} [requireNonAnamorphic] Optional. Whether to require a non anamporphic stream.
         * @param {number} [transcodingMaxAudioChannels] Optional. The maximum number of audio channels to transcode.
         * @param {number} [cpuCoreLimit] Optional. The limit of how many cpu cores to use.
         * @param {string} [liveStreamId] The live stream id.
         * @param {boolean} [enableMpegtsM2TsMode] Optional. Whether to enable the MpegtsM2Ts mode.
         * @param {string} [videoCodec] Optional. Specify a video codec to encode to, e.g. h264. If omitted the server will auto-select using the url\&#39;s extension. Options: h265, h264, mpeg4, theora, vpx, wmv.
         * @param {string} [subtitleCodec] Optional. Specify a subtitle codec to encode to.
         * @param {string} [transcodingReasons] Optional. The transcoding reason.
         * @param {number} [audioStreamIndex] Optional. The index of the audio stream to use. If omitted the first audio stream will be used.
         * @param {number} [videoStreamIndex] Optional. The index of the video stream to use. If omitted the first video stream will be used.
         * @param {EncodingContext} [context] Optional. The MediaBrowser.Model.Dlna.EncodingContext.
         * @param {{ [key: string]: string; }} [streamOptions] Optional. The streaming options.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVideoStream: async (itemId: string, container: string, _static?: boolean, params?: string, tag?: string, deviceProfileId?: string, playSessionId?: string, segmentContainer?: string, segmentLength?: number, minSegments?: number, mediaSourceId?: string, deviceId?: string, audioCodec?: string, enableAutoStreamCopy?: boolean, allowVideoStreamCopy?: boolean, allowAudioStreamCopy?: boolean, breakOnNonKeyFrames?: boolean, audioSampleRate?: number, maxAudioBitDepth?: number, audioBitRate?: number, audioChannels?: number, maxAudioChannels?: number, profile?: string, level?: string, framerate?: number, maxFramerate?: number, copyTimestamps?: boolean, startTimeTicks?: number, width?: number, height?: number, videoBitRate?: number, subtitleStreamIndex?: number, subtitleMethod?: SubtitleDeliveryMethod, maxRefFrames?: number, maxVideoBitDepth?: number, requireAvc?: boolean, deInterlace?: boolean, requireNonAnamorphic?: boolean, transcodingMaxAudioChannels?: number, cpuCoreLimit?: number, liveStreamId?: string, enableMpegtsM2TsMode?: boolean, videoCodec?: string, subtitleCodec?: string, transcodingReasons?: string, audioStreamIndex?: number, videoStreamIndex?: number, context?: EncodingContext, streamOptions?: { [key: string]: string; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'itemId' is not null or undefined
            if (itemId === null || itemId === undefined) {
                throw new RequiredError('itemId','Required parameter itemId was null or undefined when calling getVideoStream.');
            }
            // verify required parameter 'container' is not null or undefined
            if (container === null || container === undefined) {
                throw new RequiredError('container','Required parameter container was null or undefined when calling getVideoStream.');
            }
            const localVarPath = `/Videos/{itemId}/stream`
                .replace(`{${"itemId"}}`, encodeURIComponent(String(itemId)))
                .replace(`{${"container"}}`, encodeURIComponent(String(container)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }

            if (_static !== undefined) {
                localVarQueryParameter['static'] = _static;
            }

            if (params !== undefined) {
                localVarQueryParameter['params'] = params;
            }

            if (tag !== undefined) {
                localVarQueryParameter['tag'] = tag;
            }

            if (deviceProfileId !== undefined) {
                localVarQueryParameter['deviceProfileId'] = deviceProfileId;
            }

            if (playSessionId !== undefined) {
                localVarQueryParameter['playSessionId'] = playSessionId;
            }

            if (segmentContainer !== undefined) {
                localVarQueryParameter['segmentContainer'] = segmentContainer;
            }

            if (segmentLength !== undefined) {
                localVarQueryParameter['segmentLength'] = segmentLength;
            }

            if (minSegments !== undefined) {
                localVarQueryParameter['minSegments'] = minSegments;
            }

            if (mediaSourceId !== undefined) {
                localVarQueryParameter['mediaSourceId'] = mediaSourceId;
            }

            if (deviceId !== undefined) {
                localVarQueryParameter['deviceId'] = deviceId;
            }

            if (audioCodec !== undefined) {
                localVarQueryParameter['audioCodec'] = audioCodec;
            }

            if (enableAutoStreamCopy !== undefined) {
                localVarQueryParameter['enableAutoStreamCopy'] = enableAutoStreamCopy;
            }

            if (allowVideoStreamCopy !== undefined) {
                localVarQueryParameter['allowVideoStreamCopy'] = allowVideoStreamCopy;
            }

            if (allowAudioStreamCopy !== undefined) {
                localVarQueryParameter['allowAudioStreamCopy'] = allowAudioStreamCopy;
            }

            if (breakOnNonKeyFrames !== undefined) {
                localVarQueryParameter['breakOnNonKeyFrames'] = breakOnNonKeyFrames;
            }

            if (audioSampleRate !== undefined) {
                localVarQueryParameter['audioSampleRate'] = audioSampleRate;
            }

            if (maxAudioBitDepth !== undefined) {
                localVarQueryParameter['maxAudioBitDepth'] = maxAudioBitDepth;
            }

            if (audioBitRate !== undefined) {
                localVarQueryParameter['audioBitRate'] = audioBitRate;
            }

            if (audioChannels !== undefined) {
                localVarQueryParameter['audioChannels'] = audioChannels;
            }

            if (maxAudioChannels !== undefined) {
                localVarQueryParameter['maxAudioChannels'] = maxAudioChannels;
            }

            if (profile !== undefined) {
                localVarQueryParameter['profile'] = profile;
            }

            if (level !== undefined) {
                localVarQueryParameter['level'] = level;
            }

            if (framerate !== undefined) {
                localVarQueryParameter['framerate'] = framerate;
            }

            if (maxFramerate !== undefined) {
                localVarQueryParameter['maxFramerate'] = maxFramerate;
            }

            if (copyTimestamps !== undefined) {
                localVarQueryParameter['copyTimestamps'] = copyTimestamps;
            }

            if (startTimeTicks !== undefined) {
                localVarQueryParameter['startTimeTicks'] = startTimeTicks;
            }

            if (width !== undefined) {
                localVarQueryParameter['width'] = width;
            }

            if (height !== undefined) {
                localVarQueryParameter['height'] = height;
            }

            if (videoBitRate !== undefined) {
                localVarQueryParameter['videoBitRate'] = videoBitRate;
            }

            if (subtitleStreamIndex !== undefined) {
                localVarQueryParameter['subtitleStreamIndex'] = subtitleStreamIndex;
            }

            if (subtitleMethod !== undefined) {
                localVarQueryParameter['subtitleMethod'] = subtitleMethod;
            }

            if (maxRefFrames !== undefined) {
                localVarQueryParameter['maxRefFrames'] = maxRefFrames;
            }

            if (maxVideoBitDepth !== undefined) {
                localVarQueryParameter['maxVideoBitDepth'] = maxVideoBitDepth;
            }

            if (requireAvc !== undefined) {
                localVarQueryParameter['requireAvc'] = requireAvc;
            }

            if (deInterlace !== undefined) {
                localVarQueryParameter['deInterlace'] = deInterlace;
            }

            if (requireNonAnamorphic !== undefined) {
                localVarQueryParameter['requireNonAnamorphic'] = requireNonAnamorphic;
            }

            if (transcodingMaxAudioChannels !== undefined) {
                localVarQueryParameter['transcodingMaxAudioChannels'] = transcodingMaxAudioChannels;
            }

            if (cpuCoreLimit !== undefined) {
                localVarQueryParameter['cpuCoreLimit'] = cpuCoreLimit;
            }

            if (liveStreamId !== undefined) {
                localVarQueryParameter['liveStreamId'] = liveStreamId;
            }

            if (enableMpegtsM2TsMode !== undefined) {
                localVarQueryParameter['enableMpegtsM2TsMode'] = enableMpegtsM2TsMode;
            }

            if (videoCodec !== undefined) {
                localVarQueryParameter['videoCodec'] = videoCodec;
            }

            if (subtitleCodec !== undefined) {
                localVarQueryParameter['subtitleCodec'] = subtitleCodec;
            }

            if (transcodingReasons !== undefined) {
                localVarQueryParameter['transcodingReasons'] = transcodingReasons;
            }

            if (audioStreamIndex !== undefined) {
                localVarQueryParameter['audioStreamIndex'] = audioStreamIndex;
            }

            if (videoStreamIndex !== undefined) {
                localVarQueryParameter['videoStreamIndex'] = videoStreamIndex;
            }

            if (context !== undefined) {
                localVarQueryParameter['context'] = context;
            }

            if (streamOptions !== undefined) {
                localVarQueryParameter['streamOptions'] = streamOptions;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets a video stream.
         * @param {string} itemId The item id.
         * @param {string} container The video container. Possible values are: ts, webm, asf, wmv, ogv, mp4, m4v, mkv, mpeg, mpg, avi, 3gp, wmv, wtv, m2ts, mov, iso, flv.
         * @param {string} stream 
         * @param {boolean} [_static] Optional. If true, the original file will be streamed statically without any encoding. Use either no url extension or the original file extension. true/false.
         * @param {string} [params] The streaming parameters.
         * @param {string} [tag] The tag.
         * @param {string} [deviceProfileId] Optional. The dlna device profile id to utilize.
         * @param {string} [playSessionId] The play session id.
         * @param {string} [segmentContainer] The segment container.
         * @param {number} [segmentLength] The segment lenght.
         * @param {number} [minSegments] The minimum number of segments.
         * @param {string} [mediaSourceId] The media version id, if playing an alternate version.
         * @param {string} [deviceId] The device id of the client requesting. Used to stop encoding processes when needed.
         * @param {string} [audioCodec] Optional. Specify a audio codec to encode to, e.g. mp3. If omitted the server will auto-select using the url\&#39;s extension. Options: aac, mp3, vorbis, wma.
         * @param {boolean} [enableAutoStreamCopy] Whether or not to allow automatic stream copy if requested values match the original source. Defaults to true.
         * @param {boolean} [allowVideoStreamCopy] Whether or not to allow copying of the video stream url.
         * @param {boolean} [allowAudioStreamCopy] Whether or not to allow copying of the audio stream url.
         * @param {boolean} [breakOnNonKeyFrames] Optional. Whether to break on non key frames.
         * @param {number} [audioSampleRate] Optional. Specify a specific audio sample rate, e.g. 44100.
         * @param {number} [maxAudioBitDepth] Optional. The maximum audio bit depth.
         * @param {number} [audioBitRate] Optional. Specify an audio bitrate to encode to, e.g. 128000. If omitted this will be left to encoder defaults.
         * @param {number} [audioChannels] Optional. Specify a specific number of audio channels to encode to, e.g. 2.
         * @param {number} [maxAudioChannels] Optional. Specify a maximum number of audio channels to encode to, e.g. 2.
         * @param {string} [profile] Optional. Specify a specific an encoder profile (varies by encoder), e.g. main, baseline, high.
         * @param {string} [level] Optional. Specify a level for the encoder profile (varies by encoder), e.g. 3, 3.1.
         * @param {number} [framerate] Optional. A specific video framerate to encode to, e.g. 23.976. Generally this should be omitted unless the device has specific requirements.
         * @param {number} [maxFramerate] Optional. A specific maximum video framerate to encode to, e.g. 23.976. Generally this should be omitted unless the device has specific requirements.
         * @param {boolean} [copyTimestamps] Whether or not to copy timestamps when transcoding with an offset. Defaults to false.
         * @param {number} [startTimeTicks] Optional. Specify a starting offset, in ticks. 1 tick &#x3D; 10000 ms.
         * @param {number} [width] Optional. The fixed horizontal resolution of the encoded video.
         * @param {number} [height] Optional. The fixed vertical resolution of the encoded video.
         * @param {number} [videoBitRate] Optional. Specify a video bitrate to encode to, e.g. 500000. If omitted this will be left to encoder defaults.
         * @param {number} [subtitleStreamIndex] Optional. The index of the subtitle stream to use. If omitted no subtitles will be used.
         * @param {SubtitleDeliveryMethod} [subtitleMethod] Optional. Specify the subtitle delivery method.
         * @param {number} [maxRefFrames] Optional.
         * @param {number} [maxVideoBitDepth] Optional. The maximum video bit depth.
         * @param {boolean} [requireAvc] Optional. Whether to require avc.
         * @param {boolean} [deInterlace] Optional. Whether to deinterlace the video.
         * @param {boolean} [requireNonAnamorphic] Optional. Whether to require a non anamporphic stream.
         * @param {number} [transcodingMaxAudioChannels] Optional. The maximum number of audio channels to transcode.
         * @param {number} [cpuCoreLimit] Optional. The limit of how many cpu cores to use.
         * @param {string} [liveStreamId] The live stream id.
         * @param {boolean} [enableMpegtsM2TsMode] Optional. Whether to enable the MpegtsM2Ts mode.
         * @param {string} [videoCodec] Optional. Specify a video codec to encode to, e.g. h264. If omitted the server will auto-select using the url\&#39;s extension. Options: h265, h264, mpeg4, theora, vpx, wmv.
         * @param {string} [subtitleCodec] Optional. Specify a subtitle codec to encode to.
         * @param {string} [transcodingReasons] Optional. The transcoding reason.
         * @param {number} [audioStreamIndex] Optional. The index of the audio stream to use. If omitted the first audio stream will be used.
         * @param {number} [videoStreamIndex] Optional. The index of the video stream to use. If omitted the first video stream will be used.
         * @param {EncodingContext} [context] Optional. The MediaBrowser.Model.Dlna.EncodingContext.
         * @param {{ [key: string]: string; }} [streamOptions] Optional. The streaming options.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVideoStreamWithExt: async (itemId: string, container: string, stream: string, _static?: boolean, params?: string, tag?: string, deviceProfileId?: string, playSessionId?: string, segmentContainer?: string, segmentLength?: number, minSegments?: number, mediaSourceId?: string, deviceId?: string, audioCodec?: string, enableAutoStreamCopy?: boolean, allowVideoStreamCopy?: boolean, allowAudioStreamCopy?: boolean, breakOnNonKeyFrames?: boolean, audioSampleRate?: number, maxAudioBitDepth?: number, audioBitRate?: number, audioChannels?: number, maxAudioChannels?: number, profile?: string, level?: string, framerate?: number, maxFramerate?: number, copyTimestamps?: boolean, startTimeTicks?: number, width?: number, height?: number, videoBitRate?: number, subtitleStreamIndex?: number, subtitleMethod?: SubtitleDeliveryMethod, maxRefFrames?: number, maxVideoBitDepth?: number, requireAvc?: boolean, deInterlace?: boolean, requireNonAnamorphic?: boolean, transcodingMaxAudioChannels?: number, cpuCoreLimit?: number, liveStreamId?: string, enableMpegtsM2TsMode?: boolean, videoCodec?: string, subtitleCodec?: string, transcodingReasons?: string, audioStreamIndex?: number, videoStreamIndex?: number, context?: EncodingContext, streamOptions?: { [key: string]: string; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'itemId' is not null or undefined
            if (itemId === null || itemId === undefined) {
                throw new RequiredError('itemId','Required parameter itemId was null or undefined when calling getVideoStreamWithExt.');
            }
            // verify required parameter 'container' is not null or undefined
            if (container === null || container === undefined) {
                throw new RequiredError('container','Required parameter container was null or undefined when calling getVideoStreamWithExt.');
            }
            // verify required parameter 'stream' is not null or undefined
            if (stream === null || stream === undefined) {
                throw new RequiredError('stream','Required parameter stream was null or undefined when calling getVideoStreamWithExt.');
            }
            const localVarPath = `/Videos/{itemId}/{stream}.{container}`
                .replace(`{${"itemId"}}`, encodeURIComponent(String(itemId)))
                .replace(`{${"container"}}`, encodeURIComponent(String(container)))
                .replace(`{${"stream"}}`, encodeURIComponent(String(stream)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }

            if (_static !== undefined) {
                localVarQueryParameter['static'] = _static;
            }

            if (params !== undefined) {
                localVarQueryParameter['params'] = params;
            }

            if (tag !== undefined) {
                localVarQueryParameter['tag'] = tag;
            }

            if (deviceProfileId !== undefined) {
                localVarQueryParameter['deviceProfileId'] = deviceProfileId;
            }

            if (playSessionId !== undefined) {
                localVarQueryParameter['playSessionId'] = playSessionId;
            }

            if (segmentContainer !== undefined) {
                localVarQueryParameter['segmentContainer'] = segmentContainer;
            }

            if (segmentLength !== undefined) {
                localVarQueryParameter['segmentLength'] = segmentLength;
            }

            if (minSegments !== undefined) {
                localVarQueryParameter['minSegments'] = minSegments;
            }

            if (mediaSourceId !== undefined) {
                localVarQueryParameter['mediaSourceId'] = mediaSourceId;
            }

            if (deviceId !== undefined) {
                localVarQueryParameter['deviceId'] = deviceId;
            }

            if (audioCodec !== undefined) {
                localVarQueryParameter['audioCodec'] = audioCodec;
            }

            if (enableAutoStreamCopy !== undefined) {
                localVarQueryParameter['enableAutoStreamCopy'] = enableAutoStreamCopy;
            }

            if (allowVideoStreamCopy !== undefined) {
                localVarQueryParameter['allowVideoStreamCopy'] = allowVideoStreamCopy;
            }

            if (allowAudioStreamCopy !== undefined) {
                localVarQueryParameter['allowAudioStreamCopy'] = allowAudioStreamCopy;
            }

            if (breakOnNonKeyFrames !== undefined) {
                localVarQueryParameter['breakOnNonKeyFrames'] = breakOnNonKeyFrames;
            }

            if (audioSampleRate !== undefined) {
                localVarQueryParameter['audioSampleRate'] = audioSampleRate;
            }

            if (maxAudioBitDepth !== undefined) {
                localVarQueryParameter['maxAudioBitDepth'] = maxAudioBitDepth;
            }

            if (audioBitRate !== undefined) {
                localVarQueryParameter['audioBitRate'] = audioBitRate;
            }

            if (audioChannels !== undefined) {
                localVarQueryParameter['audioChannels'] = audioChannels;
            }

            if (maxAudioChannels !== undefined) {
                localVarQueryParameter['maxAudioChannels'] = maxAudioChannels;
            }

            if (profile !== undefined) {
                localVarQueryParameter['profile'] = profile;
            }

            if (level !== undefined) {
                localVarQueryParameter['level'] = level;
            }

            if (framerate !== undefined) {
                localVarQueryParameter['framerate'] = framerate;
            }

            if (maxFramerate !== undefined) {
                localVarQueryParameter['maxFramerate'] = maxFramerate;
            }

            if (copyTimestamps !== undefined) {
                localVarQueryParameter['copyTimestamps'] = copyTimestamps;
            }

            if (startTimeTicks !== undefined) {
                localVarQueryParameter['startTimeTicks'] = startTimeTicks;
            }

            if (width !== undefined) {
                localVarQueryParameter['width'] = width;
            }

            if (height !== undefined) {
                localVarQueryParameter['height'] = height;
            }

            if (videoBitRate !== undefined) {
                localVarQueryParameter['videoBitRate'] = videoBitRate;
            }

            if (subtitleStreamIndex !== undefined) {
                localVarQueryParameter['subtitleStreamIndex'] = subtitleStreamIndex;
            }

            if (subtitleMethod !== undefined) {
                localVarQueryParameter['subtitleMethod'] = subtitleMethod;
            }

            if (maxRefFrames !== undefined) {
                localVarQueryParameter['maxRefFrames'] = maxRefFrames;
            }

            if (maxVideoBitDepth !== undefined) {
                localVarQueryParameter['maxVideoBitDepth'] = maxVideoBitDepth;
            }

            if (requireAvc !== undefined) {
                localVarQueryParameter['requireAvc'] = requireAvc;
            }

            if (deInterlace !== undefined) {
                localVarQueryParameter['deInterlace'] = deInterlace;
            }

            if (requireNonAnamorphic !== undefined) {
                localVarQueryParameter['requireNonAnamorphic'] = requireNonAnamorphic;
            }

            if (transcodingMaxAudioChannels !== undefined) {
                localVarQueryParameter['transcodingMaxAudioChannels'] = transcodingMaxAudioChannels;
            }

            if (cpuCoreLimit !== undefined) {
                localVarQueryParameter['cpuCoreLimit'] = cpuCoreLimit;
            }

            if (liveStreamId !== undefined) {
                localVarQueryParameter['liveStreamId'] = liveStreamId;
            }

            if (enableMpegtsM2TsMode !== undefined) {
                localVarQueryParameter['enableMpegtsM2TsMode'] = enableMpegtsM2TsMode;
            }

            if (videoCodec !== undefined) {
                localVarQueryParameter['videoCodec'] = videoCodec;
            }

            if (subtitleCodec !== undefined) {
                localVarQueryParameter['subtitleCodec'] = subtitleCodec;
            }

            if (transcodingReasons !== undefined) {
                localVarQueryParameter['transcodingReasons'] = transcodingReasons;
            }

            if (audioStreamIndex !== undefined) {
                localVarQueryParameter['audioStreamIndex'] = audioStreamIndex;
            }

            if (videoStreamIndex !== undefined) {
                localVarQueryParameter['videoStreamIndex'] = videoStreamIndex;
            }

            if (context !== undefined) {
                localVarQueryParameter['context'] = context;
            }

            if (streamOptions !== undefined) {
                localVarQueryParameter['streamOptions'] = streamOptions;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets a video stream.
         * @param {string} itemId The item id.
         * @param {string} container The video container. Possible values are: ts, webm, asf, wmv, ogv, mp4, m4v, mkv, mpeg, mpg, avi, 3gp, wmv, wtv, m2ts, mov, iso, flv.
         * @param {boolean} [_static] Optional. If true, the original file will be streamed statically without any encoding. Use either no url extension or the original file extension. true/false.
         * @param {string} [params] The streaming parameters.
         * @param {string} [tag] The tag.
         * @param {string} [deviceProfileId] Optional. The dlna device profile id to utilize.
         * @param {string} [playSessionId] The play session id.
         * @param {string} [segmentContainer] The segment container.
         * @param {number} [segmentLength] The segment lenght.
         * @param {number} [minSegments] The minimum number of segments.
         * @param {string} [mediaSourceId] The media version id, if playing an alternate version.
         * @param {string} [deviceId] The device id of the client requesting. Used to stop encoding processes when needed.
         * @param {string} [audioCodec] Optional. Specify a audio codec to encode to, e.g. mp3. If omitted the server will auto-select using the url\&#39;s extension. Options: aac, mp3, vorbis, wma.
         * @param {boolean} [enableAutoStreamCopy] Whether or not to allow automatic stream copy if requested values match the original source. Defaults to true.
         * @param {boolean} [allowVideoStreamCopy] Whether or not to allow copying of the video stream url.
         * @param {boolean} [allowAudioStreamCopy] Whether or not to allow copying of the audio stream url.
         * @param {boolean} [breakOnNonKeyFrames] Optional. Whether to break on non key frames.
         * @param {number} [audioSampleRate] Optional. Specify a specific audio sample rate, e.g. 44100.
         * @param {number} [maxAudioBitDepth] Optional. The maximum audio bit depth.
         * @param {number} [audioBitRate] Optional. Specify an audio bitrate to encode to, e.g. 128000. If omitted this will be left to encoder defaults.
         * @param {number} [audioChannels] Optional. Specify a specific number of audio channels to encode to, e.g. 2.
         * @param {number} [maxAudioChannels] Optional. Specify a maximum number of audio channels to encode to, e.g. 2.
         * @param {string} [profile] Optional. Specify a specific an encoder profile (varies by encoder), e.g. main, baseline, high.
         * @param {string} [level] Optional. Specify a level for the encoder profile (varies by encoder), e.g. 3, 3.1.
         * @param {number} [framerate] Optional. A specific video framerate to encode to, e.g. 23.976. Generally this should be omitted unless the device has specific requirements.
         * @param {number} [maxFramerate] Optional. A specific maximum video framerate to encode to, e.g. 23.976. Generally this should be omitted unless the device has specific requirements.
         * @param {boolean} [copyTimestamps] Whether or not to copy timestamps when transcoding with an offset. Defaults to false.
         * @param {number} [startTimeTicks] Optional. Specify a starting offset, in ticks. 1 tick &#x3D; 10000 ms.
         * @param {number} [width] Optional. The fixed horizontal resolution of the encoded video.
         * @param {number} [height] Optional. The fixed vertical resolution of the encoded video.
         * @param {number} [videoBitRate] Optional. Specify a video bitrate to encode to, e.g. 500000. If omitted this will be left to encoder defaults.
         * @param {number} [subtitleStreamIndex] Optional. The index of the subtitle stream to use. If omitted no subtitles will be used.
         * @param {SubtitleDeliveryMethod} [subtitleMethod] Optional. Specify the subtitle delivery method.
         * @param {number} [maxRefFrames] Optional.
         * @param {number} [maxVideoBitDepth] Optional. The maximum video bit depth.
         * @param {boolean} [requireAvc] Optional. Whether to require avc.
         * @param {boolean} [deInterlace] Optional. Whether to deinterlace the video.
         * @param {boolean} [requireNonAnamorphic] Optional. Whether to require a non anamporphic stream.
         * @param {number} [transcodingMaxAudioChannels] Optional. The maximum number of audio channels to transcode.
         * @param {number} [cpuCoreLimit] Optional. The limit of how many cpu cores to use.
         * @param {string} [liveStreamId] The live stream id.
         * @param {boolean} [enableMpegtsM2TsMode] Optional. Whether to enable the MpegtsM2Ts mode.
         * @param {string} [videoCodec] Optional. Specify a video codec to encode to, e.g. h264. If omitted the server will auto-select using the url\&#39;s extension. Options: h265, h264, mpeg4, theora, vpx, wmv.
         * @param {string} [subtitleCodec] Optional. Specify a subtitle codec to encode to.
         * @param {string} [transcodingReasons] Optional. The transcoding reason.
         * @param {number} [audioStreamIndex] Optional. The index of the audio stream to use. If omitted the first audio stream will be used.
         * @param {number} [videoStreamIndex] Optional. The index of the video stream to use. If omitted the first video stream will be used.
         * @param {EncodingContext} [context] Optional. The MediaBrowser.Model.Dlna.EncodingContext.
         * @param {{ [key: string]: string; }} [streamOptions] Optional. The streaming options.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        headVideoStream: async (itemId: string, container: string, _static?: boolean, params?: string, tag?: string, deviceProfileId?: string, playSessionId?: string, segmentContainer?: string, segmentLength?: number, minSegments?: number, mediaSourceId?: string, deviceId?: string, audioCodec?: string, enableAutoStreamCopy?: boolean, allowVideoStreamCopy?: boolean, allowAudioStreamCopy?: boolean, breakOnNonKeyFrames?: boolean, audioSampleRate?: number, maxAudioBitDepth?: number, audioBitRate?: number, audioChannels?: number, maxAudioChannels?: number, profile?: string, level?: string, framerate?: number, maxFramerate?: number, copyTimestamps?: boolean, startTimeTicks?: number, width?: number, height?: number, videoBitRate?: number, subtitleStreamIndex?: number, subtitleMethod?: SubtitleDeliveryMethod, maxRefFrames?: number, maxVideoBitDepth?: number, requireAvc?: boolean, deInterlace?: boolean, requireNonAnamorphic?: boolean, transcodingMaxAudioChannels?: number, cpuCoreLimit?: number, liveStreamId?: string, enableMpegtsM2TsMode?: boolean, videoCodec?: string, subtitleCodec?: string, transcodingReasons?: string, audioStreamIndex?: number, videoStreamIndex?: number, context?: EncodingContext, streamOptions?: { [key: string]: string; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'itemId' is not null or undefined
            if (itemId === null || itemId === undefined) {
                throw new RequiredError('itemId','Required parameter itemId was null or undefined when calling headVideoStream.');
            }
            // verify required parameter 'container' is not null or undefined
            if (container === null || container === undefined) {
                throw new RequiredError('container','Required parameter container was null or undefined when calling headVideoStream.');
            }
            const localVarPath = `/Videos/{itemId}/stream`
                .replace(`{${"itemId"}}`, encodeURIComponent(String(itemId)))
                .replace(`{${"container"}}`, encodeURIComponent(String(container)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'HEAD', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }

            if (_static !== undefined) {
                localVarQueryParameter['static'] = _static;
            }

            if (params !== undefined) {
                localVarQueryParameter['params'] = params;
            }

            if (tag !== undefined) {
                localVarQueryParameter['tag'] = tag;
            }

            if (deviceProfileId !== undefined) {
                localVarQueryParameter['deviceProfileId'] = deviceProfileId;
            }

            if (playSessionId !== undefined) {
                localVarQueryParameter['playSessionId'] = playSessionId;
            }

            if (segmentContainer !== undefined) {
                localVarQueryParameter['segmentContainer'] = segmentContainer;
            }

            if (segmentLength !== undefined) {
                localVarQueryParameter['segmentLength'] = segmentLength;
            }

            if (minSegments !== undefined) {
                localVarQueryParameter['minSegments'] = minSegments;
            }

            if (mediaSourceId !== undefined) {
                localVarQueryParameter['mediaSourceId'] = mediaSourceId;
            }

            if (deviceId !== undefined) {
                localVarQueryParameter['deviceId'] = deviceId;
            }

            if (audioCodec !== undefined) {
                localVarQueryParameter['audioCodec'] = audioCodec;
            }

            if (enableAutoStreamCopy !== undefined) {
                localVarQueryParameter['enableAutoStreamCopy'] = enableAutoStreamCopy;
            }

            if (allowVideoStreamCopy !== undefined) {
                localVarQueryParameter['allowVideoStreamCopy'] = allowVideoStreamCopy;
            }

            if (allowAudioStreamCopy !== undefined) {
                localVarQueryParameter['allowAudioStreamCopy'] = allowAudioStreamCopy;
            }

            if (breakOnNonKeyFrames !== undefined) {
                localVarQueryParameter['breakOnNonKeyFrames'] = breakOnNonKeyFrames;
            }

            if (audioSampleRate !== undefined) {
                localVarQueryParameter['audioSampleRate'] = audioSampleRate;
            }

            if (maxAudioBitDepth !== undefined) {
                localVarQueryParameter['maxAudioBitDepth'] = maxAudioBitDepth;
            }

            if (audioBitRate !== undefined) {
                localVarQueryParameter['audioBitRate'] = audioBitRate;
            }

            if (audioChannels !== undefined) {
                localVarQueryParameter['audioChannels'] = audioChannels;
            }

            if (maxAudioChannels !== undefined) {
                localVarQueryParameter['maxAudioChannels'] = maxAudioChannels;
            }

            if (profile !== undefined) {
                localVarQueryParameter['profile'] = profile;
            }

            if (level !== undefined) {
                localVarQueryParameter['level'] = level;
            }

            if (framerate !== undefined) {
                localVarQueryParameter['framerate'] = framerate;
            }

            if (maxFramerate !== undefined) {
                localVarQueryParameter['maxFramerate'] = maxFramerate;
            }

            if (copyTimestamps !== undefined) {
                localVarQueryParameter['copyTimestamps'] = copyTimestamps;
            }

            if (startTimeTicks !== undefined) {
                localVarQueryParameter['startTimeTicks'] = startTimeTicks;
            }

            if (width !== undefined) {
                localVarQueryParameter['width'] = width;
            }

            if (height !== undefined) {
                localVarQueryParameter['height'] = height;
            }

            if (videoBitRate !== undefined) {
                localVarQueryParameter['videoBitRate'] = videoBitRate;
            }

            if (subtitleStreamIndex !== undefined) {
                localVarQueryParameter['subtitleStreamIndex'] = subtitleStreamIndex;
            }

            if (subtitleMethod !== undefined) {
                localVarQueryParameter['subtitleMethod'] = subtitleMethod;
            }

            if (maxRefFrames !== undefined) {
                localVarQueryParameter['maxRefFrames'] = maxRefFrames;
            }

            if (maxVideoBitDepth !== undefined) {
                localVarQueryParameter['maxVideoBitDepth'] = maxVideoBitDepth;
            }

            if (requireAvc !== undefined) {
                localVarQueryParameter['requireAvc'] = requireAvc;
            }

            if (deInterlace !== undefined) {
                localVarQueryParameter['deInterlace'] = deInterlace;
            }

            if (requireNonAnamorphic !== undefined) {
                localVarQueryParameter['requireNonAnamorphic'] = requireNonAnamorphic;
            }

            if (transcodingMaxAudioChannels !== undefined) {
                localVarQueryParameter['transcodingMaxAudioChannels'] = transcodingMaxAudioChannels;
            }

            if (cpuCoreLimit !== undefined) {
                localVarQueryParameter['cpuCoreLimit'] = cpuCoreLimit;
            }

            if (liveStreamId !== undefined) {
                localVarQueryParameter['liveStreamId'] = liveStreamId;
            }

            if (enableMpegtsM2TsMode !== undefined) {
                localVarQueryParameter['enableMpegtsM2TsMode'] = enableMpegtsM2TsMode;
            }

            if (videoCodec !== undefined) {
                localVarQueryParameter['videoCodec'] = videoCodec;
            }

            if (subtitleCodec !== undefined) {
                localVarQueryParameter['subtitleCodec'] = subtitleCodec;
            }

            if (transcodingReasons !== undefined) {
                localVarQueryParameter['transcodingReasons'] = transcodingReasons;
            }

            if (audioStreamIndex !== undefined) {
                localVarQueryParameter['audioStreamIndex'] = audioStreamIndex;
            }

            if (videoStreamIndex !== undefined) {
                localVarQueryParameter['videoStreamIndex'] = videoStreamIndex;
            }

            if (context !== undefined) {
                localVarQueryParameter['context'] = context;
            }

            if (streamOptions !== undefined) {
                localVarQueryParameter['streamOptions'] = streamOptions;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets a video stream.
         * @param {string} itemId The item id.
         * @param {string} container The video container. Possible values are: ts, webm, asf, wmv, ogv, mp4, m4v, mkv, mpeg, mpg, avi, 3gp, wmv, wtv, m2ts, mov, iso, flv.
         * @param {string} stream 
         * @param {boolean} [_static] Optional. If true, the original file will be streamed statically without any encoding. Use either no url extension or the original file extension. true/false.
         * @param {string} [params] The streaming parameters.
         * @param {string} [tag] The tag.
         * @param {string} [deviceProfileId] Optional. The dlna device profile id to utilize.
         * @param {string} [playSessionId] The play session id.
         * @param {string} [segmentContainer] The segment container.
         * @param {number} [segmentLength] The segment lenght.
         * @param {number} [minSegments] The minimum number of segments.
         * @param {string} [mediaSourceId] The media version id, if playing an alternate version.
         * @param {string} [deviceId] The device id of the client requesting. Used to stop encoding processes when needed.
         * @param {string} [audioCodec] Optional. Specify a audio codec to encode to, e.g. mp3. If omitted the server will auto-select using the url\&#39;s extension. Options: aac, mp3, vorbis, wma.
         * @param {boolean} [enableAutoStreamCopy] Whether or not to allow automatic stream copy if requested values match the original source. Defaults to true.
         * @param {boolean} [allowVideoStreamCopy] Whether or not to allow copying of the video stream url.
         * @param {boolean} [allowAudioStreamCopy] Whether or not to allow copying of the audio stream url.
         * @param {boolean} [breakOnNonKeyFrames] Optional. Whether to break on non key frames.
         * @param {number} [audioSampleRate] Optional. Specify a specific audio sample rate, e.g. 44100.
         * @param {number} [maxAudioBitDepth] Optional. The maximum audio bit depth.
         * @param {number} [audioBitRate] Optional. Specify an audio bitrate to encode to, e.g. 128000. If omitted this will be left to encoder defaults.
         * @param {number} [audioChannels] Optional. Specify a specific number of audio channels to encode to, e.g. 2.
         * @param {number} [maxAudioChannels] Optional. Specify a maximum number of audio channels to encode to, e.g. 2.
         * @param {string} [profile] Optional. Specify a specific an encoder profile (varies by encoder), e.g. main, baseline, high.
         * @param {string} [level] Optional. Specify a level for the encoder profile (varies by encoder), e.g. 3, 3.1.
         * @param {number} [framerate] Optional. A specific video framerate to encode to, e.g. 23.976. Generally this should be omitted unless the device has specific requirements.
         * @param {number} [maxFramerate] Optional. A specific maximum video framerate to encode to, e.g. 23.976. Generally this should be omitted unless the device has specific requirements.
         * @param {boolean} [copyTimestamps] Whether or not to copy timestamps when transcoding with an offset. Defaults to false.
         * @param {number} [startTimeTicks] Optional. Specify a starting offset, in ticks. 1 tick &#x3D; 10000 ms.
         * @param {number} [width] Optional. The fixed horizontal resolution of the encoded video.
         * @param {number} [height] Optional. The fixed vertical resolution of the encoded video.
         * @param {number} [videoBitRate] Optional. Specify a video bitrate to encode to, e.g. 500000. If omitted this will be left to encoder defaults.
         * @param {number} [subtitleStreamIndex] Optional. The index of the subtitle stream to use. If omitted no subtitles will be used.
         * @param {SubtitleDeliveryMethod} [subtitleMethod] Optional. Specify the subtitle delivery method.
         * @param {number} [maxRefFrames] Optional.
         * @param {number} [maxVideoBitDepth] Optional. The maximum video bit depth.
         * @param {boolean} [requireAvc] Optional. Whether to require avc.
         * @param {boolean} [deInterlace] Optional. Whether to deinterlace the video.
         * @param {boolean} [requireNonAnamorphic] Optional. Whether to require a non anamporphic stream.
         * @param {number} [transcodingMaxAudioChannels] Optional. The maximum number of audio channels to transcode.
         * @param {number} [cpuCoreLimit] Optional. The limit of how many cpu cores to use.
         * @param {string} [liveStreamId] The live stream id.
         * @param {boolean} [enableMpegtsM2TsMode] Optional. Whether to enable the MpegtsM2Ts mode.
         * @param {string} [videoCodec] Optional. Specify a video codec to encode to, e.g. h264. If omitted the server will auto-select using the url\&#39;s extension. Options: h265, h264, mpeg4, theora, vpx, wmv.
         * @param {string} [subtitleCodec] Optional. Specify a subtitle codec to encode to.
         * @param {string} [transcodingReasons] Optional. The transcoding reason.
         * @param {number} [audioStreamIndex] Optional. The index of the audio stream to use. If omitted the first audio stream will be used.
         * @param {number} [videoStreamIndex] Optional. The index of the video stream to use. If omitted the first video stream will be used.
         * @param {EncodingContext} [context] Optional. The MediaBrowser.Model.Dlna.EncodingContext.
         * @param {{ [key: string]: string; }} [streamOptions] Optional. The streaming options.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        headVideoStreamWithExt: async (itemId: string, container: string, stream: string, _static?: boolean, params?: string, tag?: string, deviceProfileId?: string, playSessionId?: string, segmentContainer?: string, segmentLength?: number, minSegments?: number, mediaSourceId?: string, deviceId?: string, audioCodec?: string, enableAutoStreamCopy?: boolean, allowVideoStreamCopy?: boolean, allowAudioStreamCopy?: boolean, breakOnNonKeyFrames?: boolean, audioSampleRate?: number, maxAudioBitDepth?: number, audioBitRate?: number, audioChannels?: number, maxAudioChannels?: number, profile?: string, level?: string, framerate?: number, maxFramerate?: number, copyTimestamps?: boolean, startTimeTicks?: number, width?: number, height?: number, videoBitRate?: number, subtitleStreamIndex?: number, subtitleMethod?: SubtitleDeliveryMethod, maxRefFrames?: number, maxVideoBitDepth?: number, requireAvc?: boolean, deInterlace?: boolean, requireNonAnamorphic?: boolean, transcodingMaxAudioChannels?: number, cpuCoreLimit?: number, liveStreamId?: string, enableMpegtsM2TsMode?: boolean, videoCodec?: string, subtitleCodec?: string, transcodingReasons?: string, audioStreamIndex?: number, videoStreamIndex?: number, context?: EncodingContext, streamOptions?: { [key: string]: string; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'itemId' is not null or undefined
            if (itemId === null || itemId === undefined) {
                throw new RequiredError('itemId','Required parameter itemId was null or undefined when calling headVideoStreamWithExt.');
            }
            // verify required parameter 'container' is not null or undefined
            if (container === null || container === undefined) {
                throw new RequiredError('container','Required parameter container was null or undefined when calling headVideoStreamWithExt.');
            }
            // verify required parameter 'stream' is not null or undefined
            if (stream === null || stream === undefined) {
                throw new RequiredError('stream','Required parameter stream was null or undefined when calling headVideoStreamWithExt.');
            }
            const localVarPath = `/Videos/{itemId}/{stream}.{container}`
                .replace(`{${"itemId"}}`, encodeURIComponent(String(itemId)))
                .replace(`{${"container"}}`, encodeURIComponent(String(container)))
                .replace(`{${"stream"}}`, encodeURIComponent(String(stream)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'HEAD', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }

            if (_static !== undefined) {
                localVarQueryParameter['static'] = _static;
            }

            if (params !== undefined) {
                localVarQueryParameter['params'] = params;
            }

            if (tag !== undefined) {
                localVarQueryParameter['tag'] = tag;
            }

            if (deviceProfileId !== undefined) {
                localVarQueryParameter['deviceProfileId'] = deviceProfileId;
            }

            if (playSessionId !== undefined) {
                localVarQueryParameter['playSessionId'] = playSessionId;
            }

            if (segmentContainer !== undefined) {
                localVarQueryParameter['segmentContainer'] = segmentContainer;
            }

            if (segmentLength !== undefined) {
                localVarQueryParameter['segmentLength'] = segmentLength;
            }

            if (minSegments !== undefined) {
                localVarQueryParameter['minSegments'] = minSegments;
            }

            if (mediaSourceId !== undefined) {
                localVarQueryParameter['mediaSourceId'] = mediaSourceId;
            }

            if (deviceId !== undefined) {
                localVarQueryParameter['deviceId'] = deviceId;
            }

            if (audioCodec !== undefined) {
                localVarQueryParameter['audioCodec'] = audioCodec;
            }

            if (enableAutoStreamCopy !== undefined) {
                localVarQueryParameter['enableAutoStreamCopy'] = enableAutoStreamCopy;
            }

            if (allowVideoStreamCopy !== undefined) {
                localVarQueryParameter['allowVideoStreamCopy'] = allowVideoStreamCopy;
            }

            if (allowAudioStreamCopy !== undefined) {
                localVarQueryParameter['allowAudioStreamCopy'] = allowAudioStreamCopy;
            }

            if (breakOnNonKeyFrames !== undefined) {
                localVarQueryParameter['breakOnNonKeyFrames'] = breakOnNonKeyFrames;
            }

            if (audioSampleRate !== undefined) {
                localVarQueryParameter['audioSampleRate'] = audioSampleRate;
            }

            if (maxAudioBitDepth !== undefined) {
                localVarQueryParameter['maxAudioBitDepth'] = maxAudioBitDepth;
            }

            if (audioBitRate !== undefined) {
                localVarQueryParameter['audioBitRate'] = audioBitRate;
            }

            if (audioChannels !== undefined) {
                localVarQueryParameter['audioChannels'] = audioChannels;
            }

            if (maxAudioChannels !== undefined) {
                localVarQueryParameter['maxAudioChannels'] = maxAudioChannels;
            }

            if (profile !== undefined) {
                localVarQueryParameter['profile'] = profile;
            }

            if (level !== undefined) {
                localVarQueryParameter['level'] = level;
            }

            if (framerate !== undefined) {
                localVarQueryParameter['framerate'] = framerate;
            }

            if (maxFramerate !== undefined) {
                localVarQueryParameter['maxFramerate'] = maxFramerate;
            }

            if (copyTimestamps !== undefined) {
                localVarQueryParameter['copyTimestamps'] = copyTimestamps;
            }

            if (startTimeTicks !== undefined) {
                localVarQueryParameter['startTimeTicks'] = startTimeTicks;
            }

            if (width !== undefined) {
                localVarQueryParameter['width'] = width;
            }

            if (height !== undefined) {
                localVarQueryParameter['height'] = height;
            }

            if (videoBitRate !== undefined) {
                localVarQueryParameter['videoBitRate'] = videoBitRate;
            }

            if (subtitleStreamIndex !== undefined) {
                localVarQueryParameter['subtitleStreamIndex'] = subtitleStreamIndex;
            }

            if (subtitleMethod !== undefined) {
                localVarQueryParameter['subtitleMethod'] = subtitleMethod;
            }

            if (maxRefFrames !== undefined) {
                localVarQueryParameter['maxRefFrames'] = maxRefFrames;
            }

            if (maxVideoBitDepth !== undefined) {
                localVarQueryParameter['maxVideoBitDepth'] = maxVideoBitDepth;
            }

            if (requireAvc !== undefined) {
                localVarQueryParameter['requireAvc'] = requireAvc;
            }

            if (deInterlace !== undefined) {
                localVarQueryParameter['deInterlace'] = deInterlace;
            }

            if (requireNonAnamorphic !== undefined) {
                localVarQueryParameter['requireNonAnamorphic'] = requireNonAnamorphic;
            }

            if (transcodingMaxAudioChannels !== undefined) {
                localVarQueryParameter['transcodingMaxAudioChannels'] = transcodingMaxAudioChannels;
            }

            if (cpuCoreLimit !== undefined) {
                localVarQueryParameter['cpuCoreLimit'] = cpuCoreLimit;
            }

            if (liveStreamId !== undefined) {
                localVarQueryParameter['liveStreamId'] = liveStreamId;
            }

            if (enableMpegtsM2TsMode !== undefined) {
                localVarQueryParameter['enableMpegtsM2TsMode'] = enableMpegtsM2TsMode;
            }

            if (videoCodec !== undefined) {
                localVarQueryParameter['videoCodec'] = videoCodec;
            }

            if (subtitleCodec !== undefined) {
                localVarQueryParameter['subtitleCodec'] = subtitleCodec;
            }

            if (transcodingReasons !== undefined) {
                localVarQueryParameter['transcodingReasons'] = transcodingReasons;
            }

            if (audioStreamIndex !== undefined) {
                localVarQueryParameter['audioStreamIndex'] = audioStreamIndex;
            }

            if (videoStreamIndex !== undefined) {
                localVarQueryParameter['videoStreamIndex'] = videoStreamIndex;
            }

            if (context !== undefined) {
                localVarQueryParameter['context'] = context;
            }

            if (streamOptions !== undefined) {
                localVarQueryParameter['streamOptions'] = streamOptions;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Merges videos into a single record.
         * @param {string} itemIds Item id list. This allows multiple, comma delimited.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mergeVersions: async (itemIds: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'itemIds' is not null or undefined
            if (itemIds === null || itemIds === undefined) {
                throw new RequiredError('itemIds','Required parameter itemIds was null or undefined when calling mergeVersions.');
            }
            const localVarPath = `/Videos/MergeVersions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }

            if (itemIds !== undefined) {
                localVarQueryParameter['itemIds'] = itemIds;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * VideosApi - functional programming interface
 * @export
 */
export const VideosApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Removes alternate video sources.
         * @param {string} itemId The item id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAlternateSources(itemId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await VideosApiAxiosParamCreator(configuration).deleteAlternateSources(itemId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Gets additional parts for a video.
         * @param {string} itemId The item id.
         * @param {string} [userId] Optional. Filter by user id, and attach user data.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAdditionalPart(itemId: string, userId?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BaseItemDtoQueryResult>> {
            const localVarAxiosArgs = await VideosApiAxiosParamCreator(configuration).getAdditionalPart(itemId, userId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Gets a video stream.
         * @param {string} itemId The item id.
         * @param {string} container The video container. Possible values are: ts, webm, asf, wmv, ogv, mp4, m4v, mkv, mpeg, mpg, avi, 3gp, wmv, wtv, m2ts, mov, iso, flv.
         * @param {boolean} [_static] Optional. If true, the original file will be streamed statically without any encoding. Use either no url extension or the original file extension. true/false.
         * @param {string} [params] The streaming parameters.
         * @param {string} [tag] The tag.
         * @param {string} [deviceProfileId] Optional. The dlna device profile id to utilize.
         * @param {string} [playSessionId] The play session id.
         * @param {string} [segmentContainer] The segment container.
         * @param {number} [segmentLength] The segment lenght.
         * @param {number} [minSegments] The minimum number of segments.
         * @param {string} [mediaSourceId] The media version id, if playing an alternate version.
         * @param {string} [deviceId] The device id of the client requesting. Used to stop encoding processes when needed.
         * @param {string} [audioCodec] Optional. Specify a audio codec to encode to, e.g. mp3. If omitted the server will auto-select using the url\&#39;s extension. Options: aac, mp3, vorbis, wma.
         * @param {boolean} [enableAutoStreamCopy] Whether or not to allow automatic stream copy if requested values match the original source. Defaults to true.
         * @param {boolean} [allowVideoStreamCopy] Whether or not to allow copying of the video stream url.
         * @param {boolean} [allowAudioStreamCopy] Whether or not to allow copying of the audio stream url.
         * @param {boolean} [breakOnNonKeyFrames] Optional. Whether to break on non key frames.
         * @param {number} [audioSampleRate] Optional. Specify a specific audio sample rate, e.g. 44100.
         * @param {number} [maxAudioBitDepth] Optional. The maximum audio bit depth.
         * @param {number} [audioBitRate] Optional. Specify an audio bitrate to encode to, e.g. 128000. If omitted this will be left to encoder defaults.
         * @param {number} [audioChannels] Optional. Specify a specific number of audio channels to encode to, e.g. 2.
         * @param {number} [maxAudioChannels] Optional. Specify a maximum number of audio channels to encode to, e.g. 2.
         * @param {string} [profile] Optional. Specify a specific an encoder profile (varies by encoder), e.g. main, baseline, high.
         * @param {string} [level] Optional. Specify a level for the encoder profile (varies by encoder), e.g. 3, 3.1.
         * @param {number} [framerate] Optional. A specific video framerate to encode to, e.g. 23.976. Generally this should be omitted unless the device has specific requirements.
         * @param {number} [maxFramerate] Optional. A specific maximum video framerate to encode to, e.g. 23.976. Generally this should be omitted unless the device has specific requirements.
         * @param {boolean} [copyTimestamps] Whether or not to copy timestamps when transcoding with an offset. Defaults to false.
         * @param {number} [startTimeTicks] Optional. Specify a starting offset, in ticks. 1 tick &#x3D; 10000 ms.
         * @param {number} [width] Optional. The fixed horizontal resolution of the encoded video.
         * @param {number} [height] Optional. The fixed vertical resolution of the encoded video.
         * @param {number} [videoBitRate] Optional. Specify a video bitrate to encode to, e.g. 500000. If omitted this will be left to encoder defaults.
         * @param {number} [subtitleStreamIndex] Optional. The index of the subtitle stream to use. If omitted no subtitles will be used.
         * @param {SubtitleDeliveryMethod} [subtitleMethod] Optional. Specify the subtitle delivery method.
         * @param {number} [maxRefFrames] Optional.
         * @param {number} [maxVideoBitDepth] Optional. The maximum video bit depth.
         * @param {boolean} [requireAvc] Optional. Whether to require avc.
         * @param {boolean} [deInterlace] Optional. Whether to deinterlace the video.
         * @param {boolean} [requireNonAnamorphic] Optional. Whether to require a non anamporphic stream.
         * @param {number} [transcodingMaxAudioChannels] Optional. The maximum number of audio channels to transcode.
         * @param {number} [cpuCoreLimit] Optional. The limit of how many cpu cores to use.
         * @param {string} [liveStreamId] The live stream id.
         * @param {boolean} [enableMpegtsM2TsMode] Optional. Whether to enable the MpegtsM2Ts mode.
         * @param {string} [videoCodec] Optional. Specify a video codec to encode to, e.g. h264. If omitted the server will auto-select using the url\&#39;s extension. Options: h265, h264, mpeg4, theora, vpx, wmv.
         * @param {string} [subtitleCodec] Optional. Specify a subtitle codec to encode to.
         * @param {string} [transcodingReasons] Optional. The transcoding reason.
         * @param {number} [audioStreamIndex] Optional. The index of the audio stream to use. If omitted the first audio stream will be used.
         * @param {number} [videoStreamIndex] Optional. The index of the video stream to use. If omitted the first video stream will be used.
         * @param {EncodingContext} [context] Optional. The MediaBrowser.Model.Dlna.EncodingContext.
         * @param {{ [key: string]: string; }} [streamOptions] Optional. The streaming options.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getVideoStream(itemId: string, container: string, _static?: boolean, params?: string, tag?: string, deviceProfileId?: string, playSessionId?: string, segmentContainer?: string, segmentLength?: number, minSegments?: number, mediaSourceId?: string, deviceId?: string, audioCodec?: string, enableAutoStreamCopy?: boolean, allowVideoStreamCopy?: boolean, allowAudioStreamCopy?: boolean, breakOnNonKeyFrames?: boolean, audioSampleRate?: number, maxAudioBitDepth?: number, audioBitRate?: number, audioChannels?: number, maxAudioChannels?: number, profile?: string, level?: string, framerate?: number, maxFramerate?: number, copyTimestamps?: boolean, startTimeTicks?: number, width?: number, height?: number, videoBitRate?: number, subtitleStreamIndex?: number, subtitleMethod?: SubtitleDeliveryMethod, maxRefFrames?: number, maxVideoBitDepth?: number, requireAvc?: boolean, deInterlace?: boolean, requireNonAnamorphic?: boolean, transcodingMaxAudioChannels?: number, cpuCoreLimit?: number, liveStreamId?: string, enableMpegtsM2TsMode?: boolean, videoCodec?: string, subtitleCodec?: string, transcodingReasons?: string, audioStreamIndex?: number, videoStreamIndex?: number, context?: EncodingContext, streamOptions?: { [key: string]: string; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await VideosApiAxiosParamCreator(configuration).getVideoStream(itemId, container, _static, params, tag, deviceProfileId, playSessionId, segmentContainer, segmentLength, minSegments, mediaSourceId, deviceId, audioCodec, enableAutoStreamCopy, allowVideoStreamCopy, allowAudioStreamCopy, breakOnNonKeyFrames, audioSampleRate, maxAudioBitDepth, audioBitRate, audioChannels, maxAudioChannels, profile, level, framerate, maxFramerate, copyTimestamps, startTimeTicks, width, height, videoBitRate, subtitleStreamIndex, subtitleMethod, maxRefFrames, maxVideoBitDepth, requireAvc, deInterlace, requireNonAnamorphic, transcodingMaxAudioChannels, cpuCoreLimit, liveStreamId, enableMpegtsM2TsMode, videoCodec, subtitleCodec, transcodingReasons, audioStreamIndex, videoStreamIndex, context, streamOptions, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Gets a video stream.
         * @param {string} itemId The item id.
         * @param {string} container The video container. Possible values are: ts, webm, asf, wmv, ogv, mp4, m4v, mkv, mpeg, mpg, avi, 3gp, wmv, wtv, m2ts, mov, iso, flv.
         * @param {string} stream 
         * @param {boolean} [_static] Optional. If true, the original file will be streamed statically without any encoding. Use either no url extension or the original file extension. true/false.
         * @param {string} [params] The streaming parameters.
         * @param {string} [tag] The tag.
         * @param {string} [deviceProfileId] Optional. The dlna device profile id to utilize.
         * @param {string} [playSessionId] The play session id.
         * @param {string} [segmentContainer] The segment container.
         * @param {number} [segmentLength] The segment lenght.
         * @param {number} [minSegments] The minimum number of segments.
         * @param {string} [mediaSourceId] The media version id, if playing an alternate version.
         * @param {string} [deviceId] The device id of the client requesting. Used to stop encoding processes when needed.
         * @param {string} [audioCodec] Optional. Specify a audio codec to encode to, e.g. mp3. If omitted the server will auto-select using the url\&#39;s extension. Options: aac, mp3, vorbis, wma.
         * @param {boolean} [enableAutoStreamCopy] Whether or not to allow automatic stream copy if requested values match the original source. Defaults to true.
         * @param {boolean} [allowVideoStreamCopy] Whether or not to allow copying of the video stream url.
         * @param {boolean} [allowAudioStreamCopy] Whether or not to allow copying of the audio stream url.
         * @param {boolean} [breakOnNonKeyFrames] Optional. Whether to break on non key frames.
         * @param {number} [audioSampleRate] Optional. Specify a specific audio sample rate, e.g. 44100.
         * @param {number} [maxAudioBitDepth] Optional. The maximum audio bit depth.
         * @param {number} [audioBitRate] Optional. Specify an audio bitrate to encode to, e.g. 128000. If omitted this will be left to encoder defaults.
         * @param {number} [audioChannels] Optional. Specify a specific number of audio channels to encode to, e.g. 2.
         * @param {number} [maxAudioChannels] Optional. Specify a maximum number of audio channels to encode to, e.g. 2.
         * @param {string} [profile] Optional. Specify a specific an encoder profile (varies by encoder), e.g. main, baseline, high.
         * @param {string} [level] Optional. Specify a level for the encoder profile (varies by encoder), e.g. 3, 3.1.
         * @param {number} [framerate] Optional. A specific video framerate to encode to, e.g. 23.976. Generally this should be omitted unless the device has specific requirements.
         * @param {number} [maxFramerate] Optional. A specific maximum video framerate to encode to, e.g. 23.976. Generally this should be omitted unless the device has specific requirements.
         * @param {boolean} [copyTimestamps] Whether or not to copy timestamps when transcoding with an offset. Defaults to false.
         * @param {number} [startTimeTicks] Optional. Specify a starting offset, in ticks. 1 tick &#x3D; 10000 ms.
         * @param {number} [width] Optional. The fixed horizontal resolution of the encoded video.
         * @param {number} [height] Optional. The fixed vertical resolution of the encoded video.
         * @param {number} [videoBitRate] Optional. Specify a video bitrate to encode to, e.g. 500000. If omitted this will be left to encoder defaults.
         * @param {number} [subtitleStreamIndex] Optional. The index of the subtitle stream to use. If omitted no subtitles will be used.
         * @param {SubtitleDeliveryMethod} [subtitleMethod] Optional. Specify the subtitle delivery method.
         * @param {number} [maxRefFrames] Optional.
         * @param {number} [maxVideoBitDepth] Optional. The maximum video bit depth.
         * @param {boolean} [requireAvc] Optional. Whether to require avc.
         * @param {boolean} [deInterlace] Optional. Whether to deinterlace the video.
         * @param {boolean} [requireNonAnamorphic] Optional. Whether to require a non anamporphic stream.
         * @param {number} [transcodingMaxAudioChannels] Optional. The maximum number of audio channels to transcode.
         * @param {number} [cpuCoreLimit] Optional. The limit of how many cpu cores to use.
         * @param {string} [liveStreamId] The live stream id.
         * @param {boolean} [enableMpegtsM2TsMode] Optional. Whether to enable the MpegtsM2Ts mode.
         * @param {string} [videoCodec] Optional. Specify a video codec to encode to, e.g. h264. If omitted the server will auto-select using the url\&#39;s extension. Options: h265, h264, mpeg4, theora, vpx, wmv.
         * @param {string} [subtitleCodec] Optional. Specify a subtitle codec to encode to.
         * @param {string} [transcodingReasons] Optional. The transcoding reason.
         * @param {number} [audioStreamIndex] Optional. The index of the audio stream to use. If omitted the first audio stream will be used.
         * @param {number} [videoStreamIndex] Optional. The index of the video stream to use. If omitted the first video stream will be used.
         * @param {EncodingContext} [context] Optional. The MediaBrowser.Model.Dlna.EncodingContext.
         * @param {{ [key: string]: string; }} [streamOptions] Optional. The streaming options.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getVideoStreamWithExt(itemId: string, container: string, stream: string, _static?: boolean, params?: string, tag?: string, deviceProfileId?: string, playSessionId?: string, segmentContainer?: string, segmentLength?: number, minSegments?: number, mediaSourceId?: string, deviceId?: string, audioCodec?: string, enableAutoStreamCopy?: boolean, allowVideoStreamCopy?: boolean, allowAudioStreamCopy?: boolean, breakOnNonKeyFrames?: boolean, audioSampleRate?: number, maxAudioBitDepth?: number, audioBitRate?: number, audioChannels?: number, maxAudioChannels?: number, profile?: string, level?: string, framerate?: number, maxFramerate?: number, copyTimestamps?: boolean, startTimeTicks?: number, width?: number, height?: number, videoBitRate?: number, subtitleStreamIndex?: number, subtitleMethod?: SubtitleDeliveryMethod, maxRefFrames?: number, maxVideoBitDepth?: number, requireAvc?: boolean, deInterlace?: boolean, requireNonAnamorphic?: boolean, transcodingMaxAudioChannels?: number, cpuCoreLimit?: number, liveStreamId?: string, enableMpegtsM2TsMode?: boolean, videoCodec?: string, subtitleCodec?: string, transcodingReasons?: string, audioStreamIndex?: number, videoStreamIndex?: number, context?: EncodingContext, streamOptions?: { [key: string]: string; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await VideosApiAxiosParamCreator(configuration).getVideoStreamWithExt(itemId, container, stream, _static, params, tag, deviceProfileId, playSessionId, segmentContainer, segmentLength, minSegments, mediaSourceId, deviceId, audioCodec, enableAutoStreamCopy, allowVideoStreamCopy, allowAudioStreamCopy, breakOnNonKeyFrames, audioSampleRate, maxAudioBitDepth, audioBitRate, audioChannels, maxAudioChannels, profile, level, framerate, maxFramerate, copyTimestamps, startTimeTicks, width, height, videoBitRate, subtitleStreamIndex, subtitleMethod, maxRefFrames, maxVideoBitDepth, requireAvc, deInterlace, requireNonAnamorphic, transcodingMaxAudioChannels, cpuCoreLimit, liveStreamId, enableMpegtsM2TsMode, videoCodec, subtitleCodec, transcodingReasons, audioStreamIndex, videoStreamIndex, context, streamOptions, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Gets a video stream.
         * @param {string} itemId The item id.
         * @param {string} container The video container. Possible values are: ts, webm, asf, wmv, ogv, mp4, m4v, mkv, mpeg, mpg, avi, 3gp, wmv, wtv, m2ts, mov, iso, flv.
         * @param {boolean} [_static] Optional. If true, the original file will be streamed statically without any encoding. Use either no url extension or the original file extension. true/false.
         * @param {string} [params] The streaming parameters.
         * @param {string} [tag] The tag.
         * @param {string} [deviceProfileId] Optional. The dlna device profile id to utilize.
         * @param {string} [playSessionId] The play session id.
         * @param {string} [segmentContainer] The segment container.
         * @param {number} [segmentLength] The segment lenght.
         * @param {number} [minSegments] The minimum number of segments.
         * @param {string} [mediaSourceId] The media version id, if playing an alternate version.
         * @param {string} [deviceId] The device id of the client requesting. Used to stop encoding processes when needed.
         * @param {string} [audioCodec] Optional. Specify a audio codec to encode to, e.g. mp3. If omitted the server will auto-select using the url\&#39;s extension. Options: aac, mp3, vorbis, wma.
         * @param {boolean} [enableAutoStreamCopy] Whether or not to allow automatic stream copy if requested values match the original source. Defaults to true.
         * @param {boolean} [allowVideoStreamCopy] Whether or not to allow copying of the video stream url.
         * @param {boolean} [allowAudioStreamCopy] Whether or not to allow copying of the audio stream url.
         * @param {boolean} [breakOnNonKeyFrames] Optional. Whether to break on non key frames.
         * @param {number} [audioSampleRate] Optional. Specify a specific audio sample rate, e.g. 44100.
         * @param {number} [maxAudioBitDepth] Optional. The maximum audio bit depth.
         * @param {number} [audioBitRate] Optional. Specify an audio bitrate to encode to, e.g. 128000. If omitted this will be left to encoder defaults.
         * @param {number} [audioChannels] Optional. Specify a specific number of audio channels to encode to, e.g. 2.
         * @param {number} [maxAudioChannels] Optional. Specify a maximum number of audio channels to encode to, e.g. 2.
         * @param {string} [profile] Optional. Specify a specific an encoder profile (varies by encoder), e.g. main, baseline, high.
         * @param {string} [level] Optional. Specify a level for the encoder profile (varies by encoder), e.g. 3, 3.1.
         * @param {number} [framerate] Optional. A specific video framerate to encode to, e.g. 23.976. Generally this should be omitted unless the device has specific requirements.
         * @param {number} [maxFramerate] Optional. A specific maximum video framerate to encode to, e.g. 23.976. Generally this should be omitted unless the device has specific requirements.
         * @param {boolean} [copyTimestamps] Whether or not to copy timestamps when transcoding with an offset. Defaults to false.
         * @param {number} [startTimeTicks] Optional. Specify a starting offset, in ticks. 1 tick &#x3D; 10000 ms.
         * @param {number} [width] Optional. The fixed horizontal resolution of the encoded video.
         * @param {number} [height] Optional. The fixed vertical resolution of the encoded video.
         * @param {number} [videoBitRate] Optional. Specify a video bitrate to encode to, e.g. 500000. If omitted this will be left to encoder defaults.
         * @param {number} [subtitleStreamIndex] Optional. The index of the subtitle stream to use. If omitted no subtitles will be used.
         * @param {SubtitleDeliveryMethod} [subtitleMethod] Optional. Specify the subtitle delivery method.
         * @param {number} [maxRefFrames] Optional.
         * @param {number} [maxVideoBitDepth] Optional. The maximum video bit depth.
         * @param {boolean} [requireAvc] Optional. Whether to require avc.
         * @param {boolean} [deInterlace] Optional. Whether to deinterlace the video.
         * @param {boolean} [requireNonAnamorphic] Optional. Whether to require a non anamporphic stream.
         * @param {number} [transcodingMaxAudioChannels] Optional. The maximum number of audio channels to transcode.
         * @param {number} [cpuCoreLimit] Optional. The limit of how many cpu cores to use.
         * @param {string} [liveStreamId] The live stream id.
         * @param {boolean} [enableMpegtsM2TsMode] Optional. Whether to enable the MpegtsM2Ts mode.
         * @param {string} [videoCodec] Optional. Specify a video codec to encode to, e.g. h264. If omitted the server will auto-select using the url\&#39;s extension. Options: h265, h264, mpeg4, theora, vpx, wmv.
         * @param {string} [subtitleCodec] Optional. Specify a subtitle codec to encode to.
         * @param {string} [transcodingReasons] Optional. The transcoding reason.
         * @param {number} [audioStreamIndex] Optional. The index of the audio stream to use. If omitted the first audio stream will be used.
         * @param {number} [videoStreamIndex] Optional. The index of the video stream to use. If omitted the first video stream will be used.
         * @param {EncodingContext} [context] Optional. The MediaBrowser.Model.Dlna.EncodingContext.
         * @param {{ [key: string]: string; }} [streamOptions] Optional. The streaming options.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async headVideoStream(itemId: string, container: string, _static?: boolean, params?: string, tag?: string, deviceProfileId?: string, playSessionId?: string, segmentContainer?: string, segmentLength?: number, minSegments?: number, mediaSourceId?: string, deviceId?: string, audioCodec?: string, enableAutoStreamCopy?: boolean, allowVideoStreamCopy?: boolean, allowAudioStreamCopy?: boolean, breakOnNonKeyFrames?: boolean, audioSampleRate?: number, maxAudioBitDepth?: number, audioBitRate?: number, audioChannels?: number, maxAudioChannels?: number, profile?: string, level?: string, framerate?: number, maxFramerate?: number, copyTimestamps?: boolean, startTimeTicks?: number, width?: number, height?: number, videoBitRate?: number, subtitleStreamIndex?: number, subtitleMethod?: SubtitleDeliveryMethod, maxRefFrames?: number, maxVideoBitDepth?: number, requireAvc?: boolean, deInterlace?: boolean, requireNonAnamorphic?: boolean, transcodingMaxAudioChannels?: number, cpuCoreLimit?: number, liveStreamId?: string, enableMpegtsM2TsMode?: boolean, videoCodec?: string, subtitleCodec?: string, transcodingReasons?: string, audioStreamIndex?: number, videoStreamIndex?: number, context?: EncodingContext, streamOptions?: { [key: string]: string; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await VideosApiAxiosParamCreator(configuration).headVideoStream(itemId, container, _static, params, tag, deviceProfileId, playSessionId, segmentContainer, segmentLength, minSegments, mediaSourceId, deviceId, audioCodec, enableAutoStreamCopy, allowVideoStreamCopy, allowAudioStreamCopy, breakOnNonKeyFrames, audioSampleRate, maxAudioBitDepth, audioBitRate, audioChannels, maxAudioChannels, profile, level, framerate, maxFramerate, copyTimestamps, startTimeTicks, width, height, videoBitRate, subtitleStreamIndex, subtitleMethod, maxRefFrames, maxVideoBitDepth, requireAvc, deInterlace, requireNonAnamorphic, transcodingMaxAudioChannels, cpuCoreLimit, liveStreamId, enableMpegtsM2TsMode, videoCodec, subtitleCodec, transcodingReasons, audioStreamIndex, videoStreamIndex, context, streamOptions, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Gets a video stream.
         * @param {string} itemId The item id.
         * @param {string} container The video container. Possible values are: ts, webm, asf, wmv, ogv, mp4, m4v, mkv, mpeg, mpg, avi, 3gp, wmv, wtv, m2ts, mov, iso, flv.
         * @param {string} stream 
         * @param {boolean} [_static] Optional. If true, the original file will be streamed statically without any encoding. Use either no url extension or the original file extension. true/false.
         * @param {string} [params] The streaming parameters.
         * @param {string} [tag] The tag.
         * @param {string} [deviceProfileId] Optional. The dlna device profile id to utilize.
         * @param {string} [playSessionId] The play session id.
         * @param {string} [segmentContainer] The segment container.
         * @param {number} [segmentLength] The segment lenght.
         * @param {number} [minSegments] The minimum number of segments.
         * @param {string} [mediaSourceId] The media version id, if playing an alternate version.
         * @param {string} [deviceId] The device id of the client requesting. Used to stop encoding processes when needed.
         * @param {string} [audioCodec] Optional. Specify a audio codec to encode to, e.g. mp3. If omitted the server will auto-select using the url\&#39;s extension. Options: aac, mp3, vorbis, wma.
         * @param {boolean} [enableAutoStreamCopy] Whether or not to allow automatic stream copy if requested values match the original source. Defaults to true.
         * @param {boolean} [allowVideoStreamCopy] Whether or not to allow copying of the video stream url.
         * @param {boolean} [allowAudioStreamCopy] Whether or not to allow copying of the audio stream url.
         * @param {boolean} [breakOnNonKeyFrames] Optional. Whether to break on non key frames.
         * @param {number} [audioSampleRate] Optional. Specify a specific audio sample rate, e.g. 44100.
         * @param {number} [maxAudioBitDepth] Optional. The maximum audio bit depth.
         * @param {number} [audioBitRate] Optional. Specify an audio bitrate to encode to, e.g. 128000. If omitted this will be left to encoder defaults.
         * @param {number} [audioChannels] Optional. Specify a specific number of audio channels to encode to, e.g. 2.
         * @param {number} [maxAudioChannels] Optional. Specify a maximum number of audio channels to encode to, e.g. 2.
         * @param {string} [profile] Optional. Specify a specific an encoder profile (varies by encoder), e.g. main, baseline, high.
         * @param {string} [level] Optional. Specify a level for the encoder profile (varies by encoder), e.g. 3, 3.1.
         * @param {number} [framerate] Optional. A specific video framerate to encode to, e.g. 23.976. Generally this should be omitted unless the device has specific requirements.
         * @param {number} [maxFramerate] Optional. A specific maximum video framerate to encode to, e.g. 23.976. Generally this should be omitted unless the device has specific requirements.
         * @param {boolean} [copyTimestamps] Whether or not to copy timestamps when transcoding with an offset. Defaults to false.
         * @param {number} [startTimeTicks] Optional. Specify a starting offset, in ticks. 1 tick &#x3D; 10000 ms.
         * @param {number} [width] Optional. The fixed horizontal resolution of the encoded video.
         * @param {number} [height] Optional. The fixed vertical resolution of the encoded video.
         * @param {number} [videoBitRate] Optional. Specify a video bitrate to encode to, e.g. 500000. If omitted this will be left to encoder defaults.
         * @param {number} [subtitleStreamIndex] Optional. The index of the subtitle stream to use. If omitted no subtitles will be used.
         * @param {SubtitleDeliveryMethod} [subtitleMethod] Optional. Specify the subtitle delivery method.
         * @param {number} [maxRefFrames] Optional.
         * @param {number} [maxVideoBitDepth] Optional. The maximum video bit depth.
         * @param {boolean} [requireAvc] Optional. Whether to require avc.
         * @param {boolean} [deInterlace] Optional. Whether to deinterlace the video.
         * @param {boolean} [requireNonAnamorphic] Optional. Whether to require a non anamporphic stream.
         * @param {number} [transcodingMaxAudioChannels] Optional. The maximum number of audio channels to transcode.
         * @param {number} [cpuCoreLimit] Optional. The limit of how many cpu cores to use.
         * @param {string} [liveStreamId] The live stream id.
         * @param {boolean} [enableMpegtsM2TsMode] Optional. Whether to enable the MpegtsM2Ts mode.
         * @param {string} [videoCodec] Optional. Specify a video codec to encode to, e.g. h264. If omitted the server will auto-select using the url\&#39;s extension. Options: h265, h264, mpeg4, theora, vpx, wmv.
         * @param {string} [subtitleCodec] Optional. Specify a subtitle codec to encode to.
         * @param {string} [transcodingReasons] Optional. The transcoding reason.
         * @param {number} [audioStreamIndex] Optional. The index of the audio stream to use. If omitted the first audio stream will be used.
         * @param {number} [videoStreamIndex] Optional. The index of the video stream to use. If omitted the first video stream will be used.
         * @param {EncodingContext} [context] Optional. The MediaBrowser.Model.Dlna.EncodingContext.
         * @param {{ [key: string]: string; }} [streamOptions] Optional. The streaming options.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async headVideoStreamWithExt(itemId: string, container: string, stream: string, _static?: boolean, params?: string, tag?: string, deviceProfileId?: string, playSessionId?: string, segmentContainer?: string, segmentLength?: number, minSegments?: number, mediaSourceId?: string, deviceId?: string, audioCodec?: string, enableAutoStreamCopy?: boolean, allowVideoStreamCopy?: boolean, allowAudioStreamCopy?: boolean, breakOnNonKeyFrames?: boolean, audioSampleRate?: number, maxAudioBitDepth?: number, audioBitRate?: number, audioChannels?: number, maxAudioChannels?: number, profile?: string, level?: string, framerate?: number, maxFramerate?: number, copyTimestamps?: boolean, startTimeTicks?: number, width?: number, height?: number, videoBitRate?: number, subtitleStreamIndex?: number, subtitleMethod?: SubtitleDeliveryMethod, maxRefFrames?: number, maxVideoBitDepth?: number, requireAvc?: boolean, deInterlace?: boolean, requireNonAnamorphic?: boolean, transcodingMaxAudioChannels?: number, cpuCoreLimit?: number, liveStreamId?: string, enableMpegtsM2TsMode?: boolean, videoCodec?: string, subtitleCodec?: string, transcodingReasons?: string, audioStreamIndex?: number, videoStreamIndex?: number, context?: EncodingContext, streamOptions?: { [key: string]: string; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await VideosApiAxiosParamCreator(configuration).headVideoStreamWithExt(itemId, container, stream, _static, params, tag, deviceProfileId, playSessionId, segmentContainer, segmentLength, minSegments, mediaSourceId, deviceId, audioCodec, enableAutoStreamCopy, allowVideoStreamCopy, allowAudioStreamCopy, breakOnNonKeyFrames, audioSampleRate, maxAudioBitDepth, audioBitRate, audioChannels, maxAudioChannels, profile, level, framerate, maxFramerate, copyTimestamps, startTimeTicks, width, height, videoBitRate, subtitleStreamIndex, subtitleMethod, maxRefFrames, maxVideoBitDepth, requireAvc, deInterlace, requireNonAnamorphic, transcodingMaxAudioChannels, cpuCoreLimit, liveStreamId, enableMpegtsM2TsMode, videoCodec, subtitleCodec, transcodingReasons, audioStreamIndex, videoStreamIndex, context, streamOptions, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Merges videos into a single record.
         * @param {string} itemIds Item id list. This allows multiple, comma delimited.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mergeVersions(itemIds: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await VideosApiAxiosParamCreator(configuration).mergeVersions(itemIds, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * VideosApi - factory interface
 * @export
 */
export const VideosApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Removes alternate video sources.
         * @param {string} itemId The item id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAlternateSources(itemId: string, options?: any): AxiosPromise<void> {
            return VideosApiFp(configuration).deleteAlternateSources(itemId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets additional parts for a video.
         * @param {string} itemId The item id.
         * @param {string} [userId] Optional. Filter by user id, and attach user data.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdditionalPart(itemId: string, userId?: string, options?: any): AxiosPromise<BaseItemDtoQueryResult> {
            return VideosApiFp(configuration).getAdditionalPart(itemId, userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets a video stream.
         * @param {string} itemId The item id.
         * @param {string} container The video container. Possible values are: ts, webm, asf, wmv, ogv, mp4, m4v, mkv, mpeg, mpg, avi, 3gp, wmv, wtv, m2ts, mov, iso, flv.
         * @param {boolean} [_static] Optional. If true, the original file will be streamed statically without any encoding. Use either no url extension or the original file extension. true/false.
         * @param {string} [params] The streaming parameters.
         * @param {string} [tag] The tag.
         * @param {string} [deviceProfileId] Optional. The dlna device profile id to utilize.
         * @param {string} [playSessionId] The play session id.
         * @param {string} [segmentContainer] The segment container.
         * @param {number} [segmentLength] The segment lenght.
         * @param {number} [minSegments] The minimum number of segments.
         * @param {string} [mediaSourceId] The media version id, if playing an alternate version.
         * @param {string} [deviceId] The device id of the client requesting. Used to stop encoding processes when needed.
         * @param {string} [audioCodec] Optional. Specify a audio codec to encode to, e.g. mp3. If omitted the server will auto-select using the url\&#39;s extension. Options: aac, mp3, vorbis, wma.
         * @param {boolean} [enableAutoStreamCopy] Whether or not to allow automatic stream copy if requested values match the original source. Defaults to true.
         * @param {boolean} [allowVideoStreamCopy] Whether or not to allow copying of the video stream url.
         * @param {boolean} [allowAudioStreamCopy] Whether or not to allow copying of the audio stream url.
         * @param {boolean} [breakOnNonKeyFrames] Optional. Whether to break on non key frames.
         * @param {number} [audioSampleRate] Optional. Specify a specific audio sample rate, e.g. 44100.
         * @param {number} [maxAudioBitDepth] Optional. The maximum audio bit depth.
         * @param {number} [audioBitRate] Optional. Specify an audio bitrate to encode to, e.g. 128000. If omitted this will be left to encoder defaults.
         * @param {number} [audioChannels] Optional. Specify a specific number of audio channels to encode to, e.g. 2.
         * @param {number} [maxAudioChannels] Optional. Specify a maximum number of audio channels to encode to, e.g. 2.
         * @param {string} [profile] Optional. Specify a specific an encoder profile (varies by encoder), e.g. main, baseline, high.
         * @param {string} [level] Optional. Specify a level for the encoder profile (varies by encoder), e.g. 3, 3.1.
         * @param {number} [framerate] Optional. A specific video framerate to encode to, e.g. 23.976. Generally this should be omitted unless the device has specific requirements.
         * @param {number} [maxFramerate] Optional. A specific maximum video framerate to encode to, e.g. 23.976. Generally this should be omitted unless the device has specific requirements.
         * @param {boolean} [copyTimestamps] Whether or not to copy timestamps when transcoding with an offset. Defaults to false.
         * @param {number} [startTimeTicks] Optional. Specify a starting offset, in ticks. 1 tick &#x3D; 10000 ms.
         * @param {number} [width] Optional. The fixed horizontal resolution of the encoded video.
         * @param {number} [height] Optional. The fixed vertical resolution of the encoded video.
         * @param {number} [videoBitRate] Optional. Specify a video bitrate to encode to, e.g. 500000. If omitted this will be left to encoder defaults.
         * @param {number} [subtitleStreamIndex] Optional. The index of the subtitle stream to use. If omitted no subtitles will be used.
         * @param {SubtitleDeliveryMethod} [subtitleMethod] Optional. Specify the subtitle delivery method.
         * @param {number} [maxRefFrames] Optional.
         * @param {number} [maxVideoBitDepth] Optional. The maximum video bit depth.
         * @param {boolean} [requireAvc] Optional. Whether to require avc.
         * @param {boolean} [deInterlace] Optional. Whether to deinterlace the video.
         * @param {boolean} [requireNonAnamorphic] Optional. Whether to require a non anamporphic stream.
         * @param {number} [transcodingMaxAudioChannels] Optional. The maximum number of audio channels to transcode.
         * @param {number} [cpuCoreLimit] Optional. The limit of how many cpu cores to use.
         * @param {string} [liveStreamId] The live stream id.
         * @param {boolean} [enableMpegtsM2TsMode] Optional. Whether to enable the MpegtsM2Ts mode.
         * @param {string} [videoCodec] Optional. Specify a video codec to encode to, e.g. h264. If omitted the server will auto-select using the url\&#39;s extension. Options: h265, h264, mpeg4, theora, vpx, wmv.
         * @param {string} [subtitleCodec] Optional. Specify a subtitle codec to encode to.
         * @param {string} [transcodingReasons] Optional. The transcoding reason.
         * @param {number} [audioStreamIndex] Optional. The index of the audio stream to use. If omitted the first audio stream will be used.
         * @param {number} [videoStreamIndex] Optional. The index of the video stream to use. If omitted the first video stream will be used.
         * @param {EncodingContext} [context] Optional. The MediaBrowser.Model.Dlna.EncodingContext.
         * @param {{ [key: string]: string; }} [streamOptions] Optional. The streaming options.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVideoStream(itemId: string, container: string, _static?: boolean, params?: string, tag?: string, deviceProfileId?: string, playSessionId?: string, segmentContainer?: string, segmentLength?: number, minSegments?: number, mediaSourceId?: string, deviceId?: string, audioCodec?: string, enableAutoStreamCopy?: boolean, allowVideoStreamCopy?: boolean, allowAudioStreamCopy?: boolean, breakOnNonKeyFrames?: boolean, audioSampleRate?: number, maxAudioBitDepth?: number, audioBitRate?: number, audioChannels?: number, maxAudioChannels?: number, profile?: string, level?: string, framerate?: number, maxFramerate?: number, copyTimestamps?: boolean, startTimeTicks?: number, width?: number, height?: number, videoBitRate?: number, subtitleStreamIndex?: number, subtitleMethod?: SubtitleDeliveryMethod, maxRefFrames?: number, maxVideoBitDepth?: number, requireAvc?: boolean, deInterlace?: boolean, requireNonAnamorphic?: boolean, transcodingMaxAudioChannels?: number, cpuCoreLimit?: number, liveStreamId?: string, enableMpegtsM2TsMode?: boolean, videoCodec?: string, subtitleCodec?: string, transcodingReasons?: string, audioStreamIndex?: number, videoStreamIndex?: number, context?: EncodingContext, streamOptions?: { [key: string]: string; }, options?: any): AxiosPromise<any> {
            return VideosApiFp(configuration).getVideoStream(itemId, container, _static, params, tag, deviceProfileId, playSessionId, segmentContainer, segmentLength, minSegments, mediaSourceId, deviceId, audioCodec, enableAutoStreamCopy, allowVideoStreamCopy, allowAudioStreamCopy, breakOnNonKeyFrames, audioSampleRate, maxAudioBitDepth, audioBitRate, audioChannels, maxAudioChannels, profile, level, framerate, maxFramerate, copyTimestamps, startTimeTicks, width, height, videoBitRate, subtitleStreamIndex, subtitleMethod, maxRefFrames, maxVideoBitDepth, requireAvc, deInterlace, requireNonAnamorphic, transcodingMaxAudioChannels, cpuCoreLimit, liveStreamId, enableMpegtsM2TsMode, videoCodec, subtitleCodec, transcodingReasons, audioStreamIndex, videoStreamIndex, context, streamOptions, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets a video stream.
         * @param {string} itemId The item id.
         * @param {string} container The video container. Possible values are: ts, webm, asf, wmv, ogv, mp4, m4v, mkv, mpeg, mpg, avi, 3gp, wmv, wtv, m2ts, mov, iso, flv.
         * @param {string} stream 
         * @param {boolean} [_static] Optional. If true, the original file will be streamed statically without any encoding. Use either no url extension or the original file extension. true/false.
         * @param {string} [params] The streaming parameters.
         * @param {string} [tag] The tag.
         * @param {string} [deviceProfileId] Optional. The dlna device profile id to utilize.
         * @param {string} [playSessionId] The play session id.
         * @param {string} [segmentContainer] The segment container.
         * @param {number} [segmentLength] The segment lenght.
         * @param {number} [minSegments] The minimum number of segments.
         * @param {string} [mediaSourceId] The media version id, if playing an alternate version.
         * @param {string} [deviceId] The device id of the client requesting. Used to stop encoding processes when needed.
         * @param {string} [audioCodec] Optional. Specify a audio codec to encode to, e.g. mp3. If omitted the server will auto-select using the url\&#39;s extension. Options: aac, mp3, vorbis, wma.
         * @param {boolean} [enableAutoStreamCopy] Whether or not to allow automatic stream copy if requested values match the original source. Defaults to true.
         * @param {boolean} [allowVideoStreamCopy] Whether or not to allow copying of the video stream url.
         * @param {boolean} [allowAudioStreamCopy] Whether or not to allow copying of the audio stream url.
         * @param {boolean} [breakOnNonKeyFrames] Optional. Whether to break on non key frames.
         * @param {number} [audioSampleRate] Optional. Specify a specific audio sample rate, e.g. 44100.
         * @param {number} [maxAudioBitDepth] Optional. The maximum audio bit depth.
         * @param {number} [audioBitRate] Optional. Specify an audio bitrate to encode to, e.g. 128000. If omitted this will be left to encoder defaults.
         * @param {number} [audioChannels] Optional. Specify a specific number of audio channels to encode to, e.g. 2.
         * @param {number} [maxAudioChannels] Optional. Specify a maximum number of audio channels to encode to, e.g. 2.
         * @param {string} [profile] Optional. Specify a specific an encoder profile (varies by encoder), e.g. main, baseline, high.
         * @param {string} [level] Optional. Specify a level for the encoder profile (varies by encoder), e.g. 3, 3.1.
         * @param {number} [framerate] Optional. A specific video framerate to encode to, e.g. 23.976. Generally this should be omitted unless the device has specific requirements.
         * @param {number} [maxFramerate] Optional. A specific maximum video framerate to encode to, e.g. 23.976. Generally this should be omitted unless the device has specific requirements.
         * @param {boolean} [copyTimestamps] Whether or not to copy timestamps when transcoding with an offset. Defaults to false.
         * @param {number} [startTimeTicks] Optional. Specify a starting offset, in ticks. 1 tick &#x3D; 10000 ms.
         * @param {number} [width] Optional. The fixed horizontal resolution of the encoded video.
         * @param {number} [height] Optional. The fixed vertical resolution of the encoded video.
         * @param {number} [videoBitRate] Optional. Specify a video bitrate to encode to, e.g. 500000. If omitted this will be left to encoder defaults.
         * @param {number} [subtitleStreamIndex] Optional. The index of the subtitle stream to use. If omitted no subtitles will be used.
         * @param {SubtitleDeliveryMethod} [subtitleMethod] Optional. Specify the subtitle delivery method.
         * @param {number} [maxRefFrames] Optional.
         * @param {number} [maxVideoBitDepth] Optional. The maximum video bit depth.
         * @param {boolean} [requireAvc] Optional. Whether to require avc.
         * @param {boolean} [deInterlace] Optional. Whether to deinterlace the video.
         * @param {boolean} [requireNonAnamorphic] Optional. Whether to require a non anamporphic stream.
         * @param {number} [transcodingMaxAudioChannels] Optional. The maximum number of audio channels to transcode.
         * @param {number} [cpuCoreLimit] Optional. The limit of how many cpu cores to use.
         * @param {string} [liveStreamId] The live stream id.
         * @param {boolean} [enableMpegtsM2TsMode] Optional. Whether to enable the MpegtsM2Ts mode.
         * @param {string} [videoCodec] Optional. Specify a video codec to encode to, e.g. h264. If omitted the server will auto-select using the url\&#39;s extension. Options: h265, h264, mpeg4, theora, vpx, wmv.
         * @param {string} [subtitleCodec] Optional. Specify a subtitle codec to encode to.
         * @param {string} [transcodingReasons] Optional. The transcoding reason.
         * @param {number} [audioStreamIndex] Optional. The index of the audio stream to use. If omitted the first audio stream will be used.
         * @param {number} [videoStreamIndex] Optional. The index of the video stream to use. If omitted the first video stream will be used.
         * @param {EncodingContext} [context] Optional. The MediaBrowser.Model.Dlna.EncodingContext.
         * @param {{ [key: string]: string; }} [streamOptions] Optional. The streaming options.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVideoStreamWithExt(itemId: string, container: string, stream: string, _static?: boolean, params?: string, tag?: string, deviceProfileId?: string, playSessionId?: string, segmentContainer?: string, segmentLength?: number, minSegments?: number, mediaSourceId?: string, deviceId?: string, audioCodec?: string, enableAutoStreamCopy?: boolean, allowVideoStreamCopy?: boolean, allowAudioStreamCopy?: boolean, breakOnNonKeyFrames?: boolean, audioSampleRate?: number, maxAudioBitDepth?: number, audioBitRate?: number, audioChannels?: number, maxAudioChannels?: number, profile?: string, level?: string, framerate?: number, maxFramerate?: number, copyTimestamps?: boolean, startTimeTicks?: number, width?: number, height?: number, videoBitRate?: number, subtitleStreamIndex?: number, subtitleMethod?: SubtitleDeliveryMethod, maxRefFrames?: number, maxVideoBitDepth?: number, requireAvc?: boolean, deInterlace?: boolean, requireNonAnamorphic?: boolean, transcodingMaxAudioChannels?: number, cpuCoreLimit?: number, liveStreamId?: string, enableMpegtsM2TsMode?: boolean, videoCodec?: string, subtitleCodec?: string, transcodingReasons?: string, audioStreamIndex?: number, videoStreamIndex?: number, context?: EncodingContext, streamOptions?: { [key: string]: string; }, options?: any): AxiosPromise<any> {
            return VideosApiFp(configuration).getVideoStreamWithExt(itemId, container, stream, _static, params, tag, deviceProfileId, playSessionId, segmentContainer, segmentLength, minSegments, mediaSourceId, deviceId, audioCodec, enableAutoStreamCopy, allowVideoStreamCopy, allowAudioStreamCopy, breakOnNonKeyFrames, audioSampleRate, maxAudioBitDepth, audioBitRate, audioChannels, maxAudioChannels, profile, level, framerate, maxFramerate, copyTimestamps, startTimeTicks, width, height, videoBitRate, subtitleStreamIndex, subtitleMethod, maxRefFrames, maxVideoBitDepth, requireAvc, deInterlace, requireNonAnamorphic, transcodingMaxAudioChannels, cpuCoreLimit, liveStreamId, enableMpegtsM2TsMode, videoCodec, subtitleCodec, transcodingReasons, audioStreamIndex, videoStreamIndex, context, streamOptions, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets a video stream.
         * @param {string} itemId The item id.
         * @param {string} container The video container. Possible values are: ts, webm, asf, wmv, ogv, mp4, m4v, mkv, mpeg, mpg, avi, 3gp, wmv, wtv, m2ts, mov, iso, flv.
         * @param {boolean} [_static] Optional. If true, the original file will be streamed statically without any encoding. Use either no url extension or the original file extension. true/false.
         * @param {string} [params] The streaming parameters.
         * @param {string} [tag] The tag.
         * @param {string} [deviceProfileId] Optional. The dlna device profile id to utilize.
         * @param {string} [playSessionId] The play session id.
         * @param {string} [segmentContainer] The segment container.
         * @param {number} [segmentLength] The segment lenght.
         * @param {number} [minSegments] The minimum number of segments.
         * @param {string} [mediaSourceId] The media version id, if playing an alternate version.
         * @param {string} [deviceId] The device id of the client requesting. Used to stop encoding processes when needed.
         * @param {string} [audioCodec] Optional. Specify a audio codec to encode to, e.g. mp3. If omitted the server will auto-select using the url\&#39;s extension. Options: aac, mp3, vorbis, wma.
         * @param {boolean} [enableAutoStreamCopy] Whether or not to allow automatic stream copy if requested values match the original source. Defaults to true.
         * @param {boolean} [allowVideoStreamCopy] Whether or not to allow copying of the video stream url.
         * @param {boolean} [allowAudioStreamCopy] Whether or not to allow copying of the audio stream url.
         * @param {boolean} [breakOnNonKeyFrames] Optional. Whether to break on non key frames.
         * @param {number} [audioSampleRate] Optional. Specify a specific audio sample rate, e.g. 44100.
         * @param {number} [maxAudioBitDepth] Optional. The maximum audio bit depth.
         * @param {number} [audioBitRate] Optional. Specify an audio bitrate to encode to, e.g. 128000. If omitted this will be left to encoder defaults.
         * @param {number} [audioChannels] Optional. Specify a specific number of audio channels to encode to, e.g. 2.
         * @param {number} [maxAudioChannels] Optional. Specify a maximum number of audio channels to encode to, e.g. 2.
         * @param {string} [profile] Optional. Specify a specific an encoder profile (varies by encoder), e.g. main, baseline, high.
         * @param {string} [level] Optional. Specify a level for the encoder profile (varies by encoder), e.g. 3, 3.1.
         * @param {number} [framerate] Optional. A specific video framerate to encode to, e.g. 23.976. Generally this should be omitted unless the device has specific requirements.
         * @param {number} [maxFramerate] Optional. A specific maximum video framerate to encode to, e.g. 23.976. Generally this should be omitted unless the device has specific requirements.
         * @param {boolean} [copyTimestamps] Whether or not to copy timestamps when transcoding with an offset. Defaults to false.
         * @param {number} [startTimeTicks] Optional. Specify a starting offset, in ticks. 1 tick &#x3D; 10000 ms.
         * @param {number} [width] Optional. The fixed horizontal resolution of the encoded video.
         * @param {number} [height] Optional. The fixed vertical resolution of the encoded video.
         * @param {number} [videoBitRate] Optional. Specify a video bitrate to encode to, e.g. 500000. If omitted this will be left to encoder defaults.
         * @param {number} [subtitleStreamIndex] Optional. The index of the subtitle stream to use. If omitted no subtitles will be used.
         * @param {SubtitleDeliveryMethod} [subtitleMethod] Optional. Specify the subtitle delivery method.
         * @param {number} [maxRefFrames] Optional.
         * @param {number} [maxVideoBitDepth] Optional. The maximum video bit depth.
         * @param {boolean} [requireAvc] Optional. Whether to require avc.
         * @param {boolean} [deInterlace] Optional. Whether to deinterlace the video.
         * @param {boolean} [requireNonAnamorphic] Optional. Whether to require a non anamporphic stream.
         * @param {number} [transcodingMaxAudioChannels] Optional. The maximum number of audio channels to transcode.
         * @param {number} [cpuCoreLimit] Optional. The limit of how many cpu cores to use.
         * @param {string} [liveStreamId] The live stream id.
         * @param {boolean} [enableMpegtsM2TsMode] Optional. Whether to enable the MpegtsM2Ts mode.
         * @param {string} [videoCodec] Optional. Specify a video codec to encode to, e.g. h264. If omitted the server will auto-select using the url\&#39;s extension. Options: h265, h264, mpeg4, theora, vpx, wmv.
         * @param {string} [subtitleCodec] Optional. Specify a subtitle codec to encode to.
         * @param {string} [transcodingReasons] Optional. The transcoding reason.
         * @param {number} [audioStreamIndex] Optional. The index of the audio stream to use. If omitted the first audio stream will be used.
         * @param {number} [videoStreamIndex] Optional. The index of the video stream to use. If omitted the first video stream will be used.
         * @param {EncodingContext} [context] Optional. The MediaBrowser.Model.Dlna.EncodingContext.
         * @param {{ [key: string]: string; }} [streamOptions] Optional. The streaming options.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        headVideoStream(itemId: string, container: string, _static?: boolean, params?: string, tag?: string, deviceProfileId?: string, playSessionId?: string, segmentContainer?: string, segmentLength?: number, minSegments?: number, mediaSourceId?: string, deviceId?: string, audioCodec?: string, enableAutoStreamCopy?: boolean, allowVideoStreamCopy?: boolean, allowAudioStreamCopy?: boolean, breakOnNonKeyFrames?: boolean, audioSampleRate?: number, maxAudioBitDepth?: number, audioBitRate?: number, audioChannels?: number, maxAudioChannels?: number, profile?: string, level?: string, framerate?: number, maxFramerate?: number, copyTimestamps?: boolean, startTimeTicks?: number, width?: number, height?: number, videoBitRate?: number, subtitleStreamIndex?: number, subtitleMethod?: SubtitleDeliveryMethod, maxRefFrames?: number, maxVideoBitDepth?: number, requireAvc?: boolean, deInterlace?: boolean, requireNonAnamorphic?: boolean, transcodingMaxAudioChannels?: number, cpuCoreLimit?: number, liveStreamId?: string, enableMpegtsM2TsMode?: boolean, videoCodec?: string, subtitleCodec?: string, transcodingReasons?: string, audioStreamIndex?: number, videoStreamIndex?: number, context?: EncodingContext, streamOptions?: { [key: string]: string; }, options?: any): AxiosPromise<any> {
            return VideosApiFp(configuration).headVideoStream(itemId, container, _static, params, tag, deviceProfileId, playSessionId, segmentContainer, segmentLength, minSegments, mediaSourceId, deviceId, audioCodec, enableAutoStreamCopy, allowVideoStreamCopy, allowAudioStreamCopy, breakOnNonKeyFrames, audioSampleRate, maxAudioBitDepth, audioBitRate, audioChannels, maxAudioChannels, profile, level, framerate, maxFramerate, copyTimestamps, startTimeTicks, width, height, videoBitRate, subtitleStreamIndex, subtitleMethod, maxRefFrames, maxVideoBitDepth, requireAvc, deInterlace, requireNonAnamorphic, transcodingMaxAudioChannels, cpuCoreLimit, liveStreamId, enableMpegtsM2TsMode, videoCodec, subtitleCodec, transcodingReasons, audioStreamIndex, videoStreamIndex, context, streamOptions, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets a video stream.
         * @param {string} itemId The item id.
         * @param {string} container The video container. Possible values are: ts, webm, asf, wmv, ogv, mp4, m4v, mkv, mpeg, mpg, avi, 3gp, wmv, wtv, m2ts, mov, iso, flv.
         * @param {string} stream 
         * @param {boolean} [_static] Optional. If true, the original file will be streamed statically without any encoding. Use either no url extension or the original file extension. true/false.
         * @param {string} [params] The streaming parameters.
         * @param {string} [tag] The tag.
         * @param {string} [deviceProfileId] Optional. The dlna device profile id to utilize.
         * @param {string} [playSessionId] The play session id.
         * @param {string} [segmentContainer] The segment container.
         * @param {number} [segmentLength] The segment lenght.
         * @param {number} [minSegments] The minimum number of segments.
         * @param {string} [mediaSourceId] The media version id, if playing an alternate version.
         * @param {string} [deviceId] The device id of the client requesting. Used to stop encoding processes when needed.
         * @param {string} [audioCodec] Optional. Specify a audio codec to encode to, e.g. mp3. If omitted the server will auto-select using the url\&#39;s extension. Options: aac, mp3, vorbis, wma.
         * @param {boolean} [enableAutoStreamCopy] Whether or not to allow automatic stream copy if requested values match the original source. Defaults to true.
         * @param {boolean} [allowVideoStreamCopy] Whether or not to allow copying of the video stream url.
         * @param {boolean} [allowAudioStreamCopy] Whether or not to allow copying of the audio stream url.
         * @param {boolean} [breakOnNonKeyFrames] Optional. Whether to break on non key frames.
         * @param {number} [audioSampleRate] Optional. Specify a specific audio sample rate, e.g. 44100.
         * @param {number} [maxAudioBitDepth] Optional. The maximum audio bit depth.
         * @param {number} [audioBitRate] Optional. Specify an audio bitrate to encode to, e.g. 128000. If omitted this will be left to encoder defaults.
         * @param {number} [audioChannels] Optional. Specify a specific number of audio channels to encode to, e.g. 2.
         * @param {number} [maxAudioChannels] Optional. Specify a maximum number of audio channels to encode to, e.g. 2.
         * @param {string} [profile] Optional. Specify a specific an encoder profile (varies by encoder), e.g. main, baseline, high.
         * @param {string} [level] Optional. Specify a level for the encoder profile (varies by encoder), e.g. 3, 3.1.
         * @param {number} [framerate] Optional. A specific video framerate to encode to, e.g. 23.976. Generally this should be omitted unless the device has specific requirements.
         * @param {number} [maxFramerate] Optional. A specific maximum video framerate to encode to, e.g. 23.976. Generally this should be omitted unless the device has specific requirements.
         * @param {boolean} [copyTimestamps] Whether or not to copy timestamps when transcoding with an offset. Defaults to false.
         * @param {number} [startTimeTicks] Optional. Specify a starting offset, in ticks. 1 tick &#x3D; 10000 ms.
         * @param {number} [width] Optional. The fixed horizontal resolution of the encoded video.
         * @param {number} [height] Optional. The fixed vertical resolution of the encoded video.
         * @param {number} [videoBitRate] Optional. Specify a video bitrate to encode to, e.g. 500000. If omitted this will be left to encoder defaults.
         * @param {number} [subtitleStreamIndex] Optional. The index of the subtitle stream to use. If omitted no subtitles will be used.
         * @param {SubtitleDeliveryMethod} [subtitleMethod] Optional. Specify the subtitle delivery method.
         * @param {number} [maxRefFrames] Optional.
         * @param {number} [maxVideoBitDepth] Optional. The maximum video bit depth.
         * @param {boolean} [requireAvc] Optional. Whether to require avc.
         * @param {boolean} [deInterlace] Optional. Whether to deinterlace the video.
         * @param {boolean} [requireNonAnamorphic] Optional. Whether to require a non anamporphic stream.
         * @param {number} [transcodingMaxAudioChannels] Optional. The maximum number of audio channels to transcode.
         * @param {number} [cpuCoreLimit] Optional. The limit of how many cpu cores to use.
         * @param {string} [liveStreamId] The live stream id.
         * @param {boolean} [enableMpegtsM2TsMode] Optional. Whether to enable the MpegtsM2Ts mode.
         * @param {string} [videoCodec] Optional. Specify a video codec to encode to, e.g. h264. If omitted the server will auto-select using the url\&#39;s extension. Options: h265, h264, mpeg4, theora, vpx, wmv.
         * @param {string} [subtitleCodec] Optional. Specify a subtitle codec to encode to.
         * @param {string} [transcodingReasons] Optional. The transcoding reason.
         * @param {number} [audioStreamIndex] Optional. The index of the audio stream to use. If omitted the first audio stream will be used.
         * @param {number} [videoStreamIndex] Optional. The index of the video stream to use. If omitted the first video stream will be used.
         * @param {EncodingContext} [context] Optional. The MediaBrowser.Model.Dlna.EncodingContext.
         * @param {{ [key: string]: string; }} [streamOptions] Optional. The streaming options.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        headVideoStreamWithExt(itemId: string, container: string, stream: string, _static?: boolean, params?: string, tag?: string, deviceProfileId?: string, playSessionId?: string, segmentContainer?: string, segmentLength?: number, minSegments?: number, mediaSourceId?: string, deviceId?: string, audioCodec?: string, enableAutoStreamCopy?: boolean, allowVideoStreamCopy?: boolean, allowAudioStreamCopy?: boolean, breakOnNonKeyFrames?: boolean, audioSampleRate?: number, maxAudioBitDepth?: number, audioBitRate?: number, audioChannels?: number, maxAudioChannels?: number, profile?: string, level?: string, framerate?: number, maxFramerate?: number, copyTimestamps?: boolean, startTimeTicks?: number, width?: number, height?: number, videoBitRate?: number, subtitleStreamIndex?: number, subtitleMethod?: SubtitleDeliveryMethod, maxRefFrames?: number, maxVideoBitDepth?: number, requireAvc?: boolean, deInterlace?: boolean, requireNonAnamorphic?: boolean, transcodingMaxAudioChannels?: number, cpuCoreLimit?: number, liveStreamId?: string, enableMpegtsM2TsMode?: boolean, videoCodec?: string, subtitleCodec?: string, transcodingReasons?: string, audioStreamIndex?: number, videoStreamIndex?: number, context?: EncodingContext, streamOptions?: { [key: string]: string; }, options?: any): AxiosPromise<any> {
            return VideosApiFp(configuration).headVideoStreamWithExt(itemId, container, stream, _static, params, tag, deviceProfileId, playSessionId, segmentContainer, segmentLength, minSegments, mediaSourceId, deviceId, audioCodec, enableAutoStreamCopy, allowVideoStreamCopy, allowAudioStreamCopy, breakOnNonKeyFrames, audioSampleRate, maxAudioBitDepth, audioBitRate, audioChannels, maxAudioChannels, profile, level, framerate, maxFramerate, copyTimestamps, startTimeTicks, width, height, videoBitRate, subtitleStreamIndex, subtitleMethod, maxRefFrames, maxVideoBitDepth, requireAvc, deInterlace, requireNonAnamorphic, transcodingMaxAudioChannels, cpuCoreLimit, liveStreamId, enableMpegtsM2TsMode, videoCodec, subtitleCodec, transcodingReasons, audioStreamIndex, videoStreamIndex, context, streamOptions, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Merges videos into a single record.
         * @param {string} itemIds Item id list. This allows multiple, comma delimited.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mergeVersions(itemIds: string, options?: any): AxiosPromise<void> {
            return VideosApiFp(configuration).mergeVersions(itemIds, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for deleteAlternateSources operation in VideosApi.
 * @export
 * @interface VideosApiDeleteAlternateSourcesRequest
 */
export interface VideosApiDeleteAlternateSourcesRequest {
    /**
     * The item id.
     * @type {string}
     * @memberof VideosApiDeleteAlternateSources
     */
    readonly itemId: string
}

/**
 * Request parameters for getAdditionalPart operation in VideosApi.
 * @export
 * @interface VideosApiGetAdditionalPartRequest
 */
export interface VideosApiGetAdditionalPartRequest {
    /**
     * The item id.
     * @type {string}
     * @memberof VideosApiGetAdditionalPart
     */
    readonly itemId: string

    /**
     * Optional. Filter by user id, and attach user data.
     * @type {string}
     * @memberof VideosApiGetAdditionalPart
     */
    readonly userId?: string
}

/**
 * Request parameters for getVideoStream operation in VideosApi.
 * @export
 * @interface VideosApiGetVideoStreamRequest
 */
export interface VideosApiGetVideoStreamRequest {
    /**
     * The item id.
     * @type {string}
     * @memberof VideosApiGetVideoStream
     */
    readonly itemId: string

    /**
     * The video container. Possible values are: ts, webm, asf, wmv, ogv, mp4, m4v, mkv, mpeg, mpg, avi, 3gp, wmv, wtv, m2ts, mov, iso, flv.
     * @type {string}
     * @memberof VideosApiGetVideoStream
     */
    readonly container: string

    /**
     * Optional. If true, the original file will be streamed statically without any encoding. Use either no url extension or the original file extension. true/false.
     * @type {boolean}
     * @memberof VideosApiGetVideoStream
     */
    readonly _static?: boolean

    /**
     * The streaming parameters.
     * @type {string}
     * @memberof VideosApiGetVideoStream
     */
    readonly params?: string

    /**
     * The tag.
     * @type {string}
     * @memberof VideosApiGetVideoStream
     */
    readonly tag?: string

    /**
     * Optional. The dlna device profile id to utilize.
     * @type {string}
     * @memberof VideosApiGetVideoStream
     */
    readonly deviceProfileId?: string

    /**
     * The play session id.
     * @type {string}
     * @memberof VideosApiGetVideoStream
     */
    readonly playSessionId?: string

    /**
     * The segment container.
     * @type {string}
     * @memberof VideosApiGetVideoStream
     */
    readonly segmentContainer?: string

    /**
     * The segment lenght.
     * @type {number}
     * @memberof VideosApiGetVideoStream
     */
    readonly segmentLength?: number

    /**
     * The minimum number of segments.
     * @type {number}
     * @memberof VideosApiGetVideoStream
     */
    readonly minSegments?: number

    /**
     * The media version id, if playing an alternate version.
     * @type {string}
     * @memberof VideosApiGetVideoStream
     */
    readonly mediaSourceId?: string

    /**
     * The device id of the client requesting. Used to stop encoding processes when needed.
     * @type {string}
     * @memberof VideosApiGetVideoStream
     */
    readonly deviceId?: string

    /**
     * Optional. Specify a audio codec to encode to, e.g. mp3. If omitted the server will auto-select using the url\&#39;s extension. Options: aac, mp3, vorbis, wma.
     * @type {string}
     * @memberof VideosApiGetVideoStream
     */
    readonly audioCodec?: string

    /**
     * Whether or not to allow automatic stream copy if requested values match the original source. Defaults to true.
     * @type {boolean}
     * @memberof VideosApiGetVideoStream
     */
    readonly enableAutoStreamCopy?: boolean

    /**
     * Whether or not to allow copying of the video stream url.
     * @type {boolean}
     * @memberof VideosApiGetVideoStream
     */
    readonly allowVideoStreamCopy?: boolean

    /**
     * Whether or not to allow copying of the audio stream url.
     * @type {boolean}
     * @memberof VideosApiGetVideoStream
     */
    readonly allowAudioStreamCopy?: boolean

    /**
     * Optional. Whether to break on non key frames.
     * @type {boolean}
     * @memberof VideosApiGetVideoStream
     */
    readonly breakOnNonKeyFrames?: boolean

    /**
     * Optional. Specify a specific audio sample rate, e.g. 44100.
     * @type {number}
     * @memberof VideosApiGetVideoStream
     */
    readonly audioSampleRate?: number

    /**
     * Optional. The maximum audio bit depth.
     * @type {number}
     * @memberof VideosApiGetVideoStream
     */
    readonly maxAudioBitDepth?: number

    /**
     * Optional. Specify an audio bitrate to encode to, e.g. 128000. If omitted this will be left to encoder defaults.
     * @type {number}
     * @memberof VideosApiGetVideoStream
     */
    readonly audioBitRate?: number

    /**
     * Optional. Specify a specific number of audio channels to encode to, e.g. 2.
     * @type {number}
     * @memberof VideosApiGetVideoStream
     */
    readonly audioChannels?: number

    /**
     * Optional. Specify a maximum number of audio channels to encode to, e.g. 2.
     * @type {number}
     * @memberof VideosApiGetVideoStream
     */
    readonly maxAudioChannels?: number

    /**
     * Optional. Specify a specific an encoder profile (varies by encoder), e.g. main, baseline, high.
     * @type {string}
     * @memberof VideosApiGetVideoStream
     */
    readonly profile?: string

    /**
     * Optional. Specify a level for the encoder profile (varies by encoder), e.g. 3, 3.1.
     * @type {string}
     * @memberof VideosApiGetVideoStream
     */
    readonly level?: string

    /**
     * Optional. A specific video framerate to encode to, e.g. 23.976. Generally this should be omitted unless the device has specific requirements.
     * @type {number}
     * @memberof VideosApiGetVideoStream
     */
    readonly framerate?: number

    /**
     * Optional. A specific maximum video framerate to encode to, e.g. 23.976. Generally this should be omitted unless the device has specific requirements.
     * @type {number}
     * @memberof VideosApiGetVideoStream
     */
    readonly maxFramerate?: number

    /**
     * Whether or not to copy timestamps when transcoding with an offset. Defaults to false.
     * @type {boolean}
     * @memberof VideosApiGetVideoStream
     */
    readonly copyTimestamps?: boolean

    /**
     * Optional. Specify a starting offset, in ticks. 1 tick &#x3D; 10000 ms.
     * @type {number}
     * @memberof VideosApiGetVideoStream
     */
    readonly startTimeTicks?: number

    /**
     * Optional. The fixed horizontal resolution of the encoded video.
     * @type {number}
     * @memberof VideosApiGetVideoStream
     */
    readonly width?: number

    /**
     * Optional. The fixed vertical resolution of the encoded video.
     * @type {number}
     * @memberof VideosApiGetVideoStream
     */
    readonly height?: number

    /**
     * Optional. Specify a video bitrate to encode to, e.g. 500000. If omitted this will be left to encoder defaults.
     * @type {number}
     * @memberof VideosApiGetVideoStream
     */
    readonly videoBitRate?: number

    /**
     * Optional. The index of the subtitle stream to use. If omitted no subtitles will be used.
     * @type {number}
     * @memberof VideosApiGetVideoStream
     */
    readonly subtitleStreamIndex?: number

    /**
     * Optional. Specify the subtitle delivery method.
     * @type {SubtitleDeliveryMethod}
     * @memberof VideosApiGetVideoStream
     */
    readonly subtitleMethod?: SubtitleDeliveryMethod

    /**
     * Optional.
     * @type {number}
     * @memberof VideosApiGetVideoStream
     */
    readonly maxRefFrames?: number

    /**
     * Optional. The maximum video bit depth.
     * @type {number}
     * @memberof VideosApiGetVideoStream
     */
    readonly maxVideoBitDepth?: number

    /**
     * Optional. Whether to require avc.
     * @type {boolean}
     * @memberof VideosApiGetVideoStream
     */
    readonly requireAvc?: boolean

    /**
     * Optional. Whether to deinterlace the video.
     * @type {boolean}
     * @memberof VideosApiGetVideoStream
     */
    readonly deInterlace?: boolean

    /**
     * Optional. Whether to require a non anamporphic stream.
     * @type {boolean}
     * @memberof VideosApiGetVideoStream
     */
    readonly requireNonAnamorphic?: boolean

    /**
     * Optional. The maximum number of audio channels to transcode.
     * @type {number}
     * @memberof VideosApiGetVideoStream
     */
    readonly transcodingMaxAudioChannels?: number

    /**
     * Optional. The limit of how many cpu cores to use.
     * @type {number}
     * @memberof VideosApiGetVideoStream
     */
    readonly cpuCoreLimit?: number

    /**
     * The live stream id.
     * @type {string}
     * @memberof VideosApiGetVideoStream
     */
    readonly liveStreamId?: string

    /**
     * Optional. Whether to enable the MpegtsM2Ts mode.
     * @type {boolean}
     * @memberof VideosApiGetVideoStream
     */
    readonly enableMpegtsM2TsMode?: boolean

    /**
     * Optional. Specify a video codec to encode to, e.g. h264. If omitted the server will auto-select using the url\&#39;s extension. Options: h265, h264, mpeg4, theora, vpx, wmv.
     * @type {string}
     * @memberof VideosApiGetVideoStream
     */
    readonly videoCodec?: string

    /**
     * Optional. Specify a subtitle codec to encode to.
     * @type {string}
     * @memberof VideosApiGetVideoStream
     */
    readonly subtitleCodec?: string

    /**
     * Optional. The transcoding reason.
     * @type {string}
     * @memberof VideosApiGetVideoStream
     */
    readonly transcodingReasons?: string

    /**
     * Optional. The index of the audio stream to use. If omitted the first audio stream will be used.
     * @type {number}
     * @memberof VideosApiGetVideoStream
     */
    readonly audioStreamIndex?: number

    /**
     * Optional. The index of the video stream to use. If omitted the first video stream will be used.
     * @type {number}
     * @memberof VideosApiGetVideoStream
     */
    readonly videoStreamIndex?: number

    /**
     * Optional. The MediaBrowser.Model.Dlna.EncodingContext.
     * @type {EncodingContext}
     * @memberof VideosApiGetVideoStream
     */
    readonly context?: EncodingContext

    /**
     * Optional. The streaming options.
     * @type {{ [key: string]: string; }}
     * @memberof VideosApiGetVideoStream
     */
    readonly streamOptions?: { [key: string]: string; }
}

/**
 * Request parameters for getVideoStreamWithExt operation in VideosApi.
 * @export
 * @interface VideosApiGetVideoStreamWithExtRequest
 */
export interface VideosApiGetVideoStreamWithExtRequest {
    /**
     * The item id.
     * @type {string}
     * @memberof VideosApiGetVideoStreamWithExt
     */
    readonly itemId: string

    /**
     * The video container. Possible values are: ts, webm, asf, wmv, ogv, mp4, m4v, mkv, mpeg, mpg, avi, 3gp, wmv, wtv, m2ts, mov, iso, flv.
     * @type {string}
     * @memberof VideosApiGetVideoStreamWithExt
     */
    readonly container: string

    /**
     * 
     * @type {string}
     * @memberof VideosApiGetVideoStreamWithExt
     */
    readonly stream: string

    /**
     * Optional. If true, the original file will be streamed statically without any encoding. Use either no url extension or the original file extension. true/false.
     * @type {boolean}
     * @memberof VideosApiGetVideoStreamWithExt
     */
    readonly _static?: boolean

    /**
     * The streaming parameters.
     * @type {string}
     * @memberof VideosApiGetVideoStreamWithExt
     */
    readonly params?: string

    /**
     * The tag.
     * @type {string}
     * @memberof VideosApiGetVideoStreamWithExt
     */
    readonly tag?: string

    /**
     * Optional. The dlna device profile id to utilize.
     * @type {string}
     * @memberof VideosApiGetVideoStreamWithExt
     */
    readonly deviceProfileId?: string

    /**
     * The play session id.
     * @type {string}
     * @memberof VideosApiGetVideoStreamWithExt
     */
    readonly playSessionId?: string

    /**
     * The segment container.
     * @type {string}
     * @memberof VideosApiGetVideoStreamWithExt
     */
    readonly segmentContainer?: string

    /**
     * The segment lenght.
     * @type {number}
     * @memberof VideosApiGetVideoStreamWithExt
     */
    readonly segmentLength?: number

    /**
     * The minimum number of segments.
     * @type {number}
     * @memberof VideosApiGetVideoStreamWithExt
     */
    readonly minSegments?: number

    /**
     * The media version id, if playing an alternate version.
     * @type {string}
     * @memberof VideosApiGetVideoStreamWithExt
     */
    readonly mediaSourceId?: string

    /**
     * The device id of the client requesting. Used to stop encoding processes when needed.
     * @type {string}
     * @memberof VideosApiGetVideoStreamWithExt
     */
    readonly deviceId?: string

    /**
     * Optional. Specify a audio codec to encode to, e.g. mp3. If omitted the server will auto-select using the url\&#39;s extension. Options: aac, mp3, vorbis, wma.
     * @type {string}
     * @memberof VideosApiGetVideoStreamWithExt
     */
    readonly audioCodec?: string

    /**
     * Whether or not to allow automatic stream copy if requested values match the original source. Defaults to true.
     * @type {boolean}
     * @memberof VideosApiGetVideoStreamWithExt
     */
    readonly enableAutoStreamCopy?: boolean

    /**
     * Whether or not to allow copying of the video stream url.
     * @type {boolean}
     * @memberof VideosApiGetVideoStreamWithExt
     */
    readonly allowVideoStreamCopy?: boolean

    /**
     * Whether or not to allow copying of the audio stream url.
     * @type {boolean}
     * @memberof VideosApiGetVideoStreamWithExt
     */
    readonly allowAudioStreamCopy?: boolean

    /**
     * Optional. Whether to break on non key frames.
     * @type {boolean}
     * @memberof VideosApiGetVideoStreamWithExt
     */
    readonly breakOnNonKeyFrames?: boolean

    /**
     * Optional. Specify a specific audio sample rate, e.g. 44100.
     * @type {number}
     * @memberof VideosApiGetVideoStreamWithExt
     */
    readonly audioSampleRate?: number

    /**
     * Optional. The maximum audio bit depth.
     * @type {number}
     * @memberof VideosApiGetVideoStreamWithExt
     */
    readonly maxAudioBitDepth?: number

    /**
     * Optional. Specify an audio bitrate to encode to, e.g. 128000. If omitted this will be left to encoder defaults.
     * @type {number}
     * @memberof VideosApiGetVideoStreamWithExt
     */
    readonly audioBitRate?: number

    /**
     * Optional. Specify a specific number of audio channels to encode to, e.g. 2.
     * @type {number}
     * @memberof VideosApiGetVideoStreamWithExt
     */
    readonly audioChannels?: number

    /**
     * Optional. Specify a maximum number of audio channels to encode to, e.g. 2.
     * @type {number}
     * @memberof VideosApiGetVideoStreamWithExt
     */
    readonly maxAudioChannels?: number

    /**
     * Optional. Specify a specific an encoder profile (varies by encoder), e.g. main, baseline, high.
     * @type {string}
     * @memberof VideosApiGetVideoStreamWithExt
     */
    readonly profile?: string

    /**
     * Optional. Specify a level for the encoder profile (varies by encoder), e.g. 3, 3.1.
     * @type {string}
     * @memberof VideosApiGetVideoStreamWithExt
     */
    readonly level?: string

    /**
     * Optional. A specific video framerate to encode to, e.g. 23.976. Generally this should be omitted unless the device has specific requirements.
     * @type {number}
     * @memberof VideosApiGetVideoStreamWithExt
     */
    readonly framerate?: number

    /**
     * Optional. A specific maximum video framerate to encode to, e.g. 23.976. Generally this should be omitted unless the device has specific requirements.
     * @type {number}
     * @memberof VideosApiGetVideoStreamWithExt
     */
    readonly maxFramerate?: number

    /**
     * Whether or not to copy timestamps when transcoding with an offset. Defaults to false.
     * @type {boolean}
     * @memberof VideosApiGetVideoStreamWithExt
     */
    readonly copyTimestamps?: boolean

    /**
     * Optional. Specify a starting offset, in ticks. 1 tick &#x3D; 10000 ms.
     * @type {number}
     * @memberof VideosApiGetVideoStreamWithExt
     */
    readonly startTimeTicks?: number

    /**
     * Optional. The fixed horizontal resolution of the encoded video.
     * @type {number}
     * @memberof VideosApiGetVideoStreamWithExt
     */
    readonly width?: number

    /**
     * Optional. The fixed vertical resolution of the encoded video.
     * @type {number}
     * @memberof VideosApiGetVideoStreamWithExt
     */
    readonly height?: number

    /**
     * Optional. Specify a video bitrate to encode to, e.g. 500000. If omitted this will be left to encoder defaults.
     * @type {number}
     * @memberof VideosApiGetVideoStreamWithExt
     */
    readonly videoBitRate?: number

    /**
     * Optional. The index of the subtitle stream to use. If omitted no subtitles will be used.
     * @type {number}
     * @memberof VideosApiGetVideoStreamWithExt
     */
    readonly subtitleStreamIndex?: number

    /**
     * Optional. Specify the subtitle delivery method.
     * @type {SubtitleDeliveryMethod}
     * @memberof VideosApiGetVideoStreamWithExt
     */
    readonly subtitleMethod?: SubtitleDeliveryMethod

    /**
     * Optional.
     * @type {number}
     * @memberof VideosApiGetVideoStreamWithExt
     */
    readonly maxRefFrames?: number

    /**
     * Optional. The maximum video bit depth.
     * @type {number}
     * @memberof VideosApiGetVideoStreamWithExt
     */
    readonly maxVideoBitDepth?: number

    /**
     * Optional. Whether to require avc.
     * @type {boolean}
     * @memberof VideosApiGetVideoStreamWithExt
     */
    readonly requireAvc?: boolean

    /**
     * Optional. Whether to deinterlace the video.
     * @type {boolean}
     * @memberof VideosApiGetVideoStreamWithExt
     */
    readonly deInterlace?: boolean

    /**
     * Optional. Whether to require a non anamporphic stream.
     * @type {boolean}
     * @memberof VideosApiGetVideoStreamWithExt
     */
    readonly requireNonAnamorphic?: boolean

    /**
     * Optional. The maximum number of audio channels to transcode.
     * @type {number}
     * @memberof VideosApiGetVideoStreamWithExt
     */
    readonly transcodingMaxAudioChannels?: number

    /**
     * Optional. The limit of how many cpu cores to use.
     * @type {number}
     * @memberof VideosApiGetVideoStreamWithExt
     */
    readonly cpuCoreLimit?: number

    /**
     * The live stream id.
     * @type {string}
     * @memberof VideosApiGetVideoStreamWithExt
     */
    readonly liveStreamId?: string

    /**
     * Optional. Whether to enable the MpegtsM2Ts mode.
     * @type {boolean}
     * @memberof VideosApiGetVideoStreamWithExt
     */
    readonly enableMpegtsM2TsMode?: boolean

    /**
     * Optional. Specify a video codec to encode to, e.g. h264. If omitted the server will auto-select using the url\&#39;s extension. Options: h265, h264, mpeg4, theora, vpx, wmv.
     * @type {string}
     * @memberof VideosApiGetVideoStreamWithExt
     */
    readonly videoCodec?: string

    /**
     * Optional. Specify a subtitle codec to encode to.
     * @type {string}
     * @memberof VideosApiGetVideoStreamWithExt
     */
    readonly subtitleCodec?: string

    /**
     * Optional. The transcoding reason.
     * @type {string}
     * @memberof VideosApiGetVideoStreamWithExt
     */
    readonly transcodingReasons?: string

    /**
     * Optional. The index of the audio stream to use. If omitted the first audio stream will be used.
     * @type {number}
     * @memberof VideosApiGetVideoStreamWithExt
     */
    readonly audioStreamIndex?: number

    /**
     * Optional. The index of the video stream to use. If omitted the first video stream will be used.
     * @type {number}
     * @memberof VideosApiGetVideoStreamWithExt
     */
    readonly videoStreamIndex?: number

    /**
     * Optional. The MediaBrowser.Model.Dlna.EncodingContext.
     * @type {EncodingContext}
     * @memberof VideosApiGetVideoStreamWithExt
     */
    readonly context?: EncodingContext

    /**
     * Optional. The streaming options.
     * @type {{ [key: string]: string; }}
     * @memberof VideosApiGetVideoStreamWithExt
     */
    readonly streamOptions?: { [key: string]: string; }
}

/**
 * Request parameters for headVideoStream operation in VideosApi.
 * @export
 * @interface VideosApiHeadVideoStreamRequest
 */
export interface VideosApiHeadVideoStreamRequest {
    /**
     * The item id.
     * @type {string}
     * @memberof VideosApiHeadVideoStream
     */
    readonly itemId: string

    /**
     * The video container. Possible values are: ts, webm, asf, wmv, ogv, mp4, m4v, mkv, mpeg, mpg, avi, 3gp, wmv, wtv, m2ts, mov, iso, flv.
     * @type {string}
     * @memberof VideosApiHeadVideoStream
     */
    readonly container: string

    /**
     * Optional. If true, the original file will be streamed statically without any encoding. Use either no url extension or the original file extension. true/false.
     * @type {boolean}
     * @memberof VideosApiHeadVideoStream
     */
    readonly _static?: boolean

    /**
     * The streaming parameters.
     * @type {string}
     * @memberof VideosApiHeadVideoStream
     */
    readonly params?: string

    /**
     * The tag.
     * @type {string}
     * @memberof VideosApiHeadVideoStream
     */
    readonly tag?: string

    /**
     * Optional. The dlna device profile id to utilize.
     * @type {string}
     * @memberof VideosApiHeadVideoStream
     */
    readonly deviceProfileId?: string

    /**
     * The play session id.
     * @type {string}
     * @memberof VideosApiHeadVideoStream
     */
    readonly playSessionId?: string

    /**
     * The segment container.
     * @type {string}
     * @memberof VideosApiHeadVideoStream
     */
    readonly segmentContainer?: string

    /**
     * The segment lenght.
     * @type {number}
     * @memberof VideosApiHeadVideoStream
     */
    readonly segmentLength?: number

    /**
     * The minimum number of segments.
     * @type {number}
     * @memberof VideosApiHeadVideoStream
     */
    readonly minSegments?: number

    /**
     * The media version id, if playing an alternate version.
     * @type {string}
     * @memberof VideosApiHeadVideoStream
     */
    readonly mediaSourceId?: string

    /**
     * The device id of the client requesting. Used to stop encoding processes when needed.
     * @type {string}
     * @memberof VideosApiHeadVideoStream
     */
    readonly deviceId?: string

    /**
     * Optional. Specify a audio codec to encode to, e.g. mp3. If omitted the server will auto-select using the url\&#39;s extension. Options: aac, mp3, vorbis, wma.
     * @type {string}
     * @memberof VideosApiHeadVideoStream
     */
    readonly audioCodec?: string

    /**
     * Whether or not to allow automatic stream copy if requested values match the original source. Defaults to true.
     * @type {boolean}
     * @memberof VideosApiHeadVideoStream
     */
    readonly enableAutoStreamCopy?: boolean

    /**
     * Whether or not to allow copying of the video stream url.
     * @type {boolean}
     * @memberof VideosApiHeadVideoStream
     */
    readonly allowVideoStreamCopy?: boolean

    /**
     * Whether or not to allow copying of the audio stream url.
     * @type {boolean}
     * @memberof VideosApiHeadVideoStream
     */
    readonly allowAudioStreamCopy?: boolean

    /**
     * Optional. Whether to break on non key frames.
     * @type {boolean}
     * @memberof VideosApiHeadVideoStream
     */
    readonly breakOnNonKeyFrames?: boolean

    /**
     * Optional. Specify a specific audio sample rate, e.g. 44100.
     * @type {number}
     * @memberof VideosApiHeadVideoStream
     */
    readonly audioSampleRate?: number

    /**
     * Optional. The maximum audio bit depth.
     * @type {number}
     * @memberof VideosApiHeadVideoStream
     */
    readonly maxAudioBitDepth?: number

    /**
     * Optional. Specify an audio bitrate to encode to, e.g. 128000. If omitted this will be left to encoder defaults.
     * @type {number}
     * @memberof VideosApiHeadVideoStream
     */
    readonly audioBitRate?: number

    /**
     * Optional. Specify a specific number of audio channels to encode to, e.g. 2.
     * @type {number}
     * @memberof VideosApiHeadVideoStream
     */
    readonly audioChannels?: number

    /**
     * Optional. Specify a maximum number of audio channels to encode to, e.g. 2.
     * @type {number}
     * @memberof VideosApiHeadVideoStream
     */
    readonly maxAudioChannels?: number

    /**
     * Optional. Specify a specific an encoder profile (varies by encoder), e.g. main, baseline, high.
     * @type {string}
     * @memberof VideosApiHeadVideoStream
     */
    readonly profile?: string

    /**
     * Optional. Specify a level for the encoder profile (varies by encoder), e.g. 3, 3.1.
     * @type {string}
     * @memberof VideosApiHeadVideoStream
     */
    readonly level?: string

    /**
     * Optional. A specific video framerate to encode to, e.g. 23.976. Generally this should be omitted unless the device has specific requirements.
     * @type {number}
     * @memberof VideosApiHeadVideoStream
     */
    readonly framerate?: number

    /**
     * Optional. A specific maximum video framerate to encode to, e.g. 23.976. Generally this should be omitted unless the device has specific requirements.
     * @type {number}
     * @memberof VideosApiHeadVideoStream
     */
    readonly maxFramerate?: number

    /**
     * Whether or not to copy timestamps when transcoding with an offset. Defaults to false.
     * @type {boolean}
     * @memberof VideosApiHeadVideoStream
     */
    readonly copyTimestamps?: boolean

    /**
     * Optional. Specify a starting offset, in ticks. 1 tick &#x3D; 10000 ms.
     * @type {number}
     * @memberof VideosApiHeadVideoStream
     */
    readonly startTimeTicks?: number

    /**
     * Optional. The fixed horizontal resolution of the encoded video.
     * @type {number}
     * @memberof VideosApiHeadVideoStream
     */
    readonly width?: number

    /**
     * Optional. The fixed vertical resolution of the encoded video.
     * @type {number}
     * @memberof VideosApiHeadVideoStream
     */
    readonly height?: number

    /**
     * Optional. Specify a video bitrate to encode to, e.g. 500000. If omitted this will be left to encoder defaults.
     * @type {number}
     * @memberof VideosApiHeadVideoStream
     */
    readonly videoBitRate?: number

    /**
     * Optional. The index of the subtitle stream to use. If omitted no subtitles will be used.
     * @type {number}
     * @memberof VideosApiHeadVideoStream
     */
    readonly subtitleStreamIndex?: number

    /**
     * Optional. Specify the subtitle delivery method.
     * @type {SubtitleDeliveryMethod}
     * @memberof VideosApiHeadVideoStream
     */
    readonly subtitleMethod?: SubtitleDeliveryMethod

    /**
     * Optional.
     * @type {number}
     * @memberof VideosApiHeadVideoStream
     */
    readonly maxRefFrames?: number

    /**
     * Optional. The maximum video bit depth.
     * @type {number}
     * @memberof VideosApiHeadVideoStream
     */
    readonly maxVideoBitDepth?: number

    /**
     * Optional. Whether to require avc.
     * @type {boolean}
     * @memberof VideosApiHeadVideoStream
     */
    readonly requireAvc?: boolean

    /**
     * Optional. Whether to deinterlace the video.
     * @type {boolean}
     * @memberof VideosApiHeadVideoStream
     */
    readonly deInterlace?: boolean

    /**
     * Optional. Whether to require a non anamporphic stream.
     * @type {boolean}
     * @memberof VideosApiHeadVideoStream
     */
    readonly requireNonAnamorphic?: boolean

    /**
     * Optional. The maximum number of audio channels to transcode.
     * @type {number}
     * @memberof VideosApiHeadVideoStream
     */
    readonly transcodingMaxAudioChannels?: number

    /**
     * Optional. The limit of how many cpu cores to use.
     * @type {number}
     * @memberof VideosApiHeadVideoStream
     */
    readonly cpuCoreLimit?: number

    /**
     * The live stream id.
     * @type {string}
     * @memberof VideosApiHeadVideoStream
     */
    readonly liveStreamId?: string

    /**
     * Optional. Whether to enable the MpegtsM2Ts mode.
     * @type {boolean}
     * @memberof VideosApiHeadVideoStream
     */
    readonly enableMpegtsM2TsMode?: boolean

    /**
     * Optional. Specify a video codec to encode to, e.g. h264. If omitted the server will auto-select using the url\&#39;s extension. Options: h265, h264, mpeg4, theora, vpx, wmv.
     * @type {string}
     * @memberof VideosApiHeadVideoStream
     */
    readonly videoCodec?: string

    /**
     * Optional. Specify a subtitle codec to encode to.
     * @type {string}
     * @memberof VideosApiHeadVideoStream
     */
    readonly subtitleCodec?: string

    /**
     * Optional. The transcoding reason.
     * @type {string}
     * @memberof VideosApiHeadVideoStream
     */
    readonly transcodingReasons?: string

    /**
     * Optional. The index of the audio stream to use. If omitted the first audio stream will be used.
     * @type {number}
     * @memberof VideosApiHeadVideoStream
     */
    readonly audioStreamIndex?: number

    /**
     * Optional. The index of the video stream to use. If omitted the first video stream will be used.
     * @type {number}
     * @memberof VideosApiHeadVideoStream
     */
    readonly videoStreamIndex?: number

    /**
     * Optional. The MediaBrowser.Model.Dlna.EncodingContext.
     * @type {EncodingContext}
     * @memberof VideosApiHeadVideoStream
     */
    readonly context?: EncodingContext

    /**
     * Optional. The streaming options.
     * @type {{ [key: string]: string; }}
     * @memberof VideosApiHeadVideoStream
     */
    readonly streamOptions?: { [key: string]: string; }
}

/**
 * Request parameters for headVideoStreamWithExt operation in VideosApi.
 * @export
 * @interface VideosApiHeadVideoStreamWithExtRequest
 */
export interface VideosApiHeadVideoStreamWithExtRequest {
    /**
     * The item id.
     * @type {string}
     * @memberof VideosApiHeadVideoStreamWithExt
     */
    readonly itemId: string

    /**
     * The video container. Possible values are: ts, webm, asf, wmv, ogv, mp4, m4v, mkv, mpeg, mpg, avi, 3gp, wmv, wtv, m2ts, mov, iso, flv.
     * @type {string}
     * @memberof VideosApiHeadVideoStreamWithExt
     */
    readonly container: string

    /**
     * 
     * @type {string}
     * @memberof VideosApiHeadVideoStreamWithExt
     */
    readonly stream: string

    /**
     * Optional. If true, the original file will be streamed statically without any encoding. Use either no url extension or the original file extension. true/false.
     * @type {boolean}
     * @memberof VideosApiHeadVideoStreamWithExt
     */
    readonly _static?: boolean

    /**
     * The streaming parameters.
     * @type {string}
     * @memberof VideosApiHeadVideoStreamWithExt
     */
    readonly params?: string

    /**
     * The tag.
     * @type {string}
     * @memberof VideosApiHeadVideoStreamWithExt
     */
    readonly tag?: string

    /**
     * Optional. The dlna device profile id to utilize.
     * @type {string}
     * @memberof VideosApiHeadVideoStreamWithExt
     */
    readonly deviceProfileId?: string

    /**
     * The play session id.
     * @type {string}
     * @memberof VideosApiHeadVideoStreamWithExt
     */
    readonly playSessionId?: string

    /**
     * The segment container.
     * @type {string}
     * @memberof VideosApiHeadVideoStreamWithExt
     */
    readonly segmentContainer?: string

    /**
     * The segment lenght.
     * @type {number}
     * @memberof VideosApiHeadVideoStreamWithExt
     */
    readonly segmentLength?: number

    /**
     * The minimum number of segments.
     * @type {number}
     * @memberof VideosApiHeadVideoStreamWithExt
     */
    readonly minSegments?: number

    /**
     * The media version id, if playing an alternate version.
     * @type {string}
     * @memberof VideosApiHeadVideoStreamWithExt
     */
    readonly mediaSourceId?: string

    /**
     * The device id of the client requesting. Used to stop encoding processes when needed.
     * @type {string}
     * @memberof VideosApiHeadVideoStreamWithExt
     */
    readonly deviceId?: string

    /**
     * Optional. Specify a audio codec to encode to, e.g. mp3. If omitted the server will auto-select using the url\&#39;s extension. Options: aac, mp3, vorbis, wma.
     * @type {string}
     * @memberof VideosApiHeadVideoStreamWithExt
     */
    readonly audioCodec?: string

    /**
     * Whether or not to allow automatic stream copy if requested values match the original source. Defaults to true.
     * @type {boolean}
     * @memberof VideosApiHeadVideoStreamWithExt
     */
    readonly enableAutoStreamCopy?: boolean

    /**
     * Whether or not to allow copying of the video stream url.
     * @type {boolean}
     * @memberof VideosApiHeadVideoStreamWithExt
     */
    readonly allowVideoStreamCopy?: boolean

    /**
     * Whether or not to allow copying of the audio stream url.
     * @type {boolean}
     * @memberof VideosApiHeadVideoStreamWithExt
     */
    readonly allowAudioStreamCopy?: boolean

    /**
     * Optional. Whether to break on non key frames.
     * @type {boolean}
     * @memberof VideosApiHeadVideoStreamWithExt
     */
    readonly breakOnNonKeyFrames?: boolean

    /**
     * Optional. Specify a specific audio sample rate, e.g. 44100.
     * @type {number}
     * @memberof VideosApiHeadVideoStreamWithExt
     */
    readonly audioSampleRate?: number

    /**
     * Optional. The maximum audio bit depth.
     * @type {number}
     * @memberof VideosApiHeadVideoStreamWithExt
     */
    readonly maxAudioBitDepth?: number

    /**
     * Optional. Specify an audio bitrate to encode to, e.g. 128000. If omitted this will be left to encoder defaults.
     * @type {number}
     * @memberof VideosApiHeadVideoStreamWithExt
     */
    readonly audioBitRate?: number

    /**
     * Optional. Specify a specific number of audio channels to encode to, e.g. 2.
     * @type {number}
     * @memberof VideosApiHeadVideoStreamWithExt
     */
    readonly audioChannels?: number

    /**
     * Optional. Specify a maximum number of audio channels to encode to, e.g. 2.
     * @type {number}
     * @memberof VideosApiHeadVideoStreamWithExt
     */
    readonly maxAudioChannels?: number

    /**
     * Optional. Specify a specific an encoder profile (varies by encoder), e.g. main, baseline, high.
     * @type {string}
     * @memberof VideosApiHeadVideoStreamWithExt
     */
    readonly profile?: string

    /**
     * Optional. Specify a level for the encoder profile (varies by encoder), e.g. 3, 3.1.
     * @type {string}
     * @memberof VideosApiHeadVideoStreamWithExt
     */
    readonly level?: string

    /**
     * Optional. A specific video framerate to encode to, e.g. 23.976. Generally this should be omitted unless the device has specific requirements.
     * @type {number}
     * @memberof VideosApiHeadVideoStreamWithExt
     */
    readonly framerate?: number

    /**
     * Optional. A specific maximum video framerate to encode to, e.g. 23.976. Generally this should be omitted unless the device has specific requirements.
     * @type {number}
     * @memberof VideosApiHeadVideoStreamWithExt
     */
    readonly maxFramerate?: number

    /**
     * Whether or not to copy timestamps when transcoding with an offset. Defaults to false.
     * @type {boolean}
     * @memberof VideosApiHeadVideoStreamWithExt
     */
    readonly copyTimestamps?: boolean

    /**
     * Optional. Specify a starting offset, in ticks. 1 tick &#x3D; 10000 ms.
     * @type {number}
     * @memberof VideosApiHeadVideoStreamWithExt
     */
    readonly startTimeTicks?: number

    /**
     * Optional. The fixed horizontal resolution of the encoded video.
     * @type {number}
     * @memberof VideosApiHeadVideoStreamWithExt
     */
    readonly width?: number

    /**
     * Optional. The fixed vertical resolution of the encoded video.
     * @type {number}
     * @memberof VideosApiHeadVideoStreamWithExt
     */
    readonly height?: number

    /**
     * Optional. Specify a video bitrate to encode to, e.g. 500000. If omitted this will be left to encoder defaults.
     * @type {number}
     * @memberof VideosApiHeadVideoStreamWithExt
     */
    readonly videoBitRate?: number

    /**
     * Optional. The index of the subtitle stream to use. If omitted no subtitles will be used.
     * @type {number}
     * @memberof VideosApiHeadVideoStreamWithExt
     */
    readonly subtitleStreamIndex?: number

    /**
     * Optional. Specify the subtitle delivery method.
     * @type {SubtitleDeliveryMethod}
     * @memberof VideosApiHeadVideoStreamWithExt
     */
    readonly subtitleMethod?: SubtitleDeliveryMethod

    /**
     * Optional.
     * @type {number}
     * @memberof VideosApiHeadVideoStreamWithExt
     */
    readonly maxRefFrames?: number

    /**
     * Optional. The maximum video bit depth.
     * @type {number}
     * @memberof VideosApiHeadVideoStreamWithExt
     */
    readonly maxVideoBitDepth?: number

    /**
     * Optional. Whether to require avc.
     * @type {boolean}
     * @memberof VideosApiHeadVideoStreamWithExt
     */
    readonly requireAvc?: boolean

    /**
     * Optional. Whether to deinterlace the video.
     * @type {boolean}
     * @memberof VideosApiHeadVideoStreamWithExt
     */
    readonly deInterlace?: boolean

    /**
     * Optional. Whether to require a non anamporphic stream.
     * @type {boolean}
     * @memberof VideosApiHeadVideoStreamWithExt
     */
    readonly requireNonAnamorphic?: boolean

    /**
     * Optional. The maximum number of audio channels to transcode.
     * @type {number}
     * @memberof VideosApiHeadVideoStreamWithExt
     */
    readonly transcodingMaxAudioChannels?: number

    /**
     * Optional. The limit of how many cpu cores to use.
     * @type {number}
     * @memberof VideosApiHeadVideoStreamWithExt
     */
    readonly cpuCoreLimit?: number

    /**
     * The live stream id.
     * @type {string}
     * @memberof VideosApiHeadVideoStreamWithExt
     */
    readonly liveStreamId?: string

    /**
     * Optional. Whether to enable the MpegtsM2Ts mode.
     * @type {boolean}
     * @memberof VideosApiHeadVideoStreamWithExt
     */
    readonly enableMpegtsM2TsMode?: boolean

    /**
     * Optional. Specify a video codec to encode to, e.g. h264. If omitted the server will auto-select using the url\&#39;s extension. Options: h265, h264, mpeg4, theora, vpx, wmv.
     * @type {string}
     * @memberof VideosApiHeadVideoStreamWithExt
     */
    readonly videoCodec?: string

    /**
     * Optional. Specify a subtitle codec to encode to.
     * @type {string}
     * @memberof VideosApiHeadVideoStreamWithExt
     */
    readonly subtitleCodec?: string

    /**
     * Optional. The transcoding reason.
     * @type {string}
     * @memberof VideosApiHeadVideoStreamWithExt
     */
    readonly transcodingReasons?: string

    /**
     * Optional. The index of the audio stream to use. If omitted the first audio stream will be used.
     * @type {number}
     * @memberof VideosApiHeadVideoStreamWithExt
     */
    readonly audioStreamIndex?: number

    /**
     * Optional. The index of the video stream to use. If omitted the first video stream will be used.
     * @type {number}
     * @memberof VideosApiHeadVideoStreamWithExt
     */
    readonly videoStreamIndex?: number

    /**
     * Optional. The MediaBrowser.Model.Dlna.EncodingContext.
     * @type {EncodingContext}
     * @memberof VideosApiHeadVideoStreamWithExt
     */
    readonly context?: EncodingContext

    /**
     * Optional. The streaming options.
     * @type {{ [key: string]: string; }}
     * @memberof VideosApiHeadVideoStreamWithExt
     */
    readonly streamOptions?: { [key: string]: string; }
}

/**
 * Request parameters for mergeVersions operation in VideosApi.
 * @export
 * @interface VideosApiMergeVersionsRequest
 */
export interface VideosApiMergeVersionsRequest {
    /**
     * Item id list. This allows multiple, comma delimited.
     * @type {string}
     * @memberof VideosApiMergeVersions
     */
    readonly itemIds: string
}

/**
 * VideosApi - object-oriented interface
 * @export
 * @class VideosApi
 * @extends {BaseAPI}
 */
export class VideosApi extends BaseAPI {
    /**
     * 
     * @summary Removes alternate video sources.
     * @param {VideosApiDeleteAlternateSourcesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VideosApi
     */
    public deleteAlternateSources(requestParameters: VideosApiDeleteAlternateSourcesRequest, options?: any) {
        return VideosApiFp(this.configuration).deleteAlternateSources(requestParameters.itemId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets additional parts for a video.
     * @param {VideosApiGetAdditionalPartRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VideosApi
     */
    public getAdditionalPart(requestParameters: VideosApiGetAdditionalPartRequest, options?: any) {
        return VideosApiFp(this.configuration).getAdditionalPart(requestParameters.itemId, requestParameters.userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets a video stream.
     * @param {VideosApiGetVideoStreamRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VideosApi
     */
    public getVideoStream(requestParameters: VideosApiGetVideoStreamRequest, options?: any) {
        return VideosApiFp(this.configuration).getVideoStream(requestParameters.itemId, requestParameters.container, requestParameters._static, requestParameters.params, requestParameters.tag, requestParameters.deviceProfileId, requestParameters.playSessionId, requestParameters.segmentContainer, requestParameters.segmentLength, requestParameters.minSegments, requestParameters.mediaSourceId, requestParameters.deviceId, requestParameters.audioCodec, requestParameters.enableAutoStreamCopy, requestParameters.allowVideoStreamCopy, requestParameters.allowAudioStreamCopy, requestParameters.breakOnNonKeyFrames, requestParameters.audioSampleRate, requestParameters.maxAudioBitDepth, requestParameters.audioBitRate, requestParameters.audioChannels, requestParameters.maxAudioChannels, requestParameters.profile, requestParameters.level, requestParameters.framerate, requestParameters.maxFramerate, requestParameters.copyTimestamps, requestParameters.startTimeTicks, requestParameters.width, requestParameters.height, requestParameters.videoBitRate, requestParameters.subtitleStreamIndex, requestParameters.subtitleMethod, requestParameters.maxRefFrames, requestParameters.maxVideoBitDepth, requestParameters.requireAvc, requestParameters.deInterlace, requestParameters.requireNonAnamorphic, requestParameters.transcodingMaxAudioChannels, requestParameters.cpuCoreLimit, requestParameters.liveStreamId, requestParameters.enableMpegtsM2TsMode, requestParameters.videoCodec, requestParameters.subtitleCodec, requestParameters.transcodingReasons, requestParameters.audioStreamIndex, requestParameters.videoStreamIndex, requestParameters.context, requestParameters.streamOptions, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets a video stream.
     * @param {VideosApiGetVideoStreamWithExtRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VideosApi
     */
    public getVideoStreamWithExt(requestParameters: VideosApiGetVideoStreamWithExtRequest, options?: any) {
        return VideosApiFp(this.configuration).getVideoStreamWithExt(requestParameters.itemId, requestParameters.container, requestParameters.stream, requestParameters._static, requestParameters.params, requestParameters.tag, requestParameters.deviceProfileId, requestParameters.playSessionId, requestParameters.segmentContainer, requestParameters.segmentLength, requestParameters.minSegments, requestParameters.mediaSourceId, requestParameters.deviceId, requestParameters.audioCodec, requestParameters.enableAutoStreamCopy, requestParameters.allowVideoStreamCopy, requestParameters.allowAudioStreamCopy, requestParameters.breakOnNonKeyFrames, requestParameters.audioSampleRate, requestParameters.maxAudioBitDepth, requestParameters.audioBitRate, requestParameters.audioChannels, requestParameters.maxAudioChannels, requestParameters.profile, requestParameters.level, requestParameters.framerate, requestParameters.maxFramerate, requestParameters.copyTimestamps, requestParameters.startTimeTicks, requestParameters.width, requestParameters.height, requestParameters.videoBitRate, requestParameters.subtitleStreamIndex, requestParameters.subtitleMethod, requestParameters.maxRefFrames, requestParameters.maxVideoBitDepth, requestParameters.requireAvc, requestParameters.deInterlace, requestParameters.requireNonAnamorphic, requestParameters.transcodingMaxAudioChannels, requestParameters.cpuCoreLimit, requestParameters.liveStreamId, requestParameters.enableMpegtsM2TsMode, requestParameters.videoCodec, requestParameters.subtitleCodec, requestParameters.transcodingReasons, requestParameters.audioStreamIndex, requestParameters.videoStreamIndex, requestParameters.context, requestParameters.streamOptions, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets a video stream.
     * @param {VideosApiHeadVideoStreamRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VideosApi
     */
    public headVideoStream(requestParameters: VideosApiHeadVideoStreamRequest, options?: any) {
        return VideosApiFp(this.configuration).headVideoStream(requestParameters.itemId, requestParameters.container, requestParameters._static, requestParameters.params, requestParameters.tag, requestParameters.deviceProfileId, requestParameters.playSessionId, requestParameters.segmentContainer, requestParameters.segmentLength, requestParameters.minSegments, requestParameters.mediaSourceId, requestParameters.deviceId, requestParameters.audioCodec, requestParameters.enableAutoStreamCopy, requestParameters.allowVideoStreamCopy, requestParameters.allowAudioStreamCopy, requestParameters.breakOnNonKeyFrames, requestParameters.audioSampleRate, requestParameters.maxAudioBitDepth, requestParameters.audioBitRate, requestParameters.audioChannels, requestParameters.maxAudioChannels, requestParameters.profile, requestParameters.level, requestParameters.framerate, requestParameters.maxFramerate, requestParameters.copyTimestamps, requestParameters.startTimeTicks, requestParameters.width, requestParameters.height, requestParameters.videoBitRate, requestParameters.subtitleStreamIndex, requestParameters.subtitleMethod, requestParameters.maxRefFrames, requestParameters.maxVideoBitDepth, requestParameters.requireAvc, requestParameters.deInterlace, requestParameters.requireNonAnamorphic, requestParameters.transcodingMaxAudioChannels, requestParameters.cpuCoreLimit, requestParameters.liveStreamId, requestParameters.enableMpegtsM2TsMode, requestParameters.videoCodec, requestParameters.subtitleCodec, requestParameters.transcodingReasons, requestParameters.audioStreamIndex, requestParameters.videoStreamIndex, requestParameters.context, requestParameters.streamOptions, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets a video stream.
     * @param {VideosApiHeadVideoStreamWithExtRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VideosApi
     */
    public headVideoStreamWithExt(requestParameters: VideosApiHeadVideoStreamWithExtRequest, options?: any) {
        return VideosApiFp(this.configuration).headVideoStreamWithExt(requestParameters.itemId, requestParameters.container, requestParameters.stream, requestParameters._static, requestParameters.params, requestParameters.tag, requestParameters.deviceProfileId, requestParameters.playSessionId, requestParameters.segmentContainer, requestParameters.segmentLength, requestParameters.minSegments, requestParameters.mediaSourceId, requestParameters.deviceId, requestParameters.audioCodec, requestParameters.enableAutoStreamCopy, requestParameters.allowVideoStreamCopy, requestParameters.allowAudioStreamCopy, requestParameters.breakOnNonKeyFrames, requestParameters.audioSampleRate, requestParameters.maxAudioBitDepth, requestParameters.audioBitRate, requestParameters.audioChannels, requestParameters.maxAudioChannels, requestParameters.profile, requestParameters.level, requestParameters.framerate, requestParameters.maxFramerate, requestParameters.copyTimestamps, requestParameters.startTimeTicks, requestParameters.width, requestParameters.height, requestParameters.videoBitRate, requestParameters.subtitleStreamIndex, requestParameters.subtitleMethod, requestParameters.maxRefFrames, requestParameters.maxVideoBitDepth, requestParameters.requireAvc, requestParameters.deInterlace, requestParameters.requireNonAnamorphic, requestParameters.transcodingMaxAudioChannels, requestParameters.cpuCoreLimit, requestParameters.liveStreamId, requestParameters.enableMpegtsM2TsMode, requestParameters.videoCodec, requestParameters.subtitleCodec, requestParameters.transcodingReasons, requestParameters.audioStreamIndex, requestParameters.videoStreamIndex, requestParameters.context, requestParameters.streamOptions, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Merges videos into a single record.
     * @param {VideosApiMergeVersionsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VideosApi
     */
    public mergeVersions(requestParameters: VideosApiMergeVersionsRequest, options?: any) {
        return VideosApiFp(this.configuration).mergeVersions(requestParameters.itemIds, options).then((request) => request(this.axios, this.basePath));
    }
}
