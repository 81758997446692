/* tslint:disable */
/* eslint-disable */
/**
 * Jellyfin API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ConfigurationPageInfo } from '../models';
// @ts-ignore
import { ConfigurationPageType } from '../models';
// @ts-ignore
import { ProblemDetails } from '../models';
/**
 * DashboardApi - axios parameter creator
 * @export
 */
export const DashboardApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Gets the configuration pages.
         * @param {boolean} [enableInMainMenu] Whether to enable in the main menu.
         * @param {ConfigurationPageType} [pageType] The Jellyfin.Api.Models.ConfigurationPageInfo.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConfigurationPages: async (enableInMainMenu?: boolean, pageType?: ConfigurationPageType, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/web/ConfigurationPages`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }

            if (enableInMainMenu !== undefined) {
                localVarQueryParameter['enableInMainMenu'] = enableInMainMenu;
            }

            if (pageType !== undefined) {
                localVarQueryParameter['pageType'] = pageType;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets a dashboard configuration page.
         * @param {string} [name] The name of the page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDashboardConfigurationPage: async (name?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/web/ConfigurationPage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DashboardApi - functional programming interface
 * @export
 */
export const DashboardApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Gets the configuration pages.
         * @param {boolean} [enableInMainMenu] Whether to enable in the main menu.
         * @param {ConfigurationPageType} [pageType] The Jellyfin.Api.Models.ConfigurationPageInfo.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getConfigurationPages(enableInMainMenu?: boolean, pageType?: ConfigurationPageType, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ConfigurationPageInfo>>> {
            const localVarAxiosArgs = await DashboardApiAxiosParamCreator(configuration).getConfigurationPages(enableInMainMenu, pageType, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Gets a dashboard configuration page.
         * @param {string} [name] The name of the page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDashboardConfigurationPage(name?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await DashboardApiAxiosParamCreator(configuration).getDashboardConfigurationPage(name, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * DashboardApi - factory interface
 * @export
 */
export const DashboardApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Gets the configuration pages.
         * @param {boolean} [enableInMainMenu] Whether to enable in the main menu.
         * @param {ConfigurationPageType} [pageType] The Jellyfin.Api.Models.ConfigurationPageInfo.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConfigurationPages(enableInMainMenu?: boolean, pageType?: ConfigurationPageType, options?: any): AxiosPromise<Array<ConfigurationPageInfo>> {
            return DashboardApiFp(configuration).getConfigurationPages(enableInMainMenu, pageType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets a dashboard configuration page.
         * @param {string} [name] The name of the page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDashboardConfigurationPage(name?: string, options?: any): AxiosPromise<any> {
            return DashboardApiFp(configuration).getDashboardConfigurationPage(name, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getConfigurationPages operation in DashboardApi.
 * @export
 * @interface DashboardApiGetConfigurationPagesRequest
 */
export interface DashboardApiGetConfigurationPagesRequest {
    /**
     * Whether to enable in the main menu.
     * @type {boolean}
     * @memberof DashboardApiGetConfigurationPages
     */
    readonly enableInMainMenu?: boolean

    /**
     * The Jellyfin.Api.Models.ConfigurationPageInfo.
     * @type {ConfigurationPageType}
     * @memberof DashboardApiGetConfigurationPages
     */
    readonly pageType?: ConfigurationPageType
}

/**
 * Request parameters for getDashboardConfigurationPage operation in DashboardApi.
 * @export
 * @interface DashboardApiGetDashboardConfigurationPageRequest
 */
export interface DashboardApiGetDashboardConfigurationPageRequest {
    /**
     * The name of the page.
     * @type {string}
     * @memberof DashboardApiGetDashboardConfigurationPage
     */
    readonly name?: string
}

/**
 * DashboardApi - object-oriented interface
 * @export
 * @class DashboardApi
 * @extends {BaseAPI}
 */
export class DashboardApi extends BaseAPI {
    /**
     * 
     * @summary Gets the configuration pages.
     * @param {DashboardApiGetConfigurationPagesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardApi
     */
    public getConfigurationPages(requestParameters: DashboardApiGetConfigurationPagesRequest = {}, options?: any) {
        return DashboardApiFp(this.configuration).getConfigurationPages(requestParameters.enableInMainMenu, requestParameters.pageType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets a dashboard configuration page.
     * @param {DashboardApiGetDashboardConfigurationPageRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardApi
     */
    public getDashboardConfigurationPage(requestParameters: DashboardApiGetDashboardConfigurationPageRequest = {}, options?: any) {
        return DashboardApiFp(this.configuration).getDashboardConfigurationPage(requestParameters.name, options).then((request) => request(this.axios, this.basePath));
    }
}
