/* tslint:disable */
/* eslint-disable */
/**
 * Jellyfin API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { VersionInfo } from './version-info';

/**
 * Class PackageInfo.
 * @export
 * @interface PackageInfo
 */
export interface PackageInfo {
    /**
     * Gets or sets the name.
     * @type {string}
     * @memberof PackageInfo
     */
    name?: string | null;
    /**
     * Gets or sets a long description of the plugin containing features or helpful explanations.
     * @type {string}
     * @memberof PackageInfo
     */
    description?: string | null;
    /**
     * Gets or sets a short overview of what the plugin does.
     * @type {string}
     * @memberof PackageInfo
     */
    overview?: string | null;
    /**
     * Gets or sets the owner.
     * @type {string}
     * @memberof PackageInfo
     */
    owner?: string | null;
    /**
     * Gets or sets the category.
     * @type {string}
     * @memberof PackageInfo
     */
    category?: string | null;
    /**
     * The guid of the assembly associated with this plugin.  This is used to identify the proper item for automatic updates.
     * @type {string}
     * @memberof PackageInfo
     */
    guid?: string | null;
    /**
     * Gets or sets the versions.
     * @type {Array<VersionInfo>}
     * @memberof PackageInfo
     */
    versions?: Array<VersionInfo> | null;
    /**
     * Gets or sets the repository name.
     * @type {string}
     * @memberof PackageInfo
     */
    repositoryName?: string | null;
    /**
     * Gets or sets the repository url.
     * @type {string}
     * @memberof PackageInfo
     */
    repositoryUrl?: string | null;
}


