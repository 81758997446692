/* tslint:disable */
/* eslint-disable */
/**
 * Jellyfin API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AllThemeMediaResult } from '../models';
// @ts-ignore
import { BaseItemDto } from '../models';
// @ts-ignore
import { BaseItemDtoQueryResult } from '../models';
// @ts-ignore
import { ItemCounts } from '../models';
// @ts-ignore
import { LibraryOptionsResultDto } from '../models';
// @ts-ignore
import { MediaUpdateInfoDto } from '../models';
// @ts-ignore
import { ProblemDetails } from '../models';
// @ts-ignore
import { ThemeMediaResult } from '../models';
/**
 * LibraryApi - axios parameter creator
 * @export
 */
export const LibraryApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Deletes an item from the library and filesystem.
         * @param {string} itemId The item id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteItem: async (itemId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'itemId' is not null or undefined
            if (itemId === null || itemId === undefined) {
                throw new RequiredError('itemId','Required parameter itemId was null or undefined when calling deleteItem.');
            }
            const localVarPath = `/Items/{itemId}`
                .replace(`{${"itemId"}}`, encodeURIComponent(String(itemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deletes items from the library and filesystem.
         * @param {string} [ids] The item ids.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteItems: async (ids?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/Items`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }

            if (ids !== undefined) {
                localVarQueryParameter['ids'] = ids;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets all parents of an item.
         * @param {string} itemId The item id.
         * @param {string} [userId] Optional. Filter by user id, and attach user data.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAncestors: async (itemId: string, userId?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'itemId' is not null or undefined
            if (itemId === null || itemId === undefined) {
                throw new RequiredError('itemId','Required parameter itemId was null or undefined when calling getAncestors.');
            }
            const localVarPath = `/Items/{itemId}/Ancestors`
                .replace(`{${"itemId"}}`, encodeURIComponent(String(itemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets critic review for an item.
         * @param {string} itemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCriticReviews: async (itemId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'itemId' is not null or undefined
            if (itemId === null || itemId === undefined) {
                throw new RequiredError('itemId','Required parameter itemId was null or undefined when calling getCriticReviews.');
            }
            const localVarPath = `/Items/{itemId}/CriticReviews`
                .replace(`{${"itemId"}}`, encodeURIComponent(String(itemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Downloads item media.
         * @param {string} itemId The item id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDownload: async (itemId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'itemId' is not null or undefined
            if (itemId === null || itemId === undefined) {
                throw new RequiredError('itemId','Required parameter itemId was null or undefined when calling getDownload.');
            }
            const localVarPath = `/Items/{itemId}/Download`
                .replace(`{${"itemId"}}`, encodeURIComponent(String(itemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the original file of an item.
         * @param {string} itemId The item id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFile: async (itemId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'itemId' is not null or undefined
            if (itemId === null || itemId === undefined) {
                throw new RequiredError('itemId','Required parameter itemId was null or undefined when calling getFile.');
            }
            const localVarPath = `/Items/{itemId}/File`
                .replace(`{${"itemId"}}`, encodeURIComponent(String(itemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get item counts.
         * @param {string} [userId] Optional. Get counts from a specific user\&#39;s library.
         * @param {boolean} [isFavorite] Optional. Get counts of favorite items.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getItemCounts: async (userId?: string, isFavorite?: boolean, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/Items/Counts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }

            if (isFavorite !== undefined) {
                localVarQueryParameter['isFavorite'] = isFavorite;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets the library options info.
         * @param {string} [libraryContentType] Library content type.
         * @param {boolean} [isNewLibrary] Whether this is a new library.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLibraryOptionsInfo: async (libraryContentType?: string, isNewLibrary?: boolean, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/Libraries/AvailableOptions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }

            if (libraryContentType !== undefined) {
                localVarQueryParameter['libraryContentType'] = libraryContentType;
            }

            if (isNewLibrary !== undefined) {
                localVarQueryParameter['isNewLibrary'] = isNewLibrary;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets all user media folders.
         * @param {boolean} [isHidden] Optional. Filter by folders that are marked hidden, or not.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMediaFolders: async (isHidden?: boolean, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/Library/MediaFolders`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }

            if (isHidden !== undefined) {
                localVarQueryParameter['isHidden'] = isHidden;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets a list of physical paths from virtual folders.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPhysicalPaths: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/Library/PhysicalPaths`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets similar items.
         * @param {string} itemId The item id.
         * @param {string} [excludeArtistIds] Exclude artist ids.
         * @param {string} [userId] Optional. Filter by user id, and attach user data.
         * @param {number} [limit] Optional. The maximum number of records to return.
         * @param {string} [fields] Optional. Specify additional fields of information to return in the output. This allows multiple, comma delimited. Options: Budget, Chapters, DateCreated, Genres, HomePageUrl, IndexOptions, MediaStreams, Overview, ParentId, Path, People, ProviderIds, PrimaryImageAspectRatio, Revenue, SortName, Studios, Taglines, TrailerUrls.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSimilarAlbums2: async (itemId: string, excludeArtistIds?: string, userId?: string, limit?: number, fields?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'itemId' is not null or undefined
            if (itemId === null || itemId === undefined) {
                throw new RequiredError('itemId','Required parameter itemId was null or undefined when calling getSimilarAlbums2.');
            }
            const localVarPath = `/Albums/{itemId}/Similar`
                .replace(`{${"itemId"}}`, encodeURIComponent(String(itemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }

            if (excludeArtistIds !== undefined) {
                localVarQueryParameter['excludeArtistIds'] = excludeArtistIds;
            }

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (fields !== undefined) {
                localVarQueryParameter['fields'] = fields;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets similar items.
         * @param {string} itemId The item id.
         * @param {string} [excludeArtistIds] Exclude artist ids.
         * @param {string} [userId] Optional. Filter by user id, and attach user data.
         * @param {number} [limit] Optional. The maximum number of records to return.
         * @param {string} [fields] Optional. Specify additional fields of information to return in the output. This allows multiple, comma delimited. Options: Budget, Chapters, DateCreated, Genres, HomePageUrl, IndexOptions, MediaStreams, Overview, ParentId, Path, People, ProviderIds, PrimaryImageAspectRatio, Revenue, SortName, Studios, Taglines, TrailerUrls.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSimilarArtists2: async (itemId: string, excludeArtistIds?: string, userId?: string, limit?: number, fields?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'itemId' is not null or undefined
            if (itemId === null || itemId === undefined) {
                throw new RequiredError('itemId','Required parameter itemId was null or undefined when calling getSimilarArtists2.');
            }
            const localVarPath = `/Artists/{itemId}/Similar`
                .replace(`{${"itemId"}}`, encodeURIComponent(String(itemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }

            if (excludeArtistIds !== undefined) {
                localVarQueryParameter['excludeArtistIds'] = excludeArtistIds;
            }

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (fields !== undefined) {
                localVarQueryParameter['fields'] = fields;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets similar items.
         * @param {string} itemId The item id.
         * @param {string} [excludeArtistIds] Exclude artist ids.
         * @param {string} [userId] Optional. Filter by user id, and attach user data.
         * @param {number} [limit] Optional. The maximum number of records to return.
         * @param {string} [fields] Optional. Specify additional fields of information to return in the output. This allows multiple, comma delimited. Options: Budget, Chapters, DateCreated, Genres, HomePageUrl, IndexOptions, MediaStreams, Overview, ParentId, Path, People, ProviderIds, PrimaryImageAspectRatio, Revenue, SortName, Studios, Taglines, TrailerUrls.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSimilarItems: async (itemId: string, excludeArtistIds?: string, userId?: string, limit?: number, fields?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'itemId' is not null or undefined
            if (itemId === null || itemId === undefined) {
                throw new RequiredError('itemId','Required parameter itemId was null or undefined when calling getSimilarItems.');
            }
            const localVarPath = `/Items/{itemId}/Similar`
                .replace(`{${"itemId"}}`, encodeURIComponent(String(itemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }

            if (excludeArtistIds !== undefined) {
                localVarQueryParameter['excludeArtistIds'] = excludeArtistIds;
            }

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (fields !== undefined) {
                localVarQueryParameter['fields'] = fields;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets similar items.
         * @param {string} itemId The item id.
         * @param {string} [excludeArtistIds] Exclude artist ids.
         * @param {string} [userId] Optional. Filter by user id, and attach user data.
         * @param {number} [limit] Optional. The maximum number of records to return.
         * @param {string} [fields] Optional. Specify additional fields of information to return in the output. This allows multiple, comma delimited. Options: Budget, Chapters, DateCreated, Genres, HomePageUrl, IndexOptions, MediaStreams, Overview, ParentId, Path, People, ProviderIds, PrimaryImageAspectRatio, Revenue, SortName, Studios, Taglines, TrailerUrls.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSimilarMovies2: async (itemId: string, excludeArtistIds?: string, userId?: string, limit?: number, fields?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'itemId' is not null or undefined
            if (itemId === null || itemId === undefined) {
                throw new RequiredError('itemId','Required parameter itemId was null or undefined when calling getSimilarMovies2.');
            }
            const localVarPath = `/Movies/{itemId}/Similar`
                .replace(`{${"itemId"}}`, encodeURIComponent(String(itemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }

            if (excludeArtistIds !== undefined) {
                localVarQueryParameter['excludeArtistIds'] = excludeArtistIds;
            }

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (fields !== undefined) {
                localVarQueryParameter['fields'] = fields;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets similar items.
         * @param {string} itemId The item id.
         * @param {string} [excludeArtistIds] Exclude artist ids.
         * @param {string} [userId] Optional. Filter by user id, and attach user data.
         * @param {number} [limit] Optional. The maximum number of records to return.
         * @param {string} [fields] Optional. Specify additional fields of information to return in the output. This allows multiple, comma delimited. Options: Budget, Chapters, DateCreated, Genres, HomePageUrl, IndexOptions, MediaStreams, Overview, ParentId, Path, People, ProviderIds, PrimaryImageAspectRatio, Revenue, SortName, Studios, Taglines, TrailerUrls.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSimilarShows2: async (itemId: string, excludeArtistIds?: string, userId?: string, limit?: number, fields?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'itemId' is not null or undefined
            if (itemId === null || itemId === undefined) {
                throw new RequiredError('itemId','Required parameter itemId was null or undefined when calling getSimilarShows2.');
            }
            const localVarPath = `/Shows/{itemId}/Similar`
                .replace(`{${"itemId"}}`, encodeURIComponent(String(itemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }

            if (excludeArtistIds !== undefined) {
                localVarQueryParameter['excludeArtistIds'] = excludeArtistIds;
            }

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (fields !== undefined) {
                localVarQueryParameter['fields'] = fields;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets similar items.
         * @param {string} itemId The item id.
         * @param {string} [excludeArtistIds] Exclude artist ids.
         * @param {string} [userId] Optional. Filter by user id, and attach user data.
         * @param {number} [limit] Optional. The maximum number of records to return.
         * @param {string} [fields] Optional. Specify additional fields of information to return in the output. This allows multiple, comma delimited. Options: Budget, Chapters, DateCreated, Genres, HomePageUrl, IndexOptions, MediaStreams, Overview, ParentId, Path, People, ProviderIds, PrimaryImageAspectRatio, Revenue, SortName, Studios, Taglines, TrailerUrls.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSimilarTrailers2: async (itemId: string, excludeArtistIds?: string, userId?: string, limit?: number, fields?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'itemId' is not null or undefined
            if (itemId === null || itemId === undefined) {
                throw new RequiredError('itemId','Required parameter itemId was null or undefined when calling getSimilarTrailers2.');
            }
            const localVarPath = `/Trailers/{itemId}/Similar`
                .replace(`{${"itemId"}}`, encodeURIComponent(String(itemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }

            if (excludeArtistIds !== undefined) {
                localVarQueryParameter['excludeArtistIds'] = excludeArtistIds;
            }

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (fields !== undefined) {
                localVarQueryParameter['fields'] = fields;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get theme songs and videos for an item.
         * @param {string} itemId The item id.
         * @param {string} [userId] Optional. Filter by user id, and attach user data.
         * @param {boolean} [inheritFromParent] Optional. Determines whether or not parent items should be searched for theme media.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getThemeMedia: async (itemId: string, userId?: string, inheritFromParent?: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'itemId' is not null or undefined
            if (itemId === null || itemId === undefined) {
                throw new RequiredError('itemId','Required parameter itemId was null or undefined when calling getThemeMedia.');
            }
            const localVarPath = `/Items/{itemId}/ThemeMedia`
                .replace(`{${"itemId"}}`, encodeURIComponent(String(itemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }

            if (inheritFromParent !== undefined) {
                localVarQueryParameter['inheritFromParent'] = inheritFromParent;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get theme songs for an item.
         * @param {string} itemId The item id.
         * @param {string} [userId] Optional. Filter by user id, and attach user data.
         * @param {boolean} [inheritFromParent] Optional. Determines whether or not parent items should be searched for theme media.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getThemeSongs: async (itemId: string, userId?: string, inheritFromParent?: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'itemId' is not null or undefined
            if (itemId === null || itemId === undefined) {
                throw new RequiredError('itemId','Required parameter itemId was null or undefined when calling getThemeSongs.');
            }
            const localVarPath = `/Items/{itemId}/ThemeSongs`
                .replace(`{${"itemId"}}`, encodeURIComponent(String(itemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }

            if (inheritFromParent !== undefined) {
                localVarQueryParameter['inheritFromParent'] = inheritFromParent;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get theme videos for an item.
         * @param {string} itemId The item id.
         * @param {string} [userId] Optional. Filter by user id, and attach user data.
         * @param {boolean} [inheritFromParent] Optional. Determines whether or not parent items should be searched for theme media.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getThemeVideos: async (itemId: string, userId?: string, inheritFromParent?: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'itemId' is not null or undefined
            if (itemId === null || itemId === undefined) {
                throw new RequiredError('itemId','Required parameter itemId was null or undefined when calling getThemeVideos.');
            }
            const localVarPath = `/Items/{itemId}/ThemeVideos`
                .replace(`{${"itemId"}}`, encodeURIComponent(String(itemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }

            if (inheritFromParent !== undefined) {
                localVarQueryParameter['inheritFromParent'] = inheritFromParent;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Reports that new movies have been added by an external source.
         * @param {string} [tmdbId] The tmdbId.
         * @param {string} [imdbId] The imdbId.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAddedMovies: async (tmdbId?: string, imdbId?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/Library/Movies/Added`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }

            if (tmdbId !== undefined) {
                localVarQueryParameter['tmdbId'] = tmdbId;
            }

            if (imdbId !== undefined) {
                localVarQueryParameter['imdbId'] = imdbId;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Reports that new episodes of a series have been added by an external source.
         * @param {string} [tvdbId] The tvdbId.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAddedSeries: async (tvdbId?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/Library/Series/Added`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }

            if (tvdbId !== undefined) {
                localVarQueryParameter['tvdbId'] = tvdbId;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Reports that new movies have been added by an external source.
         * @param {Array<MediaUpdateInfoDto>} mediaUpdateInfoDto A list of updated media paths.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postUpdatedMedia: async (mediaUpdateInfoDto: Array<MediaUpdateInfoDto>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'mediaUpdateInfoDto' is not null or undefined
            if (mediaUpdateInfoDto === null || mediaUpdateInfoDto === undefined) {
                throw new RequiredError('mediaUpdateInfoDto','Required parameter mediaUpdateInfoDto was null or undefined when calling postUpdatedMedia.');
            }
            const localVarPath = `/Library/Media/Updated`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof mediaUpdateInfoDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(mediaUpdateInfoDto !== undefined ? mediaUpdateInfoDto : {}) : (mediaUpdateInfoDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Reports that new movies have been added by an external source.
         * @param {string} [tmdbId] The tmdbId.
         * @param {string} [imdbId] The imdbId.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postUpdatedMovies: async (tmdbId?: string, imdbId?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/Library/Movies/Updated`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }

            if (tmdbId !== undefined) {
                localVarQueryParameter['tmdbId'] = tmdbId;
            }

            if (imdbId !== undefined) {
                localVarQueryParameter['imdbId'] = imdbId;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Reports that new episodes of a series have been added by an external source.
         * @param {string} [tvdbId] The tvdbId.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postUpdatedSeries: async (tvdbId?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/Library/Series/Updated`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }

            if (tvdbId !== undefined) {
                localVarQueryParameter['tvdbId'] = tvdbId;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Starts a library scan.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshLibrary: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/Library/Refresh`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LibraryApi - functional programming interface
 * @export
 */
export const LibraryApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Deletes an item from the library and filesystem.
         * @param {string} itemId The item id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteItem(itemId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await LibraryApiAxiosParamCreator(configuration).deleteItem(itemId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Deletes items from the library and filesystem.
         * @param {string} [ids] The item ids.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteItems(ids?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await LibraryApiAxiosParamCreator(configuration).deleteItems(ids, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Gets all parents of an item.
         * @param {string} itemId The item id.
         * @param {string} [userId] Optional. Filter by user id, and attach user data.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAncestors(itemId: string, userId?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BaseItemDto>>> {
            const localVarAxiosArgs = await LibraryApiAxiosParamCreator(configuration).getAncestors(itemId, userId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Gets critic review for an item.
         * @param {string} itemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCriticReviews(itemId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BaseItemDtoQueryResult>> {
            const localVarAxiosArgs = await LibraryApiAxiosParamCreator(configuration).getCriticReviews(itemId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Downloads item media.
         * @param {string} itemId The item id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDownload(itemId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await LibraryApiAxiosParamCreator(configuration).getDownload(itemId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get the original file of an item.
         * @param {string} itemId The item id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFile(itemId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await LibraryApiAxiosParamCreator(configuration).getFile(itemId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get item counts.
         * @param {string} [userId] Optional. Get counts from a specific user\&#39;s library.
         * @param {boolean} [isFavorite] Optional. Get counts of favorite items.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getItemCounts(userId?: string, isFavorite?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ItemCounts>> {
            const localVarAxiosArgs = await LibraryApiAxiosParamCreator(configuration).getItemCounts(userId, isFavorite, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Gets the library options info.
         * @param {string} [libraryContentType] Library content type.
         * @param {boolean} [isNewLibrary] Whether this is a new library.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLibraryOptionsInfo(libraryContentType?: string, isNewLibrary?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LibraryOptionsResultDto>> {
            const localVarAxiosArgs = await LibraryApiAxiosParamCreator(configuration).getLibraryOptionsInfo(libraryContentType, isNewLibrary, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Gets all user media folders.
         * @param {boolean} [isHidden] Optional. Filter by folders that are marked hidden, or not.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMediaFolders(isHidden?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BaseItemDtoQueryResult>> {
            const localVarAxiosArgs = await LibraryApiAxiosParamCreator(configuration).getMediaFolders(isHidden, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Gets a list of physical paths from virtual folders.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPhysicalPaths(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await LibraryApiAxiosParamCreator(configuration).getPhysicalPaths(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Gets similar items.
         * @param {string} itemId The item id.
         * @param {string} [excludeArtistIds] Exclude artist ids.
         * @param {string} [userId] Optional. Filter by user id, and attach user data.
         * @param {number} [limit] Optional. The maximum number of records to return.
         * @param {string} [fields] Optional. Specify additional fields of information to return in the output. This allows multiple, comma delimited. Options: Budget, Chapters, DateCreated, Genres, HomePageUrl, IndexOptions, MediaStreams, Overview, ParentId, Path, People, ProviderIds, PrimaryImageAspectRatio, Revenue, SortName, Studios, Taglines, TrailerUrls.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSimilarAlbums2(itemId: string, excludeArtistIds?: string, userId?: string, limit?: number, fields?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BaseItemDtoQueryResult>> {
            const localVarAxiosArgs = await LibraryApiAxiosParamCreator(configuration).getSimilarAlbums2(itemId, excludeArtistIds, userId, limit, fields, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Gets similar items.
         * @param {string} itemId The item id.
         * @param {string} [excludeArtistIds] Exclude artist ids.
         * @param {string} [userId] Optional. Filter by user id, and attach user data.
         * @param {number} [limit] Optional. The maximum number of records to return.
         * @param {string} [fields] Optional. Specify additional fields of information to return in the output. This allows multiple, comma delimited. Options: Budget, Chapters, DateCreated, Genres, HomePageUrl, IndexOptions, MediaStreams, Overview, ParentId, Path, People, ProviderIds, PrimaryImageAspectRatio, Revenue, SortName, Studios, Taglines, TrailerUrls.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSimilarArtists2(itemId: string, excludeArtistIds?: string, userId?: string, limit?: number, fields?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BaseItemDtoQueryResult>> {
            const localVarAxiosArgs = await LibraryApiAxiosParamCreator(configuration).getSimilarArtists2(itemId, excludeArtistIds, userId, limit, fields, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Gets similar items.
         * @param {string} itemId The item id.
         * @param {string} [excludeArtistIds] Exclude artist ids.
         * @param {string} [userId] Optional. Filter by user id, and attach user data.
         * @param {number} [limit] Optional. The maximum number of records to return.
         * @param {string} [fields] Optional. Specify additional fields of information to return in the output. This allows multiple, comma delimited. Options: Budget, Chapters, DateCreated, Genres, HomePageUrl, IndexOptions, MediaStreams, Overview, ParentId, Path, People, ProviderIds, PrimaryImageAspectRatio, Revenue, SortName, Studios, Taglines, TrailerUrls.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSimilarItems(itemId: string, excludeArtistIds?: string, userId?: string, limit?: number, fields?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BaseItemDtoQueryResult>> {
            const localVarAxiosArgs = await LibraryApiAxiosParamCreator(configuration).getSimilarItems(itemId, excludeArtistIds, userId, limit, fields, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Gets similar items.
         * @param {string} itemId The item id.
         * @param {string} [excludeArtistIds] Exclude artist ids.
         * @param {string} [userId] Optional. Filter by user id, and attach user data.
         * @param {number} [limit] Optional. The maximum number of records to return.
         * @param {string} [fields] Optional. Specify additional fields of information to return in the output. This allows multiple, comma delimited. Options: Budget, Chapters, DateCreated, Genres, HomePageUrl, IndexOptions, MediaStreams, Overview, ParentId, Path, People, ProviderIds, PrimaryImageAspectRatio, Revenue, SortName, Studios, Taglines, TrailerUrls.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSimilarMovies2(itemId: string, excludeArtistIds?: string, userId?: string, limit?: number, fields?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BaseItemDtoQueryResult>> {
            const localVarAxiosArgs = await LibraryApiAxiosParamCreator(configuration).getSimilarMovies2(itemId, excludeArtistIds, userId, limit, fields, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Gets similar items.
         * @param {string} itemId The item id.
         * @param {string} [excludeArtistIds] Exclude artist ids.
         * @param {string} [userId] Optional. Filter by user id, and attach user data.
         * @param {number} [limit] Optional. The maximum number of records to return.
         * @param {string} [fields] Optional. Specify additional fields of information to return in the output. This allows multiple, comma delimited. Options: Budget, Chapters, DateCreated, Genres, HomePageUrl, IndexOptions, MediaStreams, Overview, ParentId, Path, People, ProviderIds, PrimaryImageAspectRatio, Revenue, SortName, Studios, Taglines, TrailerUrls.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSimilarShows2(itemId: string, excludeArtistIds?: string, userId?: string, limit?: number, fields?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BaseItemDtoQueryResult>> {
            const localVarAxiosArgs = await LibraryApiAxiosParamCreator(configuration).getSimilarShows2(itemId, excludeArtistIds, userId, limit, fields, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Gets similar items.
         * @param {string} itemId The item id.
         * @param {string} [excludeArtistIds] Exclude artist ids.
         * @param {string} [userId] Optional. Filter by user id, and attach user data.
         * @param {number} [limit] Optional. The maximum number of records to return.
         * @param {string} [fields] Optional. Specify additional fields of information to return in the output. This allows multiple, comma delimited. Options: Budget, Chapters, DateCreated, Genres, HomePageUrl, IndexOptions, MediaStreams, Overview, ParentId, Path, People, ProviderIds, PrimaryImageAspectRatio, Revenue, SortName, Studios, Taglines, TrailerUrls.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSimilarTrailers2(itemId: string, excludeArtistIds?: string, userId?: string, limit?: number, fields?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BaseItemDtoQueryResult>> {
            const localVarAxiosArgs = await LibraryApiAxiosParamCreator(configuration).getSimilarTrailers2(itemId, excludeArtistIds, userId, limit, fields, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get theme songs and videos for an item.
         * @param {string} itemId The item id.
         * @param {string} [userId] Optional. Filter by user id, and attach user data.
         * @param {boolean} [inheritFromParent] Optional. Determines whether or not parent items should be searched for theme media.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getThemeMedia(itemId: string, userId?: string, inheritFromParent?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AllThemeMediaResult>> {
            const localVarAxiosArgs = await LibraryApiAxiosParamCreator(configuration).getThemeMedia(itemId, userId, inheritFromParent, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get theme songs for an item.
         * @param {string} itemId The item id.
         * @param {string} [userId] Optional. Filter by user id, and attach user data.
         * @param {boolean} [inheritFromParent] Optional. Determines whether or not parent items should be searched for theme media.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getThemeSongs(itemId: string, userId?: string, inheritFromParent?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ThemeMediaResult>> {
            const localVarAxiosArgs = await LibraryApiAxiosParamCreator(configuration).getThemeSongs(itemId, userId, inheritFromParent, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get theme videos for an item.
         * @param {string} itemId The item id.
         * @param {string} [userId] Optional. Filter by user id, and attach user data.
         * @param {boolean} [inheritFromParent] Optional. Determines whether or not parent items should be searched for theme media.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getThemeVideos(itemId: string, userId?: string, inheritFromParent?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ThemeMediaResult>> {
            const localVarAxiosArgs = await LibraryApiAxiosParamCreator(configuration).getThemeVideos(itemId, userId, inheritFromParent, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Reports that new movies have been added by an external source.
         * @param {string} [tmdbId] The tmdbId.
         * @param {string} [imdbId] The imdbId.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postAddedMovies(tmdbId?: string, imdbId?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await LibraryApiAxiosParamCreator(configuration).postAddedMovies(tmdbId, imdbId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Reports that new episodes of a series have been added by an external source.
         * @param {string} [tvdbId] The tvdbId.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postAddedSeries(tvdbId?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await LibraryApiAxiosParamCreator(configuration).postAddedSeries(tvdbId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Reports that new movies have been added by an external source.
         * @param {Array<MediaUpdateInfoDto>} mediaUpdateInfoDto A list of updated media paths.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postUpdatedMedia(mediaUpdateInfoDto: Array<MediaUpdateInfoDto>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await LibraryApiAxiosParamCreator(configuration).postUpdatedMedia(mediaUpdateInfoDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Reports that new movies have been added by an external source.
         * @param {string} [tmdbId] The tmdbId.
         * @param {string} [imdbId] The imdbId.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postUpdatedMovies(tmdbId?: string, imdbId?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await LibraryApiAxiosParamCreator(configuration).postUpdatedMovies(tmdbId, imdbId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Reports that new episodes of a series have been added by an external source.
         * @param {string} [tvdbId] The tvdbId.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postUpdatedSeries(tvdbId?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await LibraryApiAxiosParamCreator(configuration).postUpdatedSeries(tvdbId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Starts a library scan.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async refreshLibrary(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await LibraryApiAxiosParamCreator(configuration).refreshLibrary(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * LibraryApi - factory interface
 * @export
 */
export const LibraryApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Deletes an item from the library and filesystem.
         * @param {string} itemId The item id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteItem(itemId: string, options?: any): AxiosPromise<void> {
            return LibraryApiFp(configuration).deleteItem(itemId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Deletes items from the library and filesystem.
         * @param {string} [ids] The item ids.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteItems(ids?: string, options?: any): AxiosPromise<void> {
            return LibraryApiFp(configuration).deleteItems(ids, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets all parents of an item.
         * @param {string} itemId The item id.
         * @param {string} [userId] Optional. Filter by user id, and attach user data.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAncestors(itemId: string, userId?: string, options?: any): AxiosPromise<Array<BaseItemDto>> {
            return LibraryApiFp(configuration).getAncestors(itemId, userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets critic review for an item.
         * @param {string} itemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCriticReviews(itemId: string, options?: any): AxiosPromise<BaseItemDtoQueryResult> {
            return LibraryApiFp(configuration).getCriticReviews(itemId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Downloads item media.
         * @param {string} itemId The item id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDownload(itemId: string, options?: any): AxiosPromise<any> {
            return LibraryApiFp(configuration).getDownload(itemId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the original file of an item.
         * @param {string} itemId The item id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFile(itemId: string, options?: any): AxiosPromise<any> {
            return LibraryApiFp(configuration).getFile(itemId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get item counts.
         * @param {string} [userId] Optional. Get counts from a specific user\&#39;s library.
         * @param {boolean} [isFavorite] Optional. Get counts of favorite items.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getItemCounts(userId?: string, isFavorite?: boolean, options?: any): AxiosPromise<ItemCounts> {
            return LibraryApiFp(configuration).getItemCounts(userId, isFavorite, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets the library options info.
         * @param {string} [libraryContentType] Library content type.
         * @param {boolean} [isNewLibrary] Whether this is a new library.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLibraryOptionsInfo(libraryContentType?: string, isNewLibrary?: boolean, options?: any): AxiosPromise<LibraryOptionsResultDto> {
            return LibraryApiFp(configuration).getLibraryOptionsInfo(libraryContentType, isNewLibrary, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets all user media folders.
         * @param {boolean} [isHidden] Optional. Filter by folders that are marked hidden, or not.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMediaFolders(isHidden?: boolean, options?: any): AxiosPromise<BaseItemDtoQueryResult> {
            return LibraryApiFp(configuration).getMediaFolders(isHidden, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets a list of physical paths from virtual folders.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPhysicalPaths(options?: any): AxiosPromise<Array<string>> {
            return LibraryApiFp(configuration).getPhysicalPaths(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets similar items.
         * @param {string} itemId The item id.
         * @param {string} [excludeArtistIds] Exclude artist ids.
         * @param {string} [userId] Optional. Filter by user id, and attach user data.
         * @param {number} [limit] Optional. The maximum number of records to return.
         * @param {string} [fields] Optional. Specify additional fields of information to return in the output. This allows multiple, comma delimited. Options: Budget, Chapters, DateCreated, Genres, HomePageUrl, IndexOptions, MediaStreams, Overview, ParentId, Path, People, ProviderIds, PrimaryImageAspectRatio, Revenue, SortName, Studios, Taglines, TrailerUrls.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSimilarAlbums2(itemId: string, excludeArtistIds?: string, userId?: string, limit?: number, fields?: string, options?: any): AxiosPromise<BaseItemDtoQueryResult> {
            return LibraryApiFp(configuration).getSimilarAlbums2(itemId, excludeArtistIds, userId, limit, fields, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets similar items.
         * @param {string} itemId The item id.
         * @param {string} [excludeArtistIds] Exclude artist ids.
         * @param {string} [userId] Optional. Filter by user id, and attach user data.
         * @param {number} [limit] Optional. The maximum number of records to return.
         * @param {string} [fields] Optional. Specify additional fields of information to return in the output. This allows multiple, comma delimited. Options: Budget, Chapters, DateCreated, Genres, HomePageUrl, IndexOptions, MediaStreams, Overview, ParentId, Path, People, ProviderIds, PrimaryImageAspectRatio, Revenue, SortName, Studios, Taglines, TrailerUrls.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSimilarArtists2(itemId: string, excludeArtistIds?: string, userId?: string, limit?: number, fields?: string, options?: any): AxiosPromise<BaseItemDtoQueryResult> {
            return LibraryApiFp(configuration).getSimilarArtists2(itemId, excludeArtistIds, userId, limit, fields, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets similar items.
         * @param {string} itemId The item id.
         * @param {string} [excludeArtistIds] Exclude artist ids.
         * @param {string} [userId] Optional. Filter by user id, and attach user data.
         * @param {number} [limit] Optional. The maximum number of records to return.
         * @param {string} [fields] Optional. Specify additional fields of information to return in the output. This allows multiple, comma delimited. Options: Budget, Chapters, DateCreated, Genres, HomePageUrl, IndexOptions, MediaStreams, Overview, ParentId, Path, People, ProviderIds, PrimaryImageAspectRatio, Revenue, SortName, Studios, Taglines, TrailerUrls.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSimilarItems(itemId: string, excludeArtistIds?: string, userId?: string, limit?: number, fields?: string, options?: any): AxiosPromise<BaseItemDtoQueryResult> {
            return LibraryApiFp(configuration).getSimilarItems(itemId, excludeArtistIds, userId, limit, fields, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets similar items.
         * @param {string} itemId The item id.
         * @param {string} [excludeArtistIds] Exclude artist ids.
         * @param {string} [userId] Optional. Filter by user id, and attach user data.
         * @param {number} [limit] Optional. The maximum number of records to return.
         * @param {string} [fields] Optional. Specify additional fields of information to return in the output. This allows multiple, comma delimited. Options: Budget, Chapters, DateCreated, Genres, HomePageUrl, IndexOptions, MediaStreams, Overview, ParentId, Path, People, ProviderIds, PrimaryImageAspectRatio, Revenue, SortName, Studios, Taglines, TrailerUrls.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSimilarMovies2(itemId: string, excludeArtistIds?: string, userId?: string, limit?: number, fields?: string, options?: any): AxiosPromise<BaseItemDtoQueryResult> {
            return LibraryApiFp(configuration).getSimilarMovies2(itemId, excludeArtistIds, userId, limit, fields, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets similar items.
         * @param {string} itemId The item id.
         * @param {string} [excludeArtistIds] Exclude artist ids.
         * @param {string} [userId] Optional. Filter by user id, and attach user data.
         * @param {number} [limit] Optional. The maximum number of records to return.
         * @param {string} [fields] Optional. Specify additional fields of information to return in the output. This allows multiple, comma delimited. Options: Budget, Chapters, DateCreated, Genres, HomePageUrl, IndexOptions, MediaStreams, Overview, ParentId, Path, People, ProviderIds, PrimaryImageAspectRatio, Revenue, SortName, Studios, Taglines, TrailerUrls.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSimilarShows2(itemId: string, excludeArtistIds?: string, userId?: string, limit?: number, fields?: string, options?: any): AxiosPromise<BaseItemDtoQueryResult> {
            return LibraryApiFp(configuration).getSimilarShows2(itemId, excludeArtistIds, userId, limit, fields, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets similar items.
         * @param {string} itemId The item id.
         * @param {string} [excludeArtistIds] Exclude artist ids.
         * @param {string} [userId] Optional. Filter by user id, and attach user data.
         * @param {number} [limit] Optional. The maximum number of records to return.
         * @param {string} [fields] Optional. Specify additional fields of information to return in the output. This allows multiple, comma delimited. Options: Budget, Chapters, DateCreated, Genres, HomePageUrl, IndexOptions, MediaStreams, Overview, ParentId, Path, People, ProviderIds, PrimaryImageAspectRatio, Revenue, SortName, Studios, Taglines, TrailerUrls.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSimilarTrailers2(itemId: string, excludeArtistIds?: string, userId?: string, limit?: number, fields?: string, options?: any): AxiosPromise<BaseItemDtoQueryResult> {
            return LibraryApiFp(configuration).getSimilarTrailers2(itemId, excludeArtistIds, userId, limit, fields, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get theme songs and videos for an item.
         * @param {string} itemId The item id.
         * @param {string} [userId] Optional. Filter by user id, and attach user data.
         * @param {boolean} [inheritFromParent] Optional. Determines whether or not parent items should be searched for theme media.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getThemeMedia(itemId: string, userId?: string, inheritFromParent?: boolean, options?: any): AxiosPromise<AllThemeMediaResult> {
            return LibraryApiFp(configuration).getThemeMedia(itemId, userId, inheritFromParent, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get theme songs for an item.
         * @param {string} itemId The item id.
         * @param {string} [userId] Optional. Filter by user id, and attach user data.
         * @param {boolean} [inheritFromParent] Optional. Determines whether or not parent items should be searched for theme media.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getThemeSongs(itemId: string, userId?: string, inheritFromParent?: boolean, options?: any): AxiosPromise<ThemeMediaResult> {
            return LibraryApiFp(configuration).getThemeSongs(itemId, userId, inheritFromParent, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get theme videos for an item.
         * @param {string} itemId The item id.
         * @param {string} [userId] Optional. Filter by user id, and attach user data.
         * @param {boolean} [inheritFromParent] Optional. Determines whether or not parent items should be searched for theme media.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getThemeVideos(itemId: string, userId?: string, inheritFromParent?: boolean, options?: any): AxiosPromise<ThemeMediaResult> {
            return LibraryApiFp(configuration).getThemeVideos(itemId, userId, inheritFromParent, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Reports that new movies have been added by an external source.
         * @param {string} [tmdbId] The tmdbId.
         * @param {string} [imdbId] The imdbId.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAddedMovies(tmdbId?: string, imdbId?: string, options?: any): AxiosPromise<void> {
            return LibraryApiFp(configuration).postAddedMovies(tmdbId, imdbId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Reports that new episodes of a series have been added by an external source.
         * @param {string} [tvdbId] The tvdbId.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAddedSeries(tvdbId?: string, options?: any): AxiosPromise<void> {
            return LibraryApiFp(configuration).postAddedSeries(tvdbId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Reports that new movies have been added by an external source.
         * @param {Array<MediaUpdateInfoDto>} mediaUpdateInfoDto A list of updated media paths.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postUpdatedMedia(mediaUpdateInfoDto: Array<MediaUpdateInfoDto>, options?: any): AxiosPromise<void> {
            return LibraryApiFp(configuration).postUpdatedMedia(mediaUpdateInfoDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Reports that new movies have been added by an external source.
         * @param {string} [tmdbId] The tmdbId.
         * @param {string} [imdbId] The imdbId.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postUpdatedMovies(tmdbId?: string, imdbId?: string, options?: any): AxiosPromise<void> {
            return LibraryApiFp(configuration).postUpdatedMovies(tmdbId, imdbId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Reports that new episodes of a series have been added by an external source.
         * @param {string} [tvdbId] The tvdbId.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postUpdatedSeries(tvdbId?: string, options?: any): AxiosPromise<void> {
            return LibraryApiFp(configuration).postUpdatedSeries(tvdbId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Starts a library scan.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshLibrary(options?: any): AxiosPromise<void> {
            return LibraryApiFp(configuration).refreshLibrary(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for deleteItem operation in LibraryApi.
 * @export
 * @interface LibraryApiDeleteItemRequest
 */
export interface LibraryApiDeleteItemRequest {
    /**
     * The item id.
     * @type {string}
     * @memberof LibraryApiDeleteItem
     */
    readonly itemId: string
}

/**
 * Request parameters for deleteItems operation in LibraryApi.
 * @export
 * @interface LibraryApiDeleteItemsRequest
 */
export interface LibraryApiDeleteItemsRequest {
    /**
     * The item ids.
     * @type {string}
     * @memberof LibraryApiDeleteItems
     */
    readonly ids?: string
}

/**
 * Request parameters for getAncestors operation in LibraryApi.
 * @export
 * @interface LibraryApiGetAncestorsRequest
 */
export interface LibraryApiGetAncestorsRequest {
    /**
     * The item id.
     * @type {string}
     * @memberof LibraryApiGetAncestors
     */
    readonly itemId: string

    /**
     * Optional. Filter by user id, and attach user data.
     * @type {string}
     * @memberof LibraryApiGetAncestors
     */
    readonly userId?: string
}

/**
 * Request parameters for getCriticReviews operation in LibraryApi.
 * @export
 * @interface LibraryApiGetCriticReviewsRequest
 */
export interface LibraryApiGetCriticReviewsRequest {
    /**
     * 
     * @type {string}
     * @memberof LibraryApiGetCriticReviews
     */
    readonly itemId: string
}

/**
 * Request parameters for getDownload operation in LibraryApi.
 * @export
 * @interface LibraryApiGetDownloadRequest
 */
export interface LibraryApiGetDownloadRequest {
    /**
     * The item id.
     * @type {string}
     * @memberof LibraryApiGetDownload
     */
    readonly itemId: string
}

/**
 * Request parameters for getFile operation in LibraryApi.
 * @export
 * @interface LibraryApiGetFileRequest
 */
export interface LibraryApiGetFileRequest {
    /**
     * The item id.
     * @type {string}
     * @memberof LibraryApiGetFile
     */
    readonly itemId: string
}

/**
 * Request parameters for getItemCounts operation in LibraryApi.
 * @export
 * @interface LibraryApiGetItemCountsRequest
 */
export interface LibraryApiGetItemCountsRequest {
    /**
     * Optional. Get counts from a specific user\&#39;s library.
     * @type {string}
     * @memberof LibraryApiGetItemCounts
     */
    readonly userId?: string

    /**
     * Optional. Get counts of favorite items.
     * @type {boolean}
     * @memberof LibraryApiGetItemCounts
     */
    readonly isFavorite?: boolean
}

/**
 * Request parameters for getLibraryOptionsInfo operation in LibraryApi.
 * @export
 * @interface LibraryApiGetLibraryOptionsInfoRequest
 */
export interface LibraryApiGetLibraryOptionsInfoRequest {
    /**
     * Library content type.
     * @type {string}
     * @memberof LibraryApiGetLibraryOptionsInfo
     */
    readonly libraryContentType?: string

    /**
     * Whether this is a new library.
     * @type {boolean}
     * @memberof LibraryApiGetLibraryOptionsInfo
     */
    readonly isNewLibrary?: boolean
}

/**
 * Request parameters for getMediaFolders operation in LibraryApi.
 * @export
 * @interface LibraryApiGetMediaFoldersRequest
 */
export interface LibraryApiGetMediaFoldersRequest {
    /**
     * Optional. Filter by folders that are marked hidden, or not.
     * @type {boolean}
     * @memberof LibraryApiGetMediaFolders
     */
    readonly isHidden?: boolean
}

/**
 * Request parameters for getSimilarAlbums2 operation in LibraryApi.
 * @export
 * @interface LibraryApiGetSimilarAlbums2Request
 */
export interface LibraryApiGetSimilarAlbums2Request {
    /**
     * The item id.
     * @type {string}
     * @memberof LibraryApiGetSimilarAlbums2
     */
    readonly itemId: string

    /**
     * Exclude artist ids.
     * @type {string}
     * @memberof LibraryApiGetSimilarAlbums2
     */
    readonly excludeArtistIds?: string

    /**
     * Optional. Filter by user id, and attach user data.
     * @type {string}
     * @memberof LibraryApiGetSimilarAlbums2
     */
    readonly userId?: string

    /**
     * Optional. The maximum number of records to return.
     * @type {number}
     * @memberof LibraryApiGetSimilarAlbums2
     */
    readonly limit?: number

    /**
     * Optional. Specify additional fields of information to return in the output. This allows multiple, comma delimited. Options: Budget, Chapters, DateCreated, Genres, HomePageUrl, IndexOptions, MediaStreams, Overview, ParentId, Path, People, ProviderIds, PrimaryImageAspectRatio, Revenue, SortName, Studios, Taglines, TrailerUrls.
     * @type {string}
     * @memberof LibraryApiGetSimilarAlbums2
     */
    readonly fields?: string
}

/**
 * Request parameters for getSimilarArtists2 operation in LibraryApi.
 * @export
 * @interface LibraryApiGetSimilarArtists2Request
 */
export interface LibraryApiGetSimilarArtists2Request {
    /**
     * The item id.
     * @type {string}
     * @memberof LibraryApiGetSimilarArtists2
     */
    readonly itemId: string

    /**
     * Exclude artist ids.
     * @type {string}
     * @memberof LibraryApiGetSimilarArtists2
     */
    readonly excludeArtistIds?: string

    /**
     * Optional. Filter by user id, and attach user data.
     * @type {string}
     * @memberof LibraryApiGetSimilarArtists2
     */
    readonly userId?: string

    /**
     * Optional. The maximum number of records to return.
     * @type {number}
     * @memberof LibraryApiGetSimilarArtists2
     */
    readonly limit?: number

    /**
     * Optional. Specify additional fields of information to return in the output. This allows multiple, comma delimited. Options: Budget, Chapters, DateCreated, Genres, HomePageUrl, IndexOptions, MediaStreams, Overview, ParentId, Path, People, ProviderIds, PrimaryImageAspectRatio, Revenue, SortName, Studios, Taglines, TrailerUrls.
     * @type {string}
     * @memberof LibraryApiGetSimilarArtists2
     */
    readonly fields?: string
}

/**
 * Request parameters for getSimilarItems operation in LibraryApi.
 * @export
 * @interface LibraryApiGetSimilarItemsRequest
 */
export interface LibraryApiGetSimilarItemsRequest {
    /**
     * The item id.
     * @type {string}
     * @memberof LibraryApiGetSimilarItems
     */
    readonly itemId: string

    /**
     * Exclude artist ids.
     * @type {string}
     * @memberof LibraryApiGetSimilarItems
     */
    readonly excludeArtistIds?: string

    /**
     * Optional. Filter by user id, and attach user data.
     * @type {string}
     * @memberof LibraryApiGetSimilarItems
     */
    readonly userId?: string

    /**
     * Optional. The maximum number of records to return.
     * @type {number}
     * @memberof LibraryApiGetSimilarItems
     */
    readonly limit?: number

    /**
     * Optional. Specify additional fields of information to return in the output. This allows multiple, comma delimited. Options: Budget, Chapters, DateCreated, Genres, HomePageUrl, IndexOptions, MediaStreams, Overview, ParentId, Path, People, ProviderIds, PrimaryImageAspectRatio, Revenue, SortName, Studios, Taglines, TrailerUrls.
     * @type {string}
     * @memberof LibraryApiGetSimilarItems
     */
    readonly fields?: string
}

/**
 * Request parameters for getSimilarMovies2 operation in LibraryApi.
 * @export
 * @interface LibraryApiGetSimilarMovies2Request
 */
export interface LibraryApiGetSimilarMovies2Request {
    /**
     * The item id.
     * @type {string}
     * @memberof LibraryApiGetSimilarMovies2
     */
    readonly itemId: string

    /**
     * Exclude artist ids.
     * @type {string}
     * @memberof LibraryApiGetSimilarMovies2
     */
    readonly excludeArtistIds?: string

    /**
     * Optional. Filter by user id, and attach user data.
     * @type {string}
     * @memberof LibraryApiGetSimilarMovies2
     */
    readonly userId?: string

    /**
     * Optional. The maximum number of records to return.
     * @type {number}
     * @memberof LibraryApiGetSimilarMovies2
     */
    readonly limit?: number

    /**
     * Optional. Specify additional fields of information to return in the output. This allows multiple, comma delimited. Options: Budget, Chapters, DateCreated, Genres, HomePageUrl, IndexOptions, MediaStreams, Overview, ParentId, Path, People, ProviderIds, PrimaryImageAspectRatio, Revenue, SortName, Studios, Taglines, TrailerUrls.
     * @type {string}
     * @memberof LibraryApiGetSimilarMovies2
     */
    readonly fields?: string
}

/**
 * Request parameters for getSimilarShows2 operation in LibraryApi.
 * @export
 * @interface LibraryApiGetSimilarShows2Request
 */
export interface LibraryApiGetSimilarShows2Request {
    /**
     * The item id.
     * @type {string}
     * @memberof LibraryApiGetSimilarShows2
     */
    readonly itemId: string

    /**
     * Exclude artist ids.
     * @type {string}
     * @memberof LibraryApiGetSimilarShows2
     */
    readonly excludeArtistIds?: string

    /**
     * Optional. Filter by user id, and attach user data.
     * @type {string}
     * @memberof LibraryApiGetSimilarShows2
     */
    readonly userId?: string

    /**
     * Optional. The maximum number of records to return.
     * @type {number}
     * @memberof LibraryApiGetSimilarShows2
     */
    readonly limit?: number

    /**
     * Optional. Specify additional fields of information to return in the output. This allows multiple, comma delimited. Options: Budget, Chapters, DateCreated, Genres, HomePageUrl, IndexOptions, MediaStreams, Overview, ParentId, Path, People, ProviderIds, PrimaryImageAspectRatio, Revenue, SortName, Studios, Taglines, TrailerUrls.
     * @type {string}
     * @memberof LibraryApiGetSimilarShows2
     */
    readonly fields?: string
}

/**
 * Request parameters for getSimilarTrailers2 operation in LibraryApi.
 * @export
 * @interface LibraryApiGetSimilarTrailers2Request
 */
export interface LibraryApiGetSimilarTrailers2Request {
    /**
     * The item id.
     * @type {string}
     * @memberof LibraryApiGetSimilarTrailers2
     */
    readonly itemId: string

    /**
     * Exclude artist ids.
     * @type {string}
     * @memberof LibraryApiGetSimilarTrailers2
     */
    readonly excludeArtistIds?: string

    /**
     * Optional. Filter by user id, and attach user data.
     * @type {string}
     * @memberof LibraryApiGetSimilarTrailers2
     */
    readonly userId?: string

    /**
     * Optional. The maximum number of records to return.
     * @type {number}
     * @memberof LibraryApiGetSimilarTrailers2
     */
    readonly limit?: number

    /**
     * Optional. Specify additional fields of information to return in the output. This allows multiple, comma delimited. Options: Budget, Chapters, DateCreated, Genres, HomePageUrl, IndexOptions, MediaStreams, Overview, ParentId, Path, People, ProviderIds, PrimaryImageAspectRatio, Revenue, SortName, Studios, Taglines, TrailerUrls.
     * @type {string}
     * @memberof LibraryApiGetSimilarTrailers2
     */
    readonly fields?: string
}

/**
 * Request parameters for getThemeMedia operation in LibraryApi.
 * @export
 * @interface LibraryApiGetThemeMediaRequest
 */
export interface LibraryApiGetThemeMediaRequest {
    /**
     * The item id.
     * @type {string}
     * @memberof LibraryApiGetThemeMedia
     */
    readonly itemId: string

    /**
     * Optional. Filter by user id, and attach user data.
     * @type {string}
     * @memberof LibraryApiGetThemeMedia
     */
    readonly userId?: string

    /**
     * Optional. Determines whether or not parent items should be searched for theme media.
     * @type {boolean}
     * @memberof LibraryApiGetThemeMedia
     */
    readonly inheritFromParent?: boolean
}

/**
 * Request parameters for getThemeSongs operation in LibraryApi.
 * @export
 * @interface LibraryApiGetThemeSongsRequest
 */
export interface LibraryApiGetThemeSongsRequest {
    /**
     * The item id.
     * @type {string}
     * @memberof LibraryApiGetThemeSongs
     */
    readonly itemId: string

    /**
     * Optional. Filter by user id, and attach user data.
     * @type {string}
     * @memberof LibraryApiGetThemeSongs
     */
    readonly userId?: string

    /**
     * Optional. Determines whether or not parent items should be searched for theme media.
     * @type {boolean}
     * @memberof LibraryApiGetThemeSongs
     */
    readonly inheritFromParent?: boolean
}

/**
 * Request parameters for getThemeVideos operation in LibraryApi.
 * @export
 * @interface LibraryApiGetThemeVideosRequest
 */
export interface LibraryApiGetThemeVideosRequest {
    /**
     * The item id.
     * @type {string}
     * @memberof LibraryApiGetThemeVideos
     */
    readonly itemId: string

    /**
     * Optional. Filter by user id, and attach user data.
     * @type {string}
     * @memberof LibraryApiGetThemeVideos
     */
    readonly userId?: string

    /**
     * Optional. Determines whether or not parent items should be searched for theme media.
     * @type {boolean}
     * @memberof LibraryApiGetThemeVideos
     */
    readonly inheritFromParent?: boolean
}

/**
 * Request parameters for postAddedMovies operation in LibraryApi.
 * @export
 * @interface LibraryApiPostAddedMoviesRequest
 */
export interface LibraryApiPostAddedMoviesRequest {
    /**
     * The tmdbId.
     * @type {string}
     * @memberof LibraryApiPostAddedMovies
     */
    readonly tmdbId?: string

    /**
     * The imdbId.
     * @type {string}
     * @memberof LibraryApiPostAddedMovies
     */
    readonly imdbId?: string
}

/**
 * Request parameters for postAddedSeries operation in LibraryApi.
 * @export
 * @interface LibraryApiPostAddedSeriesRequest
 */
export interface LibraryApiPostAddedSeriesRequest {
    /**
     * The tvdbId.
     * @type {string}
     * @memberof LibraryApiPostAddedSeries
     */
    readonly tvdbId?: string
}

/**
 * Request parameters for postUpdatedMedia operation in LibraryApi.
 * @export
 * @interface LibraryApiPostUpdatedMediaRequest
 */
export interface LibraryApiPostUpdatedMediaRequest {
    /**
     * A list of updated media paths.
     * @type {Array<MediaUpdateInfoDto>}
     * @memberof LibraryApiPostUpdatedMedia
     */
    readonly mediaUpdateInfoDto: Array<MediaUpdateInfoDto>
}

/**
 * Request parameters for postUpdatedMovies operation in LibraryApi.
 * @export
 * @interface LibraryApiPostUpdatedMoviesRequest
 */
export interface LibraryApiPostUpdatedMoviesRequest {
    /**
     * The tmdbId.
     * @type {string}
     * @memberof LibraryApiPostUpdatedMovies
     */
    readonly tmdbId?: string

    /**
     * The imdbId.
     * @type {string}
     * @memberof LibraryApiPostUpdatedMovies
     */
    readonly imdbId?: string
}

/**
 * Request parameters for postUpdatedSeries operation in LibraryApi.
 * @export
 * @interface LibraryApiPostUpdatedSeriesRequest
 */
export interface LibraryApiPostUpdatedSeriesRequest {
    /**
     * The tvdbId.
     * @type {string}
     * @memberof LibraryApiPostUpdatedSeries
     */
    readonly tvdbId?: string
}

/**
 * LibraryApi - object-oriented interface
 * @export
 * @class LibraryApi
 * @extends {BaseAPI}
 */
export class LibraryApi extends BaseAPI {
    /**
     * 
     * @summary Deletes an item from the library and filesystem.
     * @param {LibraryApiDeleteItemRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LibraryApi
     */
    public deleteItem(requestParameters: LibraryApiDeleteItemRequest, options?: any) {
        return LibraryApiFp(this.configuration).deleteItem(requestParameters.itemId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Deletes items from the library and filesystem.
     * @param {LibraryApiDeleteItemsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LibraryApi
     */
    public deleteItems(requestParameters: LibraryApiDeleteItemsRequest = {}, options?: any) {
        return LibraryApiFp(this.configuration).deleteItems(requestParameters.ids, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets all parents of an item.
     * @param {LibraryApiGetAncestorsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LibraryApi
     */
    public getAncestors(requestParameters: LibraryApiGetAncestorsRequest, options?: any) {
        return LibraryApiFp(this.configuration).getAncestors(requestParameters.itemId, requestParameters.userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets critic review for an item.
     * @param {LibraryApiGetCriticReviewsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LibraryApi
     */
    public getCriticReviews(requestParameters: LibraryApiGetCriticReviewsRequest, options?: any) {
        return LibraryApiFp(this.configuration).getCriticReviews(requestParameters.itemId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Downloads item media.
     * @param {LibraryApiGetDownloadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LibraryApi
     */
    public getDownload(requestParameters: LibraryApiGetDownloadRequest, options?: any) {
        return LibraryApiFp(this.configuration).getDownload(requestParameters.itemId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the original file of an item.
     * @param {LibraryApiGetFileRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LibraryApi
     */
    public getFile(requestParameters: LibraryApiGetFileRequest, options?: any) {
        return LibraryApiFp(this.configuration).getFile(requestParameters.itemId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get item counts.
     * @param {LibraryApiGetItemCountsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LibraryApi
     */
    public getItemCounts(requestParameters: LibraryApiGetItemCountsRequest = {}, options?: any) {
        return LibraryApiFp(this.configuration).getItemCounts(requestParameters.userId, requestParameters.isFavorite, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets the library options info.
     * @param {LibraryApiGetLibraryOptionsInfoRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LibraryApi
     */
    public getLibraryOptionsInfo(requestParameters: LibraryApiGetLibraryOptionsInfoRequest = {}, options?: any) {
        return LibraryApiFp(this.configuration).getLibraryOptionsInfo(requestParameters.libraryContentType, requestParameters.isNewLibrary, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets all user media folders.
     * @param {LibraryApiGetMediaFoldersRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LibraryApi
     */
    public getMediaFolders(requestParameters: LibraryApiGetMediaFoldersRequest = {}, options?: any) {
        return LibraryApiFp(this.configuration).getMediaFolders(requestParameters.isHidden, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets a list of physical paths from virtual folders.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LibraryApi
     */
    public getPhysicalPaths(options?: any) {
        return LibraryApiFp(this.configuration).getPhysicalPaths(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets similar items.
     * @param {LibraryApiGetSimilarAlbums2Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LibraryApi
     */
    public getSimilarAlbums2(requestParameters: LibraryApiGetSimilarAlbums2Request, options?: any) {
        return LibraryApiFp(this.configuration).getSimilarAlbums2(requestParameters.itemId, requestParameters.excludeArtistIds, requestParameters.userId, requestParameters.limit, requestParameters.fields, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets similar items.
     * @param {LibraryApiGetSimilarArtists2Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LibraryApi
     */
    public getSimilarArtists2(requestParameters: LibraryApiGetSimilarArtists2Request, options?: any) {
        return LibraryApiFp(this.configuration).getSimilarArtists2(requestParameters.itemId, requestParameters.excludeArtistIds, requestParameters.userId, requestParameters.limit, requestParameters.fields, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets similar items.
     * @param {LibraryApiGetSimilarItemsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LibraryApi
     */
    public getSimilarItems(requestParameters: LibraryApiGetSimilarItemsRequest, options?: any) {
        return LibraryApiFp(this.configuration).getSimilarItems(requestParameters.itemId, requestParameters.excludeArtistIds, requestParameters.userId, requestParameters.limit, requestParameters.fields, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets similar items.
     * @param {LibraryApiGetSimilarMovies2Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LibraryApi
     */
    public getSimilarMovies2(requestParameters: LibraryApiGetSimilarMovies2Request, options?: any) {
        return LibraryApiFp(this.configuration).getSimilarMovies2(requestParameters.itemId, requestParameters.excludeArtistIds, requestParameters.userId, requestParameters.limit, requestParameters.fields, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets similar items.
     * @param {LibraryApiGetSimilarShows2Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LibraryApi
     */
    public getSimilarShows2(requestParameters: LibraryApiGetSimilarShows2Request, options?: any) {
        return LibraryApiFp(this.configuration).getSimilarShows2(requestParameters.itemId, requestParameters.excludeArtistIds, requestParameters.userId, requestParameters.limit, requestParameters.fields, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets similar items.
     * @param {LibraryApiGetSimilarTrailers2Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LibraryApi
     */
    public getSimilarTrailers2(requestParameters: LibraryApiGetSimilarTrailers2Request, options?: any) {
        return LibraryApiFp(this.configuration).getSimilarTrailers2(requestParameters.itemId, requestParameters.excludeArtistIds, requestParameters.userId, requestParameters.limit, requestParameters.fields, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get theme songs and videos for an item.
     * @param {LibraryApiGetThemeMediaRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LibraryApi
     */
    public getThemeMedia(requestParameters: LibraryApiGetThemeMediaRequest, options?: any) {
        return LibraryApiFp(this.configuration).getThemeMedia(requestParameters.itemId, requestParameters.userId, requestParameters.inheritFromParent, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get theme songs for an item.
     * @param {LibraryApiGetThemeSongsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LibraryApi
     */
    public getThemeSongs(requestParameters: LibraryApiGetThemeSongsRequest, options?: any) {
        return LibraryApiFp(this.configuration).getThemeSongs(requestParameters.itemId, requestParameters.userId, requestParameters.inheritFromParent, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get theme videos for an item.
     * @param {LibraryApiGetThemeVideosRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LibraryApi
     */
    public getThemeVideos(requestParameters: LibraryApiGetThemeVideosRequest, options?: any) {
        return LibraryApiFp(this.configuration).getThemeVideos(requestParameters.itemId, requestParameters.userId, requestParameters.inheritFromParent, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Reports that new movies have been added by an external source.
     * @param {LibraryApiPostAddedMoviesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LibraryApi
     */
    public postAddedMovies(requestParameters: LibraryApiPostAddedMoviesRequest = {}, options?: any) {
        return LibraryApiFp(this.configuration).postAddedMovies(requestParameters.tmdbId, requestParameters.imdbId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Reports that new episodes of a series have been added by an external source.
     * @param {LibraryApiPostAddedSeriesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LibraryApi
     */
    public postAddedSeries(requestParameters: LibraryApiPostAddedSeriesRequest = {}, options?: any) {
        return LibraryApiFp(this.configuration).postAddedSeries(requestParameters.tvdbId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Reports that new movies have been added by an external source.
     * @param {LibraryApiPostUpdatedMediaRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LibraryApi
     */
    public postUpdatedMedia(requestParameters: LibraryApiPostUpdatedMediaRequest, options?: any) {
        return LibraryApiFp(this.configuration).postUpdatedMedia(requestParameters.mediaUpdateInfoDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Reports that new movies have been added by an external source.
     * @param {LibraryApiPostUpdatedMoviesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LibraryApi
     */
    public postUpdatedMovies(requestParameters: LibraryApiPostUpdatedMoviesRequest = {}, options?: any) {
        return LibraryApiFp(this.configuration).postUpdatedMovies(requestParameters.tmdbId, requestParameters.imdbId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Reports that new episodes of a series have been added by an external source.
     * @param {LibraryApiPostUpdatedSeriesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LibraryApi
     */
    public postUpdatedSeries(requestParameters: LibraryApiPostUpdatedSeriesRequest = {}, options?: any) {
        return LibraryApiFp(this.configuration).postUpdatedSeries(requestParameters.tvdbId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Starts a library scan.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LibraryApi
     */
    public refreshLibrary(options?: any) {
        return LibraryApiFp(this.configuration).refreshLibrary(options).then((request) => request(this.axios, this.basePath));
    }
}
