/* tslint:disable */
/* eslint-disable */
/**
 * Jellyfin API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { DisplayPreferencesDto } from '../models';
/**
 * DisplayPreferencesApi - axios parameter creator
 * @export
 */
export const DisplayPreferencesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get Display Preferences.
         * @param {string} displayPreferencesId Display preferences id.
         * @param {string} userId User id.
         * @param {string} client Client.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDisplayPreferences: async (displayPreferencesId: string, userId: string, client: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'displayPreferencesId' is not null or undefined
            if (displayPreferencesId === null || displayPreferencesId === undefined) {
                throw new RequiredError('displayPreferencesId','Required parameter displayPreferencesId was null or undefined when calling getDisplayPreferences.');
            }
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId','Required parameter userId was null or undefined when calling getDisplayPreferences.');
            }
            // verify required parameter 'client' is not null or undefined
            if (client === null || client === undefined) {
                throw new RequiredError('client','Required parameter client was null or undefined when calling getDisplayPreferences.');
            }
            const localVarPath = `/DisplayPreferences/{displayPreferencesId}`
                .replace(`{${"displayPreferencesId"}}`, encodeURIComponent(String(displayPreferencesId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }

            if (client !== undefined) {
                localVarQueryParameter['client'] = client;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Display Preferences.
         * @param {string} displayPreferencesId Display preferences id.
         * @param {string} userId User Id.
         * @param {string} client Client.
         * @param {DisplayPreferencesDto} displayPreferencesDto New Display Preferences object.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDisplayPreferences: async (displayPreferencesId: string, userId: string, client: string, displayPreferencesDto: DisplayPreferencesDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'displayPreferencesId' is not null or undefined
            if (displayPreferencesId === null || displayPreferencesId === undefined) {
                throw new RequiredError('displayPreferencesId','Required parameter displayPreferencesId was null or undefined when calling updateDisplayPreferences.');
            }
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId','Required parameter userId was null or undefined when calling updateDisplayPreferences.');
            }
            // verify required parameter 'client' is not null or undefined
            if (client === null || client === undefined) {
                throw new RequiredError('client','Required parameter client was null or undefined when calling updateDisplayPreferences.');
            }
            // verify required parameter 'displayPreferencesDto' is not null or undefined
            if (displayPreferencesDto === null || displayPreferencesDto === undefined) {
                throw new RequiredError('displayPreferencesDto','Required parameter displayPreferencesDto was null or undefined when calling updateDisplayPreferences.');
            }
            const localVarPath = `/DisplayPreferences/{displayPreferencesId}`
                .replace(`{${"displayPreferencesId"}}`, encodeURIComponent(String(displayPreferencesId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }

            if (client !== undefined) {
                localVarQueryParameter['client'] = client;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof displayPreferencesDto !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(displayPreferencesDto !== undefined ? displayPreferencesDto : {}) : (displayPreferencesDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DisplayPreferencesApi - functional programming interface
 * @export
 */
export const DisplayPreferencesApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get Display Preferences.
         * @param {string} displayPreferencesId Display preferences id.
         * @param {string} userId User id.
         * @param {string} client Client.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDisplayPreferences(displayPreferencesId: string, userId: string, client: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DisplayPreferencesDto>> {
            const localVarAxiosArgs = await DisplayPreferencesApiAxiosParamCreator(configuration).getDisplayPreferences(displayPreferencesId, userId, client, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Update Display Preferences.
         * @param {string} displayPreferencesId Display preferences id.
         * @param {string} userId User Id.
         * @param {string} client Client.
         * @param {DisplayPreferencesDto} displayPreferencesDto New Display Preferences object.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateDisplayPreferences(displayPreferencesId: string, userId: string, client: string, displayPreferencesDto: DisplayPreferencesDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await DisplayPreferencesApiAxiosParamCreator(configuration).updateDisplayPreferences(displayPreferencesId, userId, client, displayPreferencesDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * DisplayPreferencesApi - factory interface
 * @export
 */
export const DisplayPreferencesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Get Display Preferences.
         * @param {string} displayPreferencesId Display preferences id.
         * @param {string} userId User id.
         * @param {string} client Client.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDisplayPreferences(displayPreferencesId: string, userId: string, client: string, options?: any): AxiosPromise<DisplayPreferencesDto> {
            return DisplayPreferencesApiFp(configuration).getDisplayPreferences(displayPreferencesId, userId, client, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Display Preferences.
         * @param {string} displayPreferencesId Display preferences id.
         * @param {string} userId User Id.
         * @param {string} client Client.
         * @param {DisplayPreferencesDto} displayPreferencesDto New Display Preferences object.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDisplayPreferences(displayPreferencesId: string, userId: string, client: string, displayPreferencesDto: DisplayPreferencesDto, options?: any): AxiosPromise<void> {
            return DisplayPreferencesApiFp(configuration).updateDisplayPreferences(displayPreferencesId, userId, client, displayPreferencesDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getDisplayPreferences operation in DisplayPreferencesApi.
 * @export
 * @interface DisplayPreferencesApiGetDisplayPreferencesRequest
 */
export interface DisplayPreferencesApiGetDisplayPreferencesRequest {
    /**
     * Display preferences id.
     * @type {string}
     * @memberof DisplayPreferencesApiGetDisplayPreferences
     */
    readonly displayPreferencesId: string

    /**
     * User id.
     * @type {string}
     * @memberof DisplayPreferencesApiGetDisplayPreferences
     */
    readonly userId: string

    /**
     * Client.
     * @type {string}
     * @memberof DisplayPreferencesApiGetDisplayPreferences
     */
    readonly client: string
}

/**
 * Request parameters for updateDisplayPreferences operation in DisplayPreferencesApi.
 * @export
 * @interface DisplayPreferencesApiUpdateDisplayPreferencesRequest
 */
export interface DisplayPreferencesApiUpdateDisplayPreferencesRequest {
    /**
     * Display preferences id.
     * @type {string}
     * @memberof DisplayPreferencesApiUpdateDisplayPreferences
     */
    readonly displayPreferencesId: string

    /**
     * User Id.
     * @type {string}
     * @memberof DisplayPreferencesApiUpdateDisplayPreferences
     */
    readonly userId: string

    /**
     * Client.
     * @type {string}
     * @memberof DisplayPreferencesApiUpdateDisplayPreferences
     */
    readonly client: string

    /**
     * New Display Preferences object.
     * @type {DisplayPreferencesDto}
     * @memberof DisplayPreferencesApiUpdateDisplayPreferences
     */
    readonly displayPreferencesDto: DisplayPreferencesDto
}

/**
 * DisplayPreferencesApi - object-oriented interface
 * @export
 * @class DisplayPreferencesApi
 * @extends {BaseAPI}
 */
export class DisplayPreferencesApi extends BaseAPI {
    /**
     * 
     * @summary Get Display Preferences.
     * @param {DisplayPreferencesApiGetDisplayPreferencesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DisplayPreferencesApi
     */
    public getDisplayPreferences(requestParameters: DisplayPreferencesApiGetDisplayPreferencesRequest, options?: any) {
        return DisplayPreferencesApiFp(this.configuration).getDisplayPreferences(requestParameters.displayPreferencesId, requestParameters.userId, requestParameters.client, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Display Preferences.
     * @param {DisplayPreferencesApiUpdateDisplayPreferencesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DisplayPreferencesApi
     */
    public updateDisplayPreferences(requestParameters: DisplayPreferencesApiUpdateDisplayPreferencesRequest, options?: any) {
        return DisplayPreferencesApiFp(this.configuration).updateDisplayPreferences(requestParameters.displayPreferencesId, requestParameters.userId, requestParameters.client, requestParameters.displayPreferencesDto, options).then((request) => request(this.axios, this.basePath));
    }
}
