/* tslint:disable */
/* eslint-disable */
/**
 * Jellyfin API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * An enum representing the axis that should be scrolled.
 * @export
 * @enum {string}
 */
export enum ScrollDirection {
    Horizontal = 'Horizontal',
    Vertical = 'Vertical'
}



