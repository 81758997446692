/* tslint:disable */
/* eslint-disable */
/**
 * Jellyfin API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { EncodingContext } from '../models';
// @ts-ignore
import { SubtitleDeliveryMethod } from '../models';
/**
 * DynamicHlsApi - axios parameter creator
 * @export
 */
export const DynamicHlsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Gets a video stream using HTTP live streaming.
         * @param {string} itemId The item id.
         * @param {string} playlistId The playlist id.
         * @param {number} segmentId The segment id.
         * @param {string} container The video container. Possible values are: ts, webm, asf, wmv, ogv, mp4, m4v, mkv, mpeg, mpg, avi, 3gp, wmv, wtv, m2ts, mov, iso, flv.
         * @param {boolean} [_static] Optional. If true, the original file will be streamed statically without any encoding. Use either no url extension or the original file extension. true/false.
         * @param {string} [params] The streaming parameters.
         * @param {string} [tag] The tag.
         * @param {string} [deviceProfileId] Optional. The dlna device profile id to utilize.
         * @param {string} [playSessionId] The play session id.
         * @param {string} [segmentContainer] The segment container.
         * @param {number} [segmentLength] The segment lenght.
         * @param {number} [minSegments] The minimum number of segments.
         * @param {string} [mediaSourceId] The media version id, if playing an alternate version.
         * @param {string} [deviceId] The device id of the client requesting. Used to stop encoding processes when needed.
         * @param {string} [audioCodec] Optional. Specify a audio codec to encode to, e.g. mp3. If omitted the server will auto-select using the url\&#39;s extension. Options: aac, mp3, vorbis, wma.
         * @param {boolean} [enableAutoStreamCopy] Whether or not to allow automatic stream copy if requested values match the original source. Defaults to true.
         * @param {boolean} [allowVideoStreamCopy] Whether or not to allow copying of the video stream url.
         * @param {boolean} [allowAudioStreamCopy] Whether or not to allow copying of the audio stream url.
         * @param {boolean} [breakOnNonKeyFrames] Optional. Whether to break on non key frames.
         * @param {number} [audioSampleRate] Optional. Specify a specific audio sample rate, e.g. 44100.
         * @param {number} [maxAudioBitDepth] Optional. The maximum audio bit depth.
         * @param {number} [audioBitRate] Optional. Specify an audio bitrate to encode to, e.g. 128000. If omitted this will be left to encoder defaults.
         * @param {number} [audioChannels] Optional. Specify a specific number of audio channels to encode to, e.g. 2.
         * @param {number} [maxAudioChannels] Optional. Specify a maximum number of audio channels to encode to, e.g. 2.
         * @param {string} [profile] Optional. Specify a specific an encoder profile (varies by encoder), e.g. main, baseline, high.
         * @param {string} [level] Optional. Specify a level for the encoder profile (varies by encoder), e.g. 3, 3.1.
         * @param {number} [framerate] Optional. A specific video framerate to encode to, e.g. 23.976. Generally this should be omitted unless the device has specific requirements.
         * @param {number} [maxFramerate] Optional. A specific maximum video framerate to encode to, e.g. 23.976. Generally this should be omitted unless the device has specific requirements.
         * @param {boolean} [copyTimestamps] Whether or not to copy timestamps when transcoding with an offset. Defaults to false.
         * @param {number} [startTimeTicks] Optional. Specify a starting offset, in ticks. 1 tick &#x3D; 10000 ms.
         * @param {number} [width] Optional. The fixed horizontal resolution of the encoded video.
         * @param {number} [height] Optional. The fixed vertical resolution of the encoded video.
         * @param {number} [videoBitRate] Optional. Specify a video bitrate to encode to, e.g. 500000. If omitted this will be left to encoder defaults.
         * @param {number} [subtitleStreamIndex] Optional. The index of the subtitle stream to use. If omitted no subtitles will be used.
         * @param {SubtitleDeliveryMethod} [subtitleMethod] Optional. Specify the subtitle delivery method.
         * @param {number} [maxRefFrames] Optional.
         * @param {number} [maxVideoBitDepth] Optional. The maximum video bit depth.
         * @param {boolean} [requireAvc] Optional. Whether to require avc.
         * @param {boolean} [deInterlace] Optional. Whether to deinterlace the video.
         * @param {boolean} [requireNonAnamorphic] Optional. Whether to require a non anamporphic stream.
         * @param {number} [transcodingMaxAudioChannels] Optional. The maximum number of audio channels to transcode.
         * @param {number} [cpuCoreLimit] Optional. The limit of how many cpu cores to use.
         * @param {string} [liveStreamId] The live stream id.
         * @param {boolean} [enableMpegtsM2TsMode] Optional. Whether to enable the MpegtsM2Ts mode.
         * @param {string} [videoCodec] Optional. Specify a video codec to encode to, e.g. h264. If omitted the server will auto-select using the url\&#39;s extension. Options: h265, h264, mpeg4, theora, vpx, wmv.
         * @param {string} [subtitleCodec] Optional. Specify a subtitle codec to encode to.
         * @param {string} [transcodingReasons] Optional. The transcoding reason.
         * @param {number} [audioStreamIndex] Optional. The index of the audio stream to use. If omitted the first audio stream will be used.
         * @param {number} [videoStreamIndex] Optional. The index of the video stream to use. If omitted the first video stream will be used.
         * @param {EncodingContext} [context] Optional. The MediaBrowser.Model.Dlna.EncodingContext.
         * @param {{ [key: string]: string; }} [streamOptions] Optional. The streaming options.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHlsAudioSegment: async (itemId: string, playlistId: string, segmentId: number, container: string, _static?: boolean, params?: string, tag?: string, deviceProfileId?: string, playSessionId?: string, segmentContainer?: string, segmentLength?: number, minSegments?: number, mediaSourceId?: string, deviceId?: string, audioCodec?: string, enableAutoStreamCopy?: boolean, allowVideoStreamCopy?: boolean, allowAudioStreamCopy?: boolean, breakOnNonKeyFrames?: boolean, audioSampleRate?: number, maxAudioBitDepth?: number, audioBitRate?: number, audioChannels?: number, maxAudioChannels?: number, profile?: string, level?: string, framerate?: number, maxFramerate?: number, copyTimestamps?: boolean, startTimeTicks?: number, width?: number, height?: number, videoBitRate?: number, subtitleStreamIndex?: number, subtitleMethod?: SubtitleDeliveryMethod, maxRefFrames?: number, maxVideoBitDepth?: number, requireAvc?: boolean, deInterlace?: boolean, requireNonAnamorphic?: boolean, transcodingMaxAudioChannels?: number, cpuCoreLimit?: number, liveStreamId?: string, enableMpegtsM2TsMode?: boolean, videoCodec?: string, subtitleCodec?: string, transcodingReasons?: string, audioStreamIndex?: number, videoStreamIndex?: number, context?: EncodingContext, streamOptions?: { [key: string]: string; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'itemId' is not null or undefined
            if (itemId === null || itemId === undefined) {
                throw new RequiredError('itemId','Required parameter itemId was null or undefined when calling getHlsAudioSegment.');
            }
            // verify required parameter 'playlistId' is not null or undefined
            if (playlistId === null || playlistId === undefined) {
                throw new RequiredError('playlistId','Required parameter playlistId was null or undefined when calling getHlsAudioSegment.');
            }
            // verify required parameter 'segmentId' is not null or undefined
            if (segmentId === null || segmentId === undefined) {
                throw new RequiredError('segmentId','Required parameter segmentId was null or undefined when calling getHlsAudioSegment.');
            }
            // verify required parameter 'container' is not null or undefined
            if (container === null || container === undefined) {
                throw new RequiredError('container','Required parameter container was null or undefined when calling getHlsAudioSegment.');
            }
            const localVarPath = `/Audio/{itemId}/hls1/{playlistId}/{segmentId}.{container}`
                .replace(`{${"itemId"}}`, encodeURIComponent(String(itemId)))
                .replace(`{${"playlistId"}}`, encodeURIComponent(String(playlistId)))
                .replace(`{${"segmentId"}}`, encodeURIComponent(String(segmentId)))
                .replace(`{${"container"}}`, encodeURIComponent(String(container)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }

            if (_static !== undefined) {
                localVarQueryParameter['static'] = _static;
            }

            if (params !== undefined) {
                localVarQueryParameter['params'] = params;
            }

            if (tag !== undefined) {
                localVarQueryParameter['tag'] = tag;
            }

            if (deviceProfileId !== undefined) {
                localVarQueryParameter['deviceProfileId'] = deviceProfileId;
            }

            if (playSessionId !== undefined) {
                localVarQueryParameter['playSessionId'] = playSessionId;
            }

            if (segmentContainer !== undefined) {
                localVarQueryParameter['segmentContainer'] = segmentContainer;
            }

            if (segmentLength !== undefined) {
                localVarQueryParameter['segmentLength'] = segmentLength;
            }

            if (minSegments !== undefined) {
                localVarQueryParameter['minSegments'] = minSegments;
            }

            if (mediaSourceId !== undefined) {
                localVarQueryParameter['mediaSourceId'] = mediaSourceId;
            }

            if (deviceId !== undefined) {
                localVarQueryParameter['deviceId'] = deviceId;
            }

            if (audioCodec !== undefined) {
                localVarQueryParameter['audioCodec'] = audioCodec;
            }

            if (enableAutoStreamCopy !== undefined) {
                localVarQueryParameter['enableAutoStreamCopy'] = enableAutoStreamCopy;
            }

            if (allowVideoStreamCopy !== undefined) {
                localVarQueryParameter['allowVideoStreamCopy'] = allowVideoStreamCopy;
            }

            if (allowAudioStreamCopy !== undefined) {
                localVarQueryParameter['allowAudioStreamCopy'] = allowAudioStreamCopy;
            }

            if (breakOnNonKeyFrames !== undefined) {
                localVarQueryParameter['breakOnNonKeyFrames'] = breakOnNonKeyFrames;
            }

            if (audioSampleRate !== undefined) {
                localVarQueryParameter['audioSampleRate'] = audioSampleRate;
            }

            if (maxAudioBitDepth !== undefined) {
                localVarQueryParameter['maxAudioBitDepth'] = maxAudioBitDepth;
            }

            if (audioBitRate !== undefined) {
                localVarQueryParameter['audioBitRate'] = audioBitRate;
            }

            if (audioChannels !== undefined) {
                localVarQueryParameter['audioChannels'] = audioChannels;
            }

            if (maxAudioChannels !== undefined) {
                localVarQueryParameter['maxAudioChannels'] = maxAudioChannels;
            }

            if (profile !== undefined) {
                localVarQueryParameter['profile'] = profile;
            }

            if (level !== undefined) {
                localVarQueryParameter['level'] = level;
            }

            if (framerate !== undefined) {
                localVarQueryParameter['framerate'] = framerate;
            }

            if (maxFramerate !== undefined) {
                localVarQueryParameter['maxFramerate'] = maxFramerate;
            }

            if (copyTimestamps !== undefined) {
                localVarQueryParameter['copyTimestamps'] = copyTimestamps;
            }

            if (startTimeTicks !== undefined) {
                localVarQueryParameter['startTimeTicks'] = startTimeTicks;
            }

            if (width !== undefined) {
                localVarQueryParameter['width'] = width;
            }

            if (height !== undefined) {
                localVarQueryParameter['height'] = height;
            }

            if (videoBitRate !== undefined) {
                localVarQueryParameter['videoBitRate'] = videoBitRate;
            }

            if (subtitleStreamIndex !== undefined) {
                localVarQueryParameter['subtitleStreamIndex'] = subtitleStreamIndex;
            }

            if (subtitleMethod !== undefined) {
                localVarQueryParameter['subtitleMethod'] = subtitleMethod;
            }

            if (maxRefFrames !== undefined) {
                localVarQueryParameter['maxRefFrames'] = maxRefFrames;
            }

            if (maxVideoBitDepth !== undefined) {
                localVarQueryParameter['maxVideoBitDepth'] = maxVideoBitDepth;
            }

            if (requireAvc !== undefined) {
                localVarQueryParameter['requireAvc'] = requireAvc;
            }

            if (deInterlace !== undefined) {
                localVarQueryParameter['deInterlace'] = deInterlace;
            }

            if (requireNonAnamorphic !== undefined) {
                localVarQueryParameter['requireNonAnamorphic'] = requireNonAnamorphic;
            }

            if (transcodingMaxAudioChannels !== undefined) {
                localVarQueryParameter['transcodingMaxAudioChannels'] = transcodingMaxAudioChannels;
            }

            if (cpuCoreLimit !== undefined) {
                localVarQueryParameter['cpuCoreLimit'] = cpuCoreLimit;
            }

            if (liveStreamId !== undefined) {
                localVarQueryParameter['liveStreamId'] = liveStreamId;
            }

            if (enableMpegtsM2TsMode !== undefined) {
                localVarQueryParameter['enableMpegtsM2TsMode'] = enableMpegtsM2TsMode;
            }

            if (videoCodec !== undefined) {
                localVarQueryParameter['videoCodec'] = videoCodec;
            }

            if (subtitleCodec !== undefined) {
                localVarQueryParameter['subtitleCodec'] = subtitleCodec;
            }

            if (transcodingReasons !== undefined) {
                localVarQueryParameter['transcodingReasons'] = transcodingReasons;
            }

            if (audioStreamIndex !== undefined) {
                localVarQueryParameter['audioStreamIndex'] = audioStreamIndex;
            }

            if (videoStreamIndex !== undefined) {
                localVarQueryParameter['videoStreamIndex'] = videoStreamIndex;
            }

            if (context !== undefined) {
                localVarQueryParameter['context'] = context;
            }

            if (streamOptions !== undefined) {
                localVarQueryParameter['streamOptions'] = streamOptions;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets a video stream using HTTP live streaming.
         * @param {string} itemId The item id.
         * @param {string} playlistId The playlist id.
         * @param {number} segmentId The segment id.
         * @param {string} container The video container. Possible values are: ts, webm, asf, wmv, ogv, mp4, m4v, mkv, mpeg, mpg, avi, 3gp, wmv, wtv, m2ts, mov, iso, flv.
         * @param {boolean} [_static] Optional. If true, the original file will be streamed statically without any encoding. Use either no url extension or the original file extension. true/false.
         * @param {string} [params] The streaming parameters.
         * @param {string} [tag] The tag.
         * @param {string} [deviceProfileId] Optional. The dlna device profile id to utilize.
         * @param {string} [playSessionId] The play session id.
         * @param {string} [segmentContainer] The segment container.
         * @param {number} [segmentLength] The segment lenght.
         * @param {number} [minSegments] The minimum number of segments.
         * @param {string} [mediaSourceId] The media version id, if playing an alternate version.
         * @param {string} [deviceId] The device id of the client requesting. Used to stop encoding processes when needed.
         * @param {string} [audioCodec] Optional. Specify a audio codec to encode to, e.g. mp3. If omitted the server will auto-select using the url\&#39;s extension. Options: aac, mp3, vorbis, wma.
         * @param {boolean} [enableAutoStreamCopy] Whether or not to allow automatic stream copy if requested values match the original source. Defaults to true.
         * @param {boolean} [allowVideoStreamCopy] Whether or not to allow copying of the video stream url.
         * @param {boolean} [allowAudioStreamCopy] Whether or not to allow copying of the audio stream url.
         * @param {boolean} [breakOnNonKeyFrames] Optional. Whether to break on non key frames.
         * @param {number} [audioSampleRate] Optional. Specify a specific audio sample rate, e.g. 44100.
         * @param {number} [maxAudioBitDepth] Optional. The maximum audio bit depth.
         * @param {number} [audioBitRate] Optional. Specify an audio bitrate to encode to, e.g. 128000. If omitted this will be left to encoder defaults.
         * @param {number} [audioChannels] Optional. Specify a specific number of audio channels to encode to, e.g. 2.
         * @param {number} [maxAudioChannels] Optional. Specify a maximum number of audio channels to encode to, e.g. 2.
         * @param {string} [profile] Optional. Specify a specific an encoder profile (varies by encoder), e.g. main, baseline, high.
         * @param {string} [level] Optional. Specify a level for the encoder profile (varies by encoder), e.g. 3, 3.1.
         * @param {number} [framerate] Optional. A specific video framerate to encode to, e.g. 23.976. Generally this should be omitted unless the device has specific requirements.
         * @param {number} [maxFramerate] Optional. A specific maximum video framerate to encode to, e.g. 23.976. Generally this should be omitted unless the device has specific requirements.
         * @param {boolean} [copyTimestamps] Whether or not to copy timestamps when transcoding with an offset. Defaults to false.
         * @param {number} [startTimeTicks] Optional. Specify a starting offset, in ticks. 1 tick &#x3D; 10000 ms.
         * @param {number} [width] Optional. The fixed horizontal resolution of the encoded video.
         * @param {number} [height] Optional. The fixed vertical resolution of the encoded video.
         * @param {number} [videoBitRate] Optional. Specify a video bitrate to encode to, e.g. 500000. If omitted this will be left to encoder defaults.
         * @param {number} [subtitleStreamIndex] Optional. The index of the subtitle stream to use. If omitted no subtitles will be used.
         * @param {SubtitleDeliveryMethod} [subtitleMethod] Optional. Specify the subtitle delivery method.
         * @param {number} [maxRefFrames] Optional.
         * @param {number} [maxVideoBitDepth] Optional. The maximum video bit depth.
         * @param {boolean} [requireAvc] Optional. Whether to require avc.
         * @param {boolean} [deInterlace] Optional. Whether to deinterlace the video.
         * @param {boolean} [requireNonAnamorphic] Optional. Whether to require a non anamporphic stream.
         * @param {number} [transcodingMaxAudioChannels] Optional. The maximum number of audio channels to transcode.
         * @param {number} [cpuCoreLimit] Optional. The limit of how many cpu cores to use.
         * @param {string} [liveStreamId] The live stream id.
         * @param {boolean} [enableMpegtsM2TsMode] Optional. Whether to enable the MpegtsM2Ts mode.
         * @param {string} [videoCodec] Optional. Specify a video codec to encode to, e.g. h264. If omitted the server will auto-select using the url\&#39;s extension. Options: h265, h264, mpeg4, theora, vpx, wmv.
         * @param {string} [subtitleCodec] Optional. Specify a subtitle codec to encode to.
         * @param {string} [transcodingReasons] Optional. The transcoding reason.
         * @param {number} [audioStreamIndex] Optional. The index of the audio stream to use. If omitted the first audio stream will be used.
         * @param {number} [videoStreamIndex] Optional. The index of the video stream to use. If omitted the first video stream will be used.
         * @param {EncodingContext} [context] Optional. The MediaBrowser.Model.Dlna.EncodingContext.
         * @param {{ [key: string]: string; }} [streamOptions] Optional. The streaming options.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHlsVideoSegment: async (itemId: string, playlistId: string, segmentId: number, container: string, _static?: boolean, params?: string, tag?: string, deviceProfileId?: string, playSessionId?: string, segmentContainer?: string, segmentLength?: number, minSegments?: number, mediaSourceId?: string, deviceId?: string, audioCodec?: string, enableAutoStreamCopy?: boolean, allowVideoStreamCopy?: boolean, allowAudioStreamCopy?: boolean, breakOnNonKeyFrames?: boolean, audioSampleRate?: number, maxAudioBitDepth?: number, audioBitRate?: number, audioChannels?: number, maxAudioChannels?: number, profile?: string, level?: string, framerate?: number, maxFramerate?: number, copyTimestamps?: boolean, startTimeTicks?: number, width?: number, height?: number, videoBitRate?: number, subtitleStreamIndex?: number, subtitleMethod?: SubtitleDeliveryMethod, maxRefFrames?: number, maxVideoBitDepth?: number, requireAvc?: boolean, deInterlace?: boolean, requireNonAnamorphic?: boolean, transcodingMaxAudioChannels?: number, cpuCoreLimit?: number, liveStreamId?: string, enableMpegtsM2TsMode?: boolean, videoCodec?: string, subtitleCodec?: string, transcodingReasons?: string, audioStreamIndex?: number, videoStreamIndex?: number, context?: EncodingContext, streamOptions?: { [key: string]: string; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'itemId' is not null or undefined
            if (itemId === null || itemId === undefined) {
                throw new RequiredError('itemId','Required parameter itemId was null or undefined when calling getHlsVideoSegment.');
            }
            // verify required parameter 'playlistId' is not null or undefined
            if (playlistId === null || playlistId === undefined) {
                throw new RequiredError('playlistId','Required parameter playlistId was null or undefined when calling getHlsVideoSegment.');
            }
            // verify required parameter 'segmentId' is not null or undefined
            if (segmentId === null || segmentId === undefined) {
                throw new RequiredError('segmentId','Required parameter segmentId was null or undefined when calling getHlsVideoSegment.');
            }
            // verify required parameter 'container' is not null or undefined
            if (container === null || container === undefined) {
                throw new RequiredError('container','Required parameter container was null or undefined when calling getHlsVideoSegment.');
            }
            const localVarPath = `/Videos/{itemId}/hls1/{playlistId}/{segmentId}.{container}`
                .replace(`{${"itemId"}}`, encodeURIComponent(String(itemId)))
                .replace(`{${"playlistId"}}`, encodeURIComponent(String(playlistId)))
                .replace(`{${"segmentId"}}`, encodeURIComponent(String(segmentId)))
                .replace(`{${"container"}}`, encodeURIComponent(String(container)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }

            if (_static !== undefined) {
                localVarQueryParameter['static'] = _static;
            }

            if (params !== undefined) {
                localVarQueryParameter['params'] = params;
            }

            if (tag !== undefined) {
                localVarQueryParameter['tag'] = tag;
            }

            if (deviceProfileId !== undefined) {
                localVarQueryParameter['deviceProfileId'] = deviceProfileId;
            }

            if (playSessionId !== undefined) {
                localVarQueryParameter['playSessionId'] = playSessionId;
            }

            if (segmentContainer !== undefined) {
                localVarQueryParameter['segmentContainer'] = segmentContainer;
            }

            if (segmentLength !== undefined) {
                localVarQueryParameter['segmentLength'] = segmentLength;
            }

            if (minSegments !== undefined) {
                localVarQueryParameter['minSegments'] = minSegments;
            }

            if (mediaSourceId !== undefined) {
                localVarQueryParameter['mediaSourceId'] = mediaSourceId;
            }

            if (deviceId !== undefined) {
                localVarQueryParameter['deviceId'] = deviceId;
            }

            if (audioCodec !== undefined) {
                localVarQueryParameter['audioCodec'] = audioCodec;
            }

            if (enableAutoStreamCopy !== undefined) {
                localVarQueryParameter['enableAutoStreamCopy'] = enableAutoStreamCopy;
            }

            if (allowVideoStreamCopy !== undefined) {
                localVarQueryParameter['allowVideoStreamCopy'] = allowVideoStreamCopy;
            }

            if (allowAudioStreamCopy !== undefined) {
                localVarQueryParameter['allowAudioStreamCopy'] = allowAudioStreamCopy;
            }

            if (breakOnNonKeyFrames !== undefined) {
                localVarQueryParameter['breakOnNonKeyFrames'] = breakOnNonKeyFrames;
            }

            if (audioSampleRate !== undefined) {
                localVarQueryParameter['audioSampleRate'] = audioSampleRate;
            }

            if (maxAudioBitDepth !== undefined) {
                localVarQueryParameter['maxAudioBitDepth'] = maxAudioBitDepth;
            }

            if (audioBitRate !== undefined) {
                localVarQueryParameter['audioBitRate'] = audioBitRate;
            }

            if (audioChannels !== undefined) {
                localVarQueryParameter['audioChannels'] = audioChannels;
            }

            if (maxAudioChannels !== undefined) {
                localVarQueryParameter['maxAudioChannels'] = maxAudioChannels;
            }

            if (profile !== undefined) {
                localVarQueryParameter['profile'] = profile;
            }

            if (level !== undefined) {
                localVarQueryParameter['level'] = level;
            }

            if (framerate !== undefined) {
                localVarQueryParameter['framerate'] = framerate;
            }

            if (maxFramerate !== undefined) {
                localVarQueryParameter['maxFramerate'] = maxFramerate;
            }

            if (copyTimestamps !== undefined) {
                localVarQueryParameter['copyTimestamps'] = copyTimestamps;
            }

            if (startTimeTicks !== undefined) {
                localVarQueryParameter['startTimeTicks'] = startTimeTicks;
            }

            if (width !== undefined) {
                localVarQueryParameter['width'] = width;
            }

            if (height !== undefined) {
                localVarQueryParameter['height'] = height;
            }

            if (videoBitRate !== undefined) {
                localVarQueryParameter['videoBitRate'] = videoBitRate;
            }

            if (subtitleStreamIndex !== undefined) {
                localVarQueryParameter['subtitleStreamIndex'] = subtitleStreamIndex;
            }

            if (subtitleMethod !== undefined) {
                localVarQueryParameter['subtitleMethod'] = subtitleMethod;
            }

            if (maxRefFrames !== undefined) {
                localVarQueryParameter['maxRefFrames'] = maxRefFrames;
            }

            if (maxVideoBitDepth !== undefined) {
                localVarQueryParameter['maxVideoBitDepth'] = maxVideoBitDepth;
            }

            if (requireAvc !== undefined) {
                localVarQueryParameter['requireAvc'] = requireAvc;
            }

            if (deInterlace !== undefined) {
                localVarQueryParameter['deInterlace'] = deInterlace;
            }

            if (requireNonAnamorphic !== undefined) {
                localVarQueryParameter['requireNonAnamorphic'] = requireNonAnamorphic;
            }

            if (transcodingMaxAudioChannels !== undefined) {
                localVarQueryParameter['transcodingMaxAudioChannels'] = transcodingMaxAudioChannels;
            }

            if (cpuCoreLimit !== undefined) {
                localVarQueryParameter['cpuCoreLimit'] = cpuCoreLimit;
            }

            if (liveStreamId !== undefined) {
                localVarQueryParameter['liveStreamId'] = liveStreamId;
            }

            if (enableMpegtsM2TsMode !== undefined) {
                localVarQueryParameter['enableMpegtsM2TsMode'] = enableMpegtsM2TsMode;
            }

            if (videoCodec !== undefined) {
                localVarQueryParameter['videoCodec'] = videoCodec;
            }

            if (subtitleCodec !== undefined) {
                localVarQueryParameter['subtitleCodec'] = subtitleCodec;
            }

            if (transcodingReasons !== undefined) {
                localVarQueryParameter['transcodingReasons'] = transcodingReasons;
            }

            if (audioStreamIndex !== undefined) {
                localVarQueryParameter['audioStreamIndex'] = audioStreamIndex;
            }

            if (videoStreamIndex !== undefined) {
                localVarQueryParameter['videoStreamIndex'] = videoStreamIndex;
            }

            if (context !== undefined) {
                localVarQueryParameter['context'] = context;
            }

            if (streamOptions !== undefined) {
                localVarQueryParameter['streamOptions'] = streamOptions;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets an audio hls playlist stream.
         * @param {string} itemId The item id.
         * @param {string} mediaSourceId The media version id, if playing an alternate version.
         * @param {boolean} [_static] Optional. If true, the original file will be streamed statically without any encoding. Use either no url extension or the original file extension. true/false.
         * @param {string} [params] The streaming parameters.
         * @param {string} [tag] The tag.
         * @param {string} [deviceProfileId] Optional. The dlna device profile id to utilize.
         * @param {string} [playSessionId] The play session id.
         * @param {string} [segmentContainer] The segment container.
         * @param {number} [segmentLength] The segment lenght.
         * @param {number} [minSegments] The minimum number of segments.
         * @param {string} [deviceId] The device id of the client requesting. Used to stop encoding processes when needed.
         * @param {string} [audioCodec] Optional. Specify a audio codec to encode to, e.g. mp3. If omitted the server will auto-select using the url\&#39;s extension. Options: aac, mp3, vorbis, wma.
         * @param {boolean} [enableAutoStreamCopy] Whether or not to allow automatic stream copy if requested values match the original source. Defaults to true.
         * @param {boolean} [allowVideoStreamCopy] Whether or not to allow copying of the video stream url.
         * @param {boolean} [allowAudioStreamCopy] Whether or not to allow copying of the audio stream url.
         * @param {boolean} [breakOnNonKeyFrames] Optional. Whether to break on non key frames.
         * @param {number} [audioSampleRate] Optional. Specify a specific audio sample rate, e.g. 44100.
         * @param {number} [maxAudioBitDepth] Optional. The maximum audio bit depth.
         * @param {number} [audioBitRate] Optional. Specify an audio bitrate to encode to, e.g. 128000. If omitted this will be left to encoder defaults.
         * @param {number} [audioChannels] Optional. Specify a specific number of audio channels to encode to, e.g. 2.
         * @param {number} [maxAudioChannels] Optional. Specify a maximum number of audio channels to encode to, e.g. 2.
         * @param {string} [profile] Optional. Specify a specific an encoder profile (varies by encoder), e.g. main, baseline, high.
         * @param {string} [level] Optional. Specify a level for the encoder profile (varies by encoder), e.g. 3, 3.1.
         * @param {number} [framerate] Optional. A specific video framerate to encode to, e.g. 23.976. Generally this should be omitted unless the device has specific requirements.
         * @param {number} [maxFramerate] Optional. A specific maximum video framerate to encode to, e.g. 23.976. Generally this should be omitted unless the device has specific requirements.
         * @param {boolean} [copyTimestamps] Whether or not to copy timestamps when transcoding with an offset. Defaults to false.
         * @param {number} [startTimeTicks] Optional. Specify a starting offset, in ticks. 1 tick &#x3D; 10000 ms.
         * @param {number} [width] Optional. The fixed horizontal resolution of the encoded video.
         * @param {number} [height] Optional. The fixed vertical resolution of the encoded video.
         * @param {number} [videoBitRate] Optional. Specify a video bitrate to encode to, e.g. 500000. If omitted this will be left to encoder defaults.
         * @param {number} [subtitleStreamIndex] Optional. The index of the subtitle stream to use. If omitted no subtitles will be used.
         * @param {SubtitleDeliveryMethod} [subtitleMethod] Optional. Specify the subtitle delivery method.
         * @param {number} [maxRefFrames] Optional.
         * @param {number} [maxVideoBitDepth] Optional. The maximum video bit depth.
         * @param {boolean} [requireAvc] Optional. Whether to require avc.
         * @param {boolean} [deInterlace] Optional. Whether to deinterlace the video.
         * @param {boolean} [requireNonAnamorphic] Optional. Whether to require a non anamporphic stream.
         * @param {number} [transcodingMaxAudioChannels] Optional. The maximum number of audio channels to transcode.
         * @param {number} [cpuCoreLimit] Optional. The limit of how many cpu cores to use.
         * @param {string} [liveStreamId] The live stream id.
         * @param {boolean} [enableMpegtsM2TsMode] Optional. Whether to enable the MpegtsM2Ts mode.
         * @param {string} [videoCodec] Optional. Specify a video codec to encode to, e.g. h264. If omitted the server will auto-select using the url\&#39;s extension. Options: h265, h264, mpeg4, theora, vpx, wmv.
         * @param {string} [subtitleCodec] Optional. Specify a subtitle codec to encode to.
         * @param {string} [transcodingReasons] Optional. The transcoding reason.
         * @param {number} [audioStreamIndex] Optional. The index of the audio stream to use. If omitted the first audio stream will be used.
         * @param {number} [videoStreamIndex] Optional. The index of the video stream to use. If omitted the first video stream will be used.
         * @param {EncodingContext} [context] Optional. The MediaBrowser.Model.Dlna.EncodingContext.
         * @param {{ [key: string]: string; }} [streamOptions] Optional. The streaming options.
         * @param {boolean} [enableAdaptiveBitrateStreaming] Enable adaptive bitrate streaming.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMasterHlsAudioPlaylist: async (itemId: string, mediaSourceId: string, _static?: boolean, params?: string, tag?: string, deviceProfileId?: string, playSessionId?: string, segmentContainer?: string, segmentLength?: number, minSegments?: number, deviceId?: string, audioCodec?: string, enableAutoStreamCopy?: boolean, allowVideoStreamCopy?: boolean, allowAudioStreamCopy?: boolean, breakOnNonKeyFrames?: boolean, audioSampleRate?: number, maxAudioBitDepth?: number, audioBitRate?: number, audioChannels?: number, maxAudioChannels?: number, profile?: string, level?: string, framerate?: number, maxFramerate?: number, copyTimestamps?: boolean, startTimeTicks?: number, width?: number, height?: number, videoBitRate?: number, subtitleStreamIndex?: number, subtitleMethod?: SubtitleDeliveryMethod, maxRefFrames?: number, maxVideoBitDepth?: number, requireAvc?: boolean, deInterlace?: boolean, requireNonAnamorphic?: boolean, transcodingMaxAudioChannels?: number, cpuCoreLimit?: number, liveStreamId?: string, enableMpegtsM2TsMode?: boolean, videoCodec?: string, subtitleCodec?: string, transcodingReasons?: string, audioStreamIndex?: number, videoStreamIndex?: number, context?: EncodingContext, streamOptions?: { [key: string]: string; }, enableAdaptiveBitrateStreaming?: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'itemId' is not null or undefined
            if (itemId === null || itemId === undefined) {
                throw new RequiredError('itemId','Required parameter itemId was null or undefined when calling getMasterHlsAudioPlaylist.');
            }
            // verify required parameter 'mediaSourceId' is not null or undefined
            if (mediaSourceId === null || mediaSourceId === undefined) {
                throw new RequiredError('mediaSourceId','Required parameter mediaSourceId was null or undefined when calling getMasterHlsAudioPlaylist.');
            }
            const localVarPath = `/Audio/{itemId}/master.m3u8`
                .replace(`{${"itemId"}}`, encodeURIComponent(String(itemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }

            if (_static !== undefined) {
                localVarQueryParameter['static'] = _static;
            }

            if (params !== undefined) {
                localVarQueryParameter['params'] = params;
            }

            if (tag !== undefined) {
                localVarQueryParameter['tag'] = tag;
            }

            if (deviceProfileId !== undefined) {
                localVarQueryParameter['deviceProfileId'] = deviceProfileId;
            }

            if (playSessionId !== undefined) {
                localVarQueryParameter['playSessionId'] = playSessionId;
            }

            if (segmentContainer !== undefined) {
                localVarQueryParameter['segmentContainer'] = segmentContainer;
            }

            if (segmentLength !== undefined) {
                localVarQueryParameter['segmentLength'] = segmentLength;
            }

            if (minSegments !== undefined) {
                localVarQueryParameter['minSegments'] = minSegments;
            }

            if (mediaSourceId !== undefined) {
                localVarQueryParameter['mediaSourceId'] = mediaSourceId;
            }

            if (deviceId !== undefined) {
                localVarQueryParameter['deviceId'] = deviceId;
            }

            if (audioCodec !== undefined) {
                localVarQueryParameter['audioCodec'] = audioCodec;
            }

            if (enableAutoStreamCopy !== undefined) {
                localVarQueryParameter['enableAutoStreamCopy'] = enableAutoStreamCopy;
            }

            if (allowVideoStreamCopy !== undefined) {
                localVarQueryParameter['allowVideoStreamCopy'] = allowVideoStreamCopy;
            }

            if (allowAudioStreamCopy !== undefined) {
                localVarQueryParameter['allowAudioStreamCopy'] = allowAudioStreamCopy;
            }

            if (breakOnNonKeyFrames !== undefined) {
                localVarQueryParameter['breakOnNonKeyFrames'] = breakOnNonKeyFrames;
            }

            if (audioSampleRate !== undefined) {
                localVarQueryParameter['audioSampleRate'] = audioSampleRate;
            }

            if (maxAudioBitDepth !== undefined) {
                localVarQueryParameter['maxAudioBitDepth'] = maxAudioBitDepth;
            }

            if (audioBitRate !== undefined) {
                localVarQueryParameter['audioBitRate'] = audioBitRate;
            }

            if (audioChannels !== undefined) {
                localVarQueryParameter['audioChannels'] = audioChannels;
            }

            if (maxAudioChannels !== undefined) {
                localVarQueryParameter['maxAudioChannels'] = maxAudioChannels;
            }

            if (profile !== undefined) {
                localVarQueryParameter['profile'] = profile;
            }

            if (level !== undefined) {
                localVarQueryParameter['level'] = level;
            }

            if (framerate !== undefined) {
                localVarQueryParameter['framerate'] = framerate;
            }

            if (maxFramerate !== undefined) {
                localVarQueryParameter['maxFramerate'] = maxFramerate;
            }

            if (copyTimestamps !== undefined) {
                localVarQueryParameter['copyTimestamps'] = copyTimestamps;
            }

            if (startTimeTicks !== undefined) {
                localVarQueryParameter['startTimeTicks'] = startTimeTicks;
            }

            if (width !== undefined) {
                localVarQueryParameter['width'] = width;
            }

            if (height !== undefined) {
                localVarQueryParameter['height'] = height;
            }

            if (videoBitRate !== undefined) {
                localVarQueryParameter['videoBitRate'] = videoBitRate;
            }

            if (subtitleStreamIndex !== undefined) {
                localVarQueryParameter['subtitleStreamIndex'] = subtitleStreamIndex;
            }

            if (subtitleMethod !== undefined) {
                localVarQueryParameter['subtitleMethod'] = subtitleMethod;
            }

            if (maxRefFrames !== undefined) {
                localVarQueryParameter['maxRefFrames'] = maxRefFrames;
            }

            if (maxVideoBitDepth !== undefined) {
                localVarQueryParameter['maxVideoBitDepth'] = maxVideoBitDepth;
            }

            if (requireAvc !== undefined) {
                localVarQueryParameter['requireAvc'] = requireAvc;
            }

            if (deInterlace !== undefined) {
                localVarQueryParameter['deInterlace'] = deInterlace;
            }

            if (requireNonAnamorphic !== undefined) {
                localVarQueryParameter['requireNonAnamorphic'] = requireNonAnamorphic;
            }

            if (transcodingMaxAudioChannels !== undefined) {
                localVarQueryParameter['transcodingMaxAudioChannels'] = transcodingMaxAudioChannels;
            }

            if (cpuCoreLimit !== undefined) {
                localVarQueryParameter['cpuCoreLimit'] = cpuCoreLimit;
            }

            if (liveStreamId !== undefined) {
                localVarQueryParameter['liveStreamId'] = liveStreamId;
            }

            if (enableMpegtsM2TsMode !== undefined) {
                localVarQueryParameter['enableMpegtsM2TsMode'] = enableMpegtsM2TsMode;
            }

            if (videoCodec !== undefined) {
                localVarQueryParameter['videoCodec'] = videoCodec;
            }

            if (subtitleCodec !== undefined) {
                localVarQueryParameter['subtitleCodec'] = subtitleCodec;
            }

            if (transcodingReasons !== undefined) {
                localVarQueryParameter['transcodingReasons'] = transcodingReasons;
            }

            if (audioStreamIndex !== undefined) {
                localVarQueryParameter['audioStreamIndex'] = audioStreamIndex;
            }

            if (videoStreamIndex !== undefined) {
                localVarQueryParameter['videoStreamIndex'] = videoStreamIndex;
            }

            if (context !== undefined) {
                localVarQueryParameter['context'] = context;
            }

            if (streamOptions !== undefined) {
                localVarQueryParameter['streamOptions'] = streamOptions;
            }

            if (enableAdaptiveBitrateStreaming !== undefined) {
                localVarQueryParameter['enableAdaptiveBitrateStreaming'] = enableAdaptiveBitrateStreaming;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets a video hls playlist stream.
         * @param {string} itemId The item id.
         * @param {string} mediaSourceId The media version id, if playing an alternate version.
         * @param {boolean} [_static] Optional. If true, the original file will be streamed statically without any encoding. Use either no url extension or the original file extension. true/false.
         * @param {string} [params] The streaming parameters.
         * @param {string} [tag] The tag.
         * @param {string} [deviceProfileId] Optional. The dlna device profile id to utilize.
         * @param {string} [playSessionId] The play session id.
         * @param {string} [segmentContainer] The segment container.
         * @param {number} [segmentLength] The segment lenght.
         * @param {number} [minSegments] The minimum number of segments.
         * @param {string} [deviceId] The device id of the client requesting. Used to stop encoding processes when needed.
         * @param {string} [audioCodec] Optional. Specify a audio codec to encode to, e.g. mp3. If omitted the server will auto-select using the url\&#39;s extension. Options: aac, mp3, vorbis, wma.
         * @param {boolean} [enableAutoStreamCopy] Whether or not to allow automatic stream copy if requested values match the original source. Defaults to true.
         * @param {boolean} [allowVideoStreamCopy] Whether or not to allow copying of the video stream url.
         * @param {boolean} [allowAudioStreamCopy] Whether or not to allow copying of the audio stream url.
         * @param {boolean} [breakOnNonKeyFrames] Optional. Whether to break on non key frames.
         * @param {number} [audioSampleRate] Optional. Specify a specific audio sample rate, e.g. 44100.
         * @param {number} [maxAudioBitDepth] Optional. The maximum audio bit depth.
         * @param {number} [audioBitRate] Optional. Specify an audio bitrate to encode to, e.g. 128000. If omitted this will be left to encoder defaults.
         * @param {number} [audioChannels] Optional. Specify a specific number of audio channels to encode to, e.g. 2.
         * @param {number} [maxAudioChannels] Optional. Specify a maximum number of audio channels to encode to, e.g. 2.
         * @param {string} [profile] Optional. Specify a specific an encoder profile (varies by encoder), e.g. main, baseline, high.
         * @param {string} [level] Optional. Specify a level for the encoder profile (varies by encoder), e.g. 3, 3.1.
         * @param {number} [framerate] Optional. A specific video framerate to encode to, e.g. 23.976. Generally this should be omitted unless the device has specific requirements.
         * @param {number} [maxFramerate] Optional. A specific maximum video framerate to encode to, e.g. 23.976. Generally this should be omitted unless the device has specific requirements.
         * @param {boolean} [copyTimestamps] Whether or not to copy timestamps when transcoding with an offset. Defaults to false.
         * @param {number} [startTimeTicks] Optional. Specify a starting offset, in ticks. 1 tick &#x3D; 10000 ms.
         * @param {number} [width] Optional. The fixed horizontal resolution of the encoded video.
         * @param {number} [height] Optional. The fixed vertical resolution of the encoded video.
         * @param {number} [videoBitRate] Optional. Specify a video bitrate to encode to, e.g. 500000. If omitted this will be left to encoder defaults.
         * @param {number} [subtitleStreamIndex] Optional. The index of the subtitle stream to use. If omitted no subtitles will be used.
         * @param {SubtitleDeliveryMethod} [subtitleMethod] Optional. Specify the subtitle delivery method.
         * @param {number} [maxRefFrames] Optional.
         * @param {number} [maxVideoBitDepth] Optional. The maximum video bit depth.
         * @param {boolean} [requireAvc] Optional. Whether to require avc.
         * @param {boolean} [deInterlace] Optional. Whether to deinterlace the video.
         * @param {boolean} [requireNonAnamorphic] Optional. Whether to require a non anamporphic stream.
         * @param {number} [transcodingMaxAudioChannels] Optional. The maximum number of audio channels to transcode.
         * @param {number} [cpuCoreLimit] Optional. The limit of how many cpu cores to use.
         * @param {string} [liveStreamId] The live stream id.
         * @param {boolean} [enableMpegtsM2TsMode] Optional. Whether to enable the MpegtsM2Ts mode.
         * @param {string} [videoCodec] Optional. Specify a video codec to encode to, e.g. h264. If omitted the server will auto-select using the url\&#39;s extension. Options: h265, h264, mpeg4, theora, vpx, wmv.
         * @param {string} [subtitleCodec] Optional. Specify a subtitle codec to encode to.
         * @param {string} [transcodingReasons] Optional. The transcoding reason.
         * @param {number} [audioStreamIndex] Optional. The index of the audio stream to use. If omitted the first audio stream will be used.
         * @param {number} [videoStreamIndex] Optional. The index of the video stream to use. If omitted the first video stream will be used.
         * @param {EncodingContext} [context] Optional. The MediaBrowser.Model.Dlna.EncodingContext.
         * @param {{ [key: string]: string; }} [streamOptions] Optional. The streaming options.
         * @param {boolean} [enableAdaptiveBitrateStreaming] Enable adaptive bitrate streaming.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMasterHlsVideoPlaylist: async (itemId: string, mediaSourceId: string, _static?: boolean, params?: string, tag?: string, deviceProfileId?: string, playSessionId?: string, segmentContainer?: string, segmentLength?: number, minSegments?: number, deviceId?: string, audioCodec?: string, enableAutoStreamCopy?: boolean, allowVideoStreamCopy?: boolean, allowAudioStreamCopy?: boolean, breakOnNonKeyFrames?: boolean, audioSampleRate?: number, maxAudioBitDepth?: number, audioBitRate?: number, audioChannels?: number, maxAudioChannels?: number, profile?: string, level?: string, framerate?: number, maxFramerate?: number, copyTimestamps?: boolean, startTimeTicks?: number, width?: number, height?: number, videoBitRate?: number, subtitleStreamIndex?: number, subtitleMethod?: SubtitleDeliveryMethod, maxRefFrames?: number, maxVideoBitDepth?: number, requireAvc?: boolean, deInterlace?: boolean, requireNonAnamorphic?: boolean, transcodingMaxAudioChannels?: number, cpuCoreLimit?: number, liveStreamId?: string, enableMpegtsM2TsMode?: boolean, videoCodec?: string, subtitleCodec?: string, transcodingReasons?: string, audioStreamIndex?: number, videoStreamIndex?: number, context?: EncodingContext, streamOptions?: { [key: string]: string; }, enableAdaptiveBitrateStreaming?: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'itemId' is not null or undefined
            if (itemId === null || itemId === undefined) {
                throw new RequiredError('itemId','Required parameter itemId was null or undefined when calling getMasterHlsVideoPlaylist.');
            }
            // verify required parameter 'mediaSourceId' is not null or undefined
            if (mediaSourceId === null || mediaSourceId === undefined) {
                throw new RequiredError('mediaSourceId','Required parameter mediaSourceId was null or undefined when calling getMasterHlsVideoPlaylist.');
            }
            const localVarPath = `/Videos/{itemId}/master.m3u8`
                .replace(`{${"itemId"}}`, encodeURIComponent(String(itemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }

            if (_static !== undefined) {
                localVarQueryParameter['static'] = _static;
            }

            if (params !== undefined) {
                localVarQueryParameter['params'] = params;
            }

            if (tag !== undefined) {
                localVarQueryParameter['tag'] = tag;
            }

            if (deviceProfileId !== undefined) {
                localVarQueryParameter['deviceProfileId'] = deviceProfileId;
            }

            if (playSessionId !== undefined) {
                localVarQueryParameter['playSessionId'] = playSessionId;
            }

            if (segmentContainer !== undefined) {
                localVarQueryParameter['segmentContainer'] = segmentContainer;
            }

            if (segmentLength !== undefined) {
                localVarQueryParameter['segmentLength'] = segmentLength;
            }

            if (minSegments !== undefined) {
                localVarQueryParameter['minSegments'] = minSegments;
            }

            if (mediaSourceId !== undefined) {
                localVarQueryParameter['mediaSourceId'] = mediaSourceId;
            }

            if (deviceId !== undefined) {
                localVarQueryParameter['deviceId'] = deviceId;
            }

            if (audioCodec !== undefined) {
                localVarQueryParameter['audioCodec'] = audioCodec;
            }

            if (enableAutoStreamCopy !== undefined) {
                localVarQueryParameter['enableAutoStreamCopy'] = enableAutoStreamCopy;
            }

            if (allowVideoStreamCopy !== undefined) {
                localVarQueryParameter['allowVideoStreamCopy'] = allowVideoStreamCopy;
            }

            if (allowAudioStreamCopy !== undefined) {
                localVarQueryParameter['allowAudioStreamCopy'] = allowAudioStreamCopy;
            }

            if (breakOnNonKeyFrames !== undefined) {
                localVarQueryParameter['breakOnNonKeyFrames'] = breakOnNonKeyFrames;
            }

            if (audioSampleRate !== undefined) {
                localVarQueryParameter['audioSampleRate'] = audioSampleRate;
            }

            if (maxAudioBitDepth !== undefined) {
                localVarQueryParameter['maxAudioBitDepth'] = maxAudioBitDepth;
            }

            if (audioBitRate !== undefined) {
                localVarQueryParameter['audioBitRate'] = audioBitRate;
            }

            if (audioChannels !== undefined) {
                localVarQueryParameter['audioChannels'] = audioChannels;
            }

            if (maxAudioChannels !== undefined) {
                localVarQueryParameter['maxAudioChannels'] = maxAudioChannels;
            }

            if (profile !== undefined) {
                localVarQueryParameter['profile'] = profile;
            }

            if (level !== undefined) {
                localVarQueryParameter['level'] = level;
            }

            if (framerate !== undefined) {
                localVarQueryParameter['framerate'] = framerate;
            }

            if (maxFramerate !== undefined) {
                localVarQueryParameter['maxFramerate'] = maxFramerate;
            }

            if (copyTimestamps !== undefined) {
                localVarQueryParameter['copyTimestamps'] = copyTimestamps;
            }

            if (startTimeTicks !== undefined) {
                localVarQueryParameter['startTimeTicks'] = startTimeTicks;
            }

            if (width !== undefined) {
                localVarQueryParameter['width'] = width;
            }

            if (height !== undefined) {
                localVarQueryParameter['height'] = height;
            }

            if (videoBitRate !== undefined) {
                localVarQueryParameter['videoBitRate'] = videoBitRate;
            }

            if (subtitleStreamIndex !== undefined) {
                localVarQueryParameter['subtitleStreamIndex'] = subtitleStreamIndex;
            }

            if (subtitleMethod !== undefined) {
                localVarQueryParameter['subtitleMethod'] = subtitleMethod;
            }

            if (maxRefFrames !== undefined) {
                localVarQueryParameter['maxRefFrames'] = maxRefFrames;
            }

            if (maxVideoBitDepth !== undefined) {
                localVarQueryParameter['maxVideoBitDepth'] = maxVideoBitDepth;
            }

            if (requireAvc !== undefined) {
                localVarQueryParameter['requireAvc'] = requireAvc;
            }

            if (deInterlace !== undefined) {
                localVarQueryParameter['deInterlace'] = deInterlace;
            }

            if (requireNonAnamorphic !== undefined) {
                localVarQueryParameter['requireNonAnamorphic'] = requireNonAnamorphic;
            }

            if (transcodingMaxAudioChannels !== undefined) {
                localVarQueryParameter['transcodingMaxAudioChannels'] = transcodingMaxAudioChannels;
            }

            if (cpuCoreLimit !== undefined) {
                localVarQueryParameter['cpuCoreLimit'] = cpuCoreLimit;
            }

            if (liveStreamId !== undefined) {
                localVarQueryParameter['liveStreamId'] = liveStreamId;
            }

            if (enableMpegtsM2TsMode !== undefined) {
                localVarQueryParameter['enableMpegtsM2TsMode'] = enableMpegtsM2TsMode;
            }

            if (videoCodec !== undefined) {
                localVarQueryParameter['videoCodec'] = videoCodec;
            }

            if (subtitleCodec !== undefined) {
                localVarQueryParameter['subtitleCodec'] = subtitleCodec;
            }

            if (transcodingReasons !== undefined) {
                localVarQueryParameter['transcodingReasons'] = transcodingReasons;
            }

            if (audioStreamIndex !== undefined) {
                localVarQueryParameter['audioStreamIndex'] = audioStreamIndex;
            }

            if (videoStreamIndex !== undefined) {
                localVarQueryParameter['videoStreamIndex'] = videoStreamIndex;
            }

            if (context !== undefined) {
                localVarQueryParameter['context'] = context;
            }

            if (streamOptions !== undefined) {
                localVarQueryParameter['streamOptions'] = streamOptions;
            }

            if (enableAdaptiveBitrateStreaming !== undefined) {
                localVarQueryParameter['enableAdaptiveBitrateStreaming'] = enableAdaptiveBitrateStreaming;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets an audio stream using HTTP live streaming.
         * @param {string} itemId The item id.
         * @param {boolean} [_static] Optional. If true, the original file will be streamed statically without any encoding. Use either no url extension or the original file extension. true/false.
         * @param {string} [params] The streaming parameters.
         * @param {string} [tag] The tag.
         * @param {string} [deviceProfileId] Optional. The dlna device profile id to utilize.
         * @param {string} [playSessionId] The play session id.
         * @param {string} [segmentContainer] The segment container.
         * @param {number} [segmentLength] The segment lenght.
         * @param {number} [minSegments] The minimum number of segments.
         * @param {string} [mediaSourceId] The media version id, if playing an alternate version.
         * @param {string} [deviceId] The device id of the client requesting. Used to stop encoding processes when needed.
         * @param {string} [audioCodec] Optional. Specify a audio codec to encode to, e.g. mp3. If omitted the server will auto-select using the url\&#39;s extension. Options: aac, mp3, vorbis, wma.
         * @param {boolean} [enableAutoStreamCopy] Whether or not to allow automatic stream copy if requested values match the original source. Defaults to true.
         * @param {boolean} [allowVideoStreamCopy] Whether or not to allow copying of the video stream url.
         * @param {boolean} [allowAudioStreamCopy] Whether or not to allow copying of the audio stream url.
         * @param {boolean} [breakOnNonKeyFrames] Optional. Whether to break on non key frames.
         * @param {number} [audioSampleRate] Optional. Specify a specific audio sample rate, e.g. 44100.
         * @param {number} [maxAudioBitDepth] Optional. The maximum audio bit depth.
         * @param {number} [audioBitRate] Optional. Specify an audio bitrate to encode to, e.g. 128000. If omitted this will be left to encoder defaults.
         * @param {number} [audioChannels] Optional. Specify a specific number of audio channels to encode to, e.g. 2.
         * @param {number} [maxAudioChannels] Optional. Specify a maximum number of audio channels to encode to, e.g. 2.
         * @param {string} [profile] Optional. Specify a specific an encoder profile (varies by encoder), e.g. main, baseline, high.
         * @param {string} [level] Optional. Specify a level for the encoder profile (varies by encoder), e.g. 3, 3.1.
         * @param {number} [framerate] Optional. A specific video framerate to encode to, e.g. 23.976. Generally this should be omitted unless the device has specific requirements.
         * @param {number} [maxFramerate] Optional. A specific maximum video framerate to encode to, e.g. 23.976. Generally this should be omitted unless the device has specific requirements.
         * @param {boolean} [copyTimestamps] Whether or not to copy timestamps when transcoding with an offset. Defaults to false.
         * @param {number} [startTimeTicks] Optional. Specify a starting offset, in ticks. 1 tick &#x3D; 10000 ms.
         * @param {number} [width] Optional. The fixed horizontal resolution of the encoded video.
         * @param {number} [height] Optional. The fixed vertical resolution of the encoded video.
         * @param {number} [videoBitRate] Optional. Specify a video bitrate to encode to, e.g. 500000. If omitted this will be left to encoder defaults.
         * @param {number} [subtitleStreamIndex] Optional. The index of the subtitle stream to use. If omitted no subtitles will be used.
         * @param {SubtitleDeliveryMethod} [subtitleMethod] Optional. Specify the subtitle delivery method.
         * @param {number} [maxRefFrames] Optional.
         * @param {number} [maxVideoBitDepth] Optional. The maximum video bit depth.
         * @param {boolean} [requireAvc] Optional. Whether to require avc.
         * @param {boolean} [deInterlace] Optional. Whether to deinterlace the video.
         * @param {boolean} [requireNonAnamorphic] Optional. Whether to require a non anamporphic stream.
         * @param {number} [transcodingMaxAudioChannels] Optional. The maximum number of audio channels to transcode.
         * @param {number} [cpuCoreLimit] Optional. The limit of how many cpu cores to use.
         * @param {string} [liveStreamId] The live stream id.
         * @param {boolean} [enableMpegtsM2TsMode] Optional. Whether to enable the MpegtsM2Ts mode.
         * @param {string} [videoCodec] Optional. Specify a video codec to encode to, e.g. h264. If omitted the server will auto-select using the url\&#39;s extension. Options: h265, h264, mpeg4, theora, vpx, wmv.
         * @param {string} [subtitleCodec] Optional. Specify a subtitle codec to encode to.
         * @param {string} [transcodingReasons] Optional. The transcoding reason.
         * @param {number} [audioStreamIndex] Optional. The index of the audio stream to use. If omitted the first audio stream will be used.
         * @param {number} [videoStreamIndex] Optional. The index of the video stream to use. If omitted the first video stream will be used.
         * @param {EncodingContext} [context] Optional. The MediaBrowser.Model.Dlna.EncodingContext.
         * @param {{ [key: string]: string; }} [streamOptions] Optional. The streaming options.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVariantHlsAudioPlaylist: async (itemId: string, _static?: boolean, params?: string, tag?: string, deviceProfileId?: string, playSessionId?: string, segmentContainer?: string, segmentLength?: number, minSegments?: number, mediaSourceId?: string, deviceId?: string, audioCodec?: string, enableAutoStreamCopy?: boolean, allowVideoStreamCopy?: boolean, allowAudioStreamCopy?: boolean, breakOnNonKeyFrames?: boolean, audioSampleRate?: number, maxAudioBitDepth?: number, audioBitRate?: number, audioChannels?: number, maxAudioChannels?: number, profile?: string, level?: string, framerate?: number, maxFramerate?: number, copyTimestamps?: boolean, startTimeTicks?: number, width?: number, height?: number, videoBitRate?: number, subtitleStreamIndex?: number, subtitleMethod?: SubtitleDeliveryMethod, maxRefFrames?: number, maxVideoBitDepth?: number, requireAvc?: boolean, deInterlace?: boolean, requireNonAnamorphic?: boolean, transcodingMaxAudioChannels?: number, cpuCoreLimit?: number, liveStreamId?: string, enableMpegtsM2TsMode?: boolean, videoCodec?: string, subtitleCodec?: string, transcodingReasons?: string, audioStreamIndex?: number, videoStreamIndex?: number, context?: EncodingContext, streamOptions?: { [key: string]: string; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'itemId' is not null or undefined
            if (itemId === null || itemId === undefined) {
                throw new RequiredError('itemId','Required parameter itemId was null or undefined when calling getVariantHlsAudioPlaylist.');
            }
            const localVarPath = `/Audio/{itemId}/main.m3u8`
                .replace(`{${"itemId"}}`, encodeURIComponent(String(itemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }

            if (_static !== undefined) {
                localVarQueryParameter['static'] = _static;
            }

            if (params !== undefined) {
                localVarQueryParameter['params'] = params;
            }

            if (tag !== undefined) {
                localVarQueryParameter['tag'] = tag;
            }

            if (deviceProfileId !== undefined) {
                localVarQueryParameter['deviceProfileId'] = deviceProfileId;
            }

            if (playSessionId !== undefined) {
                localVarQueryParameter['playSessionId'] = playSessionId;
            }

            if (segmentContainer !== undefined) {
                localVarQueryParameter['segmentContainer'] = segmentContainer;
            }

            if (segmentLength !== undefined) {
                localVarQueryParameter['segmentLength'] = segmentLength;
            }

            if (minSegments !== undefined) {
                localVarQueryParameter['minSegments'] = minSegments;
            }

            if (mediaSourceId !== undefined) {
                localVarQueryParameter['mediaSourceId'] = mediaSourceId;
            }

            if (deviceId !== undefined) {
                localVarQueryParameter['deviceId'] = deviceId;
            }

            if (audioCodec !== undefined) {
                localVarQueryParameter['audioCodec'] = audioCodec;
            }

            if (enableAutoStreamCopy !== undefined) {
                localVarQueryParameter['enableAutoStreamCopy'] = enableAutoStreamCopy;
            }

            if (allowVideoStreamCopy !== undefined) {
                localVarQueryParameter['allowVideoStreamCopy'] = allowVideoStreamCopy;
            }

            if (allowAudioStreamCopy !== undefined) {
                localVarQueryParameter['allowAudioStreamCopy'] = allowAudioStreamCopy;
            }

            if (breakOnNonKeyFrames !== undefined) {
                localVarQueryParameter['breakOnNonKeyFrames'] = breakOnNonKeyFrames;
            }

            if (audioSampleRate !== undefined) {
                localVarQueryParameter['audioSampleRate'] = audioSampleRate;
            }

            if (maxAudioBitDepth !== undefined) {
                localVarQueryParameter['maxAudioBitDepth'] = maxAudioBitDepth;
            }

            if (audioBitRate !== undefined) {
                localVarQueryParameter['audioBitRate'] = audioBitRate;
            }

            if (audioChannels !== undefined) {
                localVarQueryParameter['audioChannels'] = audioChannels;
            }

            if (maxAudioChannels !== undefined) {
                localVarQueryParameter['maxAudioChannels'] = maxAudioChannels;
            }

            if (profile !== undefined) {
                localVarQueryParameter['profile'] = profile;
            }

            if (level !== undefined) {
                localVarQueryParameter['level'] = level;
            }

            if (framerate !== undefined) {
                localVarQueryParameter['framerate'] = framerate;
            }

            if (maxFramerate !== undefined) {
                localVarQueryParameter['maxFramerate'] = maxFramerate;
            }

            if (copyTimestamps !== undefined) {
                localVarQueryParameter['copyTimestamps'] = copyTimestamps;
            }

            if (startTimeTicks !== undefined) {
                localVarQueryParameter['startTimeTicks'] = startTimeTicks;
            }

            if (width !== undefined) {
                localVarQueryParameter['width'] = width;
            }

            if (height !== undefined) {
                localVarQueryParameter['height'] = height;
            }

            if (videoBitRate !== undefined) {
                localVarQueryParameter['videoBitRate'] = videoBitRate;
            }

            if (subtitleStreamIndex !== undefined) {
                localVarQueryParameter['subtitleStreamIndex'] = subtitleStreamIndex;
            }

            if (subtitleMethod !== undefined) {
                localVarQueryParameter['subtitleMethod'] = subtitleMethod;
            }

            if (maxRefFrames !== undefined) {
                localVarQueryParameter['maxRefFrames'] = maxRefFrames;
            }

            if (maxVideoBitDepth !== undefined) {
                localVarQueryParameter['maxVideoBitDepth'] = maxVideoBitDepth;
            }

            if (requireAvc !== undefined) {
                localVarQueryParameter['requireAvc'] = requireAvc;
            }

            if (deInterlace !== undefined) {
                localVarQueryParameter['deInterlace'] = deInterlace;
            }

            if (requireNonAnamorphic !== undefined) {
                localVarQueryParameter['requireNonAnamorphic'] = requireNonAnamorphic;
            }

            if (transcodingMaxAudioChannels !== undefined) {
                localVarQueryParameter['transcodingMaxAudioChannels'] = transcodingMaxAudioChannels;
            }

            if (cpuCoreLimit !== undefined) {
                localVarQueryParameter['cpuCoreLimit'] = cpuCoreLimit;
            }

            if (liveStreamId !== undefined) {
                localVarQueryParameter['liveStreamId'] = liveStreamId;
            }

            if (enableMpegtsM2TsMode !== undefined) {
                localVarQueryParameter['enableMpegtsM2TsMode'] = enableMpegtsM2TsMode;
            }

            if (videoCodec !== undefined) {
                localVarQueryParameter['videoCodec'] = videoCodec;
            }

            if (subtitleCodec !== undefined) {
                localVarQueryParameter['subtitleCodec'] = subtitleCodec;
            }

            if (transcodingReasons !== undefined) {
                localVarQueryParameter['transcodingReasons'] = transcodingReasons;
            }

            if (audioStreamIndex !== undefined) {
                localVarQueryParameter['audioStreamIndex'] = audioStreamIndex;
            }

            if (videoStreamIndex !== undefined) {
                localVarQueryParameter['videoStreamIndex'] = videoStreamIndex;
            }

            if (context !== undefined) {
                localVarQueryParameter['context'] = context;
            }

            if (streamOptions !== undefined) {
                localVarQueryParameter['streamOptions'] = streamOptions;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets a video stream using HTTP live streaming.
         * @param {string} itemId The item id.
         * @param {boolean} [_static] Optional. If true, the original file will be streamed statically without any encoding. Use either no url extension or the original file extension. true/false.
         * @param {string} [params] The streaming parameters.
         * @param {string} [tag] The tag.
         * @param {string} [deviceProfileId] Optional. The dlna device profile id to utilize.
         * @param {string} [playSessionId] The play session id.
         * @param {string} [segmentContainer] The segment container.
         * @param {number} [segmentLength] The segment lenght.
         * @param {number} [minSegments] The minimum number of segments.
         * @param {string} [mediaSourceId] The media version id, if playing an alternate version.
         * @param {string} [deviceId] The device id of the client requesting. Used to stop encoding processes when needed.
         * @param {string} [audioCodec] Optional. Specify a audio codec to encode to, e.g. mp3. If omitted the server will auto-select using the url\&#39;s extension. Options: aac, mp3, vorbis, wma.
         * @param {boolean} [enableAutoStreamCopy] Whether or not to allow automatic stream copy if requested values match the original source. Defaults to true.
         * @param {boolean} [allowVideoStreamCopy] Whether or not to allow copying of the video stream url.
         * @param {boolean} [allowAudioStreamCopy] Whether or not to allow copying of the audio stream url.
         * @param {boolean} [breakOnNonKeyFrames] Optional. Whether to break on non key frames.
         * @param {number} [audioSampleRate] Optional. Specify a specific audio sample rate, e.g. 44100.
         * @param {number} [maxAudioBitDepth] Optional. The maximum audio bit depth.
         * @param {number} [audioBitRate] Optional. Specify an audio bitrate to encode to, e.g. 128000. If omitted this will be left to encoder defaults.
         * @param {number} [audioChannels] Optional. Specify a specific number of audio channels to encode to, e.g. 2.
         * @param {number} [maxAudioChannels] Optional. Specify a maximum number of audio channels to encode to, e.g. 2.
         * @param {string} [profile] Optional. Specify a specific an encoder profile (varies by encoder), e.g. main, baseline, high.
         * @param {string} [level] Optional. Specify a level for the encoder profile (varies by encoder), e.g. 3, 3.1.
         * @param {number} [framerate] Optional. A specific video framerate to encode to, e.g. 23.976. Generally this should be omitted unless the device has specific requirements.
         * @param {number} [maxFramerate] Optional. A specific maximum video framerate to encode to, e.g. 23.976. Generally this should be omitted unless the device has specific requirements.
         * @param {boolean} [copyTimestamps] Whether or not to copy timestamps when transcoding with an offset. Defaults to false.
         * @param {number} [startTimeTicks] Optional. Specify a starting offset, in ticks. 1 tick &#x3D; 10000 ms.
         * @param {number} [width] Optional. The fixed horizontal resolution of the encoded video.
         * @param {number} [height] Optional. The fixed vertical resolution of the encoded video.
         * @param {number} [videoBitRate] Optional. Specify a video bitrate to encode to, e.g. 500000. If omitted this will be left to encoder defaults.
         * @param {number} [subtitleStreamIndex] Optional. The index of the subtitle stream to use. If omitted no subtitles will be used.
         * @param {SubtitleDeliveryMethod} [subtitleMethod] Optional. Specify the subtitle delivery method.
         * @param {number} [maxRefFrames] Optional.
         * @param {number} [maxVideoBitDepth] Optional. The maximum video bit depth.
         * @param {boolean} [requireAvc] Optional. Whether to require avc.
         * @param {boolean} [deInterlace] Optional. Whether to deinterlace the video.
         * @param {boolean} [requireNonAnamorphic] Optional. Whether to require a non anamporphic stream.
         * @param {number} [transcodingMaxAudioChannels] Optional. The maximum number of audio channels to transcode.
         * @param {number} [cpuCoreLimit] Optional. The limit of how many cpu cores to use.
         * @param {string} [liveStreamId] The live stream id.
         * @param {boolean} [enableMpegtsM2TsMode] Optional. Whether to enable the MpegtsM2Ts mode.
         * @param {string} [videoCodec] Optional. Specify a video codec to encode to, e.g. h264. If omitted the server will auto-select using the url\&#39;s extension. Options: h265, h264, mpeg4, theora, vpx, wmv.
         * @param {string} [subtitleCodec] Optional. Specify a subtitle codec to encode to.
         * @param {string} [transcodingReasons] Optional. The transcoding reason.
         * @param {number} [audioStreamIndex] Optional. The index of the audio stream to use. If omitted the first audio stream will be used.
         * @param {number} [videoStreamIndex] Optional. The index of the video stream to use. If omitted the first video stream will be used.
         * @param {EncodingContext} [context] Optional. The MediaBrowser.Model.Dlna.EncodingContext.
         * @param {{ [key: string]: string; }} [streamOptions] Optional. The streaming options.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVariantHlsVideoPlaylist: async (itemId: string, _static?: boolean, params?: string, tag?: string, deviceProfileId?: string, playSessionId?: string, segmentContainer?: string, segmentLength?: number, minSegments?: number, mediaSourceId?: string, deviceId?: string, audioCodec?: string, enableAutoStreamCopy?: boolean, allowVideoStreamCopy?: boolean, allowAudioStreamCopy?: boolean, breakOnNonKeyFrames?: boolean, audioSampleRate?: number, maxAudioBitDepth?: number, audioBitRate?: number, audioChannels?: number, maxAudioChannels?: number, profile?: string, level?: string, framerate?: number, maxFramerate?: number, copyTimestamps?: boolean, startTimeTicks?: number, width?: number, height?: number, videoBitRate?: number, subtitleStreamIndex?: number, subtitleMethod?: SubtitleDeliveryMethod, maxRefFrames?: number, maxVideoBitDepth?: number, requireAvc?: boolean, deInterlace?: boolean, requireNonAnamorphic?: boolean, transcodingMaxAudioChannels?: number, cpuCoreLimit?: number, liveStreamId?: string, enableMpegtsM2TsMode?: boolean, videoCodec?: string, subtitleCodec?: string, transcodingReasons?: string, audioStreamIndex?: number, videoStreamIndex?: number, context?: EncodingContext, streamOptions?: { [key: string]: string; }, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'itemId' is not null or undefined
            if (itemId === null || itemId === undefined) {
                throw new RequiredError('itemId','Required parameter itemId was null or undefined when calling getVariantHlsVideoPlaylist.');
            }
            const localVarPath = `/Videos/{itemId}/main.m3u8`
                .replace(`{${"itemId"}}`, encodeURIComponent(String(itemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }

            if (_static !== undefined) {
                localVarQueryParameter['static'] = _static;
            }

            if (params !== undefined) {
                localVarQueryParameter['params'] = params;
            }

            if (tag !== undefined) {
                localVarQueryParameter['tag'] = tag;
            }

            if (deviceProfileId !== undefined) {
                localVarQueryParameter['deviceProfileId'] = deviceProfileId;
            }

            if (playSessionId !== undefined) {
                localVarQueryParameter['playSessionId'] = playSessionId;
            }

            if (segmentContainer !== undefined) {
                localVarQueryParameter['segmentContainer'] = segmentContainer;
            }

            if (segmentLength !== undefined) {
                localVarQueryParameter['segmentLength'] = segmentLength;
            }

            if (minSegments !== undefined) {
                localVarQueryParameter['minSegments'] = minSegments;
            }

            if (mediaSourceId !== undefined) {
                localVarQueryParameter['mediaSourceId'] = mediaSourceId;
            }

            if (deviceId !== undefined) {
                localVarQueryParameter['deviceId'] = deviceId;
            }

            if (audioCodec !== undefined) {
                localVarQueryParameter['audioCodec'] = audioCodec;
            }

            if (enableAutoStreamCopy !== undefined) {
                localVarQueryParameter['enableAutoStreamCopy'] = enableAutoStreamCopy;
            }

            if (allowVideoStreamCopy !== undefined) {
                localVarQueryParameter['allowVideoStreamCopy'] = allowVideoStreamCopy;
            }

            if (allowAudioStreamCopy !== undefined) {
                localVarQueryParameter['allowAudioStreamCopy'] = allowAudioStreamCopy;
            }

            if (breakOnNonKeyFrames !== undefined) {
                localVarQueryParameter['breakOnNonKeyFrames'] = breakOnNonKeyFrames;
            }

            if (audioSampleRate !== undefined) {
                localVarQueryParameter['audioSampleRate'] = audioSampleRate;
            }

            if (maxAudioBitDepth !== undefined) {
                localVarQueryParameter['maxAudioBitDepth'] = maxAudioBitDepth;
            }

            if (audioBitRate !== undefined) {
                localVarQueryParameter['audioBitRate'] = audioBitRate;
            }

            if (audioChannels !== undefined) {
                localVarQueryParameter['audioChannels'] = audioChannels;
            }

            if (maxAudioChannels !== undefined) {
                localVarQueryParameter['maxAudioChannels'] = maxAudioChannels;
            }

            if (profile !== undefined) {
                localVarQueryParameter['profile'] = profile;
            }

            if (level !== undefined) {
                localVarQueryParameter['level'] = level;
            }

            if (framerate !== undefined) {
                localVarQueryParameter['framerate'] = framerate;
            }

            if (maxFramerate !== undefined) {
                localVarQueryParameter['maxFramerate'] = maxFramerate;
            }

            if (copyTimestamps !== undefined) {
                localVarQueryParameter['copyTimestamps'] = copyTimestamps;
            }

            if (startTimeTicks !== undefined) {
                localVarQueryParameter['startTimeTicks'] = startTimeTicks;
            }

            if (width !== undefined) {
                localVarQueryParameter['width'] = width;
            }

            if (height !== undefined) {
                localVarQueryParameter['height'] = height;
            }

            if (videoBitRate !== undefined) {
                localVarQueryParameter['videoBitRate'] = videoBitRate;
            }

            if (subtitleStreamIndex !== undefined) {
                localVarQueryParameter['subtitleStreamIndex'] = subtitleStreamIndex;
            }

            if (subtitleMethod !== undefined) {
                localVarQueryParameter['subtitleMethod'] = subtitleMethod;
            }

            if (maxRefFrames !== undefined) {
                localVarQueryParameter['maxRefFrames'] = maxRefFrames;
            }

            if (maxVideoBitDepth !== undefined) {
                localVarQueryParameter['maxVideoBitDepth'] = maxVideoBitDepth;
            }

            if (requireAvc !== undefined) {
                localVarQueryParameter['requireAvc'] = requireAvc;
            }

            if (deInterlace !== undefined) {
                localVarQueryParameter['deInterlace'] = deInterlace;
            }

            if (requireNonAnamorphic !== undefined) {
                localVarQueryParameter['requireNonAnamorphic'] = requireNonAnamorphic;
            }

            if (transcodingMaxAudioChannels !== undefined) {
                localVarQueryParameter['transcodingMaxAudioChannels'] = transcodingMaxAudioChannels;
            }

            if (cpuCoreLimit !== undefined) {
                localVarQueryParameter['cpuCoreLimit'] = cpuCoreLimit;
            }

            if (liveStreamId !== undefined) {
                localVarQueryParameter['liveStreamId'] = liveStreamId;
            }

            if (enableMpegtsM2TsMode !== undefined) {
                localVarQueryParameter['enableMpegtsM2TsMode'] = enableMpegtsM2TsMode;
            }

            if (videoCodec !== undefined) {
                localVarQueryParameter['videoCodec'] = videoCodec;
            }

            if (subtitleCodec !== undefined) {
                localVarQueryParameter['subtitleCodec'] = subtitleCodec;
            }

            if (transcodingReasons !== undefined) {
                localVarQueryParameter['transcodingReasons'] = transcodingReasons;
            }

            if (audioStreamIndex !== undefined) {
                localVarQueryParameter['audioStreamIndex'] = audioStreamIndex;
            }

            if (videoStreamIndex !== undefined) {
                localVarQueryParameter['videoStreamIndex'] = videoStreamIndex;
            }

            if (context !== undefined) {
                localVarQueryParameter['context'] = context;
            }

            if (streamOptions !== undefined) {
                localVarQueryParameter['streamOptions'] = streamOptions;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets an audio hls playlist stream.
         * @param {string} itemId The item id.
         * @param {string} mediaSourceId The media version id, if playing an alternate version.
         * @param {boolean} [_static] Optional. If true, the original file will be streamed statically without any encoding. Use either no url extension or the original file extension. true/false.
         * @param {string} [params] The streaming parameters.
         * @param {string} [tag] The tag.
         * @param {string} [deviceProfileId] Optional. The dlna device profile id to utilize.
         * @param {string} [playSessionId] The play session id.
         * @param {string} [segmentContainer] The segment container.
         * @param {number} [segmentLength] The segment lenght.
         * @param {number} [minSegments] The minimum number of segments.
         * @param {string} [deviceId] The device id of the client requesting. Used to stop encoding processes when needed.
         * @param {string} [audioCodec] Optional. Specify a audio codec to encode to, e.g. mp3. If omitted the server will auto-select using the url\&#39;s extension. Options: aac, mp3, vorbis, wma.
         * @param {boolean} [enableAutoStreamCopy] Whether or not to allow automatic stream copy if requested values match the original source. Defaults to true.
         * @param {boolean} [allowVideoStreamCopy] Whether or not to allow copying of the video stream url.
         * @param {boolean} [allowAudioStreamCopy] Whether or not to allow copying of the audio stream url.
         * @param {boolean} [breakOnNonKeyFrames] Optional. Whether to break on non key frames.
         * @param {number} [audioSampleRate] Optional. Specify a specific audio sample rate, e.g. 44100.
         * @param {number} [maxAudioBitDepth] Optional. The maximum audio bit depth.
         * @param {number} [audioBitRate] Optional. Specify an audio bitrate to encode to, e.g. 128000. If omitted this will be left to encoder defaults.
         * @param {number} [audioChannels] Optional. Specify a specific number of audio channels to encode to, e.g. 2.
         * @param {number} [maxAudioChannels] Optional. Specify a maximum number of audio channels to encode to, e.g. 2.
         * @param {string} [profile] Optional. Specify a specific an encoder profile (varies by encoder), e.g. main, baseline, high.
         * @param {string} [level] Optional. Specify a level for the encoder profile (varies by encoder), e.g. 3, 3.1.
         * @param {number} [framerate] Optional. A specific video framerate to encode to, e.g. 23.976. Generally this should be omitted unless the device has specific requirements.
         * @param {number} [maxFramerate] Optional. A specific maximum video framerate to encode to, e.g. 23.976. Generally this should be omitted unless the device has specific requirements.
         * @param {boolean} [copyTimestamps] Whether or not to copy timestamps when transcoding with an offset. Defaults to false.
         * @param {number} [startTimeTicks] Optional. Specify a starting offset, in ticks. 1 tick &#x3D; 10000 ms.
         * @param {number} [width] Optional. The fixed horizontal resolution of the encoded video.
         * @param {number} [height] Optional. The fixed vertical resolution of the encoded video.
         * @param {number} [videoBitRate] Optional. Specify a video bitrate to encode to, e.g. 500000. If omitted this will be left to encoder defaults.
         * @param {number} [subtitleStreamIndex] Optional. The index of the subtitle stream to use. If omitted no subtitles will be used.
         * @param {SubtitleDeliveryMethod} [subtitleMethod] Optional. Specify the subtitle delivery method.
         * @param {number} [maxRefFrames] Optional.
         * @param {number} [maxVideoBitDepth] Optional. The maximum video bit depth.
         * @param {boolean} [requireAvc] Optional. Whether to require avc.
         * @param {boolean} [deInterlace] Optional. Whether to deinterlace the video.
         * @param {boolean} [requireNonAnamorphic] Optional. Whether to require a non anamporphic stream.
         * @param {number} [transcodingMaxAudioChannels] Optional. The maximum number of audio channels to transcode.
         * @param {number} [cpuCoreLimit] Optional. The limit of how many cpu cores to use.
         * @param {string} [liveStreamId] The live stream id.
         * @param {boolean} [enableMpegtsM2TsMode] Optional. Whether to enable the MpegtsM2Ts mode.
         * @param {string} [videoCodec] Optional. Specify a video codec to encode to, e.g. h264. If omitted the server will auto-select using the url\&#39;s extension. Options: h265, h264, mpeg4, theora, vpx, wmv.
         * @param {string} [subtitleCodec] Optional. Specify a subtitle codec to encode to.
         * @param {string} [transcodingReasons] Optional. The transcoding reason.
         * @param {number} [audioStreamIndex] Optional. The index of the audio stream to use. If omitted the first audio stream will be used.
         * @param {number} [videoStreamIndex] Optional. The index of the video stream to use. If omitted the first video stream will be used.
         * @param {EncodingContext} [context] Optional. The MediaBrowser.Model.Dlna.EncodingContext.
         * @param {{ [key: string]: string; }} [streamOptions] Optional. The streaming options.
         * @param {boolean} [enableAdaptiveBitrateStreaming] Enable adaptive bitrate streaming.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        headMasterHlsAudioPlaylist: async (itemId: string, mediaSourceId: string, _static?: boolean, params?: string, tag?: string, deviceProfileId?: string, playSessionId?: string, segmentContainer?: string, segmentLength?: number, minSegments?: number, deviceId?: string, audioCodec?: string, enableAutoStreamCopy?: boolean, allowVideoStreamCopy?: boolean, allowAudioStreamCopy?: boolean, breakOnNonKeyFrames?: boolean, audioSampleRate?: number, maxAudioBitDepth?: number, audioBitRate?: number, audioChannels?: number, maxAudioChannels?: number, profile?: string, level?: string, framerate?: number, maxFramerate?: number, copyTimestamps?: boolean, startTimeTicks?: number, width?: number, height?: number, videoBitRate?: number, subtitleStreamIndex?: number, subtitleMethod?: SubtitleDeliveryMethod, maxRefFrames?: number, maxVideoBitDepth?: number, requireAvc?: boolean, deInterlace?: boolean, requireNonAnamorphic?: boolean, transcodingMaxAudioChannels?: number, cpuCoreLimit?: number, liveStreamId?: string, enableMpegtsM2TsMode?: boolean, videoCodec?: string, subtitleCodec?: string, transcodingReasons?: string, audioStreamIndex?: number, videoStreamIndex?: number, context?: EncodingContext, streamOptions?: { [key: string]: string; }, enableAdaptiveBitrateStreaming?: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'itemId' is not null or undefined
            if (itemId === null || itemId === undefined) {
                throw new RequiredError('itemId','Required parameter itemId was null or undefined when calling headMasterHlsAudioPlaylist.');
            }
            // verify required parameter 'mediaSourceId' is not null or undefined
            if (mediaSourceId === null || mediaSourceId === undefined) {
                throw new RequiredError('mediaSourceId','Required parameter mediaSourceId was null or undefined when calling headMasterHlsAudioPlaylist.');
            }
            const localVarPath = `/Audio/{itemId}/master.m3u8`
                .replace(`{${"itemId"}}`, encodeURIComponent(String(itemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'HEAD', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }

            if (_static !== undefined) {
                localVarQueryParameter['static'] = _static;
            }

            if (params !== undefined) {
                localVarQueryParameter['params'] = params;
            }

            if (tag !== undefined) {
                localVarQueryParameter['tag'] = tag;
            }

            if (deviceProfileId !== undefined) {
                localVarQueryParameter['deviceProfileId'] = deviceProfileId;
            }

            if (playSessionId !== undefined) {
                localVarQueryParameter['playSessionId'] = playSessionId;
            }

            if (segmentContainer !== undefined) {
                localVarQueryParameter['segmentContainer'] = segmentContainer;
            }

            if (segmentLength !== undefined) {
                localVarQueryParameter['segmentLength'] = segmentLength;
            }

            if (minSegments !== undefined) {
                localVarQueryParameter['minSegments'] = minSegments;
            }

            if (mediaSourceId !== undefined) {
                localVarQueryParameter['mediaSourceId'] = mediaSourceId;
            }

            if (deviceId !== undefined) {
                localVarQueryParameter['deviceId'] = deviceId;
            }

            if (audioCodec !== undefined) {
                localVarQueryParameter['audioCodec'] = audioCodec;
            }

            if (enableAutoStreamCopy !== undefined) {
                localVarQueryParameter['enableAutoStreamCopy'] = enableAutoStreamCopy;
            }

            if (allowVideoStreamCopy !== undefined) {
                localVarQueryParameter['allowVideoStreamCopy'] = allowVideoStreamCopy;
            }

            if (allowAudioStreamCopy !== undefined) {
                localVarQueryParameter['allowAudioStreamCopy'] = allowAudioStreamCopy;
            }

            if (breakOnNonKeyFrames !== undefined) {
                localVarQueryParameter['breakOnNonKeyFrames'] = breakOnNonKeyFrames;
            }

            if (audioSampleRate !== undefined) {
                localVarQueryParameter['audioSampleRate'] = audioSampleRate;
            }

            if (maxAudioBitDepth !== undefined) {
                localVarQueryParameter['maxAudioBitDepth'] = maxAudioBitDepth;
            }

            if (audioBitRate !== undefined) {
                localVarQueryParameter['audioBitRate'] = audioBitRate;
            }

            if (audioChannels !== undefined) {
                localVarQueryParameter['audioChannels'] = audioChannels;
            }

            if (maxAudioChannels !== undefined) {
                localVarQueryParameter['maxAudioChannels'] = maxAudioChannels;
            }

            if (profile !== undefined) {
                localVarQueryParameter['profile'] = profile;
            }

            if (level !== undefined) {
                localVarQueryParameter['level'] = level;
            }

            if (framerate !== undefined) {
                localVarQueryParameter['framerate'] = framerate;
            }

            if (maxFramerate !== undefined) {
                localVarQueryParameter['maxFramerate'] = maxFramerate;
            }

            if (copyTimestamps !== undefined) {
                localVarQueryParameter['copyTimestamps'] = copyTimestamps;
            }

            if (startTimeTicks !== undefined) {
                localVarQueryParameter['startTimeTicks'] = startTimeTicks;
            }

            if (width !== undefined) {
                localVarQueryParameter['width'] = width;
            }

            if (height !== undefined) {
                localVarQueryParameter['height'] = height;
            }

            if (videoBitRate !== undefined) {
                localVarQueryParameter['videoBitRate'] = videoBitRate;
            }

            if (subtitleStreamIndex !== undefined) {
                localVarQueryParameter['subtitleStreamIndex'] = subtitleStreamIndex;
            }

            if (subtitleMethod !== undefined) {
                localVarQueryParameter['subtitleMethod'] = subtitleMethod;
            }

            if (maxRefFrames !== undefined) {
                localVarQueryParameter['maxRefFrames'] = maxRefFrames;
            }

            if (maxVideoBitDepth !== undefined) {
                localVarQueryParameter['maxVideoBitDepth'] = maxVideoBitDepth;
            }

            if (requireAvc !== undefined) {
                localVarQueryParameter['requireAvc'] = requireAvc;
            }

            if (deInterlace !== undefined) {
                localVarQueryParameter['deInterlace'] = deInterlace;
            }

            if (requireNonAnamorphic !== undefined) {
                localVarQueryParameter['requireNonAnamorphic'] = requireNonAnamorphic;
            }

            if (transcodingMaxAudioChannels !== undefined) {
                localVarQueryParameter['transcodingMaxAudioChannels'] = transcodingMaxAudioChannels;
            }

            if (cpuCoreLimit !== undefined) {
                localVarQueryParameter['cpuCoreLimit'] = cpuCoreLimit;
            }

            if (liveStreamId !== undefined) {
                localVarQueryParameter['liveStreamId'] = liveStreamId;
            }

            if (enableMpegtsM2TsMode !== undefined) {
                localVarQueryParameter['enableMpegtsM2TsMode'] = enableMpegtsM2TsMode;
            }

            if (videoCodec !== undefined) {
                localVarQueryParameter['videoCodec'] = videoCodec;
            }

            if (subtitleCodec !== undefined) {
                localVarQueryParameter['subtitleCodec'] = subtitleCodec;
            }

            if (transcodingReasons !== undefined) {
                localVarQueryParameter['transcodingReasons'] = transcodingReasons;
            }

            if (audioStreamIndex !== undefined) {
                localVarQueryParameter['audioStreamIndex'] = audioStreamIndex;
            }

            if (videoStreamIndex !== undefined) {
                localVarQueryParameter['videoStreamIndex'] = videoStreamIndex;
            }

            if (context !== undefined) {
                localVarQueryParameter['context'] = context;
            }

            if (streamOptions !== undefined) {
                localVarQueryParameter['streamOptions'] = streamOptions;
            }

            if (enableAdaptiveBitrateStreaming !== undefined) {
                localVarQueryParameter['enableAdaptiveBitrateStreaming'] = enableAdaptiveBitrateStreaming;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets a video hls playlist stream.
         * @param {string} itemId The item id.
         * @param {string} mediaSourceId The media version id, if playing an alternate version.
         * @param {boolean} [_static] Optional. If true, the original file will be streamed statically without any encoding. Use either no url extension or the original file extension. true/false.
         * @param {string} [params] The streaming parameters.
         * @param {string} [tag] The tag.
         * @param {string} [deviceProfileId] Optional. The dlna device profile id to utilize.
         * @param {string} [playSessionId] The play session id.
         * @param {string} [segmentContainer] The segment container.
         * @param {number} [segmentLength] The segment lenght.
         * @param {number} [minSegments] The minimum number of segments.
         * @param {string} [deviceId] The device id of the client requesting. Used to stop encoding processes when needed.
         * @param {string} [audioCodec] Optional. Specify a audio codec to encode to, e.g. mp3. If omitted the server will auto-select using the url\&#39;s extension. Options: aac, mp3, vorbis, wma.
         * @param {boolean} [enableAutoStreamCopy] Whether or not to allow automatic stream copy if requested values match the original source. Defaults to true.
         * @param {boolean} [allowVideoStreamCopy] Whether or not to allow copying of the video stream url.
         * @param {boolean} [allowAudioStreamCopy] Whether or not to allow copying of the audio stream url.
         * @param {boolean} [breakOnNonKeyFrames] Optional. Whether to break on non key frames.
         * @param {number} [audioSampleRate] Optional. Specify a specific audio sample rate, e.g. 44100.
         * @param {number} [maxAudioBitDepth] Optional. The maximum audio bit depth.
         * @param {number} [audioBitRate] Optional. Specify an audio bitrate to encode to, e.g. 128000. If omitted this will be left to encoder defaults.
         * @param {number} [audioChannels] Optional. Specify a specific number of audio channels to encode to, e.g. 2.
         * @param {number} [maxAudioChannels] Optional. Specify a maximum number of audio channels to encode to, e.g. 2.
         * @param {string} [profile] Optional. Specify a specific an encoder profile (varies by encoder), e.g. main, baseline, high.
         * @param {string} [level] Optional. Specify a level for the encoder profile (varies by encoder), e.g. 3, 3.1.
         * @param {number} [framerate] Optional. A specific video framerate to encode to, e.g. 23.976. Generally this should be omitted unless the device has specific requirements.
         * @param {number} [maxFramerate] Optional. A specific maximum video framerate to encode to, e.g. 23.976. Generally this should be omitted unless the device has specific requirements.
         * @param {boolean} [copyTimestamps] Whether or not to copy timestamps when transcoding with an offset. Defaults to false.
         * @param {number} [startTimeTicks] Optional. Specify a starting offset, in ticks. 1 tick &#x3D; 10000 ms.
         * @param {number} [width] Optional. The fixed horizontal resolution of the encoded video.
         * @param {number} [height] Optional. The fixed vertical resolution of the encoded video.
         * @param {number} [videoBitRate] Optional. Specify a video bitrate to encode to, e.g. 500000. If omitted this will be left to encoder defaults.
         * @param {number} [subtitleStreamIndex] Optional. The index of the subtitle stream to use. If omitted no subtitles will be used.
         * @param {SubtitleDeliveryMethod} [subtitleMethod] Optional. Specify the subtitle delivery method.
         * @param {number} [maxRefFrames] Optional.
         * @param {number} [maxVideoBitDepth] Optional. The maximum video bit depth.
         * @param {boolean} [requireAvc] Optional. Whether to require avc.
         * @param {boolean} [deInterlace] Optional. Whether to deinterlace the video.
         * @param {boolean} [requireNonAnamorphic] Optional. Whether to require a non anamporphic stream.
         * @param {number} [transcodingMaxAudioChannels] Optional. The maximum number of audio channels to transcode.
         * @param {number} [cpuCoreLimit] Optional. The limit of how many cpu cores to use.
         * @param {string} [liveStreamId] The live stream id.
         * @param {boolean} [enableMpegtsM2TsMode] Optional. Whether to enable the MpegtsM2Ts mode.
         * @param {string} [videoCodec] Optional. Specify a video codec to encode to, e.g. h264. If omitted the server will auto-select using the url\&#39;s extension. Options: h265, h264, mpeg4, theora, vpx, wmv.
         * @param {string} [subtitleCodec] Optional. Specify a subtitle codec to encode to.
         * @param {string} [transcodingReasons] Optional. The transcoding reason.
         * @param {number} [audioStreamIndex] Optional. The index of the audio stream to use. If omitted the first audio stream will be used.
         * @param {number} [videoStreamIndex] Optional. The index of the video stream to use. If omitted the first video stream will be used.
         * @param {EncodingContext} [context] Optional. The MediaBrowser.Model.Dlna.EncodingContext.
         * @param {{ [key: string]: string; }} [streamOptions] Optional. The streaming options.
         * @param {boolean} [enableAdaptiveBitrateStreaming] Enable adaptive bitrate streaming.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        headMasterHlsVideoPlaylist: async (itemId: string, mediaSourceId: string, _static?: boolean, params?: string, tag?: string, deviceProfileId?: string, playSessionId?: string, segmentContainer?: string, segmentLength?: number, minSegments?: number, deviceId?: string, audioCodec?: string, enableAutoStreamCopy?: boolean, allowVideoStreamCopy?: boolean, allowAudioStreamCopy?: boolean, breakOnNonKeyFrames?: boolean, audioSampleRate?: number, maxAudioBitDepth?: number, audioBitRate?: number, audioChannels?: number, maxAudioChannels?: number, profile?: string, level?: string, framerate?: number, maxFramerate?: number, copyTimestamps?: boolean, startTimeTicks?: number, width?: number, height?: number, videoBitRate?: number, subtitleStreamIndex?: number, subtitleMethod?: SubtitleDeliveryMethod, maxRefFrames?: number, maxVideoBitDepth?: number, requireAvc?: boolean, deInterlace?: boolean, requireNonAnamorphic?: boolean, transcodingMaxAudioChannels?: number, cpuCoreLimit?: number, liveStreamId?: string, enableMpegtsM2TsMode?: boolean, videoCodec?: string, subtitleCodec?: string, transcodingReasons?: string, audioStreamIndex?: number, videoStreamIndex?: number, context?: EncodingContext, streamOptions?: { [key: string]: string; }, enableAdaptiveBitrateStreaming?: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'itemId' is not null or undefined
            if (itemId === null || itemId === undefined) {
                throw new RequiredError('itemId','Required parameter itemId was null or undefined when calling headMasterHlsVideoPlaylist.');
            }
            // verify required parameter 'mediaSourceId' is not null or undefined
            if (mediaSourceId === null || mediaSourceId === undefined) {
                throw new RequiredError('mediaSourceId','Required parameter mediaSourceId was null or undefined when calling headMasterHlsVideoPlaylist.');
            }
            const localVarPath = `/Videos/{itemId}/master.m3u8`
                .replace(`{${"itemId"}}`, encodeURIComponent(String(itemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'HEAD', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }

            if (_static !== undefined) {
                localVarQueryParameter['static'] = _static;
            }

            if (params !== undefined) {
                localVarQueryParameter['params'] = params;
            }

            if (tag !== undefined) {
                localVarQueryParameter['tag'] = tag;
            }

            if (deviceProfileId !== undefined) {
                localVarQueryParameter['deviceProfileId'] = deviceProfileId;
            }

            if (playSessionId !== undefined) {
                localVarQueryParameter['playSessionId'] = playSessionId;
            }

            if (segmentContainer !== undefined) {
                localVarQueryParameter['segmentContainer'] = segmentContainer;
            }

            if (segmentLength !== undefined) {
                localVarQueryParameter['segmentLength'] = segmentLength;
            }

            if (minSegments !== undefined) {
                localVarQueryParameter['minSegments'] = minSegments;
            }

            if (mediaSourceId !== undefined) {
                localVarQueryParameter['mediaSourceId'] = mediaSourceId;
            }

            if (deviceId !== undefined) {
                localVarQueryParameter['deviceId'] = deviceId;
            }

            if (audioCodec !== undefined) {
                localVarQueryParameter['audioCodec'] = audioCodec;
            }

            if (enableAutoStreamCopy !== undefined) {
                localVarQueryParameter['enableAutoStreamCopy'] = enableAutoStreamCopy;
            }

            if (allowVideoStreamCopy !== undefined) {
                localVarQueryParameter['allowVideoStreamCopy'] = allowVideoStreamCopy;
            }

            if (allowAudioStreamCopy !== undefined) {
                localVarQueryParameter['allowAudioStreamCopy'] = allowAudioStreamCopy;
            }

            if (breakOnNonKeyFrames !== undefined) {
                localVarQueryParameter['breakOnNonKeyFrames'] = breakOnNonKeyFrames;
            }

            if (audioSampleRate !== undefined) {
                localVarQueryParameter['audioSampleRate'] = audioSampleRate;
            }

            if (maxAudioBitDepth !== undefined) {
                localVarQueryParameter['maxAudioBitDepth'] = maxAudioBitDepth;
            }

            if (audioBitRate !== undefined) {
                localVarQueryParameter['audioBitRate'] = audioBitRate;
            }

            if (audioChannels !== undefined) {
                localVarQueryParameter['audioChannels'] = audioChannels;
            }

            if (maxAudioChannels !== undefined) {
                localVarQueryParameter['maxAudioChannels'] = maxAudioChannels;
            }

            if (profile !== undefined) {
                localVarQueryParameter['profile'] = profile;
            }

            if (level !== undefined) {
                localVarQueryParameter['level'] = level;
            }

            if (framerate !== undefined) {
                localVarQueryParameter['framerate'] = framerate;
            }

            if (maxFramerate !== undefined) {
                localVarQueryParameter['maxFramerate'] = maxFramerate;
            }

            if (copyTimestamps !== undefined) {
                localVarQueryParameter['copyTimestamps'] = copyTimestamps;
            }

            if (startTimeTicks !== undefined) {
                localVarQueryParameter['startTimeTicks'] = startTimeTicks;
            }

            if (width !== undefined) {
                localVarQueryParameter['width'] = width;
            }

            if (height !== undefined) {
                localVarQueryParameter['height'] = height;
            }

            if (videoBitRate !== undefined) {
                localVarQueryParameter['videoBitRate'] = videoBitRate;
            }

            if (subtitleStreamIndex !== undefined) {
                localVarQueryParameter['subtitleStreamIndex'] = subtitleStreamIndex;
            }

            if (subtitleMethod !== undefined) {
                localVarQueryParameter['subtitleMethod'] = subtitleMethod;
            }

            if (maxRefFrames !== undefined) {
                localVarQueryParameter['maxRefFrames'] = maxRefFrames;
            }

            if (maxVideoBitDepth !== undefined) {
                localVarQueryParameter['maxVideoBitDepth'] = maxVideoBitDepth;
            }

            if (requireAvc !== undefined) {
                localVarQueryParameter['requireAvc'] = requireAvc;
            }

            if (deInterlace !== undefined) {
                localVarQueryParameter['deInterlace'] = deInterlace;
            }

            if (requireNonAnamorphic !== undefined) {
                localVarQueryParameter['requireNonAnamorphic'] = requireNonAnamorphic;
            }

            if (transcodingMaxAudioChannels !== undefined) {
                localVarQueryParameter['transcodingMaxAudioChannels'] = transcodingMaxAudioChannels;
            }

            if (cpuCoreLimit !== undefined) {
                localVarQueryParameter['cpuCoreLimit'] = cpuCoreLimit;
            }

            if (liveStreamId !== undefined) {
                localVarQueryParameter['liveStreamId'] = liveStreamId;
            }

            if (enableMpegtsM2TsMode !== undefined) {
                localVarQueryParameter['enableMpegtsM2TsMode'] = enableMpegtsM2TsMode;
            }

            if (videoCodec !== undefined) {
                localVarQueryParameter['videoCodec'] = videoCodec;
            }

            if (subtitleCodec !== undefined) {
                localVarQueryParameter['subtitleCodec'] = subtitleCodec;
            }

            if (transcodingReasons !== undefined) {
                localVarQueryParameter['transcodingReasons'] = transcodingReasons;
            }

            if (audioStreamIndex !== undefined) {
                localVarQueryParameter['audioStreamIndex'] = audioStreamIndex;
            }

            if (videoStreamIndex !== undefined) {
                localVarQueryParameter['videoStreamIndex'] = videoStreamIndex;
            }

            if (context !== undefined) {
                localVarQueryParameter['context'] = context;
            }

            if (streamOptions !== undefined) {
                localVarQueryParameter['streamOptions'] = streamOptions;
            }

            if (enableAdaptiveBitrateStreaming !== undefined) {
                localVarQueryParameter['enableAdaptiveBitrateStreaming'] = enableAdaptiveBitrateStreaming;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DynamicHlsApi - functional programming interface
 * @export
 */
export const DynamicHlsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Gets a video stream using HTTP live streaming.
         * @param {string} itemId The item id.
         * @param {string} playlistId The playlist id.
         * @param {number} segmentId The segment id.
         * @param {string} container The video container. Possible values are: ts, webm, asf, wmv, ogv, mp4, m4v, mkv, mpeg, mpg, avi, 3gp, wmv, wtv, m2ts, mov, iso, flv.
         * @param {boolean} [_static] Optional. If true, the original file will be streamed statically without any encoding. Use either no url extension or the original file extension. true/false.
         * @param {string} [params] The streaming parameters.
         * @param {string} [tag] The tag.
         * @param {string} [deviceProfileId] Optional. The dlna device profile id to utilize.
         * @param {string} [playSessionId] The play session id.
         * @param {string} [segmentContainer] The segment container.
         * @param {number} [segmentLength] The segment lenght.
         * @param {number} [minSegments] The minimum number of segments.
         * @param {string} [mediaSourceId] The media version id, if playing an alternate version.
         * @param {string} [deviceId] The device id of the client requesting. Used to stop encoding processes when needed.
         * @param {string} [audioCodec] Optional. Specify a audio codec to encode to, e.g. mp3. If omitted the server will auto-select using the url\&#39;s extension. Options: aac, mp3, vorbis, wma.
         * @param {boolean} [enableAutoStreamCopy] Whether or not to allow automatic stream copy if requested values match the original source. Defaults to true.
         * @param {boolean} [allowVideoStreamCopy] Whether or not to allow copying of the video stream url.
         * @param {boolean} [allowAudioStreamCopy] Whether or not to allow copying of the audio stream url.
         * @param {boolean} [breakOnNonKeyFrames] Optional. Whether to break on non key frames.
         * @param {number} [audioSampleRate] Optional. Specify a specific audio sample rate, e.g. 44100.
         * @param {number} [maxAudioBitDepth] Optional. The maximum audio bit depth.
         * @param {number} [audioBitRate] Optional. Specify an audio bitrate to encode to, e.g. 128000. If omitted this will be left to encoder defaults.
         * @param {number} [audioChannels] Optional. Specify a specific number of audio channels to encode to, e.g. 2.
         * @param {number} [maxAudioChannels] Optional. Specify a maximum number of audio channels to encode to, e.g. 2.
         * @param {string} [profile] Optional. Specify a specific an encoder profile (varies by encoder), e.g. main, baseline, high.
         * @param {string} [level] Optional. Specify a level for the encoder profile (varies by encoder), e.g. 3, 3.1.
         * @param {number} [framerate] Optional. A specific video framerate to encode to, e.g. 23.976. Generally this should be omitted unless the device has specific requirements.
         * @param {number} [maxFramerate] Optional. A specific maximum video framerate to encode to, e.g. 23.976. Generally this should be omitted unless the device has specific requirements.
         * @param {boolean} [copyTimestamps] Whether or not to copy timestamps when transcoding with an offset. Defaults to false.
         * @param {number} [startTimeTicks] Optional. Specify a starting offset, in ticks. 1 tick &#x3D; 10000 ms.
         * @param {number} [width] Optional. The fixed horizontal resolution of the encoded video.
         * @param {number} [height] Optional. The fixed vertical resolution of the encoded video.
         * @param {number} [videoBitRate] Optional. Specify a video bitrate to encode to, e.g. 500000. If omitted this will be left to encoder defaults.
         * @param {number} [subtitleStreamIndex] Optional. The index of the subtitle stream to use. If omitted no subtitles will be used.
         * @param {SubtitleDeliveryMethod} [subtitleMethod] Optional. Specify the subtitle delivery method.
         * @param {number} [maxRefFrames] Optional.
         * @param {number} [maxVideoBitDepth] Optional. The maximum video bit depth.
         * @param {boolean} [requireAvc] Optional. Whether to require avc.
         * @param {boolean} [deInterlace] Optional. Whether to deinterlace the video.
         * @param {boolean} [requireNonAnamorphic] Optional. Whether to require a non anamporphic stream.
         * @param {number} [transcodingMaxAudioChannels] Optional. The maximum number of audio channels to transcode.
         * @param {number} [cpuCoreLimit] Optional. The limit of how many cpu cores to use.
         * @param {string} [liveStreamId] The live stream id.
         * @param {boolean} [enableMpegtsM2TsMode] Optional. Whether to enable the MpegtsM2Ts mode.
         * @param {string} [videoCodec] Optional. Specify a video codec to encode to, e.g. h264. If omitted the server will auto-select using the url\&#39;s extension. Options: h265, h264, mpeg4, theora, vpx, wmv.
         * @param {string} [subtitleCodec] Optional. Specify a subtitle codec to encode to.
         * @param {string} [transcodingReasons] Optional. The transcoding reason.
         * @param {number} [audioStreamIndex] Optional. The index of the audio stream to use. If omitted the first audio stream will be used.
         * @param {number} [videoStreamIndex] Optional. The index of the video stream to use. If omitted the first video stream will be used.
         * @param {EncodingContext} [context] Optional. The MediaBrowser.Model.Dlna.EncodingContext.
         * @param {{ [key: string]: string; }} [streamOptions] Optional. The streaming options.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getHlsAudioSegment(itemId: string, playlistId: string, segmentId: number, container: string, _static?: boolean, params?: string, tag?: string, deviceProfileId?: string, playSessionId?: string, segmentContainer?: string, segmentLength?: number, minSegments?: number, mediaSourceId?: string, deviceId?: string, audioCodec?: string, enableAutoStreamCopy?: boolean, allowVideoStreamCopy?: boolean, allowAudioStreamCopy?: boolean, breakOnNonKeyFrames?: boolean, audioSampleRate?: number, maxAudioBitDepth?: number, audioBitRate?: number, audioChannels?: number, maxAudioChannels?: number, profile?: string, level?: string, framerate?: number, maxFramerate?: number, copyTimestamps?: boolean, startTimeTicks?: number, width?: number, height?: number, videoBitRate?: number, subtitleStreamIndex?: number, subtitleMethod?: SubtitleDeliveryMethod, maxRefFrames?: number, maxVideoBitDepth?: number, requireAvc?: boolean, deInterlace?: boolean, requireNonAnamorphic?: boolean, transcodingMaxAudioChannels?: number, cpuCoreLimit?: number, liveStreamId?: string, enableMpegtsM2TsMode?: boolean, videoCodec?: string, subtitleCodec?: string, transcodingReasons?: string, audioStreamIndex?: number, videoStreamIndex?: number, context?: EncodingContext, streamOptions?: { [key: string]: string; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await DynamicHlsApiAxiosParamCreator(configuration).getHlsAudioSegment(itemId, playlistId, segmentId, container, _static, params, tag, deviceProfileId, playSessionId, segmentContainer, segmentLength, minSegments, mediaSourceId, deviceId, audioCodec, enableAutoStreamCopy, allowVideoStreamCopy, allowAudioStreamCopy, breakOnNonKeyFrames, audioSampleRate, maxAudioBitDepth, audioBitRate, audioChannels, maxAudioChannels, profile, level, framerate, maxFramerate, copyTimestamps, startTimeTicks, width, height, videoBitRate, subtitleStreamIndex, subtitleMethod, maxRefFrames, maxVideoBitDepth, requireAvc, deInterlace, requireNonAnamorphic, transcodingMaxAudioChannels, cpuCoreLimit, liveStreamId, enableMpegtsM2TsMode, videoCodec, subtitleCodec, transcodingReasons, audioStreamIndex, videoStreamIndex, context, streamOptions, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Gets a video stream using HTTP live streaming.
         * @param {string} itemId The item id.
         * @param {string} playlistId The playlist id.
         * @param {number} segmentId The segment id.
         * @param {string} container The video container. Possible values are: ts, webm, asf, wmv, ogv, mp4, m4v, mkv, mpeg, mpg, avi, 3gp, wmv, wtv, m2ts, mov, iso, flv.
         * @param {boolean} [_static] Optional. If true, the original file will be streamed statically without any encoding. Use either no url extension or the original file extension. true/false.
         * @param {string} [params] The streaming parameters.
         * @param {string} [tag] The tag.
         * @param {string} [deviceProfileId] Optional. The dlna device profile id to utilize.
         * @param {string} [playSessionId] The play session id.
         * @param {string} [segmentContainer] The segment container.
         * @param {number} [segmentLength] The segment lenght.
         * @param {number} [minSegments] The minimum number of segments.
         * @param {string} [mediaSourceId] The media version id, if playing an alternate version.
         * @param {string} [deviceId] The device id of the client requesting. Used to stop encoding processes when needed.
         * @param {string} [audioCodec] Optional. Specify a audio codec to encode to, e.g. mp3. If omitted the server will auto-select using the url\&#39;s extension. Options: aac, mp3, vorbis, wma.
         * @param {boolean} [enableAutoStreamCopy] Whether or not to allow automatic stream copy if requested values match the original source. Defaults to true.
         * @param {boolean} [allowVideoStreamCopy] Whether or not to allow copying of the video stream url.
         * @param {boolean} [allowAudioStreamCopy] Whether or not to allow copying of the audio stream url.
         * @param {boolean} [breakOnNonKeyFrames] Optional. Whether to break on non key frames.
         * @param {number} [audioSampleRate] Optional. Specify a specific audio sample rate, e.g. 44100.
         * @param {number} [maxAudioBitDepth] Optional. The maximum audio bit depth.
         * @param {number} [audioBitRate] Optional. Specify an audio bitrate to encode to, e.g. 128000. If omitted this will be left to encoder defaults.
         * @param {number} [audioChannels] Optional. Specify a specific number of audio channels to encode to, e.g. 2.
         * @param {number} [maxAudioChannels] Optional. Specify a maximum number of audio channels to encode to, e.g. 2.
         * @param {string} [profile] Optional. Specify a specific an encoder profile (varies by encoder), e.g. main, baseline, high.
         * @param {string} [level] Optional. Specify a level for the encoder profile (varies by encoder), e.g. 3, 3.1.
         * @param {number} [framerate] Optional. A specific video framerate to encode to, e.g. 23.976. Generally this should be omitted unless the device has specific requirements.
         * @param {number} [maxFramerate] Optional. A specific maximum video framerate to encode to, e.g. 23.976. Generally this should be omitted unless the device has specific requirements.
         * @param {boolean} [copyTimestamps] Whether or not to copy timestamps when transcoding with an offset. Defaults to false.
         * @param {number} [startTimeTicks] Optional. Specify a starting offset, in ticks. 1 tick &#x3D; 10000 ms.
         * @param {number} [width] Optional. The fixed horizontal resolution of the encoded video.
         * @param {number} [height] Optional. The fixed vertical resolution of the encoded video.
         * @param {number} [videoBitRate] Optional. Specify a video bitrate to encode to, e.g. 500000. If omitted this will be left to encoder defaults.
         * @param {number} [subtitleStreamIndex] Optional. The index of the subtitle stream to use. If omitted no subtitles will be used.
         * @param {SubtitleDeliveryMethod} [subtitleMethod] Optional. Specify the subtitle delivery method.
         * @param {number} [maxRefFrames] Optional.
         * @param {number} [maxVideoBitDepth] Optional. The maximum video bit depth.
         * @param {boolean} [requireAvc] Optional. Whether to require avc.
         * @param {boolean} [deInterlace] Optional. Whether to deinterlace the video.
         * @param {boolean} [requireNonAnamorphic] Optional. Whether to require a non anamporphic stream.
         * @param {number} [transcodingMaxAudioChannels] Optional. The maximum number of audio channels to transcode.
         * @param {number} [cpuCoreLimit] Optional. The limit of how many cpu cores to use.
         * @param {string} [liveStreamId] The live stream id.
         * @param {boolean} [enableMpegtsM2TsMode] Optional. Whether to enable the MpegtsM2Ts mode.
         * @param {string} [videoCodec] Optional. Specify a video codec to encode to, e.g. h264. If omitted the server will auto-select using the url\&#39;s extension. Options: h265, h264, mpeg4, theora, vpx, wmv.
         * @param {string} [subtitleCodec] Optional. Specify a subtitle codec to encode to.
         * @param {string} [transcodingReasons] Optional. The transcoding reason.
         * @param {number} [audioStreamIndex] Optional. The index of the audio stream to use. If omitted the first audio stream will be used.
         * @param {number} [videoStreamIndex] Optional. The index of the video stream to use. If omitted the first video stream will be used.
         * @param {EncodingContext} [context] Optional. The MediaBrowser.Model.Dlna.EncodingContext.
         * @param {{ [key: string]: string; }} [streamOptions] Optional. The streaming options.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getHlsVideoSegment(itemId: string, playlistId: string, segmentId: number, container: string, _static?: boolean, params?: string, tag?: string, deviceProfileId?: string, playSessionId?: string, segmentContainer?: string, segmentLength?: number, minSegments?: number, mediaSourceId?: string, deviceId?: string, audioCodec?: string, enableAutoStreamCopy?: boolean, allowVideoStreamCopy?: boolean, allowAudioStreamCopy?: boolean, breakOnNonKeyFrames?: boolean, audioSampleRate?: number, maxAudioBitDepth?: number, audioBitRate?: number, audioChannels?: number, maxAudioChannels?: number, profile?: string, level?: string, framerate?: number, maxFramerate?: number, copyTimestamps?: boolean, startTimeTicks?: number, width?: number, height?: number, videoBitRate?: number, subtitleStreamIndex?: number, subtitleMethod?: SubtitleDeliveryMethod, maxRefFrames?: number, maxVideoBitDepth?: number, requireAvc?: boolean, deInterlace?: boolean, requireNonAnamorphic?: boolean, transcodingMaxAudioChannels?: number, cpuCoreLimit?: number, liveStreamId?: string, enableMpegtsM2TsMode?: boolean, videoCodec?: string, subtitleCodec?: string, transcodingReasons?: string, audioStreamIndex?: number, videoStreamIndex?: number, context?: EncodingContext, streamOptions?: { [key: string]: string; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await DynamicHlsApiAxiosParamCreator(configuration).getHlsVideoSegment(itemId, playlistId, segmentId, container, _static, params, tag, deviceProfileId, playSessionId, segmentContainer, segmentLength, minSegments, mediaSourceId, deviceId, audioCodec, enableAutoStreamCopy, allowVideoStreamCopy, allowAudioStreamCopy, breakOnNonKeyFrames, audioSampleRate, maxAudioBitDepth, audioBitRate, audioChannels, maxAudioChannels, profile, level, framerate, maxFramerate, copyTimestamps, startTimeTicks, width, height, videoBitRate, subtitleStreamIndex, subtitleMethod, maxRefFrames, maxVideoBitDepth, requireAvc, deInterlace, requireNonAnamorphic, transcodingMaxAudioChannels, cpuCoreLimit, liveStreamId, enableMpegtsM2TsMode, videoCodec, subtitleCodec, transcodingReasons, audioStreamIndex, videoStreamIndex, context, streamOptions, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Gets an audio hls playlist stream.
         * @param {string} itemId The item id.
         * @param {string} mediaSourceId The media version id, if playing an alternate version.
         * @param {boolean} [_static] Optional. If true, the original file will be streamed statically without any encoding. Use either no url extension or the original file extension. true/false.
         * @param {string} [params] The streaming parameters.
         * @param {string} [tag] The tag.
         * @param {string} [deviceProfileId] Optional. The dlna device profile id to utilize.
         * @param {string} [playSessionId] The play session id.
         * @param {string} [segmentContainer] The segment container.
         * @param {number} [segmentLength] The segment lenght.
         * @param {number} [minSegments] The minimum number of segments.
         * @param {string} [deviceId] The device id of the client requesting. Used to stop encoding processes when needed.
         * @param {string} [audioCodec] Optional. Specify a audio codec to encode to, e.g. mp3. If omitted the server will auto-select using the url\&#39;s extension. Options: aac, mp3, vorbis, wma.
         * @param {boolean} [enableAutoStreamCopy] Whether or not to allow automatic stream copy if requested values match the original source. Defaults to true.
         * @param {boolean} [allowVideoStreamCopy] Whether or not to allow copying of the video stream url.
         * @param {boolean} [allowAudioStreamCopy] Whether or not to allow copying of the audio stream url.
         * @param {boolean} [breakOnNonKeyFrames] Optional. Whether to break on non key frames.
         * @param {number} [audioSampleRate] Optional. Specify a specific audio sample rate, e.g. 44100.
         * @param {number} [maxAudioBitDepth] Optional. The maximum audio bit depth.
         * @param {number} [audioBitRate] Optional. Specify an audio bitrate to encode to, e.g. 128000. If omitted this will be left to encoder defaults.
         * @param {number} [audioChannels] Optional. Specify a specific number of audio channels to encode to, e.g. 2.
         * @param {number} [maxAudioChannels] Optional. Specify a maximum number of audio channels to encode to, e.g. 2.
         * @param {string} [profile] Optional. Specify a specific an encoder profile (varies by encoder), e.g. main, baseline, high.
         * @param {string} [level] Optional. Specify a level for the encoder profile (varies by encoder), e.g. 3, 3.1.
         * @param {number} [framerate] Optional. A specific video framerate to encode to, e.g. 23.976. Generally this should be omitted unless the device has specific requirements.
         * @param {number} [maxFramerate] Optional. A specific maximum video framerate to encode to, e.g. 23.976. Generally this should be omitted unless the device has specific requirements.
         * @param {boolean} [copyTimestamps] Whether or not to copy timestamps when transcoding with an offset. Defaults to false.
         * @param {number} [startTimeTicks] Optional. Specify a starting offset, in ticks. 1 tick &#x3D; 10000 ms.
         * @param {number} [width] Optional. The fixed horizontal resolution of the encoded video.
         * @param {number} [height] Optional. The fixed vertical resolution of the encoded video.
         * @param {number} [videoBitRate] Optional. Specify a video bitrate to encode to, e.g. 500000. If omitted this will be left to encoder defaults.
         * @param {number} [subtitleStreamIndex] Optional. The index of the subtitle stream to use. If omitted no subtitles will be used.
         * @param {SubtitleDeliveryMethod} [subtitleMethod] Optional. Specify the subtitle delivery method.
         * @param {number} [maxRefFrames] Optional.
         * @param {number} [maxVideoBitDepth] Optional. The maximum video bit depth.
         * @param {boolean} [requireAvc] Optional. Whether to require avc.
         * @param {boolean} [deInterlace] Optional. Whether to deinterlace the video.
         * @param {boolean} [requireNonAnamorphic] Optional. Whether to require a non anamporphic stream.
         * @param {number} [transcodingMaxAudioChannels] Optional. The maximum number of audio channels to transcode.
         * @param {number} [cpuCoreLimit] Optional. The limit of how many cpu cores to use.
         * @param {string} [liveStreamId] The live stream id.
         * @param {boolean} [enableMpegtsM2TsMode] Optional. Whether to enable the MpegtsM2Ts mode.
         * @param {string} [videoCodec] Optional. Specify a video codec to encode to, e.g. h264. If omitted the server will auto-select using the url\&#39;s extension. Options: h265, h264, mpeg4, theora, vpx, wmv.
         * @param {string} [subtitleCodec] Optional. Specify a subtitle codec to encode to.
         * @param {string} [transcodingReasons] Optional. The transcoding reason.
         * @param {number} [audioStreamIndex] Optional. The index of the audio stream to use. If omitted the first audio stream will be used.
         * @param {number} [videoStreamIndex] Optional. The index of the video stream to use. If omitted the first video stream will be used.
         * @param {EncodingContext} [context] Optional. The MediaBrowser.Model.Dlna.EncodingContext.
         * @param {{ [key: string]: string; }} [streamOptions] Optional. The streaming options.
         * @param {boolean} [enableAdaptiveBitrateStreaming] Enable adaptive bitrate streaming.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMasterHlsAudioPlaylist(itemId: string, mediaSourceId: string, _static?: boolean, params?: string, tag?: string, deviceProfileId?: string, playSessionId?: string, segmentContainer?: string, segmentLength?: number, minSegments?: number, deviceId?: string, audioCodec?: string, enableAutoStreamCopy?: boolean, allowVideoStreamCopy?: boolean, allowAudioStreamCopy?: boolean, breakOnNonKeyFrames?: boolean, audioSampleRate?: number, maxAudioBitDepth?: number, audioBitRate?: number, audioChannels?: number, maxAudioChannels?: number, profile?: string, level?: string, framerate?: number, maxFramerate?: number, copyTimestamps?: boolean, startTimeTicks?: number, width?: number, height?: number, videoBitRate?: number, subtitleStreamIndex?: number, subtitleMethod?: SubtitleDeliveryMethod, maxRefFrames?: number, maxVideoBitDepth?: number, requireAvc?: boolean, deInterlace?: boolean, requireNonAnamorphic?: boolean, transcodingMaxAudioChannels?: number, cpuCoreLimit?: number, liveStreamId?: string, enableMpegtsM2TsMode?: boolean, videoCodec?: string, subtitleCodec?: string, transcodingReasons?: string, audioStreamIndex?: number, videoStreamIndex?: number, context?: EncodingContext, streamOptions?: { [key: string]: string; }, enableAdaptiveBitrateStreaming?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await DynamicHlsApiAxiosParamCreator(configuration).getMasterHlsAudioPlaylist(itemId, mediaSourceId, _static, params, tag, deviceProfileId, playSessionId, segmentContainer, segmentLength, minSegments, deviceId, audioCodec, enableAutoStreamCopy, allowVideoStreamCopy, allowAudioStreamCopy, breakOnNonKeyFrames, audioSampleRate, maxAudioBitDepth, audioBitRate, audioChannels, maxAudioChannels, profile, level, framerate, maxFramerate, copyTimestamps, startTimeTicks, width, height, videoBitRate, subtitleStreamIndex, subtitleMethod, maxRefFrames, maxVideoBitDepth, requireAvc, deInterlace, requireNonAnamorphic, transcodingMaxAudioChannels, cpuCoreLimit, liveStreamId, enableMpegtsM2TsMode, videoCodec, subtitleCodec, transcodingReasons, audioStreamIndex, videoStreamIndex, context, streamOptions, enableAdaptiveBitrateStreaming, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Gets a video hls playlist stream.
         * @param {string} itemId The item id.
         * @param {string} mediaSourceId The media version id, if playing an alternate version.
         * @param {boolean} [_static] Optional. If true, the original file will be streamed statically without any encoding. Use either no url extension or the original file extension. true/false.
         * @param {string} [params] The streaming parameters.
         * @param {string} [tag] The tag.
         * @param {string} [deviceProfileId] Optional. The dlna device profile id to utilize.
         * @param {string} [playSessionId] The play session id.
         * @param {string} [segmentContainer] The segment container.
         * @param {number} [segmentLength] The segment lenght.
         * @param {number} [minSegments] The minimum number of segments.
         * @param {string} [deviceId] The device id of the client requesting. Used to stop encoding processes when needed.
         * @param {string} [audioCodec] Optional. Specify a audio codec to encode to, e.g. mp3. If omitted the server will auto-select using the url\&#39;s extension. Options: aac, mp3, vorbis, wma.
         * @param {boolean} [enableAutoStreamCopy] Whether or not to allow automatic stream copy if requested values match the original source. Defaults to true.
         * @param {boolean} [allowVideoStreamCopy] Whether or not to allow copying of the video stream url.
         * @param {boolean} [allowAudioStreamCopy] Whether or not to allow copying of the audio stream url.
         * @param {boolean} [breakOnNonKeyFrames] Optional. Whether to break on non key frames.
         * @param {number} [audioSampleRate] Optional. Specify a specific audio sample rate, e.g. 44100.
         * @param {number} [maxAudioBitDepth] Optional. The maximum audio bit depth.
         * @param {number} [audioBitRate] Optional. Specify an audio bitrate to encode to, e.g. 128000. If omitted this will be left to encoder defaults.
         * @param {number} [audioChannels] Optional. Specify a specific number of audio channels to encode to, e.g. 2.
         * @param {number} [maxAudioChannels] Optional. Specify a maximum number of audio channels to encode to, e.g. 2.
         * @param {string} [profile] Optional. Specify a specific an encoder profile (varies by encoder), e.g. main, baseline, high.
         * @param {string} [level] Optional. Specify a level for the encoder profile (varies by encoder), e.g. 3, 3.1.
         * @param {number} [framerate] Optional. A specific video framerate to encode to, e.g. 23.976. Generally this should be omitted unless the device has specific requirements.
         * @param {number} [maxFramerate] Optional. A specific maximum video framerate to encode to, e.g. 23.976. Generally this should be omitted unless the device has specific requirements.
         * @param {boolean} [copyTimestamps] Whether or not to copy timestamps when transcoding with an offset. Defaults to false.
         * @param {number} [startTimeTicks] Optional. Specify a starting offset, in ticks. 1 tick &#x3D; 10000 ms.
         * @param {number} [width] Optional. The fixed horizontal resolution of the encoded video.
         * @param {number} [height] Optional. The fixed vertical resolution of the encoded video.
         * @param {number} [videoBitRate] Optional. Specify a video bitrate to encode to, e.g. 500000. If omitted this will be left to encoder defaults.
         * @param {number} [subtitleStreamIndex] Optional. The index of the subtitle stream to use. If omitted no subtitles will be used.
         * @param {SubtitleDeliveryMethod} [subtitleMethod] Optional. Specify the subtitle delivery method.
         * @param {number} [maxRefFrames] Optional.
         * @param {number} [maxVideoBitDepth] Optional. The maximum video bit depth.
         * @param {boolean} [requireAvc] Optional. Whether to require avc.
         * @param {boolean} [deInterlace] Optional. Whether to deinterlace the video.
         * @param {boolean} [requireNonAnamorphic] Optional. Whether to require a non anamporphic stream.
         * @param {number} [transcodingMaxAudioChannels] Optional. The maximum number of audio channels to transcode.
         * @param {number} [cpuCoreLimit] Optional. The limit of how many cpu cores to use.
         * @param {string} [liveStreamId] The live stream id.
         * @param {boolean} [enableMpegtsM2TsMode] Optional. Whether to enable the MpegtsM2Ts mode.
         * @param {string} [videoCodec] Optional. Specify a video codec to encode to, e.g. h264. If omitted the server will auto-select using the url\&#39;s extension. Options: h265, h264, mpeg4, theora, vpx, wmv.
         * @param {string} [subtitleCodec] Optional. Specify a subtitle codec to encode to.
         * @param {string} [transcodingReasons] Optional. The transcoding reason.
         * @param {number} [audioStreamIndex] Optional. The index of the audio stream to use. If omitted the first audio stream will be used.
         * @param {number} [videoStreamIndex] Optional. The index of the video stream to use. If omitted the first video stream will be used.
         * @param {EncodingContext} [context] Optional. The MediaBrowser.Model.Dlna.EncodingContext.
         * @param {{ [key: string]: string; }} [streamOptions] Optional. The streaming options.
         * @param {boolean} [enableAdaptiveBitrateStreaming] Enable adaptive bitrate streaming.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMasterHlsVideoPlaylist(itemId: string, mediaSourceId: string, _static?: boolean, params?: string, tag?: string, deviceProfileId?: string, playSessionId?: string, segmentContainer?: string, segmentLength?: number, minSegments?: number, deviceId?: string, audioCodec?: string, enableAutoStreamCopy?: boolean, allowVideoStreamCopy?: boolean, allowAudioStreamCopy?: boolean, breakOnNonKeyFrames?: boolean, audioSampleRate?: number, maxAudioBitDepth?: number, audioBitRate?: number, audioChannels?: number, maxAudioChannels?: number, profile?: string, level?: string, framerate?: number, maxFramerate?: number, copyTimestamps?: boolean, startTimeTicks?: number, width?: number, height?: number, videoBitRate?: number, subtitleStreamIndex?: number, subtitleMethod?: SubtitleDeliveryMethod, maxRefFrames?: number, maxVideoBitDepth?: number, requireAvc?: boolean, deInterlace?: boolean, requireNonAnamorphic?: boolean, transcodingMaxAudioChannels?: number, cpuCoreLimit?: number, liveStreamId?: string, enableMpegtsM2TsMode?: boolean, videoCodec?: string, subtitleCodec?: string, transcodingReasons?: string, audioStreamIndex?: number, videoStreamIndex?: number, context?: EncodingContext, streamOptions?: { [key: string]: string; }, enableAdaptiveBitrateStreaming?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await DynamicHlsApiAxiosParamCreator(configuration).getMasterHlsVideoPlaylist(itemId, mediaSourceId, _static, params, tag, deviceProfileId, playSessionId, segmentContainer, segmentLength, minSegments, deviceId, audioCodec, enableAutoStreamCopy, allowVideoStreamCopy, allowAudioStreamCopy, breakOnNonKeyFrames, audioSampleRate, maxAudioBitDepth, audioBitRate, audioChannels, maxAudioChannels, profile, level, framerate, maxFramerate, copyTimestamps, startTimeTicks, width, height, videoBitRate, subtitleStreamIndex, subtitleMethod, maxRefFrames, maxVideoBitDepth, requireAvc, deInterlace, requireNonAnamorphic, transcodingMaxAudioChannels, cpuCoreLimit, liveStreamId, enableMpegtsM2TsMode, videoCodec, subtitleCodec, transcodingReasons, audioStreamIndex, videoStreamIndex, context, streamOptions, enableAdaptiveBitrateStreaming, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Gets an audio stream using HTTP live streaming.
         * @param {string} itemId The item id.
         * @param {boolean} [_static] Optional. If true, the original file will be streamed statically without any encoding. Use either no url extension or the original file extension. true/false.
         * @param {string} [params] The streaming parameters.
         * @param {string} [tag] The tag.
         * @param {string} [deviceProfileId] Optional. The dlna device profile id to utilize.
         * @param {string} [playSessionId] The play session id.
         * @param {string} [segmentContainer] The segment container.
         * @param {number} [segmentLength] The segment lenght.
         * @param {number} [minSegments] The minimum number of segments.
         * @param {string} [mediaSourceId] The media version id, if playing an alternate version.
         * @param {string} [deviceId] The device id of the client requesting. Used to stop encoding processes when needed.
         * @param {string} [audioCodec] Optional. Specify a audio codec to encode to, e.g. mp3. If omitted the server will auto-select using the url\&#39;s extension. Options: aac, mp3, vorbis, wma.
         * @param {boolean} [enableAutoStreamCopy] Whether or not to allow automatic stream copy if requested values match the original source. Defaults to true.
         * @param {boolean} [allowVideoStreamCopy] Whether or not to allow copying of the video stream url.
         * @param {boolean} [allowAudioStreamCopy] Whether or not to allow copying of the audio stream url.
         * @param {boolean} [breakOnNonKeyFrames] Optional. Whether to break on non key frames.
         * @param {number} [audioSampleRate] Optional. Specify a specific audio sample rate, e.g. 44100.
         * @param {number} [maxAudioBitDepth] Optional. The maximum audio bit depth.
         * @param {number} [audioBitRate] Optional. Specify an audio bitrate to encode to, e.g. 128000. If omitted this will be left to encoder defaults.
         * @param {number} [audioChannels] Optional. Specify a specific number of audio channels to encode to, e.g. 2.
         * @param {number} [maxAudioChannels] Optional. Specify a maximum number of audio channels to encode to, e.g. 2.
         * @param {string} [profile] Optional. Specify a specific an encoder profile (varies by encoder), e.g. main, baseline, high.
         * @param {string} [level] Optional. Specify a level for the encoder profile (varies by encoder), e.g. 3, 3.1.
         * @param {number} [framerate] Optional. A specific video framerate to encode to, e.g. 23.976. Generally this should be omitted unless the device has specific requirements.
         * @param {number} [maxFramerate] Optional. A specific maximum video framerate to encode to, e.g. 23.976. Generally this should be omitted unless the device has specific requirements.
         * @param {boolean} [copyTimestamps] Whether or not to copy timestamps when transcoding with an offset. Defaults to false.
         * @param {number} [startTimeTicks] Optional. Specify a starting offset, in ticks. 1 tick &#x3D; 10000 ms.
         * @param {number} [width] Optional. The fixed horizontal resolution of the encoded video.
         * @param {number} [height] Optional. The fixed vertical resolution of the encoded video.
         * @param {number} [videoBitRate] Optional. Specify a video bitrate to encode to, e.g. 500000. If omitted this will be left to encoder defaults.
         * @param {number} [subtitleStreamIndex] Optional. The index of the subtitle stream to use. If omitted no subtitles will be used.
         * @param {SubtitleDeliveryMethod} [subtitleMethod] Optional. Specify the subtitle delivery method.
         * @param {number} [maxRefFrames] Optional.
         * @param {number} [maxVideoBitDepth] Optional. The maximum video bit depth.
         * @param {boolean} [requireAvc] Optional. Whether to require avc.
         * @param {boolean} [deInterlace] Optional. Whether to deinterlace the video.
         * @param {boolean} [requireNonAnamorphic] Optional. Whether to require a non anamporphic stream.
         * @param {number} [transcodingMaxAudioChannels] Optional. The maximum number of audio channels to transcode.
         * @param {number} [cpuCoreLimit] Optional. The limit of how many cpu cores to use.
         * @param {string} [liveStreamId] The live stream id.
         * @param {boolean} [enableMpegtsM2TsMode] Optional. Whether to enable the MpegtsM2Ts mode.
         * @param {string} [videoCodec] Optional. Specify a video codec to encode to, e.g. h264. If omitted the server will auto-select using the url\&#39;s extension. Options: h265, h264, mpeg4, theora, vpx, wmv.
         * @param {string} [subtitleCodec] Optional. Specify a subtitle codec to encode to.
         * @param {string} [transcodingReasons] Optional. The transcoding reason.
         * @param {number} [audioStreamIndex] Optional. The index of the audio stream to use. If omitted the first audio stream will be used.
         * @param {number} [videoStreamIndex] Optional. The index of the video stream to use. If omitted the first video stream will be used.
         * @param {EncodingContext} [context] Optional. The MediaBrowser.Model.Dlna.EncodingContext.
         * @param {{ [key: string]: string; }} [streamOptions] Optional. The streaming options.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getVariantHlsAudioPlaylist(itemId: string, _static?: boolean, params?: string, tag?: string, deviceProfileId?: string, playSessionId?: string, segmentContainer?: string, segmentLength?: number, minSegments?: number, mediaSourceId?: string, deviceId?: string, audioCodec?: string, enableAutoStreamCopy?: boolean, allowVideoStreamCopy?: boolean, allowAudioStreamCopy?: boolean, breakOnNonKeyFrames?: boolean, audioSampleRate?: number, maxAudioBitDepth?: number, audioBitRate?: number, audioChannels?: number, maxAudioChannels?: number, profile?: string, level?: string, framerate?: number, maxFramerate?: number, copyTimestamps?: boolean, startTimeTicks?: number, width?: number, height?: number, videoBitRate?: number, subtitleStreamIndex?: number, subtitleMethod?: SubtitleDeliveryMethod, maxRefFrames?: number, maxVideoBitDepth?: number, requireAvc?: boolean, deInterlace?: boolean, requireNonAnamorphic?: boolean, transcodingMaxAudioChannels?: number, cpuCoreLimit?: number, liveStreamId?: string, enableMpegtsM2TsMode?: boolean, videoCodec?: string, subtitleCodec?: string, transcodingReasons?: string, audioStreamIndex?: number, videoStreamIndex?: number, context?: EncodingContext, streamOptions?: { [key: string]: string; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await DynamicHlsApiAxiosParamCreator(configuration).getVariantHlsAudioPlaylist(itemId, _static, params, tag, deviceProfileId, playSessionId, segmentContainer, segmentLength, minSegments, mediaSourceId, deviceId, audioCodec, enableAutoStreamCopy, allowVideoStreamCopy, allowAudioStreamCopy, breakOnNonKeyFrames, audioSampleRate, maxAudioBitDepth, audioBitRate, audioChannels, maxAudioChannels, profile, level, framerate, maxFramerate, copyTimestamps, startTimeTicks, width, height, videoBitRate, subtitleStreamIndex, subtitleMethod, maxRefFrames, maxVideoBitDepth, requireAvc, deInterlace, requireNonAnamorphic, transcodingMaxAudioChannels, cpuCoreLimit, liveStreamId, enableMpegtsM2TsMode, videoCodec, subtitleCodec, transcodingReasons, audioStreamIndex, videoStreamIndex, context, streamOptions, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Gets a video stream using HTTP live streaming.
         * @param {string} itemId The item id.
         * @param {boolean} [_static] Optional. If true, the original file will be streamed statically without any encoding. Use either no url extension or the original file extension. true/false.
         * @param {string} [params] The streaming parameters.
         * @param {string} [tag] The tag.
         * @param {string} [deviceProfileId] Optional. The dlna device profile id to utilize.
         * @param {string} [playSessionId] The play session id.
         * @param {string} [segmentContainer] The segment container.
         * @param {number} [segmentLength] The segment lenght.
         * @param {number} [minSegments] The minimum number of segments.
         * @param {string} [mediaSourceId] The media version id, if playing an alternate version.
         * @param {string} [deviceId] The device id of the client requesting. Used to stop encoding processes when needed.
         * @param {string} [audioCodec] Optional. Specify a audio codec to encode to, e.g. mp3. If omitted the server will auto-select using the url\&#39;s extension. Options: aac, mp3, vorbis, wma.
         * @param {boolean} [enableAutoStreamCopy] Whether or not to allow automatic stream copy if requested values match the original source. Defaults to true.
         * @param {boolean} [allowVideoStreamCopy] Whether or not to allow copying of the video stream url.
         * @param {boolean} [allowAudioStreamCopy] Whether or not to allow copying of the audio stream url.
         * @param {boolean} [breakOnNonKeyFrames] Optional. Whether to break on non key frames.
         * @param {number} [audioSampleRate] Optional. Specify a specific audio sample rate, e.g. 44100.
         * @param {number} [maxAudioBitDepth] Optional. The maximum audio bit depth.
         * @param {number} [audioBitRate] Optional. Specify an audio bitrate to encode to, e.g. 128000. If omitted this will be left to encoder defaults.
         * @param {number} [audioChannels] Optional. Specify a specific number of audio channels to encode to, e.g. 2.
         * @param {number} [maxAudioChannels] Optional. Specify a maximum number of audio channels to encode to, e.g. 2.
         * @param {string} [profile] Optional. Specify a specific an encoder profile (varies by encoder), e.g. main, baseline, high.
         * @param {string} [level] Optional. Specify a level for the encoder profile (varies by encoder), e.g. 3, 3.1.
         * @param {number} [framerate] Optional. A specific video framerate to encode to, e.g. 23.976. Generally this should be omitted unless the device has specific requirements.
         * @param {number} [maxFramerate] Optional. A specific maximum video framerate to encode to, e.g. 23.976. Generally this should be omitted unless the device has specific requirements.
         * @param {boolean} [copyTimestamps] Whether or not to copy timestamps when transcoding with an offset. Defaults to false.
         * @param {number} [startTimeTicks] Optional. Specify a starting offset, in ticks. 1 tick &#x3D; 10000 ms.
         * @param {number} [width] Optional. The fixed horizontal resolution of the encoded video.
         * @param {number} [height] Optional. The fixed vertical resolution of the encoded video.
         * @param {number} [videoBitRate] Optional. Specify a video bitrate to encode to, e.g. 500000. If omitted this will be left to encoder defaults.
         * @param {number} [subtitleStreamIndex] Optional. The index of the subtitle stream to use. If omitted no subtitles will be used.
         * @param {SubtitleDeliveryMethod} [subtitleMethod] Optional. Specify the subtitle delivery method.
         * @param {number} [maxRefFrames] Optional.
         * @param {number} [maxVideoBitDepth] Optional. The maximum video bit depth.
         * @param {boolean} [requireAvc] Optional. Whether to require avc.
         * @param {boolean} [deInterlace] Optional. Whether to deinterlace the video.
         * @param {boolean} [requireNonAnamorphic] Optional. Whether to require a non anamporphic stream.
         * @param {number} [transcodingMaxAudioChannels] Optional. The maximum number of audio channels to transcode.
         * @param {number} [cpuCoreLimit] Optional. The limit of how many cpu cores to use.
         * @param {string} [liveStreamId] The live stream id.
         * @param {boolean} [enableMpegtsM2TsMode] Optional. Whether to enable the MpegtsM2Ts mode.
         * @param {string} [videoCodec] Optional. Specify a video codec to encode to, e.g. h264. If omitted the server will auto-select using the url\&#39;s extension. Options: h265, h264, mpeg4, theora, vpx, wmv.
         * @param {string} [subtitleCodec] Optional. Specify a subtitle codec to encode to.
         * @param {string} [transcodingReasons] Optional. The transcoding reason.
         * @param {number} [audioStreamIndex] Optional. The index of the audio stream to use. If omitted the first audio stream will be used.
         * @param {number} [videoStreamIndex] Optional. The index of the video stream to use. If omitted the first video stream will be used.
         * @param {EncodingContext} [context] Optional. The MediaBrowser.Model.Dlna.EncodingContext.
         * @param {{ [key: string]: string; }} [streamOptions] Optional. The streaming options.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getVariantHlsVideoPlaylist(itemId: string, _static?: boolean, params?: string, tag?: string, deviceProfileId?: string, playSessionId?: string, segmentContainer?: string, segmentLength?: number, minSegments?: number, mediaSourceId?: string, deviceId?: string, audioCodec?: string, enableAutoStreamCopy?: boolean, allowVideoStreamCopy?: boolean, allowAudioStreamCopy?: boolean, breakOnNonKeyFrames?: boolean, audioSampleRate?: number, maxAudioBitDepth?: number, audioBitRate?: number, audioChannels?: number, maxAudioChannels?: number, profile?: string, level?: string, framerate?: number, maxFramerate?: number, copyTimestamps?: boolean, startTimeTicks?: number, width?: number, height?: number, videoBitRate?: number, subtitleStreamIndex?: number, subtitleMethod?: SubtitleDeliveryMethod, maxRefFrames?: number, maxVideoBitDepth?: number, requireAvc?: boolean, deInterlace?: boolean, requireNonAnamorphic?: boolean, transcodingMaxAudioChannels?: number, cpuCoreLimit?: number, liveStreamId?: string, enableMpegtsM2TsMode?: boolean, videoCodec?: string, subtitleCodec?: string, transcodingReasons?: string, audioStreamIndex?: number, videoStreamIndex?: number, context?: EncodingContext, streamOptions?: { [key: string]: string; }, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await DynamicHlsApiAxiosParamCreator(configuration).getVariantHlsVideoPlaylist(itemId, _static, params, tag, deviceProfileId, playSessionId, segmentContainer, segmentLength, minSegments, mediaSourceId, deviceId, audioCodec, enableAutoStreamCopy, allowVideoStreamCopy, allowAudioStreamCopy, breakOnNonKeyFrames, audioSampleRate, maxAudioBitDepth, audioBitRate, audioChannels, maxAudioChannels, profile, level, framerate, maxFramerate, copyTimestamps, startTimeTicks, width, height, videoBitRate, subtitleStreamIndex, subtitleMethod, maxRefFrames, maxVideoBitDepth, requireAvc, deInterlace, requireNonAnamorphic, transcodingMaxAudioChannels, cpuCoreLimit, liveStreamId, enableMpegtsM2TsMode, videoCodec, subtitleCodec, transcodingReasons, audioStreamIndex, videoStreamIndex, context, streamOptions, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Gets an audio hls playlist stream.
         * @param {string} itemId The item id.
         * @param {string} mediaSourceId The media version id, if playing an alternate version.
         * @param {boolean} [_static] Optional. If true, the original file will be streamed statically without any encoding. Use either no url extension or the original file extension. true/false.
         * @param {string} [params] The streaming parameters.
         * @param {string} [tag] The tag.
         * @param {string} [deviceProfileId] Optional. The dlna device profile id to utilize.
         * @param {string} [playSessionId] The play session id.
         * @param {string} [segmentContainer] The segment container.
         * @param {number} [segmentLength] The segment lenght.
         * @param {number} [minSegments] The minimum number of segments.
         * @param {string} [deviceId] The device id of the client requesting. Used to stop encoding processes when needed.
         * @param {string} [audioCodec] Optional. Specify a audio codec to encode to, e.g. mp3. If omitted the server will auto-select using the url\&#39;s extension. Options: aac, mp3, vorbis, wma.
         * @param {boolean} [enableAutoStreamCopy] Whether or not to allow automatic stream copy if requested values match the original source. Defaults to true.
         * @param {boolean} [allowVideoStreamCopy] Whether or not to allow copying of the video stream url.
         * @param {boolean} [allowAudioStreamCopy] Whether or not to allow copying of the audio stream url.
         * @param {boolean} [breakOnNonKeyFrames] Optional. Whether to break on non key frames.
         * @param {number} [audioSampleRate] Optional. Specify a specific audio sample rate, e.g. 44100.
         * @param {number} [maxAudioBitDepth] Optional. The maximum audio bit depth.
         * @param {number} [audioBitRate] Optional. Specify an audio bitrate to encode to, e.g. 128000. If omitted this will be left to encoder defaults.
         * @param {number} [audioChannels] Optional. Specify a specific number of audio channels to encode to, e.g. 2.
         * @param {number} [maxAudioChannels] Optional. Specify a maximum number of audio channels to encode to, e.g. 2.
         * @param {string} [profile] Optional. Specify a specific an encoder profile (varies by encoder), e.g. main, baseline, high.
         * @param {string} [level] Optional. Specify a level for the encoder profile (varies by encoder), e.g. 3, 3.1.
         * @param {number} [framerate] Optional. A specific video framerate to encode to, e.g. 23.976. Generally this should be omitted unless the device has specific requirements.
         * @param {number} [maxFramerate] Optional. A specific maximum video framerate to encode to, e.g. 23.976. Generally this should be omitted unless the device has specific requirements.
         * @param {boolean} [copyTimestamps] Whether or not to copy timestamps when transcoding with an offset. Defaults to false.
         * @param {number} [startTimeTicks] Optional. Specify a starting offset, in ticks. 1 tick &#x3D; 10000 ms.
         * @param {number} [width] Optional. The fixed horizontal resolution of the encoded video.
         * @param {number} [height] Optional. The fixed vertical resolution of the encoded video.
         * @param {number} [videoBitRate] Optional. Specify a video bitrate to encode to, e.g. 500000. If omitted this will be left to encoder defaults.
         * @param {number} [subtitleStreamIndex] Optional. The index of the subtitle stream to use. If omitted no subtitles will be used.
         * @param {SubtitleDeliveryMethod} [subtitleMethod] Optional. Specify the subtitle delivery method.
         * @param {number} [maxRefFrames] Optional.
         * @param {number} [maxVideoBitDepth] Optional. The maximum video bit depth.
         * @param {boolean} [requireAvc] Optional. Whether to require avc.
         * @param {boolean} [deInterlace] Optional. Whether to deinterlace the video.
         * @param {boolean} [requireNonAnamorphic] Optional. Whether to require a non anamporphic stream.
         * @param {number} [transcodingMaxAudioChannels] Optional. The maximum number of audio channels to transcode.
         * @param {number} [cpuCoreLimit] Optional. The limit of how many cpu cores to use.
         * @param {string} [liveStreamId] The live stream id.
         * @param {boolean} [enableMpegtsM2TsMode] Optional. Whether to enable the MpegtsM2Ts mode.
         * @param {string} [videoCodec] Optional. Specify a video codec to encode to, e.g. h264. If omitted the server will auto-select using the url\&#39;s extension. Options: h265, h264, mpeg4, theora, vpx, wmv.
         * @param {string} [subtitleCodec] Optional. Specify a subtitle codec to encode to.
         * @param {string} [transcodingReasons] Optional. The transcoding reason.
         * @param {number} [audioStreamIndex] Optional. The index of the audio stream to use. If omitted the first audio stream will be used.
         * @param {number} [videoStreamIndex] Optional. The index of the video stream to use. If omitted the first video stream will be used.
         * @param {EncodingContext} [context] Optional. The MediaBrowser.Model.Dlna.EncodingContext.
         * @param {{ [key: string]: string; }} [streamOptions] Optional. The streaming options.
         * @param {boolean} [enableAdaptiveBitrateStreaming] Enable adaptive bitrate streaming.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async headMasterHlsAudioPlaylist(itemId: string, mediaSourceId: string, _static?: boolean, params?: string, tag?: string, deviceProfileId?: string, playSessionId?: string, segmentContainer?: string, segmentLength?: number, minSegments?: number, deviceId?: string, audioCodec?: string, enableAutoStreamCopy?: boolean, allowVideoStreamCopy?: boolean, allowAudioStreamCopy?: boolean, breakOnNonKeyFrames?: boolean, audioSampleRate?: number, maxAudioBitDepth?: number, audioBitRate?: number, audioChannels?: number, maxAudioChannels?: number, profile?: string, level?: string, framerate?: number, maxFramerate?: number, copyTimestamps?: boolean, startTimeTicks?: number, width?: number, height?: number, videoBitRate?: number, subtitleStreamIndex?: number, subtitleMethod?: SubtitleDeliveryMethod, maxRefFrames?: number, maxVideoBitDepth?: number, requireAvc?: boolean, deInterlace?: boolean, requireNonAnamorphic?: boolean, transcodingMaxAudioChannels?: number, cpuCoreLimit?: number, liveStreamId?: string, enableMpegtsM2TsMode?: boolean, videoCodec?: string, subtitleCodec?: string, transcodingReasons?: string, audioStreamIndex?: number, videoStreamIndex?: number, context?: EncodingContext, streamOptions?: { [key: string]: string; }, enableAdaptiveBitrateStreaming?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await DynamicHlsApiAxiosParamCreator(configuration).headMasterHlsAudioPlaylist(itemId, mediaSourceId, _static, params, tag, deviceProfileId, playSessionId, segmentContainer, segmentLength, minSegments, deviceId, audioCodec, enableAutoStreamCopy, allowVideoStreamCopy, allowAudioStreamCopy, breakOnNonKeyFrames, audioSampleRate, maxAudioBitDepth, audioBitRate, audioChannels, maxAudioChannels, profile, level, framerate, maxFramerate, copyTimestamps, startTimeTicks, width, height, videoBitRate, subtitleStreamIndex, subtitleMethod, maxRefFrames, maxVideoBitDepth, requireAvc, deInterlace, requireNonAnamorphic, transcodingMaxAudioChannels, cpuCoreLimit, liveStreamId, enableMpegtsM2TsMode, videoCodec, subtitleCodec, transcodingReasons, audioStreamIndex, videoStreamIndex, context, streamOptions, enableAdaptiveBitrateStreaming, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Gets a video hls playlist stream.
         * @param {string} itemId The item id.
         * @param {string} mediaSourceId The media version id, if playing an alternate version.
         * @param {boolean} [_static] Optional. If true, the original file will be streamed statically without any encoding. Use either no url extension or the original file extension. true/false.
         * @param {string} [params] The streaming parameters.
         * @param {string} [tag] The tag.
         * @param {string} [deviceProfileId] Optional. The dlna device profile id to utilize.
         * @param {string} [playSessionId] The play session id.
         * @param {string} [segmentContainer] The segment container.
         * @param {number} [segmentLength] The segment lenght.
         * @param {number} [minSegments] The minimum number of segments.
         * @param {string} [deviceId] The device id of the client requesting. Used to stop encoding processes when needed.
         * @param {string} [audioCodec] Optional. Specify a audio codec to encode to, e.g. mp3. If omitted the server will auto-select using the url\&#39;s extension. Options: aac, mp3, vorbis, wma.
         * @param {boolean} [enableAutoStreamCopy] Whether or not to allow automatic stream copy if requested values match the original source. Defaults to true.
         * @param {boolean} [allowVideoStreamCopy] Whether or not to allow copying of the video stream url.
         * @param {boolean} [allowAudioStreamCopy] Whether or not to allow copying of the audio stream url.
         * @param {boolean} [breakOnNonKeyFrames] Optional. Whether to break on non key frames.
         * @param {number} [audioSampleRate] Optional. Specify a specific audio sample rate, e.g. 44100.
         * @param {number} [maxAudioBitDepth] Optional. The maximum audio bit depth.
         * @param {number} [audioBitRate] Optional. Specify an audio bitrate to encode to, e.g. 128000. If omitted this will be left to encoder defaults.
         * @param {number} [audioChannels] Optional. Specify a specific number of audio channels to encode to, e.g. 2.
         * @param {number} [maxAudioChannels] Optional. Specify a maximum number of audio channels to encode to, e.g. 2.
         * @param {string} [profile] Optional. Specify a specific an encoder profile (varies by encoder), e.g. main, baseline, high.
         * @param {string} [level] Optional. Specify a level for the encoder profile (varies by encoder), e.g. 3, 3.1.
         * @param {number} [framerate] Optional. A specific video framerate to encode to, e.g. 23.976. Generally this should be omitted unless the device has specific requirements.
         * @param {number} [maxFramerate] Optional. A specific maximum video framerate to encode to, e.g. 23.976. Generally this should be omitted unless the device has specific requirements.
         * @param {boolean} [copyTimestamps] Whether or not to copy timestamps when transcoding with an offset. Defaults to false.
         * @param {number} [startTimeTicks] Optional. Specify a starting offset, in ticks. 1 tick &#x3D; 10000 ms.
         * @param {number} [width] Optional. The fixed horizontal resolution of the encoded video.
         * @param {number} [height] Optional. The fixed vertical resolution of the encoded video.
         * @param {number} [videoBitRate] Optional. Specify a video bitrate to encode to, e.g. 500000. If omitted this will be left to encoder defaults.
         * @param {number} [subtitleStreamIndex] Optional. The index of the subtitle stream to use. If omitted no subtitles will be used.
         * @param {SubtitleDeliveryMethod} [subtitleMethod] Optional. Specify the subtitle delivery method.
         * @param {number} [maxRefFrames] Optional.
         * @param {number} [maxVideoBitDepth] Optional. The maximum video bit depth.
         * @param {boolean} [requireAvc] Optional. Whether to require avc.
         * @param {boolean} [deInterlace] Optional. Whether to deinterlace the video.
         * @param {boolean} [requireNonAnamorphic] Optional. Whether to require a non anamporphic stream.
         * @param {number} [transcodingMaxAudioChannels] Optional. The maximum number of audio channels to transcode.
         * @param {number} [cpuCoreLimit] Optional. The limit of how many cpu cores to use.
         * @param {string} [liveStreamId] The live stream id.
         * @param {boolean} [enableMpegtsM2TsMode] Optional. Whether to enable the MpegtsM2Ts mode.
         * @param {string} [videoCodec] Optional. Specify a video codec to encode to, e.g. h264. If omitted the server will auto-select using the url\&#39;s extension. Options: h265, h264, mpeg4, theora, vpx, wmv.
         * @param {string} [subtitleCodec] Optional. Specify a subtitle codec to encode to.
         * @param {string} [transcodingReasons] Optional. The transcoding reason.
         * @param {number} [audioStreamIndex] Optional. The index of the audio stream to use. If omitted the first audio stream will be used.
         * @param {number} [videoStreamIndex] Optional. The index of the video stream to use. If omitted the first video stream will be used.
         * @param {EncodingContext} [context] Optional. The MediaBrowser.Model.Dlna.EncodingContext.
         * @param {{ [key: string]: string; }} [streamOptions] Optional. The streaming options.
         * @param {boolean} [enableAdaptiveBitrateStreaming] Enable adaptive bitrate streaming.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async headMasterHlsVideoPlaylist(itemId: string, mediaSourceId: string, _static?: boolean, params?: string, tag?: string, deviceProfileId?: string, playSessionId?: string, segmentContainer?: string, segmentLength?: number, minSegments?: number, deviceId?: string, audioCodec?: string, enableAutoStreamCopy?: boolean, allowVideoStreamCopy?: boolean, allowAudioStreamCopy?: boolean, breakOnNonKeyFrames?: boolean, audioSampleRate?: number, maxAudioBitDepth?: number, audioBitRate?: number, audioChannels?: number, maxAudioChannels?: number, profile?: string, level?: string, framerate?: number, maxFramerate?: number, copyTimestamps?: boolean, startTimeTicks?: number, width?: number, height?: number, videoBitRate?: number, subtitleStreamIndex?: number, subtitleMethod?: SubtitleDeliveryMethod, maxRefFrames?: number, maxVideoBitDepth?: number, requireAvc?: boolean, deInterlace?: boolean, requireNonAnamorphic?: boolean, transcodingMaxAudioChannels?: number, cpuCoreLimit?: number, liveStreamId?: string, enableMpegtsM2TsMode?: boolean, videoCodec?: string, subtitleCodec?: string, transcodingReasons?: string, audioStreamIndex?: number, videoStreamIndex?: number, context?: EncodingContext, streamOptions?: { [key: string]: string; }, enableAdaptiveBitrateStreaming?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await DynamicHlsApiAxiosParamCreator(configuration).headMasterHlsVideoPlaylist(itemId, mediaSourceId, _static, params, tag, deviceProfileId, playSessionId, segmentContainer, segmentLength, minSegments, deviceId, audioCodec, enableAutoStreamCopy, allowVideoStreamCopy, allowAudioStreamCopy, breakOnNonKeyFrames, audioSampleRate, maxAudioBitDepth, audioBitRate, audioChannels, maxAudioChannels, profile, level, framerate, maxFramerate, copyTimestamps, startTimeTicks, width, height, videoBitRate, subtitleStreamIndex, subtitleMethod, maxRefFrames, maxVideoBitDepth, requireAvc, deInterlace, requireNonAnamorphic, transcodingMaxAudioChannels, cpuCoreLimit, liveStreamId, enableMpegtsM2TsMode, videoCodec, subtitleCodec, transcodingReasons, audioStreamIndex, videoStreamIndex, context, streamOptions, enableAdaptiveBitrateStreaming, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * DynamicHlsApi - factory interface
 * @export
 */
export const DynamicHlsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Gets a video stream using HTTP live streaming.
         * @param {string} itemId The item id.
         * @param {string} playlistId The playlist id.
         * @param {number} segmentId The segment id.
         * @param {string} container The video container. Possible values are: ts, webm, asf, wmv, ogv, mp4, m4v, mkv, mpeg, mpg, avi, 3gp, wmv, wtv, m2ts, mov, iso, flv.
         * @param {boolean} [_static] Optional. If true, the original file will be streamed statically without any encoding. Use either no url extension or the original file extension. true/false.
         * @param {string} [params] The streaming parameters.
         * @param {string} [tag] The tag.
         * @param {string} [deviceProfileId] Optional. The dlna device profile id to utilize.
         * @param {string} [playSessionId] The play session id.
         * @param {string} [segmentContainer] The segment container.
         * @param {number} [segmentLength] The segment lenght.
         * @param {number} [minSegments] The minimum number of segments.
         * @param {string} [mediaSourceId] The media version id, if playing an alternate version.
         * @param {string} [deviceId] The device id of the client requesting. Used to stop encoding processes when needed.
         * @param {string} [audioCodec] Optional. Specify a audio codec to encode to, e.g. mp3. If omitted the server will auto-select using the url\&#39;s extension. Options: aac, mp3, vorbis, wma.
         * @param {boolean} [enableAutoStreamCopy] Whether or not to allow automatic stream copy if requested values match the original source. Defaults to true.
         * @param {boolean} [allowVideoStreamCopy] Whether or not to allow copying of the video stream url.
         * @param {boolean} [allowAudioStreamCopy] Whether or not to allow copying of the audio stream url.
         * @param {boolean} [breakOnNonKeyFrames] Optional. Whether to break on non key frames.
         * @param {number} [audioSampleRate] Optional. Specify a specific audio sample rate, e.g. 44100.
         * @param {number} [maxAudioBitDepth] Optional. The maximum audio bit depth.
         * @param {number} [audioBitRate] Optional. Specify an audio bitrate to encode to, e.g. 128000. If omitted this will be left to encoder defaults.
         * @param {number} [audioChannels] Optional. Specify a specific number of audio channels to encode to, e.g. 2.
         * @param {number} [maxAudioChannels] Optional. Specify a maximum number of audio channels to encode to, e.g. 2.
         * @param {string} [profile] Optional. Specify a specific an encoder profile (varies by encoder), e.g. main, baseline, high.
         * @param {string} [level] Optional. Specify a level for the encoder profile (varies by encoder), e.g. 3, 3.1.
         * @param {number} [framerate] Optional. A specific video framerate to encode to, e.g. 23.976. Generally this should be omitted unless the device has specific requirements.
         * @param {number} [maxFramerate] Optional. A specific maximum video framerate to encode to, e.g. 23.976. Generally this should be omitted unless the device has specific requirements.
         * @param {boolean} [copyTimestamps] Whether or not to copy timestamps when transcoding with an offset. Defaults to false.
         * @param {number} [startTimeTicks] Optional. Specify a starting offset, in ticks. 1 tick &#x3D; 10000 ms.
         * @param {number} [width] Optional. The fixed horizontal resolution of the encoded video.
         * @param {number} [height] Optional. The fixed vertical resolution of the encoded video.
         * @param {number} [videoBitRate] Optional. Specify a video bitrate to encode to, e.g. 500000. If omitted this will be left to encoder defaults.
         * @param {number} [subtitleStreamIndex] Optional. The index of the subtitle stream to use. If omitted no subtitles will be used.
         * @param {SubtitleDeliveryMethod} [subtitleMethod] Optional. Specify the subtitle delivery method.
         * @param {number} [maxRefFrames] Optional.
         * @param {number} [maxVideoBitDepth] Optional. The maximum video bit depth.
         * @param {boolean} [requireAvc] Optional. Whether to require avc.
         * @param {boolean} [deInterlace] Optional. Whether to deinterlace the video.
         * @param {boolean} [requireNonAnamorphic] Optional. Whether to require a non anamporphic stream.
         * @param {number} [transcodingMaxAudioChannels] Optional. The maximum number of audio channels to transcode.
         * @param {number} [cpuCoreLimit] Optional. The limit of how many cpu cores to use.
         * @param {string} [liveStreamId] The live stream id.
         * @param {boolean} [enableMpegtsM2TsMode] Optional. Whether to enable the MpegtsM2Ts mode.
         * @param {string} [videoCodec] Optional. Specify a video codec to encode to, e.g. h264. If omitted the server will auto-select using the url\&#39;s extension. Options: h265, h264, mpeg4, theora, vpx, wmv.
         * @param {string} [subtitleCodec] Optional. Specify a subtitle codec to encode to.
         * @param {string} [transcodingReasons] Optional. The transcoding reason.
         * @param {number} [audioStreamIndex] Optional. The index of the audio stream to use. If omitted the first audio stream will be used.
         * @param {number} [videoStreamIndex] Optional. The index of the video stream to use. If omitted the first video stream will be used.
         * @param {EncodingContext} [context] Optional. The MediaBrowser.Model.Dlna.EncodingContext.
         * @param {{ [key: string]: string; }} [streamOptions] Optional. The streaming options.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHlsAudioSegment(itemId: string, playlistId: string, segmentId: number, container: string, _static?: boolean, params?: string, tag?: string, deviceProfileId?: string, playSessionId?: string, segmentContainer?: string, segmentLength?: number, minSegments?: number, mediaSourceId?: string, deviceId?: string, audioCodec?: string, enableAutoStreamCopy?: boolean, allowVideoStreamCopy?: boolean, allowAudioStreamCopy?: boolean, breakOnNonKeyFrames?: boolean, audioSampleRate?: number, maxAudioBitDepth?: number, audioBitRate?: number, audioChannels?: number, maxAudioChannels?: number, profile?: string, level?: string, framerate?: number, maxFramerate?: number, copyTimestamps?: boolean, startTimeTicks?: number, width?: number, height?: number, videoBitRate?: number, subtitleStreamIndex?: number, subtitleMethod?: SubtitleDeliveryMethod, maxRefFrames?: number, maxVideoBitDepth?: number, requireAvc?: boolean, deInterlace?: boolean, requireNonAnamorphic?: boolean, transcodingMaxAudioChannels?: number, cpuCoreLimit?: number, liveStreamId?: string, enableMpegtsM2TsMode?: boolean, videoCodec?: string, subtitleCodec?: string, transcodingReasons?: string, audioStreamIndex?: number, videoStreamIndex?: number, context?: EncodingContext, streamOptions?: { [key: string]: string; }, options?: any): AxiosPromise<any> {
            return DynamicHlsApiFp(configuration).getHlsAudioSegment(itemId, playlistId, segmentId, container, _static, params, tag, deviceProfileId, playSessionId, segmentContainer, segmentLength, minSegments, mediaSourceId, deviceId, audioCodec, enableAutoStreamCopy, allowVideoStreamCopy, allowAudioStreamCopy, breakOnNonKeyFrames, audioSampleRate, maxAudioBitDepth, audioBitRate, audioChannels, maxAudioChannels, profile, level, framerate, maxFramerate, copyTimestamps, startTimeTicks, width, height, videoBitRate, subtitleStreamIndex, subtitleMethod, maxRefFrames, maxVideoBitDepth, requireAvc, deInterlace, requireNonAnamorphic, transcodingMaxAudioChannels, cpuCoreLimit, liveStreamId, enableMpegtsM2TsMode, videoCodec, subtitleCodec, transcodingReasons, audioStreamIndex, videoStreamIndex, context, streamOptions, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets a video stream using HTTP live streaming.
         * @param {string} itemId The item id.
         * @param {string} playlistId The playlist id.
         * @param {number} segmentId The segment id.
         * @param {string} container The video container. Possible values are: ts, webm, asf, wmv, ogv, mp4, m4v, mkv, mpeg, mpg, avi, 3gp, wmv, wtv, m2ts, mov, iso, flv.
         * @param {boolean} [_static] Optional. If true, the original file will be streamed statically without any encoding. Use either no url extension or the original file extension. true/false.
         * @param {string} [params] The streaming parameters.
         * @param {string} [tag] The tag.
         * @param {string} [deviceProfileId] Optional. The dlna device profile id to utilize.
         * @param {string} [playSessionId] The play session id.
         * @param {string} [segmentContainer] The segment container.
         * @param {number} [segmentLength] The segment lenght.
         * @param {number} [minSegments] The minimum number of segments.
         * @param {string} [mediaSourceId] The media version id, if playing an alternate version.
         * @param {string} [deviceId] The device id of the client requesting. Used to stop encoding processes when needed.
         * @param {string} [audioCodec] Optional. Specify a audio codec to encode to, e.g. mp3. If omitted the server will auto-select using the url\&#39;s extension. Options: aac, mp3, vorbis, wma.
         * @param {boolean} [enableAutoStreamCopy] Whether or not to allow automatic stream copy if requested values match the original source. Defaults to true.
         * @param {boolean} [allowVideoStreamCopy] Whether or not to allow copying of the video stream url.
         * @param {boolean} [allowAudioStreamCopy] Whether or not to allow copying of the audio stream url.
         * @param {boolean} [breakOnNonKeyFrames] Optional. Whether to break on non key frames.
         * @param {number} [audioSampleRate] Optional. Specify a specific audio sample rate, e.g. 44100.
         * @param {number} [maxAudioBitDepth] Optional. The maximum audio bit depth.
         * @param {number} [audioBitRate] Optional. Specify an audio bitrate to encode to, e.g. 128000. If omitted this will be left to encoder defaults.
         * @param {number} [audioChannels] Optional. Specify a specific number of audio channels to encode to, e.g. 2.
         * @param {number} [maxAudioChannels] Optional. Specify a maximum number of audio channels to encode to, e.g. 2.
         * @param {string} [profile] Optional. Specify a specific an encoder profile (varies by encoder), e.g. main, baseline, high.
         * @param {string} [level] Optional. Specify a level for the encoder profile (varies by encoder), e.g. 3, 3.1.
         * @param {number} [framerate] Optional. A specific video framerate to encode to, e.g. 23.976. Generally this should be omitted unless the device has specific requirements.
         * @param {number} [maxFramerate] Optional. A specific maximum video framerate to encode to, e.g. 23.976. Generally this should be omitted unless the device has specific requirements.
         * @param {boolean} [copyTimestamps] Whether or not to copy timestamps when transcoding with an offset. Defaults to false.
         * @param {number} [startTimeTicks] Optional. Specify a starting offset, in ticks. 1 tick &#x3D; 10000 ms.
         * @param {number} [width] Optional. The fixed horizontal resolution of the encoded video.
         * @param {number} [height] Optional. The fixed vertical resolution of the encoded video.
         * @param {number} [videoBitRate] Optional. Specify a video bitrate to encode to, e.g. 500000. If omitted this will be left to encoder defaults.
         * @param {number} [subtitleStreamIndex] Optional. The index of the subtitle stream to use. If omitted no subtitles will be used.
         * @param {SubtitleDeliveryMethod} [subtitleMethod] Optional. Specify the subtitle delivery method.
         * @param {number} [maxRefFrames] Optional.
         * @param {number} [maxVideoBitDepth] Optional. The maximum video bit depth.
         * @param {boolean} [requireAvc] Optional. Whether to require avc.
         * @param {boolean} [deInterlace] Optional. Whether to deinterlace the video.
         * @param {boolean} [requireNonAnamorphic] Optional. Whether to require a non anamporphic stream.
         * @param {number} [transcodingMaxAudioChannels] Optional. The maximum number of audio channels to transcode.
         * @param {number} [cpuCoreLimit] Optional. The limit of how many cpu cores to use.
         * @param {string} [liveStreamId] The live stream id.
         * @param {boolean} [enableMpegtsM2TsMode] Optional. Whether to enable the MpegtsM2Ts mode.
         * @param {string} [videoCodec] Optional. Specify a video codec to encode to, e.g. h264. If omitted the server will auto-select using the url\&#39;s extension. Options: h265, h264, mpeg4, theora, vpx, wmv.
         * @param {string} [subtitleCodec] Optional. Specify a subtitle codec to encode to.
         * @param {string} [transcodingReasons] Optional. The transcoding reason.
         * @param {number} [audioStreamIndex] Optional. The index of the audio stream to use. If omitted the first audio stream will be used.
         * @param {number} [videoStreamIndex] Optional. The index of the video stream to use. If omitted the first video stream will be used.
         * @param {EncodingContext} [context] Optional. The MediaBrowser.Model.Dlna.EncodingContext.
         * @param {{ [key: string]: string; }} [streamOptions] Optional. The streaming options.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHlsVideoSegment(itemId: string, playlistId: string, segmentId: number, container: string, _static?: boolean, params?: string, tag?: string, deviceProfileId?: string, playSessionId?: string, segmentContainer?: string, segmentLength?: number, minSegments?: number, mediaSourceId?: string, deviceId?: string, audioCodec?: string, enableAutoStreamCopy?: boolean, allowVideoStreamCopy?: boolean, allowAudioStreamCopy?: boolean, breakOnNonKeyFrames?: boolean, audioSampleRate?: number, maxAudioBitDepth?: number, audioBitRate?: number, audioChannels?: number, maxAudioChannels?: number, profile?: string, level?: string, framerate?: number, maxFramerate?: number, copyTimestamps?: boolean, startTimeTicks?: number, width?: number, height?: number, videoBitRate?: number, subtitleStreamIndex?: number, subtitleMethod?: SubtitleDeliveryMethod, maxRefFrames?: number, maxVideoBitDepth?: number, requireAvc?: boolean, deInterlace?: boolean, requireNonAnamorphic?: boolean, transcodingMaxAudioChannels?: number, cpuCoreLimit?: number, liveStreamId?: string, enableMpegtsM2TsMode?: boolean, videoCodec?: string, subtitleCodec?: string, transcodingReasons?: string, audioStreamIndex?: number, videoStreamIndex?: number, context?: EncodingContext, streamOptions?: { [key: string]: string; }, options?: any): AxiosPromise<any> {
            return DynamicHlsApiFp(configuration).getHlsVideoSegment(itemId, playlistId, segmentId, container, _static, params, tag, deviceProfileId, playSessionId, segmentContainer, segmentLength, minSegments, mediaSourceId, deviceId, audioCodec, enableAutoStreamCopy, allowVideoStreamCopy, allowAudioStreamCopy, breakOnNonKeyFrames, audioSampleRate, maxAudioBitDepth, audioBitRate, audioChannels, maxAudioChannels, profile, level, framerate, maxFramerate, copyTimestamps, startTimeTicks, width, height, videoBitRate, subtitleStreamIndex, subtitleMethod, maxRefFrames, maxVideoBitDepth, requireAvc, deInterlace, requireNonAnamorphic, transcodingMaxAudioChannels, cpuCoreLimit, liveStreamId, enableMpegtsM2TsMode, videoCodec, subtitleCodec, transcodingReasons, audioStreamIndex, videoStreamIndex, context, streamOptions, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets an audio hls playlist stream.
         * @param {string} itemId The item id.
         * @param {string} mediaSourceId The media version id, if playing an alternate version.
         * @param {boolean} [_static] Optional. If true, the original file will be streamed statically without any encoding. Use either no url extension or the original file extension. true/false.
         * @param {string} [params] The streaming parameters.
         * @param {string} [tag] The tag.
         * @param {string} [deviceProfileId] Optional. The dlna device profile id to utilize.
         * @param {string} [playSessionId] The play session id.
         * @param {string} [segmentContainer] The segment container.
         * @param {number} [segmentLength] The segment lenght.
         * @param {number} [minSegments] The minimum number of segments.
         * @param {string} [deviceId] The device id of the client requesting. Used to stop encoding processes when needed.
         * @param {string} [audioCodec] Optional. Specify a audio codec to encode to, e.g. mp3. If omitted the server will auto-select using the url\&#39;s extension. Options: aac, mp3, vorbis, wma.
         * @param {boolean} [enableAutoStreamCopy] Whether or not to allow automatic stream copy if requested values match the original source. Defaults to true.
         * @param {boolean} [allowVideoStreamCopy] Whether or not to allow copying of the video stream url.
         * @param {boolean} [allowAudioStreamCopy] Whether or not to allow copying of the audio stream url.
         * @param {boolean} [breakOnNonKeyFrames] Optional. Whether to break on non key frames.
         * @param {number} [audioSampleRate] Optional. Specify a specific audio sample rate, e.g. 44100.
         * @param {number} [maxAudioBitDepth] Optional. The maximum audio bit depth.
         * @param {number} [audioBitRate] Optional. Specify an audio bitrate to encode to, e.g. 128000. If omitted this will be left to encoder defaults.
         * @param {number} [audioChannels] Optional. Specify a specific number of audio channels to encode to, e.g. 2.
         * @param {number} [maxAudioChannels] Optional. Specify a maximum number of audio channels to encode to, e.g. 2.
         * @param {string} [profile] Optional. Specify a specific an encoder profile (varies by encoder), e.g. main, baseline, high.
         * @param {string} [level] Optional. Specify a level for the encoder profile (varies by encoder), e.g. 3, 3.1.
         * @param {number} [framerate] Optional. A specific video framerate to encode to, e.g. 23.976. Generally this should be omitted unless the device has specific requirements.
         * @param {number} [maxFramerate] Optional. A specific maximum video framerate to encode to, e.g. 23.976. Generally this should be omitted unless the device has specific requirements.
         * @param {boolean} [copyTimestamps] Whether or not to copy timestamps when transcoding with an offset. Defaults to false.
         * @param {number} [startTimeTicks] Optional. Specify a starting offset, in ticks. 1 tick &#x3D; 10000 ms.
         * @param {number} [width] Optional. The fixed horizontal resolution of the encoded video.
         * @param {number} [height] Optional. The fixed vertical resolution of the encoded video.
         * @param {number} [videoBitRate] Optional. Specify a video bitrate to encode to, e.g. 500000. If omitted this will be left to encoder defaults.
         * @param {number} [subtitleStreamIndex] Optional. The index of the subtitle stream to use. If omitted no subtitles will be used.
         * @param {SubtitleDeliveryMethod} [subtitleMethod] Optional. Specify the subtitle delivery method.
         * @param {number} [maxRefFrames] Optional.
         * @param {number} [maxVideoBitDepth] Optional. The maximum video bit depth.
         * @param {boolean} [requireAvc] Optional. Whether to require avc.
         * @param {boolean} [deInterlace] Optional. Whether to deinterlace the video.
         * @param {boolean} [requireNonAnamorphic] Optional. Whether to require a non anamporphic stream.
         * @param {number} [transcodingMaxAudioChannels] Optional. The maximum number of audio channels to transcode.
         * @param {number} [cpuCoreLimit] Optional. The limit of how many cpu cores to use.
         * @param {string} [liveStreamId] The live stream id.
         * @param {boolean} [enableMpegtsM2TsMode] Optional. Whether to enable the MpegtsM2Ts mode.
         * @param {string} [videoCodec] Optional. Specify a video codec to encode to, e.g. h264. If omitted the server will auto-select using the url\&#39;s extension. Options: h265, h264, mpeg4, theora, vpx, wmv.
         * @param {string} [subtitleCodec] Optional. Specify a subtitle codec to encode to.
         * @param {string} [transcodingReasons] Optional. The transcoding reason.
         * @param {number} [audioStreamIndex] Optional. The index of the audio stream to use. If omitted the first audio stream will be used.
         * @param {number} [videoStreamIndex] Optional. The index of the video stream to use. If omitted the first video stream will be used.
         * @param {EncodingContext} [context] Optional. The MediaBrowser.Model.Dlna.EncodingContext.
         * @param {{ [key: string]: string; }} [streamOptions] Optional. The streaming options.
         * @param {boolean} [enableAdaptiveBitrateStreaming] Enable adaptive bitrate streaming.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMasterHlsAudioPlaylist(itemId: string, mediaSourceId: string, _static?: boolean, params?: string, tag?: string, deviceProfileId?: string, playSessionId?: string, segmentContainer?: string, segmentLength?: number, minSegments?: number, deviceId?: string, audioCodec?: string, enableAutoStreamCopy?: boolean, allowVideoStreamCopy?: boolean, allowAudioStreamCopy?: boolean, breakOnNonKeyFrames?: boolean, audioSampleRate?: number, maxAudioBitDepth?: number, audioBitRate?: number, audioChannels?: number, maxAudioChannels?: number, profile?: string, level?: string, framerate?: number, maxFramerate?: number, copyTimestamps?: boolean, startTimeTicks?: number, width?: number, height?: number, videoBitRate?: number, subtitleStreamIndex?: number, subtitleMethod?: SubtitleDeliveryMethod, maxRefFrames?: number, maxVideoBitDepth?: number, requireAvc?: boolean, deInterlace?: boolean, requireNonAnamorphic?: boolean, transcodingMaxAudioChannels?: number, cpuCoreLimit?: number, liveStreamId?: string, enableMpegtsM2TsMode?: boolean, videoCodec?: string, subtitleCodec?: string, transcodingReasons?: string, audioStreamIndex?: number, videoStreamIndex?: number, context?: EncodingContext, streamOptions?: { [key: string]: string; }, enableAdaptiveBitrateStreaming?: boolean, options?: any): AxiosPromise<any> {
            return DynamicHlsApiFp(configuration).getMasterHlsAudioPlaylist(itemId, mediaSourceId, _static, params, tag, deviceProfileId, playSessionId, segmentContainer, segmentLength, minSegments, deviceId, audioCodec, enableAutoStreamCopy, allowVideoStreamCopy, allowAudioStreamCopy, breakOnNonKeyFrames, audioSampleRate, maxAudioBitDepth, audioBitRate, audioChannels, maxAudioChannels, profile, level, framerate, maxFramerate, copyTimestamps, startTimeTicks, width, height, videoBitRate, subtitleStreamIndex, subtitleMethod, maxRefFrames, maxVideoBitDepth, requireAvc, deInterlace, requireNonAnamorphic, transcodingMaxAudioChannels, cpuCoreLimit, liveStreamId, enableMpegtsM2TsMode, videoCodec, subtitleCodec, transcodingReasons, audioStreamIndex, videoStreamIndex, context, streamOptions, enableAdaptiveBitrateStreaming, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets a video hls playlist stream.
         * @param {string} itemId The item id.
         * @param {string} mediaSourceId The media version id, if playing an alternate version.
         * @param {boolean} [_static] Optional. If true, the original file will be streamed statically without any encoding. Use either no url extension or the original file extension. true/false.
         * @param {string} [params] The streaming parameters.
         * @param {string} [tag] The tag.
         * @param {string} [deviceProfileId] Optional. The dlna device profile id to utilize.
         * @param {string} [playSessionId] The play session id.
         * @param {string} [segmentContainer] The segment container.
         * @param {number} [segmentLength] The segment lenght.
         * @param {number} [minSegments] The minimum number of segments.
         * @param {string} [deviceId] The device id of the client requesting. Used to stop encoding processes when needed.
         * @param {string} [audioCodec] Optional. Specify a audio codec to encode to, e.g. mp3. If omitted the server will auto-select using the url\&#39;s extension. Options: aac, mp3, vorbis, wma.
         * @param {boolean} [enableAutoStreamCopy] Whether or not to allow automatic stream copy if requested values match the original source. Defaults to true.
         * @param {boolean} [allowVideoStreamCopy] Whether or not to allow copying of the video stream url.
         * @param {boolean} [allowAudioStreamCopy] Whether or not to allow copying of the audio stream url.
         * @param {boolean} [breakOnNonKeyFrames] Optional. Whether to break on non key frames.
         * @param {number} [audioSampleRate] Optional. Specify a specific audio sample rate, e.g. 44100.
         * @param {number} [maxAudioBitDepth] Optional. The maximum audio bit depth.
         * @param {number} [audioBitRate] Optional. Specify an audio bitrate to encode to, e.g. 128000. If omitted this will be left to encoder defaults.
         * @param {number} [audioChannels] Optional. Specify a specific number of audio channels to encode to, e.g. 2.
         * @param {number} [maxAudioChannels] Optional. Specify a maximum number of audio channels to encode to, e.g. 2.
         * @param {string} [profile] Optional. Specify a specific an encoder profile (varies by encoder), e.g. main, baseline, high.
         * @param {string} [level] Optional. Specify a level for the encoder profile (varies by encoder), e.g. 3, 3.1.
         * @param {number} [framerate] Optional. A specific video framerate to encode to, e.g. 23.976. Generally this should be omitted unless the device has specific requirements.
         * @param {number} [maxFramerate] Optional. A specific maximum video framerate to encode to, e.g. 23.976. Generally this should be omitted unless the device has specific requirements.
         * @param {boolean} [copyTimestamps] Whether or not to copy timestamps when transcoding with an offset. Defaults to false.
         * @param {number} [startTimeTicks] Optional. Specify a starting offset, in ticks. 1 tick &#x3D; 10000 ms.
         * @param {number} [width] Optional. The fixed horizontal resolution of the encoded video.
         * @param {number} [height] Optional. The fixed vertical resolution of the encoded video.
         * @param {number} [videoBitRate] Optional. Specify a video bitrate to encode to, e.g. 500000. If omitted this will be left to encoder defaults.
         * @param {number} [subtitleStreamIndex] Optional. The index of the subtitle stream to use. If omitted no subtitles will be used.
         * @param {SubtitleDeliveryMethod} [subtitleMethod] Optional. Specify the subtitle delivery method.
         * @param {number} [maxRefFrames] Optional.
         * @param {number} [maxVideoBitDepth] Optional. The maximum video bit depth.
         * @param {boolean} [requireAvc] Optional. Whether to require avc.
         * @param {boolean} [deInterlace] Optional. Whether to deinterlace the video.
         * @param {boolean} [requireNonAnamorphic] Optional. Whether to require a non anamporphic stream.
         * @param {number} [transcodingMaxAudioChannels] Optional. The maximum number of audio channels to transcode.
         * @param {number} [cpuCoreLimit] Optional. The limit of how many cpu cores to use.
         * @param {string} [liveStreamId] The live stream id.
         * @param {boolean} [enableMpegtsM2TsMode] Optional. Whether to enable the MpegtsM2Ts mode.
         * @param {string} [videoCodec] Optional. Specify a video codec to encode to, e.g. h264. If omitted the server will auto-select using the url\&#39;s extension. Options: h265, h264, mpeg4, theora, vpx, wmv.
         * @param {string} [subtitleCodec] Optional. Specify a subtitle codec to encode to.
         * @param {string} [transcodingReasons] Optional. The transcoding reason.
         * @param {number} [audioStreamIndex] Optional. The index of the audio stream to use. If omitted the first audio stream will be used.
         * @param {number} [videoStreamIndex] Optional. The index of the video stream to use. If omitted the first video stream will be used.
         * @param {EncodingContext} [context] Optional. The MediaBrowser.Model.Dlna.EncodingContext.
         * @param {{ [key: string]: string; }} [streamOptions] Optional. The streaming options.
         * @param {boolean} [enableAdaptiveBitrateStreaming] Enable adaptive bitrate streaming.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMasterHlsVideoPlaylist(itemId: string, mediaSourceId: string, _static?: boolean, params?: string, tag?: string, deviceProfileId?: string, playSessionId?: string, segmentContainer?: string, segmentLength?: number, minSegments?: number, deviceId?: string, audioCodec?: string, enableAutoStreamCopy?: boolean, allowVideoStreamCopy?: boolean, allowAudioStreamCopy?: boolean, breakOnNonKeyFrames?: boolean, audioSampleRate?: number, maxAudioBitDepth?: number, audioBitRate?: number, audioChannels?: number, maxAudioChannels?: number, profile?: string, level?: string, framerate?: number, maxFramerate?: number, copyTimestamps?: boolean, startTimeTicks?: number, width?: number, height?: number, videoBitRate?: number, subtitleStreamIndex?: number, subtitleMethod?: SubtitleDeliveryMethod, maxRefFrames?: number, maxVideoBitDepth?: number, requireAvc?: boolean, deInterlace?: boolean, requireNonAnamorphic?: boolean, transcodingMaxAudioChannels?: number, cpuCoreLimit?: number, liveStreamId?: string, enableMpegtsM2TsMode?: boolean, videoCodec?: string, subtitleCodec?: string, transcodingReasons?: string, audioStreamIndex?: number, videoStreamIndex?: number, context?: EncodingContext, streamOptions?: { [key: string]: string; }, enableAdaptiveBitrateStreaming?: boolean, options?: any): AxiosPromise<any> {
            return DynamicHlsApiFp(configuration).getMasterHlsVideoPlaylist(itemId, mediaSourceId, _static, params, tag, deviceProfileId, playSessionId, segmentContainer, segmentLength, minSegments, deviceId, audioCodec, enableAutoStreamCopy, allowVideoStreamCopy, allowAudioStreamCopy, breakOnNonKeyFrames, audioSampleRate, maxAudioBitDepth, audioBitRate, audioChannels, maxAudioChannels, profile, level, framerate, maxFramerate, copyTimestamps, startTimeTicks, width, height, videoBitRate, subtitleStreamIndex, subtitleMethod, maxRefFrames, maxVideoBitDepth, requireAvc, deInterlace, requireNonAnamorphic, transcodingMaxAudioChannels, cpuCoreLimit, liveStreamId, enableMpegtsM2TsMode, videoCodec, subtitleCodec, transcodingReasons, audioStreamIndex, videoStreamIndex, context, streamOptions, enableAdaptiveBitrateStreaming, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets an audio stream using HTTP live streaming.
         * @param {string} itemId The item id.
         * @param {boolean} [_static] Optional. If true, the original file will be streamed statically without any encoding. Use either no url extension or the original file extension. true/false.
         * @param {string} [params] The streaming parameters.
         * @param {string} [tag] The tag.
         * @param {string} [deviceProfileId] Optional. The dlna device profile id to utilize.
         * @param {string} [playSessionId] The play session id.
         * @param {string} [segmentContainer] The segment container.
         * @param {number} [segmentLength] The segment lenght.
         * @param {number} [minSegments] The minimum number of segments.
         * @param {string} [mediaSourceId] The media version id, if playing an alternate version.
         * @param {string} [deviceId] The device id of the client requesting. Used to stop encoding processes when needed.
         * @param {string} [audioCodec] Optional. Specify a audio codec to encode to, e.g. mp3. If omitted the server will auto-select using the url\&#39;s extension. Options: aac, mp3, vorbis, wma.
         * @param {boolean} [enableAutoStreamCopy] Whether or not to allow automatic stream copy if requested values match the original source. Defaults to true.
         * @param {boolean} [allowVideoStreamCopy] Whether or not to allow copying of the video stream url.
         * @param {boolean} [allowAudioStreamCopy] Whether or not to allow copying of the audio stream url.
         * @param {boolean} [breakOnNonKeyFrames] Optional. Whether to break on non key frames.
         * @param {number} [audioSampleRate] Optional. Specify a specific audio sample rate, e.g. 44100.
         * @param {number} [maxAudioBitDepth] Optional. The maximum audio bit depth.
         * @param {number} [audioBitRate] Optional. Specify an audio bitrate to encode to, e.g. 128000. If omitted this will be left to encoder defaults.
         * @param {number} [audioChannels] Optional. Specify a specific number of audio channels to encode to, e.g. 2.
         * @param {number} [maxAudioChannels] Optional. Specify a maximum number of audio channels to encode to, e.g. 2.
         * @param {string} [profile] Optional. Specify a specific an encoder profile (varies by encoder), e.g. main, baseline, high.
         * @param {string} [level] Optional. Specify a level for the encoder profile (varies by encoder), e.g. 3, 3.1.
         * @param {number} [framerate] Optional. A specific video framerate to encode to, e.g. 23.976. Generally this should be omitted unless the device has specific requirements.
         * @param {number} [maxFramerate] Optional. A specific maximum video framerate to encode to, e.g. 23.976. Generally this should be omitted unless the device has specific requirements.
         * @param {boolean} [copyTimestamps] Whether or not to copy timestamps when transcoding with an offset. Defaults to false.
         * @param {number} [startTimeTicks] Optional. Specify a starting offset, in ticks. 1 tick &#x3D; 10000 ms.
         * @param {number} [width] Optional. The fixed horizontal resolution of the encoded video.
         * @param {number} [height] Optional. The fixed vertical resolution of the encoded video.
         * @param {number} [videoBitRate] Optional. Specify a video bitrate to encode to, e.g. 500000. If omitted this will be left to encoder defaults.
         * @param {number} [subtitleStreamIndex] Optional. The index of the subtitle stream to use. If omitted no subtitles will be used.
         * @param {SubtitleDeliveryMethod} [subtitleMethod] Optional. Specify the subtitle delivery method.
         * @param {number} [maxRefFrames] Optional.
         * @param {number} [maxVideoBitDepth] Optional. The maximum video bit depth.
         * @param {boolean} [requireAvc] Optional. Whether to require avc.
         * @param {boolean} [deInterlace] Optional. Whether to deinterlace the video.
         * @param {boolean} [requireNonAnamorphic] Optional. Whether to require a non anamporphic stream.
         * @param {number} [transcodingMaxAudioChannels] Optional. The maximum number of audio channels to transcode.
         * @param {number} [cpuCoreLimit] Optional. The limit of how many cpu cores to use.
         * @param {string} [liveStreamId] The live stream id.
         * @param {boolean} [enableMpegtsM2TsMode] Optional. Whether to enable the MpegtsM2Ts mode.
         * @param {string} [videoCodec] Optional. Specify a video codec to encode to, e.g. h264. If omitted the server will auto-select using the url\&#39;s extension. Options: h265, h264, mpeg4, theora, vpx, wmv.
         * @param {string} [subtitleCodec] Optional. Specify a subtitle codec to encode to.
         * @param {string} [transcodingReasons] Optional. The transcoding reason.
         * @param {number} [audioStreamIndex] Optional. The index of the audio stream to use. If omitted the first audio stream will be used.
         * @param {number} [videoStreamIndex] Optional. The index of the video stream to use. If omitted the first video stream will be used.
         * @param {EncodingContext} [context] Optional. The MediaBrowser.Model.Dlna.EncodingContext.
         * @param {{ [key: string]: string; }} [streamOptions] Optional. The streaming options.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVariantHlsAudioPlaylist(itemId: string, _static?: boolean, params?: string, tag?: string, deviceProfileId?: string, playSessionId?: string, segmentContainer?: string, segmentLength?: number, minSegments?: number, mediaSourceId?: string, deviceId?: string, audioCodec?: string, enableAutoStreamCopy?: boolean, allowVideoStreamCopy?: boolean, allowAudioStreamCopy?: boolean, breakOnNonKeyFrames?: boolean, audioSampleRate?: number, maxAudioBitDepth?: number, audioBitRate?: number, audioChannels?: number, maxAudioChannels?: number, profile?: string, level?: string, framerate?: number, maxFramerate?: number, copyTimestamps?: boolean, startTimeTicks?: number, width?: number, height?: number, videoBitRate?: number, subtitleStreamIndex?: number, subtitleMethod?: SubtitleDeliveryMethod, maxRefFrames?: number, maxVideoBitDepth?: number, requireAvc?: boolean, deInterlace?: boolean, requireNonAnamorphic?: boolean, transcodingMaxAudioChannels?: number, cpuCoreLimit?: number, liveStreamId?: string, enableMpegtsM2TsMode?: boolean, videoCodec?: string, subtitleCodec?: string, transcodingReasons?: string, audioStreamIndex?: number, videoStreamIndex?: number, context?: EncodingContext, streamOptions?: { [key: string]: string; }, options?: any): AxiosPromise<any> {
            return DynamicHlsApiFp(configuration).getVariantHlsAudioPlaylist(itemId, _static, params, tag, deviceProfileId, playSessionId, segmentContainer, segmentLength, minSegments, mediaSourceId, deviceId, audioCodec, enableAutoStreamCopy, allowVideoStreamCopy, allowAudioStreamCopy, breakOnNonKeyFrames, audioSampleRate, maxAudioBitDepth, audioBitRate, audioChannels, maxAudioChannels, profile, level, framerate, maxFramerate, copyTimestamps, startTimeTicks, width, height, videoBitRate, subtitleStreamIndex, subtitleMethod, maxRefFrames, maxVideoBitDepth, requireAvc, deInterlace, requireNonAnamorphic, transcodingMaxAudioChannels, cpuCoreLimit, liveStreamId, enableMpegtsM2TsMode, videoCodec, subtitleCodec, transcodingReasons, audioStreamIndex, videoStreamIndex, context, streamOptions, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets a video stream using HTTP live streaming.
         * @param {string} itemId The item id.
         * @param {boolean} [_static] Optional. If true, the original file will be streamed statically without any encoding. Use either no url extension or the original file extension. true/false.
         * @param {string} [params] The streaming parameters.
         * @param {string} [tag] The tag.
         * @param {string} [deviceProfileId] Optional. The dlna device profile id to utilize.
         * @param {string} [playSessionId] The play session id.
         * @param {string} [segmentContainer] The segment container.
         * @param {number} [segmentLength] The segment lenght.
         * @param {number} [minSegments] The minimum number of segments.
         * @param {string} [mediaSourceId] The media version id, if playing an alternate version.
         * @param {string} [deviceId] The device id of the client requesting. Used to stop encoding processes when needed.
         * @param {string} [audioCodec] Optional. Specify a audio codec to encode to, e.g. mp3. If omitted the server will auto-select using the url\&#39;s extension. Options: aac, mp3, vorbis, wma.
         * @param {boolean} [enableAutoStreamCopy] Whether or not to allow automatic stream copy if requested values match the original source. Defaults to true.
         * @param {boolean} [allowVideoStreamCopy] Whether or not to allow copying of the video stream url.
         * @param {boolean} [allowAudioStreamCopy] Whether or not to allow copying of the audio stream url.
         * @param {boolean} [breakOnNonKeyFrames] Optional. Whether to break on non key frames.
         * @param {number} [audioSampleRate] Optional. Specify a specific audio sample rate, e.g. 44100.
         * @param {number} [maxAudioBitDepth] Optional. The maximum audio bit depth.
         * @param {number} [audioBitRate] Optional. Specify an audio bitrate to encode to, e.g. 128000. If omitted this will be left to encoder defaults.
         * @param {number} [audioChannels] Optional. Specify a specific number of audio channels to encode to, e.g. 2.
         * @param {number} [maxAudioChannels] Optional. Specify a maximum number of audio channels to encode to, e.g. 2.
         * @param {string} [profile] Optional. Specify a specific an encoder profile (varies by encoder), e.g. main, baseline, high.
         * @param {string} [level] Optional. Specify a level for the encoder profile (varies by encoder), e.g. 3, 3.1.
         * @param {number} [framerate] Optional. A specific video framerate to encode to, e.g. 23.976. Generally this should be omitted unless the device has specific requirements.
         * @param {number} [maxFramerate] Optional. A specific maximum video framerate to encode to, e.g. 23.976. Generally this should be omitted unless the device has specific requirements.
         * @param {boolean} [copyTimestamps] Whether or not to copy timestamps when transcoding with an offset. Defaults to false.
         * @param {number} [startTimeTicks] Optional. Specify a starting offset, in ticks. 1 tick &#x3D; 10000 ms.
         * @param {number} [width] Optional. The fixed horizontal resolution of the encoded video.
         * @param {number} [height] Optional. The fixed vertical resolution of the encoded video.
         * @param {number} [videoBitRate] Optional. Specify a video bitrate to encode to, e.g. 500000. If omitted this will be left to encoder defaults.
         * @param {number} [subtitleStreamIndex] Optional. The index of the subtitle stream to use. If omitted no subtitles will be used.
         * @param {SubtitleDeliveryMethod} [subtitleMethod] Optional. Specify the subtitle delivery method.
         * @param {number} [maxRefFrames] Optional.
         * @param {number} [maxVideoBitDepth] Optional. The maximum video bit depth.
         * @param {boolean} [requireAvc] Optional. Whether to require avc.
         * @param {boolean} [deInterlace] Optional. Whether to deinterlace the video.
         * @param {boolean} [requireNonAnamorphic] Optional. Whether to require a non anamporphic stream.
         * @param {number} [transcodingMaxAudioChannels] Optional. The maximum number of audio channels to transcode.
         * @param {number} [cpuCoreLimit] Optional. The limit of how many cpu cores to use.
         * @param {string} [liveStreamId] The live stream id.
         * @param {boolean} [enableMpegtsM2TsMode] Optional. Whether to enable the MpegtsM2Ts mode.
         * @param {string} [videoCodec] Optional. Specify a video codec to encode to, e.g. h264. If omitted the server will auto-select using the url\&#39;s extension. Options: h265, h264, mpeg4, theora, vpx, wmv.
         * @param {string} [subtitleCodec] Optional. Specify a subtitle codec to encode to.
         * @param {string} [transcodingReasons] Optional. The transcoding reason.
         * @param {number} [audioStreamIndex] Optional. The index of the audio stream to use. If omitted the first audio stream will be used.
         * @param {number} [videoStreamIndex] Optional. The index of the video stream to use. If omitted the first video stream will be used.
         * @param {EncodingContext} [context] Optional. The MediaBrowser.Model.Dlna.EncodingContext.
         * @param {{ [key: string]: string; }} [streamOptions] Optional. The streaming options.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVariantHlsVideoPlaylist(itemId: string, _static?: boolean, params?: string, tag?: string, deviceProfileId?: string, playSessionId?: string, segmentContainer?: string, segmentLength?: number, minSegments?: number, mediaSourceId?: string, deviceId?: string, audioCodec?: string, enableAutoStreamCopy?: boolean, allowVideoStreamCopy?: boolean, allowAudioStreamCopy?: boolean, breakOnNonKeyFrames?: boolean, audioSampleRate?: number, maxAudioBitDepth?: number, audioBitRate?: number, audioChannels?: number, maxAudioChannels?: number, profile?: string, level?: string, framerate?: number, maxFramerate?: number, copyTimestamps?: boolean, startTimeTicks?: number, width?: number, height?: number, videoBitRate?: number, subtitleStreamIndex?: number, subtitleMethod?: SubtitleDeliveryMethod, maxRefFrames?: number, maxVideoBitDepth?: number, requireAvc?: boolean, deInterlace?: boolean, requireNonAnamorphic?: boolean, transcodingMaxAudioChannels?: number, cpuCoreLimit?: number, liveStreamId?: string, enableMpegtsM2TsMode?: boolean, videoCodec?: string, subtitleCodec?: string, transcodingReasons?: string, audioStreamIndex?: number, videoStreamIndex?: number, context?: EncodingContext, streamOptions?: { [key: string]: string; }, options?: any): AxiosPromise<any> {
            return DynamicHlsApiFp(configuration).getVariantHlsVideoPlaylist(itemId, _static, params, tag, deviceProfileId, playSessionId, segmentContainer, segmentLength, minSegments, mediaSourceId, deviceId, audioCodec, enableAutoStreamCopy, allowVideoStreamCopy, allowAudioStreamCopy, breakOnNonKeyFrames, audioSampleRate, maxAudioBitDepth, audioBitRate, audioChannels, maxAudioChannels, profile, level, framerate, maxFramerate, copyTimestamps, startTimeTicks, width, height, videoBitRate, subtitleStreamIndex, subtitleMethod, maxRefFrames, maxVideoBitDepth, requireAvc, deInterlace, requireNonAnamorphic, transcodingMaxAudioChannels, cpuCoreLimit, liveStreamId, enableMpegtsM2TsMode, videoCodec, subtitleCodec, transcodingReasons, audioStreamIndex, videoStreamIndex, context, streamOptions, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets an audio hls playlist stream.
         * @param {string} itemId The item id.
         * @param {string} mediaSourceId The media version id, if playing an alternate version.
         * @param {boolean} [_static] Optional. If true, the original file will be streamed statically without any encoding. Use either no url extension or the original file extension. true/false.
         * @param {string} [params] The streaming parameters.
         * @param {string} [tag] The tag.
         * @param {string} [deviceProfileId] Optional. The dlna device profile id to utilize.
         * @param {string} [playSessionId] The play session id.
         * @param {string} [segmentContainer] The segment container.
         * @param {number} [segmentLength] The segment lenght.
         * @param {number} [minSegments] The minimum number of segments.
         * @param {string} [deviceId] The device id of the client requesting. Used to stop encoding processes when needed.
         * @param {string} [audioCodec] Optional. Specify a audio codec to encode to, e.g. mp3. If omitted the server will auto-select using the url\&#39;s extension. Options: aac, mp3, vorbis, wma.
         * @param {boolean} [enableAutoStreamCopy] Whether or not to allow automatic stream copy if requested values match the original source. Defaults to true.
         * @param {boolean} [allowVideoStreamCopy] Whether or not to allow copying of the video stream url.
         * @param {boolean} [allowAudioStreamCopy] Whether or not to allow copying of the audio stream url.
         * @param {boolean} [breakOnNonKeyFrames] Optional. Whether to break on non key frames.
         * @param {number} [audioSampleRate] Optional. Specify a specific audio sample rate, e.g. 44100.
         * @param {number} [maxAudioBitDepth] Optional. The maximum audio bit depth.
         * @param {number} [audioBitRate] Optional. Specify an audio bitrate to encode to, e.g. 128000. If omitted this will be left to encoder defaults.
         * @param {number} [audioChannels] Optional. Specify a specific number of audio channels to encode to, e.g. 2.
         * @param {number} [maxAudioChannels] Optional. Specify a maximum number of audio channels to encode to, e.g. 2.
         * @param {string} [profile] Optional. Specify a specific an encoder profile (varies by encoder), e.g. main, baseline, high.
         * @param {string} [level] Optional. Specify a level for the encoder profile (varies by encoder), e.g. 3, 3.1.
         * @param {number} [framerate] Optional. A specific video framerate to encode to, e.g. 23.976. Generally this should be omitted unless the device has specific requirements.
         * @param {number} [maxFramerate] Optional. A specific maximum video framerate to encode to, e.g. 23.976. Generally this should be omitted unless the device has specific requirements.
         * @param {boolean} [copyTimestamps] Whether or not to copy timestamps when transcoding with an offset. Defaults to false.
         * @param {number} [startTimeTicks] Optional. Specify a starting offset, in ticks. 1 tick &#x3D; 10000 ms.
         * @param {number} [width] Optional. The fixed horizontal resolution of the encoded video.
         * @param {number} [height] Optional. The fixed vertical resolution of the encoded video.
         * @param {number} [videoBitRate] Optional. Specify a video bitrate to encode to, e.g. 500000. If omitted this will be left to encoder defaults.
         * @param {number} [subtitleStreamIndex] Optional. The index of the subtitle stream to use. If omitted no subtitles will be used.
         * @param {SubtitleDeliveryMethod} [subtitleMethod] Optional. Specify the subtitle delivery method.
         * @param {number} [maxRefFrames] Optional.
         * @param {number} [maxVideoBitDepth] Optional. The maximum video bit depth.
         * @param {boolean} [requireAvc] Optional. Whether to require avc.
         * @param {boolean} [deInterlace] Optional. Whether to deinterlace the video.
         * @param {boolean} [requireNonAnamorphic] Optional. Whether to require a non anamporphic stream.
         * @param {number} [transcodingMaxAudioChannels] Optional. The maximum number of audio channels to transcode.
         * @param {number} [cpuCoreLimit] Optional. The limit of how many cpu cores to use.
         * @param {string} [liveStreamId] The live stream id.
         * @param {boolean} [enableMpegtsM2TsMode] Optional. Whether to enable the MpegtsM2Ts mode.
         * @param {string} [videoCodec] Optional. Specify a video codec to encode to, e.g. h264. If omitted the server will auto-select using the url\&#39;s extension. Options: h265, h264, mpeg4, theora, vpx, wmv.
         * @param {string} [subtitleCodec] Optional. Specify a subtitle codec to encode to.
         * @param {string} [transcodingReasons] Optional. The transcoding reason.
         * @param {number} [audioStreamIndex] Optional. The index of the audio stream to use. If omitted the first audio stream will be used.
         * @param {number} [videoStreamIndex] Optional. The index of the video stream to use. If omitted the first video stream will be used.
         * @param {EncodingContext} [context] Optional. The MediaBrowser.Model.Dlna.EncodingContext.
         * @param {{ [key: string]: string; }} [streamOptions] Optional. The streaming options.
         * @param {boolean} [enableAdaptiveBitrateStreaming] Enable adaptive bitrate streaming.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        headMasterHlsAudioPlaylist(itemId: string, mediaSourceId: string, _static?: boolean, params?: string, tag?: string, deviceProfileId?: string, playSessionId?: string, segmentContainer?: string, segmentLength?: number, minSegments?: number, deviceId?: string, audioCodec?: string, enableAutoStreamCopy?: boolean, allowVideoStreamCopy?: boolean, allowAudioStreamCopy?: boolean, breakOnNonKeyFrames?: boolean, audioSampleRate?: number, maxAudioBitDepth?: number, audioBitRate?: number, audioChannels?: number, maxAudioChannels?: number, profile?: string, level?: string, framerate?: number, maxFramerate?: number, copyTimestamps?: boolean, startTimeTicks?: number, width?: number, height?: number, videoBitRate?: number, subtitleStreamIndex?: number, subtitleMethod?: SubtitleDeliveryMethod, maxRefFrames?: number, maxVideoBitDepth?: number, requireAvc?: boolean, deInterlace?: boolean, requireNonAnamorphic?: boolean, transcodingMaxAudioChannels?: number, cpuCoreLimit?: number, liveStreamId?: string, enableMpegtsM2TsMode?: boolean, videoCodec?: string, subtitleCodec?: string, transcodingReasons?: string, audioStreamIndex?: number, videoStreamIndex?: number, context?: EncodingContext, streamOptions?: { [key: string]: string; }, enableAdaptiveBitrateStreaming?: boolean, options?: any): AxiosPromise<any> {
            return DynamicHlsApiFp(configuration).headMasterHlsAudioPlaylist(itemId, mediaSourceId, _static, params, tag, deviceProfileId, playSessionId, segmentContainer, segmentLength, minSegments, deviceId, audioCodec, enableAutoStreamCopy, allowVideoStreamCopy, allowAudioStreamCopy, breakOnNonKeyFrames, audioSampleRate, maxAudioBitDepth, audioBitRate, audioChannels, maxAudioChannels, profile, level, framerate, maxFramerate, copyTimestamps, startTimeTicks, width, height, videoBitRate, subtitleStreamIndex, subtitleMethod, maxRefFrames, maxVideoBitDepth, requireAvc, deInterlace, requireNonAnamorphic, transcodingMaxAudioChannels, cpuCoreLimit, liveStreamId, enableMpegtsM2TsMode, videoCodec, subtitleCodec, transcodingReasons, audioStreamIndex, videoStreamIndex, context, streamOptions, enableAdaptiveBitrateStreaming, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets a video hls playlist stream.
         * @param {string} itemId The item id.
         * @param {string} mediaSourceId The media version id, if playing an alternate version.
         * @param {boolean} [_static] Optional. If true, the original file will be streamed statically without any encoding. Use either no url extension or the original file extension. true/false.
         * @param {string} [params] The streaming parameters.
         * @param {string} [tag] The tag.
         * @param {string} [deviceProfileId] Optional. The dlna device profile id to utilize.
         * @param {string} [playSessionId] The play session id.
         * @param {string} [segmentContainer] The segment container.
         * @param {number} [segmentLength] The segment lenght.
         * @param {number} [minSegments] The minimum number of segments.
         * @param {string} [deviceId] The device id of the client requesting. Used to stop encoding processes when needed.
         * @param {string} [audioCodec] Optional. Specify a audio codec to encode to, e.g. mp3. If omitted the server will auto-select using the url\&#39;s extension. Options: aac, mp3, vorbis, wma.
         * @param {boolean} [enableAutoStreamCopy] Whether or not to allow automatic stream copy if requested values match the original source. Defaults to true.
         * @param {boolean} [allowVideoStreamCopy] Whether or not to allow copying of the video stream url.
         * @param {boolean} [allowAudioStreamCopy] Whether or not to allow copying of the audio stream url.
         * @param {boolean} [breakOnNonKeyFrames] Optional. Whether to break on non key frames.
         * @param {number} [audioSampleRate] Optional. Specify a specific audio sample rate, e.g. 44100.
         * @param {number} [maxAudioBitDepth] Optional. The maximum audio bit depth.
         * @param {number} [audioBitRate] Optional. Specify an audio bitrate to encode to, e.g. 128000. If omitted this will be left to encoder defaults.
         * @param {number} [audioChannels] Optional. Specify a specific number of audio channels to encode to, e.g. 2.
         * @param {number} [maxAudioChannels] Optional. Specify a maximum number of audio channels to encode to, e.g. 2.
         * @param {string} [profile] Optional. Specify a specific an encoder profile (varies by encoder), e.g. main, baseline, high.
         * @param {string} [level] Optional. Specify a level for the encoder profile (varies by encoder), e.g. 3, 3.1.
         * @param {number} [framerate] Optional. A specific video framerate to encode to, e.g. 23.976. Generally this should be omitted unless the device has specific requirements.
         * @param {number} [maxFramerate] Optional. A specific maximum video framerate to encode to, e.g. 23.976. Generally this should be omitted unless the device has specific requirements.
         * @param {boolean} [copyTimestamps] Whether or not to copy timestamps when transcoding with an offset. Defaults to false.
         * @param {number} [startTimeTicks] Optional. Specify a starting offset, in ticks. 1 tick &#x3D; 10000 ms.
         * @param {number} [width] Optional. The fixed horizontal resolution of the encoded video.
         * @param {number} [height] Optional. The fixed vertical resolution of the encoded video.
         * @param {number} [videoBitRate] Optional. Specify a video bitrate to encode to, e.g. 500000. If omitted this will be left to encoder defaults.
         * @param {number} [subtitleStreamIndex] Optional. The index of the subtitle stream to use. If omitted no subtitles will be used.
         * @param {SubtitleDeliveryMethod} [subtitleMethod] Optional. Specify the subtitle delivery method.
         * @param {number} [maxRefFrames] Optional.
         * @param {number} [maxVideoBitDepth] Optional. The maximum video bit depth.
         * @param {boolean} [requireAvc] Optional. Whether to require avc.
         * @param {boolean} [deInterlace] Optional. Whether to deinterlace the video.
         * @param {boolean} [requireNonAnamorphic] Optional. Whether to require a non anamporphic stream.
         * @param {number} [transcodingMaxAudioChannels] Optional. The maximum number of audio channels to transcode.
         * @param {number} [cpuCoreLimit] Optional. The limit of how many cpu cores to use.
         * @param {string} [liveStreamId] The live stream id.
         * @param {boolean} [enableMpegtsM2TsMode] Optional. Whether to enable the MpegtsM2Ts mode.
         * @param {string} [videoCodec] Optional. Specify a video codec to encode to, e.g. h264. If omitted the server will auto-select using the url\&#39;s extension. Options: h265, h264, mpeg4, theora, vpx, wmv.
         * @param {string} [subtitleCodec] Optional. Specify a subtitle codec to encode to.
         * @param {string} [transcodingReasons] Optional. The transcoding reason.
         * @param {number} [audioStreamIndex] Optional. The index of the audio stream to use. If omitted the first audio stream will be used.
         * @param {number} [videoStreamIndex] Optional. The index of the video stream to use. If omitted the first video stream will be used.
         * @param {EncodingContext} [context] Optional. The MediaBrowser.Model.Dlna.EncodingContext.
         * @param {{ [key: string]: string; }} [streamOptions] Optional. The streaming options.
         * @param {boolean} [enableAdaptiveBitrateStreaming] Enable adaptive bitrate streaming.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        headMasterHlsVideoPlaylist(itemId: string, mediaSourceId: string, _static?: boolean, params?: string, tag?: string, deviceProfileId?: string, playSessionId?: string, segmentContainer?: string, segmentLength?: number, minSegments?: number, deviceId?: string, audioCodec?: string, enableAutoStreamCopy?: boolean, allowVideoStreamCopy?: boolean, allowAudioStreamCopy?: boolean, breakOnNonKeyFrames?: boolean, audioSampleRate?: number, maxAudioBitDepth?: number, audioBitRate?: number, audioChannels?: number, maxAudioChannels?: number, profile?: string, level?: string, framerate?: number, maxFramerate?: number, copyTimestamps?: boolean, startTimeTicks?: number, width?: number, height?: number, videoBitRate?: number, subtitleStreamIndex?: number, subtitleMethod?: SubtitleDeliveryMethod, maxRefFrames?: number, maxVideoBitDepth?: number, requireAvc?: boolean, deInterlace?: boolean, requireNonAnamorphic?: boolean, transcodingMaxAudioChannels?: number, cpuCoreLimit?: number, liveStreamId?: string, enableMpegtsM2TsMode?: boolean, videoCodec?: string, subtitleCodec?: string, transcodingReasons?: string, audioStreamIndex?: number, videoStreamIndex?: number, context?: EncodingContext, streamOptions?: { [key: string]: string; }, enableAdaptiveBitrateStreaming?: boolean, options?: any): AxiosPromise<any> {
            return DynamicHlsApiFp(configuration).headMasterHlsVideoPlaylist(itemId, mediaSourceId, _static, params, tag, deviceProfileId, playSessionId, segmentContainer, segmentLength, minSegments, deviceId, audioCodec, enableAutoStreamCopy, allowVideoStreamCopy, allowAudioStreamCopy, breakOnNonKeyFrames, audioSampleRate, maxAudioBitDepth, audioBitRate, audioChannels, maxAudioChannels, profile, level, framerate, maxFramerate, copyTimestamps, startTimeTicks, width, height, videoBitRate, subtitleStreamIndex, subtitleMethod, maxRefFrames, maxVideoBitDepth, requireAvc, deInterlace, requireNonAnamorphic, transcodingMaxAudioChannels, cpuCoreLimit, liveStreamId, enableMpegtsM2TsMode, videoCodec, subtitleCodec, transcodingReasons, audioStreamIndex, videoStreamIndex, context, streamOptions, enableAdaptiveBitrateStreaming, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getHlsAudioSegment operation in DynamicHlsApi.
 * @export
 * @interface DynamicHlsApiGetHlsAudioSegmentRequest
 */
export interface DynamicHlsApiGetHlsAudioSegmentRequest {
    /**
     * The item id.
     * @type {string}
     * @memberof DynamicHlsApiGetHlsAudioSegment
     */
    readonly itemId: string

    /**
     * The playlist id.
     * @type {string}
     * @memberof DynamicHlsApiGetHlsAudioSegment
     */
    readonly playlistId: string

    /**
     * The segment id.
     * @type {number}
     * @memberof DynamicHlsApiGetHlsAudioSegment
     */
    readonly segmentId: number

    /**
     * The video container. Possible values are: ts, webm, asf, wmv, ogv, mp4, m4v, mkv, mpeg, mpg, avi, 3gp, wmv, wtv, m2ts, mov, iso, flv.
     * @type {string}
     * @memberof DynamicHlsApiGetHlsAudioSegment
     */
    readonly container: string

    /**
     * Optional. If true, the original file will be streamed statically without any encoding. Use either no url extension or the original file extension. true/false.
     * @type {boolean}
     * @memberof DynamicHlsApiGetHlsAudioSegment
     */
    readonly _static?: boolean

    /**
     * The streaming parameters.
     * @type {string}
     * @memberof DynamicHlsApiGetHlsAudioSegment
     */
    readonly params?: string

    /**
     * The tag.
     * @type {string}
     * @memberof DynamicHlsApiGetHlsAudioSegment
     */
    readonly tag?: string

    /**
     * Optional. The dlna device profile id to utilize.
     * @type {string}
     * @memberof DynamicHlsApiGetHlsAudioSegment
     */
    readonly deviceProfileId?: string

    /**
     * The play session id.
     * @type {string}
     * @memberof DynamicHlsApiGetHlsAudioSegment
     */
    readonly playSessionId?: string

    /**
     * The segment container.
     * @type {string}
     * @memberof DynamicHlsApiGetHlsAudioSegment
     */
    readonly segmentContainer?: string

    /**
     * The segment lenght.
     * @type {number}
     * @memberof DynamicHlsApiGetHlsAudioSegment
     */
    readonly segmentLength?: number

    /**
     * The minimum number of segments.
     * @type {number}
     * @memberof DynamicHlsApiGetHlsAudioSegment
     */
    readonly minSegments?: number

    /**
     * The media version id, if playing an alternate version.
     * @type {string}
     * @memberof DynamicHlsApiGetHlsAudioSegment
     */
    readonly mediaSourceId?: string

    /**
     * The device id of the client requesting. Used to stop encoding processes when needed.
     * @type {string}
     * @memberof DynamicHlsApiGetHlsAudioSegment
     */
    readonly deviceId?: string

    /**
     * Optional. Specify a audio codec to encode to, e.g. mp3. If omitted the server will auto-select using the url\&#39;s extension. Options: aac, mp3, vorbis, wma.
     * @type {string}
     * @memberof DynamicHlsApiGetHlsAudioSegment
     */
    readonly audioCodec?: string

    /**
     * Whether or not to allow automatic stream copy if requested values match the original source. Defaults to true.
     * @type {boolean}
     * @memberof DynamicHlsApiGetHlsAudioSegment
     */
    readonly enableAutoStreamCopy?: boolean

    /**
     * Whether or not to allow copying of the video stream url.
     * @type {boolean}
     * @memberof DynamicHlsApiGetHlsAudioSegment
     */
    readonly allowVideoStreamCopy?: boolean

    /**
     * Whether or not to allow copying of the audio stream url.
     * @type {boolean}
     * @memberof DynamicHlsApiGetHlsAudioSegment
     */
    readonly allowAudioStreamCopy?: boolean

    /**
     * Optional. Whether to break on non key frames.
     * @type {boolean}
     * @memberof DynamicHlsApiGetHlsAudioSegment
     */
    readonly breakOnNonKeyFrames?: boolean

    /**
     * Optional. Specify a specific audio sample rate, e.g. 44100.
     * @type {number}
     * @memberof DynamicHlsApiGetHlsAudioSegment
     */
    readonly audioSampleRate?: number

    /**
     * Optional. The maximum audio bit depth.
     * @type {number}
     * @memberof DynamicHlsApiGetHlsAudioSegment
     */
    readonly maxAudioBitDepth?: number

    /**
     * Optional. Specify an audio bitrate to encode to, e.g. 128000. If omitted this will be left to encoder defaults.
     * @type {number}
     * @memberof DynamicHlsApiGetHlsAudioSegment
     */
    readonly audioBitRate?: number

    /**
     * Optional. Specify a specific number of audio channels to encode to, e.g. 2.
     * @type {number}
     * @memberof DynamicHlsApiGetHlsAudioSegment
     */
    readonly audioChannels?: number

    /**
     * Optional. Specify a maximum number of audio channels to encode to, e.g. 2.
     * @type {number}
     * @memberof DynamicHlsApiGetHlsAudioSegment
     */
    readonly maxAudioChannels?: number

    /**
     * Optional. Specify a specific an encoder profile (varies by encoder), e.g. main, baseline, high.
     * @type {string}
     * @memberof DynamicHlsApiGetHlsAudioSegment
     */
    readonly profile?: string

    /**
     * Optional. Specify a level for the encoder profile (varies by encoder), e.g. 3, 3.1.
     * @type {string}
     * @memberof DynamicHlsApiGetHlsAudioSegment
     */
    readonly level?: string

    /**
     * Optional. A specific video framerate to encode to, e.g. 23.976. Generally this should be omitted unless the device has specific requirements.
     * @type {number}
     * @memberof DynamicHlsApiGetHlsAudioSegment
     */
    readonly framerate?: number

    /**
     * Optional. A specific maximum video framerate to encode to, e.g. 23.976. Generally this should be omitted unless the device has specific requirements.
     * @type {number}
     * @memberof DynamicHlsApiGetHlsAudioSegment
     */
    readonly maxFramerate?: number

    /**
     * Whether or not to copy timestamps when transcoding with an offset. Defaults to false.
     * @type {boolean}
     * @memberof DynamicHlsApiGetHlsAudioSegment
     */
    readonly copyTimestamps?: boolean

    /**
     * Optional. Specify a starting offset, in ticks. 1 tick &#x3D; 10000 ms.
     * @type {number}
     * @memberof DynamicHlsApiGetHlsAudioSegment
     */
    readonly startTimeTicks?: number

    /**
     * Optional. The fixed horizontal resolution of the encoded video.
     * @type {number}
     * @memberof DynamicHlsApiGetHlsAudioSegment
     */
    readonly width?: number

    /**
     * Optional. The fixed vertical resolution of the encoded video.
     * @type {number}
     * @memberof DynamicHlsApiGetHlsAudioSegment
     */
    readonly height?: number

    /**
     * Optional. Specify a video bitrate to encode to, e.g. 500000. If omitted this will be left to encoder defaults.
     * @type {number}
     * @memberof DynamicHlsApiGetHlsAudioSegment
     */
    readonly videoBitRate?: number

    /**
     * Optional. The index of the subtitle stream to use. If omitted no subtitles will be used.
     * @type {number}
     * @memberof DynamicHlsApiGetHlsAudioSegment
     */
    readonly subtitleStreamIndex?: number

    /**
     * Optional. Specify the subtitle delivery method.
     * @type {SubtitleDeliveryMethod}
     * @memberof DynamicHlsApiGetHlsAudioSegment
     */
    readonly subtitleMethod?: SubtitleDeliveryMethod

    /**
     * Optional.
     * @type {number}
     * @memberof DynamicHlsApiGetHlsAudioSegment
     */
    readonly maxRefFrames?: number

    /**
     * Optional. The maximum video bit depth.
     * @type {number}
     * @memberof DynamicHlsApiGetHlsAudioSegment
     */
    readonly maxVideoBitDepth?: number

    /**
     * Optional. Whether to require avc.
     * @type {boolean}
     * @memberof DynamicHlsApiGetHlsAudioSegment
     */
    readonly requireAvc?: boolean

    /**
     * Optional. Whether to deinterlace the video.
     * @type {boolean}
     * @memberof DynamicHlsApiGetHlsAudioSegment
     */
    readonly deInterlace?: boolean

    /**
     * Optional. Whether to require a non anamporphic stream.
     * @type {boolean}
     * @memberof DynamicHlsApiGetHlsAudioSegment
     */
    readonly requireNonAnamorphic?: boolean

    /**
     * Optional. The maximum number of audio channels to transcode.
     * @type {number}
     * @memberof DynamicHlsApiGetHlsAudioSegment
     */
    readonly transcodingMaxAudioChannels?: number

    /**
     * Optional. The limit of how many cpu cores to use.
     * @type {number}
     * @memberof DynamicHlsApiGetHlsAudioSegment
     */
    readonly cpuCoreLimit?: number

    /**
     * The live stream id.
     * @type {string}
     * @memberof DynamicHlsApiGetHlsAudioSegment
     */
    readonly liveStreamId?: string

    /**
     * Optional. Whether to enable the MpegtsM2Ts mode.
     * @type {boolean}
     * @memberof DynamicHlsApiGetHlsAudioSegment
     */
    readonly enableMpegtsM2TsMode?: boolean

    /**
     * Optional. Specify a video codec to encode to, e.g. h264. If omitted the server will auto-select using the url\&#39;s extension. Options: h265, h264, mpeg4, theora, vpx, wmv.
     * @type {string}
     * @memberof DynamicHlsApiGetHlsAudioSegment
     */
    readonly videoCodec?: string

    /**
     * Optional. Specify a subtitle codec to encode to.
     * @type {string}
     * @memberof DynamicHlsApiGetHlsAudioSegment
     */
    readonly subtitleCodec?: string

    /**
     * Optional. The transcoding reason.
     * @type {string}
     * @memberof DynamicHlsApiGetHlsAudioSegment
     */
    readonly transcodingReasons?: string

    /**
     * Optional. The index of the audio stream to use. If omitted the first audio stream will be used.
     * @type {number}
     * @memberof DynamicHlsApiGetHlsAudioSegment
     */
    readonly audioStreamIndex?: number

    /**
     * Optional. The index of the video stream to use. If omitted the first video stream will be used.
     * @type {number}
     * @memberof DynamicHlsApiGetHlsAudioSegment
     */
    readonly videoStreamIndex?: number

    /**
     * Optional. The MediaBrowser.Model.Dlna.EncodingContext.
     * @type {EncodingContext}
     * @memberof DynamicHlsApiGetHlsAudioSegment
     */
    readonly context?: EncodingContext

    /**
     * Optional. The streaming options.
     * @type {{ [key: string]: string; }}
     * @memberof DynamicHlsApiGetHlsAudioSegment
     */
    readonly streamOptions?: { [key: string]: string; }
}

/**
 * Request parameters for getHlsVideoSegment operation in DynamicHlsApi.
 * @export
 * @interface DynamicHlsApiGetHlsVideoSegmentRequest
 */
export interface DynamicHlsApiGetHlsVideoSegmentRequest {
    /**
     * The item id.
     * @type {string}
     * @memberof DynamicHlsApiGetHlsVideoSegment
     */
    readonly itemId: string

    /**
     * The playlist id.
     * @type {string}
     * @memberof DynamicHlsApiGetHlsVideoSegment
     */
    readonly playlistId: string

    /**
     * The segment id.
     * @type {number}
     * @memberof DynamicHlsApiGetHlsVideoSegment
     */
    readonly segmentId: number

    /**
     * The video container. Possible values are: ts, webm, asf, wmv, ogv, mp4, m4v, mkv, mpeg, mpg, avi, 3gp, wmv, wtv, m2ts, mov, iso, flv.
     * @type {string}
     * @memberof DynamicHlsApiGetHlsVideoSegment
     */
    readonly container: string

    /**
     * Optional. If true, the original file will be streamed statically without any encoding. Use either no url extension or the original file extension. true/false.
     * @type {boolean}
     * @memberof DynamicHlsApiGetHlsVideoSegment
     */
    readonly _static?: boolean

    /**
     * The streaming parameters.
     * @type {string}
     * @memberof DynamicHlsApiGetHlsVideoSegment
     */
    readonly params?: string

    /**
     * The tag.
     * @type {string}
     * @memberof DynamicHlsApiGetHlsVideoSegment
     */
    readonly tag?: string

    /**
     * Optional. The dlna device profile id to utilize.
     * @type {string}
     * @memberof DynamicHlsApiGetHlsVideoSegment
     */
    readonly deviceProfileId?: string

    /**
     * The play session id.
     * @type {string}
     * @memberof DynamicHlsApiGetHlsVideoSegment
     */
    readonly playSessionId?: string

    /**
     * The segment container.
     * @type {string}
     * @memberof DynamicHlsApiGetHlsVideoSegment
     */
    readonly segmentContainer?: string

    /**
     * The segment lenght.
     * @type {number}
     * @memberof DynamicHlsApiGetHlsVideoSegment
     */
    readonly segmentLength?: number

    /**
     * The minimum number of segments.
     * @type {number}
     * @memberof DynamicHlsApiGetHlsVideoSegment
     */
    readonly minSegments?: number

    /**
     * The media version id, if playing an alternate version.
     * @type {string}
     * @memberof DynamicHlsApiGetHlsVideoSegment
     */
    readonly mediaSourceId?: string

    /**
     * The device id of the client requesting. Used to stop encoding processes when needed.
     * @type {string}
     * @memberof DynamicHlsApiGetHlsVideoSegment
     */
    readonly deviceId?: string

    /**
     * Optional. Specify a audio codec to encode to, e.g. mp3. If omitted the server will auto-select using the url\&#39;s extension. Options: aac, mp3, vorbis, wma.
     * @type {string}
     * @memberof DynamicHlsApiGetHlsVideoSegment
     */
    readonly audioCodec?: string

    /**
     * Whether or not to allow automatic stream copy if requested values match the original source. Defaults to true.
     * @type {boolean}
     * @memberof DynamicHlsApiGetHlsVideoSegment
     */
    readonly enableAutoStreamCopy?: boolean

    /**
     * Whether or not to allow copying of the video stream url.
     * @type {boolean}
     * @memberof DynamicHlsApiGetHlsVideoSegment
     */
    readonly allowVideoStreamCopy?: boolean

    /**
     * Whether or not to allow copying of the audio stream url.
     * @type {boolean}
     * @memberof DynamicHlsApiGetHlsVideoSegment
     */
    readonly allowAudioStreamCopy?: boolean

    /**
     * Optional. Whether to break on non key frames.
     * @type {boolean}
     * @memberof DynamicHlsApiGetHlsVideoSegment
     */
    readonly breakOnNonKeyFrames?: boolean

    /**
     * Optional. Specify a specific audio sample rate, e.g. 44100.
     * @type {number}
     * @memberof DynamicHlsApiGetHlsVideoSegment
     */
    readonly audioSampleRate?: number

    /**
     * Optional. The maximum audio bit depth.
     * @type {number}
     * @memberof DynamicHlsApiGetHlsVideoSegment
     */
    readonly maxAudioBitDepth?: number

    /**
     * Optional. Specify an audio bitrate to encode to, e.g. 128000. If omitted this will be left to encoder defaults.
     * @type {number}
     * @memberof DynamicHlsApiGetHlsVideoSegment
     */
    readonly audioBitRate?: number

    /**
     * Optional. Specify a specific number of audio channels to encode to, e.g. 2.
     * @type {number}
     * @memberof DynamicHlsApiGetHlsVideoSegment
     */
    readonly audioChannels?: number

    /**
     * Optional. Specify a maximum number of audio channels to encode to, e.g. 2.
     * @type {number}
     * @memberof DynamicHlsApiGetHlsVideoSegment
     */
    readonly maxAudioChannels?: number

    /**
     * Optional. Specify a specific an encoder profile (varies by encoder), e.g. main, baseline, high.
     * @type {string}
     * @memberof DynamicHlsApiGetHlsVideoSegment
     */
    readonly profile?: string

    /**
     * Optional. Specify a level for the encoder profile (varies by encoder), e.g. 3, 3.1.
     * @type {string}
     * @memberof DynamicHlsApiGetHlsVideoSegment
     */
    readonly level?: string

    /**
     * Optional. A specific video framerate to encode to, e.g. 23.976. Generally this should be omitted unless the device has specific requirements.
     * @type {number}
     * @memberof DynamicHlsApiGetHlsVideoSegment
     */
    readonly framerate?: number

    /**
     * Optional. A specific maximum video framerate to encode to, e.g. 23.976. Generally this should be omitted unless the device has specific requirements.
     * @type {number}
     * @memberof DynamicHlsApiGetHlsVideoSegment
     */
    readonly maxFramerate?: number

    /**
     * Whether or not to copy timestamps when transcoding with an offset. Defaults to false.
     * @type {boolean}
     * @memberof DynamicHlsApiGetHlsVideoSegment
     */
    readonly copyTimestamps?: boolean

    /**
     * Optional. Specify a starting offset, in ticks. 1 tick &#x3D; 10000 ms.
     * @type {number}
     * @memberof DynamicHlsApiGetHlsVideoSegment
     */
    readonly startTimeTicks?: number

    /**
     * Optional. The fixed horizontal resolution of the encoded video.
     * @type {number}
     * @memberof DynamicHlsApiGetHlsVideoSegment
     */
    readonly width?: number

    /**
     * Optional. The fixed vertical resolution of the encoded video.
     * @type {number}
     * @memberof DynamicHlsApiGetHlsVideoSegment
     */
    readonly height?: number

    /**
     * Optional. Specify a video bitrate to encode to, e.g. 500000. If omitted this will be left to encoder defaults.
     * @type {number}
     * @memberof DynamicHlsApiGetHlsVideoSegment
     */
    readonly videoBitRate?: number

    /**
     * Optional. The index of the subtitle stream to use. If omitted no subtitles will be used.
     * @type {number}
     * @memberof DynamicHlsApiGetHlsVideoSegment
     */
    readonly subtitleStreamIndex?: number

    /**
     * Optional. Specify the subtitle delivery method.
     * @type {SubtitleDeliveryMethod}
     * @memberof DynamicHlsApiGetHlsVideoSegment
     */
    readonly subtitleMethod?: SubtitleDeliveryMethod

    /**
     * Optional.
     * @type {number}
     * @memberof DynamicHlsApiGetHlsVideoSegment
     */
    readonly maxRefFrames?: number

    /**
     * Optional. The maximum video bit depth.
     * @type {number}
     * @memberof DynamicHlsApiGetHlsVideoSegment
     */
    readonly maxVideoBitDepth?: number

    /**
     * Optional. Whether to require avc.
     * @type {boolean}
     * @memberof DynamicHlsApiGetHlsVideoSegment
     */
    readonly requireAvc?: boolean

    /**
     * Optional. Whether to deinterlace the video.
     * @type {boolean}
     * @memberof DynamicHlsApiGetHlsVideoSegment
     */
    readonly deInterlace?: boolean

    /**
     * Optional. Whether to require a non anamporphic stream.
     * @type {boolean}
     * @memberof DynamicHlsApiGetHlsVideoSegment
     */
    readonly requireNonAnamorphic?: boolean

    /**
     * Optional. The maximum number of audio channels to transcode.
     * @type {number}
     * @memberof DynamicHlsApiGetHlsVideoSegment
     */
    readonly transcodingMaxAudioChannels?: number

    /**
     * Optional. The limit of how many cpu cores to use.
     * @type {number}
     * @memberof DynamicHlsApiGetHlsVideoSegment
     */
    readonly cpuCoreLimit?: number

    /**
     * The live stream id.
     * @type {string}
     * @memberof DynamicHlsApiGetHlsVideoSegment
     */
    readonly liveStreamId?: string

    /**
     * Optional. Whether to enable the MpegtsM2Ts mode.
     * @type {boolean}
     * @memberof DynamicHlsApiGetHlsVideoSegment
     */
    readonly enableMpegtsM2TsMode?: boolean

    /**
     * Optional. Specify a video codec to encode to, e.g. h264. If omitted the server will auto-select using the url\&#39;s extension. Options: h265, h264, mpeg4, theora, vpx, wmv.
     * @type {string}
     * @memberof DynamicHlsApiGetHlsVideoSegment
     */
    readonly videoCodec?: string

    /**
     * Optional. Specify a subtitle codec to encode to.
     * @type {string}
     * @memberof DynamicHlsApiGetHlsVideoSegment
     */
    readonly subtitleCodec?: string

    /**
     * Optional. The transcoding reason.
     * @type {string}
     * @memberof DynamicHlsApiGetHlsVideoSegment
     */
    readonly transcodingReasons?: string

    /**
     * Optional. The index of the audio stream to use. If omitted the first audio stream will be used.
     * @type {number}
     * @memberof DynamicHlsApiGetHlsVideoSegment
     */
    readonly audioStreamIndex?: number

    /**
     * Optional. The index of the video stream to use. If omitted the first video stream will be used.
     * @type {number}
     * @memberof DynamicHlsApiGetHlsVideoSegment
     */
    readonly videoStreamIndex?: number

    /**
     * Optional. The MediaBrowser.Model.Dlna.EncodingContext.
     * @type {EncodingContext}
     * @memberof DynamicHlsApiGetHlsVideoSegment
     */
    readonly context?: EncodingContext

    /**
     * Optional. The streaming options.
     * @type {{ [key: string]: string; }}
     * @memberof DynamicHlsApiGetHlsVideoSegment
     */
    readonly streamOptions?: { [key: string]: string; }
}

/**
 * Request parameters for getMasterHlsAudioPlaylist operation in DynamicHlsApi.
 * @export
 * @interface DynamicHlsApiGetMasterHlsAudioPlaylistRequest
 */
export interface DynamicHlsApiGetMasterHlsAudioPlaylistRequest {
    /**
     * The item id.
     * @type {string}
     * @memberof DynamicHlsApiGetMasterHlsAudioPlaylist
     */
    readonly itemId: string

    /**
     * The media version id, if playing an alternate version.
     * @type {string}
     * @memberof DynamicHlsApiGetMasterHlsAudioPlaylist
     */
    readonly mediaSourceId: string

    /**
     * Optional. If true, the original file will be streamed statically without any encoding. Use either no url extension or the original file extension. true/false.
     * @type {boolean}
     * @memberof DynamicHlsApiGetMasterHlsAudioPlaylist
     */
    readonly _static?: boolean

    /**
     * The streaming parameters.
     * @type {string}
     * @memberof DynamicHlsApiGetMasterHlsAudioPlaylist
     */
    readonly params?: string

    /**
     * The tag.
     * @type {string}
     * @memberof DynamicHlsApiGetMasterHlsAudioPlaylist
     */
    readonly tag?: string

    /**
     * Optional. The dlna device profile id to utilize.
     * @type {string}
     * @memberof DynamicHlsApiGetMasterHlsAudioPlaylist
     */
    readonly deviceProfileId?: string

    /**
     * The play session id.
     * @type {string}
     * @memberof DynamicHlsApiGetMasterHlsAudioPlaylist
     */
    readonly playSessionId?: string

    /**
     * The segment container.
     * @type {string}
     * @memberof DynamicHlsApiGetMasterHlsAudioPlaylist
     */
    readonly segmentContainer?: string

    /**
     * The segment lenght.
     * @type {number}
     * @memberof DynamicHlsApiGetMasterHlsAudioPlaylist
     */
    readonly segmentLength?: number

    /**
     * The minimum number of segments.
     * @type {number}
     * @memberof DynamicHlsApiGetMasterHlsAudioPlaylist
     */
    readonly minSegments?: number

    /**
     * The device id of the client requesting. Used to stop encoding processes when needed.
     * @type {string}
     * @memberof DynamicHlsApiGetMasterHlsAudioPlaylist
     */
    readonly deviceId?: string

    /**
     * Optional. Specify a audio codec to encode to, e.g. mp3. If omitted the server will auto-select using the url\&#39;s extension. Options: aac, mp3, vorbis, wma.
     * @type {string}
     * @memberof DynamicHlsApiGetMasterHlsAudioPlaylist
     */
    readonly audioCodec?: string

    /**
     * Whether or not to allow automatic stream copy if requested values match the original source. Defaults to true.
     * @type {boolean}
     * @memberof DynamicHlsApiGetMasterHlsAudioPlaylist
     */
    readonly enableAutoStreamCopy?: boolean

    /**
     * Whether or not to allow copying of the video stream url.
     * @type {boolean}
     * @memberof DynamicHlsApiGetMasterHlsAudioPlaylist
     */
    readonly allowVideoStreamCopy?: boolean

    /**
     * Whether or not to allow copying of the audio stream url.
     * @type {boolean}
     * @memberof DynamicHlsApiGetMasterHlsAudioPlaylist
     */
    readonly allowAudioStreamCopy?: boolean

    /**
     * Optional. Whether to break on non key frames.
     * @type {boolean}
     * @memberof DynamicHlsApiGetMasterHlsAudioPlaylist
     */
    readonly breakOnNonKeyFrames?: boolean

    /**
     * Optional. Specify a specific audio sample rate, e.g. 44100.
     * @type {number}
     * @memberof DynamicHlsApiGetMasterHlsAudioPlaylist
     */
    readonly audioSampleRate?: number

    /**
     * Optional. The maximum audio bit depth.
     * @type {number}
     * @memberof DynamicHlsApiGetMasterHlsAudioPlaylist
     */
    readonly maxAudioBitDepth?: number

    /**
     * Optional. Specify an audio bitrate to encode to, e.g. 128000. If omitted this will be left to encoder defaults.
     * @type {number}
     * @memberof DynamicHlsApiGetMasterHlsAudioPlaylist
     */
    readonly audioBitRate?: number

    /**
     * Optional. Specify a specific number of audio channels to encode to, e.g. 2.
     * @type {number}
     * @memberof DynamicHlsApiGetMasterHlsAudioPlaylist
     */
    readonly audioChannels?: number

    /**
     * Optional. Specify a maximum number of audio channels to encode to, e.g. 2.
     * @type {number}
     * @memberof DynamicHlsApiGetMasterHlsAudioPlaylist
     */
    readonly maxAudioChannels?: number

    /**
     * Optional. Specify a specific an encoder profile (varies by encoder), e.g. main, baseline, high.
     * @type {string}
     * @memberof DynamicHlsApiGetMasterHlsAudioPlaylist
     */
    readonly profile?: string

    /**
     * Optional. Specify a level for the encoder profile (varies by encoder), e.g. 3, 3.1.
     * @type {string}
     * @memberof DynamicHlsApiGetMasterHlsAudioPlaylist
     */
    readonly level?: string

    /**
     * Optional. A specific video framerate to encode to, e.g. 23.976. Generally this should be omitted unless the device has specific requirements.
     * @type {number}
     * @memberof DynamicHlsApiGetMasterHlsAudioPlaylist
     */
    readonly framerate?: number

    /**
     * Optional. A specific maximum video framerate to encode to, e.g. 23.976. Generally this should be omitted unless the device has specific requirements.
     * @type {number}
     * @memberof DynamicHlsApiGetMasterHlsAudioPlaylist
     */
    readonly maxFramerate?: number

    /**
     * Whether or not to copy timestamps when transcoding with an offset. Defaults to false.
     * @type {boolean}
     * @memberof DynamicHlsApiGetMasterHlsAudioPlaylist
     */
    readonly copyTimestamps?: boolean

    /**
     * Optional. Specify a starting offset, in ticks. 1 tick &#x3D; 10000 ms.
     * @type {number}
     * @memberof DynamicHlsApiGetMasterHlsAudioPlaylist
     */
    readonly startTimeTicks?: number

    /**
     * Optional. The fixed horizontal resolution of the encoded video.
     * @type {number}
     * @memberof DynamicHlsApiGetMasterHlsAudioPlaylist
     */
    readonly width?: number

    /**
     * Optional. The fixed vertical resolution of the encoded video.
     * @type {number}
     * @memberof DynamicHlsApiGetMasterHlsAudioPlaylist
     */
    readonly height?: number

    /**
     * Optional. Specify a video bitrate to encode to, e.g. 500000. If omitted this will be left to encoder defaults.
     * @type {number}
     * @memberof DynamicHlsApiGetMasterHlsAudioPlaylist
     */
    readonly videoBitRate?: number

    /**
     * Optional. The index of the subtitle stream to use. If omitted no subtitles will be used.
     * @type {number}
     * @memberof DynamicHlsApiGetMasterHlsAudioPlaylist
     */
    readonly subtitleStreamIndex?: number

    /**
     * Optional. Specify the subtitle delivery method.
     * @type {SubtitleDeliveryMethod}
     * @memberof DynamicHlsApiGetMasterHlsAudioPlaylist
     */
    readonly subtitleMethod?: SubtitleDeliveryMethod

    /**
     * Optional.
     * @type {number}
     * @memberof DynamicHlsApiGetMasterHlsAudioPlaylist
     */
    readonly maxRefFrames?: number

    /**
     * Optional. The maximum video bit depth.
     * @type {number}
     * @memberof DynamicHlsApiGetMasterHlsAudioPlaylist
     */
    readonly maxVideoBitDepth?: number

    /**
     * Optional. Whether to require avc.
     * @type {boolean}
     * @memberof DynamicHlsApiGetMasterHlsAudioPlaylist
     */
    readonly requireAvc?: boolean

    /**
     * Optional. Whether to deinterlace the video.
     * @type {boolean}
     * @memberof DynamicHlsApiGetMasterHlsAudioPlaylist
     */
    readonly deInterlace?: boolean

    /**
     * Optional. Whether to require a non anamporphic stream.
     * @type {boolean}
     * @memberof DynamicHlsApiGetMasterHlsAudioPlaylist
     */
    readonly requireNonAnamorphic?: boolean

    /**
     * Optional. The maximum number of audio channels to transcode.
     * @type {number}
     * @memberof DynamicHlsApiGetMasterHlsAudioPlaylist
     */
    readonly transcodingMaxAudioChannels?: number

    /**
     * Optional. The limit of how many cpu cores to use.
     * @type {number}
     * @memberof DynamicHlsApiGetMasterHlsAudioPlaylist
     */
    readonly cpuCoreLimit?: number

    /**
     * The live stream id.
     * @type {string}
     * @memberof DynamicHlsApiGetMasterHlsAudioPlaylist
     */
    readonly liveStreamId?: string

    /**
     * Optional. Whether to enable the MpegtsM2Ts mode.
     * @type {boolean}
     * @memberof DynamicHlsApiGetMasterHlsAudioPlaylist
     */
    readonly enableMpegtsM2TsMode?: boolean

    /**
     * Optional. Specify a video codec to encode to, e.g. h264. If omitted the server will auto-select using the url\&#39;s extension. Options: h265, h264, mpeg4, theora, vpx, wmv.
     * @type {string}
     * @memberof DynamicHlsApiGetMasterHlsAudioPlaylist
     */
    readonly videoCodec?: string

    /**
     * Optional. Specify a subtitle codec to encode to.
     * @type {string}
     * @memberof DynamicHlsApiGetMasterHlsAudioPlaylist
     */
    readonly subtitleCodec?: string

    /**
     * Optional. The transcoding reason.
     * @type {string}
     * @memberof DynamicHlsApiGetMasterHlsAudioPlaylist
     */
    readonly transcodingReasons?: string

    /**
     * Optional. The index of the audio stream to use. If omitted the first audio stream will be used.
     * @type {number}
     * @memberof DynamicHlsApiGetMasterHlsAudioPlaylist
     */
    readonly audioStreamIndex?: number

    /**
     * Optional. The index of the video stream to use. If omitted the first video stream will be used.
     * @type {number}
     * @memberof DynamicHlsApiGetMasterHlsAudioPlaylist
     */
    readonly videoStreamIndex?: number

    /**
     * Optional. The MediaBrowser.Model.Dlna.EncodingContext.
     * @type {EncodingContext}
     * @memberof DynamicHlsApiGetMasterHlsAudioPlaylist
     */
    readonly context?: EncodingContext

    /**
     * Optional. The streaming options.
     * @type {{ [key: string]: string; }}
     * @memberof DynamicHlsApiGetMasterHlsAudioPlaylist
     */
    readonly streamOptions?: { [key: string]: string; }

    /**
     * Enable adaptive bitrate streaming.
     * @type {boolean}
     * @memberof DynamicHlsApiGetMasterHlsAudioPlaylist
     */
    readonly enableAdaptiveBitrateStreaming?: boolean
}

/**
 * Request parameters for getMasterHlsVideoPlaylist operation in DynamicHlsApi.
 * @export
 * @interface DynamicHlsApiGetMasterHlsVideoPlaylistRequest
 */
export interface DynamicHlsApiGetMasterHlsVideoPlaylistRequest {
    /**
     * The item id.
     * @type {string}
     * @memberof DynamicHlsApiGetMasterHlsVideoPlaylist
     */
    readonly itemId: string

    /**
     * The media version id, if playing an alternate version.
     * @type {string}
     * @memberof DynamicHlsApiGetMasterHlsVideoPlaylist
     */
    readonly mediaSourceId: string

    /**
     * Optional. If true, the original file will be streamed statically without any encoding. Use either no url extension or the original file extension. true/false.
     * @type {boolean}
     * @memberof DynamicHlsApiGetMasterHlsVideoPlaylist
     */
    readonly _static?: boolean

    /**
     * The streaming parameters.
     * @type {string}
     * @memberof DynamicHlsApiGetMasterHlsVideoPlaylist
     */
    readonly params?: string

    /**
     * The tag.
     * @type {string}
     * @memberof DynamicHlsApiGetMasterHlsVideoPlaylist
     */
    readonly tag?: string

    /**
     * Optional. The dlna device profile id to utilize.
     * @type {string}
     * @memberof DynamicHlsApiGetMasterHlsVideoPlaylist
     */
    readonly deviceProfileId?: string

    /**
     * The play session id.
     * @type {string}
     * @memberof DynamicHlsApiGetMasterHlsVideoPlaylist
     */
    readonly playSessionId?: string

    /**
     * The segment container.
     * @type {string}
     * @memberof DynamicHlsApiGetMasterHlsVideoPlaylist
     */
    readonly segmentContainer?: string

    /**
     * The segment lenght.
     * @type {number}
     * @memberof DynamicHlsApiGetMasterHlsVideoPlaylist
     */
    readonly segmentLength?: number

    /**
     * The minimum number of segments.
     * @type {number}
     * @memberof DynamicHlsApiGetMasterHlsVideoPlaylist
     */
    readonly minSegments?: number

    /**
     * The device id of the client requesting. Used to stop encoding processes when needed.
     * @type {string}
     * @memberof DynamicHlsApiGetMasterHlsVideoPlaylist
     */
    readonly deviceId?: string

    /**
     * Optional. Specify a audio codec to encode to, e.g. mp3. If omitted the server will auto-select using the url\&#39;s extension. Options: aac, mp3, vorbis, wma.
     * @type {string}
     * @memberof DynamicHlsApiGetMasterHlsVideoPlaylist
     */
    readonly audioCodec?: string

    /**
     * Whether or not to allow automatic stream copy if requested values match the original source. Defaults to true.
     * @type {boolean}
     * @memberof DynamicHlsApiGetMasterHlsVideoPlaylist
     */
    readonly enableAutoStreamCopy?: boolean

    /**
     * Whether or not to allow copying of the video stream url.
     * @type {boolean}
     * @memberof DynamicHlsApiGetMasterHlsVideoPlaylist
     */
    readonly allowVideoStreamCopy?: boolean

    /**
     * Whether or not to allow copying of the audio stream url.
     * @type {boolean}
     * @memberof DynamicHlsApiGetMasterHlsVideoPlaylist
     */
    readonly allowAudioStreamCopy?: boolean

    /**
     * Optional. Whether to break on non key frames.
     * @type {boolean}
     * @memberof DynamicHlsApiGetMasterHlsVideoPlaylist
     */
    readonly breakOnNonKeyFrames?: boolean

    /**
     * Optional. Specify a specific audio sample rate, e.g. 44100.
     * @type {number}
     * @memberof DynamicHlsApiGetMasterHlsVideoPlaylist
     */
    readonly audioSampleRate?: number

    /**
     * Optional. The maximum audio bit depth.
     * @type {number}
     * @memberof DynamicHlsApiGetMasterHlsVideoPlaylist
     */
    readonly maxAudioBitDepth?: number

    /**
     * Optional. Specify an audio bitrate to encode to, e.g. 128000. If omitted this will be left to encoder defaults.
     * @type {number}
     * @memberof DynamicHlsApiGetMasterHlsVideoPlaylist
     */
    readonly audioBitRate?: number

    /**
     * Optional. Specify a specific number of audio channels to encode to, e.g. 2.
     * @type {number}
     * @memberof DynamicHlsApiGetMasterHlsVideoPlaylist
     */
    readonly audioChannels?: number

    /**
     * Optional. Specify a maximum number of audio channels to encode to, e.g. 2.
     * @type {number}
     * @memberof DynamicHlsApiGetMasterHlsVideoPlaylist
     */
    readonly maxAudioChannels?: number

    /**
     * Optional. Specify a specific an encoder profile (varies by encoder), e.g. main, baseline, high.
     * @type {string}
     * @memberof DynamicHlsApiGetMasterHlsVideoPlaylist
     */
    readonly profile?: string

    /**
     * Optional. Specify a level for the encoder profile (varies by encoder), e.g. 3, 3.1.
     * @type {string}
     * @memberof DynamicHlsApiGetMasterHlsVideoPlaylist
     */
    readonly level?: string

    /**
     * Optional. A specific video framerate to encode to, e.g. 23.976. Generally this should be omitted unless the device has specific requirements.
     * @type {number}
     * @memberof DynamicHlsApiGetMasterHlsVideoPlaylist
     */
    readonly framerate?: number

    /**
     * Optional. A specific maximum video framerate to encode to, e.g. 23.976. Generally this should be omitted unless the device has specific requirements.
     * @type {number}
     * @memberof DynamicHlsApiGetMasterHlsVideoPlaylist
     */
    readonly maxFramerate?: number

    /**
     * Whether or not to copy timestamps when transcoding with an offset. Defaults to false.
     * @type {boolean}
     * @memberof DynamicHlsApiGetMasterHlsVideoPlaylist
     */
    readonly copyTimestamps?: boolean

    /**
     * Optional. Specify a starting offset, in ticks. 1 tick &#x3D; 10000 ms.
     * @type {number}
     * @memberof DynamicHlsApiGetMasterHlsVideoPlaylist
     */
    readonly startTimeTicks?: number

    /**
     * Optional. The fixed horizontal resolution of the encoded video.
     * @type {number}
     * @memberof DynamicHlsApiGetMasterHlsVideoPlaylist
     */
    readonly width?: number

    /**
     * Optional. The fixed vertical resolution of the encoded video.
     * @type {number}
     * @memberof DynamicHlsApiGetMasterHlsVideoPlaylist
     */
    readonly height?: number

    /**
     * Optional. Specify a video bitrate to encode to, e.g. 500000. If omitted this will be left to encoder defaults.
     * @type {number}
     * @memberof DynamicHlsApiGetMasterHlsVideoPlaylist
     */
    readonly videoBitRate?: number

    /**
     * Optional. The index of the subtitle stream to use. If omitted no subtitles will be used.
     * @type {number}
     * @memberof DynamicHlsApiGetMasterHlsVideoPlaylist
     */
    readonly subtitleStreamIndex?: number

    /**
     * Optional. Specify the subtitle delivery method.
     * @type {SubtitleDeliveryMethod}
     * @memberof DynamicHlsApiGetMasterHlsVideoPlaylist
     */
    readonly subtitleMethod?: SubtitleDeliveryMethod

    /**
     * Optional.
     * @type {number}
     * @memberof DynamicHlsApiGetMasterHlsVideoPlaylist
     */
    readonly maxRefFrames?: number

    /**
     * Optional. The maximum video bit depth.
     * @type {number}
     * @memberof DynamicHlsApiGetMasterHlsVideoPlaylist
     */
    readonly maxVideoBitDepth?: number

    /**
     * Optional. Whether to require avc.
     * @type {boolean}
     * @memberof DynamicHlsApiGetMasterHlsVideoPlaylist
     */
    readonly requireAvc?: boolean

    /**
     * Optional. Whether to deinterlace the video.
     * @type {boolean}
     * @memberof DynamicHlsApiGetMasterHlsVideoPlaylist
     */
    readonly deInterlace?: boolean

    /**
     * Optional. Whether to require a non anamporphic stream.
     * @type {boolean}
     * @memberof DynamicHlsApiGetMasterHlsVideoPlaylist
     */
    readonly requireNonAnamorphic?: boolean

    /**
     * Optional. The maximum number of audio channels to transcode.
     * @type {number}
     * @memberof DynamicHlsApiGetMasterHlsVideoPlaylist
     */
    readonly transcodingMaxAudioChannels?: number

    /**
     * Optional. The limit of how many cpu cores to use.
     * @type {number}
     * @memberof DynamicHlsApiGetMasterHlsVideoPlaylist
     */
    readonly cpuCoreLimit?: number

    /**
     * The live stream id.
     * @type {string}
     * @memberof DynamicHlsApiGetMasterHlsVideoPlaylist
     */
    readonly liveStreamId?: string

    /**
     * Optional. Whether to enable the MpegtsM2Ts mode.
     * @type {boolean}
     * @memberof DynamicHlsApiGetMasterHlsVideoPlaylist
     */
    readonly enableMpegtsM2TsMode?: boolean

    /**
     * Optional. Specify a video codec to encode to, e.g. h264. If omitted the server will auto-select using the url\&#39;s extension. Options: h265, h264, mpeg4, theora, vpx, wmv.
     * @type {string}
     * @memberof DynamicHlsApiGetMasterHlsVideoPlaylist
     */
    readonly videoCodec?: string

    /**
     * Optional. Specify a subtitle codec to encode to.
     * @type {string}
     * @memberof DynamicHlsApiGetMasterHlsVideoPlaylist
     */
    readonly subtitleCodec?: string

    /**
     * Optional. The transcoding reason.
     * @type {string}
     * @memberof DynamicHlsApiGetMasterHlsVideoPlaylist
     */
    readonly transcodingReasons?: string

    /**
     * Optional. The index of the audio stream to use. If omitted the first audio stream will be used.
     * @type {number}
     * @memberof DynamicHlsApiGetMasterHlsVideoPlaylist
     */
    readonly audioStreamIndex?: number

    /**
     * Optional. The index of the video stream to use. If omitted the first video stream will be used.
     * @type {number}
     * @memberof DynamicHlsApiGetMasterHlsVideoPlaylist
     */
    readonly videoStreamIndex?: number

    /**
     * Optional. The MediaBrowser.Model.Dlna.EncodingContext.
     * @type {EncodingContext}
     * @memberof DynamicHlsApiGetMasterHlsVideoPlaylist
     */
    readonly context?: EncodingContext

    /**
     * Optional. The streaming options.
     * @type {{ [key: string]: string; }}
     * @memberof DynamicHlsApiGetMasterHlsVideoPlaylist
     */
    readonly streamOptions?: { [key: string]: string; }

    /**
     * Enable adaptive bitrate streaming.
     * @type {boolean}
     * @memberof DynamicHlsApiGetMasterHlsVideoPlaylist
     */
    readonly enableAdaptiveBitrateStreaming?: boolean
}

/**
 * Request parameters for getVariantHlsAudioPlaylist operation in DynamicHlsApi.
 * @export
 * @interface DynamicHlsApiGetVariantHlsAudioPlaylistRequest
 */
export interface DynamicHlsApiGetVariantHlsAudioPlaylistRequest {
    /**
     * The item id.
     * @type {string}
     * @memberof DynamicHlsApiGetVariantHlsAudioPlaylist
     */
    readonly itemId: string

    /**
     * Optional. If true, the original file will be streamed statically without any encoding. Use either no url extension or the original file extension. true/false.
     * @type {boolean}
     * @memberof DynamicHlsApiGetVariantHlsAudioPlaylist
     */
    readonly _static?: boolean

    /**
     * The streaming parameters.
     * @type {string}
     * @memberof DynamicHlsApiGetVariantHlsAudioPlaylist
     */
    readonly params?: string

    /**
     * The tag.
     * @type {string}
     * @memberof DynamicHlsApiGetVariantHlsAudioPlaylist
     */
    readonly tag?: string

    /**
     * Optional. The dlna device profile id to utilize.
     * @type {string}
     * @memberof DynamicHlsApiGetVariantHlsAudioPlaylist
     */
    readonly deviceProfileId?: string

    /**
     * The play session id.
     * @type {string}
     * @memberof DynamicHlsApiGetVariantHlsAudioPlaylist
     */
    readonly playSessionId?: string

    /**
     * The segment container.
     * @type {string}
     * @memberof DynamicHlsApiGetVariantHlsAudioPlaylist
     */
    readonly segmentContainer?: string

    /**
     * The segment lenght.
     * @type {number}
     * @memberof DynamicHlsApiGetVariantHlsAudioPlaylist
     */
    readonly segmentLength?: number

    /**
     * The minimum number of segments.
     * @type {number}
     * @memberof DynamicHlsApiGetVariantHlsAudioPlaylist
     */
    readonly minSegments?: number

    /**
     * The media version id, if playing an alternate version.
     * @type {string}
     * @memberof DynamicHlsApiGetVariantHlsAudioPlaylist
     */
    readonly mediaSourceId?: string

    /**
     * The device id of the client requesting. Used to stop encoding processes when needed.
     * @type {string}
     * @memberof DynamicHlsApiGetVariantHlsAudioPlaylist
     */
    readonly deviceId?: string

    /**
     * Optional. Specify a audio codec to encode to, e.g. mp3. If omitted the server will auto-select using the url\&#39;s extension. Options: aac, mp3, vorbis, wma.
     * @type {string}
     * @memberof DynamicHlsApiGetVariantHlsAudioPlaylist
     */
    readonly audioCodec?: string

    /**
     * Whether or not to allow automatic stream copy if requested values match the original source. Defaults to true.
     * @type {boolean}
     * @memberof DynamicHlsApiGetVariantHlsAudioPlaylist
     */
    readonly enableAutoStreamCopy?: boolean

    /**
     * Whether or not to allow copying of the video stream url.
     * @type {boolean}
     * @memberof DynamicHlsApiGetVariantHlsAudioPlaylist
     */
    readonly allowVideoStreamCopy?: boolean

    /**
     * Whether or not to allow copying of the audio stream url.
     * @type {boolean}
     * @memberof DynamicHlsApiGetVariantHlsAudioPlaylist
     */
    readonly allowAudioStreamCopy?: boolean

    /**
     * Optional. Whether to break on non key frames.
     * @type {boolean}
     * @memberof DynamicHlsApiGetVariantHlsAudioPlaylist
     */
    readonly breakOnNonKeyFrames?: boolean

    /**
     * Optional. Specify a specific audio sample rate, e.g. 44100.
     * @type {number}
     * @memberof DynamicHlsApiGetVariantHlsAudioPlaylist
     */
    readonly audioSampleRate?: number

    /**
     * Optional. The maximum audio bit depth.
     * @type {number}
     * @memberof DynamicHlsApiGetVariantHlsAudioPlaylist
     */
    readonly maxAudioBitDepth?: number

    /**
     * Optional. Specify an audio bitrate to encode to, e.g. 128000. If omitted this will be left to encoder defaults.
     * @type {number}
     * @memberof DynamicHlsApiGetVariantHlsAudioPlaylist
     */
    readonly audioBitRate?: number

    /**
     * Optional. Specify a specific number of audio channels to encode to, e.g. 2.
     * @type {number}
     * @memberof DynamicHlsApiGetVariantHlsAudioPlaylist
     */
    readonly audioChannels?: number

    /**
     * Optional. Specify a maximum number of audio channels to encode to, e.g. 2.
     * @type {number}
     * @memberof DynamicHlsApiGetVariantHlsAudioPlaylist
     */
    readonly maxAudioChannels?: number

    /**
     * Optional. Specify a specific an encoder profile (varies by encoder), e.g. main, baseline, high.
     * @type {string}
     * @memberof DynamicHlsApiGetVariantHlsAudioPlaylist
     */
    readonly profile?: string

    /**
     * Optional. Specify a level for the encoder profile (varies by encoder), e.g. 3, 3.1.
     * @type {string}
     * @memberof DynamicHlsApiGetVariantHlsAudioPlaylist
     */
    readonly level?: string

    /**
     * Optional. A specific video framerate to encode to, e.g. 23.976. Generally this should be omitted unless the device has specific requirements.
     * @type {number}
     * @memberof DynamicHlsApiGetVariantHlsAudioPlaylist
     */
    readonly framerate?: number

    /**
     * Optional. A specific maximum video framerate to encode to, e.g. 23.976. Generally this should be omitted unless the device has specific requirements.
     * @type {number}
     * @memberof DynamicHlsApiGetVariantHlsAudioPlaylist
     */
    readonly maxFramerate?: number

    /**
     * Whether or not to copy timestamps when transcoding with an offset. Defaults to false.
     * @type {boolean}
     * @memberof DynamicHlsApiGetVariantHlsAudioPlaylist
     */
    readonly copyTimestamps?: boolean

    /**
     * Optional. Specify a starting offset, in ticks. 1 tick &#x3D; 10000 ms.
     * @type {number}
     * @memberof DynamicHlsApiGetVariantHlsAudioPlaylist
     */
    readonly startTimeTicks?: number

    /**
     * Optional. The fixed horizontal resolution of the encoded video.
     * @type {number}
     * @memberof DynamicHlsApiGetVariantHlsAudioPlaylist
     */
    readonly width?: number

    /**
     * Optional. The fixed vertical resolution of the encoded video.
     * @type {number}
     * @memberof DynamicHlsApiGetVariantHlsAudioPlaylist
     */
    readonly height?: number

    /**
     * Optional. Specify a video bitrate to encode to, e.g. 500000. If omitted this will be left to encoder defaults.
     * @type {number}
     * @memberof DynamicHlsApiGetVariantHlsAudioPlaylist
     */
    readonly videoBitRate?: number

    /**
     * Optional. The index of the subtitle stream to use. If omitted no subtitles will be used.
     * @type {number}
     * @memberof DynamicHlsApiGetVariantHlsAudioPlaylist
     */
    readonly subtitleStreamIndex?: number

    /**
     * Optional. Specify the subtitle delivery method.
     * @type {SubtitleDeliveryMethod}
     * @memberof DynamicHlsApiGetVariantHlsAudioPlaylist
     */
    readonly subtitleMethod?: SubtitleDeliveryMethod

    /**
     * Optional.
     * @type {number}
     * @memberof DynamicHlsApiGetVariantHlsAudioPlaylist
     */
    readonly maxRefFrames?: number

    /**
     * Optional. The maximum video bit depth.
     * @type {number}
     * @memberof DynamicHlsApiGetVariantHlsAudioPlaylist
     */
    readonly maxVideoBitDepth?: number

    /**
     * Optional. Whether to require avc.
     * @type {boolean}
     * @memberof DynamicHlsApiGetVariantHlsAudioPlaylist
     */
    readonly requireAvc?: boolean

    /**
     * Optional. Whether to deinterlace the video.
     * @type {boolean}
     * @memberof DynamicHlsApiGetVariantHlsAudioPlaylist
     */
    readonly deInterlace?: boolean

    /**
     * Optional. Whether to require a non anamporphic stream.
     * @type {boolean}
     * @memberof DynamicHlsApiGetVariantHlsAudioPlaylist
     */
    readonly requireNonAnamorphic?: boolean

    /**
     * Optional. The maximum number of audio channels to transcode.
     * @type {number}
     * @memberof DynamicHlsApiGetVariantHlsAudioPlaylist
     */
    readonly transcodingMaxAudioChannels?: number

    /**
     * Optional. The limit of how many cpu cores to use.
     * @type {number}
     * @memberof DynamicHlsApiGetVariantHlsAudioPlaylist
     */
    readonly cpuCoreLimit?: number

    /**
     * The live stream id.
     * @type {string}
     * @memberof DynamicHlsApiGetVariantHlsAudioPlaylist
     */
    readonly liveStreamId?: string

    /**
     * Optional. Whether to enable the MpegtsM2Ts mode.
     * @type {boolean}
     * @memberof DynamicHlsApiGetVariantHlsAudioPlaylist
     */
    readonly enableMpegtsM2TsMode?: boolean

    /**
     * Optional. Specify a video codec to encode to, e.g. h264. If omitted the server will auto-select using the url\&#39;s extension. Options: h265, h264, mpeg4, theora, vpx, wmv.
     * @type {string}
     * @memberof DynamicHlsApiGetVariantHlsAudioPlaylist
     */
    readonly videoCodec?: string

    /**
     * Optional. Specify a subtitle codec to encode to.
     * @type {string}
     * @memberof DynamicHlsApiGetVariantHlsAudioPlaylist
     */
    readonly subtitleCodec?: string

    /**
     * Optional. The transcoding reason.
     * @type {string}
     * @memberof DynamicHlsApiGetVariantHlsAudioPlaylist
     */
    readonly transcodingReasons?: string

    /**
     * Optional. The index of the audio stream to use. If omitted the first audio stream will be used.
     * @type {number}
     * @memberof DynamicHlsApiGetVariantHlsAudioPlaylist
     */
    readonly audioStreamIndex?: number

    /**
     * Optional. The index of the video stream to use. If omitted the first video stream will be used.
     * @type {number}
     * @memberof DynamicHlsApiGetVariantHlsAudioPlaylist
     */
    readonly videoStreamIndex?: number

    /**
     * Optional. The MediaBrowser.Model.Dlna.EncodingContext.
     * @type {EncodingContext}
     * @memberof DynamicHlsApiGetVariantHlsAudioPlaylist
     */
    readonly context?: EncodingContext

    /**
     * Optional. The streaming options.
     * @type {{ [key: string]: string; }}
     * @memberof DynamicHlsApiGetVariantHlsAudioPlaylist
     */
    readonly streamOptions?: { [key: string]: string; }
}

/**
 * Request parameters for getVariantHlsVideoPlaylist operation in DynamicHlsApi.
 * @export
 * @interface DynamicHlsApiGetVariantHlsVideoPlaylistRequest
 */
export interface DynamicHlsApiGetVariantHlsVideoPlaylistRequest {
    /**
     * The item id.
     * @type {string}
     * @memberof DynamicHlsApiGetVariantHlsVideoPlaylist
     */
    readonly itemId: string

    /**
     * Optional. If true, the original file will be streamed statically without any encoding. Use either no url extension or the original file extension. true/false.
     * @type {boolean}
     * @memberof DynamicHlsApiGetVariantHlsVideoPlaylist
     */
    readonly _static?: boolean

    /**
     * The streaming parameters.
     * @type {string}
     * @memberof DynamicHlsApiGetVariantHlsVideoPlaylist
     */
    readonly params?: string

    /**
     * The tag.
     * @type {string}
     * @memberof DynamicHlsApiGetVariantHlsVideoPlaylist
     */
    readonly tag?: string

    /**
     * Optional. The dlna device profile id to utilize.
     * @type {string}
     * @memberof DynamicHlsApiGetVariantHlsVideoPlaylist
     */
    readonly deviceProfileId?: string

    /**
     * The play session id.
     * @type {string}
     * @memberof DynamicHlsApiGetVariantHlsVideoPlaylist
     */
    readonly playSessionId?: string

    /**
     * The segment container.
     * @type {string}
     * @memberof DynamicHlsApiGetVariantHlsVideoPlaylist
     */
    readonly segmentContainer?: string

    /**
     * The segment lenght.
     * @type {number}
     * @memberof DynamicHlsApiGetVariantHlsVideoPlaylist
     */
    readonly segmentLength?: number

    /**
     * The minimum number of segments.
     * @type {number}
     * @memberof DynamicHlsApiGetVariantHlsVideoPlaylist
     */
    readonly minSegments?: number

    /**
     * The media version id, if playing an alternate version.
     * @type {string}
     * @memberof DynamicHlsApiGetVariantHlsVideoPlaylist
     */
    readonly mediaSourceId?: string

    /**
     * The device id of the client requesting. Used to stop encoding processes when needed.
     * @type {string}
     * @memberof DynamicHlsApiGetVariantHlsVideoPlaylist
     */
    readonly deviceId?: string

    /**
     * Optional. Specify a audio codec to encode to, e.g. mp3. If omitted the server will auto-select using the url\&#39;s extension. Options: aac, mp3, vorbis, wma.
     * @type {string}
     * @memberof DynamicHlsApiGetVariantHlsVideoPlaylist
     */
    readonly audioCodec?: string

    /**
     * Whether or not to allow automatic stream copy if requested values match the original source. Defaults to true.
     * @type {boolean}
     * @memberof DynamicHlsApiGetVariantHlsVideoPlaylist
     */
    readonly enableAutoStreamCopy?: boolean

    /**
     * Whether or not to allow copying of the video stream url.
     * @type {boolean}
     * @memberof DynamicHlsApiGetVariantHlsVideoPlaylist
     */
    readonly allowVideoStreamCopy?: boolean

    /**
     * Whether or not to allow copying of the audio stream url.
     * @type {boolean}
     * @memberof DynamicHlsApiGetVariantHlsVideoPlaylist
     */
    readonly allowAudioStreamCopy?: boolean

    /**
     * Optional. Whether to break on non key frames.
     * @type {boolean}
     * @memberof DynamicHlsApiGetVariantHlsVideoPlaylist
     */
    readonly breakOnNonKeyFrames?: boolean

    /**
     * Optional. Specify a specific audio sample rate, e.g. 44100.
     * @type {number}
     * @memberof DynamicHlsApiGetVariantHlsVideoPlaylist
     */
    readonly audioSampleRate?: number

    /**
     * Optional. The maximum audio bit depth.
     * @type {number}
     * @memberof DynamicHlsApiGetVariantHlsVideoPlaylist
     */
    readonly maxAudioBitDepth?: number

    /**
     * Optional. Specify an audio bitrate to encode to, e.g. 128000. If omitted this will be left to encoder defaults.
     * @type {number}
     * @memberof DynamicHlsApiGetVariantHlsVideoPlaylist
     */
    readonly audioBitRate?: number

    /**
     * Optional. Specify a specific number of audio channels to encode to, e.g. 2.
     * @type {number}
     * @memberof DynamicHlsApiGetVariantHlsVideoPlaylist
     */
    readonly audioChannels?: number

    /**
     * Optional. Specify a maximum number of audio channels to encode to, e.g. 2.
     * @type {number}
     * @memberof DynamicHlsApiGetVariantHlsVideoPlaylist
     */
    readonly maxAudioChannels?: number

    /**
     * Optional. Specify a specific an encoder profile (varies by encoder), e.g. main, baseline, high.
     * @type {string}
     * @memberof DynamicHlsApiGetVariantHlsVideoPlaylist
     */
    readonly profile?: string

    /**
     * Optional. Specify a level for the encoder profile (varies by encoder), e.g. 3, 3.1.
     * @type {string}
     * @memberof DynamicHlsApiGetVariantHlsVideoPlaylist
     */
    readonly level?: string

    /**
     * Optional. A specific video framerate to encode to, e.g. 23.976. Generally this should be omitted unless the device has specific requirements.
     * @type {number}
     * @memberof DynamicHlsApiGetVariantHlsVideoPlaylist
     */
    readonly framerate?: number

    /**
     * Optional. A specific maximum video framerate to encode to, e.g. 23.976. Generally this should be omitted unless the device has specific requirements.
     * @type {number}
     * @memberof DynamicHlsApiGetVariantHlsVideoPlaylist
     */
    readonly maxFramerate?: number

    /**
     * Whether or not to copy timestamps when transcoding with an offset. Defaults to false.
     * @type {boolean}
     * @memberof DynamicHlsApiGetVariantHlsVideoPlaylist
     */
    readonly copyTimestamps?: boolean

    /**
     * Optional. Specify a starting offset, in ticks. 1 tick &#x3D; 10000 ms.
     * @type {number}
     * @memberof DynamicHlsApiGetVariantHlsVideoPlaylist
     */
    readonly startTimeTicks?: number

    /**
     * Optional. The fixed horizontal resolution of the encoded video.
     * @type {number}
     * @memberof DynamicHlsApiGetVariantHlsVideoPlaylist
     */
    readonly width?: number

    /**
     * Optional. The fixed vertical resolution of the encoded video.
     * @type {number}
     * @memberof DynamicHlsApiGetVariantHlsVideoPlaylist
     */
    readonly height?: number

    /**
     * Optional. Specify a video bitrate to encode to, e.g. 500000. If omitted this will be left to encoder defaults.
     * @type {number}
     * @memberof DynamicHlsApiGetVariantHlsVideoPlaylist
     */
    readonly videoBitRate?: number

    /**
     * Optional. The index of the subtitle stream to use. If omitted no subtitles will be used.
     * @type {number}
     * @memberof DynamicHlsApiGetVariantHlsVideoPlaylist
     */
    readonly subtitleStreamIndex?: number

    /**
     * Optional. Specify the subtitle delivery method.
     * @type {SubtitleDeliveryMethod}
     * @memberof DynamicHlsApiGetVariantHlsVideoPlaylist
     */
    readonly subtitleMethod?: SubtitleDeliveryMethod

    /**
     * Optional.
     * @type {number}
     * @memberof DynamicHlsApiGetVariantHlsVideoPlaylist
     */
    readonly maxRefFrames?: number

    /**
     * Optional. The maximum video bit depth.
     * @type {number}
     * @memberof DynamicHlsApiGetVariantHlsVideoPlaylist
     */
    readonly maxVideoBitDepth?: number

    /**
     * Optional. Whether to require avc.
     * @type {boolean}
     * @memberof DynamicHlsApiGetVariantHlsVideoPlaylist
     */
    readonly requireAvc?: boolean

    /**
     * Optional. Whether to deinterlace the video.
     * @type {boolean}
     * @memberof DynamicHlsApiGetVariantHlsVideoPlaylist
     */
    readonly deInterlace?: boolean

    /**
     * Optional. Whether to require a non anamporphic stream.
     * @type {boolean}
     * @memberof DynamicHlsApiGetVariantHlsVideoPlaylist
     */
    readonly requireNonAnamorphic?: boolean

    /**
     * Optional. The maximum number of audio channels to transcode.
     * @type {number}
     * @memberof DynamicHlsApiGetVariantHlsVideoPlaylist
     */
    readonly transcodingMaxAudioChannels?: number

    /**
     * Optional. The limit of how many cpu cores to use.
     * @type {number}
     * @memberof DynamicHlsApiGetVariantHlsVideoPlaylist
     */
    readonly cpuCoreLimit?: number

    /**
     * The live stream id.
     * @type {string}
     * @memberof DynamicHlsApiGetVariantHlsVideoPlaylist
     */
    readonly liveStreamId?: string

    /**
     * Optional. Whether to enable the MpegtsM2Ts mode.
     * @type {boolean}
     * @memberof DynamicHlsApiGetVariantHlsVideoPlaylist
     */
    readonly enableMpegtsM2TsMode?: boolean

    /**
     * Optional. Specify a video codec to encode to, e.g. h264. If omitted the server will auto-select using the url\&#39;s extension. Options: h265, h264, mpeg4, theora, vpx, wmv.
     * @type {string}
     * @memberof DynamicHlsApiGetVariantHlsVideoPlaylist
     */
    readonly videoCodec?: string

    /**
     * Optional. Specify a subtitle codec to encode to.
     * @type {string}
     * @memberof DynamicHlsApiGetVariantHlsVideoPlaylist
     */
    readonly subtitleCodec?: string

    /**
     * Optional. The transcoding reason.
     * @type {string}
     * @memberof DynamicHlsApiGetVariantHlsVideoPlaylist
     */
    readonly transcodingReasons?: string

    /**
     * Optional. The index of the audio stream to use. If omitted the first audio stream will be used.
     * @type {number}
     * @memberof DynamicHlsApiGetVariantHlsVideoPlaylist
     */
    readonly audioStreamIndex?: number

    /**
     * Optional. The index of the video stream to use. If omitted the first video stream will be used.
     * @type {number}
     * @memberof DynamicHlsApiGetVariantHlsVideoPlaylist
     */
    readonly videoStreamIndex?: number

    /**
     * Optional. The MediaBrowser.Model.Dlna.EncodingContext.
     * @type {EncodingContext}
     * @memberof DynamicHlsApiGetVariantHlsVideoPlaylist
     */
    readonly context?: EncodingContext

    /**
     * Optional. The streaming options.
     * @type {{ [key: string]: string; }}
     * @memberof DynamicHlsApiGetVariantHlsVideoPlaylist
     */
    readonly streamOptions?: { [key: string]: string; }
}

/**
 * Request parameters for headMasterHlsAudioPlaylist operation in DynamicHlsApi.
 * @export
 * @interface DynamicHlsApiHeadMasterHlsAudioPlaylistRequest
 */
export interface DynamicHlsApiHeadMasterHlsAudioPlaylistRequest {
    /**
     * The item id.
     * @type {string}
     * @memberof DynamicHlsApiHeadMasterHlsAudioPlaylist
     */
    readonly itemId: string

    /**
     * The media version id, if playing an alternate version.
     * @type {string}
     * @memberof DynamicHlsApiHeadMasterHlsAudioPlaylist
     */
    readonly mediaSourceId: string

    /**
     * Optional. If true, the original file will be streamed statically without any encoding. Use either no url extension or the original file extension. true/false.
     * @type {boolean}
     * @memberof DynamicHlsApiHeadMasterHlsAudioPlaylist
     */
    readonly _static?: boolean

    /**
     * The streaming parameters.
     * @type {string}
     * @memberof DynamicHlsApiHeadMasterHlsAudioPlaylist
     */
    readonly params?: string

    /**
     * The tag.
     * @type {string}
     * @memberof DynamicHlsApiHeadMasterHlsAudioPlaylist
     */
    readonly tag?: string

    /**
     * Optional. The dlna device profile id to utilize.
     * @type {string}
     * @memberof DynamicHlsApiHeadMasterHlsAudioPlaylist
     */
    readonly deviceProfileId?: string

    /**
     * The play session id.
     * @type {string}
     * @memberof DynamicHlsApiHeadMasterHlsAudioPlaylist
     */
    readonly playSessionId?: string

    /**
     * The segment container.
     * @type {string}
     * @memberof DynamicHlsApiHeadMasterHlsAudioPlaylist
     */
    readonly segmentContainer?: string

    /**
     * The segment lenght.
     * @type {number}
     * @memberof DynamicHlsApiHeadMasterHlsAudioPlaylist
     */
    readonly segmentLength?: number

    /**
     * The minimum number of segments.
     * @type {number}
     * @memberof DynamicHlsApiHeadMasterHlsAudioPlaylist
     */
    readonly minSegments?: number

    /**
     * The device id of the client requesting. Used to stop encoding processes when needed.
     * @type {string}
     * @memberof DynamicHlsApiHeadMasterHlsAudioPlaylist
     */
    readonly deviceId?: string

    /**
     * Optional. Specify a audio codec to encode to, e.g. mp3. If omitted the server will auto-select using the url\&#39;s extension. Options: aac, mp3, vorbis, wma.
     * @type {string}
     * @memberof DynamicHlsApiHeadMasterHlsAudioPlaylist
     */
    readonly audioCodec?: string

    /**
     * Whether or not to allow automatic stream copy if requested values match the original source. Defaults to true.
     * @type {boolean}
     * @memberof DynamicHlsApiHeadMasterHlsAudioPlaylist
     */
    readonly enableAutoStreamCopy?: boolean

    /**
     * Whether or not to allow copying of the video stream url.
     * @type {boolean}
     * @memberof DynamicHlsApiHeadMasterHlsAudioPlaylist
     */
    readonly allowVideoStreamCopy?: boolean

    /**
     * Whether or not to allow copying of the audio stream url.
     * @type {boolean}
     * @memberof DynamicHlsApiHeadMasterHlsAudioPlaylist
     */
    readonly allowAudioStreamCopy?: boolean

    /**
     * Optional. Whether to break on non key frames.
     * @type {boolean}
     * @memberof DynamicHlsApiHeadMasterHlsAudioPlaylist
     */
    readonly breakOnNonKeyFrames?: boolean

    /**
     * Optional. Specify a specific audio sample rate, e.g. 44100.
     * @type {number}
     * @memberof DynamicHlsApiHeadMasterHlsAudioPlaylist
     */
    readonly audioSampleRate?: number

    /**
     * Optional. The maximum audio bit depth.
     * @type {number}
     * @memberof DynamicHlsApiHeadMasterHlsAudioPlaylist
     */
    readonly maxAudioBitDepth?: number

    /**
     * Optional. Specify an audio bitrate to encode to, e.g. 128000. If omitted this will be left to encoder defaults.
     * @type {number}
     * @memberof DynamicHlsApiHeadMasterHlsAudioPlaylist
     */
    readonly audioBitRate?: number

    /**
     * Optional. Specify a specific number of audio channels to encode to, e.g. 2.
     * @type {number}
     * @memberof DynamicHlsApiHeadMasterHlsAudioPlaylist
     */
    readonly audioChannels?: number

    /**
     * Optional. Specify a maximum number of audio channels to encode to, e.g. 2.
     * @type {number}
     * @memberof DynamicHlsApiHeadMasterHlsAudioPlaylist
     */
    readonly maxAudioChannels?: number

    /**
     * Optional. Specify a specific an encoder profile (varies by encoder), e.g. main, baseline, high.
     * @type {string}
     * @memberof DynamicHlsApiHeadMasterHlsAudioPlaylist
     */
    readonly profile?: string

    /**
     * Optional. Specify a level for the encoder profile (varies by encoder), e.g. 3, 3.1.
     * @type {string}
     * @memberof DynamicHlsApiHeadMasterHlsAudioPlaylist
     */
    readonly level?: string

    /**
     * Optional. A specific video framerate to encode to, e.g. 23.976. Generally this should be omitted unless the device has specific requirements.
     * @type {number}
     * @memberof DynamicHlsApiHeadMasterHlsAudioPlaylist
     */
    readonly framerate?: number

    /**
     * Optional. A specific maximum video framerate to encode to, e.g. 23.976. Generally this should be omitted unless the device has specific requirements.
     * @type {number}
     * @memberof DynamicHlsApiHeadMasterHlsAudioPlaylist
     */
    readonly maxFramerate?: number

    /**
     * Whether or not to copy timestamps when transcoding with an offset. Defaults to false.
     * @type {boolean}
     * @memberof DynamicHlsApiHeadMasterHlsAudioPlaylist
     */
    readonly copyTimestamps?: boolean

    /**
     * Optional. Specify a starting offset, in ticks. 1 tick &#x3D; 10000 ms.
     * @type {number}
     * @memberof DynamicHlsApiHeadMasterHlsAudioPlaylist
     */
    readonly startTimeTicks?: number

    /**
     * Optional. The fixed horizontal resolution of the encoded video.
     * @type {number}
     * @memberof DynamicHlsApiHeadMasterHlsAudioPlaylist
     */
    readonly width?: number

    /**
     * Optional. The fixed vertical resolution of the encoded video.
     * @type {number}
     * @memberof DynamicHlsApiHeadMasterHlsAudioPlaylist
     */
    readonly height?: number

    /**
     * Optional. Specify a video bitrate to encode to, e.g. 500000. If omitted this will be left to encoder defaults.
     * @type {number}
     * @memberof DynamicHlsApiHeadMasterHlsAudioPlaylist
     */
    readonly videoBitRate?: number

    /**
     * Optional. The index of the subtitle stream to use. If omitted no subtitles will be used.
     * @type {number}
     * @memberof DynamicHlsApiHeadMasterHlsAudioPlaylist
     */
    readonly subtitleStreamIndex?: number

    /**
     * Optional. Specify the subtitle delivery method.
     * @type {SubtitleDeliveryMethod}
     * @memberof DynamicHlsApiHeadMasterHlsAudioPlaylist
     */
    readonly subtitleMethod?: SubtitleDeliveryMethod

    /**
     * Optional.
     * @type {number}
     * @memberof DynamicHlsApiHeadMasterHlsAudioPlaylist
     */
    readonly maxRefFrames?: number

    /**
     * Optional. The maximum video bit depth.
     * @type {number}
     * @memberof DynamicHlsApiHeadMasterHlsAudioPlaylist
     */
    readonly maxVideoBitDepth?: number

    /**
     * Optional. Whether to require avc.
     * @type {boolean}
     * @memberof DynamicHlsApiHeadMasterHlsAudioPlaylist
     */
    readonly requireAvc?: boolean

    /**
     * Optional. Whether to deinterlace the video.
     * @type {boolean}
     * @memberof DynamicHlsApiHeadMasterHlsAudioPlaylist
     */
    readonly deInterlace?: boolean

    /**
     * Optional. Whether to require a non anamporphic stream.
     * @type {boolean}
     * @memberof DynamicHlsApiHeadMasterHlsAudioPlaylist
     */
    readonly requireNonAnamorphic?: boolean

    /**
     * Optional. The maximum number of audio channels to transcode.
     * @type {number}
     * @memberof DynamicHlsApiHeadMasterHlsAudioPlaylist
     */
    readonly transcodingMaxAudioChannels?: number

    /**
     * Optional. The limit of how many cpu cores to use.
     * @type {number}
     * @memberof DynamicHlsApiHeadMasterHlsAudioPlaylist
     */
    readonly cpuCoreLimit?: number

    /**
     * The live stream id.
     * @type {string}
     * @memberof DynamicHlsApiHeadMasterHlsAudioPlaylist
     */
    readonly liveStreamId?: string

    /**
     * Optional. Whether to enable the MpegtsM2Ts mode.
     * @type {boolean}
     * @memberof DynamicHlsApiHeadMasterHlsAudioPlaylist
     */
    readonly enableMpegtsM2TsMode?: boolean

    /**
     * Optional. Specify a video codec to encode to, e.g. h264. If omitted the server will auto-select using the url\&#39;s extension. Options: h265, h264, mpeg4, theora, vpx, wmv.
     * @type {string}
     * @memberof DynamicHlsApiHeadMasterHlsAudioPlaylist
     */
    readonly videoCodec?: string

    /**
     * Optional. Specify a subtitle codec to encode to.
     * @type {string}
     * @memberof DynamicHlsApiHeadMasterHlsAudioPlaylist
     */
    readonly subtitleCodec?: string

    /**
     * Optional. The transcoding reason.
     * @type {string}
     * @memberof DynamicHlsApiHeadMasterHlsAudioPlaylist
     */
    readonly transcodingReasons?: string

    /**
     * Optional. The index of the audio stream to use. If omitted the first audio stream will be used.
     * @type {number}
     * @memberof DynamicHlsApiHeadMasterHlsAudioPlaylist
     */
    readonly audioStreamIndex?: number

    /**
     * Optional. The index of the video stream to use. If omitted the first video stream will be used.
     * @type {number}
     * @memberof DynamicHlsApiHeadMasterHlsAudioPlaylist
     */
    readonly videoStreamIndex?: number

    /**
     * Optional. The MediaBrowser.Model.Dlna.EncodingContext.
     * @type {EncodingContext}
     * @memberof DynamicHlsApiHeadMasterHlsAudioPlaylist
     */
    readonly context?: EncodingContext

    /**
     * Optional. The streaming options.
     * @type {{ [key: string]: string; }}
     * @memberof DynamicHlsApiHeadMasterHlsAudioPlaylist
     */
    readonly streamOptions?: { [key: string]: string; }

    /**
     * Enable adaptive bitrate streaming.
     * @type {boolean}
     * @memberof DynamicHlsApiHeadMasterHlsAudioPlaylist
     */
    readonly enableAdaptiveBitrateStreaming?: boolean
}

/**
 * Request parameters for headMasterHlsVideoPlaylist operation in DynamicHlsApi.
 * @export
 * @interface DynamicHlsApiHeadMasterHlsVideoPlaylistRequest
 */
export interface DynamicHlsApiHeadMasterHlsVideoPlaylistRequest {
    /**
     * The item id.
     * @type {string}
     * @memberof DynamicHlsApiHeadMasterHlsVideoPlaylist
     */
    readonly itemId: string

    /**
     * The media version id, if playing an alternate version.
     * @type {string}
     * @memberof DynamicHlsApiHeadMasterHlsVideoPlaylist
     */
    readonly mediaSourceId: string

    /**
     * Optional. If true, the original file will be streamed statically without any encoding. Use either no url extension or the original file extension. true/false.
     * @type {boolean}
     * @memberof DynamicHlsApiHeadMasterHlsVideoPlaylist
     */
    readonly _static?: boolean

    /**
     * The streaming parameters.
     * @type {string}
     * @memberof DynamicHlsApiHeadMasterHlsVideoPlaylist
     */
    readonly params?: string

    /**
     * The tag.
     * @type {string}
     * @memberof DynamicHlsApiHeadMasterHlsVideoPlaylist
     */
    readonly tag?: string

    /**
     * Optional. The dlna device profile id to utilize.
     * @type {string}
     * @memberof DynamicHlsApiHeadMasterHlsVideoPlaylist
     */
    readonly deviceProfileId?: string

    /**
     * The play session id.
     * @type {string}
     * @memberof DynamicHlsApiHeadMasterHlsVideoPlaylist
     */
    readonly playSessionId?: string

    /**
     * The segment container.
     * @type {string}
     * @memberof DynamicHlsApiHeadMasterHlsVideoPlaylist
     */
    readonly segmentContainer?: string

    /**
     * The segment lenght.
     * @type {number}
     * @memberof DynamicHlsApiHeadMasterHlsVideoPlaylist
     */
    readonly segmentLength?: number

    /**
     * The minimum number of segments.
     * @type {number}
     * @memberof DynamicHlsApiHeadMasterHlsVideoPlaylist
     */
    readonly minSegments?: number

    /**
     * The device id of the client requesting. Used to stop encoding processes when needed.
     * @type {string}
     * @memberof DynamicHlsApiHeadMasterHlsVideoPlaylist
     */
    readonly deviceId?: string

    /**
     * Optional. Specify a audio codec to encode to, e.g. mp3. If omitted the server will auto-select using the url\&#39;s extension. Options: aac, mp3, vorbis, wma.
     * @type {string}
     * @memberof DynamicHlsApiHeadMasterHlsVideoPlaylist
     */
    readonly audioCodec?: string

    /**
     * Whether or not to allow automatic stream copy if requested values match the original source. Defaults to true.
     * @type {boolean}
     * @memberof DynamicHlsApiHeadMasterHlsVideoPlaylist
     */
    readonly enableAutoStreamCopy?: boolean

    /**
     * Whether or not to allow copying of the video stream url.
     * @type {boolean}
     * @memberof DynamicHlsApiHeadMasterHlsVideoPlaylist
     */
    readonly allowVideoStreamCopy?: boolean

    /**
     * Whether or not to allow copying of the audio stream url.
     * @type {boolean}
     * @memberof DynamicHlsApiHeadMasterHlsVideoPlaylist
     */
    readonly allowAudioStreamCopy?: boolean

    /**
     * Optional. Whether to break on non key frames.
     * @type {boolean}
     * @memberof DynamicHlsApiHeadMasterHlsVideoPlaylist
     */
    readonly breakOnNonKeyFrames?: boolean

    /**
     * Optional. Specify a specific audio sample rate, e.g. 44100.
     * @type {number}
     * @memberof DynamicHlsApiHeadMasterHlsVideoPlaylist
     */
    readonly audioSampleRate?: number

    /**
     * Optional. The maximum audio bit depth.
     * @type {number}
     * @memberof DynamicHlsApiHeadMasterHlsVideoPlaylist
     */
    readonly maxAudioBitDepth?: number

    /**
     * Optional. Specify an audio bitrate to encode to, e.g. 128000. If omitted this will be left to encoder defaults.
     * @type {number}
     * @memberof DynamicHlsApiHeadMasterHlsVideoPlaylist
     */
    readonly audioBitRate?: number

    /**
     * Optional. Specify a specific number of audio channels to encode to, e.g. 2.
     * @type {number}
     * @memberof DynamicHlsApiHeadMasterHlsVideoPlaylist
     */
    readonly audioChannels?: number

    /**
     * Optional. Specify a maximum number of audio channels to encode to, e.g. 2.
     * @type {number}
     * @memberof DynamicHlsApiHeadMasterHlsVideoPlaylist
     */
    readonly maxAudioChannels?: number

    /**
     * Optional. Specify a specific an encoder profile (varies by encoder), e.g. main, baseline, high.
     * @type {string}
     * @memberof DynamicHlsApiHeadMasterHlsVideoPlaylist
     */
    readonly profile?: string

    /**
     * Optional. Specify a level for the encoder profile (varies by encoder), e.g. 3, 3.1.
     * @type {string}
     * @memberof DynamicHlsApiHeadMasterHlsVideoPlaylist
     */
    readonly level?: string

    /**
     * Optional. A specific video framerate to encode to, e.g. 23.976. Generally this should be omitted unless the device has specific requirements.
     * @type {number}
     * @memberof DynamicHlsApiHeadMasterHlsVideoPlaylist
     */
    readonly framerate?: number

    /**
     * Optional. A specific maximum video framerate to encode to, e.g. 23.976. Generally this should be omitted unless the device has specific requirements.
     * @type {number}
     * @memberof DynamicHlsApiHeadMasterHlsVideoPlaylist
     */
    readonly maxFramerate?: number

    /**
     * Whether or not to copy timestamps when transcoding with an offset. Defaults to false.
     * @type {boolean}
     * @memberof DynamicHlsApiHeadMasterHlsVideoPlaylist
     */
    readonly copyTimestamps?: boolean

    /**
     * Optional. Specify a starting offset, in ticks. 1 tick &#x3D; 10000 ms.
     * @type {number}
     * @memberof DynamicHlsApiHeadMasterHlsVideoPlaylist
     */
    readonly startTimeTicks?: number

    /**
     * Optional. The fixed horizontal resolution of the encoded video.
     * @type {number}
     * @memberof DynamicHlsApiHeadMasterHlsVideoPlaylist
     */
    readonly width?: number

    /**
     * Optional. The fixed vertical resolution of the encoded video.
     * @type {number}
     * @memberof DynamicHlsApiHeadMasterHlsVideoPlaylist
     */
    readonly height?: number

    /**
     * Optional. Specify a video bitrate to encode to, e.g. 500000. If omitted this will be left to encoder defaults.
     * @type {number}
     * @memberof DynamicHlsApiHeadMasterHlsVideoPlaylist
     */
    readonly videoBitRate?: number

    /**
     * Optional. The index of the subtitle stream to use. If omitted no subtitles will be used.
     * @type {number}
     * @memberof DynamicHlsApiHeadMasterHlsVideoPlaylist
     */
    readonly subtitleStreamIndex?: number

    /**
     * Optional. Specify the subtitle delivery method.
     * @type {SubtitleDeliveryMethod}
     * @memberof DynamicHlsApiHeadMasterHlsVideoPlaylist
     */
    readonly subtitleMethod?: SubtitleDeliveryMethod

    /**
     * Optional.
     * @type {number}
     * @memberof DynamicHlsApiHeadMasterHlsVideoPlaylist
     */
    readonly maxRefFrames?: number

    /**
     * Optional. The maximum video bit depth.
     * @type {number}
     * @memberof DynamicHlsApiHeadMasterHlsVideoPlaylist
     */
    readonly maxVideoBitDepth?: number

    /**
     * Optional. Whether to require avc.
     * @type {boolean}
     * @memberof DynamicHlsApiHeadMasterHlsVideoPlaylist
     */
    readonly requireAvc?: boolean

    /**
     * Optional. Whether to deinterlace the video.
     * @type {boolean}
     * @memberof DynamicHlsApiHeadMasterHlsVideoPlaylist
     */
    readonly deInterlace?: boolean

    /**
     * Optional. Whether to require a non anamporphic stream.
     * @type {boolean}
     * @memberof DynamicHlsApiHeadMasterHlsVideoPlaylist
     */
    readonly requireNonAnamorphic?: boolean

    /**
     * Optional. The maximum number of audio channels to transcode.
     * @type {number}
     * @memberof DynamicHlsApiHeadMasterHlsVideoPlaylist
     */
    readonly transcodingMaxAudioChannels?: number

    /**
     * Optional. The limit of how many cpu cores to use.
     * @type {number}
     * @memberof DynamicHlsApiHeadMasterHlsVideoPlaylist
     */
    readonly cpuCoreLimit?: number

    /**
     * The live stream id.
     * @type {string}
     * @memberof DynamicHlsApiHeadMasterHlsVideoPlaylist
     */
    readonly liveStreamId?: string

    /**
     * Optional. Whether to enable the MpegtsM2Ts mode.
     * @type {boolean}
     * @memberof DynamicHlsApiHeadMasterHlsVideoPlaylist
     */
    readonly enableMpegtsM2TsMode?: boolean

    /**
     * Optional. Specify a video codec to encode to, e.g. h264. If omitted the server will auto-select using the url\&#39;s extension. Options: h265, h264, mpeg4, theora, vpx, wmv.
     * @type {string}
     * @memberof DynamicHlsApiHeadMasterHlsVideoPlaylist
     */
    readonly videoCodec?: string

    /**
     * Optional. Specify a subtitle codec to encode to.
     * @type {string}
     * @memberof DynamicHlsApiHeadMasterHlsVideoPlaylist
     */
    readonly subtitleCodec?: string

    /**
     * Optional. The transcoding reason.
     * @type {string}
     * @memberof DynamicHlsApiHeadMasterHlsVideoPlaylist
     */
    readonly transcodingReasons?: string

    /**
     * Optional. The index of the audio stream to use. If omitted the first audio stream will be used.
     * @type {number}
     * @memberof DynamicHlsApiHeadMasterHlsVideoPlaylist
     */
    readonly audioStreamIndex?: number

    /**
     * Optional. The index of the video stream to use. If omitted the first video stream will be used.
     * @type {number}
     * @memberof DynamicHlsApiHeadMasterHlsVideoPlaylist
     */
    readonly videoStreamIndex?: number

    /**
     * Optional. The MediaBrowser.Model.Dlna.EncodingContext.
     * @type {EncodingContext}
     * @memberof DynamicHlsApiHeadMasterHlsVideoPlaylist
     */
    readonly context?: EncodingContext

    /**
     * Optional. The streaming options.
     * @type {{ [key: string]: string; }}
     * @memberof DynamicHlsApiHeadMasterHlsVideoPlaylist
     */
    readonly streamOptions?: { [key: string]: string; }

    /**
     * Enable adaptive bitrate streaming.
     * @type {boolean}
     * @memberof DynamicHlsApiHeadMasterHlsVideoPlaylist
     */
    readonly enableAdaptiveBitrateStreaming?: boolean
}

/**
 * DynamicHlsApi - object-oriented interface
 * @export
 * @class DynamicHlsApi
 * @extends {BaseAPI}
 */
export class DynamicHlsApi extends BaseAPI {
    /**
     * 
     * @summary Gets a video stream using HTTP live streaming.
     * @param {DynamicHlsApiGetHlsAudioSegmentRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DynamicHlsApi
     */
    public getHlsAudioSegment(requestParameters: DynamicHlsApiGetHlsAudioSegmentRequest, options?: any) {
        return DynamicHlsApiFp(this.configuration).getHlsAudioSegment(requestParameters.itemId, requestParameters.playlistId, requestParameters.segmentId, requestParameters.container, requestParameters._static, requestParameters.params, requestParameters.tag, requestParameters.deviceProfileId, requestParameters.playSessionId, requestParameters.segmentContainer, requestParameters.segmentLength, requestParameters.minSegments, requestParameters.mediaSourceId, requestParameters.deviceId, requestParameters.audioCodec, requestParameters.enableAutoStreamCopy, requestParameters.allowVideoStreamCopy, requestParameters.allowAudioStreamCopy, requestParameters.breakOnNonKeyFrames, requestParameters.audioSampleRate, requestParameters.maxAudioBitDepth, requestParameters.audioBitRate, requestParameters.audioChannels, requestParameters.maxAudioChannels, requestParameters.profile, requestParameters.level, requestParameters.framerate, requestParameters.maxFramerate, requestParameters.copyTimestamps, requestParameters.startTimeTicks, requestParameters.width, requestParameters.height, requestParameters.videoBitRate, requestParameters.subtitleStreamIndex, requestParameters.subtitleMethod, requestParameters.maxRefFrames, requestParameters.maxVideoBitDepth, requestParameters.requireAvc, requestParameters.deInterlace, requestParameters.requireNonAnamorphic, requestParameters.transcodingMaxAudioChannels, requestParameters.cpuCoreLimit, requestParameters.liveStreamId, requestParameters.enableMpegtsM2TsMode, requestParameters.videoCodec, requestParameters.subtitleCodec, requestParameters.transcodingReasons, requestParameters.audioStreamIndex, requestParameters.videoStreamIndex, requestParameters.context, requestParameters.streamOptions, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets a video stream using HTTP live streaming.
     * @param {DynamicHlsApiGetHlsVideoSegmentRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DynamicHlsApi
     */
    public getHlsVideoSegment(requestParameters: DynamicHlsApiGetHlsVideoSegmentRequest, options?: any) {
        return DynamicHlsApiFp(this.configuration).getHlsVideoSegment(requestParameters.itemId, requestParameters.playlistId, requestParameters.segmentId, requestParameters.container, requestParameters._static, requestParameters.params, requestParameters.tag, requestParameters.deviceProfileId, requestParameters.playSessionId, requestParameters.segmentContainer, requestParameters.segmentLength, requestParameters.minSegments, requestParameters.mediaSourceId, requestParameters.deviceId, requestParameters.audioCodec, requestParameters.enableAutoStreamCopy, requestParameters.allowVideoStreamCopy, requestParameters.allowAudioStreamCopy, requestParameters.breakOnNonKeyFrames, requestParameters.audioSampleRate, requestParameters.maxAudioBitDepth, requestParameters.audioBitRate, requestParameters.audioChannels, requestParameters.maxAudioChannels, requestParameters.profile, requestParameters.level, requestParameters.framerate, requestParameters.maxFramerate, requestParameters.copyTimestamps, requestParameters.startTimeTicks, requestParameters.width, requestParameters.height, requestParameters.videoBitRate, requestParameters.subtitleStreamIndex, requestParameters.subtitleMethod, requestParameters.maxRefFrames, requestParameters.maxVideoBitDepth, requestParameters.requireAvc, requestParameters.deInterlace, requestParameters.requireNonAnamorphic, requestParameters.transcodingMaxAudioChannels, requestParameters.cpuCoreLimit, requestParameters.liveStreamId, requestParameters.enableMpegtsM2TsMode, requestParameters.videoCodec, requestParameters.subtitleCodec, requestParameters.transcodingReasons, requestParameters.audioStreamIndex, requestParameters.videoStreamIndex, requestParameters.context, requestParameters.streamOptions, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets an audio hls playlist stream.
     * @param {DynamicHlsApiGetMasterHlsAudioPlaylistRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DynamicHlsApi
     */
    public getMasterHlsAudioPlaylist(requestParameters: DynamicHlsApiGetMasterHlsAudioPlaylistRequest, options?: any) {
        return DynamicHlsApiFp(this.configuration).getMasterHlsAudioPlaylist(requestParameters.itemId, requestParameters.mediaSourceId, requestParameters._static, requestParameters.params, requestParameters.tag, requestParameters.deviceProfileId, requestParameters.playSessionId, requestParameters.segmentContainer, requestParameters.segmentLength, requestParameters.minSegments, requestParameters.deviceId, requestParameters.audioCodec, requestParameters.enableAutoStreamCopy, requestParameters.allowVideoStreamCopy, requestParameters.allowAudioStreamCopy, requestParameters.breakOnNonKeyFrames, requestParameters.audioSampleRate, requestParameters.maxAudioBitDepth, requestParameters.audioBitRate, requestParameters.audioChannels, requestParameters.maxAudioChannels, requestParameters.profile, requestParameters.level, requestParameters.framerate, requestParameters.maxFramerate, requestParameters.copyTimestamps, requestParameters.startTimeTicks, requestParameters.width, requestParameters.height, requestParameters.videoBitRate, requestParameters.subtitleStreamIndex, requestParameters.subtitleMethod, requestParameters.maxRefFrames, requestParameters.maxVideoBitDepth, requestParameters.requireAvc, requestParameters.deInterlace, requestParameters.requireNonAnamorphic, requestParameters.transcodingMaxAudioChannels, requestParameters.cpuCoreLimit, requestParameters.liveStreamId, requestParameters.enableMpegtsM2TsMode, requestParameters.videoCodec, requestParameters.subtitleCodec, requestParameters.transcodingReasons, requestParameters.audioStreamIndex, requestParameters.videoStreamIndex, requestParameters.context, requestParameters.streamOptions, requestParameters.enableAdaptiveBitrateStreaming, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets a video hls playlist stream.
     * @param {DynamicHlsApiGetMasterHlsVideoPlaylistRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DynamicHlsApi
     */
    public getMasterHlsVideoPlaylist(requestParameters: DynamicHlsApiGetMasterHlsVideoPlaylistRequest, options?: any) {
        return DynamicHlsApiFp(this.configuration).getMasterHlsVideoPlaylist(requestParameters.itemId, requestParameters.mediaSourceId, requestParameters._static, requestParameters.params, requestParameters.tag, requestParameters.deviceProfileId, requestParameters.playSessionId, requestParameters.segmentContainer, requestParameters.segmentLength, requestParameters.minSegments, requestParameters.deviceId, requestParameters.audioCodec, requestParameters.enableAutoStreamCopy, requestParameters.allowVideoStreamCopy, requestParameters.allowAudioStreamCopy, requestParameters.breakOnNonKeyFrames, requestParameters.audioSampleRate, requestParameters.maxAudioBitDepth, requestParameters.audioBitRate, requestParameters.audioChannels, requestParameters.maxAudioChannels, requestParameters.profile, requestParameters.level, requestParameters.framerate, requestParameters.maxFramerate, requestParameters.copyTimestamps, requestParameters.startTimeTicks, requestParameters.width, requestParameters.height, requestParameters.videoBitRate, requestParameters.subtitleStreamIndex, requestParameters.subtitleMethod, requestParameters.maxRefFrames, requestParameters.maxVideoBitDepth, requestParameters.requireAvc, requestParameters.deInterlace, requestParameters.requireNonAnamorphic, requestParameters.transcodingMaxAudioChannels, requestParameters.cpuCoreLimit, requestParameters.liveStreamId, requestParameters.enableMpegtsM2TsMode, requestParameters.videoCodec, requestParameters.subtitleCodec, requestParameters.transcodingReasons, requestParameters.audioStreamIndex, requestParameters.videoStreamIndex, requestParameters.context, requestParameters.streamOptions, requestParameters.enableAdaptiveBitrateStreaming, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets an audio stream using HTTP live streaming.
     * @param {DynamicHlsApiGetVariantHlsAudioPlaylistRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DynamicHlsApi
     */
    public getVariantHlsAudioPlaylist(requestParameters: DynamicHlsApiGetVariantHlsAudioPlaylistRequest, options?: any) {
        return DynamicHlsApiFp(this.configuration).getVariantHlsAudioPlaylist(requestParameters.itemId, requestParameters._static, requestParameters.params, requestParameters.tag, requestParameters.deviceProfileId, requestParameters.playSessionId, requestParameters.segmentContainer, requestParameters.segmentLength, requestParameters.minSegments, requestParameters.mediaSourceId, requestParameters.deviceId, requestParameters.audioCodec, requestParameters.enableAutoStreamCopy, requestParameters.allowVideoStreamCopy, requestParameters.allowAudioStreamCopy, requestParameters.breakOnNonKeyFrames, requestParameters.audioSampleRate, requestParameters.maxAudioBitDepth, requestParameters.audioBitRate, requestParameters.audioChannels, requestParameters.maxAudioChannels, requestParameters.profile, requestParameters.level, requestParameters.framerate, requestParameters.maxFramerate, requestParameters.copyTimestamps, requestParameters.startTimeTicks, requestParameters.width, requestParameters.height, requestParameters.videoBitRate, requestParameters.subtitleStreamIndex, requestParameters.subtitleMethod, requestParameters.maxRefFrames, requestParameters.maxVideoBitDepth, requestParameters.requireAvc, requestParameters.deInterlace, requestParameters.requireNonAnamorphic, requestParameters.transcodingMaxAudioChannels, requestParameters.cpuCoreLimit, requestParameters.liveStreamId, requestParameters.enableMpegtsM2TsMode, requestParameters.videoCodec, requestParameters.subtitleCodec, requestParameters.transcodingReasons, requestParameters.audioStreamIndex, requestParameters.videoStreamIndex, requestParameters.context, requestParameters.streamOptions, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets a video stream using HTTP live streaming.
     * @param {DynamicHlsApiGetVariantHlsVideoPlaylistRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DynamicHlsApi
     */
    public getVariantHlsVideoPlaylist(requestParameters: DynamicHlsApiGetVariantHlsVideoPlaylistRequest, options?: any) {
        return DynamicHlsApiFp(this.configuration).getVariantHlsVideoPlaylist(requestParameters.itemId, requestParameters._static, requestParameters.params, requestParameters.tag, requestParameters.deviceProfileId, requestParameters.playSessionId, requestParameters.segmentContainer, requestParameters.segmentLength, requestParameters.minSegments, requestParameters.mediaSourceId, requestParameters.deviceId, requestParameters.audioCodec, requestParameters.enableAutoStreamCopy, requestParameters.allowVideoStreamCopy, requestParameters.allowAudioStreamCopy, requestParameters.breakOnNonKeyFrames, requestParameters.audioSampleRate, requestParameters.maxAudioBitDepth, requestParameters.audioBitRate, requestParameters.audioChannels, requestParameters.maxAudioChannels, requestParameters.profile, requestParameters.level, requestParameters.framerate, requestParameters.maxFramerate, requestParameters.copyTimestamps, requestParameters.startTimeTicks, requestParameters.width, requestParameters.height, requestParameters.videoBitRate, requestParameters.subtitleStreamIndex, requestParameters.subtitleMethod, requestParameters.maxRefFrames, requestParameters.maxVideoBitDepth, requestParameters.requireAvc, requestParameters.deInterlace, requestParameters.requireNonAnamorphic, requestParameters.transcodingMaxAudioChannels, requestParameters.cpuCoreLimit, requestParameters.liveStreamId, requestParameters.enableMpegtsM2TsMode, requestParameters.videoCodec, requestParameters.subtitleCodec, requestParameters.transcodingReasons, requestParameters.audioStreamIndex, requestParameters.videoStreamIndex, requestParameters.context, requestParameters.streamOptions, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets an audio hls playlist stream.
     * @param {DynamicHlsApiHeadMasterHlsAudioPlaylistRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DynamicHlsApi
     */
    public headMasterHlsAudioPlaylist(requestParameters: DynamicHlsApiHeadMasterHlsAudioPlaylistRequest, options?: any) {
        return DynamicHlsApiFp(this.configuration).headMasterHlsAudioPlaylist(requestParameters.itemId, requestParameters.mediaSourceId, requestParameters._static, requestParameters.params, requestParameters.tag, requestParameters.deviceProfileId, requestParameters.playSessionId, requestParameters.segmentContainer, requestParameters.segmentLength, requestParameters.minSegments, requestParameters.deviceId, requestParameters.audioCodec, requestParameters.enableAutoStreamCopy, requestParameters.allowVideoStreamCopy, requestParameters.allowAudioStreamCopy, requestParameters.breakOnNonKeyFrames, requestParameters.audioSampleRate, requestParameters.maxAudioBitDepth, requestParameters.audioBitRate, requestParameters.audioChannels, requestParameters.maxAudioChannels, requestParameters.profile, requestParameters.level, requestParameters.framerate, requestParameters.maxFramerate, requestParameters.copyTimestamps, requestParameters.startTimeTicks, requestParameters.width, requestParameters.height, requestParameters.videoBitRate, requestParameters.subtitleStreamIndex, requestParameters.subtitleMethod, requestParameters.maxRefFrames, requestParameters.maxVideoBitDepth, requestParameters.requireAvc, requestParameters.deInterlace, requestParameters.requireNonAnamorphic, requestParameters.transcodingMaxAudioChannels, requestParameters.cpuCoreLimit, requestParameters.liveStreamId, requestParameters.enableMpegtsM2TsMode, requestParameters.videoCodec, requestParameters.subtitleCodec, requestParameters.transcodingReasons, requestParameters.audioStreamIndex, requestParameters.videoStreamIndex, requestParameters.context, requestParameters.streamOptions, requestParameters.enableAdaptiveBitrateStreaming, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets a video hls playlist stream.
     * @param {DynamicHlsApiHeadMasterHlsVideoPlaylistRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DynamicHlsApi
     */
    public headMasterHlsVideoPlaylist(requestParameters: DynamicHlsApiHeadMasterHlsVideoPlaylistRequest, options?: any) {
        return DynamicHlsApiFp(this.configuration).headMasterHlsVideoPlaylist(requestParameters.itemId, requestParameters.mediaSourceId, requestParameters._static, requestParameters.params, requestParameters.tag, requestParameters.deviceProfileId, requestParameters.playSessionId, requestParameters.segmentContainer, requestParameters.segmentLength, requestParameters.minSegments, requestParameters.deviceId, requestParameters.audioCodec, requestParameters.enableAutoStreamCopy, requestParameters.allowVideoStreamCopy, requestParameters.allowAudioStreamCopy, requestParameters.breakOnNonKeyFrames, requestParameters.audioSampleRate, requestParameters.maxAudioBitDepth, requestParameters.audioBitRate, requestParameters.audioChannels, requestParameters.maxAudioChannels, requestParameters.profile, requestParameters.level, requestParameters.framerate, requestParameters.maxFramerate, requestParameters.copyTimestamps, requestParameters.startTimeTicks, requestParameters.width, requestParameters.height, requestParameters.videoBitRate, requestParameters.subtitleStreamIndex, requestParameters.subtitleMethod, requestParameters.maxRefFrames, requestParameters.maxVideoBitDepth, requestParameters.requireAvc, requestParameters.deInterlace, requestParameters.requireNonAnamorphic, requestParameters.transcodingMaxAudioChannels, requestParameters.cpuCoreLimit, requestParameters.liveStreamId, requestParameters.enableMpegtsM2TsMode, requestParameters.videoCodec, requestParameters.subtitleCodec, requestParameters.transcodingReasons, requestParameters.audioStreamIndex, requestParameters.videoStreamIndex, requestParameters.context, requestParameters.streamOptions, requestParameters.enableAdaptiveBitrateStreaming, options).then((request) => request(this.axios, this.basePath));
    }
}
