/* tslint:disable */
/* eslint-disable */
/**
 * Jellyfin API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AuthenticationInfoQueryResult } from '../models';
/**
 * ApiKeyApi - axios parameter creator
 * @export
 */
export const ApiKeyApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create a new api key.
         * @param {string} app Name of the app using the authentication key.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createKey: async (app: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'app' is not null or undefined
            if (app === null || app === undefined) {
                throw new RequiredError('app','Required parameter app was null or undefined when calling createKey.');
            }
            const localVarPath = `/Auth/Keys`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }

            if (app !== undefined) {
                localVarQueryParameter['app'] = app;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all keys.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKeys: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/Auth/Keys`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Remove an api key.
         * @param {string} key The access token to delete.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        revokeKey: async (key: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'key' is not null or undefined
            if (key === null || key === undefined) {
                throw new RequiredError('key','Required parameter key was null or undefined when calling revokeKey.');
            }
            const localVarPath = `/Auth/Keys/{key}`
                .replace(`{${"key"}}`, encodeURIComponent(String(key)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ApiKeyApi - functional programming interface
 * @export
 */
export const ApiKeyApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create a new api key.
         * @param {string} app Name of the app using the authentication key.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createKey(app: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ApiKeyApiAxiosParamCreator(configuration).createKey(app, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get all keys.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getKeys(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthenticationInfoQueryResult>> {
            const localVarAxiosArgs = await ApiKeyApiAxiosParamCreator(configuration).getKeys(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Remove an api key.
         * @param {string} key The access token to delete.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async revokeKey(key: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ApiKeyApiAxiosParamCreator(configuration).revokeKey(key, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * ApiKeyApi - factory interface
 * @export
 */
export const ApiKeyApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Create a new api key.
         * @param {string} app Name of the app using the authentication key.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createKey(app: string, options?: any): AxiosPromise<void> {
            return ApiKeyApiFp(configuration).createKey(app, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all keys.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKeys(options?: any): AxiosPromise<AuthenticationInfoQueryResult> {
            return ApiKeyApiFp(configuration).getKeys(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Remove an api key.
         * @param {string} key The access token to delete.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        revokeKey(key: string, options?: any): AxiosPromise<void> {
            return ApiKeyApiFp(configuration).revokeKey(key, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createKey operation in ApiKeyApi.
 * @export
 * @interface ApiKeyApiCreateKeyRequest
 */
export interface ApiKeyApiCreateKeyRequest {
    /**
     * Name of the app using the authentication key.
     * @type {string}
     * @memberof ApiKeyApiCreateKey
     */
    readonly app: string
}

/**
 * Request parameters for revokeKey operation in ApiKeyApi.
 * @export
 * @interface ApiKeyApiRevokeKeyRequest
 */
export interface ApiKeyApiRevokeKeyRequest {
    /**
     * The access token to delete.
     * @type {string}
     * @memberof ApiKeyApiRevokeKey
     */
    readonly key: string
}

/**
 * ApiKeyApi - object-oriented interface
 * @export
 * @class ApiKeyApi
 * @extends {BaseAPI}
 */
export class ApiKeyApi extends BaseAPI {
    /**
     * 
     * @summary Create a new api key.
     * @param {ApiKeyApiCreateKeyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiKeyApi
     */
    public createKey(requestParameters: ApiKeyApiCreateKeyRequest, options?: any) {
        return ApiKeyApiFp(this.configuration).createKey(requestParameters.app, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all keys.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiKeyApi
     */
    public getKeys(options?: any) {
        return ApiKeyApiFp(this.configuration).getKeys(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Remove an api key.
     * @param {ApiKeyApiRevokeKeyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiKeyApi
     */
    public revokeKey(requestParameters: ApiKeyApiRevokeKeyRequest, options?: any) {
        return ApiKeyApiFp(this.configuration).revokeKey(requestParameters.key, options).then((request) => request(this.axios, this.basePath));
    }
}
