/* tslint:disable */
/* eslint-disable */
/**
 * Jellyfin API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { EndPointInfo } from '../models';
// @ts-ignore
import { LogFile } from '../models';
// @ts-ignore
import { PublicSystemInfo } from '../models';
// @ts-ignore
import { SystemInfo } from '../models';
// @ts-ignore
import { WakeOnLanInfo } from '../models';
/**
 * SystemApi - axios parameter creator
 * @export
 */
export const SystemApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Gets information about the request endpoint.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEndpointInfo: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/System/Endpoint`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets a log file.
         * @param {string} name The name of the log file to get.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLogFile: async (name: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            if (name === null || name === undefined) {
                throw new RequiredError('name','Required parameter name was null or undefined when calling getLogFile.');
            }
            const localVarPath = `/System/Logs/Log`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Pings the system.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPingSystem: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/System/Ping`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets public information about the server.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPublicSystemInfo: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/System/Info/Public`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets a list of available server log files.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getServerLogs: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/System/Logs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets information about the server.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSystemInfo: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/System/Info`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets wake on lan information.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWakeOnLanInfo: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/System/WakeOnLanInfo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Pings the system.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postPingSystem: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/System/Ping`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Restarts the application.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restartApplication: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/System/Restart`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Shuts down the application.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shutdownApplication: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/System/Shutdown`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SystemApi - functional programming interface
 * @export
 */
export const SystemApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Gets information about the request endpoint.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEndpointInfo(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EndPointInfo>> {
            const localVarAxiosArgs = await SystemApiAxiosParamCreator(configuration).getEndpointInfo(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Gets a log file.
         * @param {string} name The name of the log file to get.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLogFile(name: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await SystemApiAxiosParamCreator(configuration).getLogFile(name, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Pings the system.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPingSystem(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await SystemApiAxiosParamCreator(configuration).getPingSystem(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Gets public information about the server.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPublicSystemInfo(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PublicSystemInfo>> {
            const localVarAxiosArgs = await SystemApiAxiosParamCreator(configuration).getPublicSystemInfo(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Gets a list of available server log files.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getServerLogs(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<LogFile>>> {
            const localVarAxiosArgs = await SystemApiAxiosParamCreator(configuration).getServerLogs(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Gets information about the server.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSystemInfo(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SystemInfo>> {
            const localVarAxiosArgs = await SystemApiAxiosParamCreator(configuration).getSystemInfo(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Gets wake on lan information.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWakeOnLanInfo(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<WakeOnLanInfo>>> {
            const localVarAxiosArgs = await SystemApiAxiosParamCreator(configuration).getWakeOnLanInfo(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Pings the system.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postPingSystem(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await SystemApiAxiosParamCreator(configuration).postPingSystem(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Restarts the application.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async restartApplication(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await SystemApiAxiosParamCreator(configuration).restartApplication(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Shuts down the application.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async shutdownApplication(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await SystemApiAxiosParamCreator(configuration).shutdownApplication(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * SystemApi - factory interface
 * @export
 */
export const SystemApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Gets information about the request endpoint.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEndpointInfo(options?: any): AxiosPromise<EndPointInfo> {
            return SystemApiFp(configuration).getEndpointInfo(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets a log file.
         * @param {string} name The name of the log file to get.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLogFile(name: string, options?: any): AxiosPromise<any> {
            return SystemApiFp(configuration).getLogFile(name, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Pings the system.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPingSystem(options?: any): AxiosPromise<string> {
            return SystemApiFp(configuration).getPingSystem(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets public information about the server.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPublicSystemInfo(options?: any): AxiosPromise<PublicSystemInfo> {
            return SystemApiFp(configuration).getPublicSystemInfo(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets a list of available server log files.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getServerLogs(options?: any): AxiosPromise<Array<LogFile>> {
            return SystemApiFp(configuration).getServerLogs(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets information about the server.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSystemInfo(options?: any): AxiosPromise<SystemInfo> {
            return SystemApiFp(configuration).getSystemInfo(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets wake on lan information.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWakeOnLanInfo(options?: any): AxiosPromise<Array<WakeOnLanInfo>> {
            return SystemApiFp(configuration).getWakeOnLanInfo(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Pings the system.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postPingSystem(options?: any): AxiosPromise<string> {
            return SystemApiFp(configuration).postPingSystem(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Restarts the application.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restartApplication(options?: any): AxiosPromise<void> {
            return SystemApiFp(configuration).restartApplication(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Shuts down the application.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shutdownApplication(options?: any): AxiosPromise<void> {
            return SystemApiFp(configuration).shutdownApplication(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getLogFile operation in SystemApi.
 * @export
 * @interface SystemApiGetLogFileRequest
 */
export interface SystemApiGetLogFileRequest {
    /**
     * The name of the log file to get.
     * @type {string}
     * @memberof SystemApiGetLogFile
     */
    readonly name: string
}

/**
 * SystemApi - object-oriented interface
 * @export
 * @class SystemApi
 * @extends {BaseAPI}
 */
export class SystemApi extends BaseAPI {
    /**
     * 
     * @summary Gets information about the request endpoint.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApi
     */
    public getEndpointInfo(options?: any) {
        return SystemApiFp(this.configuration).getEndpointInfo(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets a log file.
     * @param {SystemApiGetLogFileRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApi
     */
    public getLogFile(requestParameters: SystemApiGetLogFileRequest, options?: any) {
        return SystemApiFp(this.configuration).getLogFile(requestParameters.name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Pings the system.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApi
     */
    public getPingSystem(options?: any) {
        return SystemApiFp(this.configuration).getPingSystem(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets public information about the server.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApi
     */
    public getPublicSystemInfo(options?: any) {
        return SystemApiFp(this.configuration).getPublicSystemInfo(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets a list of available server log files.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApi
     */
    public getServerLogs(options?: any) {
        return SystemApiFp(this.configuration).getServerLogs(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets information about the server.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApi
     */
    public getSystemInfo(options?: any) {
        return SystemApiFp(this.configuration).getSystemInfo(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets wake on lan information.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApi
     */
    public getWakeOnLanInfo(options?: any) {
        return SystemApiFp(this.configuration).getWakeOnLanInfo(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Pings the system.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApi
     */
    public postPingSystem(options?: any) {
        return SystemApiFp(this.configuration).postPingSystem(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Restarts the application.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApi
     */
    public restartApplication(options?: any) {
        return SystemApiFp(this.configuration).restartApplication(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Shuts down the application.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApi
     */
    public shutdownApplication(options?: any) {
        return SystemApiFp(this.configuration).shutdownApplication(options).then((request) => request(this.axios, this.basePath));
    }
}
