/* tslint:disable */
/* eslint-disable */
/**
 * Jellyfin API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { BaseItemDtoQueryResult } from '../models';
// @ts-ignore
import { ChannelFeatures } from '../models';
// @ts-ignore
import { ItemFilter } from '../models';
/**
 * ChannelsApi - axios parameter creator
 * @export
 */
export const ChannelsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get all channel features.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllChannelFeatures: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/Channels/Features`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get channel features.
         * @param {string} channelId Channel id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChannelFeatures: async (channelId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'channelId' is not null or undefined
            if (channelId === null || channelId === undefined) {
                throw new RequiredError('channelId','Required parameter channelId was null or undefined when calling getChannelFeatures.');
            }
            const localVarPath = `/Channels/{channelId}/Features`
                .replace(`{${"channelId"}}`, encodeURIComponent(String(channelId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get channel items.
         * @param {string} channelId Channel Id.
         * @param {string} [folderId] Optional. Folder Id.
         * @param {string} [userId] Optional. User Id.
         * @param {number} [startIndex] Optional. The record index to start at. All items with a lower index will be dropped from the results.
         * @param {number} [limit] Optional. The maximum number of records to return.
         * @param {string} [sortOrder] Optional. Sort Order - Ascending,Descending.
         * @param {Array<ItemFilter>} [filters] Optional. Specify additional filters to apply.
         * @param {string} [sortBy] Optional. Specify one or more sort orders, comma delimited. Options: Album, AlbumArtist, Artist, Budget, CommunityRating, CriticRating, DateCreated, DatePlayed, PlayCount, PremiereDate, ProductionYear, SortName, Random, Revenue, Runtime.
         * @param {string} [fields] Optional. Specify additional fields of information to return in the output. This allows multiple, comma delimited. Options: Budget, Chapters, DateCreated, Genres, HomePageUrl, IndexOptions, MediaStreams, Overview, ParentId, Path, People, ProviderIds, PrimaryImageAspectRatio, Revenue, SortName, Studios, Taglines.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChannelItems: async (channelId: string, folderId?: string, userId?: string, startIndex?: number, limit?: number, sortOrder?: string, filters?: Array<ItemFilter>, sortBy?: string, fields?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'channelId' is not null or undefined
            if (channelId === null || channelId === undefined) {
                throw new RequiredError('channelId','Required parameter channelId was null or undefined when calling getChannelItems.');
            }
            const localVarPath = `/Channels/{channelId}/Items`
                .replace(`{${"channelId"}}`, encodeURIComponent(String(channelId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }

            if (folderId !== undefined) {
                localVarQueryParameter['folderId'] = folderId;
            }

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }

            if (startIndex !== undefined) {
                localVarQueryParameter['startIndex'] = startIndex;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (sortOrder !== undefined) {
                localVarQueryParameter['sortOrder'] = sortOrder;
            }

            if (filters) {
                localVarQueryParameter['filters'] = filters;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (fields !== undefined) {
                localVarQueryParameter['fields'] = fields;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets available channels.
         * @param {string} [userId] User Id to filter by. Use System.Guid.Empty to not filter by user.
         * @param {number} [startIndex] Optional. The record index to start at. All items with a lower index will be dropped from the results.
         * @param {number} [limit] Optional. The maximum number of records to return.
         * @param {boolean} [supportsLatestItems] Optional. Filter by channels that support getting latest items.
         * @param {boolean} [supportsMediaDeletion] Optional. Filter by channels that support media deletion.
         * @param {boolean} [isFavorite] Optional. Filter by channels that are favorite.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChannels: async (userId?: string, startIndex?: number, limit?: number, supportsLatestItems?: boolean, supportsMediaDeletion?: boolean, isFavorite?: boolean, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/Channels`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }

            if (startIndex !== undefined) {
                localVarQueryParameter['startIndex'] = startIndex;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (supportsLatestItems !== undefined) {
                localVarQueryParameter['supportsLatestItems'] = supportsLatestItems;
            }

            if (supportsMediaDeletion !== undefined) {
                localVarQueryParameter['supportsMediaDeletion'] = supportsMediaDeletion;
            }

            if (isFavorite !== undefined) {
                localVarQueryParameter['isFavorite'] = isFavorite;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets latest channel items.
         * @param {string} [userId] Optional. User Id.
         * @param {number} [startIndex] Optional. The record index to start at. All items with a lower index will be dropped from the results.
         * @param {number} [limit] Optional. The maximum number of records to return.
         * @param {Array<ItemFilter>} [filters] Optional. Specify additional filters to apply.
         * @param {string} [fields] Optional. Specify additional fields of information to return in the output. This allows multiple, comma delimited. Options: Budget, Chapters, DateCreated, Genres, HomePageUrl, IndexOptions, MediaStreams, Overview, ParentId, Path, People, ProviderIds, PrimaryImageAspectRatio, Revenue, SortName, Studios, Taglines.
         * @param {string} [channelIds] Optional. Specify one or more channel id\&#39;s, comma delimited.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLatestChannelItems: async (userId?: string, startIndex?: number, limit?: number, filters?: Array<ItemFilter>, fields?: string, channelIds?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/Channels/Items/Latest`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }

            if (startIndex !== undefined) {
                localVarQueryParameter['startIndex'] = startIndex;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (filters) {
                localVarQueryParameter['filters'] = filters;
            }

            if (fields !== undefined) {
                localVarQueryParameter['fields'] = fields;
            }

            if (channelIds !== undefined) {
                localVarQueryParameter['channelIds'] = channelIds;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ChannelsApi - functional programming interface
 * @export
 */
export const ChannelsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get all channel features.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllChannelFeatures(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ChannelFeatures>>> {
            const localVarAxiosArgs = await ChannelsApiAxiosParamCreator(configuration).getAllChannelFeatures(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get channel features.
         * @param {string} channelId Channel id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getChannelFeatures(channelId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChannelFeatures>> {
            const localVarAxiosArgs = await ChannelsApiAxiosParamCreator(configuration).getChannelFeatures(channelId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get channel items.
         * @param {string} channelId Channel Id.
         * @param {string} [folderId] Optional. Folder Id.
         * @param {string} [userId] Optional. User Id.
         * @param {number} [startIndex] Optional. The record index to start at. All items with a lower index will be dropped from the results.
         * @param {number} [limit] Optional. The maximum number of records to return.
         * @param {string} [sortOrder] Optional. Sort Order - Ascending,Descending.
         * @param {Array<ItemFilter>} [filters] Optional. Specify additional filters to apply.
         * @param {string} [sortBy] Optional. Specify one or more sort orders, comma delimited. Options: Album, AlbumArtist, Artist, Budget, CommunityRating, CriticRating, DateCreated, DatePlayed, PlayCount, PremiereDate, ProductionYear, SortName, Random, Revenue, Runtime.
         * @param {string} [fields] Optional. Specify additional fields of information to return in the output. This allows multiple, comma delimited. Options: Budget, Chapters, DateCreated, Genres, HomePageUrl, IndexOptions, MediaStreams, Overview, ParentId, Path, People, ProviderIds, PrimaryImageAspectRatio, Revenue, SortName, Studios, Taglines.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getChannelItems(channelId: string, folderId?: string, userId?: string, startIndex?: number, limit?: number, sortOrder?: string, filters?: Array<ItemFilter>, sortBy?: string, fields?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BaseItemDtoQueryResult>> {
            const localVarAxiosArgs = await ChannelsApiAxiosParamCreator(configuration).getChannelItems(channelId, folderId, userId, startIndex, limit, sortOrder, filters, sortBy, fields, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Gets available channels.
         * @param {string} [userId] User Id to filter by. Use System.Guid.Empty to not filter by user.
         * @param {number} [startIndex] Optional. The record index to start at. All items with a lower index will be dropped from the results.
         * @param {number} [limit] Optional. The maximum number of records to return.
         * @param {boolean} [supportsLatestItems] Optional. Filter by channels that support getting latest items.
         * @param {boolean} [supportsMediaDeletion] Optional. Filter by channels that support media deletion.
         * @param {boolean} [isFavorite] Optional. Filter by channels that are favorite.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getChannels(userId?: string, startIndex?: number, limit?: number, supportsLatestItems?: boolean, supportsMediaDeletion?: boolean, isFavorite?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BaseItemDtoQueryResult>> {
            const localVarAxiosArgs = await ChannelsApiAxiosParamCreator(configuration).getChannels(userId, startIndex, limit, supportsLatestItems, supportsMediaDeletion, isFavorite, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Gets latest channel items.
         * @param {string} [userId] Optional. User Id.
         * @param {number} [startIndex] Optional. The record index to start at. All items with a lower index will be dropped from the results.
         * @param {number} [limit] Optional. The maximum number of records to return.
         * @param {Array<ItemFilter>} [filters] Optional. Specify additional filters to apply.
         * @param {string} [fields] Optional. Specify additional fields of information to return in the output. This allows multiple, comma delimited. Options: Budget, Chapters, DateCreated, Genres, HomePageUrl, IndexOptions, MediaStreams, Overview, ParentId, Path, People, ProviderIds, PrimaryImageAspectRatio, Revenue, SortName, Studios, Taglines.
         * @param {string} [channelIds] Optional. Specify one or more channel id\&#39;s, comma delimited.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLatestChannelItems(userId?: string, startIndex?: number, limit?: number, filters?: Array<ItemFilter>, fields?: string, channelIds?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BaseItemDtoQueryResult>> {
            const localVarAxiosArgs = await ChannelsApiAxiosParamCreator(configuration).getLatestChannelItems(userId, startIndex, limit, filters, fields, channelIds, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * ChannelsApi - factory interface
 * @export
 */
export const ChannelsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Get all channel features.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllChannelFeatures(options?: any): AxiosPromise<Array<ChannelFeatures>> {
            return ChannelsApiFp(configuration).getAllChannelFeatures(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get channel features.
         * @param {string} channelId Channel id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChannelFeatures(channelId: string, options?: any): AxiosPromise<ChannelFeatures> {
            return ChannelsApiFp(configuration).getChannelFeatures(channelId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get channel items.
         * @param {string} channelId Channel Id.
         * @param {string} [folderId] Optional. Folder Id.
         * @param {string} [userId] Optional. User Id.
         * @param {number} [startIndex] Optional. The record index to start at. All items with a lower index will be dropped from the results.
         * @param {number} [limit] Optional. The maximum number of records to return.
         * @param {string} [sortOrder] Optional. Sort Order - Ascending,Descending.
         * @param {Array<ItemFilter>} [filters] Optional. Specify additional filters to apply.
         * @param {string} [sortBy] Optional. Specify one or more sort orders, comma delimited. Options: Album, AlbumArtist, Artist, Budget, CommunityRating, CriticRating, DateCreated, DatePlayed, PlayCount, PremiereDate, ProductionYear, SortName, Random, Revenue, Runtime.
         * @param {string} [fields] Optional. Specify additional fields of information to return in the output. This allows multiple, comma delimited. Options: Budget, Chapters, DateCreated, Genres, HomePageUrl, IndexOptions, MediaStreams, Overview, ParentId, Path, People, ProviderIds, PrimaryImageAspectRatio, Revenue, SortName, Studios, Taglines.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChannelItems(channelId: string, folderId?: string, userId?: string, startIndex?: number, limit?: number, sortOrder?: string, filters?: Array<ItemFilter>, sortBy?: string, fields?: string, options?: any): AxiosPromise<BaseItemDtoQueryResult> {
            return ChannelsApiFp(configuration).getChannelItems(channelId, folderId, userId, startIndex, limit, sortOrder, filters, sortBy, fields, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets available channels.
         * @param {string} [userId] User Id to filter by. Use System.Guid.Empty to not filter by user.
         * @param {number} [startIndex] Optional. The record index to start at. All items with a lower index will be dropped from the results.
         * @param {number} [limit] Optional. The maximum number of records to return.
         * @param {boolean} [supportsLatestItems] Optional. Filter by channels that support getting latest items.
         * @param {boolean} [supportsMediaDeletion] Optional. Filter by channels that support media deletion.
         * @param {boolean} [isFavorite] Optional. Filter by channels that are favorite.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChannels(userId?: string, startIndex?: number, limit?: number, supportsLatestItems?: boolean, supportsMediaDeletion?: boolean, isFavorite?: boolean, options?: any): AxiosPromise<BaseItemDtoQueryResult> {
            return ChannelsApiFp(configuration).getChannels(userId, startIndex, limit, supportsLatestItems, supportsMediaDeletion, isFavorite, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets latest channel items.
         * @param {string} [userId] Optional. User Id.
         * @param {number} [startIndex] Optional. The record index to start at. All items with a lower index will be dropped from the results.
         * @param {number} [limit] Optional. The maximum number of records to return.
         * @param {Array<ItemFilter>} [filters] Optional. Specify additional filters to apply.
         * @param {string} [fields] Optional. Specify additional fields of information to return in the output. This allows multiple, comma delimited. Options: Budget, Chapters, DateCreated, Genres, HomePageUrl, IndexOptions, MediaStreams, Overview, ParentId, Path, People, ProviderIds, PrimaryImageAspectRatio, Revenue, SortName, Studios, Taglines.
         * @param {string} [channelIds] Optional. Specify one or more channel id\&#39;s, comma delimited.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLatestChannelItems(userId?: string, startIndex?: number, limit?: number, filters?: Array<ItemFilter>, fields?: string, channelIds?: string, options?: any): AxiosPromise<BaseItemDtoQueryResult> {
            return ChannelsApiFp(configuration).getLatestChannelItems(userId, startIndex, limit, filters, fields, channelIds, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getChannelFeatures operation in ChannelsApi.
 * @export
 * @interface ChannelsApiGetChannelFeaturesRequest
 */
export interface ChannelsApiGetChannelFeaturesRequest {
    /**
     * Channel id.
     * @type {string}
     * @memberof ChannelsApiGetChannelFeatures
     */
    readonly channelId: string
}

/**
 * Request parameters for getChannelItems operation in ChannelsApi.
 * @export
 * @interface ChannelsApiGetChannelItemsRequest
 */
export interface ChannelsApiGetChannelItemsRequest {
    /**
     * Channel Id.
     * @type {string}
     * @memberof ChannelsApiGetChannelItems
     */
    readonly channelId: string

    /**
     * Optional. Folder Id.
     * @type {string}
     * @memberof ChannelsApiGetChannelItems
     */
    readonly folderId?: string

    /**
     * Optional. User Id.
     * @type {string}
     * @memberof ChannelsApiGetChannelItems
     */
    readonly userId?: string

    /**
     * Optional. The record index to start at. All items with a lower index will be dropped from the results.
     * @type {number}
     * @memberof ChannelsApiGetChannelItems
     */
    readonly startIndex?: number

    /**
     * Optional. The maximum number of records to return.
     * @type {number}
     * @memberof ChannelsApiGetChannelItems
     */
    readonly limit?: number

    /**
     * Optional. Sort Order - Ascending,Descending.
     * @type {string}
     * @memberof ChannelsApiGetChannelItems
     */
    readonly sortOrder?: string

    /**
     * Optional. Specify additional filters to apply.
     * @type {Array<ItemFilter>}
     * @memberof ChannelsApiGetChannelItems
     */
    readonly filters?: Array<ItemFilter>

    /**
     * Optional. Specify one or more sort orders, comma delimited. Options: Album, AlbumArtist, Artist, Budget, CommunityRating, CriticRating, DateCreated, DatePlayed, PlayCount, PremiereDate, ProductionYear, SortName, Random, Revenue, Runtime.
     * @type {string}
     * @memberof ChannelsApiGetChannelItems
     */
    readonly sortBy?: string

    /**
     * Optional. Specify additional fields of information to return in the output. This allows multiple, comma delimited. Options: Budget, Chapters, DateCreated, Genres, HomePageUrl, IndexOptions, MediaStreams, Overview, ParentId, Path, People, ProviderIds, PrimaryImageAspectRatio, Revenue, SortName, Studios, Taglines.
     * @type {string}
     * @memberof ChannelsApiGetChannelItems
     */
    readonly fields?: string
}

/**
 * Request parameters for getChannels operation in ChannelsApi.
 * @export
 * @interface ChannelsApiGetChannelsRequest
 */
export interface ChannelsApiGetChannelsRequest {
    /**
     * User Id to filter by. Use System.Guid.Empty to not filter by user.
     * @type {string}
     * @memberof ChannelsApiGetChannels
     */
    readonly userId?: string

    /**
     * Optional. The record index to start at. All items with a lower index will be dropped from the results.
     * @type {number}
     * @memberof ChannelsApiGetChannels
     */
    readonly startIndex?: number

    /**
     * Optional. The maximum number of records to return.
     * @type {number}
     * @memberof ChannelsApiGetChannels
     */
    readonly limit?: number

    /**
     * Optional. Filter by channels that support getting latest items.
     * @type {boolean}
     * @memberof ChannelsApiGetChannels
     */
    readonly supportsLatestItems?: boolean

    /**
     * Optional. Filter by channels that support media deletion.
     * @type {boolean}
     * @memberof ChannelsApiGetChannels
     */
    readonly supportsMediaDeletion?: boolean

    /**
     * Optional. Filter by channels that are favorite.
     * @type {boolean}
     * @memberof ChannelsApiGetChannels
     */
    readonly isFavorite?: boolean
}

/**
 * Request parameters for getLatestChannelItems operation in ChannelsApi.
 * @export
 * @interface ChannelsApiGetLatestChannelItemsRequest
 */
export interface ChannelsApiGetLatestChannelItemsRequest {
    /**
     * Optional. User Id.
     * @type {string}
     * @memberof ChannelsApiGetLatestChannelItems
     */
    readonly userId?: string

    /**
     * Optional. The record index to start at. All items with a lower index will be dropped from the results.
     * @type {number}
     * @memberof ChannelsApiGetLatestChannelItems
     */
    readonly startIndex?: number

    /**
     * Optional. The maximum number of records to return.
     * @type {number}
     * @memberof ChannelsApiGetLatestChannelItems
     */
    readonly limit?: number

    /**
     * Optional. Specify additional filters to apply.
     * @type {Array<ItemFilter>}
     * @memberof ChannelsApiGetLatestChannelItems
     */
    readonly filters?: Array<ItemFilter>

    /**
     * Optional. Specify additional fields of information to return in the output. This allows multiple, comma delimited. Options: Budget, Chapters, DateCreated, Genres, HomePageUrl, IndexOptions, MediaStreams, Overview, ParentId, Path, People, ProviderIds, PrimaryImageAspectRatio, Revenue, SortName, Studios, Taglines.
     * @type {string}
     * @memberof ChannelsApiGetLatestChannelItems
     */
    readonly fields?: string

    /**
     * Optional. Specify one or more channel id\&#39;s, comma delimited.
     * @type {string}
     * @memberof ChannelsApiGetLatestChannelItems
     */
    readonly channelIds?: string
}

/**
 * ChannelsApi - object-oriented interface
 * @export
 * @class ChannelsApi
 * @extends {BaseAPI}
 */
export class ChannelsApi extends BaseAPI {
    /**
     * 
     * @summary Get all channel features.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChannelsApi
     */
    public getAllChannelFeatures(options?: any) {
        return ChannelsApiFp(this.configuration).getAllChannelFeatures(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get channel features.
     * @param {ChannelsApiGetChannelFeaturesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChannelsApi
     */
    public getChannelFeatures(requestParameters: ChannelsApiGetChannelFeaturesRequest, options?: any) {
        return ChannelsApiFp(this.configuration).getChannelFeatures(requestParameters.channelId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get channel items.
     * @param {ChannelsApiGetChannelItemsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChannelsApi
     */
    public getChannelItems(requestParameters: ChannelsApiGetChannelItemsRequest, options?: any) {
        return ChannelsApiFp(this.configuration).getChannelItems(requestParameters.channelId, requestParameters.folderId, requestParameters.userId, requestParameters.startIndex, requestParameters.limit, requestParameters.sortOrder, requestParameters.filters, requestParameters.sortBy, requestParameters.fields, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets available channels.
     * @param {ChannelsApiGetChannelsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChannelsApi
     */
    public getChannels(requestParameters: ChannelsApiGetChannelsRequest = {}, options?: any) {
        return ChannelsApiFp(this.configuration).getChannels(requestParameters.userId, requestParameters.startIndex, requestParameters.limit, requestParameters.supportsLatestItems, requestParameters.supportsMediaDeletion, requestParameters.isFavorite, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets latest channel items.
     * @param {ChannelsApiGetLatestChannelItemsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChannelsApi
     */
    public getLatestChannelItems(requestParameters: ChannelsApiGetLatestChannelItemsRequest = {}, options?: any) {
        return ChannelsApiFp(this.configuration).getLatestChannelItems(requestParameters.userId, requestParameters.startIndex, requestParameters.limit, requestParameters.filters, requestParameters.fields, requestParameters.channelIds, options).then((request) => request(this.axios, this.basePath));
    }
}
