/* tslint:disable */
/* eslint-disable */
/**
 * Jellyfin API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { BaseItemDtoQueryResult } from '../models';
// @ts-ignore
import { ImageType } from '../models';
/**
 * InstantMixApi - axios parameter creator
 * @export
 */
export const InstantMixApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Creates an instant playlist based on a given song.
         * @param {string} id The item id.
         * @param {string} [userId] Optional. Filter by user id, and attach user data.
         * @param {number} [limit] Optional. The maximum number of records to return.
         * @param {string} [fields] Optional. Specify additional fields of information to return in the output. This allows multiple, comma delimeted. Options: Budget, Chapters, DateCreated, Genres, HomePageUrl, IndexOptions, MediaStreams, Overview, ParentId, Path, People, ProviderIds, PrimaryImageAspectRatio, Revenue, SortName, Studios, Taglines, TrailerUrls.
         * @param {boolean} [enableImages] Optional. Include image information in output.
         * @param {boolean} [enableUserData] Optional. Include user data.
         * @param {number} [imageTypeLimit] Optional. The max number of images to return, per image type.
         * @param {Array<ImageType>} [enableImageTypes] Optional. The image types to include in the output.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInstantMixFromAlbum: async (id: string, userId?: string, limit?: number, fields?: string, enableImages?: boolean, enableUserData?: boolean, imageTypeLimit?: number, enableImageTypes?: Array<ImageType>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getInstantMixFromAlbum.');
            }
            const localVarPath = `/Albums/{id}/InstantMix`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (fields !== undefined) {
                localVarQueryParameter['fields'] = fields;
            }

            if (enableImages !== undefined) {
                localVarQueryParameter['enableImages'] = enableImages;
            }

            if (enableUserData !== undefined) {
                localVarQueryParameter['enableUserData'] = enableUserData;
            }

            if (imageTypeLimit !== undefined) {
                localVarQueryParameter['imageTypeLimit'] = imageTypeLimit;
            }

            if (enableImageTypes) {
                localVarQueryParameter['enableImageTypes'] = enableImageTypes;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates an instant playlist based on a given song.
         * @param {string} id The item id.
         * @param {string} [userId] Optional. Filter by user id, and attach user data.
         * @param {number} [limit] Optional. The maximum number of records to return.
         * @param {string} [fields] Optional. Specify additional fields of information to return in the output. This allows multiple, comma delimeted. Options: Budget, Chapters, DateCreated, Genres, HomePageUrl, IndexOptions, MediaStreams, Overview, ParentId, Path, People, ProviderIds, PrimaryImageAspectRatio, Revenue, SortName, Studios, Taglines, TrailerUrls.
         * @param {boolean} [enableImages] Optional. Include image information in output.
         * @param {boolean} [enableUserData] Optional. Include user data.
         * @param {number} [imageTypeLimit] Optional. The max number of images to return, per image type.
         * @param {Array<ImageType>} [enableImageTypes] Optional. The image types to include in the output.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInstantMixFromArtists: async (id: string, userId?: string, limit?: number, fields?: string, enableImages?: boolean, enableUserData?: boolean, imageTypeLimit?: number, enableImageTypes?: Array<ImageType>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getInstantMixFromArtists.');
            }
            const localVarPath = `/Artists/InstantMix`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (fields !== undefined) {
                localVarQueryParameter['fields'] = fields;
            }

            if (enableImages !== undefined) {
                localVarQueryParameter['enableImages'] = enableImages;
            }

            if (enableUserData !== undefined) {
                localVarQueryParameter['enableUserData'] = enableUserData;
            }

            if (imageTypeLimit !== undefined) {
                localVarQueryParameter['imageTypeLimit'] = imageTypeLimit;
            }

            if (enableImageTypes) {
                localVarQueryParameter['enableImageTypes'] = enableImageTypes;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates an instant playlist based on a given song.
         * @param {string} id The item id.
         * @param {string} [userId] Optional. Filter by user id, and attach user data.
         * @param {number} [limit] Optional. The maximum number of records to return.
         * @param {string} [fields] Optional. Specify additional fields of information to return in the output. This allows multiple, comma delimeted. Options: Budget, Chapters, DateCreated, Genres, HomePageUrl, IndexOptions, MediaStreams, Overview, ParentId, Path, People, ProviderIds, PrimaryImageAspectRatio, Revenue, SortName, Studios, Taglines, TrailerUrls.
         * @param {boolean} [enableImages] Optional. Include image information in output.
         * @param {boolean} [enableUserData] Optional. Include user data.
         * @param {number} [imageTypeLimit] Optional. The max number of images to return, per image type.
         * @param {Array<ImageType>} [enableImageTypes] Optional. The image types to include in the output.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInstantMixFromItem: async (id: string, userId?: string, limit?: number, fields?: string, enableImages?: boolean, enableUserData?: boolean, imageTypeLimit?: number, enableImageTypes?: Array<ImageType>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getInstantMixFromItem.');
            }
            const localVarPath = `/Items/{id}/InstantMix`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (fields !== undefined) {
                localVarQueryParameter['fields'] = fields;
            }

            if (enableImages !== undefined) {
                localVarQueryParameter['enableImages'] = enableImages;
            }

            if (enableUserData !== undefined) {
                localVarQueryParameter['enableUserData'] = enableUserData;
            }

            if (imageTypeLimit !== undefined) {
                localVarQueryParameter['imageTypeLimit'] = imageTypeLimit;
            }

            if (enableImageTypes) {
                localVarQueryParameter['enableImageTypes'] = enableImageTypes;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates an instant playlist based on a given song.
         * @param {string} name The genre name.
         * @param {string} [userId] Optional. Filter by user id, and attach user data.
         * @param {number} [limit] Optional. The maximum number of records to return.
         * @param {string} [fields] Optional. Specify additional fields of information to return in the output. This allows multiple, comma delimeted. Options: Budget, Chapters, DateCreated, Genres, HomePageUrl, IndexOptions, MediaStreams, Overview, ParentId, Path, People, ProviderIds, PrimaryImageAspectRatio, Revenue, SortName, Studios, Taglines, TrailerUrls.
         * @param {boolean} [enableImages] Optional. Include image information in output.
         * @param {boolean} [enableUserData] Optional. Include user data.
         * @param {number} [imageTypeLimit] Optional. The max number of images to return, per image type.
         * @param {Array<ImageType>} [enableImageTypes] Optional. The image types to include in the output.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInstantMixFromMusicGenre: async (name: string, userId?: string, limit?: number, fields?: string, enableImages?: boolean, enableUserData?: boolean, imageTypeLimit?: number, enableImageTypes?: Array<ImageType>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            if (name === null || name === undefined) {
                throw new RequiredError('name','Required parameter name was null or undefined when calling getInstantMixFromMusicGenre.');
            }
            const localVarPath = `/MusicGenres/{name}/InstantMix`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (fields !== undefined) {
                localVarQueryParameter['fields'] = fields;
            }

            if (enableImages !== undefined) {
                localVarQueryParameter['enableImages'] = enableImages;
            }

            if (enableUserData !== undefined) {
                localVarQueryParameter['enableUserData'] = enableUserData;
            }

            if (imageTypeLimit !== undefined) {
                localVarQueryParameter['imageTypeLimit'] = imageTypeLimit;
            }

            if (enableImageTypes) {
                localVarQueryParameter['enableImageTypes'] = enableImageTypes;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates an instant playlist based on a given song.
         * @param {string} id The item id.
         * @param {string} [userId] Optional. Filter by user id, and attach user data.
         * @param {number} [limit] Optional. The maximum number of records to return.
         * @param {string} [fields] Optional. Specify additional fields of information to return in the output. This allows multiple, comma delimeted. Options: Budget, Chapters, DateCreated, Genres, HomePageUrl, IndexOptions, MediaStreams, Overview, ParentId, Path, People, ProviderIds, PrimaryImageAspectRatio, Revenue, SortName, Studios, Taglines, TrailerUrls.
         * @param {boolean} [enableImages] Optional. Include image information in output.
         * @param {boolean} [enableUserData] Optional. Include user data.
         * @param {number} [imageTypeLimit] Optional. The max number of images to return, per image type.
         * @param {Array<ImageType>} [enableImageTypes] Optional. The image types to include in the output.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInstantMixFromMusicGenres: async (id: string, userId?: string, limit?: number, fields?: string, enableImages?: boolean, enableUserData?: boolean, imageTypeLimit?: number, enableImageTypes?: Array<ImageType>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getInstantMixFromMusicGenres.');
            }
            const localVarPath = `/MusicGenres/InstantMix`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (fields !== undefined) {
                localVarQueryParameter['fields'] = fields;
            }

            if (enableImages !== undefined) {
                localVarQueryParameter['enableImages'] = enableImages;
            }

            if (enableUserData !== undefined) {
                localVarQueryParameter['enableUserData'] = enableUserData;
            }

            if (imageTypeLimit !== undefined) {
                localVarQueryParameter['imageTypeLimit'] = imageTypeLimit;
            }

            if (enableImageTypes) {
                localVarQueryParameter['enableImageTypes'] = enableImageTypes;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates an instant playlist based on a given song.
         * @param {string} id The item id.
         * @param {string} [userId] Optional. Filter by user id, and attach user data.
         * @param {number} [limit] Optional. The maximum number of records to return.
         * @param {string} [fields] Optional. Specify additional fields of information to return in the output. This allows multiple, comma delimeted. Options: Budget, Chapters, DateCreated, Genres, HomePageUrl, IndexOptions, MediaStreams, Overview, ParentId, Path, People, ProviderIds, PrimaryImageAspectRatio, Revenue, SortName, Studios, Taglines, TrailerUrls.
         * @param {boolean} [enableImages] Optional. Include image information in output.
         * @param {boolean} [enableUserData] Optional. Include user data.
         * @param {number} [imageTypeLimit] Optional. The max number of images to return, per image type.
         * @param {Array<ImageType>} [enableImageTypes] Optional. The image types to include in the output.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInstantMixFromPlaylist: async (id: string, userId?: string, limit?: number, fields?: string, enableImages?: boolean, enableUserData?: boolean, imageTypeLimit?: number, enableImageTypes?: Array<ImageType>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getInstantMixFromPlaylist.');
            }
            const localVarPath = `/Playlists/{id}/InstantMix`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (fields !== undefined) {
                localVarQueryParameter['fields'] = fields;
            }

            if (enableImages !== undefined) {
                localVarQueryParameter['enableImages'] = enableImages;
            }

            if (enableUserData !== undefined) {
                localVarQueryParameter['enableUserData'] = enableUserData;
            }

            if (imageTypeLimit !== undefined) {
                localVarQueryParameter['imageTypeLimit'] = imageTypeLimit;
            }

            if (enableImageTypes) {
                localVarQueryParameter['enableImageTypes'] = enableImageTypes;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates an instant playlist based on a given song.
         * @param {string} id The item id.
         * @param {string} [userId] Optional. Filter by user id, and attach user data.
         * @param {number} [limit] Optional. The maximum number of records to return.
         * @param {string} [fields] Optional. Specify additional fields of information to return in the output. This allows multiple, comma delimeted. Options: Budget, Chapters, DateCreated, Genres, HomePageUrl, IndexOptions, MediaStreams, Overview, ParentId, Path, People, ProviderIds, PrimaryImageAspectRatio, Revenue, SortName, Studios, Taglines, TrailerUrls.
         * @param {boolean} [enableImages] Optional. Include image information in output.
         * @param {boolean} [enableUserData] Optional. Include user data.
         * @param {number} [imageTypeLimit] Optional. The max number of images to return, per image type.
         * @param {Array<ImageType>} [enableImageTypes] Optional. The image types to include in the output.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInstantMixFromSong: async (id: string, userId?: string, limit?: number, fields?: string, enableImages?: boolean, enableUserData?: boolean, imageTypeLimit?: number, enableImageTypes?: Array<ImageType>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getInstantMixFromSong.');
            }
            const localVarPath = `/Songs/{id}/InstantMix`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (fields !== undefined) {
                localVarQueryParameter['fields'] = fields;
            }

            if (enableImages !== undefined) {
                localVarQueryParameter['enableImages'] = enableImages;
            }

            if (enableUserData !== undefined) {
                localVarQueryParameter['enableUserData'] = enableUserData;
            }

            if (imageTypeLimit !== undefined) {
                localVarQueryParameter['imageTypeLimit'] = imageTypeLimit;
            }

            if (enableImageTypes) {
                localVarQueryParameter['enableImageTypes'] = enableImageTypes;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InstantMixApi - functional programming interface
 * @export
 */
export const InstantMixApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Creates an instant playlist based on a given song.
         * @param {string} id The item id.
         * @param {string} [userId] Optional. Filter by user id, and attach user data.
         * @param {number} [limit] Optional. The maximum number of records to return.
         * @param {string} [fields] Optional. Specify additional fields of information to return in the output. This allows multiple, comma delimeted. Options: Budget, Chapters, DateCreated, Genres, HomePageUrl, IndexOptions, MediaStreams, Overview, ParentId, Path, People, ProviderIds, PrimaryImageAspectRatio, Revenue, SortName, Studios, Taglines, TrailerUrls.
         * @param {boolean} [enableImages] Optional. Include image information in output.
         * @param {boolean} [enableUserData] Optional. Include user data.
         * @param {number} [imageTypeLimit] Optional. The max number of images to return, per image type.
         * @param {Array<ImageType>} [enableImageTypes] Optional. The image types to include in the output.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInstantMixFromAlbum(id: string, userId?: string, limit?: number, fields?: string, enableImages?: boolean, enableUserData?: boolean, imageTypeLimit?: number, enableImageTypes?: Array<ImageType>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BaseItemDtoQueryResult>> {
            const localVarAxiosArgs = await InstantMixApiAxiosParamCreator(configuration).getInstantMixFromAlbum(id, userId, limit, fields, enableImages, enableUserData, imageTypeLimit, enableImageTypes, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Creates an instant playlist based on a given song.
         * @param {string} id The item id.
         * @param {string} [userId] Optional. Filter by user id, and attach user data.
         * @param {number} [limit] Optional. The maximum number of records to return.
         * @param {string} [fields] Optional. Specify additional fields of information to return in the output. This allows multiple, comma delimeted. Options: Budget, Chapters, DateCreated, Genres, HomePageUrl, IndexOptions, MediaStreams, Overview, ParentId, Path, People, ProviderIds, PrimaryImageAspectRatio, Revenue, SortName, Studios, Taglines, TrailerUrls.
         * @param {boolean} [enableImages] Optional. Include image information in output.
         * @param {boolean} [enableUserData] Optional. Include user data.
         * @param {number} [imageTypeLimit] Optional. The max number of images to return, per image type.
         * @param {Array<ImageType>} [enableImageTypes] Optional. The image types to include in the output.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInstantMixFromArtists(id: string, userId?: string, limit?: number, fields?: string, enableImages?: boolean, enableUserData?: boolean, imageTypeLimit?: number, enableImageTypes?: Array<ImageType>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BaseItemDtoQueryResult>> {
            const localVarAxiosArgs = await InstantMixApiAxiosParamCreator(configuration).getInstantMixFromArtists(id, userId, limit, fields, enableImages, enableUserData, imageTypeLimit, enableImageTypes, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Creates an instant playlist based on a given song.
         * @param {string} id The item id.
         * @param {string} [userId] Optional. Filter by user id, and attach user data.
         * @param {number} [limit] Optional. The maximum number of records to return.
         * @param {string} [fields] Optional. Specify additional fields of information to return in the output. This allows multiple, comma delimeted. Options: Budget, Chapters, DateCreated, Genres, HomePageUrl, IndexOptions, MediaStreams, Overview, ParentId, Path, People, ProviderIds, PrimaryImageAspectRatio, Revenue, SortName, Studios, Taglines, TrailerUrls.
         * @param {boolean} [enableImages] Optional. Include image information in output.
         * @param {boolean} [enableUserData] Optional. Include user data.
         * @param {number} [imageTypeLimit] Optional. The max number of images to return, per image type.
         * @param {Array<ImageType>} [enableImageTypes] Optional. The image types to include in the output.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInstantMixFromItem(id: string, userId?: string, limit?: number, fields?: string, enableImages?: boolean, enableUserData?: boolean, imageTypeLimit?: number, enableImageTypes?: Array<ImageType>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BaseItemDtoQueryResult>> {
            const localVarAxiosArgs = await InstantMixApiAxiosParamCreator(configuration).getInstantMixFromItem(id, userId, limit, fields, enableImages, enableUserData, imageTypeLimit, enableImageTypes, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Creates an instant playlist based on a given song.
         * @param {string} name The genre name.
         * @param {string} [userId] Optional. Filter by user id, and attach user data.
         * @param {number} [limit] Optional. The maximum number of records to return.
         * @param {string} [fields] Optional. Specify additional fields of information to return in the output. This allows multiple, comma delimeted. Options: Budget, Chapters, DateCreated, Genres, HomePageUrl, IndexOptions, MediaStreams, Overview, ParentId, Path, People, ProviderIds, PrimaryImageAspectRatio, Revenue, SortName, Studios, Taglines, TrailerUrls.
         * @param {boolean} [enableImages] Optional. Include image information in output.
         * @param {boolean} [enableUserData] Optional. Include user data.
         * @param {number} [imageTypeLimit] Optional. The max number of images to return, per image type.
         * @param {Array<ImageType>} [enableImageTypes] Optional. The image types to include in the output.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInstantMixFromMusicGenre(name: string, userId?: string, limit?: number, fields?: string, enableImages?: boolean, enableUserData?: boolean, imageTypeLimit?: number, enableImageTypes?: Array<ImageType>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BaseItemDtoQueryResult>> {
            const localVarAxiosArgs = await InstantMixApiAxiosParamCreator(configuration).getInstantMixFromMusicGenre(name, userId, limit, fields, enableImages, enableUserData, imageTypeLimit, enableImageTypes, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Creates an instant playlist based on a given song.
         * @param {string} id The item id.
         * @param {string} [userId] Optional. Filter by user id, and attach user data.
         * @param {number} [limit] Optional. The maximum number of records to return.
         * @param {string} [fields] Optional. Specify additional fields of information to return in the output. This allows multiple, comma delimeted. Options: Budget, Chapters, DateCreated, Genres, HomePageUrl, IndexOptions, MediaStreams, Overview, ParentId, Path, People, ProviderIds, PrimaryImageAspectRatio, Revenue, SortName, Studios, Taglines, TrailerUrls.
         * @param {boolean} [enableImages] Optional. Include image information in output.
         * @param {boolean} [enableUserData] Optional. Include user data.
         * @param {number} [imageTypeLimit] Optional. The max number of images to return, per image type.
         * @param {Array<ImageType>} [enableImageTypes] Optional. The image types to include in the output.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInstantMixFromMusicGenres(id: string, userId?: string, limit?: number, fields?: string, enableImages?: boolean, enableUserData?: boolean, imageTypeLimit?: number, enableImageTypes?: Array<ImageType>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BaseItemDtoQueryResult>> {
            const localVarAxiosArgs = await InstantMixApiAxiosParamCreator(configuration).getInstantMixFromMusicGenres(id, userId, limit, fields, enableImages, enableUserData, imageTypeLimit, enableImageTypes, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Creates an instant playlist based on a given song.
         * @param {string} id The item id.
         * @param {string} [userId] Optional. Filter by user id, and attach user data.
         * @param {number} [limit] Optional. The maximum number of records to return.
         * @param {string} [fields] Optional. Specify additional fields of information to return in the output. This allows multiple, comma delimeted. Options: Budget, Chapters, DateCreated, Genres, HomePageUrl, IndexOptions, MediaStreams, Overview, ParentId, Path, People, ProviderIds, PrimaryImageAspectRatio, Revenue, SortName, Studios, Taglines, TrailerUrls.
         * @param {boolean} [enableImages] Optional. Include image information in output.
         * @param {boolean} [enableUserData] Optional. Include user data.
         * @param {number} [imageTypeLimit] Optional. The max number of images to return, per image type.
         * @param {Array<ImageType>} [enableImageTypes] Optional. The image types to include in the output.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInstantMixFromPlaylist(id: string, userId?: string, limit?: number, fields?: string, enableImages?: boolean, enableUserData?: boolean, imageTypeLimit?: number, enableImageTypes?: Array<ImageType>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BaseItemDtoQueryResult>> {
            const localVarAxiosArgs = await InstantMixApiAxiosParamCreator(configuration).getInstantMixFromPlaylist(id, userId, limit, fields, enableImages, enableUserData, imageTypeLimit, enableImageTypes, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Creates an instant playlist based on a given song.
         * @param {string} id The item id.
         * @param {string} [userId] Optional. Filter by user id, and attach user data.
         * @param {number} [limit] Optional. The maximum number of records to return.
         * @param {string} [fields] Optional. Specify additional fields of information to return in the output. This allows multiple, comma delimeted. Options: Budget, Chapters, DateCreated, Genres, HomePageUrl, IndexOptions, MediaStreams, Overview, ParentId, Path, People, ProviderIds, PrimaryImageAspectRatio, Revenue, SortName, Studios, Taglines, TrailerUrls.
         * @param {boolean} [enableImages] Optional. Include image information in output.
         * @param {boolean} [enableUserData] Optional. Include user data.
         * @param {number} [imageTypeLimit] Optional. The max number of images to return, per image type.
         * @param {Array<ImageType>} [enableImageTypes] Optional. The image types to include in the output.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInstantMixFromSong(id: string, userId?: string, limit?: number, fields?: string, enableImages?: boolean, enableUserData?: boolean, imageTypeLimit?: number, enableImageTypes?: Array<ImageType>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BaseItemDtoQueryResult>> {
            const localVarAxiosArgs = await InstantMixApiAxiosParamCreator(configuration).getInstantMixFromSong(id, userId, limit, fields, enableImages, enableUserData, imageTypeLimit, enableImageTypes, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * InstantMixApi - factory interface
 * @export
 */
export const InstantMixApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Creates an instant playlist based on a given song.
         * @param {string} id The item id.
         * @param {string} [userId] Optional. Filter by user id, and attach user data.
         * @param {number} [limit] Optional. The maximum number of records to return.
         * @param {string} [fields] Optional. Specify additional fields of information to return in the output. This allows multiple, comma delimeted. Options: Budget, Chapters, DateCreated, Genres, HomePageUrl, IndexOptions, MediaStreams, Overview, ParentId, Path, People, ProviderIds, PrimaryImageAspectRatio, Revenue, SortName, Studios, Taglines, TrailerUrls.
         * @param {boolean} [enableImages] Optional. Include image information in output.
         * @param {boolean} [enableUserData] Optional. Include user data.
         * @param {number} [imageTypeLimit] Optional. The max number of images to return, per image type.
         * @param {Array<ImageType>} [enableImageTypes] Optional. The image types to include in the output.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInstantMixFromAlbum(id: string, userId?: string, limit?: number, fields?: string, enableImages?: boolean, enableUserData?: boolean, imageTypeLimit?: number, enableImageTypes?: Array<ImageType>, options?: any): AxiosPromise<BaseItemDtoQueryResult> {
            return InstantMixApiFp(configuration).getInstantMixFromAlbum(id, userId, limit, fields, enableImages, enableUserData, imageTypeLimit, enableImageTypes, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates an instant playlist based on a given song.
         * @param {string} id The item id.
         * @param {string} [userId] Optional. Filter by user id, and attach user data.
         * @param {number} [limit] Optional. The maximum number of records to return.
         * @param {string} [fields] Optional. Specify additional fields of information to return in the output. This allows multiple, comma delimeted. Options: Budget, Chapters, DateCreated, Genres, HomePageUrl, IndexOptions, MediaStreams, Overview, ParentId, Path, People, ProviderIds, PrimaryImageAspectRatio, Revenue, SortName, Studios, Taglines, TrailerUrls.
         * @param {boolean} [enableImages] Optional. Include image information in output.
         * @param {boolean} [enableUserData] Optional. Include user data.
         * @param {number} [imageTypeLimit] Optional. The max number of images to return, per image type.
         * @param {Array<ImageType>} [enableImageTypes] Optional. The image types to include in the output.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInstantMixFromArtists(id: string, userId?: string, limit?: number, fields?: string, enableImages?: boolean, enableUserData?: boolean, imageTypeLimit?: number, enableImageTypes?: Array<ImageType>, options?: any): AxiosPromise<BaseItemDtoQueryResult> {
            return InstantMixApiFp(configuration).getInstantMixFromArtists(id, userId, limit, fields, enableImages, enableUserData, imageTypeLimit, enableImageTypes, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates an instant playlist based on a given song.
         * @param {string} id The item id.
         * @param {string} [userId] Optional. Filter by user id, and attach user data.
         * @param {number} [limit] Optional. The maximum number of records to return.
         * @param {string} [fields] Optional. Specify additional fields of information to return in the output. This allows multiple, comma delimeted. Options: Budget, Chapters, DateCreated, Genres, HomePageUrl, IndexOptions, MediaStreams, Overview, ParentId, Path, People, ProviderIds, PrimaryImageAspectRatio, Revenue, SortName, Studios, Taglines, TrailerUrls.
         * @param {boolean} [enableImages] Optional. Include image information in output.
         * @param {boolean} [enableUserData] Optional. Include user data.
         * @param {number} [imageTypeLimit] Optional. The max number of images to return, per image type.
         * @param {Array<ImageType>} [enableImageTypes] Optional. The image types to include in the output.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInstantMixFromItem(id: string, userId?: string, limit?: number, fields?: string, enableImages?: boolean, enableUserData?: boolean, imageTypeLimit?: number, enableImageTypes?: Array<ImageType>, options?: any): AxiosPromise<BaseItemDtoQueryResult> {
            return InstantMixApiFp(configuration).getInstantMixFromItem(id, userId, limit, fields, enableImages, enableUserData, imageTypeLimit, enableImageTypes, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates an instant playlist based on a given song.
         * @param {string} name The genre name.
         * @param {string} [userId] Optional. Filter by user id, and attach user data.
         * @param {number} [limit] Optional. The maximum number of records to return.
         * @param {string} [fields] Optional. Specify additional fields of information to return in the output. This allows multiple, comma delimeted. Options: Budget, Chapters, DateCreated, Genres, HomePageUrl, IndexOptions, MediaStreams, Overview, ParentId, Path, People, ProviderIds, PrimaryImageAspectRatio, Revenue, SortName, Studios, Taglines, TrailerUrls.
         * @param {boolean} [enableImages] Optional. Include image information in output.
         * @param {boolean} [enableUserData] Optional. Include user data.
         * @param {number} [imageTypeLimit] Optional. The max number of images to return, per image type.
         * @param {Array<ImageType>} [enableImageTypes] Optional. The image types to include in the output.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInstantMixFromMusicGenre(name: string, userId?: string, limit?: number, fields?: string, enableImages?: boolean, enableUserData?: boolean, imageTypeLimit?: number, enableImageTypes?: Array<ImageType>, options?: any): AxiosPromise<BaseItemDtoQueryResult> {
            return InstantMixApiFp(configuration).getInstantMixFromMusicGenre(name, userId, limit, fields, enableImages, enableUserData, imageTypeLimit, enableImageTypes, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates an instant playlist based on a given song.
         * @param {string} id The item id.
         * @param {string} [userId] Optional. Filter by user id, and attach user data.
         * @param {number} [limit] Optional. The maximum number of records to return.
         * @param {string} [fields] Optional. Specify additional fields of information to return in the output. This allows multiple, comma delimeted. Options: Budget, Chapters, DateCreated, Genres, HomePageUrl, IndexOptions, MediaStreams, Overview, ParentId, Path, People, ProviderIds, PrimaryImageAspectRatio, Revenue, SortName, Studios, Taglines, TrailerUrls.
         * @param {boolean} [enableImages] Optional. Include image information in output.
         * @param {boolean} [enableUserData] Optional. Include user data.
         * @param {number} [imageTypeLimit] Optional. The max number of images to return, per image type.
         * @param {Array<ImageType>} [enableImageTypes] Optional. The image types to include in the output.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInstantMixFromMusicGenres(id: string, userId?: string, limit?: number, fields?: string, enableImages?: boolean, enableUserData?: boolean, imageTypeLimit?: number, enableImageTypes?: Array<ImageType>, options?: any): AxiosPromise<BaseItemDtoQueryResult> {
            return InstantMixApiFp(configuration).getInstantMixFromMusicGenres(id, userId, limit, fields, enableImages, enableUserData, imageTypeLimit, enableImageTypes, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates an instant playlist based on a given song.
         * @param {string} id The item id.
         * @param {string} [userId] Optional. Filter by user id, and attach user data.
         * @param {number} [limit] Optional. The maximum number of records to return.
         * @param {string} [fields] Optional. Specify additional fields of information to return in the output. This allows multiple, comma delimeted. Options: Budget, Chapters, DateCreated, Genres, HomePageUrl, IndexOptions, MediaStreams, Overview, ParentId, Path, People, ProviderIds, PrimaryImageAspectRatio, Revenue, SortName, Studios, Taglines, TrailerUrls.
         * @param {boolean} [enableImages] Optional. Include image information in output.
         * @param {boolean} [enableUserData] Optional. Include user data.
         * @param {number} [imageTypeLimit] Optional. The max number of images to return, per image type.
         * @param {Array<ImageType>} [enableImageTypes] Optional. The image types to include in the output.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInstantMixFromPlaylist(id: string, userId?: string, limit?: number, fields?: string, enableImages?: boolean, enableUserData?: boolean, imageTypeLimit?: number, enableImageTypes?: Array<ImageType>, options?: any): AxiosPromise<BaseItemDtoQueryResult> {
            return InstantMixApiFp(configuration).getInstantMixFromPlaylist(id, userId, limit, fields, enableImages, enableUserData, imageTypeLimit, enableImageTypes, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates an instant playlist based on a given song.
         * @param {string} id The item id.
         * @param {string} [userId] Optional. Filter by user id, and attach user data.
         * @param {number} [limit] Optional. The maximum number of records to return.
         * @param {string} [fields] Optional. Specify additional fields of information to return in the output. This allows multiple, comma delimeted. Options: Budget, Chapters, DateCreated, Genres, HomePageUrl, IndexOptions, MediaStreams, Overview, ParentId, Path, People, ProviderIds, PrimaryImageAspectRatio, Revenue, SortName, Studios, Taglines, TrailerUrls.
         * @param {boolean} [enableImages] Optional. Include image information in output.
         * @param {boolean} [enableUserData] Optional. Include user data.
         * @param {number} [imageTypeLimit] Optional. The max number of images to return, per image type.
         * @param {Array<ImageType>} [enableImageTypes] Optional. The image types to include in the output.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInstantMixFromSong(id: string, userId?: string, limit?: number, fields?: string, enableImages?: boolean, enableUserData?: boolean, imageTypeLimit?: number, enableImageTypes?: Array<ImageType>, options?: any): AxiosPromise<BaseItemDtoQueryResult> {
            return InstantMixApiFp(configuration).getInstantMixFromSong(id, userId, limit, fields, enableImages, enableUserData, imageTypeLimit, enableImageTypes, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getInstantMixFromAlbum operation in InstantMixApi.
 * @export
 * @interface InstantMixApiGetInstantMixFromAlbumRequest
 */
export interface InstantMixApiGetInstantMixFromAlbumRequest {
    /**
     * The item id.
     * @type {string}
     * @memberof InstantMixApiGetInstantMixFromAlbum
     */
    readonly id: string

    /**
     * Optional. Filter by user id, and attach user data.
     * @type {string}
     * @memberof InstantMixApiGetInstantMixFromAlbum
     */
    readonly userId?: string

    /**
     * Optional. The maximum number of records to return.
     * @type {number}
     * @memberof InstantMixApiGetInstantMixFromAlbum
     */
    readonly limit?: number

    /**
     * Optional. Specify additional fields of information to return in the output. This allows multiple, comma delimeted. Options: Budget, Chapters, DateCreated, Genres, HomePageUrl, IndexOptions, MediaStreams, Overview, ParentId, Path, People, ProviderIds, PrimaryImageAspectRatio, Revenue, SortName, Studios, Taglines, TrailerUrls.
     * @type {string}
     * @memberof InstantMixApiGetInstantMixFromAlbum
     */
    readonly fields?: string

    /**
     * Optional. Include image information in output.
     * @type {boolean}
     * @memberof InstantMixApiGetInstantMixFromAlbum
     */
    readonly enableImages?: boolean

    /**
     * Optional. Include user data.
     * @type {boolean}
     * @memberof InstantMixApiGetInstantMixFromAlbum
     */
    readonly enableUserData?: boolean

    /**
     * Optional. The max number of images to return, per image type.
     * @type {number}
     * @memberof InstantMixApiGetInstantMixFromAlbum
     */
    readonly imageTypeLimit?: number

    /**
     * Optional. The image types to include in the output.
     * @type {Array<ImageType>}
     * @memberof InstantMixApiGetInstantMixFromAlbum
     */
    readonly enableImageTypes?: Array<ImageType>
}

/**
 * Request parameters for getInstantMixFromArtists operation in InstantMixApi.
 * @export
 * @interface InstantMixApiGetInstantMixFromArtistsRequest
 */
export interface InstantMixApiGetInstantMixFromArtistsRequest {
    /**
     * The item id.
     * @type {string}
     * @memberof InstantMixApiGetInstantMixFromArtists
     */
    readonly id: string

    /**
     * Optional. Filter by user id, and attach user data.
     * @type {string}
     * @memberof InstantMixApiGetInstantMixFromArtists
     */
    readonly userId?: string

    /**
     * Optional. The maximum number of records to return.
     * @type {number}
     * @memberof InstantMixApiGetInstantMixFromArtists
     */
    readonly limit?: number

    /**
     * Optional. Specify additional fields of information to return in the output. This allows multiple, comma delimeted. Options: Budget, Chapters, DateCreated, Genres, HomePageUrl, IndexOptions, MediaStreams, Overview, ParentId, Path, People, ProviderIds, PrimaryImageAspectRatio, Revenue, SortName, Studios, Taglines, TrailerUrls.
     * @type {string}
     * @memberof InstantMixApiGetInstantMixFromArtists
     */
    readonly fields?: string

    /**
     * Optional. Include image information in output.
     * @type {boolean}
     * @memberof InstantMixApiGetInstantMixFromArtists
     */
    readonly enableImages?: boolean

    /**
     * Optional. Include user data.
     * @type {boolean}
     * @memberof InstantMixApiGetInstantMixFromArtists
     */
    readonly enableUserData?: boolean

    /**
     * Optional. The max number of images to return, per image type.
     * @type {number}
     * @memberof InstantMixApiGetInstantMixFromArtists
     */
    readonly imageTypeLimit?: number

    /**
     * Optional. The image types to include in the output.
     * @type {Array<ImageType>}
     * @memberof InstantMixApiGetInstantMixFromArtists
     */
    readonly enableImageTypes?: Array<ImageType>
}

/**
 * Request parameters for getInstantMixFromItem operation in InstantMixApi.
 * @export
 * @interface InstantMixApiGetInstantMixFromItemRequest
 */
export interface InstantMixApiGetInstantMixFromItemRequest {
    /**
     * The item id.
     * @type {string}
     * @memberof InstantMixApiGetInstantMixFromItem
     */
    readonly id: string

    /**
     * Optional. Filter by user id, and attach user data.
     * @type {string}
     * @memberof InstantMixApiGetInstantMixFromItem
     */
    readonly userId?: string

    /**
     * Optional. The maximum number of records to return.
     * @type {number}
     * @memberof InstantMixApiGetInstantMixFromItem
     */
    readonly limit?: number

    /**
     * Optional. Specify additional fields of information to return in the output. This allows multiple, comma delimeted. Options: Budget, Chapters, DateCreated, Genres, HomePageUrl, IndexOptions, MediaStreams, Overview, ParentId, Path, People, ProviderIds, PrimaryImageAspectRatio, Revenue, SortName, Studios, Taglines, TrailerUrls.
     * @type {string}
     * @memberof InstantMixApiGetInstantMixFromItem
     */
    readonly fields?: string

    /**
     * Optional. Include image information in output.
     * @type {boolean}
     * @memberof InstantMixApiGetInstantMixFromItem
     */
    readonly enableImages?: boolean

    /**
     * Optional. Include user data.
     * @type {boolean}
     * @memberof InstantMixApiGetInstantMixFromItem
     */
    readonly enableUserData?: boolean

    /**
     * Optional. The max number of images to return, per image type.
     * @type {number}
     * @memberof InstantMixApiGetInstantMixFromItem
     */
    readonly imageTypeLimit?: number

    /**
     * Optional. The image types to include in the output.
     * @type {Array<ImageType>}
     * @memberof InstantMixApiGetInstantMixFromItem
     */
    readonly enableImageTypes?: Array<ImageType>
}

/**
 * Request parameters for getInstantMixFromMusicGenre operation in InstantMixApi.
 * @export
 * @interface InstantMixApiGetInstantMixFromMusicGenreRequest
 */
export interface InstantMixApiGetInstantMixFromMusicGenreRequest {
    /**
     * The genre name.
     * @type {string}
     * @memberof InstantMixApiGetInstantMixFromMusicGenre
     */
    readonly name: string

    /**
     * Optional. Filter by user id, and attach user data.
     * @type {string}
     * @memberof InstantMixApiGetInstantMixFromMusicGenre
     */
    readonly userId?: string

    /**
     * Optional. The maximum number of records to return.
     * @type {number}
     * @memberof InstantMixApiGetInstantMixFromMusicGenre
     */
    readonly limit?: number

    /**
     * Optional. Specify additional fields of information to return in the output. This allows multiple, comma delimeted. Options: Budget, Chapters, DateCreated, Genres, HomePageUrl, IndexOptions, MediaStreams, Overview, ParentId, Path, People, ProviderIds, PrimaryImageAspectRatio, Revenue, SortName, Studios, Taglines, TrailerUrls.
     * @type {string}
     * @memberof InstantMixApiGetInstantMixFromMusicGenre
     */
    readonly fields?: string

    /**
     * Optional. Include image information in output.
     * @type {boolean}
     * @memberof InstantMixApiGetInstantMixFromMusicGenre
     */
    readonly enableImages?: boolean

    /**
     * Optional. Include user data.
     * @type {boolean}
     * @memberof InstantMixApiGetInstantMixFromMusicGenre
     */
    readonly enableUserData?: boolean

    /**
     * Optional. The max number of images to return, per image type.
     * @type {number}
     * @memberof InstantMixApiGetInstantMixFromMusicGenre
     */
    readonly imageTypeLimit?: number

    /**
     * Optional. The image types to include in the output.
     * @type {Array<ImageType>}
     * @memberof InstantMixApiGetInstantMixFromMusicGenre
     */
    readonly enableImageTypes?: Array<ImageType>
}

/**
 * Request parameters for getInstantMixFromMusicGenres operation in InstantMixApi.
 * @export
 * @interface InstantMixApiGetInstantMixFromMusicGenresRequest
 */
export interface InstantMixApiGetInstantMixFromMusicGenresRequest {
    /**
     * The item id.
     * @type {string}
     * @memberof InstantMixApiGetInstantMixFromMusicGenres
     */
    readonly id: string

    /**
     * Optional. Filter by user id, and attach user data.
     * @type {string}
     * @memberof InstantMixApiGetInstantMixFromMusicGenres
     */
    readonly userId?: string

    /**
     * Optional. The maximum number of records to return.
     * @type {number}
     * @memberof InstantMixApiGetInstantMixFromMusicGenres
     */
    readonly limit?: number

    /**
     * Optional. Specify additional fields of information to return in the output. This allows multiple, comma delimeted. Options: Budget, Chapters, DateCreated, Genres, HomePageUrl, IndexOptions, MediaStreams, Overview, ParentId, Path, People, ProviderIds, PrimaryImageAspectRatio, Revenue, SortName, Studios, Taglines, TrailerUrls.
     * @type {string}
     * @memberof InstantMixApiGetInstantMixFromMusicGenres
     */
    readonly fields?: string

    /**
     * Optional. Include image information in output.
     * @type {boolean}
     * @memberof InstantMixApiGetInstantMixFromMusicGenres
     */
    readonly enableImages?: boolean

    /**
     * Optional. Include user data.
     * @type {boolean}
     * @memberof InstantMixApiGetInstantMixFromMusicGenres
     */
    readonly enableUserData?: boolean

    /**
     * Optional. The max number of images to return, per image type.
     * @type {number}
     * @memberof InstantMixApiGetInstantMixFromMusicGenres
     */
    readonly imageTypeLimit?: number

    /**
     * Optional. The image types to include in the output.
     * @type {Array<ImageType>}
     * @memberof InstantMixApiGetInstantMixFromMusicGenres
     */
    readonly enableImageTypes?: Array<ImageType>
}

/**
 * Request parameters for getInstantMixFromPlaylist operation in InstantMixApi.
 * @export
 * @interface InstantMixApiGetInstantMixFromPlaylistRequest
 */
export interface InstantMixApiGetInstantMixFromPlaylistRequest {
    /**
     * The item id.
     * @type {string}
     * @memberof InstantMixApiGetInstantMixFromPlaylist
     */
    readonly id: string

    /**
     * Optional. Filter by user id, and attach user data.
     * @type {string}
     * @memberof InstantMixApiGetInstantMixFromPlaylist
     */
    readonly userId?: string

    /**
     * Optional. The maximum number of records to return.
     * @type {number}
     * @memberof InstantMixApiGetInstantMixFromPlaylist
     */
    readonly limit?: number

    /**
     * Optional. Specify additional fields of information to return in the output. This allows multiple, comma delimeted. Options: Budget, Chapters, DateCreated, Genres, HomePageUrl, IndexOptions, MediaStreams, Overview, ParentId, Path, People, ProviderIds, PrimaryImageAspectRatio, Revenue, SortName, Studios, Taglines, TrailerUrls.
     * @type {string}
     * @memberof InstantMixApiGetInstantMixFromPlaylist
     */
    readonly fields?: string

    /**
     * Optional. Include image information in output.
     * @type {boolean}
     * @memberof InstantMixApiGetInstantMixFromPlaylist
     */
    readonly enableImages?: boolean

    /**
     * Optional. Include user data.
     * @type {boolean}
     * @memberof InstantMixApiGetInstantMixFromPlaylist
     */
    readonly enableUserData?: boolean

    /**
     * Optional. The max number of images to return, per image type.
     * @type {number}
     * @memberof InstantMixApiGetInstantMixFromPlaylist
     */
    readonly imageTypeLimit?: number

    /**
     * Optional. The image types to include in the output.
     * @type {Array<ImageType>}
     * @memberof InstantMixApiGetInstantMixFromPlaylist
     */
    readonly enableImageTypes?: Array<ImageType>
}

/**
 * Request parameters for getInstantMixFromSong operation in InstantMixApi.
 * @export
 * @interface InstantMixApiGetInstantMixFromSongRequest
 */
export interface InstantMixApiGetInstantMixFromSongRequest {
    /**
     * The item id.
     * @type {string}
     * @memberof InstantMixApiGetInstantMixFromSong
     */
    readonly id: string

    /**
     * Optional. Filter by user id, and attach user data.
     * @type {string}
     * @memberof InstantMixApiGetInstantMixFromSong
     */
    readonly userId?: string

    /**
     * Optional. The maximum number of records to return.
     * @type {number}
     * @memberof InstantMixApiGetInstantMixFromSong
     */
    readonly limit?: number

    /**
     * Optional. Specify additional fields of information to return in the output. This allows multiple, comma delimeted. Options: Budget, Chapters, DateCreated, Genres, HomePageUrl, IndexOptions, MediaStreams, Overview, ParentId, Path, People, ProviderIds, PrimaryImageAspectRatio, Revenue, SortName, Studios, Taglines, TrailerUrls.
     * @type {string}
     * @memberof InstantMixApiGetInstantMixFromSong
     */
    readonly fields?: string

    /**
     * Optional. Include image information in output.
     * @type {boolean}
     * @memberof InstantMixApiGetInstantMixFromSong
     */
    readonly enableImages?: boolean

    /**
     * Optional. Include user data.
     * @type {boolean}
     * @memberof InstantMixApiGetInstantMixFromSong
     */
    readonly enableUserData?: boolean

    /**
     * Optional. The max number of images to return, per image type.
     * @type {number}
     * @memberof InstantMixApiGetInstantMixFromSong
     */
    readonly imageTypeLimit?: number

    /**
     * Optional. The image types to include in the output.
     * @type {Array<ImageType>}
     * @memberof InstantMixApiGetInstantMixFromSong
     */
    readonly enableImageTypes?: Array<ImageType>
}

/**
 * InstantMixApi - object-oriented interface
 * @export
 * @class InstantMixApi
 * @extends {BaseAPI}
 */
export class InstantMixApi extends BaseAPI {
    /**
     * 
     * @summary Creates an instant playlist based on a given song.
     * @param {InstantMixApiGetInstantMixFromAlbumRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InstantMixApi
     */
    public getInstantMixFromAlbum(requestParameters: InstantMixApiGetInstantMixFromAlbumRequest, options?: any) {
        return InstantMixApiFp(this.configuration).getInstantMixFromAlbum(requestParameters.id, requestParameters.userId, requestParameters.limit, requestParameters.fields, requestParameters.enableImages, requestParameters.enableUserData, requestParameters.imageTypeLimit, requestParameters.enableImageTypes, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Creates an instant playlist based on a given song.
     * @param {InstantMixApiGetInstantMixFromArtistsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InstantMixApi
     */
    public getInstantMixFromArtists(requestParameters: InstantMixApiGetInstantMixFromArtistsRequest, options?: any) {
        return InstantMixApiFp(this.configuration).getInstantMixFromArtists(requestParameters.id, requestParameters.userId, requestParameters.limit, requestParameters.fields, requestParameters.enableImages, requestParameters.enableUserData, requestParameters.imageTypeLimit, requestParameters.enableImageTypes, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Creates an instant playlist based on a given song.
     * @param {InstantMixApiGetInstantMixFromItemRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InstantMixApi
     */
    public getInstantMixFromItem(requestParameters: InstantMixApiGetInstantMixFromItemRequest, options?: any) {
        return InstantMixApiFp(this.configuration).getInstantMixFromItem(requestParameters.id, requestParameters.userId, requestParameters.limit, requestParameters.fields, requestParameters.enableImages, requestParameters.enableUserData, requestParameters.imageTypeLimit, requestParameters.enableImageTypes, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Creates an instant playlist based on a given song.
     * @param {InstantMixApiGetInstantMixFromMusicGenreRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InstantMixApi
     */
    public getInstantMixFromMusicGenre(requestParameters: InstantMixApiGetInstantMixFromMusicGenreRequest, options?: any) {
        return InstantMixApiFp(this.configuration).getInstantMixFromMusicGenre(requestParameters.name, requestParameters.userId, requestParameters.limit, requestParameters.fields, requestParameters.enableImages, requestParameters.enableUserData, requestParameters.imageTypeLimit, requestParameters.enableImageTypes, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Creates an instant playlist based on a given song.
     * @param {InstantMixApiGetInstantMixFromMusicGenresRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InstantMixApi
     */
    public getInstantMixFromMusicGenres(requestParameters: InstantMixApiGetInstantMixFromMusicGenresRequest, options?: any) {
        return InstantMixApiFp(this.configuration).getInstantMixFromMusicGenres(requestParameters.id, requestParameters.userId, requestParameters.limit, requestParameters.fields, requestParameters.enableImages, requestParameters.enableUserData, requestParameters.imageTypeLimit, requestParameters.enableImageTypes, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Creates an instant playlist based on a given song.
     * @param {InstantMixApiGetInstantMixFromPlaylistRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InstantMixApi
     */
    public getInstantMixFromPlaylist(requestParameters: InstantMixApiGetInstantMixFromPlaylistRequest, options?: any) {
        return InstantMixApiFp(this.configuration).getInstantMixFromPlaylist(requestParameters.id, requestParameters.userId, requestParameters.limit, requestParameters.fields, requestParameters.enableImages, requestParameters.enableUserData, requestParameters.imageTypeLimit, requestParameters.enableImageTypes, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Creates an instant playlist based on a given song.
     * @param {InstantMixApiGetInstantMixFromSongRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InstantMixApi
     */
    public getInstantMixFromSong(requestParameters: InstantMixApiGetInstantMixFromSongRequest, options?: any) {
        return InstantMixApiFp(this.configuration).getInstantMixFromSong(requestParameters.id, requestParameters.userId, requestParameters.limit, requestParameters.fields, requestParameters.enableImages, requestParameters.enableUserData, requestParameters.imageTypeLimit, requestParameters.enableImageTypes, options).then((request) => request(this.axios, this.basePath));
    }
}
