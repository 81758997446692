/* tslint:disable */
/* eslint-disable */
/**
 * Jellyfin API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { MBRegistrationRecord } from '../models';
// @ts-ignore
import { PluginInfo } from '../models';
// @ts-ignore
import { PluginSecurityInfo } from '../models';
// @ts-ignore
import { ProblemDetails } from '../models';
/**
 * PluginsApi - axios parameter creator
 * @export
 */
export const PluginsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Gets plugin configuration.
         * @param {string} pluginId Plugin id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPluginConfiguration: async (pluginId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'pluginId' is not null or undefined
            if (pluginId === null || pluginId === undefined) {
                throw new RequiredError('pluginId','Required parameter pluginId was null or undefined when calling getPluginConfiguration.');
            }
            const localVarPath = `/Plugins/{pluginId}/Configuration`
                .replace(`{${"pluginId"}}`, encodeURIComponent(String(pluginId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get plugin security info.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPluginSecurityInfo: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/Plugins/SecurityInfo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets a list of currently installed plugins.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPlugins: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/Plugins`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets registration status for a feature.
         * @param {string} name Feature name.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRegistration: async (name: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            if (name === null || name === undefined) {
                throw new RequiredError('name','Required parameter name was null or undefined when calling getRegistration.');
            }
            const localVarPath = `/Plugins/Registrations/{name}`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets registration status for a feature.
         * @param {string} name Feature name.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRegistrationStatus: async (name: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            if (name === null || name === undefined) {
                throw new RequiredError('name','Required parameter name was null or undefined when calling getRegistrationStatus.');
            }
            const localVarPath = `/Plugins/RegistrationRecords/{name}`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Uninstalls a plugin.
         * @param {string} pluginId Plugin id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uninstallPlugin: async (pluginId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'pluginId' is not null or undefined
            if (pluginId === null || pluginId === undefined) {
                throw new RequiredError('pluginId','Required parameter pluginId was null or undefined when calling uninstallPlugin.');
            }
            const localVarPath = `/Plugins/{pluginId}`
                .replace(`{${"pluginId"}}`, encodeURIComponent(String(pluginId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Accepts plugin configuration as JSON body.
         * @summary Updates plugin configuration.
         * @param {string} pluginId Plugin id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePluginConfiguration: async (pluginId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'pluginId' is not null or undefined
            if (pluginId === null || pluginId === undefined) {
                throw new RequiredError('pluginId','Required parameter pluginId was null or undefined when calling updatePluginConfiguration.');
            }
            const localVarPath = `/Plugins/{pluginId}/Configuration`
                .replace(`{${"pluginId"}}`, encodeURIComponent(String(pluginId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates plugin security info.
         * @param {PluginSecurityInfo} pluginSecurityInfo Plugin security info.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePluginSecurityInfo: async (pluginSecurityInfo: PluginSecurityInfo, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'pluginSecurityInfo' is not null or undefined
            if (pluginSecurityInfo === null || pluginSecurityInfo === undefined) {
                throw new RequiredError('pluginSecurityInfo','Required parameter pluginSecurityInfo was null or undefined when calling updatePluginSecurityInfo.');
            }
            const localVarPath = `/Plugins/SecurityInfo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof pluginSecurityInfo !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(pluginSecurityInfo !== undefined ? pluginSecurityInfo : {}) : (pluginSecurityInfo || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PluginsApi - functional programming interface
 * @export
 */
export const PluginsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Gets plugin configuration.
         * @param {string} pluginId Plugin id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPluginConfiguration(pluginId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await PluginsApiAxiosParamCreator(configuration).getPluginConfiguration(pluginId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get plugin security info.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPluginSecurityInfo(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PluginSecurityInfo>> {
            const localVarAxiosArgs = await PluginsApiAxiosParamCreator(configuration).getPluginSecurityInfo(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Gets a list of currently installed plugins.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPlugins(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PluginInfo>>> {
            const localVarAxiosArgs = await PluginsApiAxiosParamCreator(configuration).getPlugins(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Gets registration status for a feature.
         * @param {string} name Feature name.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRegistration(name: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await PluginsApiAxiosParamCreator(configuration).getRegistration(name, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Gets registration status for a feature.
         * @param {string} name Feature name.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRegistrationStatus(name: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MBRegistrationRecord>> {
            const localVarAxiosArgs = await PluginsApiAxiosParamCreator(configuration).getRegistrationStatus(name, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Uninstalls a plugin.
         * @param {string} pluginId Plugin id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uninstallPlugin(pluginId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await PluginsApiAxiosParamCreator(configuration).uninstallPlugin(pluginId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Accepts plugin configuration as JSON body.
         * @summary Updates plugin configuration.
         * @param {string} pluginId Plugin id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePluginConfiguration(pluginId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await PluginsApiAxiosParamCreator(configuration).updatePluginConfiguration(pluginId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Updates plugin security info.
         * @param {PluginSecurityInfo} pluginSecurityInfo Plugin security info.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePluginSecurityInfo(pluginSecurityInfo: PluginSecurityInfo, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await PluginsApiAxiosParamCreator(configuration).updatePluginSecurityInfo(pluginSecurityInfo, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * PluginsApi - factory interface
 * @export
 */
export const PluginsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Gets plugin configuration.
         * @param {string} pluginId Plugin id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPluginConfiguration(pluginId: string, options?: any): AxiosPromise<object> {
            return PluginsApiFp(configuration).getPluginConfiguration(pluginId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get plugin security info.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPluginSecurityInfo(options?: any): AxiosPromise<PluginSecurityInfo> {
            return PluginsApiFp(configuration).getPluginSecurityInfo(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets a list of currently installed plugins.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPlugins(options?: any): AxiosPromise<Array<PluginInfo>> {
            return PluginsApiFp(configuration).getPlugins(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets registration status for a feature.
         * @param {string} name Feature name.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRegistration(name: string, options?: any): AxiosPromise<void> {
            return PluginsApiFp(configuration).getRegistration(name, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets registration status for a feature.
         * @param {string} name Feature name.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRegistrationStatus(name: string, options?: any): AxiosPromise<MBRegistrationRecord> {
            return PluginsApiFp(configuration).getRegistrationStatus(name, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Uninstalls a plugin.
         * @param {string} pluginId Plugin id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uninstallPlugin(pluginId: string, options?: any): AxiosPromise<void> {
            return PluginsApiFp(configuration).uninstallPlugin(pluginId, options).then((request) => request(axios, basePath));
        },
        /**
         * Accepts plugin configuration as JSON body.
         * @summary Updates plugin configuration.
         * @param {string} pluginId Plugin id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePluginConfiguration(pluginId: string, options?: any): AxiosPromise<void> {
            return PluginsApiFp(configuration).updatePluginConfiguration(pluginId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates plugin security info.
         * @param {PluginSecurityInfo} pluginSecurityInfo Plugin security info.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePluginSecurityInfo(pluginSecurityInfo: PluginSecurityInfo, options?: any): AxiosPromise<void> {
            return PluginsApiFp(configuration).updatePluginSecurityInfo(pluginSecurityInfo, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getPluginConfiguration operation in PluginsApi.
 * @export
 * @interface PluginsApiGetPluginConfigurationRequest
 */
export interface PluginsApiGetPluginConfigurationRequest {
    /**
     * Plugin id.
     * @type {string}
     * @memberof PluginsApiGetPluginConfiguration
     */
    readonly pluginId: string
}

/**
 * Request parameters for getRegistration operation in PluginsApi.
 * @export
 * @interface PluginsApiGetRegistrationRequest
 */
export interface PluginsApiGetRegistrationRequest {
    /**
     * Feature name.
     * @type {string}
     * @memberof PluginsApiGetRegistration
     */
    readonly name: string
}

/**
 * Request parameters for getRegistrationStatus operation in PluginsApi.
 * @export
 * @interface PluginsApiGetRegistrationStatusRequest
 */
export interface PluginsApiGetRegistrationStatusRequest {
    /**
     * Feature name.
     * @type {string}
     * @memberof PluginsApiGetRegistrationStatus
     */
    readonly name: string
}

/**
 * Request parameters for uninstallPlugin operation in PluginsApi.
 * @export
 * @interface PluginsApiUninstallPluginRequest
 */
export interface PluginsApiUninstallPluginRequest {
    /**
     * Plugin id.
     * @type {string}
     * @memberof PluginsApiUninstallPlugin
     */
    readonly pluginId: string
}

/**
 * Request parameters for updatePluginConfiguration operation in PluginsApi.
 * @export
 * @interface PluginsApiUpdatePluginConfigurationRequest
 */
export interface PluginsApiUpdatePluginConfigurationRequest {
    /**
     * Plugin id.
     * @type {string}
     * @memberof PluginsApiUpdatePluginConfiguration
     */
    readonly pluginId: string
}

/**
 * Request parameters for updatePluginSecurityInfo operation in PluginsApi.
 * @export
 * @interface PluginsApiUpdatePluginSecurityInfoRequest
 */
export interface PluginsApiUpdatePluginSecurityInfoRequest {
    /**
     * Plugin security info.
     * @type {PluginSecurityInfo}
     * @memberof PluginsApiUpdatePluginSecurityInfo
     */
    readonly pluginSecurityInfo: PluginSecurityInfo
}

/**
 * PluginsApi - object-oriented interface
 * @export
 * @class PluginsApi
 * @extends {BaseAPI}
 */
export class PluginsApi extends BaseAPI {
    /**
     * 
     * @summary Gets plugin configuration.
     * @param {PluginsApiGetPluginConfigurationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PluginsApi
     */
    public getPluginConfiguration(requestParameters: PluginsApiGetPluginConfigurationRequest, options?: any) {
        return PluginsApiFp(this.configuration).getPluginConfiguration(requestParameters.pluginId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get plugin security info.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PluginsApi
     */
    public getPluginSecurityInfo(options?: any) {
        return PluginsApiFp(this.configuration).getPluginSecurityInfo(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets a list of currently installed plugins.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PluginsApi
     */
    public getPlugins(options?: any) {
        return PluginsApiFp(this.configuration).getPlugins(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets registration status for a feature.
     * @param {PluginsApiGetRegistrationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PluginsApi
     */
    public getRegistration(requestParameters: PluginsApiGetRegistrationRequest, options?: any) {
        return PluginsApiFp(this.configuration).getRegistration(requestParameters.name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets registration status for a feature.
     * @param {PluginsApiGetRegistrationStatusRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PluginsApi
     */
    public getRegistrationStatus(requestParameters: PluginsApiGetRegistrationStatusRequest, options?: any) {
        return PluginsApiFp(this.configuration).getRegistrationStatus(requestParameters.name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Uninstalls a plugin.
     * @param {PluginsApiUninstallPluginRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PluginsApi
     */
    public uninstallPlugin(requestParameters: PluginsApiUninstallPluginRequest, options?: any) {
        return PluginsApiFp(this.configuration).uninstallPlugin(requestParameters.pluginId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Accepts plugin configuration as JSON body.
     * @summary Updates plugin configuration.
     * @param {PluginsApiUpdatePluginConfigurationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PluginsApi
     */
    public updatePluginConfiguration(requestParameters: PluginsApiUpdatePluginConfigurationRequest, options?: any) {
        return PluginsApiFp(this.configuration).updatePluginConfiguration(requestParameters.pluginId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates plugin security info.
     * @param {PluginsApiUpdatePluginSecurityInfoRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PluginsApi
     */
    public updatePluginSecurityInfo(requestParameters: PluginsApiUpdatePluginSecurityInfoRequest, options?: any) {
        return PluginsApiFp(this.configuration).updatePluginSecurityInfo(requestParameters.pluginSecurityInfo, options).then((request) => request(this.axios, this.basePath));
    }
}
