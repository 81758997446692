/* tslint:disable */
/* eslint-disable */
/**
 * Jellyfin API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AlbumInfoRemoteSearchQuery } from '../models';
// @ts-ignore
import { ArtistInfoRemoteSearchQuery } from '../models';
// @ts-ignore
import { BookInfoRemoteSearchQuery } from '../models';
// @ts-ignore
import { BoxSetInfoRemoteSearchQuery } from '../models';
// @ts-ignore
import { ExternalIdInfo } from '../models';
// @ts-ignore
import { MovieInfoRemoteSearchQuery } from '../models';
// @ts-ignore
import { MusicVideoInfoRemoteSearchQuery } from '../models';
// @ts-ignore
import { PersonLookupInfoRemoteSearchQuery } from '../models';
// @ts-ignore
import { ProblemDetails } from '../models';
// @ts-ignore
import { RemoteSearchResult } from '../models';
// @ts-ignore
import { SeriesInfoRemoteSearchQuery } from '../models';
// @ts-ignore
import { TrailerInfoRemoteSearchQuery } from '../models';
/**
 * ItemLookupApi - axios parameter creator
 * @export
 */
export const ItemLookupApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Applies search criteria to an item and refreshes metadata.
         * @param {string} itemId Item id.
         * @param {RemoteSearchResult} remoteSearchResult The remote search result.
         * @param {boolean} [replaceAllImages] Optional. Whether or not to replace all images. Default: True.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        applySearchCriteria: async (itemId: string, remoteSearchResult: RemoteSearchResult, replaceAllImages?: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'itemId' is not null or undefined
            if (itemId === null || itemId === undefined) {
                throw new RequiredError('itemId','Required parameter itemId was null or undefined when calling applySearchCriteria.');
            }
            // verify required parameter 'remoteSearchResult' is not null or undefined
            if (remoteSearchResult === null || remoteSearchResult === undefined) {
                throw new RequiredError('remoteSearchResult','Required parameter remoteSearchResult was null or undefined when calling applySearchCriteria.');
            }
            const localVarPath = `/Items/RemoteSearch/Apply/{itemId}`
                .replace(`{${"itemId"}}`, encodeURIComponent(String(itemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }

            if (replaceAllImages !== undefined) {
                localVarQueryParameter['replaceAllImages'] = replaceAllImages;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof remoteSearchResult !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(remoteSearchResult !== undefined ? remoteSearchResult : {}) : (remoteSearchResult || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get book remote search.
         * @param {BookInfoRemoteSearchQuery} bookInfoRemoteSearchQuery Remote search query.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBookRemoteSearchResults: async (bookInfoRemoteSearchQuery: BookInfoRemoteSearchQuery, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'bookInfoRemoteSearchQuery' is not null or undefined
            if (bookInfoRemoteSearchQuery === null || bookInfoRemoteSearchQuery === undefined) {
                throw new RequiredError('bookInfoRemoteSearchQuery','Required parameter bookInfoRemoteSearchQuery was null or undefined when calling getBookRemoteSearchResults.');
            }
            const localVarPath = `/Items/RemoteSearch/Book`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof bookInfoRemoteSearchQuery !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(bookInfoRemoteSearchQuery !== undefined ? bookInfoRemoteSearchQuery : {}) : (bookInfoRemoteSearchQuery || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get box set remote search.
         * @param {BoxSetInfoRemoteSearchQuery} boxSetInfoRemoteSearchQuery Remote search query.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBoxSetRemoteSearchResults: async (boxSetInfoRemoteSearchQuery: BoxSetInfoRemoteSearchQuery, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'boxSetInfoRemoteSearchQuery' is not null or undefined
            if (boxSetInfoRemoteSearchQuery === null || boxSetInfoRemoteSearchQuery === undefined) {
                throw new RequiredError('boxSetInfoRemoteSearchQuery','Required parameter boxSetInfoRemoteSearchQuery was null or undefined when calling getBoxSetRemoteSearchResults.');
            }
            const localVarPath = `/Items/RemoteSearch/BoxSet`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof boxSetInfoRemoteSearchQuery !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(boxSetInfoRemoteSearchQuery !== undefined ? boxSetInfoRemoteSearchQuery : {}) : (boxSetInfoRemoteSearchQuery || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the item\'s external id info.
         * @param {string} itemId Item id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExternalIdInfos: async (itemId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'itemId' is not null or undefined
            if (itemId === null || itemId === undefined) {
                throw new RequiredError('itemId','Required parameter itemId was null or undefined when calling getExternalIdInfos.');
            }
            const localVarPath = `/Items/{itemId}/ExternalIdInfos`
                .replace(`{${"itemId"}}`, encodeURIComponent(String(itemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get movie remote search.
         * @param {MovieInfoRemoteSearchQuery} movieInfoRemoteSearchQuery Remote search query.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMovieRemoteSearchResults: async (movieInfoRemoteSearchQuery: MovieInfoRemoteSearchQuery, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'movieInfoRemoteSearchQuery' is not null or undefined
            if (movieInfoRemoteSearchQuery === null || movieInfoRemoteSearchQuery === undefined) {
                throw new RequiredError('movieInfoRemoteSearchQuery','Required parameter movieInfoRemoteSearchQuery was null or undefined when calling getMovieRemoteSearchResults.');
            }
            const localVarPath = `/Items/RemoteSearch/Movie`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof movieInfoRemoteSearchQuery !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(movieInfoRemoteSearchQuery !== undefined ? movieInfoRemoteSearchQuery : {}) : (movieInfoRemoteSearchQuery || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get music album remote search.
         * @param {AlbumInfoRemoteSearchQuery} albumInfoRemoteSearchQuery Remote search query.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMusicAlbumRemoteSearchResults: async (albumInfoRemoteSearchQuery: AlbumInfoRemoteSearchQuery, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'albumInfoRemoteSearchQuery' is not null or undefined
            if (albumInfoRemoteSearchQuery === null || albumInfoRemoteSearchQuery === undefined) {
                throw new RequiredError('albumInfoRemoteSearchQuery','Required parameter albumInfoRemoteSearchQuery was null or undefined when calling getMusicAlbumRemoteSearchResults.');
            }
            const localVarPath = `/Items/RemoteSearch/MusicAlbum`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof albumInfoRemoteSearchQuery !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(albumInfoRemoteSearchQuery !== undefined ? albumInfoRemoteSearchQuery : {}) : (albumInfoRemoteSearchQuery || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get music artist remote search.
         * @param {ArtistInfoRemoteSearchQuery} artistInfoRemoteSearchQuery Remote search query.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMusicArtistRemoteSearchResults: async (artistInfoRemoteSearchQuery: ArtistInfoRemoteSearchQuery, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'artistInfoRemoteSearchQuery' is not null or undefined
            if (artistInfoRemoteSearchQuery === null || artistInfoRemoteSearchQuery === undefined) {
                throw new RequiredError('artistInfoRemoteSearchQuery','Required parameter artistInfoRemoteSearchQuery was null or undefined when calling getMusicArtistRemoteSearchResults.');
            }
            const localVarPath = `/Items/RemoteSearch/MusicArtist`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof artistInfoRemoteSearchQuery !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(artistInfoRemoteSearchQuery !== undefined ? artistInfoRemoteSearchQuery : {}) : (artistInfoRemoteSearchQuery || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get music video remote search.
         * @param {MusicVideoInfoRemoteSearchQuery} musicVideoInfoRemoteSearchQuery Remote search query.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMusicVideoRemoteSearchResults: async (musicVideoInfoRemoteSearchQuery: MusicVideoInfoRemoteSearchQuery, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'musicVideoInfoRemoteSearchQuery' is not null or undefined
            if (musicVideoInfoRemoteSearchQuery === null || musicVideoInfoRemoteSearchQuery === undefined) {
                throw new RequiredError('musicVideoInfoRemoteSearchQuery','Required parameter musicVideoInfoRemoteSearchQuery was null or undefined when calling getMusicVideoRemoteSearchResults.');
            }
            const localVarPath = `/Items/RemoteSearch/MusicVideo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof musicVideoInfoRemoteSearchQuery !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(musicVideoInfoRemoteSearchQuery !== undefined ? musicVideoInfoRemoteSearchQuery : {}) : (musicVideoInfoRemoteSearchQuery || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get person remote search.
         * @param {PersonLookupInfoRemoteSearchQuery} personLookupInfoRemoteSearchQuery Remote search query.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPersonRemoteSearchResults: async (personLookupInfoRemoteSearchQuery: PersonLookupInfoRemoteSearchQuery, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'personLookupInfoRemoteSearchQuery' is not null or undefined
            if (personLookupInfoRemoteSearchQuery === null || personLookupInfoRemoteSearchQuery === undefined) {
                throw new RequiredError('personLookupInfoRemoteSearchQuery','Required parameter personLookupInfoRemoteSearchQuery was null or undefined when calling getPersonRemoteSearchResults.');
            }
            const localVarPath = `/Items/RemoteSearch/Person`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof personLookupInfoRemoteSearchQuery !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(personLookupInfoRemoteSearchQuery !== undefined ? personLookupInfoRemoteSearchQuery : {}) : (personLookupInfoRemoteSearchQuery || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets a remote image.
         * @param {string} imageUrl The image url.
         * @param {string} providerName The provider name.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRemoteSearchImage: async (imageUrl: string, providerName: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'imageUrl' is not null or undefined
            if (imageUrl === null || imageUrl === undefined) {
                throw new RequiredError('imageUrl','Required parameter imageUrl was null or undefined when calling getRemoteSearchImage.');
            }
            // verify required parameter 'providerName' is not null or undefined
            if (providerName === null || providerName === undefined) {
                throw new RequiredError('providerName','Required parameter providerName was null or undefined when calling getRemoteSearchImage.');
            }
            const localVarPath = `/Items/RemoteSearch/Image`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }

            if (imageUrl !== undefined) {
                localVarQueryParameter['imageUrl'] = imageUrl;
            }

            if (providerName !== undefined) {
                localVarQueryParameter['providerName'] = providerName;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get series remote search.
         * @param {SeriesInfoRemoteSearchQuery} seriesInfoRemoteSearchQuery Remote search query.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSeriesRemoteSearchResults: async (seriesInfoRemoteSearchQuery: SeriesInfoRemoteSearchQuery, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'seriesInfoRemoteSearchQuery' is not null or undefined
            if (seriesInfoRemoteSearchQuery === null || seriesInfoRemoteSearchQuery === undefined) {
                throw new RequiredError('seriesInfoRemoteSearchQuery','Required parameter seriesInfoRemoteSearchQuery was null or undefined when calling getSeriesRemoteSearchResults.');
            }
            const localVarPath = `/Items/RemoteSearch/Series`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof seriesInfoRemoteSearchQuery !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(seriesInfoRemoteSearchQuery !== undefined ? seriesInfoRemoteSearchQuery : {}) : (seriesInfoRemoteSearchQuery || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get trailer remote search.
         * @param {TrailerInfoRemoteSearchQuery} trailerInfoRemoteSearchQuery Remote search query.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTrailerRemoteSearchResults: async (trailerInfoRemoteSearchQuery: TrailerInfoRemoteSearchQuery, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'trailerInfoRemoteSearchQuery' is not null or undefined
            if (trailerInfoRemoteSearchQuery === null || trailerInfoRemoteSearchQuery === undefined) {
                throw new RequiredError('trailerInfoRemoteSearchQuery','Required parameter trailerInfoRemoteSearchQuery was null or undefined when calling getTrailerRemoteSearchResults.');
            }
            const localVarPath = `/Items/RemoteSearch/Trailer`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof trailerInfoRemoteSearchQuery !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(trailerInfoRemoteSearchQuery !== undefined ? trailerInfoRemoteSearchQuery : {}) : (trailerInfoRemoteSearchQuery || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ItemLookupApi - functional programming interface
 * @export
 */
export const ItemLookupApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Applies search criteria to an item and refreshes metadata.
         * @param {string} itemId Item id.
         * @param {RemoteSearchResult} remoteSearchResult The remote search result.
         * @param {boolean} [replaceAllImages] Optional. Whether or not to replace all images. Default: True.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async applySearchCriteria(itemId: string, remoteSearchResult: RemoteSearchResult, replaceAllImages?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ItemLookupApiAxiosParamCreator(configuration).applySearchCriteria(itemId, remoteSearchResult, replaceAllImages, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get book remote search.
         * @param {BookInfoRemoteSearchQuery} bookInfoRemoteSearchQuery Remote search query.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBookRemoteSearchResults(bookInfoRemoteSearchQuery: BookInfoRemoteSearchQuery, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RemoteSearchResult>>> {
            const localVarAxiosArgs = await ItemLookupApiAxiosParamCreator(configuration).getBookRemoteSearchResults(bookInfoRemoteSearchQuery, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get box set remote search.
         * @param {BoxSetInfoRemoteSearchQuery} boxSetInfoRemoteSearchQuery Remote search query.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBoxSetRemoteSearchResults(boxSetInfoRemoteSearchQuery: BoxSetInfoRemoteSearchQuery, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RemoteSearchResult>>> {
            const localVarAxiosArgs = await ItemLookupApiAxiosParamCreator(configuration).getBoxSetRemoteSearchResults(boxSetInfoRemoteSearchQuery, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get the item\'s external id info.
         * @param {string} itemId Item id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getExternalIdInfos(itemId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ExternalIdInfo>>> {
            const localVarAxiosArgs = await ItemLookupApiAxiosParamCreator(configuration).getExternalIdInfos(itemId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get movie remote search.
         * @param {MovieInfoRemoteSearchQuery} movieInfoRemoteSearchQuery Remote search query.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMovieRemoteSearchResults(movieInfoRemoteSearchQuery: MovieInfoRemoteSearchQuery, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RemoteSearchResult>>> {
            const localVarAxiosArgs = await ItemLookupApiAxiosParamCreator(configuration).getMovieRemoteSearchResults(movieInfoRemoteSearchQuery, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get music album remote search.
         * @param {AlbumInfoRemoteSearchQuery} albumInfoRemoteSearchQuery Remote search query.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMusicAlbumRemoteSearchResults(albumInfoRemoteSearchQuery: AlbumInfoRemoteSearchQuery, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RemoteSearchResult>>> {
            const localVarAxiosArgs = await ItemLookupApiAxiosParamCreator(configuration).getMusicAlbumRemoteSearchResults(albumInfoRemoteSearchQuery, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get music artist remote search.
         * @param {ArtistInfoRemoteSearchQuery} artistInfoRemoteSearchQuery Remote search query.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMusicArtistRemoteSearchResults(artistInfoRemoteSearchQuery: ArtistInfoRemoteSearchQuery, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RemoteSearchResult>>> {
            const localVarAxiosArgs = await ItemLookupApiAxiosParamCreator(configuration).getMusicArtistRemoteSearchResults(artistInfoRemoteSearchQuery, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get music video remote search.
         * @param {MusicVideoInfoRemoteSearchQuery} musicVideoInfoRemoteSearchQuery Remote search query.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMusicVideoRemoteSearchResults(musicVideoInfoRemoteSearchQuery: MusicVideoInfoRemoteSearchQuery, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RemoteSearchResult>>> {
            const localVarAxiosArgs = await ItemLookupApiAxiosParamCreator(configuration).getMusicVideoRemoteSearchResults(musicVideoInfoRemoteSearchQuery, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get person remote search.
         * @param {PersonLookupInfoRemoteSearchQuery} personLookupInfoRemoteSearchQuery Remote search query.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPersonRemoteSearchResults(personLookupInfoRemoteSearchQuery: PersonLookupInfoRemoteSearchQuery, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RemoteSearchResult>>> {
            const localVarAxiosArgs = await ItemLookupApiAxiosParamCreator(configuration).getPersonRemoteSearchResults(personLookupInfoRemoteSearchQuery, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Gets a remote image.
         * @param {string} imageUrl The image url.
         * @param {string} providerName The provider name.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRemoteSearchImage(imageUrl: string, providerName: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await ItemLookupApiAxiosParamCreator(configuration).getRemoteSearchImage(imageUrl, providerName, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get series remote search.
         * @param {SeriesInfoRemoteSearchQuery} seriesInfoRemoteSearchQuery Remote search query.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSeriesRemoteSearchResults(seriesInfoRemoteSearchQuery: SeriesInfoRemoteSearchQuery, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RemoteSearchResult>>> {
            const localVarAxiosArgs = await ItemLookupApiAxiosParamCreator(configuration).getSeriesRemoteSearchResults(seriesInfoRemoteSearchQuery, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get trailer remote search.
         * @param {TrailerInfoRemoteSearchQuery} trailerInfoRemoteSearchQuery Remote search query.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTrailerRemoteSearchResults(trailerInfoRemoteSearchQuery: TrailerInfoRemoteSearchQuery, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RemoteSearchResult>>> {
            const localVarAxiosArgs = await ItemLookupApiAxiosParamCreator(configuration).getTrailerRemoteSearchResults(trailerInfoRemoteSearchQuery, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * ItemLookupApi - factory interface
 * @export
 */
export const ItemLookupApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Applies search criteria to an item and refreshes metadata.
         * @param {string} itemId Item id.
         * @param {RemoteSearchResult} remoteSearchResult The remote search result.
         * @param {boolean} [replaceAllImages] Optional. Whether or not to replace all images. Default: True.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        applySearchCriteria(itemId: string, remoteSearchResult: RemoteSearchResult, replaceAllImages?: boolean, options?: any): AxiosPromise<void> {
            return ItemLookupApiFp(configuration).applySearchCriteria(itemId, remoteSearchResult, replaceAllImages, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get book remote search.
         * @param {BookInfoRemoteSearchQuery} bookInfoRemoteSearchQuery Remote search query.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBookRemoteSearchResults(bookInfoRemoteSearchQuery: BookInfoRemoteSearchQuery, options?: any): AxiosPromise<Array<RemoteSearchResult>> {
            return ItemLookupApiFp(configuration).getBookRemoteSearchResults(bookInfoRemoteSearchQuery, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get box set remote search.
         * @param {BoxSetInfoRemoteSearchQuery} boxSetInfoRemoteSearchQuery Remote search query.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBoxSetRemoteSearchResults(boxSetInfoRemoteSearchQuery: BoxSetInfoRemoteSearchQuery, options?: any): AxiosPromise<Array<RemoteSearchResult>> {
            return ItemLookupApiFp(configuration).getBoxSetRemoteSearchResults(boxSetInfoRemoteSearchQuery, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the item\'s external id info.
         * @param {string} itemId Item id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExternalIdInfos(itemId: string, options?: any): AxiosPromise<Array<ExternalIdInfo>> {
            return ItemLookupApiFp(configuration).getExternalIdInfos(itemId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get movie remote search.
         * @param {MovieInfoRemoteSearchQuery} movieInfoRemoteSearchQuery Remote search query.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMovieRemoteSearchResults(movieInfoRemoteSearchQuery: MovieInfoRemoteSearchQuery, options?: any): AxiosPromise<Array<RemoteSearchResult>> {
            return ItemLookupApiFp(configuration).getMovieRemoteSearchResults(movieInfoRemoteSearchQuery, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get music album remote search.
         * @param {AlbumInfoRemoteSearchQuery} albumInfoRemoteSearchQuery Remote search query.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMusicAlbumRemoteSearchResults(albumInfoRemoteSearchQuery: AlbumInfoRemoteSearchQuery, options?: any): AxiosPromise<Array<RemoteSearchResult>> {
            return ItemLookupApiFp(configuration).getMusicAlbumRemoteSearchResults(albumInfoRemoteSearchQuery, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get music artist remote search.
         * @param {ArtistInfoRemoteSearchQuery} artistInfoRemoteSearchQuery Remote search query.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMusicArtistRemoteSearchResults(artistInfoRemoteSearchQuery: ArtistInfoRemoteSearchQuery, options?: any): AxiosPromise<Array<RemoteSearchResult>> {
            return ItemLookupApiFp(configuration).getMusicArtistRemoteSearchResults(artistInfoRemoteSearchQuery, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get music video remote search.
         * @param {MusicVideoInfoRemoteSearchQuery} musicVideoInfoRemoteSearchQuery Remote search query.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMusicVideoRemoteSearchResults(musicVideoInfoRemoteSearchQuery: MusicVideoInfoRemoteSearchQuery, options?: any): AxiosPromise<Array<RemoteSearchResult>> {
            return ItemLookupApiFp(configuration).getMusicVideoRemoteSearchResults(musicVideoInfoRemoteSearchQuery, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get person remote search.
         * @param {PersonLookupInfoRemoteSearchQuery} personLookupInfoRemoteSearchQuery Remote search query.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPersonRemoteSearchResults(personLookupInfoRemoteSearchQuery: PersonLookupInfoRemoteSearchQuery, options?: any): AxiosPromise<Array<RemoteSearchResult>> {
            return ItemLookupApiFp(configuration).getPersonRemoteSearchResults(personLookupInfoRemoteSearchQuery, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets a remote image.
         * @param {string} imageUrl The image url.
         * @param {string} providerName The provider name.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRemoteSearchImage(imageUrl: string, providerName: string, options?: any): AxiosPromise<any> {
            return ItemLookupApiFp(configuration).getRemoteSearchImage(imageUrl, providerName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get series remote search.
         * @param {SeriesInfoRemoteSearchQuery} seriesInfoRemoteSearchQuery Remote search query.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSeriesRemoteSearchResults(seriesInfoRemoteSearchQuery: SeriesInfoRemoteSearchQuery, options?: any): AxiosPromise<Array<RemoteSearchResult>> {
            return ItemLookupApiFp(configuration).getSeriesRemoteSearchResults(seriesInfoRemoteSearchQuery, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get trailer remote search.
         * @param {TrailerInfoRemoteSearchQuery} trailerInfoRemoteSearchQuery Remote search query.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTrailerRemoteSearchResults(trailerInfoRemoteSearchQuery: TrailerInfoRemoteSearchQuery, options?: any): AxiosPromise<Array<RemoteSearchResult>> {
            return ItemLookupApiFp(configuration).getTrailerRemoteSearchResults(trailerInfoRemoteSearchQuery, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for applySearchCriteria operation in ItemLookupApi.
 * @export
 * @interface ItemLookupApiApplySearchCriteriaRequest
 */
export interface ItemLookupApiApplySearchCriteriaRequest {
    /**
     * Item id.
     * @type {string}
     * @memberof ItemLookupApiApplySearchCriteria
     */
    readonly itemId: string

    /**
     * The remote search result.
     * @type {RemoteSearchResult}
     * @memberof ItemLookupApiApplySearchCriteria
     */
    readonly remoteSearchResult: RemoteSearchResult

    /**
     * Optional. Whether or not to replace all images. Default: True.
     * @type {boolean}
     * @memberof ItemLookupApiApplySearchCriteria
     */
    readonly replaceAllImages?: boolean
}

/**
 * Request parameters for getBookRemoteSearchResults operation in ItemLookupApi.
 * @export
 * @interface ItemLookupApiGetBookRemoteSearchResultsRequest
 */
export interface ItemLookupApiGetBookRemoteSearchResultsRequest {
    /**
     * Remote search query.
     * @type {BookInfoRemoteSearchQuery}
     * @memberof ItemLookupApiGetBookRemoteSearchResults
     */
    readonly bookInfoRemoteSearchQuery: BookInfoRemoteSearchQuery
}

/**
 * Request parameters for getBoxSetRemoteSearchResults operation in ItemLookupApi.
 * @export
 * @interface ItemLookupApiGetBoxSetRemoteSearchResultsRequest
 */
export interface ItemLookupApiGetBoxSetRemoteSearchResultsRequest {
    /**
     * Remote search query.
     * @type {BoxSetInfoRemoteSearchQuery}
     * @memberof ItemLookupApiGetBoxSetRemoteSearchResults
     */
    readonly boxSetInfoRemoteSearchQuery: BoxSetInfoRemoteSearchQuery
}

/**
 * Request parameters for getExternalIdInfos operation in ItemLookupApi.
 * @export
 * @interface ItemLookupApiGetExternalIdInfosRequest
 */
export interface ItemLookupApiGetExternalIdInfosRequest {
    /**
     * Item id.
     * @type {string}
     * @memberof ItemLookupApiGetExternalIdInfos
     */
    readonly itemId: string
}

/**
 * Request parameters for getMovieRemoteSearchResults operation in ItemLookupApi.
 * @export
 * @interface ItemLookupApiGetMovieRemoteSearchResultsRequest
 */
export interface ItemLookupApiGetMovieRemoteSearchResultsRequest {
    /**
     * Remote search query.
     * @type {MovieInfoRemoteSearchQuery}
     * @memberof ItemLookupApiGetMovieRemoteSearchResults
     */
    readonly movieInfoRemoteSearchQuery: MovieInfoRemoteSearchQuery
}

/**
 * Request parameters for getMusicAlbumRemoteSearchResults operation in ItemLookupApi.
 * @export
 * @interface ItemLookupApiGetMusicAlbumRemoteSearchResultsRequest
 */
export interface ItemLookupApiGetMusicAlbumRemoteSearchResultsRequest {
    /**
     * Remote search query.
     * @type {AlbumInfoRemoteSearchQuery}
     * @memberof ItemLookupApiGetMusicAlbumRemoteSearchResults
     */
    readonly albumInfoRemoteSearchQuery: AlbumInfoRemoteSearchQuery
}

/**
 * Request parameters for getMusicArtistRemoteSearchResults operation in ItemLookupApi.
 * @export
 * @interface ItemLookupApiGetMusicArtistRemoteSearchResultsRequest
 */
export interface ItemLookupApiGetMusicArtistRemoteSearchResultsRequest {
    /**
     * Remote search query.
     * @type {ArtistInfoRemoteSearchQuery}
     * @memberof ItemLookupApiGetMusicArtistRemoteSearchResults
     */
    readonly artistInfoRemoteSearchQuery: ArtistInfoRemoteSearchQuery
}

/**
 * Request parameters for getMusicVideoRemoteSearchResults operation in ItemLookupApi.
 * @export
 * @interface ItemLookupApiGetMusicVideoRemoteSearchResultsRequest
 */
export interface ItemLookupApiGetMusicVideoRemoteSearchResultsRequest {
    /**
     * Remote search query.
     * @type {MusicVideoInfoRemoteSearchQuery}
     * @memberof ItemLookupApiGetMusicVideoRemoteSearchResults
     */
    readonly musicVideoInfoRemoteSearchQuery: MusicVideoInfoRemoteSearchQuery
}

/**
 * Request parameters for getPersonRemoteSearchResults operation in ItemLookupApi.
 * @export
 * @interface ItemLookupApiGetPersonRemoteSearchResultsRequest
 */
export interface ItemLookupApiGetPersonRemoteSearchResultsRequest {
    /**
     * Remote search query.
     * @type {PersonLookupInfoRemoteSearchQuery}
     * @memberof ItemLookupApiGetPersonRemoteSearchResults
     */
    readonly personLookupInfoRemoteSearchQuery: PersonLookupInfoRemoteSearchQuery
}

/**
 * Request parameters for getRemoteSearchImage operation in ItemLookupApi.
 * @export
 * @interface ItemLookupApiGetRemoteSearchImageRequest
 */
export interface ItemLookupApiGetRemoteSearchImageRequest {
    /**
     * The image url.
     * @type {string}
     * @memberof ItemLookupApiGetRemoteSearchImage
     */
    readonly imageUrl: string

    /**
     * The provider name.
     * @type {string}
     * @memberof ItemLookupApiGetRemoteSearchImage
     */
    readonly providerName: string
}

/**
 * Request parameters for getSeriesRemoteSearchResults operation in ItemLookupApi.
 * @export
 * @interface ItemLookupApiGetSeriesRemoteSearchResultsRequest
 */
export interface ItemLookupApiGetSeriesRemoteSearchResultsRequest {
    /**
     * Remote search query.
     * @type {SeriesInfoRemoteSearchQuery}
     * @memberof ItemLookupApiGetSeriesRemoteSearchResults
     */
    readonly seriesInfoRemoteSearchQuery: SeriesInfoRemoteSearchQuery
}

/**
 * Request parameters for getTrailerRemoteSearchResults operation in ItemLookupApi.
 * @export
 * @interface ItemLookupApiGetTrailerRemoteSearchResultsRequest
 */
export interface ItemLookupApiGetTrailerRemoteSearchResultsRequest {
    /**
     * Remote search query.
     * @type {TrailerInfoRemoteSearchQuery}
     * @memberof ItemLookupApiGetTrailerRemoteSearchResults
     */
    readonly trailerInfoRemoteSearchQuery: TrailerInfoRemoteSearchQuery
}

/**
 * ItemLookupApi - object-oriented interface
 * @export
 * @class ItemLookupApi
 * @extends {BaseAPI}
 */
export class ItemLookupApi extends BaseAPI {
    /**
     * 
     * @summary Applies search criteria to an item and refreshes metadata.
     * @param {ItemLookupApiApplySearchCriteriaRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemLookupApi
     */
    public applySearchCriteria(requestParameters: ItemLookupApiApplySearchCriteriaRequest, options?: any) {
        return ItemLookupApiFp(this.configuration).applySearchCriteria(requestParameters.itemId, requestParameters.remoteSearchResult, requestParameters.replaceAllImages, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get book remote search.
     * @param {ItemLookupApiGetBookRemoteSearchResultsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemLookupApi
     */
    public getBookRemoteSearchResults(requestParameters: ItemLookupApiGetBookRemoteSearchResultsRequest, options?: any) {
        return ItemLookupApiFp(this.configuration).getBookRemoteSearchResults(requestParameters.bookInfoRemoteSearchQuery, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get box set remote search.
     * @param {ItemLookupApiGetBoxSetRemoteSearchResultsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemLookupApi
     */
    public getBoxSetRemoteSearchResults(requestParameters: ItemLookupApiGetBoxSetRemoteSearchResultsRequest, options?: any) {
        return ItemLookupApiFp(this.configuration).getBoxSetRemoteSearchResults(requestParameters.boxSetInfoRemoteSearchQuery, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the item\'s external id info.
     * @param {ItemLookupApiGetExternalIdInfosRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemLookupApi
     */
    public getExternalIdInfos(requestParameters: ItemLookupApiGetExternalIdInfosRequest, options?: any) {
        return ItemLookupApiFp(this.configuration).getExternalIdInfos(requestParameters.itemId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get movie remote search.
     * @param {ItemLookupApiGetMovieRemoteSearchResultsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemLookupApi
     */
    public getMovieRemoteSearchResults(requestParameters: ItemLookupApiGetMovieRemoteSearchResultsRequest, options?: any) {
        return ItemLookupApiFp(this.configuration).getMovieRemoteSearchResults(requestParameters.movieInfoRemoteSearchQuery, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get music album remote search.
     * @param {ItemLookupApiGetMusicAlbumRemoteSearchResultsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemLookupApi
     */
    public getMusicAlbumRemoteSearchResults(requestParameters: ItemLookupApiGetMusicAlbumRemoteSearchResultsRequest, options?: any) {
        return ItemLookupApiFp(this.configuration).getMusicAlbumRemoteSearchResults(requestParameters.albumInfoRemoteSearchQuery, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get music artist remote search.
     * @param {ItemLookupApiGetMusicArtistRemoteSearchResultsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemLookupApi
     */
    public getMusicArtistRemoteSearchResults(requestParameters: ItemLookupApiGetMusicArtistRemoteSearchResultsRequest, options?: any) {
        return ItemLookupApiFp(this.configuration).getMusicArtistRemoteSearchResults(requestParameters.artistInfoRemoteSearchQuery, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get music video remote search.
     * @param {ItemLookupApiGetMusicVideoRemoteSearchResultsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemLookupApi
     */
    public getMusicVideoRemoteSearchResults(requestParameters: ItemLookupApiGetMusicVideoRemoteSearchResultsRequest, options?: any) {
        return ItemLookupApiFp(this.configuration).getMusicVideoRemoteSearchResults(requestParameters.musicVideoInfoRemoteSearchQuery, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get person remote search.
     * @param {ItemLookupApiGetPersonRemoteSearchResultsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemLookupApi
     */
    public getPersonRemoteSearchResults(requestParameters: ItemLookupApiGetPersonRemoteSearchResultsRequest, options?: any) {
        return ItemLookupApiFp(this.configuration).getPersonRemoteSearchResults(requestParameters.personLookupInfoRemoteSearchQuery, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets a remote image.
     * @param {ItemLookupApiGetRemoteSearchImageRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemLookupApi
     */
    public getRemoteSearchImage(requestParameters: ItemLookupApiGetRemoteSearchImageRequest, options?: any) {
        return ItemLookupApiFp(this.configuration).getRemoteSearchImage(requestParameters.imageUrl, requestParameters.providerName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get series remote search.
     * @param {ItemLookupApiGetSeriesRemoteSearchResultsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemLookupApi
     */
    public getSeriesRemoteSearchResults(requestParameters: ItemLookupApiGetSeriesRemoteSearchResultsRequest, options?: any) {
        return ItemLookupApiFp(this.configuration).getSeriesRemoteSearchResults(requestParameters.seriesInfoRemoteSearchQuery, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get trailer remote search.
     * @param {ItemLookupApiGetTrailerRemoteSearchResultsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemLookupApi
     */
    public getTrailerRemoteSearchResults(requestParameters: ItemLookupApiGetTrailerRemoteSearchResultsRequest, options?: any) {
        return ItemLookupApiFp(this.configuration).getTrailerRemoteSearchResults(requestParameters.trailerInfoRemoteSearchQuery, options).then((request) => request(this.axios, this.basePath));
    }
}
