/* tslint:disable */
/* eslint-disable */
/**
 * Jellyfin API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { BaseItemPersonImageBlurHashesPrimary } from './base-item-person-image-blur-hashes-primary';

/**
 * Gets or sets the primary image blurhash.
 * @export
 * @interface BaseItemPersonImageBlurHashes
 */
export interface BaseItemPersonImageBlurHashes {
    /**
     * 
     * @type {BaseItemPersonImageBlurHashesPrimary}
     * @memberof BaseItemPersonImageBlurHashes
     */
    Primary?: BaseItemPersonImageBlurHashesPrimary;
    /**
     * 
     * @type {BaseItemPersonImageBlurHashesPrimary}
     * @memberof BaseItemPersonImageBlurHashes
     */
    Art?: BaseItemPersonImageBlurHashesPrimary;
    /**
     * 
     * @type {BaseItemPersonImageBlurHashesPrimary}
     * @memberof BaseItemPersonImageBlurHashes
     */
    Backdrop?: BaseItemPersonImageBlurHashesPrimary;
    /**
     * 
     * @type {BaseItemPersonImageBlurHashesPrimary}
     * @memberof BaseItemPersonImageBlurHashes
     */
    Banner?: BaseItemPersonImageBlurHashesPrimary;
    /**
     * 
     * @type {BaseItemPersonImageBlurHashesPrimary}
     * @memberof BaseItemPersonImageBlurHashes
     */
    Logo?: BaseItemPersonImageBlurHashesPrimary;
    /**
     * 
     * @type {BaseItemPersonImageBlurHashesPrimary}
     * @memberof BaseItemPersonImageBlurHashes
     */
    Thumb?: BaseItemPersonImageBlurHashesPrimary;
    /**
     * 
     * @type {BaseItemPersonImageBlurHashesPrimary}
     * @memberof BaseItemPersonImageBlurHashes
     */
    Disc?: BaseItemPersonImageBlurHashesPrimary;
    /**
     * 
     * @type {BaseItemPersonImageBlurHashesPrimary}
     * @memberof BaseItemPersonImageBlurHashes
     */
    Box?: BaseItemPersonImageBlurHashesPrimary;
    /**
     * 
     * @type {BaseItemPersonImageBlurHashesPrimary}
     * @memberof BaseItemPersonImageBlurHashes
     */
    Screenshot?: BaseItemPersonImageBlurHashesPrimary;
    /**
     * 
     * @type {BaseItemPersonImageBlurHashesPrimary}
     * @memberof BaseItemPersonImageBlurHashes
     */
    Menu?: BaseItemPersonImageBlurHashesPrimary;
    /**
     * 
     * @type {BaseItemPersonImageBlurHashesPrimary}
     * @memberof BaseItemPersonImageBlurHashes
     */
    Chapter?: BaseItemPersonImageBlurHashesPrimary;
    /**
     * 
     * @type {BaseItemPersonImageBlurHashesPrimary}
     * @memberof BaseItemPersonImageBlurHashes
     */
    BoxRear?: BaseItemPersonImageBlurHashesPrimary;
    /**
     * 
     * @type {BaseItemPersonImageBlurHashesPrimary}
     * @memberof BaseItemPersonImageBlurHashes
     */
    Profile?: BaseItemPersonImageBlurHashesPrimary;
}


