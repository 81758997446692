/* tslint:disable */
/* eslint-disable */
/**
 * Jellyfin API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { BaseItemDtoQueryResult } from '../models';
// @ts-ignore
import { ImageType } from '../models';
// @ts-ignore
import { ProblemDetails } from '../models';
/**
 * TvShowsApi - axios parameter creator
 * @export
 */
export const TvShowsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Gets episodes for a tv season.
         * @param {string} seriesId The series id.
         * @param {string} [userId] The user id.
         * @param {string} [fields] Optional. Specify additional fields of information to return in the output. This allows multiple, comma delimeted. Options: Budget, Chapters, DateCreated, Genres, HomePageUrl, IndexOptions, MediaStreams, Overview, ParentId, Path, People, ProviderIds, PrimaryImageAspectRatio, Revenue, SortName, Studios, Taglines, TrailerUrls.
         * @param {number} [season] Optional filter by season number.
         * @param {string} [seasonId] Optional. Filter by season id.
         * @param {boolean} [isMissing] Optional. Filter by items that are missing episodes or not.
         * @param {string} [adjacentTo] Optional. Return items that are siblings of a supplied item.
         * @param {string} [startItemId] Optional. Skip through the list until a given item is found.
         * @param {number} [startIndex] Optional. The record index to start at. All items with a lower index will be dropped from the results.
         * @param {number} [limit] Optional. The maximum number of records to return.
         * @param {boolean} [enableImages] Optional, include image information in output.
         * @param {number} [imageTypeLimit] Optional, the max number of images to return, per image type.
         * @param {Array<ImageType>} [enableImageTypes] Optional. The image types to include in the output.
         * @param {boolean} [enableUserData] Optional. Include user data.
         * @param {string} [sortBy] Optional. Specify one or more sort orders, comma delimeted. Options: Album, AlbumArtist, Artist, Budget, CommunityRating, CriticRating, DateCreated, DatePlayed, PlayCount, PremiereDate, ProductionYear, SortName, Random, Revenue, Runtime.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEpisodes: async (seriesId: string, userId?: string, fields?: string, season?: number, seasonId?: string, isMissing?: boolean, adjacentTo?: string, startItemId?: string, startIndex?: number, limit?: number, enableImages?: boolean, imageTypeLimit?: number, enableImageTypes?: Array<ImageType>, enableUserData?: boolean, sortBy?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'seriesId' is not null or undefined
            if (seriesId === null || seriesId === undefined) {
                throw new RequiredError('seriesId','Required parameter seriesId was null or undefined when calling getEpisodes.');
            }
            const localVarPath = `/Shows/{seriesId}/Episodes`
                .replace(`{${"seriesId"}}`, encodeURIComponent(String(seriesId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }

            if (fields !== undefined) {
                localVarQueryParameter['fields'] = fields;
            }

            if (season !== undefined) {
                localVarQueryParameter['season'] = season;
            }

            if (seasonId !== undefined) {
                localVarQueryParameter['seasonId'] = seasonId;
            }

            if (isMissing !== undefined) {
                localVarQueryParameter['isMissing'] = isMissing;
            }

            if (adjacentTo !== undefined) {
                localVarQueryParameter['adjacentTo'] = adjacentTo;
            }

            if (startItemId !== undefined) {
                localVarQueryParameter['startItemId'] = startItemId;
            }

            if (startIndex !== undefined) {
                localVarQueryParameter['startIndex'] = startIndex;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (enableImages !== undefined) {
                localVarQueryParameter['enableImages'] = enableImages;
            }

            if (imageTypeLimit !== undefined) {
                localVarQueryParameter['imageTypeLimit'] = imageTypeLimit;
            }

            if (enableImageTypes) {
                localVarQueryParameter['enableImageTypes'] = enableImageTypes;
            }

            if (enableUserData !== undefined) {
                localVarQueryParameter['enableUserData'] = enableUserData;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets a list of next up episodes.
         * @param {string} [userId] The user id of the user to get the next up episodes for.
         * @param {number} [startIndex] Optional. The record index to start at. All items with a lower index will be dropped from the results.
         * @param {number} [limit] Optional. The maximum number of records to return.
         * @param {string} [fields] Optional. Specify additional fields of information to return in the output. This allows multiple, comma delimeted. Options: Budget, Chapters, DateCreated, Genres, HomePageUrl, IndexOptions, MediaStreams, Overview, ParentId, Path, People, ProviderIds, PrimaryImageAspectRatio, Revenue, SortName, Studios, Taglines, TrailerUrls.
         * @param {string} [seriesId] Optional. Filter by series id.
         * @param {string} [parentId] Optional. Specify this to localize the search to a specific item or folder. Omit to use the root.
         * @param {boolean} [enableImges] Optional. Include image information in output.
         * @param {number} [imageTypeLimit] Optional. The max number of images to return, per image type.
         * @param {Array<ImageType>} [enableImageTypes] Optional. The image types to include in the output.
         * @param {boolean} [enableUserData] Optional. Include user data.
         * @param {boolean} [enableTotalRecordCount] Whether to enable the total records count. Defaults to true.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNextUp: async (userId?: string, startIndex?: number, limit?: number, fields?: string, seriesId?: string, parentId?: string, enableImges?: boolean, imageTypeLimit?: number, enableImageTypes?: Array<ImageType>, enableUserData?: boolean, enableTotalRecordCount?: boolean, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/Shows/NextUp`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }

            if (startIndex !== undefined) {
                localVarQueryParameter['startIndex'] = startIndex;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (fields !== undefined) {
                localVarQueryParameter['fields'] = fields;
            }

            if (seriesId !== undefined) {
                localVarQueryParameter['seriesId'] = seriesId;
            }

            if (parentId !== undefined) {
                localVarQueryParameter['parentId'] = parentId;
            }

            if (enableImges !== undefined) {
                localVarQueryParameter['enableImges'] = enableImges;
            }

            if (imageTypeLimit !== undefined) {
                localVarQueryParameter['imageTypeLimit'] = imageTypeLimit;
            }

            if (enableImageTypes) {
                localVarQueryParameter['enableImageTypes'] = enableImageTypes;
            }

            if (enableUserData !== undefined) {
                localVarQueryParameter['enableUserData'] = enableUserData;
            }

            if (enableTotalRecordCount !== undefined) {
                localVarQueryParameter['enableTotalRecordCount'] = enableTotalRecordCount;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets seasons for a tv series.
         * @param {string} seriesId The series id.
         * @param {string} [userId] The user id.
         * @param {string} [fields] Optional. Specify additional fields of information to return in the output. This allows multiple, comma delimeted. Options: Budget, Chapters, DateCreated, Genres, HomePageUrl, IndexOptions, MediaStreams, Overview, ParentId, Path, People, ProviderIds, PrimaryImageAspectRatio, Revenue, SortName, Studios, Taglines, TrailerUrls.
         * @param {boolean} [isSpecialSeason] Optional. Filter by special season.
         * @param {boolean} [isMissing] Optional. Filter by items that are missing episodes or not.
         * @param {string} [adjacentTo] Optional. Return items that are siblings of a supplied item.
         * @param {boolean} [enableImages] Optional. Include image information in output.
         * @param {number} [imageTypeLimit] Optional. The max number of images to return, per image type.
         * @param {Array<ImageType>} [enableImageTypes] Optional. The image types to include in the output.
         * @param {boolean} [enableUserData] Optional. Include user data.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSeasons: async (seriesId: string, userId?: string, fields?: string, isSpecialSeason?: boolean, isMissing?: boolean, adjacentTo?: string, enableImages?: boolean, imageTypeLimit?: number, enableImageTypes?: Array<ImageType>, enableUserData?: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'seriesId' is not null or undefined
            if (seriesId === null || seriesId === undefined) {
                throw new RequiredError('seriesId','Required parameter seriesId was null or undefined when calling getSeasons.');
            }
            const localVarPath = `/Shows/{seriesId}/Seasons`
                .replace(`{${"seriesId"}}`, encodeURIComponent(String(seriesId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }

            if (fields !== undefined) {
                localVarQueryParameter['fields'] = fields;
            }

            if (isSpecialSeason !== undefined) {
                localVarQueryParameter['isSpecialSeason'] = isSpecialSeason;
            }

            if (isMissing !== undefined) {
                localVarQueryParameter['isMissing'] = isMissing;
            }

            if (adjacentTo !== undefined) {
                localVarQueryParameter['adjacentTo'] = adjacentTo;
            }

            if (enableImages !== undefined) {
                localVarQueryParameter['enableImages'] = enableImages;
            }

            if (imageTypeLimit !== undefined) {
                localVarQueryParameter['imageTypeLimit'] = imageTypeLimit;
            }

            if (enableImageTypes) {
                localVarQueryParameter['enableImageTypes'] = enableImageTypes;
            }

            if (enableUserData !== undefined) {
                localVarQueryParameter['enableUserData'] = enableUserData;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets a list of upcoming episodes.
         * @param {string} [userId] The user id of the user to get the upcoming episodes for.
         * @param {number} [startIndex] Optional. The record index to start at. All items with a lower index will be dropped from the results.
         * @param {number} [limit] Optional. The maximum number of records to return.
         * @param {string} [fields] Optional. Specify additional fields of information to return in the output. This allows multiple, comma delimeted. Options: Budget, Chapters, DateCreated, Genres, HomePageUrl, IndexOptions, MediaStreams, Overview, ParentId, Path, People, ProviderIds, PrimaryImageAspectRatio, Revenue, SortName, Studios, Taglines, TrailerUrls.
         * @param {string} [parentId] Optional. Specify this to localize the search to a specific item or folder. Omit to use the root.
         * @param {boolean} [enableImges] Optional. Include image information in output.
         * @param {number} [imageTypeLimit] Optional. The max number of images to return, per image type.
         * @param {Array<ImageType>} [enableImageTypes] Optional. The image types to include in the output.
         * @param {boolean} [enableUserData] Optional. Include user data.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUpcomingEpisodes: async (userId?: string, startIndex?: number, limit?: number, fields?: string, parentId?: string, enableImges?: boolean, imageTypeLimit?: number, enableImageTypes?: Array<ImageType>, enableUserData?: boolean, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/Shows/Upcoming`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }

            if (startIndex !== undefined) {
                localVarQueryParameter['startIndex'] = startIndex;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (fields !== undefined) {
                localVarQueryParameter['fields'] = fields;
            }

            if (parentId !== undefined) {
                localVarQueryParameter['parentId'] = parentId;
            }

            if (enableImges !== undefined) {
                localVarQueryParameter['enableImges'] = enableImges;
            }

            if (imageTypeLimit !== undefined) {
                localVarQueryParameter['imageTypeLimit'] = imageTypeLimit;
            }

            if (enableImageTypes) {
                localVarQueryParameter['enableImageTypes'] = enableImageTypes;
            }

            if (enableUserData !== undefined) {
                localVarQueryParameter['enableUserData'] = enableUserData;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TvShowsApi - functional programming interface
 * @export
 */
export const TvShowsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Gets episodes for a tv season.
         * @param {string} seriesId The series id.
         * @param {string} [userId] The user id.
         * @param {string} [fields] Optional. Specify additional fields of information to return in the output. This allows multiple, comma delimeted. Options: Budget, Chapters, DateCreated, Genres, HomePageUrl, IndexOptions, MediaStreams, Overview, ParentId, Path, People, ProviderIds, PrimaryImageAspectRatio, Revenue, SortName, Studios, Taglines, TrailerUrls.
         * @param {number} [season] Optional filter by season number.
         * @param {string} [seasonId] Optional. Filter by season id.
         * @param {boolean} [isMissing] Optional. Filter by items that are missing episodes or not.
         * @param {string} [adjacentTo] Optional. Return items that are siblings of a supplied item.
         * @param {string} [startItemId] Optional. Skip through the list until a given item is found.
         * @param {number} [startIndex] Optional. The record index to start at. All items with a lower index will be dropped from the results.
         * @param {number} [limit] Optional. The maximum number of records to return.
         * @param {boolean} [enableImages] Optional, include image information in output.
         * @param {number} [imageTypeLimit] Optional, the max number of images to return, per image type.
         * @param {Array<ImageType>} [enableImageTypes] Optional. The image types to include in the output.
         * @param {boolean} [enableUserData] Optional. Include user data.
         * @param {string} [sortBy] Optional. Specify one or more sort orders, comma delimeted. Options: Album, AlbumArtist, Artist, Budget, CommunityRating, CriticRating, DateCreated, DatePlayed, PlayCount, PremiereDate, ProductionYear, SortName, Random, Revenue, Runtime.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEpisodes(seriesId: string, userId?: string, fields?: string, season?: number, seasonId?: string, isMissing?: boolean, adjacentTo?: string, startItemId?: string, startIndex?: number, limit?: number, enableImages?: boolean, imageTypeLimit?: number, enableImageTypes?: Array<ImageType>, enableUserData?: boolean, sortBy?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BaseItemDtoQueryResult>> {
            const localVarAxiosArgs = await TvShowsApiAxiosParamCreator(configuration).getEpisodes(seriesId, userId, fields, season, seasonId, isMissing, adjacentTo, startItemId, startIndex, limit, enableImages, imageTypeLimit, enableImageTypes, enableUserData, sortBy, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Gets a list of next up episodes.
         * @param {string} [userId] The user id of the user to get the next up episodes for.
         * @param {number} [startIndex] Optional. The record index to start at. All items with a lower index will be dropped from the results.
         * @param {number} [limit] Optional. The maximum number of records to return.
         * @param {string} [fields] Optional. Specify additional fields of information to return in the output. This allows multiple, comma delimeted. Options: Budget, Chapters, DateCreated, Genres, HomePageUrl, IndexOptions, MediaStreams, Overview, ParentId, Path, People, ProviderIds, PrimaryImageAspectRatio, Revenue, SortName, Studios, Taglines, TrailerUrls.
         * @param {string} [seriesId] Optional. Filter by series id.
         * @param {string} [parentId] Optional. Specify this to localize the search to a specific item or folder. Omit to use the root.
         * @param {boolean} [enableImges] Optional. Include image information in output.
         * @param {number} [imageTypeLimit] Optional. The max number of images to return, per image type.
         * @param {Array<ImageType>} [enableImageTypes] Optional. The image types to include in the output.
         * @param {boolean} [enableUserData] Optional. Include user data.
         * @param {boolean} [enableTotalRecordCount] Whether to enable the total records count. Defaults to true.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNextUp(userId?: string, startIndex?: number, limit?: number, fields?: string, seriesId?: string, parentId?: string, enableImges?: boolean, imageTypeLimit?: number, enableImageTypes?: Array<ImageType>, enableUserData?: boolean, enableTotalRecordCount?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BaseItemDtoQueryResult>> {
            const localVarAxiosArgs = await TvShowsApiAxiosParamCreator(configuration).getNextUp(userId, startIndex, limit, fields, seriesId, parentId, enableImges, imageTypeLimit, enableImageTypes, enableUserData, enableTotalRecordCount, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Gets seasons for a tv series.
         * @param {string} seriesId The series id.
         * @param {string} [userId] The user id.
         * @param {string} [fields] Optional. Specify additional fields of information to return in the output. This allows multiple, comma delimeted. Options: Budget, Chapters, DateCreated, Genres, HomePageUrl, IndexOptions, MediaStreams, Overview, ParentId, Path, People, ProviderIds, PrimaryImageAspectRatio, Revenue, SortName, Studios, Taglines, TrailerUrls.
         * @param {boolean} [isSpecialSeason] Optional. Filter by special season.
         * @param {boolean} [isMissing] Optional. Filter by items that are missing episodes or not.
         * @param {string} [adjacentTo] Optional. Return items that are siblings of a supplied item.
         * @param {boolean} [enableImages] Optional. Include image information in output.
         * @param {number} [imageTypeLimit] Optional. The max number of images to return, per image type.
         * @param {Array<ImageType>} [enableImageTypes] Optional. The image types to include in the output.
         * @param {boolean} [enableUserData] Optional. Include user data.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSeasons(seriesId: string, userId?: string, fields?: string, isSpecialSeason?: boolean, isMissing?: boolean, adjacentTo?: string, enableImages?: boolean, imageTypeLimit?: number, enableImageTypes?: Array<ImageType>, enableUserData?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BaseItemDtoQueryResult>> {
            const localVarAxiosArgs = await TvShowsApiAxiosParamCreator(configuration).getSeasons(seriesId, userId, fields, isSpecialSeason, isMissing, adjacentTo, enableImages, imageTypeLimit, enableImageTypes, enableUserData, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Gets a list of upcoming episodes.
         * @param {string} [userId] The user id of the user to get the upcoming episodes for.
         * @param {number} [startIndex] Optional. The record index to start at. All items with a lower index will be dropped from the results.
         * @param {number} [limit] Optional. The maximum number of records to return.
         * @param {string} [fields] Optional. Specify additional fields of information to return in the output. This allows multiple, comma delimeted. Options: Budget, Chapters, DateCreated, Genres, HomePageUrl, IndexOptions, MediaStreams, Overview, ParentId, Path, People, ProviderIds, PrimaryImageAspectRatio, Revenue, SortName, Studios, Taglines, TrailerUrls.
         * @param {string} [parentId] Optional. Specify this to localize the search to a specific item or folder. Omit to use the root.
         * @param {boolean} [enableImges] Optional. Include image information in output.
         * @param {number} [imageTypeLimit] Optional. The max number of images to return, per image type.
         * @param {Array<ImageType>} [enableImageTypes] Optional. The image types to include in the output.
         * @param {boolean} [enableUserData] Optional. Include user data.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUpcomingEpisodes(userId?: string, startIndex?: number, limit?: number, fields?: string, parentId?: string, enableImges?: boolean, imageTypeLimit?: number, enableImageTypes?: Array<ImageType>, enableUserData?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BaseItemDtoQueryResult>> {
            const localVarAxiosArgs = await TvShowsApiAxiosParamCreator(configuration).getUpcomingEpisodes(userId, startIndex, limit, fields, parentId, enableImges, imageTypeLimit, enableImageTypes, enableUserData, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * TvShowsApi - factory interface
 * @export
 */
export const TvShowsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Gets episodes for a tv season.
         * @param {string} seriesId The series id.
         * @param {string} [userId] The user id.
         * @param {string} [fields] Optional. Specify additional fields of information to return in the output. This allows multiple, comma delimeted. Options: Budget, Chapters, DateCreated, Genres, HomePageUrl, IndexOptions, MediaStreams, Overview, ParentId, Path, People, ProviderIds, PrimaryImageAspectRatio, Revenue, SortName, Studios, Taglines, TrailerUrls.
         * @param {number} [season] Optional filter by season number.
         * @param {string} [seasonId] Optional. Filter by season id.
         * @param {boolean} [isMissing] Optional. Filter by items that are missing episodes or not.
         * @param {string} [adjacentTo] Optional. Return items that are siblings of a supplied item.
         * @param {string} [startItemId] Optional. Skip through the list until a given item is found.
         * @param {number} [startIndex] Optional. The record index to start at. All items with a lower index will be dropped from the results.
         * @param {number} [limit] Optional. The maximum number of records to return.
         * @param {boolean} [enableImages] Optional, include image information in output.
         * @param {number} [imageTypeLimit] Optional, the max number of images to return, per image type.
         * @param {Array<ImageType>} [enableImageTypes] Optional. The image types to include in the output.
         * @param {boolean} [enableUserData] Optional. Include user data.
         * @param {string} [sortBy] Optional. Specify one or more sort orders, comma delimeted. Options: Album, AlbumArtist, Artist, Budget, CommunityRating, CriticRating, DateCreated, DatePlayed, PlayCount, PremiereDate, ProductionYear, SortName, Random, Revenue, Runtime.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEpisodes(seriesId: string, userId?: string, fields?: string, season?: number, seasonId?: string, isMissing?: boolean, adjacentTo?: string, startItemId?: string, startIndex?: number, limit?: number, enableImages?: boolean, imageTypeLimit?: number, enableImageTypes?: Array<ImageType>, enableUserData?: boolean, sortBy?: string, options?: any): AxiosPromise<BaseItemDtoQueryResult> {
            return TvShowsApiFp(configuration).getEpisodes(seriesId, userId, fields, season, seasonId, isMissing, adjacentTo, startItemId, startIndex, limit, enableImages, imageTypeLimit, enableImageTypes, enableUserData, sortBy, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets a list of next up episodes.
         * @param {string} [userId] The user id of the user to get the next up episodes for.
         * @param {number} [startIndex] Optional. The record index to start at. All items with a lower index will be dropped from the results.
         * @param {number} [limit] Optional. The maximum number of records to return.
         * @param {string} [fields] Optional. Specify additional fields of information to return in the output. This allows multiple, comma delimeted. Options: Budget, Chapters, DateCreated, Genres, HomePageUrl, IndexOptions, MediaStreams, Overview, ParentId, Path, People, ProviderIds, PrimaryImageAspectRatio, Revenue, SortName, Studios, Taglines, TrailerUrls.
         * @param {string} [seriesId] Optional. Filter by series id.
         * @param {string} [parentId] Optional. Specify this to localize the search to a specific item or folder. Omit to use the root.
         * @param {boolean} [enableImges] Optional. Include image information in output.
         * @param {number} [imageTypeLimit] Optional. The max number of images to return, per image type.
         * @param {Array<ImageType>} [enableImageTypes] Optional. The image types to include in the output.
         * @param {boolean} [enableUserData] Optional. Include user data.
         * @param {boolean} [enableTotalRecordCount] Whether to enable the total records count. Defaults to true.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNextUp(userId?: string, startIndex?: number, limit?: number, fields?: string, seriesId?: string, parentId?: string, enableImges?: boolean, imageTypeLimit?: number, enableImageTypes?: Array<ImageType>, enableUserData?: boolean, enableTotalRecordCount?: boolean, options?: any): AxiosPromise<BaseItemDtoQueryResult> {
            return TvShowsApiFp(configuration).getNextUp(userId, startIndex, limit, fields, seriesId, parentId, enableImges, imageTypeLimit, enableImageTypes, enableUserData, enableTotalRecordCount, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets seasons for a tv series.
         * @param {string} seriesId The series id.
         * @param {string} [userId] The user id.
         * @param {string} [fields] Optional. Specify additional fields of information to return in the output. This allows multiple, comma delimeted. Options: Budget, Chapters, DateCreated, Genres, HomePageUrl, IndexOptions, MediaStreams, Overview, ParentId, Path, People, ProviderIds, PrimaryImageAspectRatio, Revenue, SortName, Studios, Taglines, TrailerUrls.
         * @param {boolean} [isSpecialSeason] Optional. Filter by special season.
         * @param {boolean} [isMissing] Optional. Filter by items that are missing episodes or not.
         * @param {string} [adjacentTo] Optional. Return items that are siblings of a supplied item.
         * @param {boolean} [enableImages] Optional. Include image information in output.
         * @param {number} [imageTypeLimit] Optional. The max number of images to return, per image type.
         * @param {Array<ImageType>} [enableImageTypes] Optional. The image types to include in the output.
         * @param {boolean} [enableUserData] Optional. Include user data.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSeasons(seriesId: string, userId?: string, fields?: string, isSpecialSeason?: boolean, isMissing?: boolean, adjacentTo?: string, enableImages?: boolean, imageTypeLimit?: number, enableImageTypes?: Array<ImageType>, enableUserData?: boolean, options?: any): AxiosPromise<BaseItemDtoQueryResult> {
            return TvShowsApiFp(configuration).getSeasons(seriesId, userId, fields, isSpecialSeason, isMissing, adjacentTo, enableImages, imageTypeLimit, enableImageTypes, enableUserData, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets a list of upcoming episodes.
         * @param {string} [userId] The user id of the user to get the upcoming episodes for.
         * @param {number} [startIndex] Optional. The record index to start at. All items with a lower index will be dropped from the results.
         * @param {number} [limit] Optional. The maximum number of records to return.
         * @param {string} [fields] Optional. Specify additional fields of information to return in the output. This allows multiple, comma delimeted. Options: Budget, Chapters, DateCreated, Genres, HomePageUrl, IndexOptions, MediaStreams, Overview, ParentId, Path, People, ProviderIds, PrimaryImageAspectRatio, Revenue, SortName, Studios, Taglines, TrailerUrls.
         * @param {string} [parentId] Optional. Specify this to localize the search to a specific item or folder. Omit to use the root.
         * @param {boolean} [enableImges] Optional. Include image information in output.
         * @param {number} [imageTypeLimit] Optional. The max number of images to return, per image type.
         * @param {Array<ImageType>} [enableImageTypes] Optional. The image types to include in the output.
         * @param {boolean} [enableUserData] Optional. Include user data.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUpcomingEpisodes(userId?: string, startIndex?: number, limit?: number, fields?: string, parentId?: string, enableImges?: boolean, imageTypeLimit?: number, enableImageTypes?: Array<ImageType>, enableUserData?: boolean, options?: any): AxiosPromise<BaseItemDtoQueryResult> {
            return TvShowsApiFp(configuration).getUpcomingEpisodes(userId, startIndex, limit, fields, parentId, enableImges, imageTypeLimit, enableImageTypes, enableUserData, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getEpisodes operation in TvShowsApi.
 * @export
 * @interface TvShowsApiGetEpisodesRequest
 */
export interface TvShowsApiGetEpisodesRequest {
    /**
     * The series id.
     * @type {string}
     * @memberof TvShowsApiGetEpisodes
     */
    readonly seriesId: string

    /**
     * The user id.
     * @type {string}
     * @memberof TvShowsApiGetEpisodes
     */
    readonly userId?: string

    /**
     * Optional. Specify additional fields of information to return in the output. This allows multiple, comma delimeted. Options: Budget, Chapters, DateCreated, Genres, HomePageUrl, IndexOptions, MediaStreams, Overview, ParentId, Path, People, ProviderIds, PrimaryImageAspectRatio, Revenue, SortName, Studios, Taglines, TrailerUrls.
     * @type {string}
     * @memberof TvShowsApiGetEpisodes
     */
    readonly fields?: string

    /**
     * Optional filter by season number.
     * @type {number}
     * @memberof TvShowsApiGetEpisodes
     */
    readonly season?: number

    /**
     * Optional. Filter by season id.
     * @type {string}
     * @memberof TvShowsApiGetEpisodes
     */
    readonly seasonId?: string

    /**
     * Optional. Filter by items that are missing episodes or not.
     * @type {boolean}
     * @memberof TvShowsApiGetEpisodes
     */
    readonly isMissing?: boolean

    /**
     * Optional. Return items that are siblings of a supplied item.
     * @type {string}
     * @memberof TvShowsApiGetEpisodes
     */
    readonly adjacentTo?: string

    /**
     * Optional. Skip through the list until a given item is found.
     * @type {string}
     * @memberof TvShowsApiGetEpisodes
     */
    readonly startItemId?: string

    /**
     * Optional. The record index to start at. All items with a lower index will be dropped from the results.
     * @type {number}
     * @memberof TvShowsApiGetEpisodes
     */
    readonly startIndex?: number

    /**
     * Optional. The maximum number of records to return.
     * @type {number}
     * @memberof TvShowsApiGetEpisodes
     */
    readonly limit?: number

    /**
     * Optional, include image information in output.
     * @type {boolean}
     * @memberof TvShowsApiGetEpisodes
     */
    readonly enableImages?: boolean

    /**
     * Optional, the max number of images to return, per image type.
     * @type {number}
     * @memberof TvShowsApiGetEpisodes
     */
    readonly imageTypeLimit?: number

    /**
     * Optional. The image types to include in the output.
     * @type {Array<ImageType>}
     * @memberof TvShowsApiGetEpisodes
     */
    readonly enableImageTypes?: Array<ImageType>

    /**
     * Optional. Include user data.
     * @type {boolean}
     * @memberof TvShowsApiGetEpisodes
     */
    readonly enableUserData?: boolean

    /**
     * Optional. Specify one or more sort orders, comma delimeted. Options: Album, AlbumArtist, Artist, Budget, CommunityRating, CriticRating, DateCreated, DatePlayed, PlayCount, PremiereDate, ProductionYear, SortName, Random, Revenue, Runtime.
     * @type {string}
     * @memberof TvShowsApiGetEpisodes
     */
    readonly sortBy?: string
}

/**
 * Request parameters for getNextUp operation in TvShowsApi.
 * @export
 * @interface TvShowsApiGetNextUpRequest
 */
export interface TvShowsApiGetNextUpRequest {
    /**
     * The user id of the user to get the next up episodes for.
     * @type {string}
     * @memberof TvShowsApiGetNextUp
     */
    readonly userId?: string

    /**
     * Optional. The record index to start at. All items with a lower index will be dropped from the results.
     * @type {number}
     * @memberof TvShowsApiGetNextUp
     */
    readonly startIndex?: number

    /**
     * Optional. The maximum number of records to return.
     * @type {number}
     * @memberof TvShowsApiGetNextUp
     */
    readonly limit?: number

    /**
     * Optional. Specify additional fields of information to return in the output. This allows multiple, comma delimeted. Options: Budget, Chapters, DateCreated, Genres, HomePageUrl, IndexOptions, MediaStreams, Overview, ParentId, Path, People, ProviderIds, PrimaryImageAspectRatio, Revenue, SortName, Studios, Taglines, TrailerUrls.
     * @type {string}
     * @memberof TvShowsApiGetNextUp
     */
    readonly fields?: string

    /**
     * Optional. Filter by series id.
     * @type {string}
     * @memberof TvShowsApiGetNextUp
     */
    readonly seriesId?: string

    /**
     * Optional. Specify this to localize the search to a specific item or folder. Omit to use the root.
     * @type {string}
     * @memberof TvShowsApiGetNextUp
     */
    readonly parentId?: string

    /**
     * Optional. Include image information in output.
     * @type {boolean}
     * @memberof TvShowsApiGetNextUp
     */
    readonly enableImges?: boolean

    /**
     * Optional. The max number of images to return, per image type.
     * @type {number}
     * @memberof TvShowsApiGetNextUp
     */
    readonly imageTypeLimit?: number

    /**
     * Optional. The image types to include in the output.
     * @type {Array<ImageType>}
     * @memberof TvShowsApiGetNextUp
     */
    readonly enableImageTypes?: Array<ImageType>

    /**
     * Optional. Include user data.
     * @type {boolean}
     * @memberof TvShowsApiGetNextUp
     */
    readonly enableUserData?: boolean

    /**
     * Whether to enable the total records count. Defaults to true.
     * @type {boolean}
     * @memberof TvShowsApiGetNextUp
     */
    readonly enableTotalRecordCount?: boolean
}

/**
 * Request parameters for getSeasons operation in TvShowsApi.
 * @export
 * @interface TvShowsApiGetSeasonsRequest
 */
export interface TvShowsApiGetSeasonsRequest {
    /**
     * The series id.
     * @type {string}
     * @memberof TvShowsApiGetSeasons
     */
    readonly seriesId: string

    /**
     * The user id.
     * @type {string}
     * @memberof TvShowsApiGetSeasons
     */
    readonly userId?: string

    /**
     * Optional. Specify additional fields of information to return in the output. This allows multiple, comma delimeted. Options: Budget, Chapters, DateCreated, Genres, HomePageUrl, IndexOptions, MediaStreams, Overview, ParentId, Path, People, ProviderIds, PrimaryImageAspectRatio, Revenue, SortName, Studios, Taglines, TrailerUrls.
     * @type {string}
     * @memberof TvShowsApiGetSeasons
     */
    readonly fields?: string

    /**
     * Optional. Filter by special season.
     * @type {boolean}
     * @memberof TvShowsApiGetSeasons
     */
    readonly isSpecialSeason?: boolean

    /**
     * Optional. Filter by items that are missing episodes or not.
     * @type {boolean}
     * @memberof TvShowsApiGetSeasons
     */
    readonly isMissing?: boolean

    /**
     * Optional. Return items that are siblings of a supplied item.
     * @type {string}
     * @memberof TvShowsApiGetSeasons
     */
    readonly adjacentTo?: string

    /**
     * Optional. Include image information in output.
     * @type {boolean}
     * @memberof TvShowsApiGetSeasons
     */
    readonly enableImages?: boolean

    /**
     * Optional. The max number of images to return, per image type.
     * @type {number}
     * @memberof TvShowsApiGetSeasons
     */
    readonly imageTypeLimit?: number

    /**
     * Optional. The image types to include in the output.
     * @type {Array<ImageType>}
     * @memberof TvShowsApiGetSeasons
     */
    readonly enableImageTypes?: Array<ImageType>

    /**
     * Optional. Include user data.
     * @type {boolean}
     * @memberof TvShowsApiGetSeasons
     */
    readonly enableUserData?: boolean
}

/**
 * Request parameters for getUpcomingEpisodes operation in TvShowsApi.
 * @export
 * @interface TvShowsApiGetUpcomingEpisodesRequest
 */
export interface TvShowsApiGetUpcomingEpisodesRequest {
    /**
     * The user id of the user to get the upcoming episodes for.
     * @type {string}
     * @memberof TvShowsApiGetUpcomingEpisodes
     */
    readonly userId?: string

    /**
     * Optional. The record index to start at. All items with a lower index will be dropped from the results.
     * @type {number}
     * @memberof TvShowsApiGetUpcomingEpisodes
     */
    readonly startIndex?: number

    /**
     * Optional. The maximum number of records to return.
     * @type {number}
     * @memberof TvShowsApiGetUpcomingEpisodes
     */
    readonly limit?: number

    /**
     * Optional. Specify additional fields of information to return in the output. This allows multiple, comma delimeted. Options: Budget, Chapters, DateCreated, Genres, HomePageUrl, IndexOptions, MediaStreams, Overview, ParentId, Path, People, ProviderIds, PrimaryImageAspectRatio, Revenue, SortName, Studios, Taglines, TrailerUrls.
     * @type {string}
     * @memberof TvShowsApiGetUpcomingEpisodes
     */
    readonly fields?: string

    /**
     * Optional. Specify this to localize the search to a specific item or folder. Omit to use the root.
     * @type {string}
     * @memberof TvShowsApiGetUpcomingEpisodes
     */
    readonly parentId?: string

    /**
     * Optional. Include image information in output.
     * @type {boolean}
     * @memberof TvShowsApiGetUpcomingEpisodes
     */
    readonly enableImges?: boolean

    /**
     * Optional. The max number of images to return, per image type.
     * @type {number}
     * @memberof TvShowsApiGetUpcomingEpisodes
     */
    readonly imageTypeLimit?: number

    /**
     * Optional. The image types to include in the output.
     * @type {Array<ImageType>}
     * @memberof TvShowsApiGetUpcomingEpisodes
     */
    readonly enableImageTypes?: Array<ImageType>

    /**
     * Optional. Include user data.
     * @type {boolean}
     * @memberof TvShowsApiGetUpcomingEpisodes
     */
    readonly enableUserData?: boolean
}

/**
 * TvShowsApi - object-oriented interface
 * @export
 * @class TvShowsApi
 * @extends {BaseAPI}
 */
export class TvShowsApi extends BaseAPI {
    /**
     * 
     * @summary Gets episodes for a tv season.
     * @param {TvShowsApiGetEpisodesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TvShowsApi
     */
    public getEpisodes(requestParameters: TvShowsApiGetEpisodesRequest, options?: any) {
        return TvShowsApiFp(this.configuration).getEpisodes(requestParameters.seriesId, requestParameters.userId, requestParameters.fields, requestParameters.season, requestParameters.seasonId, requestParameters.isMissing, requestParameters.adjacentTo, requestParameters.startItemId, requestParameters.startIndex, requestParameters.limit, requestParameters.enableImages, requestParameters.imageTypeLimit, requestParameters.enableImageTypes, requestParameters.enableUserData, requestParameters.sortBy, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets a list of next up episodes.
     * @param {TvShowsApiGetNextUpRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TvShowsApi
     */
    public getNextUp(requestParameters: TvShowsApiGetNextUpRequest = {}, options?: any) {
        return TvShowsApiFp(this.configuration).getNextUp(requestParameters.userId, requestParameters.startIndex, requestParameters.limit, requestParameters.fields, requestParameters.seriesId, requestParameters.parentId, requestParameters.enableImges, requestParameters.imageTypeLimit, requestParameters.enableImageTypes, requestParameters.enableUserData, requestParameters.enableTotalRecordCount, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets seasons for a tv series.
     * @param {TvShowsApiGetSeasonsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TvShowsApi
     */
    public getSeasons(requestParameters: TvShowsApiGetSeasonsRequest, options?: any) {
        return TvShowsApiFp(this.configuration).getSeasons(requestParameters.seriesId, requestParameters.userId, requestParameters.fields, requestParameters.isSpecialSeason, requestParameters.isMissing, requestParameters.adjacentTo, requestParameters.enableImages, requestParameters.imageTypeLimit, requestParameters.enableImageTypes, requestParameters.enableUserData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets a list of upcoming episodes.
     * @param {TvShowsApiGetUpcomingEpisodesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TvShowsApi
     */
    public getUpcomingEpisodes(requestParameters: TvShowsApiGetUpcomingEpisodesRequest = {}, options?: any) {
        return TvShowsApiFp(this.configuration).getUpcomingEpisodes(requestParameters.userId, requestParameters.startIndex, requestParameters.limit, requestParameters.fields, requestParameters.parentId, requestParameters.enableImges, requestParameters.imageTypeLimit, requestParameters.enableImageTypes, requestParameters.enableUserData, options).then((request) => request(this.axios, this.basePath));
    }
}
