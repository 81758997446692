/* tslint:disable */
/* eslint-disable */
/**
 * Jellyfin API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ControlResponse } from '../models';
/**
 * DlnaServerApi - axios parameter creator
 * @export
 */
export const DlnaServerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Gets Dlna media receiver registrar xml.
         * @param {string} serverId Server UUID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConnectionManager: async (serverId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'serverId' is not null or undefined
            if (serverId === null || serverId === undefined) {
                throw new RequiredError('serverId','Required parameter serverId was null or undefined when calling getConnectionManager.');
            }
            const localVarPath = `/Dlna/{serverId}/ConnectionManager`
                .replace(`{${"serverId"}}`, encodeURIComponent(String(serverId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets Dlna media receiver registrar xml.
         * @param {string} serverId Server UUID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConnectionManager2: async (serverId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'serverId' is not null or undefined
            if (serverId === null || serverId === undefined) {
                throw new RequiredError('serverId','Required parameter serverId was null or undefined when calling getConnectionManager2.');
            }
            const localVarPath = `/Dlna/{serverId}/ConnectionManager/ConnectionManager`
                .replace(`{${"serverId"}}`, encodeURIComponent(String(serverId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets Dlna media receiver registrar xml.
         * @param {string} serverId Server UUID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConnectionManager3: async (serverId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'serverId' is not null or undefined
            if (serverId === null || serverId === undefined) {
                throw new RequiredError('serverId','Required parameter serverId was null or undefined when calling getConnectionManager3.');
            }
            const localVarPath = `/Dlna/{serverId}/ConnectionManager/ConnectionManager.xml`
                .replace(`{${"serverId"}}`, encodeURIComponent(String(serverId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets Dlna content directory xml.
         * @param {string} serverId Server UUID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContentDirectory: async (serverId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'serverId' is not null or undefined
            if (serverId === null || serverId === undefined) {
                throw new RequiredError('serverId','Required parameter serverId was null or undefined when calling getContentDirectory.');
            }
            const localVarPath = `/Dlna/{serverId}/ContentDirectory`
                .replace(`{${"serverId"}}`, encodeURIComponent(String(serverId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets Dlna content directory xml.
         * @param {string} serverId Server UUID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContentDirectory2: async (serverId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'serverId' is not null or undefined
            if (serverId === null || serverId === undefined) {
                throw new RequiredError('serverId','Required parameter serverId was null or undefined when calling getContentDirectory2.');
            }
            const localVarPath = `/Dlna/{serverId}/ContentDirectory/ContentDirectory`
                .replace(`{${"serverId"}}`, encodeURIComponent(String(serverId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets Dlna content directory xml.
         * @param {string} serverId Server UUID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContentDirectory3: async (serverId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'serverId' is not null or undefined
            if (serverId === null || serverId === undefined) {
                throw new RequiredError('serverId','Required parameter serverId was null or undefined when calling getContentDirectory3.');
            }
            const localVarPath = `/Dlna/{serverId}/ContentDirectory/ContentDirectory.xml`
                .replace(`{${"serverId"}}`, encodeURIComponent(String(serverId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Description Xml.
         * @param {string} serverId Server UUID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDescriptionXml: async (serverId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'serverId' is not null or undefined
            if (serverId === null || serverId === undefined) {
                throw new RequiredError('serverId','Required parameter serverId was null or undefined when calling getDescriptionXml.');
            }
            const localVarPath = `/Dlna/{serverId}/description`
                .replace(`{${"serverId"}}`, encodeURIComponent(String(serverId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Description Xml.
         * @param {string} serverId Server UUID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDescriptionXml2: async (serverId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'serverId' is not null or undefined
            if (serverId === null || serverId === undefined) {
                throw new RequiredError('serverId','Required parameter serverId was null or undefined when calling getDescriptionXml2.');
            }
            const localVarPath = `/Dlna/{serverId}/description.xml`
                .replace(`{${"serverId"}}`, encodeURIComponent(String(serverId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets a server icon.
         * @param {string} fileName The icon filename.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIcon: async (fileName: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'fileName' is not null or undefined
            if (fileName === null || fileName === undefined) {
                throw new RequiredError('fileName','Required parameter fileName was null or undefined when calling getIcon.');
            }
            const localVarPath = `/Dlna/icons/{fileName}`
                .replace(`{${"fileName"}}`, encodeURIComponent(String(fileName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets a server icon.
         * @param {string} serverId Server UUID.
         * @param {string} fileName The icon filename.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIconId: async (serverId: string, fileName: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'serverId' is not null or undefined
            if (serverId === null || serverId === undefined) {
                throw new RequiredError('serverId','Required parameter serverId was null or undefined when calling getIconId.');
            }
            // verify required parameter 'fileName' is not null or undefined
            if (fileName === null || fileName === undefined) {
                throw new RequiredError('fileName','Required parameter fileName was null or undefined when calling getIconId.');
            }
            const localVarPath = `/Dlna/{serverId}/icons/{fileName}`
                .replace(`{${"serverId"}}`, encodeURIComponent(String(serverId)))
                .replace(`{${"fileName"}}`, encodeURIComponent(String(fileName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets Dlna media receiver registrar xml.
         * @param {string} serverId Server UUID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMediaReceiverRegistrar: async (serverId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'serverId' is not null or undefined
            if (serverId === null || serverId === undefined) {
                throw new RequiredError('serverId','Required parameter serverId was null or undefined when calling getMediaReceiverRegistrar.');
            }
            const localVarPath = `/Dlna/{serverId}/MediaReceiverRegistrar`
                .replace(`{${"serverId"}}`, encodeURIComponent(String(serverId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets Dlna media receiver registrar xml.
         * @param {string} serverId Server UUID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMediaReceiverRegistrar2: async (serverId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'serverId' is not null or undefined
            if (serverId === null || serverId === undefined) {
                throw new RequiredError('serverId','Required parameter serverId was null or undefined when calling getMediaReceiverRegistrar2.');
            }
            const localVarPath = `/Dlna/{serverId}/MediaReceiverRegistrar/MediaReceiverRegistrar`
                .replace(`{${"serverId"}}`, encodeURIComponent(String(serverId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets Dlna media receiver registrar xml.
         * @param {string} serverId Server UUID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMediaReceiverRegistrar3: async (serverId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'serverId' is not null or undefined
            if (serverId === null || serverId === undefined) {
                throw new RequiredError('serverId','Required parameter serverId was null or undefined when calling getMediaReceiverRegistrar3.');
            }
            const localVarPath = `/Dlna/{serverId}/MediaReceiverRegistrar/MediaReceiverRegistrar.xml`
                .replace(`{${"serverId"}}`, encodeURIComponent(String(serverId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Process a connection manager control request.
         * @param {string} serverId Server UUID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processConnectionManagerControlRequest: async (serverId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'serverId' is not null or undefined
            if (serverId === null || serverId === undefined) {
                throw new RequiredError('serverId','Required parameter serverId was null or undefined when calling processConnectionManagerControlRequest.');
            }
            const localVarPath = `/Dlna/{serverId}/ConnectionManager/Control`
                .replace(`{${"serverId"}}`, encodeURIComponent(String(serverId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Process a content directory control request.
         * @param {string} serverId Server UUID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processContentDirectoryControlRequest: async (serverId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'serverId' is not null or undefined
            if (serverId === null || serverId === undefined) {
                throw new RequiredError('serverId','Required parameter serverId was null or undefined when calling processContentDirectoryControlRequest.');
            }
            const localVarPath = `/Dlna/{serverId}/ContentDirectory/Control`
                .replace(`{${"serverId"}}`, encodeURIComponent(String(serverId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Process a media receiver registrar control request.
         * @param {string} serverId Server UUID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processMediaReceiverRegistrarControlRequest: async (serverId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'serverId' is not null or undefined
            if (serverId === null || serverId === undefined) {
                throw new RequiredError('serverId','Required parameter serverId was null or undefined when calling processMediaReceiverRegistrarControlRequest.');
            }
            const localVarPath = `/Dlna/{serverId}/MediaReceiverRegistrar/Control`
                .replace(`{${"serverId"}}`, encodeURIComponent(String(serverId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DlnaServerApi - functional programming interface
 * @export
 */
export const DlnaServerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Gets Dlna media receiver registrar xml.
         * @param {string} serverId Server UUID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getConnectionManager(serverId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await DlnaServerApiAxiosParamCreator(configuration).getConnectionManager(serverId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Gets Dlna media receiver registrar xml.
         * @param {string} serverId Server UUID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getConnectionManager2(serverId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await DlnaServerApiAxiosParamCreator(configuration).getConnectionManager2(serverId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Gets Dlna media receiver registrar xml.
         * @param {string} serverId Server UUID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getConnectionManager3(serverId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await DlnaServerApiAxiosParamCreator(configuration).getConnectionManager3(serverId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Gets Dlna content directory xml.
         * @param {string} serverId Server UUID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getContentDirectory(serverId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await DlnaServerApiAxiosParamCreator(configuration).getContentDirectory(serverId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Gets Dlna content directory xml.
         * @param {string} serverId Server UUID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getContentDirectory2(serverId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await DlnaServerApiAxiosParamCreator(configuration).getContentDirectory2(serverId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Gets Dlna content directory xml.
         * @param {string} serverId Server UUID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getContentDirectory3(serverId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await DlnaServerApiAxiosParamCreator(configuration).getContentDirectory3(serverId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get Description Xml.
         * @param {string} serverId Server UUID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDescriptionXml(serverId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await DlnaServerApiAxiosParamCreator(configuration).getDescriptionXml(serverId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get Description Xml.
         * @param {string} serverId Server UUID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDescriptionXml2(serverId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await DlnaServerApiAxiosParamCreator(configuration).getDescriptionXml2(serverId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Gets a server icon.
         * @param {string} fileName The icon filename.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getIcon(fileName: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await DlnaServerApiAxiosParamCreator(configuration).getIcon(fileName, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Gets a server icon.
         * @param {string} serverId Server UUID.
         * @param {string} fileName The icon filename.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getIconId(serverId: string, fileName: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await DlnaServerApiAxiosParamCreator(configuration).getIconId(serverId, fileName, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Gets Dlna media receiver registrar xml.
         * @param {string} serverId Server UUID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMediaReceiverRegistrar(serverId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await DlnaServerApiAxiosParamCreator(configuration).getMediaReceiverRegistrar(serverId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Gets Dlna media receiver registrar xml.
         * @param {string} serverId Server UUID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMediaReceiverRegistrar2(serverId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await DlnaServerApiAxiosParamCreator(configuration).getMediaReceiverRegistrar2(serverId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Gets Dlna media receiver registrar xml.
         * @param {string} serverId Server UUID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMediaReceiverRegistrar3(serverId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await DlnaServerApiAxiosParamCreator(configuration).getMediaReceiverRegistrar3(serverId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Process a connection manager control request.
         * @param {string} serverId Server UUID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async processConnectionManagerControlRequest(serverId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControlResponse>> {
            const localVarAxiosArgs = await DlnaServerApiAxiosParamCreator(configuration).processConnectionManagerControlRequest(serverId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Process a content directory control request.
         * @param {string} serverId Server UUID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async processContentDirectoryControlRequest(serverId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControlResponse>> {
            const localVarAxiosArgs = await DlnaServerApiAxiosParamCreator(configuration).processContentDirectoryControlRequest(serverId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Process a media receiver registrar control request.
         * @param {string} serverId Server UUID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async processMediaReceiverRegistrarControlRequest(serverId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControlResponse>> {
            const localVarAxiosArgs = await DlnaServerApiAxiosParamCreator(configuration).processMediaReceiverRegistrarControlRequest(serverId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * DlnaServerApi - factory interface
 * @export
 */
export const DlnaServerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Gets Dlna media receiver registrar xml.
         * @param {string} serverId Server UUID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConnectionManager(serverId: string, options?: any): AxiosPromise<any> {
            return DlnaServerApiFp(configuration).getConnectionManager(serverId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets Dlna media receiver registrar xml.
         * @param {string} serverId Server UUID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConnectionManager2(serverId: string, options?: any): AxiosPromise<any> {
            return DlnaServerApiFp(configuration).getConnectionManager2(serverId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets Dlna media receiver registrar xml.
         * @param {string} serverId Server UUID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConnectionManager3(serverId: string, options?: any): AxiosPromise<any> {
            return DlnaServerApiFp(configuration).getConnectionManager3(serverId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets Dlna content directory xml.
         * @param {string} serverId Server UUID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContentDirectory(serverId: string, options?: any): AxiosPromise<any> {
            return DlnaServerApiFp(configuration).getContentDirectory(serverId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets Dlna content directory xml.
         * @param {string} serverId Server UUID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContentDirectory2(serverId: string, options?: any): AxiosPromise<any> {
            return DlnaServerApiFp(configuration).getContentDirectory2(serverId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets Dlna content directory xml.
         * @param {string} serverId Server UUID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContentDirectory3(serverId: string, options?: any): AxiosPromise<any> {
            return DlnaServerApiFp(configuration).getContentDirectory3(serverId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Description Xml.
         * @param {string} serverId Server UUID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDescriptionXml(serverId: string, options?: any): AxiosPromise<any> {
            return DlnaServerApiFp(configuration).getDescriptionXml(serverId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Description Xml.
         * @param {string} serverId Server UUID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDescriptionXml2(serverId: string, options?: any): AxiosPromise<any> {
            return DlnaServerApiFp(configuration).getDescriptionXml2(serverId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets a server icon.
         * @param {string} fileName The icon filename.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIcon(fileName: string, options?: any): AxiosPromise<any> {
            return DlnaServerApiFp(configuration).getIcon(fileName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets a server icon.
         * @param {string} serverId Server UUID.
         * @param {string} fileName The icon filename.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIconId(serverId: string, fileName: string, options?: any): AxiosPromise<any> {
            return DlnaServerApiFp(configuration).getIconId(serverId, fileName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets Dlna media receiver registrar xml.
         * @param {string} serverId Server UUID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMediaReceiverRegistrar(serverId: string, options?: any): AxiosPromise<any> {
            return DlnaServerApiFp(configuration).getMediaReceiverRegistrar(serverId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets Dlna media receiver registrar xml.
         * @param {string} serverId Server UUID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMediaReceiverRegistrar2(serverId: string, options?: any): AxiosPromise<any> {
            return DlnaServerApiFp(configuration).getMediaReceiverRegistrar2(serverId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets Dlna media receiver registrar xml.
         * @param {string} serverId Server UUID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMediaReceiverRegistrar3(serverId: string, options?: any): AxiosPromise<any> {
            return DlnaServerApiFp(configuration).getMediaReceiverRegistrar3(serverId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Process a connection manager control request.
         * @param {string} serverId Server UUID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processConnectionManagerControlRequest(serverId: string, options?: any): AxiosPromise<ControlResponse> {
            return DlnaServerApiFp(configuration).processConnectionManagerControlRequest(serverId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Process a content directory control request.
         * @param {string} serverId Server UUID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processContentDirectoryControlRequest(serverId: string, options?: any): AxiosPromise<ControlResponse> {
            return DlnaServerApiFp(configuration).processContentDirectoryControlRequest(serverId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Process a media receiver registrar control request.
         * @param {string} serverId Server UUID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processMediaReceiverRegistrarControlRequest(serverId: string, options?: any): AxiosPromise<ControlResponse> {
            return DlnaServerApiFp(configuration).processMediaReceiverRegistrarControlRequest(serverId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getConnectionManager operation in DlnaServerApi.
 * @export
 * @interface DlnaServerApiGetConnectionManagerRequest
 */
export interface DlnaServerApiGetConnectionManagerRequest {
    /**
     * Server UUID.
     * @type {string}
     * @memberof DlnaServerApiGetConnectionManager
     */
    readonly serverId: string
}

/**
 * Request parameters for getConnectionManager2 operation in DlnaServerApi.
 * @export
 * @interface DlnaServerApiGetConnectionManager2Request
 */
export interface DlnaServerApiGetConnectionManager2Request {
    /**
     * Server UUID.
     * @type {string}
     * @memberof DlnaServerApiGetConnectionManager2
     */
    readonly serverId: string
}

/**
 * Request parameters for getConnectionManager3 operation in DlnaServerApi.
 * @export
 * @interface DlnaServerApiGetConnectionManager3Request
 */
export interface DlnaServerApiGetConnectionManager3Request {
    /**
     * Server UUID.
     * @type {string}
     * @memberof DlnaServerApiGetConnectionManager3
     */
    readonly serverId: string
}

/**
 * Request parameters for getContentDirectory operation in DlnaServerApi.
 * @export
 * @interface DlnaServerApiGetContentDirectoryRequest
 */
export interface DlnaServerApiGetContentDirectoryRequest {
    /**
     * Server UUID.
     * @type {string}
     * @memberof DlnaServerApiGetContentDirectory
     */
    readonly serverId: string
}

/**
 * Request parameters for getContentDirectory2 operation in DlnaServerApi.
 * @export
 * @interface DlnaServerApiGetContentDirectory2Request
 */
export interface DlnaServerApiGetContentDirectory2Request {
    /**
     * Server UUID.
     * @type {string}
     * @memberof DlnaServerApiGetContentDirectory2
     */
    readonly serverId: string
}

/**
 * Request parameters for getContentDirectory3 operation in DlnaServerApi.
 * @export
 * @interface DlnaServerApiGetContentDirectory3Request
 */
export interface DlnaServerApiGetContentDirectory3Request {
    /**
     * Server UUID.
     * @type {string}
     * @memberof DlnaServerApiGetContentDirectory3
     */
    readonly serverId: string
}

/**
 * Request parameters for getDescriptionXml operation in DlnaServerApi.
 * @export
 * @interface DlnaServerApiGetDescriptionXmlRequest
 */
export interface DlnaServerApiGetDescriptionXmlRequest {
    /**
     * Server UUID.
     * @type {string}
     * @memberof DlnaServerApiGetDescriptionXml
     */
    readonly serverId: string
}

/**
 * Request parameters for getDescriptionXml2 operation in DlnaServerApi.
 * @export
 * @interface DlnaServerApiGetDescriptionXml2Request
 */
export interface DlnaServerApiGetDescriptionXml2Request {
    /**
     * Server UUID.
     * @type {string}
     * @memberof DlnaServerApiGetDescriptionXml2
     */
    readonly serverId: string
}

/**
 * Request parameters for getIcon operation in DlnaServerApi.
 * @export
 * @interface DlnaServerApiGetIconRequest
 */
export interface DlnaServerApiGetIconRequest {
    /**
     * The icon filename.
     * @type {string}
     * @memberof DlnaServerApiGetIcon
     */
    readonly fileName: string
}

/**
 * Request parameters for getIconId operation in DlnaServerApi.
 * @export
 * @interface DlnaServerApiGetIconIdRequest
 */
export interface DlnaServerApiGetIconIdRequest {
    /**
     * Server UUID.
     * @type {string}
     * @memberof DlnaServerApiGetIconId
     */
    readonly serverId: string

    /**
     * The icon filename.
     * @type {string}
     * @memberof DlnaServerApiGetIconId
     */
    readonly fileName: string
}

/**
 * Request parameters for getMediaReceiverRegistrar operation in DlnaServerApi.
 * @export
 * @interface DlnaServerApiGetMediaReceiverRegistrarRequest
 */
export interface DlnaServerApiGetMediaReceiverRegistrarRequest {
    /**
     * Server UUID.
     * @type {string}
     * @memberof DlnaServerApiGetMediaReceiverRegistrar
     */
    readonly serverId: string
}

/**
 * Request parameters for getMediaReceiverRegistrar2 operation in DlnaServerApi.
 * @export
 * @interface DlnaServerApiGetMediaReceiverRegistrar2Request
 */
export interface DlnaServerApiGetMediaReceiverRegistrar2Request {
    /**
     * Server UUID.
     * @type {string}
     * @memberof DlnaServerApiGetMediaReceiverRegistrar2
     */
    readonly serverId: string
}

/**
 * Request parameters for getMediaReceiverRegistrar3 operation in DlnaServerApi.
 * @export
 * @interface DlnaServerApiGetMediaReceiverRegistrar3Request
 */
export interface DlnaServerApiGetMediaReceiverRegistrar3Request {
    /**
     * Server UUID.
     * @type {string}
     * @memberof DlnaServerApiGetMediaReceiverRegistrar3
     */
    readonly serverId: string
}

/**
 * Request parameters for processConnectionManagerControlRequest operation in DlnaServerApi.
 * @export
 * @interface DlnaServerApiProcessConnectionManagerControlRequestRequest
 */
export interface DlnaServerApiProcessConnectionManagerControlRequestRequest {
    /**
     * Server UUID.
     * @type {string}
     * @memberof DlnaServerApiProcessConnectionManagerControlRequest
     */
    readonly serverId: string
}

/**
 * Request parameters for processContentDirectoryControlRequest operation in DlnaServerApi.
 * @export
 * @interface DlnaServerApiProcessContentDirectoryControlRequestRequest
 */
export interface DlnaServerApiProcessContentDirectoryControlRequestRequest {
    /**
     * Server UUID.
     * @type {string}
     * @memberof DlnaServerApiProcessContentDirectoryControlRequest
     */
    readonly serverId: string
}

/**
 * Request parameters for processMediaReceiverRegistrarControlRequest operation in DlnaServerApi.
 * @export
 * @interface DlnaServerApiProcessMediaReceiverRegistrarControlRequestRequest
 */
export interface DlnaServerApiProcessMediaReceiverRegistrarControlRequestRequest {
    /**
     * Server UUID.
     * @type {string}
     * @memberof DlnaServerApiProcessMediaReceiverRegistrarControlRequest
     */
    readonly serverId: string
}

/**
 * DlnaServerApi - object-oriented interface
 * @export
 * @class DlnaServerApi
 * @extends {BaseAPI}
 */
export class DlnaServerApi extends BaseAPI {
    /**
     * 
     * @summary Gets Dlna media receiver registrar xml.
     * @param {DlnaServerApiGetConnectionManagerRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DlnaServerApi
     */
    public getConnectionManager(requestParameters: DlnaServerApiGetConnectionManagerRequest, options?: any) {
        return DlnaServerApiFp(this.configuration).getConnectionManager(requestParameters.serverId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets Dlna media receiver registrar xml.
     * @param {DlnaServerApiGetConnectionManager2Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DlnaServerApi
     */
    public getConnectionManager2(requestParameters: DlnaServerApiGetConnectionManager2Request, options?: any) {
        return DlnaServerApiFp(this.configuration).getConnectionManager2(requestParameters.serverId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets Dlna media receiver registrar xml.
     * @param {DlnaServerApiGetConnectionManager3Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DlnaServerApi
     */
    public getConnectionManager3(requestParameters: DlnaServerApiGetConnectionManager3Request, options?: any) {
        return DlnaServerApiFp(this.configuration).getConnectionManager3(requestParameters.serverId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets Dlna content directory xml.
     * @param {DlnaServerApiGetContentDirectoryRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DlnaServerApi
     */
    public getContentDirectory(requestParameters: DlnaServerApiGetContentDirectoryRequest, options?: any) {
        return DlnaServerApiFp(this.configuration).getContentDirectory(requestParameters.serverId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets Dlna content directory xml.
     * @param {DlnaServerApiGetContentDirectory2Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DlnaServerApi
     */
    public getContentDirectory2(requestParameters: DlnaServerApiGetContentDirectory2Request, options?: any) {
        return DlnaServerApiFp(this.configuration).getContentDirectory2(requestParameters.serverId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets Dlna content directory xml.
     * @param {DlnaServerApiGetContentDirectory3Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DlnaServerApi
     */
    public getContentDirectory3(requestParameters: DlnaServerApiGetContentDirectory3Request, options?: any) {
        return DlnaServerApiFp(this.configuration).getContentDirectory3(requestParameters.serverId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Description Xml.
     * @param {DlnaServerApiGetDescriptionXmlRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DlnaServerApi
     */
    public getDescriptionXml(requestParameters: DlnaServerApiGetDescriptionXmlRequest, options?: any) {
        return DlnaServerApiFp(this.configuration).getDescriptionXml(requestParameters.serverId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Description Xml.
     * @param {DlnaServerApiGetDescriptionXml2Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DlnaServerApi
     */
    public getDescriptionXml2(requestParameters: DlnaServerApiGetDescriptionXml2Request, options?: any) {
        return DlnaServerApiFp(this.configuration).getDescriptionXml2(requestParameters.serverId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets a server icon.
     * @param {DlnaServerApiGetIconRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DlnaServerApi
     */
    public getIcon(requestParameters: DlnaServerApiGetIconRequest, options?: any) {
        return DlnaServerApiFp(this.configuration).getIcon(requestParameters.fileName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets a server icon.
     * @param {DlnaServerApiGetIconIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DlnaServerApi
     */
    public getIconId(requestParameters: DlnaServerApiGetIconIdRequest, options?: any) {
        return DlnaServerApiFp(this.configuration).getIconId(requestParameters.serverId, requestParameters.fileName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets Dlna media receiver registrar xml.
     * @param {DlnaServerApiGetMediaReceiverRegistrarRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DlnaServerApi
     */
    public getMediaReceiverRegistrar(requestParameters: DlnaServerApiGetMediaReceiverRegistrarRequest, options?: any) {
        return DlnaServerApiFp(this.configuration).getMediaReceiverRegistrar(requestParameters.serverId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets Dlna media receiver registrar xml.
     * @param {DlnaServerApiGetMediaReceiverRegistrar2Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DlnaServerApi
     */
    public getMediaReceiverRegistrar2(requestParameters: DlnaServerApiGetMediaReceiverRegistrar2Request, options?: any) {
        return DlnaServerApiFp(this.configuration).getMediaReceiverRegistrar2(requestParameters.serverId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets Dlna media receiver registrar xml.
     * @param {DlnaServerApiGetMediaReceiverRegistrar3Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DlnaServerApi
     */
    public getMediaReceiverRegistrar3(requestParameters: DlnaServerApiGetMediaReceiverRegistrar3Request, options?: any) {
        return DlnaServerApiFp(this.configuration).getMediaReceiverRegistrar3(requestParameters.serverId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Process a connection manager control request.
     * @param {DlnaServerApiProcessConnectionManagerControlRequestRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DlnaServerApi
     */
    public processConnectionManagerControlRequest(requestParameters: DlnaServerApiProcessConnectionManagerControlRequestRequest, options?: any) {
        return DlnaServerApiFp(this.configuration).processConnectionManagerControlRequest(requestParameters.serverId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Process a content directory control request.
     * @param {DlnaServerApiProcessContentDirectoryControlRequestRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DlnaServerApi
     */
    public processContentDirectoryControlRequest(requestParameters: DlnaServerApiProcessContentDirectoryControlRequestRequest, options?: any) {
        return DlnaServerApiFp(this.configuration).processContentDirectoryControlRequest(requestParameters.serverId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Process a media receiver registrar control request.
     * @param {DlnaServerApiProcessMediaReceiverRegistrarControlRequestRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DlnaServerApi
     */
    public processMediaReceiverRegistrarControlRequest(requestParameters: DlnaServerApiProcessMediaReceiverRegistrarControlRequestRequest, options?: any) {
        return DlnaServerApiFp(this.configuration).processMediaReceiverRegistrarControlRequest(requestParameters.serverId, options).then((request) => request(this.axios, this.basePath));
    }
}
