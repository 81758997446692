/* tslint:disable */
/* eslint-disable */
/**
 * Jellyfin API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ClientCapabilities } from '../models';
// @ts-ignore
import { GeneralCommand } from '../models';
// @ts-ignore
import { GeneralCommandType } from '../models';
// @ts-ignore
import { NameIdPair } from '../models';
// @ts-ignore
import { PlayCommand } from '../models';
// @ts-ignore
import { PlaystateCommand } from '../models';
// @ts-ignore
import { SessionInfo } from '../models';
/**
 * SessionApi - axios parameter creator
 * @export
 */
export const SessionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Adds an additional user to a session.
         * @param {string} sessionId The session id.
         * @param {string} userId The user id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addUserToSession: async (sessionId: string, userId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'sessionId' is not null or undefined
            if (sessionId === null || sessionId === undefined) {
                throw new RequiredError('sessionId','Required parameter sessionId was null or undefined when calling addUserToSession.');
            }
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId','Required parameter userId was null or undefined when calling addUserToSession.');
            }
            const localVarPath = `/Sessions/{sessionId}/User/{userId}`
                .replace(`{${"sessionId"}}`, encodeURIComponent(String(sessionId)))
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Instructs a session to browse to an item or view.
         * @param {string} sessionId The session Id.
         * @param {string} itemType The type of item to browse to.
         * @param {string} itemId The Id of the item.
         * @param {string} itemName The name of the item.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        displayContent: async (sessionId: string, itemType: string, itemId: string, itemName: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'sessionId' is not null or undefined
            if (sessionId === null || sessionId === undefined) {
                throw new RequiredError('sessionId','Required parameter sessionId was null or undefined when calling displayContent.');
            }
            // verify required parameter 'itemType' is not null or undefined
            if (itemType === null || itemType === undefined) {
                throw new RequiredError('itemType','Required parameter itemType was null or undefined when calling displayContent.');
            }
            // verify required parameter 'itemId' is not null or undefined
            if (itemId === null || itemId === undefined) {
                throw new RequiredError('itemId','Required parameter itemId was null or undefined when calling displayContent.');
            }
            // verify required parameter 'itemName' is not null or undefined
            if (itemName === null || itemName === undefined) {
                throw new RequiredError('itemName','Required parameter itemName was null or undefined when calling displayContent.');
            }
            const localVarPath = `/Sessions/{sessionId}/Viewing`
                .replace(`{${"sessionId"}}`, encodeURIComponent(String(sessionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }

            if (itemType !== undefined) {
                localVarQueryParameter['itemType'] = itemType;
            }

            if (itemId !== undefined) {
                localVarQueryParameter['itemId'] = itemId;
            }

            if (itemName !== undefined) {
                localVarQueryParameter['itemName'] = itemName;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all auth providers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAuthProviders: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/Auth/Providers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all password reset providers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPasswordResetProviders: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/Auth/PasswordResetProviders`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets a list of sessions.
         * @param {string} [controllableByUserId] Filter by sessions that a given user is allowed to remote control.
         * @param {string} [deviceId] Filter by device Id.
         * @param {number} [activeWithinSeconds] Optional. Filter by sessions that were active in the last n seconds.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSessions: async (controllableByUserId?: string, deviceId?: string, activeWithinSeconds?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/Sessions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }

            if (controllableByUserId !== undefined) {
                localVarQueryParameter['controllableByUserId'] = controllableByUserId;
            }

            if (deviceId !== undefined) {
                localVarQueryParameter['deviceId'] = deviceId;
            }

            if (activeWithinSeconds !== undefined) {
                localVarQueryParameter['activeWithinSeconds'] = activeWithinSeconds;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Instructs a session to play an item.
         * @param {string} sessionId The session id.
         * @param {PlayCommand} playCommand The type of play command to issue (PlayNow, PlayNext, PlayLast). Clients who have not yet implemented play next and play last may play now.
         * @param {string} itemIds The ids of the items to play, comma delimited.
         * @param {number} [startPositionTicks] The starting position of the first item.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        play: async (sessionId: string, playCommand: PlayCommand, itemIds: string, startPositionTicks?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'sessionId' is not null or undefined
            if (sessionId === null || sessionId === undefined) {
                throw new RequiredError('sessionId','Required parameter sessionId was null or undefined when calling play.');
            }
            // verify required parameter 'playCommand' is not null or undefined
            if (playCommand === null || playCommand === undefined) {
                throw new RequiredError('playCommand','Required parameter playCommand was null or undefined when calling play.');
            }
            // verify required parameter 'itemIds' is not null or undefined
            if (itemIds === null || itemIds === undefined) {
                throw new RequiredError('itemIds','Required parameter itemIds was null or undefined when calling play.');
            }
            const localVarPath = `/Sessions/{sessionId}/Playing`
                .replace(`{${"sessionId"}}`, encodeURIComponent(String(sessionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }

            if (playCommand !== undefined) {
                localVarQueryParameter['playCommand'] = playCommand;
            }

            if (itemIds !== undefined) {
                localVarQueryParameter['itemIds'] = itemIds;
            }

            if (startPositionTicks !== undefined) {
                localVarQueryParameter['startPositionTicks'] = startPositionTicks;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates capabilities for a device.
         * @param {string} [id] The session id.
         * @param {string} [playableMediaTypes] A list of playable media types, comma delimited. Audio, Video, Book, Photo.
         * @param {Array<GeneralCommandType>} [supportedCommands] A list of supported remote control commands, comma delimited.
         * @param {boolean} [supportsMediaControl] Determines whether media can be played remotely..
         * @param {boolean} [supportsSync] Determines whether sync is supported.
         * @param {boolean} [supportsPersistentIdentifier] Determines whether the device supports a unique identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCapabilities: async (id?: string, playableMediaTypes?: string, supportedCommands?: Array<GeneralCommandType>, supportsMediaControl?: boolean, supportsSync?: boolean, supportsPersistentIdentifier?: boolean, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/Sessions/Capabilities`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (playableMediaTypes !== undefined) {
                localVarQueryParameter['playableMediaTypes'] = playableMediaTypes;
            }

            if (supportedCommands) {
                localVarQueryParameter['supportedCommands'] = supportedCommands;
            }

            if (supportsMediaControl !== undefined) {
                localVarQueryParameter['supportsMediaControl'] = supportsMediaControl;
            }

            if (supportsSync !== undefined) {
                localVarQueryParameter['supportsSync'] = supportsSync;
            }

            if (supportsPersistentIdentifier !== undefined) {
                localVarQueryParameter['supportsPersistentIdentifier'] = supportsPersistentIdentifier;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates capabilities for a device.
         * @param {ClientCapabilities} clientCapabilities The MediaBrowser.Model.Session.ClientCapabilities.
         * @param {string} [id] The session id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postFullCapabilities: async (clientCapabilities: ClientCapabilities, id?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientCapabilities' is not null or undefined
            if (clientCapabilities === null || clientCapabilities === undefined) {
                throw new RequiredError('clientCapabilities','Required parameter clientCapabilities was null or undefined when calling postFullCapabilities.');
            }
            const localVarPath = `/Sessions/Capabilities/Full`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof clientCapabilities !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(clientCapabilities !== undefined ? clientCapabilities : {}) : (clientCapabilities || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Removes an additional user from a session.
         * @param {string} sessionId The session id.
         * @param {string} userId The user id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeUserFromSession: async (sessionId: string, userId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'sessionId' is not null or undefined
            if (sessionId === null || sessionId === undefined) {
                throw new RequiredError('sessionId','Required parameter sessionId was null or undefined when calling removeUserFromSession.');
            }
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId','Required parameter userId was null or undefined when calling removeUserFromSession.');
            }
            const localVarPath = `/Sessions/{sessionId}/User/{userId}`
                .replace(`{${"sessionId"}}`, encodeURIComponent(String(sessionId)))
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Reports that a session has ended.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportSessionEnded: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/Sessions/Logout`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Reports that a session is viewing an item.
         * @param {string} itemId The item id.
         * @param {string} [sessionId] The session id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportViewing: async (itemId: string, sessionId?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'itemId' is not null or undefined
            if (itemId === null || itemId === undefined) {
                throw new RequiredError('itemId','Required parameter itemId was null or undefined when calling reportViewing.');
            }
            const localVarPath = `/Sessions/Viewing`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }

            if (sessionId !== undefined) {
                localVarQueryParameter['sessionId'] = sessionId;
            }

            if (itemId !== undefined) {
                localVarQueryParameter['itemId'] = itemId;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Issues a full general command to a client.
         * @param {string} sessionId The session id.
         * @param {GeneralCommand} generalCommand The MediaBrowser.Model.Session.GeneralCommand.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendFullGeneralCommand: async (sessionId: string, generalCommand: GeneralCommand, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'sessionId' is not null or undefined
            if (sessionId === null || sessionId === undefined) {
                throw new RequiredError('sessionId','Required parameter sessionId was null or undefined when calling sendFullGeneralCommand.');
            }
            // verify required parameter 'generalCommand' is not null or undefined
            if (generalCommand === null || generalCommand === undefined) {
                throw new RequiredError('generalCommand','Required parameter generalCommand was null or undefined when calling sendFullGeneralCommand.');
            }
            const localVarPath = `/Sessions/{sessionId}/Command`
                .replace(`{${"sessionId"}}`, encodeURIComponent(String(sessionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof generalCommand !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(generalCommand !== undefined ? generalCommand : {}) : (generalCommand || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Issues a general command to a client.
         * @param {string} sessionId The session id.
         * @param {GeneralCommandType} command The command to send.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendGeneralCommand: async (sessionId: string, command: GeneralCommandType, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'sessionId' is not null or undefined
            if (sessionId === null || sessionId === undefined) {
                throw new RequiredError('sessionId','Required parameter sessionId was null or undefined when calling sendGeneralCommand.');
            }
            // verify required parameter 'command' is not null or undefined
            if (command === null || command === undefined) {
                throw new RequiredError('command','Required parameter command was null or undefined when calling sendGeneralCommand.');
            }
            const localVarPath = `/Sessions/{sessionId}/Command/{command}`
                .replace(`{${"sessionId"}}`, encodeURIComponent(String(sessionId)))
                .replace(`{${"command"}}`, encodeURIComponent(String(command)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Issues a command to a client to display a message to the user.
         * @param {string} sessionId The session id.
         * @param {string} text The message test.
         * @param {string} [header] The message header.
         * @param {number} [timeoutMs] The message timeout. If omitted the user will have to confirm viewing the message.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendMessageCommand: async (sessionId: string, text: string, header?: string, timeoutMs?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'sessionId' is not null or undefined
            if (sessionId === null || sessionId === undefined) {
                throw new RequiredError('sessionId','Required parameter sessionId was null or undefined when calling sendMessageCommand.');
            }
            // verify required parameter 'text' is not null or undefined
            if (text === null || text === undefined) {
                throw new RequiredError('text','Required parameter text was null or undefined when calling sendMessageCommand.');
            }
            const localVarPath = `/Sessions/{sessionId}/Message`
                .replace(`{${"sessionId"}}`, encodeURIComponent(String(sessionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }

            if (text !== undefined) {
                localVarQueryParameter['text'] = text;
            }

            if (header !== undefined) {
                localVarQueryParameter['header'] = header;
            }

            if (timeoutMs !== undefined) {
                localVarQueryParameter['timeoutMs'] = timeoutMs;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Issues a playstate command to a client.
         * @param {string} sessionId The session id.
         * @param {PlaystateCommand} command The MediaBrowser.Model.Session.PlaystateCommand.
         * @param {number} [seekPositionTicks] The optional position ticks.
         * @param {string} [controllingUserId] The optional controlling user id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendPlaystateCommand: async (sessionId: string, command: PlaystateCommand, seekPositionTicks?: number, controllingUserId?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'sessionId' is not null or undefined
            if (sessionId === null || sessionId === undefined) {
                throw new RequiredError('sessionId','Required parameter sessionId was null or undefined when calling sendPlaystateCommand.');
            }
            // verify required parameter 'command' is not null or undefined
            if (command === null || command === undefined) {
                throw new RequiredError('command','Required parameter command was null or undefined when calling sendPlaystateCommand.');
            }
            const localVarPath = `/Sessions/{sessionId}/Playing/{command}`
                .replace(`{${"sessionId"}}`, encodeURIComponent(String(sessionId)))
                .replace(`{${"command"}}`, encodeURIComponent(String(command)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }

            if (seekPositionTicks !== undefined) {
                localVarQueryParameter['seekPositionTicks'] = seekPositionTicks;
            }

            if (controllingUserId !== undefined) {
                localVarQueryParameter['controllingUserId'] = controllingUserId;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Issues a system command to a client.
         * @param {string} sessionId The session id.
         * @param {GeneralCommandType} command The command to send.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendSystemCommand: async (sessionId: string, command: GeneralCommandType, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'sessionId' is not null or undefined
            if (sessionId === null || sessionId === undefined) {
                throw new RequiredError('sessionId','Required parameter sessionId was null or undefined when calling sendSystemCommand.');
            }
            // verify required parameter 'command' is not null or undefined
            if (command === null || command === undefined) {
                throw new RequiredError('command','Required parameter command was null or undefined when calling sendSystemCommand.');
            }
            const localVarPath = `/Sessions/{sessionId}/System/{command}`
                .replace(`{${"sessionId"}}`, encodeURIComponent(String(sessionId)))
                .replace(`{${"command"}}`, encodeURIComponent(String(command)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SessionApi - functional programming interface
 * @export
 */
export const SessionApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Adds an additional user to a session.
         * @param {string} sessionId The session id.
         * @param {string} userId The user id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addUserToSession(sessionId: string, userId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await SessionApiAxiosParamCreator(configuration).addUserToSession(sessionId, userId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Instructs a session to browse to an item or view.
         * @param {string} sessionId The session Id.
         * @param {string} itemType The type of item to browse to.
         * @param {string} itemId The Id of the item.
         * @param {string} itemName The name of the item.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async displayContent(sessionId: string, itemType: string, itemId: string, itemName: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await SessionApiAxiosParamCreator(configuration).displayContent(sessionId, itemType, itemId, itemName, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get all auth providers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAuthProviders(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<NameIdPair>>> {
            const localVarAxiosArgs = await SessionApiAxiosParamCreator(configuration).getAuthProviders(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get all password reset providers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPasswordResetProviders(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<NameIdPair>>> {
            const localVarAxiosArgs = await SessionApiAxiosParamCreator(configuration).getPasswordResetProviders(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Gets a list of sessions.
         * @param {string} [controllableByUserId] Filter by sessions that a given user is allowed to remote control.
         * @param {string} [deviceId] Filter by device Id.
         * @param {number} [activeWithinSeconds] Optional. Filter by sessions that were active in the last n seconds.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSessions(controllableByUserId?: string, deviceId?: string, activeWithinSeconds?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SessionInfo>>> {
            const localVarAxiosArgs = await SessionApiAxiosParamCreator(configuration).getSessions(controllableByUserId, deviceId, activeWithinSeconds, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Instructs a session to play an item.
         * @param {string} sessionId The session id.
         * @param {PlayCommand} playCommand The type of play command to issue (PlayNow, PlayNext, PlayLast). Clients who have not yet implemented play next and play last may play now.
         * @param {string} itemIds The ids of the items to play, comma delimited.
         * @param {number} [startPositionTicks] The starting position of the first item.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async play(sessionId: string, playCommand: PlayCommand, itemIds: string, startPositionTicks?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await SessionApiAxiosParamCreator(configuration).play(sessionId, playCommand, itemIds, startPositionTicks, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Updates capabilities for a device.
         * @param {string} [id] The session id.
         * @param {string} [playableMediaTypes] A list of playable media types, comma delimited. Audio, Video, Book, Photo.
         * @param {Array<GeneralCommandType>} [supportedCommands] A list of supported remote control commands, comma delimited.
         * @param {boolean} [supportsMediaControl] Determines whether media can be played remotely..
         * @param {boolean} [supportsSync] Determines whether sync is supported.
         * @param {boolean} [supportsPersistentIdentifier] Determines whether the device supports a unique identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postCapabilities(id?: string, playableMediaTypes?: string, supportedCommands?: Array<GeneralCommandType>, supportsMediaControl?: boolean, supportsSync?: boolean, supportsPersistentIdentifier?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await SessionApiAxiosParamCreator(configuration).postCapabilities(id, playableMediaTypes, supportedCommands, supportsMediaControl, supportsSync, supportsPersistentIdentifier, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Updates capabilities for a device.
         * @param {ClientCapabilities} clientCapabilities The MediaBrowser.Model.Session.ClientCapabilities.
         * @param {string} [id] The session id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postFullCapabilities(clientCapabilities: ClientCapabilities, id?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await SessionApiAxiosParamCreator(configuration).postFullCapabilities(clientCapabilities, id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Removes an additional user from a session.
         * @param {string} sessionId The session id.
         * @param {string} userId The user id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeUserFromSession(sessionId: string, userId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await SessionApiAxiosParamCreator(configuration).removeUserFromSession(sessionId, userId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Reports that a session has ended.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reportSessionEnded(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await SessionApiAxiosParamCreator(configuration).reportSessionEnded(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Reports that a session is viewing an item.
         * @param {string} itemId The item id.
         * @param {string} [sessionId] The session id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reportViewing(itemId: string, sessionId?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await SessionApiAxiosParamCreator(configuration).reportViewing(itemId, sessionId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Issues a full general command to a client.
         * @param {string} sessionId The session id.
         * @param {GeneralCommand} generalCommand The MediaBrowser.Model.Session.GeneralCommand.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendFullGeneralCommand(sessionId: string, generalCommand: GeneralCommand, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await SessionApiAxiosParamCreator(configuration).sendFullGeneralCommand(sessionId, generalCommand, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Issues a general command to a client.
         * @param {string} sessionId The session id.
         * @param {GeneralCommandType} command The command to send.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendGeneralCommand(sessionId: string, command: GeneralCommandType, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await SessionApiAxiosParamCreator(configuration).sendGeneralCommand(sessionId, command, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Issues a command to a client to display a message to the user.
         * @param {string} sessionId The session id.
         * @param {string} text The message test.
         * @param {string} [header] The message header.
         * @param {number} [timeoutMs] The message timeout. If omitted the user will have to confirm viewing the message.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendMessageCommand(sessionId: string, text: string, header?: string, timeoutMs?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await SessionApiAxiosParamCreator(configuration).sendMessageCommand(sessionId, text, header, timeoutMs, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Issues a playstate command to a client.
         * @param {string} sessionId The session id.
         * @param {PlaystateCommand} command The MediaBrowser.Model.Session.PlaystateCommand.
         * @param {number} [seekPositionTicks] The optional position ticks.
         * @param {string} [controllingUserId] The optional controlling user id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendPlaystateCommand(sessionId: string, command: PlaystateCommand, seekPositionTicks?: number, controllingUserId?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await SessionApiAxiosParamCreator(configuration).sendPlaystateCommand(sessionId, command, seekPositionTicks, controllingUserId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Issues a system command to a client.
         * @param {string} sessionId The session id.
         * @param {GeneralCommandType} command The command to send.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendSystemCommand(sessionId: string, command: GeneralCommandType, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await SessionApiAxiosParamCreator(configuration).sendSystemCommand(sessionId, command, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * SessionApi - factory interface
 * @export
 */
export const SessionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Adds an additional user to a session.
         * @param {string} sessionId The session id.
         * @param {string} userId The user id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addUserToSession(sessionId: string, userId: string, options?: any): AxiosPromise<void> {
            return SessionApiFp(configuration).addUserToSession(sessionId, userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Instructs a session to browse to an item or view.
         * @param {string} sessionId The session Id.
         * @param {string} itemType The type of item to browse to.
         * @param {string} itemId The Id of the item.
         * @param {string} itemName The name of the item.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        displayContent(sessionId: string, itemType: string, itemId: string, itemName: string, options?: any): AxiosPromise<void> {
            return SessionApiFp(configuration).displayContent(sessionId, itemType, itemId, itemName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all auth providers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAuthProviders(options?: any): AxiosPromise<Array<NameIdPair>> {
            return SessionApiFp(configuration).getAuthProviders(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all password reset providers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPasswordResetProviders(options?: any): AxiosPromise<Array<NameIdPair>> {
            return SessionApiFp(configuration).getPasswordResetProviders(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets a list of sessions.
         * @param {string} [controllableByUserId] Filter by sessions that a given user is allowed to remote control.
         * @param {string} [deviceId] Filter by device Id.
         * @param {number} [activeWithinSeconds] Optional. Filter by sessions that were active in the last n seconds.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSessions(controllableByUserId?: string, deviceId?: string, activeWithinSeconds?: number, options?: any): AxiosPromise<Array<SessionInfo>> {
            return SessionApiFp(configuration).getSessions(controllableByUserId, deviceId, activeWithinSeconds, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Instructs a session to play an item.
         * @param {string} sessionId The session id.
         * @param {PlayCommand} playCommand The type of play command to issue (PlayNow, PlayNext, PlayLast). Clients who have not yet implemented play next and play last may play now.
         * @param {string} itemIds The ids of the items to play, comma delimited.
         * @param {number} [startPositionTicks] The starting position of the first item.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        play(sessionId: string, playCommand: PlayCommand, itemIds: string, startPositionTicks?: number, options?: any): AxiosPromise<void> {
            return SessionApiFp(configuration).play(sessionId, playCommand, itemIds, startPositionTicks, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates capabilities for a device.
         * @param {string} [id] The session id.
         * @param {string} [playableMediaTypes] A list of playable media types, comma delimited. Audio, Video, Book, Photo.
         * @param {Array<GeneralCommandType>} [supportedCommands] A list of supported remote control commands, comma delimited.
         * @param {boolean} [supportsMediaControl] Determines whether media can be played remotely..
         * @param {boolean} [supportsSync] Determines whether sync is supported.
         * @param {boolean} [supportsPersistentIdentifier] Determines whether the device supports a unique identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCapabilities(id?: string, playableMediaTypes?: string, supportedCommands?: Array<GeneralCommandType>, supportsMediaControl?: boolean, supportsSync?: boolean, supportsPersistentIdentifier?: boolean, options?: any): AxiosPromise<void> {
            return SessionApiFp(configuration).postCapabilities(id, playableMediaTypes, supportedCommands, supportsMediaControl, supportsSync, supportsPersistentIdentifier, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates capabilities for a device.
         * @param {ClientCapabilities} clientCapabilities The MediaBrowser.Model.Session.ClientCapabilities.
         * @param {string} [id] The session id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postFullCapabilities(clientCapabilities: ClientCapabilities, id?: string, options?: any): AxiosPromise<void> {
            return SessionApiFp(configuration).postFullCapabilities(clientCapabilities, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Removes an additional user from a session.
         * @param {string} sessionId The session id.
         * @param {string} userId The user id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeUserFromSession(sessionId: string, userId: string, options?: any): AxiosPromise<void> {
            return SessionApiFp(configuration).removeUserFromSession(sessionId, userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Reports that a session has ended.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportSessionEnded(options?: any): AxiosPromise<void> {
            return SessionApiFp(configuration).reportSessionEnded(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Reports that a session is viewing an item.
         * @param {string} itemId The item id.
         * @param {string} [sessionId] The session id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportViewing(itemId: string, sessionId?: string, options?: any): AxiosPromise<void> {
            return SessionApiFp(configuration).reportViewing(itemId, sessionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Issues a full general command to a client.
         * @param {string} sessionId The session id.
         * @param {GeneralCommand} generalCommand The MediaBrowser.Model.Session.GeneralCommand.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendFullGeneralCommand(sessionId: string, generalCommand: GeneralCommand, options?: any): AxiosPromise<void> {
            return SessionApiFp(configuration).sendFullGeneralCommand(sessionId, generalCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Issues a general command to a client.
         * @param {string} sessionId The session id.
         * @param {GeneralCommandType} command The command to send.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendGeneralCommand(sessionId: string, command: GeneralCommandType, options?: any): AxiosPromise<void> {
            return SessionApiFp(configuration).sendGeneralCommand(sessionId, command, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Issues a command to a client to display a message to the user.
         * @param {string} sessionId The session id.
         * @param {string} text The message test.
         * @param {string} [header] The message header.
         * @param {number} [timeoutMs] The message timeout. If omitted the user will have to confirm viewing the message.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendMessageCommand(sessionId: string, text: string, header?: string, timeoutMs?: number, options?: any): AxiosPromise<void> {
            return SessionApiFp(configuration).sendMessageCommand(sessionId, text, header, timeoutMs, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Issues a playstate command to a client.
         * @param {string} sessionId The session id.
         * @param {PlaystateCommand} command The MediaBrowser.Model.Session.PlaystateCommand.
         * @param {number} [seekPositionTicks] The optional position ticks.
         * @param {string} [controllingUserId] The optional controlling user id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendPlaystateCommand(sessionId: string, command: PlaystateCommand, seekPositionTicks?: number, controllingUserId?: string, options?: any): AxiosPromise<void> {
            return SessionApiFp(configuration).sendPlaystateCommand(sessionId, command, seekPositionTicks, controllingUserId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Issues a system command to a client.
         * @param {string} sessionId The session id.
         * @param {GeneralCommandType} command The command to send.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendSystemCommand(sessionId: string, command: GeneralCommandType, options?: any): AxiosPromise<void> {
            return SessionApiFp(configuration).sendSystemCommand(sessionId, command, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for addUserToSession operation in SessionApi.
 * @export
 * @interface SessionApiAddUserToSessionRequest
 */
export interface SessionApiAddUserToSessionRequest {
    /**
     * The session id.
     * @type {string}
     * @memberof SessionApiAddUserToSession
     */
    readonly sessionId: string

    /**
     * The user id.
     * @type {string}
     * @memberof SessionApiAddUserToSession
     */
    readonly userId: string
}

/**
 * Request parameters for displayContent operation in SessionApi.
 * @export
 * @interface SessionApiDisplayContentRequest
 */
export interface SessionApiDisplayContentRequest {
    /**
     * The session Id.
     * @type {string}
     * @memberof SessionApiDisplayContent
     */
    readonly sessionId: string

    /**
     * The type of item to browse to.
     * @type {string}
     * @memberof SessionApiDisplayContent
     */
    readonly itemType: string

    /**
     * The Id of the item.
     * @type {string}
     * @memberof SessionApiDisplayContent
     */
    readonly itemId: string

    /**
     * The name of the item.
     * @type {string}
     * @memberof SessionApiDisplayContent
     */
    readonly itemName: string
}

/**
 * Request parameters for getSessions operation in SessionApi.
 * @export
 * @interface SessionApiGetSessionsRequest
 */
export interface SessionApiGetSessionsRequest {
    /**
     * Filter by sessions that a given user is allowed to remote control.
     * @type {string}
     * @memberof SessionApiGetSessions
     */
    readonly controllableByUserId?: string

    /**
     * Filter by device Id.
     * @type {string}
     * @memberof SessionApiGetSessions
     */
    readonly deviceId?: string

    /**
     * Optional. Filter by sessions that were active in the last n seconds.
     * @type {number}
     * @memberof SessionApiGetSessions
     */
    readonly activeWithinSeconds?: number
}

/**
 * Request parameters for play operation in SessionApi.
 * @export
 * @interface SessionApiPlayRequest
 */
export interface SessionApiPlayRequest {
    /**
     * The session id.
     * @type {string}
     * @memberof SessionApiPlay
     */
    readonly sessionId: string

    /**
     * The type of play command to issue (PlayNow, PlayNext, PlayLast). Clients who have not yet implemented play next and play last may play now.
     * @type {PlayCommand}
     * @memberof SessionApiPlay
     */
    readonly playCommand: PlayCommand

    /**
     * The ids of the items to play, comma delimited.
     * @type {string}
     * @memberof SessionApiPlay
     */
    readonly itemIds: string

    /**
     * The starting position of the first item.
     * @type {number}
     * @memberof SessionApiPlay
     */
    readonly startPositionTicks?: number
}

/**
 * Request parameters for postCapabilities operation in SessionApi.
 * @export
 * @interface SessionApiPostCapabilitiesRequest
 */
export interface SessionApiPostCapabilitiesRequest {
    /**
     * The session id.
     * @type {string}
     * @memberof SessionApiPostCapabilities
     */
    readonly id?: string

    /**
     * A list of playable media types, comma delimited. Audio, Video, Book, Photo.
     * @type {string}
     * @memberof SessionApiPostCapabilities
     */
    readonly playableMediaTypes?: string

    /**
     * A list of supported remote control commands, comma delimited.
     * @type {Array<GeneralCommandType>}
     * @memberof SessionApiPostCapabilities
     */
    readonly supportedCommands?: Array<GeneralCommandType>

    /**
     * Determines whether media can be played remotely..
     * @type {boolean}
     * @memberof SessionApiPostCapabilities
     */
    readonly supportsMediaControl?: boolean

    /**
     * Determines whether sync is supported.
     * @type {boolean}
     * @memberof SessionApiPostCapabilities
     */
    readonly supportsSync?: boolean

    /**
     * Determines whether the device supports a unique identifier.
     * @type {boolean}
     * @memberof SessionApiPostCapabilities
     */
    readonly supportsPersistentIdentifier?: boolean
}

/**
 * Request parameters for postFullCapabilities operation in SessionApi.
 * @export
 * @interface SessionApiPostFullCapabilitiesRequest
 */
export interface SessionApiPostFullCapabilitiesRequest {
    /**
     * The MediaBrowser.Model.Session.ClientCapabilities.
     * @type {ClientCapabilities}
     * @memberof SessionApiPostFullCapabilities
     */
    readonly clientCapabilities: ClientCapabilities

    /**
     * The session id.
     * @type {string}
     * @memberof SessionApiPostFullCapabilities
     */
    readonly id?: string
}

/**
 * Request parameters for removeUserFromSession operation in SessionApi.
 * @export
 * @interface SessionApiRemoveUserFromSessionRequest
 */
export interface SessionApiRemoveUserFromSessionRequest {
    /**
     * The session id.
     * @type {string}
     * @memberof SessionApiRemoveUserFromSession
     */
    readonly sessionId: string

    /**
     * The user id.
     * @type {string}
     * @memberof SessionApiRemoveUserFromSession
     */
    readonly userId: string
}

/**
 * Request parameters for reportViewing operation in SessionApi.
 * @export
 * @interface SessionApiReportViewingRequest
 */
export interface SessionApiReportViewingRequest {
    /**
     * The item id.
     * @type {string}
     * @memberof SessionApiReportViewing
     */
    readonly itemId: string

    /**
     * The session id.
     * @type {string}
     * @memberof SessionApiReportViewing
     */
    readonly sessionId?: string
}

/**
 * Request parameters for sendFullGeneralCommand operation in SessionApi.
 * @export
 * @interface SessionApiSendFullGeneralCommandRequest
 */
export interface SessionApiSendFullGeneralCommandRequest {
    /**
     * The session id.
     * @type {string}
     * @memberof SessionApiSendFullGeneralCommand
     */
    readonly sessionId: string

    /**
     * The MediaBrowser.Model.Session.GeneralCommand.
     * @type {GeneralCommand}
     * @memberof SessionApiSendFullGeneralCommand
     */
    readonly generalCommand: GeneralCommand
}

/**
 * Request parameters for sendGeneralCommand operation in SessionApi.
 * @export
 * @interface SessionApiSendGeneralCommandRequest
 */
export interface SessionApiSendGeneralCommandRequest {
    /**
     * The session id.
     * @type {string}
     * @memberof SessionApiSendGeneralCommand
     */
    readonly sessionId: string

    /**
     * The command to send.
     * @type {GeneralCommandType}
     * @memberof SessionApiSendGeneralCommand
     */
    readonly command: GeneralCommandType
}

/**
 * Request parameters for sendMessageCommand operation in SessionApi.
 * @export
 * @interface SessionApiSendMessageCommandRequest
 */
export interface SessionApiSendMessageCommandRequest {
    /**
     * The session id.
     * @type {string}
     * @memberof SessionApiSendMessageCommand
     */
    readonly sessionId: string

    /**
     * The message test.
     * @type {string}
     * @memberof SessionApiSendMessageCommand
     */
    readonly text: string

    /**
     * The message header.
     * @type {string}
     * @memberof SessionApiSendMessageCommand
     */
    readonly header?: string

    /**
     * The message timeout. If omitted the user will have to confirm viewing the message.
     * @type {number}
     * @memberof SessionApiSendMessageCommand
     */
    readonly timeoutMs?: number
}

/**
 * Request parameters for sendPlaystateCommand operation in SessionApi.
 * @export
 * @interface SessionApiSendPlaystateCommandRequest
 */
export interface SessionApiSendPlaystateCommandRequest {
    /**
     * The session id.
     * @type {string}
     * @memberof SessionApiSendPlaystateCommand
     */
    readonly sessionId: string

    /**
     * The MediaBrowser.Model.Session.PlaystateCommand.
     * @type {PlaystateCommand}
     * @memberof SessionApiSendPlaystateCommand
     */
    readonly command: PlaystateCommand

    /**
     * The optional position ticks.
     * @type {number}
     * @memberof SessionApiSendPlaystateCommand
     */
    readonly seekPositionTicks?: number

    /**
     * The optional controlling user id.
     * @type {string}
     * @memberof SessionApiSendPlaystateCommand
     */
    readonly controllingUserId?: string
}

/**
 * Request parameters for sendSystemCommand operation in SessionApi.
 * @export
 * @interface SessionApiSendSystemCommandRequest
 */
export interface SessionApiSendSystemCommandRequest {
    /**
     * The session id.
     * @type {string}
     * @memberof SessionApiSendSystemCommand
     */
    readonly sessionId: string

    /**
     * The command to send.
     * @type {GeneralCommandType}
     * @memberof SessionApiSendSystemCommand
     */
    readonly command: GeneralCommandType
}

/**
 * SessionApi - object-oriented interface
 * @export
 * @class SessionApi
 * @extends {BaseAPI}
 */
export class SessionApi extends BaseAPI {
    /**
     * 
     * @summary Adds an additional user to a session.
     * @param {SessionApiAddUserToSessionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SessionApi
     */
    public addUserToSession(requestParameters: SessionApiAddUserToSessionRequest, options?: any) {
        return SessionApiFp(this.configuration).addUserToSession(requestParameters.sessionId, requestParameters.userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Instructs a session to browse to an item or view.
     * @param {SessionApiDisplayContentRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SessionApi
     */
    public displayContent(requestParameters: SessionApiDisplayContentRequest, options?: any) {
        return SessionApiFp(this.configuration).displayContent(requestParameters.sessionId, requestParameters.itemType, requestParameters.itemId, requestParameters.itemName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all auth providers.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SessionApi
     */
    public getAuthProviders(options?: any) {
        return SessionApiFp(this.configuration).getAuthProviders(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all password reset providers.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SessionApi
     */
    public getPasswordResetProviders(options?: any) {
        return SessionApiFp(this.configuration).getPasswordResetProviders(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets a list of sessions.
     * @param {SessionApiGetSessionsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SessionApi
     */
    public getSessions(requestParameters: SessionApiGetSessionsRequest = {}, options?: any) {
        return SessionApiFp(this.configuration).getSessions(requestParameters.controllableByUserId, requestParameters.deviceId, requestParameters.activeWithinSeconds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Instructs a session to play an item.
     * @param {SessionApiPlayRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SessionApi
     */
    public play(requestParameters: SessionApiPlayRequest, options?: any) {
        return SessionApiFp(this.configuration).play(requestParameters.sessionId, requestParameters.playCommand, requestParameters.itemIds, requestParameters.startPositionTicks, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates capabilities for a device.
     * @param {SessionApiPostCapabilitiesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SessionApi
     */
    public postCapabilities(requestParameters: SessionApiPostCapabilitiesRequest = {}, options?: any) {
        return SessionApiFp(this.configuration).postCapabilities(requestParameters.id, requestParameters.playableMediaTypes, requestParameters.supportedCommands, requestParameters.supportsMediaControl, requestParameters.supportsSync, requestParameters.supportsPersistentIdentifier, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates capabilities for a device.
     * @param {SessionApiPostFullCapabilitiesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SessionApi
     */
    public postFullCapabilities(requestParameters: SessionApiPostFullCapabilitiesRequest, options?: any) {
        return SessionApiFp(this.configuration).postFullCapabilities(requestParameters.clientCapabilities, requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Removes an additional user from a session.
     * @param {SessionApiRemoveUserFromSessionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SessionApi
     */
    public removeUserFromSession(requestParameters: SessionApiRemoveUserFromSessionRequest, options?: any) {
        return SessionApiFp(this.configuration).removeUserFromSession(requestParameters.sessionId, requestParameters.userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Reports that a session has ended.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SessionApi
     */
    public reportSessionEnded(options?: any) {
        return SessionApiFp(this.configuration).reportSessionEnded(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Reports that a session is viewing an item.
     * @param {SessionApiReportViewingRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SessionApi
     */
    public reportViewing(requestParameters: SessionApiReportViewingRequest, options?: any) {
        return SessionApiFp(this.configuration).reportViewing(requestParameters.itemId, requestParameters.sessionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Issues a full general command to a client.
     * @param {SessionApiSendFullGeneralCommandRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SessionApi
     */
    public sendFullGeneralCommand(requestParameters: SessionApiSendFullGeneralCommandRequest, options?: any) {
        return SessionApiFp(this.configuration).sendFullGeneralCommand(requestParameters.sessionId, requestParameters.generalCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Issues a general command to a client.
     * @param {SessionApiSendGeneralCommandRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SessionApi
     */
    public sendGeneralCommand(requestParameters: SessionApiSendGeneralCommandRequest, options?: any) {
        return SessionApiFp(this.configuration).sendGeneralCommand(requestParameters.sessionId, requestParameters.command, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Issues a command to a client to display a message to the user.
     * @param {SessionApiSendMessageCommandRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SessionApi
     */
    public sendMessageCommand(requestParameters: SessionApiSendMessageCommandRequest, options?: any) {
        return SessionApiFp(this.configuration).sendMessageCommand(requestParameters.sessionId, requestParameters.text, requestParameters.header, requestParameters.timeoutMs, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Issues a playstate command to a client.
     * @param {SessionApiSendPlaystateCommandRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SessionApi
     */
    public sendPlaystateCommand(requestParameters: SessionApiSendPlaystateCommandRequest, options?: any) {
        return SessionApiFp(this.configuration).sendPlaystateCommand(requestParameters.sessionId, requestParameters.command, requestParameters.seekPositionTicks, requestParameters.controllingUserId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Issues a system command to a client.
     * @param {SessionApiSendSystemCommandRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SessionApi
     */
    public sendSystemCommand(requestParameters: SessionApiSendSystemCommandRequest, options?: any) {
        return SessionApiFp(this.configuration).sendSystemCommand(requestParameters.sessionId, requestParameters.command, options).then((request) => request(this.axios, this.basePath));
    }
}
