/* tslint:disable */
/* eslint-disable */
/**
 * Jellyfin API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { PlayMethod } from '../models';
// @ts-ignore
import { PlaybackProgressInfo } from '../models';
// @ts-ignore
import { PlaybackStartInfo } from '../models';
// @ts-ignore
import { PlaybackStopInfo } from '../models';
// @ts-ignore
import { RepeatMode } from '../models';
// @ts-ignore
import { UserItemDataDto } from '../models';
/**
 * PlaystateApi - axios parameter creator
 * @export
 */
export const PlaystateApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Marks an item as played for user.
         * @param {string} userId User id.
         * @param {string} itemId Item id.
         * @param {string} [datePlayed] Optional. The date the item was played.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markPlayedItem: async (userId: string, itemId: string, datePlayed?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId','Required parameter userId was null or undefined when calling markPlayedItem.');
            }
            // verify required parameter 'itemId' is not null or undefined
            if (itemId === null || itemId === undefined) {
                throw new RequiredError('itemId','Required parameter itemId was null or undefined when calling markPlayedItem.');
            }
            const localVarPath = `/Users/{userId}/PlayedItems/{itemId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"itemId"}}`, encodeURIComponent(String(itemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }

            if (datePlayed !== undefined) {
                localVarQueryParameter['datePlayed'] = (datePlayed as any instanceof Date) ?
                    (datePlayed as any).toISOString() :
                    datePlayed;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Marks an item as unplayed for user.
         * @param {string} userId User id.
         * @param {string} itemId Item id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markUnplayedItem: async (userId: string, itemId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId','Required parameter userId was null or undefined when calling markUnplayedItem.');
            }
            // verify required parameter 'itemId' is not null or undefined
            if (itemId === null || itemId === undefined) {
                throw new RequiredError('itemId','Required parameter itemId was null or undefined when calling markUnplayedItem.');
            }
            const localVarPath = `/Users/{userId}/PlayedItems/{itemId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"itemId"}}`, encodeURIComponent(String(itemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Reports a user\'s playback progress.
         * @param {string} userId User id.
         * @param {string} itemId Item id.
         * @param {string} [mediaSourceId] The id of the MediaSource.
         * @param {number} [positionTicks] Optional. The current position, in ticks. 1 tick &#x3D; 10000 ms.
         * @param {number} [audioStreamIndex] The audio stream index.
         * @param {number} [subtitleStreamIndex] The subtitle stream index.
         * @param {number} [volumeLevel] Scale of 0-100.
         * @param {PlayMethod} [playMethod] The play method.
         * @param {string} [liveStreamId] The live stream id.
         * @param {string} [playSessionId] The play session id.
         * @param {RepeatMode} [repeatMode] The repeat mode.
         * @param {boolean} [isPaused] Indicates if the player is paused.
         * @param {boolean} [isMuted] Indicates if the player is muted.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        onPlaybackProgress: async (userId: string, itemId: string, mediaSourceId?: string, positionTicks?: number, audioStreamIndex?: number, subtitleStreamIndex?: number, volumeLevel?: number, playMethod?: PlayMethod, liveStreamId?: string, playSessionId?: string, repeatMode?: RepeatMode, isPaused?: boolean, isMuted?: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId','Required parameter userId was null or undefined when calling onPlaybackProgress.');
            }
            // verify required parameter 'itemId' is not null or undefined
            if (itemId === null || itemId === undefined) {
                throw new RequiredError('itemId','Required parameter itemId was null or undefined when calling onPlaybackProgress.');
            }
            const localVarPath = `/Users/{userId}/PlayingItems/{itemId}/Progress`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"itemId"}}`, encodeURIComponent(String(itemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }

            if (mediaSourceId !== undefined) {
                localVarQueryParameter['mediaSourceId'] = mediaSourceId;
            }

            if (positionTicks !== undefined) {
                localVarQueryParameter['positionTicks'] = positionTicks;
            }

            if (audioStreamIndex !== undefined) {
                localVarQueryParameter['audioStreamIndex'] = audioStreamIndex;
            }

            if (subtitleStreamIndex !== undefined) {
                localVarQueryParameter['subtitleStreamIndex'] = subtitleStreamIndex;
            }

            if (volumeLevel !== undefined) {
                localVarQueryParameter['volumeLevel'] = volumeLevel;
            }

            if (playMethod !== undefined) {
                localVarQueryParameter['playMethod'] = playMethod;
            }

            if (liveStreamId !== undefined) {
                localVarQueryParameter['liveStreamId'] = liveStreamId;
            }

            if (playSessionId !== undefined) {
                localVarQueryParameter['playSessionId'] = playSessionId;
            }

            if (repeatMode !== undefined) {
                localVarQueryParameter['repeatMode'] = repeatMode;
            }

            if (isPaused !== undefined) {
                localVarQueryParameter['isPaused'] = isPaused;
            }

            if (isMuted !== undefined) {
                localVarQueryParameter['isMuted'] = isMuted;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Reports that a user has begun playing an item.
         * @param {string} userId User id.
         * @param {string} itemId Item id.
         * @param {string} [mediaSourceId] The id of the MediaSource.
         * @param {number} [audioStreamIndex] The audio stream index.
         * @param {number} [subtitleStreamIndex] The subtitle stream index.
         * @param {PlayMethod} [playMethod] The play method.
         * @param {string} [liveStreamId] The live stream id.
         * @param {string} [playSessionId] The play session id.
         * @param {boolean} [canSeek] Indicates if the client can seek.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        onPlaybackStart: async (userId: string, itemId: string, mediaSourceId?: string, audioStreamIndex?: number, subtitleStreamIndex?: number, playMethod?: PlayMethod, liveStreamId?: string, playSessionId?: string, canSeek?: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId','Required parameter userId was null or undefined when calling onPlaybackStart.');
            }
            // verify required parameter 'itemId' is not null or undefined
            if (itemId === null || itemId === undefined) {
                throw new RequiredError('itemId','Required parameter itemId was null or undefined when calling onPlaybackStart.');
            }
            const localVarPath = `/Users/{userId}/PlayingItems/{itemId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"itemId"}}`, encodeURIComponent(String(itemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }

            if (mediaSourceId !== undefined) {
                localVarQueryParameter['mediaSourceId'] = mediaSourceId;
            }

            if (audioStreamIndex !== undefined) {
                localVarQueryParameter['audioStreamIndex'] = audioStreamIndex;
            }

            if (subtitleStreamIndex !== undefined) {
                localVarQueryParameter['subtitleStreamIndex'] = subtitleStreamIndex;
            }

            if (playMethod !== undefined) {
                localVarQueryParameter['playMethod'] = playMethod;
            }

            if (liveStreamId !== undefined) {
                localVarQueryParameter['liveStreamId'] = liveStreamId;
            }

            if (playSessionId !== undefined) {
                localVarQueryParameter['playSessionId'] = playSessionId;
            }

            if (canSeek !== undefined) {
                localVarQueryParameter['canSeek'] = canSeek;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Reports that a user has stopped playing an item.
         * @param {string} userId User id.
         * @param {string} itemId Item id.
         * @param {string} [mediaSourceId] The id of the MediaSource.
         * @param {string} [nextMediaType] The next media type that will play.
         * @param {number} [positionTicks] Optional. The position, in ticks, where playback stopped. 1 tick &#x3D; 10000 ms.
         * @param {string} [liveStreamId] The live stream id.
         * @param {string} [playSessionId] The play session id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        onPlaybackStopped: async (userId: string, itemId: string, mediaSourceId?: string, nextMediaType?: string, positionTicks?: number, liveStreamId?: string, playSessionId?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId','Required parameter userId was null or undefined when calling onPlaybackStopped.');
            }
            // verify required parameter 'itemId' is not null or undefined
            if (itemId === null || itemId === undefined) {
                throw new RequiredError('itemId','Required parameter itemId was null or undefined when calling onPlaybackStopped.');
            }
            const localVarPath = `/Users/{userId}/PlayingItems/{itemId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"itemId"}}`, encodeURIComponent(String(itemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }

            if (mediaSourceId !== undefined) {
                localVarQueryParameter['mediaSourceId'] = mediaSourceId;
            }

            if (nextMediaType !== undefined) {
                localVarQueryParameter['nextMediaType'] = nextMediaType;
            }

            if (positionTicks !== undefined) {
                localVarQueryParameter['positionTicks'] = positionTicks;
            }

            if (liveStreamId !== undefined) {
                localVarQueryParameter['liveStreamId'] = liveStreamId;
            }

            if (playSessionId !== undefined) {
                localVarQueryParameter['playSessionId'] = playSessionId;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Pings a playback session.
         * @param {string} [playSessionId] Playback session id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pingPlaybackSession: async (playSessionId?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/Sessions/Playing/Ping`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }

            if (playSessionId !== undefined) {
                localVarQueryParameter['playSessionId'] = playSessionId;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Reports playback progress within a session.
         * @param {PlaybackProgressInfo} [playbackProgressInfo] The playback progress info.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportPlaybackProgress: async (playbackProgressInfo?: PlaybackProgressInfo, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/Sessions/Playing/Progress`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof playbackProgressInfo !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(playbackProgressInfo !== undefined ? playbackProgressInfo : {}) : (playbackProgressInfo || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Reports playback has started within a session.
         * @param {PlaybackStartInfo} [playbackStartInfo] The playback start info.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportPlaybackStart: async (playbackStartInfo?: PlaybackStartInfo, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/Sessions/Playing`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof playbackStartInfo !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(playbackStartInfo !== undefined ? playbackStartInfo : {}) : (playbackStartInfo || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Reports playback has stopped within a session.
         * @param {PlaybackStopInfo} [playbackStopInfo] The playback stop info.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportPlaybackStopped: async (playbackStopInfo?: PlaybackStopInfo, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/Sessions/Playing/Stopped`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof playbackStopInfo !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(playbackStopInfo !== undefined ? playbackStopInfo : {}) : (playbackStopInfo || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PlaystateApi - functional programming interface
 * @export
 */
export const PlaystateApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Marks an item as played for user.
         * @param {string} userId User id.
         * @param {string} itemId Item id.
         * @param {string} [datePlayed] Optional. The date the item was played.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async markPlayedItem(userId: string, itemId: string, datePlayed?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserItemDataDto>> {
            const localVarAxiosArgs = await PlaystateApiAxiosParamCreator(configuration).markPlayedItem(userId, itemId, datePlayed, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Marks an item as unplayed for user.
         * @param {string} userId User id.
         * @param {string} itemId Item id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async markUnplayedItem(userId: string, itemId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserItemDataDto>> {
            const localVarAxiosArgs = await PlaystateApiAxiosParamCreator(configuration).markUnplayedItem(userId, itemId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Reports a user\'s playback progress.
         * @param {string} userId User id.
         * @param {string} itemId Item id.
         * @param {string} [mediaSourceId] The id of the MediaSource.
         * @param {number} [positionTicks] Optional. The current position, in ticks. 1 tick &#x3D; 10000 ms.
         * @param {number} [audioStreamIndex] The audio stream index.
         * @param {number} [subtitleStreamIndex] The subtitle stream index.
         * @param {number} [volumeLevel] Scale of 0-100.
         * @param {PlayMethod} [playMethod] The play method.
         * @param {string} [liveStreamId] The live stream id.
         * @param {string} [playSessionId] The play session id.
         * @param {RepeatMode} [repeatMode] The repeat mode.
         * @param {boolean} [isPaused] Indicates if the player is paused.
         * @param {boolean} [isMuted] Indicates if the player is muted.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async onPlaybackProgress(userId: string, itemId: string, mediaSourceId?: string, positionTicks?: number, audioStreamIndex?: number, subtitleStreamIndex?: number, volumeLevel?: number, playMethod?: PlayMethod, liveStreamId?: string, playSessionId?: string, repeatMode?: RepeatMode, isPaused?: boolean, isMuted?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await PlaystateApiAxiosParamCreator(configuration).onPlaybackProgress(userId, itemId, mediaSourceId, positionTicks, audioStreamIndex, subtitleStreamIndex, volumeLevel, playMethod, liveStreamId, playSessionId, repeatMode, isPaused, isMuted, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Reports that a user has begun playing an item.
         * @param {string} userId User id.
         * @param {string} itemId Item id.
         * @param {string} [mediaSourceId] The id of the MediaSource.
         * @param {number} [audioStreamIndex] The audio stream index.
         * @param {number} [subtitleStreamIndex] The subtitle stream index.
         * @param {PlayMethod} [playMethod] The play method.
         * @param {string} [liveStreamId] The live stream id.
         * @param {string} [playSessionId] The play session id.
         * @param {boolean} [canSeek] Indicates if the client can seek.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async onPlaybackStart(userId: string, itemId: string, mediaSourceId?: string, audioStreamIndex?: number, subtitleStreamIndex?: number, playMethod?: PlayMethod, liveStreamId?: string, playSessionId?: string, canSeek?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await PlaystateApiAxiosParamCreator(configuration).onPlaybackStart(userId, itemId, mediaSourceId, audioStreamIndex, subtitleStreamIndex, playMethod, liveStreamId, playSessionId, canSeek, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Reports that a user has stopped playing an item.
         * @param {string} userId User id.
         * @param {string} itemId Item id.
         * @param {string} [mediaSourceId] The id of the MediaSource.
         * @param {string} [nextMediaType] The next media type that will play.
         * @param {number} [positionTicks] Optional. The position, in ticks, where playback stopped. 1 tick &#x3D; 10000 ms.
         * @param {string} [liveStreamId] The live stream id.
         * @param {string} [playSessionId] The play session id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async onPlaybackStopped(userId: string, itemId: string, mediaSourceId?: string, nextMediaType?: string, positionTicks?: number, liveStreamId?: string, playSessionId?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await PlaystateApiAxiosParamCreator(configuration).onPlaybackStopped(userId, itemId, mediaSourceId, nextMediaType, positionTicks, liveStreamId, playSessionId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Pings a playback session.
         * @param {string} [playSessionId] Playback session id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pingPlaybackSession(playSessionId?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await PlaystateApiAxiosParamCreator(configuration).pingPlaybackSession(playSessionId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Reports playback progress within a session.
         * @param {PlaybackProgressInfo} [playbackProgressInfo] The playback progress info.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reportPlaybackProgress(playbackProgressInfo?: PlaybackProgressInfo, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await PlaystateApiAxiosParamCreator(configuration).reportPlaybackProgress(playbackProgressInfo, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Reports playback has started within a session.
         * @param {PlaybackStartInfo} [playbackStartInfo] The playback start info.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reportPlaybackStart(playbackStartInfo?: PlaybackStartInfo, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await PlaystateApiAxiosParamCreator(configuration).reportPlaybackStart(playbackStartInfo, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Reports playback has stopped within a session.
         * @param {PlaybackStopInfo} [playbackStopInfo] The playback stop info.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reportPlaybackStopped(playbackStopInfo?: PlaybackStopInfo, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await PlaystateApiAxiosParamCreator(configuration).reportPlaybackStopped(playbackStopInfo, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * PlaystateApi - factory interface
 * @export
 */
export const PlaystateApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Marks an item as played for user.
         * @param {string} userId User id.
         * @param {string} itemId Item id.
         * @param {string} [datePlayed] Optional. The date the item was played.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markPlayedItem(userId: string, itemId: string, datePlayed?: string, options?: any): AxiosPromise<UserItemDataDto> {
            return PlaystateApiFp(configuration).markPlayedItem(userId, itemId, datePlayed, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Marks an item as unplayed for user.
         * @param {string} userId User id.
         * @param {string} itemId Item id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markUnplayedItem(userId: string, itemId: string, options?: any): AxiosPromise<UserItemDataDto> {
            return PlaystateApiFp(configuration).markUnplayedItem(userId, itemId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Reports a user\'s playback progress.
         * @param {string} userId User id.
         * @param {string} itemId Item id.
         * @param {string} [mediaSourceId] The id of the MediaSource.
         * @param {number} [positionTicks] Optional. The current position, in ticks. 1 tick &#x3D; 10000 ms.
         * @param {number} [audioStreamIndex] The audio stream index.
         * @param {number} [subtitleStreamIndex] The subtitle stream index.
         * @param {number} [volumeLevel] Scale of 0-100.
         * @param {PlayMethod} [playMethod] The play method.
         * @param {string} [liveStreamId] The live stream id.
         * @param {string} [playSessionId] The play session id.
         * @param {RepeatMode} [repeatMode] The repeat mode.
         * @param {boolean} [isPaused] Indicates if the player is paused.
         * @param {boolean} [isMuted] Indicates if the player is muted.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        onPlaybackProgress(userId: string, itemId: string, mediaSourceId?: string, positionTicks?: number, audioStreamIndex?: number, subtitleStreamIndex?: number, volumeLevel?: number, playMethod?: PlayMethod, liveStreamId?: string, playSessionId?: string, repeatMode?: RepeatMode, isPaused?: boolean, isMuted?: boolean, options?: any): AxiosPromise<void> {
            return PlaystateApiFp(configuration).onPlaybackProgress(userId, itemId, mediaSourceId, positionTicks, audioStreamIndex, subtitleStreamIndex, volumeLevel, playMethod, liveStreamId, playSessionId, repeatMode, isPaused, isMuted, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Reports that a user has begun playing an item.
         * @param {string} userId User id.
         * @param {string} itemId Item id.
         * @param {string} [mediaSourceId] The id of the MediaSource.
         * @param {number} [audioStreamIndex] The audio stream index.
         * @param {number} [subtitleStreamIndex] The subtitle stream index.
         * @param {PlayMethod} [playMethod] The play method.
         * @param {string} [liveStreamId] The live stream id.
         * @param {string} [playSessionId] The play session id.
         * @param {boolean} [canSeek] Indicates if the client can seek.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        onPlaybackStart(userId: string, itemId: string, mediaSourceId?: string, audioStreamIndex?: number, subtitleStreamIndex?: number, playMethod?: PlayMethod, liveStreamId?: string, playSessionId?: string, canSeek?: boolean, options?: any): AxiosPromise<void> {
            return PlaystateApiFp(configuration).onPlaybackStart(userId, itemId, mediaSourceId, audioStreamIndex, subtitleStreamIndex, playMethod, liveStreamId, playSessionId, canSeek, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Reports that a user has stopped playing an item.
         * @param {string} userId User id.
         * @param {string} itemId Item id.
         * @param {string} [mediaSourceId] The id of the MediaSource.
         * @param {string} [nextMediaType] The next media type that will play.
         * @param {number} [positionTicks] Optional. The position, in ticks, where playback stopped. 1 tick &#x3D; 10000 ms.
         * @param {string} [liveStreamId] The live stream id.
         * @param {string} [playSessionId] The play session id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        onPlaybackStopped(userId: string, itemId: string, mediaSourceId?: string, nextMediaType?: string, positionTicks?: number, liveStreamId?: string, playSessionId?: string, options?: any): AxiosPromise<void> {
            return PlaystateApiFp(configuration).onPlaybackStopped(userId, itemId, mediaSourceId, nextMediaType, positionTicks, liveStreamId, playSessionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Pings a playback session.
         * @param {string} [playSessionId] Playback session id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pingPlaybackSession(playSessionId?: string, options?: any): AxiosPromise<void> {
            return PlaystateApiFp(configuration).pingPlaybackSession(playSessionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Reports playback progress within a session.
         * @param {PlaybackProgressInfo} [playbackProgressInfo] The playback progress info.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportPlaybackProgress(playbackProgressInfo?: PlaybackProgressInfo, options?: any): AxiosPromise<void> {
            return PlaystateApiFp(configuration).reportPlaybackProgress(playbackProgressInfo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Reports playback has started within a session.
         * @param {PlaybackStartInfo} [playbackStartInfo] The playback start info.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportPlaybackStart(playbackStartInfo?: PlaybackStartInfo, options?: any): AxiosPromise<void> {
            return PlaystateApiFp(configuration).reportPlaybackStart(playbackStartInfo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Reports playback has stopped within a session.
         * @param {PlaybackStopInfo} [playbackStopInfo] The playback stop info.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportPlaybackStopped(playbackStopInfo?: PlaybackStopInfo, options?: any): AxiosPromise<void> {
            return PlaystateApiFp(configuration).reportPlaybackStopped(playbackStopInfo, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for markPlayedItem operation in PlaystateApi.
 * @export
 * @interface PlaystateApiMarkPlayedItemRequest
 */
export interface PlaystateApiMarkPlayedItemRequest {
    /**
     * User id.
     * @type {string}
     * @memberof PlaystateApiMarkPlayedItem
     */
    readonly userId: string

    /**
     * Item id.
     * @type {string}
     * @memberof PlaystateApiMarkPlayedItem
     */
    readonly itemId: string

    /**
     * Optional. The date the item was played.
     * @type {string}
     * @memberof PlaystateApiMarkPlayedItem
     */
    readonly datePlayed?: string
}

/**
 * Request parameters for markUnplayedItem operation in PlaystateApi.
 * @export
 * @interface PlaystateApiMarkUnplayedItemRequest
 */
export interface PlaystateApiMarkUnplayedItemRequest {
    /**
     * User id.
     * @type {string}
     * @memberof PlaystateApiMarkUnplayedItem
     */
    readonly userId: string

    /**
     * Item id.
     * @type {string}
     * @memberof PlaystateApiMarkUnplayedItem
     */
    readonly itemId: string
}

/**
 * Request parameters for onPlaybackProgress operation in PlaystateApi.
 * @export
 * @interface PlaystateApiOnPlaybackProgressRequest
 */
export interface PlaystateApiOnPlaybackProgressRequest {
    /**
     * User id.
     * @type {string}
     * @memberof PlaystateApiOnPlaybackProgress
     */
    readonly userId: string

    /**
     * Item id.
     * @type {string}
     * @memberof PlaystateApiOnPlaybackProgress
     */
    readonly itemId: string

    /**
     * The id of the MediaSource.
     * @type {string}
     * @memberof PlaystateApiOnPlaybackProgress
     */
    readonly mediaSourceId?: string

    /**
     * Optional. The current position, in ticks. 1 tick &#x3D; 10000 ms.
     * @type {number}
     * @memberof PlaystateApiOnPlaybackProgress
     */
    readonly positionTicks?: number

    /**
     * The audio stream index.
     * @type {number}
     * @memberof PlaystateApiOnPlaybackProgress
     */
    readonly audioStreamIndex?: number

    /**
     * The subtitle stream index.
     * @type {number}
     * @memberof PlaystateApiOnPlaybackProgress
     */
    readonly subtitleStreamIndex?: number

    /**
     * Scale of 0-100.
     * @type {number}
     * @memberof PlaystateApiOnPlaybackProgress
     */
    readonly volumeLevel?: number

    /**
     * The play method.
     * @type {PlayMethod}
     * @memberof PlaystateApiOnPlaybackProgress
     */
    readonly playMethod?: PlayMethod

    /**
     * The live stream id.
     * @type {string}
     * @memberof PlaystateApiOnPlaybackProgress
     */
    readonly liveStreamId?: string

    /**
     * The play session id.
     * @type {string}
     * @memberof PlaystateApiOnPlaybackProgress
     */
    readonly playSessionId?: string

    /**
     * The repeat mode.
     * @type {RepeatMode}
     * @memberof PlaystateApiOnPlaybackProgress
     */
    readonly repeatMode?: RepeatMode

    /**
     * Indicates if the player is paused.
     * @type {boolean}
     * @memberof PlaystateApiOnPlaybackProgress
     */
    readonly isPaused?: boolean

    /**
     * Indicates if the player is muted.
     * @type {boolean}
     * @memberof PlaystateApiOnPlaybackProgress
     */
    readonly isMuted?: boolean
}

/**
 * Request parameters for onPlaybackStart operation in PlaystateApi.
 * @export
 * @interface PlaystateApiOnPlaybackStartRequest
 */
export interface PlaystateApiOnPlaybackStartRequest {
    /**
     * User id.
     * @type {string}
     * @memberof PlaystateApiOnPlaybackStart
     */
    readonly userId: string

    /**
     * Item id.
     * @type {string}
     * @memberof PlaystateApiOnPlaybackStart
     */
    readonly itemId: string

    /**
     * The id of the MediaSource.
     * @type {string}
     * @memberof PlaystateApiOnPlaybackStart
     */
    readonly mediaSourceId?: string

    /**
     * The audio stream index.
     * @type {number}
     * @memberof PlaystateApiOnPlaybackStart
     */
    readonly audioStreamIndex?: number

    /**
     * The subtitle stream index.
     * @type {number}
     * @memberof PlaystateApiOnPlaybackStart
     */
    readonly subtitleStreamIndex?: number

    /**
     * The play method.
     * @type {PlayMethod}
     * @memberof PlaystateApiOnPlaybackStart
     */
    readonly playMethod?: PlayMethod

    /**
     * The live stream id.
     * @type {string}
     * @memberof PlaystateApiOnPlaybackStart
     */
    readonly liveStreamId?: string

    /**
     * The play session id.
     * @type {string}
     * @memberof PlaystateApiOnPlaybackStart
     */
    readonly playSessionId?: string

    /**
     * Indicates if the client can seek.
     * @type {boolean}
     * @memberof PlaystateApiOnPlaybackStart
     */
    readonly canSeek?: boolean
}

/**
 * Request parameters for onPlaybackStopped operation in PlaystateApi.
 * @export
 * @interface PlaystateApiOnPlaybackStoppedRequest
 */
export interface PlaystateApiOnPlaybackStoppedRequest {
    /**
     * User id.
     * @type {string}
     * @memberof PlaystateApiOnPlaybackStopped
     */
    readonly userId: string

    /**
     * Item id.
     * @type {string}
     * @memberof PlaystateApiOnPlaybackStopped
     */
    readonly itemId: string

    /**
     * The id of the MediaSource.
     * @type {string}
     * @memberof PlaystateApiOnPlaybackStopped
     */
    readonly mediaSourceId?: string

    /**
     * The next media type that will play.
     * @type {string}
     * @memberof PlaystateApiOnPlaybackStopped
     */
    readonly nextMediaType?: string

    /**
     * Optional. The position, in ticks, where playback stopped. 1 tick &#x3D; 10000 ms.
     * @type {number}
     * @memberof PlaystateApiOnPlaybackStopped
     */
    readonly positionTicks?: number

    /**
     * The live stream id.
     * @type {string}
     * @memberof PlaystateApiOnPlaybackStopped
     */
    readonly liveStreamId?: string

    /**
     * The play session id.
     * @type {string}
     * @memberof PlaystateApiOnPlaybackStopped
     */
    readonly playSessionId?: string
}

/**
 * Request parameters for pingPlaybackSession operation in PlaystateApi.
 * @export
 * @interface PlaystateApiPingPlaybackSessionRequest
 */
export interface PlaystateApiPingPlaybackSessionRequest {
    /**
     * Playback session id.
     * @type {string}
     * @memberof PlaystateApiPingPlaybackSession
     */
    readonly playSessionId?: string
}

/**
 * Request parameters for reportPlaybackProgress operation in PlaystateApi.
 * @export
 * @interface PlaystateApiReportPlaybackProgressRequest
 */
export interface PlaystateApiReportPlaybackProgressRequest {
    /**
     * The playback progress info.
     * @type {PlaybackProgressInfo}
     * @memberof PlaystateApiReportPlaybackProgress
     */
    readonly playbackProgressInfo?: PlaybackProgressInfo
}

/**
 * Request parameters for reportPlaybackStart operation in PlaystateApi.
 * @export
 * @interface PlaystateApiReportPlaybackStartRequest
 */
export interface PlaystateApiReportPlaybackStartRequest {
    /**
     * The playback start info.
     * @type {PlaybackStartInfo}
     * @memberof PlaystateApiReportPlaybackStart
     */
    readonly playbackStartInfo?: PlaybackStartInfo
}

/**
 * Request parameters for reportPlaybackStopped operation in PlaystateApi.
 * @export
 * @interface PlaystateApiReportPlaybackStoppedRequest
 */
export interface PlaystateApiReportPlaybackStoppedRequest {
    /**
     * The playback stop info.
     * @type {PlaybackStopInfo}
     * @memberof PlaystateApiReportPlaybackStopped
     */
    readonly playbackStopInfo?: PlaybackStopInfo
}

/**
 * PlaystateApi - object-oriented interface
 * @export
 * @class PlaystateApi
 * @extends {BaseAPI}
 */
export class PlaystateApi extends BaseAPI {
    /**
     * 
     * @summary Marks an item as played for user.
     * @param {PlaystateApiMarkPlayedItemRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlaystateApi
     */
    public markPlayedItem(requestParameters: PlaystateApiMarkPlayedItemRequest, options?: any) {
        return PlaystateApiFp(this.configuration).markPlayedItem(requestParameters.userId, requestParameters.itemId, requestParameters.datePlayed, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Marks an item as unplayed for user.
     * @param {PlaystateApiMarkUnplayedItemRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlaystateApi
     */
    public markUnplayedItem(requestParameters: PlaystateApiMarkUnplayedItemRequest, options?: any) {
        return PlaystateApiFp(this.configuration).markUnplayedItem(requestParameters.userId, requestParameters.itemId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Reports a user\'s playback progress.
     * @param {PlaystateApiOnPlaybackProgressRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlaystateApi
     */
    public onPlaybackProgress(requestParameters: PlaystateApiOnPlaybackProgressRequest, options?: any) {
        return PlaystateApiFp(this.configuration).onPlaybackProgress(requestParameters.userId, requestParameters.itemId, requestParameters.mediaSourceId, requestParameters.positionTicks, requestParameters.audioStreamIndex, requestParameters.subtitleStreamIndex, requestParameters.volumeLevel, requestParameters.playMethod, requestParameters.liveStreamId, requestParameters.playSessionId, requestParameters.repeatMode, requestParameters.isPaused, requestParameters.isMuted, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Reports that a user has begun playing an item.
     * @param {PlaystateApiOnPlaybackStartRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlaystateApi
     */
    public onPlaybackStart(requestParameters: PlaystateApiOnPlaybackStartRequest, options?: any) {
        return PlaystateApiFp(this.configuration).onPlaybackStart(requestParameters.userId, requestParameters.itemId, requestParameters.mediaSourceId, requestParameters.audioStreamIndex, requestParameters.subtitleStreamIndex, requestParameters.playMethod, requestParameters.liveStreamId, requestParameters.playSessionId, requestParameters.canSeek, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Reports that a user has stopped playing an item.
     * @param {PlaystateApiOnPlaybackStoppedRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlaystateApi
     */
    public onPlaybackStopped(requestParameters: PlaystateApiOnPlaybackStoppedRequest, options?: any) {
        return PlaystateApiFp(this.configuration).onPlaybackStopped(requestParameters.userId, requestParameters.itemId, requestParameters.mediaSourceId, requestParameters.nextMediaType, requestParameters.positionTicks, requestParameters.liveStreamId, requestParameters.playSessionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Pings a playback session.
     * @param {PlaystateApiPingPlaybackSessionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlaystateApi
     */
    public pingPlaybackSession(requestParameters: PlaystateApiPingPlaybackSessionRequest = {}, options?: any) {
        return PlaystateApiFp(this.configuration).pingPlaybackSession(requestParameters.playSessionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Reports playback progress within a session.
     * @param {PlaystateApiReportPlaybackProgressRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlaystateApi
     */
    public reportPlaybackProgress(requestParameters: PlaystateApiReportPlaybackProgressRequest = {}, options?: any) {
        return PlaystateApiFp(this.configuration).reportPlaybackProgress(requestParameters.playbackProgressInfo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Reports playback has started within a session.
     * @param {PlaystateApiReportPlaybackStartRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlaystateApi
     */
    public reportPlaybackStart(requestParameters: PlaystateApiReportPlaybackStartRequest = {}, options?: any) {
        return PlaystateApiFp(this.configuration).reportPlaybackStart(requestParameters.playbackStartInfo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Reports playback has stopped within a session.
     * @param {PlaystateApiReportPlaybackStoppedRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlaystateApi
     */
    public reportPlaybackStopped(requestParameters: PlaystateApiReportPlaybackStoppedRequest = {}, options?: any) {
        return PlaystateApiFp(this.configuration).reportPlaybackStopped(requestParameters.playbackStopInfo, options).then((request) => request(this.axios, this.basePath));
    }
}
