/* tslint:disable */
/* eslint-disable */
/**
 * Jellyfin API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ImageFormat } from '../models';
// @ts-ignore
import { ImageInfo } from '../models';
// @ts-ignore
import { ImageType } from '../models';
// @ts-ignore
import { ProblemDetails } from '../models';
/**
 * ImageApi - axios parameter creator
 * @export
 */
export const ImageApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Delete an item\'s image.
         * @param {string} itemId Item id.
         * @param {ImageType} imageType Image type.
         * @param {number} imageIndex The image index.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteItemImage: async (itemId: string, imageType: ImageType, imageIndex: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'itemId' is not null or undefined
            if (itemId === null || itemId === undefined) {
                throw new RequiredError('itemId','Required parameter itemId was null or undefined when calling deleteItemImage.');
            }
            // verify required parameter 'imageType' is not null or undefined
            if (imageType === null || imageType === undefined) {
                throw new RequiredError('imageType','Required parameter imageType was null or undefined when calling deleteItemImage.');
            }
            // verify required parameter 'imageIndex' is not null or undefined
            if (imageIndex === null || imageIndex === undefined) {
                throw new RequiredError('imageIndex','Required parameter imageIndex was null or undefined when calling deleteItemImage.');
            }
            const localVarPath = `/Items/{itemId}/Images/{imageType}`
                .replace(`{${"itemId"}}`, encodeURIComponent(String(itemId)))
                .replace(`{${"imageType"}}`, encodeURIComponent(String(imageType)))
                .replace(`{${"imageIndex"}}`, encodeURIComponent(String(imageIndex)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete an item\'s image.
         * @param {string} itemId Item id.
         * @param {ImageType} imageType Image type.
         * @param {number} imageIndex The image index.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteItemImage2: async (itemId: string, imageType: ImageType, imageIndex: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'itemId' is not null or undefined
            if (itemId === null || itemId === undefined) {
                throw new RequiredError('itemId','Required parameter itemId was null or undefined when calling deleteItemImage2.');
            }
            // verify required parameter 'imageType' is not null or undefined
            if (imageType === null || imageType === undefined) {
                throw new RequiredError('imageType','Required parameter imageType was null or undefined when calling deleteItemImage2.');
            }
            // verify required parameter 'imageIndex' is not null or undefined
            if (imageIndex === null || imageIndex === undefined) {
                throw new RequiredError('imageIndex','Required parameter imageIndex was null or undefined when calling deleteItemImage2.');
            }
            const localVarPath = `/Items/{itemId}/Images/{imageType}/{imageIndex}`
                .replace(`{${"itemId"}}`, encodeURIComponent(String(itemId)))
                .replace(`{${"imageType"}}`, encodeURIComponent(String(imageType)))
                .replace(`{${"imageIndex"}}`, encodeURIComponent(String(imageIndex)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete the user\'s image.
         * @param {string} userId User Id.
         * @param {ImageType} imageType (Unused) Image type.
         * @param {number} index (Unused) Image index.
         * @param {string} itemType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserImage: async (userId: string, imageType: ImageType, index: number, itemType: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId','Required parameter userId was null or undefined when calling deleteUserImage.');
            }
            // verify required parameter 'imageType' is not null or undefined
            if (imageType === null || imageType === undefined) {
                throw new RequiredError('imageType','Required parameter imageType was null or undefined when calling deleteUserImage.');
            }
            // verify required parameter 'index' is not null or undefined
            if (index === null || index === undefined) {
                throw new RequiredError('index','Required parameter index was null or undefined when calling deleteUserImage.');
            }
            // verify required parameter 'itemType' is not null or undefined
            if (itemType === null || itemType === undefined) {
                throw new RequiredError('itemType','Required parameter itemType was null or undefined when calling deleteUserImage.');
            }
            const localVarPath = `/Users/{userId}/Images/{itemType}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"imageType"}}`, encodeURIComponent(String(imageType)))
                .replace(`{${"index"}}`, encodeURIComponent(String(index)))
                .replace(`{${"itemType"}}`, encodeURIComponent(String(itemType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete the user\'s image.
         * @param {string} userId User Id.
         * @param {ImageType} imageType (Unused) Image type.
         * @param {number} index (Unused) Image index.
         * @param {string} itemType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserImage2: async (userId: string, imageType: ImageType, index: number, itemType: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId','Required parameter userId was null or undefined when calling deleteUserImage2.');
            }
            // verify required parameter 'imageType' is not null or undefined
            if (imageType === null || imageType === undefined) {
                throw new RequiredError('imageType','Required parameter imageType was null or undefined when calling deleteUserImage2.');
            }
            // verify required parameter 'index' is not null or undefined
            if (index === null || index === undefined) {
                throw new RequiredError('index','Required parameter index was null or undefined when calling deleteUserImage2.');
            }
            // verify required parameter 'itemType' is not null or undefined
            if (itemType === null || itemType === undefined) {
                throw new RequiredError('itemType','Required parameter itemType was null or undefined when calling deleteUserImage2.');
            }
            const localVarPath = `/Users/{userId}/Images/{itemType}/{index}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"imageType"}}`, encodeURIComponent(String(imageType)))
                .replace(`{${"index"}}`, encodeURIComponent(String(index)))
                .replace(`{${"itemType"}}`, encodeURIComponent(String(itemType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get artist image by name.
         * @param {string} name Artist name.
         * @param {ImageType} imageType Image type.
         * @param {number} imageIndex Image index.
         * @param {string} [tag] Optional. Supply the cache tag from the item object to receive strong caching headers.
         * @param {ImageFormat} [format] Determines the output format of the image - original,gif,jpg,png.
         * @param {number} [maxWidth] The maximum image width to return.
         * @param {number} [maxHeight] The maximum image height to return.
         * @param {number} [percentPlayed] Optional. Percent to render for the percent played overlay.
         * @param {number} [unplayedCount] Optional. Unplayed count overlay to render.
         * @param {number} [width] The fixed image width to return.
         * @param {number} [height] The fixed image height to return.
         * @param {number} [quality] Optional. Quality setting, from 0-100. Defaults to 90 and should suffice in most cases.
         * @param {boolean} [cropWhitespace] Optional. Specify if whitespace should be cropped out of the image. True/False. If unspecified, whitespace will be cropped from logos and clear art.
         * @param {boolean} [addPlayedIndicator] Optional. Add a played indicator.
         * @param {number} [blur] Optional. Blur image.
         * @param {string} [backgroundColor] Optional. Apply a background color for transparent images.
         * @param {string} [foregroundLayer] Optional. Apply a foreground layer on top of the image.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getArtistImage: async (name: string, imageType: ImageType, imageIndex: number, tag?: string, format?: ImageFormat, maxWidth?: number, maxHeight?: number, percentPlayed?: number, unplayedCount?: number, width?: number, height?: number, quality?: number, cropWhitespace?: boolean, addPlayedIndicator?: boolean, blur?: number, backgroundColor?: string, foregroundLayer?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            if (name === null || name === undefined) {
                throw new RequiredError('name','Required parameter name was null or undefined when calling getArtistImage.');
            }
            // verify required parameter 'imageType' is not null or undefined
            if (imageType === null || imageType === undefined) {
                throw new RequiredError('imageType','Required parameter imageType was null or undefined when calling getArtistImage.');
            }
            // verify required parameter 'imageIndex' is not null or undefined
            if (imageIndex === null || imageIndex === undefined) {
                throw new RequiredError('imageIndex','Required parameter imageIndex was null or undefined when calling getArtistImage.');
            }
            const localVarPath = `/Artists/{name}/Images/{imageType}/{imageIndex}`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)))
                .replace(`{${"imageType"}}`, encodeURIComponent(String(imageType)))
                .replace(`{${"imageIndex"}}`, encodeURIComponent(String(imageIndex)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }

            if (tag !== undefined) {
                localVarQueryParameter['tag'] = tag;
            }

            if (format !== undefined) {
                localVarQueryParameter['format'] = format;
            }

            if (maxWidth !== undefined) {
                localVarQueryParameter['maxWidth'] = maxWidth;
            }

            if (maxHeight !== undefined) {
                localVarQueryParameter['maxHeight'] = maxHeight;
            }

            if (percentPlayed !== undefined) {
                localVarQueryParameter['percentPlayed'] = percentPlayed;
            }

            if (unplayedCount !== undefined) {
                localVarQueryParameter['unplayedCount'] = unplayedCount;
            }

            if (width !== undefined) {
                localVarQueryParameter['width'] = width;
            }

            if (height !== undefined) {
                localVarQueryParameter['height'] = height;
            }

            if (quality !== undefined) {
                localVarQueryParameter['quality'] = quality;
            }

            if (cropWhitespace !== undefined) {
                localVarQueryParameter['cropWhitespace'] = cropWhitespace;
            }

            if (addPlayedIndicator !== undefined) {
                localVarQueryParameter['addPlayedIndicator'] = addPlayedIndicator;
            }

            if (blur !== undefined) {
                localVarQueryParameter['blur'] = blur;
            }

            if (backgroundColor !== undefined) {
                localVarQueryParameter['backgroundColor'] = backgroundColor;
            }

            if (foregroundLayer !== undefined) {
                localVarQueryParameter['foregroundLayer'] = foregroundLayer;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get genre image by name.
         * @param {string} name Genre name.
         * @param {ImageType} imageType Image type.
         * @param {number} imageIndex Image index.
         * @param {string} [tag] Optional. Supply the cache tag from the item object to receive strong caching headers.
         * @param {ImageFormat} [format] Determines the output format of the image - original,gif,jpg,png.
         * @param {number} [maxWidth] The maximum image width to return.
         * @param {number} [maxHeight] The maximum image height to return.
         * @param {number} [percentPlayed] Optional. Percent to render for the percent played overlay.
         * @param {number} [unplayedCount] Optional. Unplayed count overlay to render.
         * @param {number} [width] The fixed image width to return.
         * @param {number} [height] The fixed image height to return.
         * @param {number} [quality] Optional. Quality setting, from 0-100. Defaults to 90 and should suffice in most cases.
         * @param {boolean} [cropWhitespace] Optional. Specify if whitespace should be cropped out of the image. True/False. If unspecified, whitespace will be cropped from logos and clear art.
         * @param {boolean} [addPlayedIndicator] Optional. Add a played indicator.
         * @param {number} [blur] Optional. Blur image.
         * @param {string} [backgroundColor] Optional. Apply a background color for transparent images.
         * @param {string} [foregroundLayer] Optional. Apply a foreground layer on top of the image.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGenreImage: async (name: string, imageType: ImageType, imageIndex: number, tag?: string, format?: ImageFormat, maxWidth?: number, maxHeight?: number, percentPlayed?: number, unplayedCount?: number, width?: number, height?: number, quality?: number, cropWhitespace?: boolean, addPlayedIndicator?: boolean, blur?: number, backgroundColor?: string, foregroundLayer?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            if (name === null || name === undefined) {
                throw new RequiredError('name','Required parameter name was null or undefined when calling getGenreImage.');
            }
            // verify required parameter 'imageType' is not null or undefined
            if (imageType === null || imageType === undefined) {
                throw new RequiredError('imageType','Required parameter imageType was null or undefined when calling getGenreImage.');
            }
            // verify required parameter 'imageIndex' is not null or undefined
            if (imageIndex === null || imageIndex === undefined) {
                throw new RequiredError('imageIndex','Required parameter imageIndex was null or undefined when calling getGenreImage.');
            }
            const localVarPath = `/Genres/{name}/Images/{imageType}/{imageIndex}`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)))
                .replace(`{${"imageType"}}`, encodeURIComponent(String(imageType)))
                .replace(`{${"imageIndex"}}`, encodeURIComponent(String(imageIndex)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }

            if (tag !== undefined) {
                localVarQueryParameter['tag'] = tag;
            }

            if (format !== undefined) {
                localVarQueryParameter['format'] = format;
            }

            if (maxWidth !== undefined) {
                localVarQueryParameter['maxWidth'] = maxWidth;
            }

            if (maxHeight !== undefined) {
                localVarQueryParameter['maxHeight'] = maxHeight;
            }

            if (percentPlayed !== undefined) {
                localVarQueryParameter['percentPlayed'] = percentPlayed;
            }

            if (unplayedCount !== undefined) {
                localVarQueryParameter['unplayedCount'] = unplayedCount;
            }

            if (width !== undefined) {
                localVarQueryParameter['width'] = width;
            }

            if (height !== undefined) {
                localVarQueryParameter['height'] = height;
            }

            if (quality !== undefined) {
                localVarQueryParameter['quality'] = quality;
            }

            if (cropWhitespace !== undefined) {
                localVarQueryParameter['cropWhitespace'] = cropWhitespace;
            }

            if (addPlayedIndicator !== undefined) {
                localVarQueryParameter['addPlayedIndicator'] = addPlayedIndicator;
            }

            if (blur !== undefined) {
                localVarQueryParameter['blur'] = blur;
            }

            if (backgroundColor !== undefined) {
                localVarQueryParameter['backgroundColor'] = backgroundColor;
            }

            if (foregroundLayer !== undefined) {
                localVarQueryParameter['foregroundLayer'] = foregroundLayer;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets the item\'s image.
         * @param {string} itemId Item id.
         * @param {ImageType} imageType Image type.
         * @param {number} imageIndex Image index.
         * @param {number} [maxWidth] The maximum image width to return.
         * @param {number} [maxHeight] The maximum image height to return.
         * @param {number} [width] The fixed image width to return.
         * @param {number} [height] The fixed image height to return.
         * @param {number} [quality] Optional. Quality setting, from 0-100. Defaults to 90 and should suffice in most cases.
         * @param {string} [tag] Optional. Supply the cache tag from the item object to receive strong caching headers.
         * @param {boolean} [cropWhitespace] Optional. Specify if whitespace should be cropped out of the image. True/False. If unspecified, whitespace will be cropped from logos and clear art.
         * @param {ImageFormat} [format] Optional. The MediaBrowser.Model.Drawing.ImageFormat of the returned image.
         * @param {boolean} [addPlayedIndicator] Optional. Add a played indicator.
         * @param {number} [percentPlayed] Optional. Percent to render for the percent played overlay.
         * @param {number} [unplayedCount] Optional. Unplayed count overlay to render.
         * @param {number} [blur] Optional. Blur image.
         * @param {string} [backgroundColor] Optional. Apply a background color for transparent images.
         * @param {string} [foregroundLayer] Optional. Apply a foreground layer on top of the image.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getItemImage: async (itemId: string, imageType: ImageType, imageIndex: number, maxWidth?: number, maxHeight?: number, width?: number, height?: number, quality?: number, tag?: string, cropWhitespace?: boolean, format?: ImageFormat, addPlayedIndicator?: boolean, percentPlayed?: number, unplayedCount?: number, blur?: number, backgroundColor?: string, foregroundLayer?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'itemId' is not null or undefined
            if (itemId === null || itemId === undefined) {
                throw new RequiredError('itemId','Required parameter itemId was null or undefined when calling getItemImage.');
            }
            // verify required parameter 'imageType' is not null or undefined
            if (imageType === null || imageType === undefined) {
                throw new RequiredError('imageType','Required parameter imageType was null or undefined when calling getItemImage.');
            }
            // verify required parameter 'imageIndex' is not null or undefined
            if (imageIndex === null || imageIndex === undefined) {
                throw new RequiredError('imageIndex','Required parameter imageIndex was null or undefined when calling getItemImage.');
            }
            const localVarPath = `/Items/{itemId}/Images/{imageType}`
                .replace(`{${"itemId"}}`, encodeURIComponent(String(itemId)))
                .replace(`{${"imageType"}}`, encodeURIComponent(String(imageType)))
                .replace(`{${"imageIndex"}}`, encodeURIComponent(String(imageIndex)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }

            if (maxWidth !== undefined) {
                localVarQueryParameter['maxWidth'] = maxWidth;
            }

            if (maxHeight !== undefined) {
                localVarQueryParameter['maxHeight'] = maxHeight;
            }

            if (width !== undefined) {
                localVarQueryParameter['width'] = width;
            }

            if (height !== undefined) {
                localVarQueryParameter['height'] = height;
            }

            if (quality !== undefined) {
                localVarQueryParameter['quality'] = quality;
            }

            if (tag !== undefined) {
                localVarQueryParameter['tag'] = tag;
            }

            if (cropWhitespace !== undefined) {
                localVarQueryParameter['cropWhitespace'] = cropWhitespace;
            }

            if (format !== undefined) {
                localVarQueryParameter['format'] = format;
            }

            if (addPlayedIndicator !== undefined) {
                localVarQueryParameter['addPlayedIndicator'] = addPlayedIndicator;
            }

            if (percentPlayed !== undefined) {
                localVarQueryParameter['percentPlayed'] = percentPlayed;
            }

            if (unplayedCount !== undefined) {
                localVarQueryParameter['unplayedCount'] = unplayedCount;
            }

            if (blur !== undefined) {
                localVarQueryParameter['blur'] = blur;
            }

            if (backgroundColor !== undefined) {
                localVarQueryParameter['backgroundColor'] = backgroundColor;
            }

            if (foregroundLayer !== undefined) {
                localVarQueryParameter['foregroundLayer'] = foregroundLayer;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets the item\'s image.
         * @param {string} itemId Item id.
         * @param {ImageType} imageType Image type.
         * @param {number} imageIndex Image index.
         * @param {number} [maxWidth] The maximum image width to return.
         * @param {number} [maxHeight] The maximum image height to return.
         * @param {number} [width] The fixed image width to return.
         * @param {number} [height] The fixed image height to return.
         * @param {number} [quality] Optional. Quality setting, from 0-100. Defaults to 90 and should suffice in most cases.
         * @param {string} [tag] Optional. Supply the cache tag from the item object to receive strong caching headers.
         * @param {boolean} [cropWhitespace] Optional. Specify if whitespace should be cropped out of the image. True/False. If unspecified, whitespace will be cropped from logos and clear art.
         * @param {ImageFormat} [format] Optional. The MediaBrowser.Model.Drawing.ImageFormat of the returned image.
         * @param {boolean} [addPlayedIndicator] Optional. Add a played indicator.
         * @param {number} [percentPlayed] Optional. Percent to render for the percent played overlay.
         * @param {number} [unplayedCount] Optional. Unplayed count overlay to render.
         * @param {number} [blur] Optional. Blur image.
         * @param {string} [backgroundColor] Optional. Apply a background color for transparent images.
         * @param {string} [foregroundLayer] Optional. Apply a foreground layer on top of the image.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getItemImage2: async (itemId: string, imageType: ImageType, imageIndex: number, maxWidth?: number, maxHeight?: number, width?: number, height?: number, quality?: number, tag?: string, cropWhitespace?: boolean, format?: ImageFormat, addPlayedIndicator?: boolean, percentPlayed?: number, unplayedCount?: number, blur?: number, backgroundColor?: string, foregroundLayer?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'itemId' is not null or undefined
            if (itemId === null || itemId === undefined) {
                throw new RequiredError('itemId','Required parameter itemId was null or undefined when calling getItemImage2.');
            }
            // verify required parameter 'imageType' is not null or undefined
            if (imageType === null || imageType === undefined) {
                throw new RequiredError('imageType','Required parameter imageType was null or undefined when calling getItemImage2.');
            }
            // verify required parameter 'imageIndex' is not null or undefined
            if (imageIndex === null || imageIndex === undefined) {
                throw new RequiredError('imageIndex','Required parameter imageIndex was null or undefined when calling getItemImage2.');
            }
            const localVarPath = `/Items/{itemId}/Images/{imageType}/{imageIndex}`
                .replace(`{${"itemId"}}`, encodeURIComponent(String(itemId)))
                .replace(`{${"imageType"}}`, encodeURIComponent(String(imageType)))
                .replace(`{${"imageIndex"}}`, encodeURIComponent(String(imageIndex)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }

            if (maxWidth !== undefined) {
                localVarQueryParameter['maxWidth'] = maxWidth;
            }

            if (maxHeight !== undefined) {
                localVarQueryParameter['maxHeight'] = maxHeight;
            }

            if (width !== undefined) {
                localVarQueryParameter['width'] = width;
            }

            if (height !== undefined) {
                localVarQueryParameter['height'] = height;
            }

            if (quality !== undefined) {
                localVarQueryParameter['quality'] = quality;
            }

            if (tag !== undefined) {
                localVarQueryParameter['tag'] = tag;
            }

            if (cropWhitespace !== undefined) {
                localVarQueryParameter['cropWhitespace'] = cropWhitespace;
            }

            if (format !== undefined) {
                localVarQueryParameter['format'] = format;
            }

            if (addPlayedIndicator !== undefined) {
                localVarQueryParameter['addPlayedIndicator'] = addPlayedIndicator;
            }

            if (percentPlayed !== undefined) {
                localVarQueryParameter['percentPlayed'] = percentPlayed;
            }

            if (unplayedCount !== undefined) {
                localVarQueryParameter['unplayedCount'] = unplayedCount;
            }

            if (blur !== undefined) {
                localVarQueryParameter['blur'] = blur;
            }

            if (backgroundColor !== undefined) {
                localVarQueryParameter['backgroundColor'] = backgroundColor;
            }

            if (foregroundLayer !== undefined) {
                localVarQueryParameter['foregroundLayer'] = foregroundLayer;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets the item\'s image.
         * @param {string} itemId Item id.
         * @param {ImageType} imageType Image type.
         * @param {number} maxWidth The maximum image width to return.
         * @param {number} maxHeight The maximum image height to return.
         * @param {string} tag Optional. Supply the cache tag from the item object to receive strong caching headers.
         * @param {ImageFormat} format Determines the output format of the image - original,gif,jpg,png.
         * @param {number} percentPlayed Optional. Percent to render for the percent played overlay.
         * @param {number} unplayedCount Optional. Unplayed count overlay to render.
         * @param {number} imageIndex Image index.
         * @param {number} [width] The fixed image width to return.
         * @param {number} [height] The fixed image height to return.
         * @param {number} [quality] Optional. Quality setting, from 0-100. Defaults to 90 and should suffice in most cases.
         * @param {boolean} [cropWhitespace] Optional. Specify if whitespace should be cropped out of the image. True/False. If unspecified, whitespace will be cropped from logos and clear art.
         * @param {boolean} [addPlayedIndicator] Optional. Add a played indicator.
         * @param {number} [blur] Optional. Blur image.
         * @param {string} [backgroundColor] Optional. Apply a background color for transparent images.
         * @param {string} [foregroundLayer] Optional. Apply a foreground layer on top of the image.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getItemImage2_1: async (itemId: string, imageType: ImageType, maxWidth: number, maxHeight: number, tag: string, format: ImageFormat, percentPlayed: number, unplayedCount: number, imageIndex: number, width?: number, height?: number, quality?: number, cropWhitespace?: boolean, addPlayedIndicator?: boolean, blur?: number, backgroundColor?: string, foregroundLayer?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'itemId' is not null or undefined
            if (itemId === null || itemId === undefined) {
                throw new RequiredError('itemId','Required parameter itemId was null or undefined when calling getItemImage2_1.');
            }
            // verify required parameter 'imageType' is not null or undefined
            if (imageType === null || imageType === undefined) {
                throw new RequiredError('imageType','Required parameter imageType was null or undefined when calling getItemImage2_1.');
            }
            // verify required parameter 'maxWidth' is not null or undefined
            if (maxWidth === null || maxWidth === undefined) {
                throw new RequiredError('maxWidth','Required parameter maxWidth was null or undefined when calling getItemImage2_1.');
            }
            // verify required parameter 'maxHeight' is not null or undefined
            if (maxHeight === null || maxHeight === undefined) {
                throw new RequiredError('maxHeight','Required parameter maxHeight was null or undefined when calling getItemImage2_1.');
            }
            // verify required parameter 'tag' is not null or undefined
            if (tag === null || tag === undefined) {
                throw new RequiredError('tag','Required parameter tag was null or undefined when calling getItemImage2_1.');
            }
            // verify required parameter 'format' is not null or undefined
            if (format === null || format === undefined) {
                throw new RequiredError('format','Required parameter format was null or undefined when calling getItemImage2_1.');
            }
            // verify required parameter 'percentPlayed' is not null or undefined
            if (percentPlayed === null || percentPlayed === undefined) {
                throw new RequiredError('percentPlayed','Required parameter percentPlayed was null or undefined when calling getItemImage2_1.');
            }
            // verify required parameter 'unplayedCount' is not null or undefined
            if (unplayedCount === null || unplayedCount === undefined) {
                throw new RequiredError('unplayedCount','Required parameter unplayedCount was null or undefined when calling getItemImage2_1.');
            }
            // verify required parameter 'imageIndex' is not null or undefined
            if (imageIndex === null || imageIndex === undefined) {
                throw new RequiredError('imageIndex','Required parameter imageIndex was null or undefined when calling getItemImage2_1.');
            }
            const localVarPath = `/Items/{itemId}/Images/{imageType}/{imageIndex}/{tag}/{format}/{maxWidth}/{maxHeight}/{percentPlayed}/{unplayedCount}`
                .replace(`{${"itemId"}}`, encodeURIComponent(String(itemId)))
                .replace(`{${"imageType"}}`, encodeURIComponent(String(imageType)))
                .replace(`{${"maxWidth"}}`, encodeURIComponent(String(maxWidth)))
                .replace(`{${"maxHeight"}}`, encodeURIComponent(String(maxHeight)))
                .replace(`{${"tag"}}`, encodeURIComponent(String(tag)))
                .replace(`{${"format"}}`, encodeURIComponent(String(format)))
                .replace(`{${"percentPlayed"}}`, encodeURIComponent(String(percentPlayed)))
                .replace(`{${"unplayedCount"}}`, encodeURIComponent(String(unplayedCount)))
                .replace(`{${"imageIndex"}}`, encodeURIComponent(String(imageIndex)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }

            if (width !== undefined) {
                localVarQueryParameter['width'] = width;
            }

            if (height !== undefined) {
                localVarQueryParameter['height'] = height;
            }

            if (quality !== undefined) {
                localVarQueryParameter['quality'] = quality;
            }

            if (cropWhitespace !== undefined) {
                localVarQueryParameter['cropWhitespace'] = cropWhitespace;
            }

            if (addPlayedIndicator !== undefined) {
                localVarQueryParameter['addPlayedIndicator'] = addPlayedIndicator;
            }

            if (blur !== undefined) {
                localVarQueryParameter['blur'] = blur;
            }

            if (backgroundColor !== undefined) {
                localVarQueryParameter['backgroundColor'] = backgroundColor;
            }

            if (foregroundLayer !== undefined) {
                localVarQueryParameter['foregroundLayer'] = foregroundLayer;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get item image infos.
         * @param {string} itemId Item id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getItemImageInfos: async (itemId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'itemId' is not null or undefined
            if (itemId === null || itemId === undefined) {
                throw new RequiredError('itemId','Required parameter itemId was null or undefined when calling getItemImageInfos.');
            }
            const localVarPath = `/Items/{itemId}/Images`
                .replace(`{${"itemId"}}`, encodeURIComponent(String(itemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get music genre image by name.
         * @param {string} name Music genre name.
         * @param {ImageType} imageType Image type.
         * @param {number} imageIndex Image index.
         * @param {string} [tag] Optional. Supply the cache tag from the item object to receive strong caching headers.
         * @param {ImageFormat} [format] Determines the output format of the image - original,gif,jpg,png.
         * @param {number} [maxWidth] The maximum image width to return.
         * @param {number} [maxHeight] The maximum image height to return.
         * @param {number} [percentPlayed] Optional. Percent to render for the percent played overlay.
         * @param {number} [unplayedCount] Optional. Unplayed count overlay to render.
         * @param {number} [width] The fixed image width to return.
         * @param {number} [height] The fixed image height to return.
         * @param {number} [quality] Optional. Quality setting, from 0-100. Defaults to 90 and should suffice in most cases.
         * @param {boolean} [cropWhitespace] Optional. Specify if whitespace should be cropped out of the image. True/False. If unspecified, whitespace will be cropped from logos and clear art.
         * @param {boolean} [addPlayedIndicator] Optional. Add a played indicator.
         * @param {number} [blur] Optional. Blur image.
         * @param {string} [backgroundColor] Optional. Apply a background color for transparent images.
         * @param {string} [foregroundLayer] Optional. Apply a foreground layer on top of the image.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMusicGenreImage: async (name: string, imageType: ImageType, imageIndex: number, tag?: string, format?: ImageFormat, maxWidth?: number, maxHeight?: number, percentPlayed?: number, unplayedCount?: number, width?: number, height?: number, quality?: number, cropWhitespace?: boolean, addPlayedIndicator?: boolean, blur?: number, backgroundColor?: string, foregroundLayer?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            if (name === null || name === undefined) {
                throw new RequiredError('name','Required parameter name was null or undefined when calling getMusicGenreImage.');
            }
            // verify required parameter 'imageType' is not null or undefined
            if (imageType === null || imageType === undefined) {
                throw new RequiredError('imageType','Required parameter imageType was null or undefined when calling getMusicGenreImage.');
            }
            // verify required parameter 'imageIndex' is not null or undefined
            if (imageIndex === null || imageIndex === undefined) {
                throw new RequiredError('imageIndex','Required parameter imageIndex was null or undefined when calling getMusicGenreImage.');
            }
            const localVarPath = `/MusicGenres/{name}/Images/{imageType}/{imageIndex}`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)))
                .replace(`{${"imageType"}}`, encodeURIComponent(String(imageType)))
                .replace(`{${"imageIndex"}}`, encodeURIComponent(String(imageIndex)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }

            if (tag !== undefined) {
                localVarQueryParameter['tag'] = tag;
            }

            if (format !== undefined) {
                localVarQueryParameter['format'] = format;
            }

            if (maxWidth !== undefined) {
                localVarQueryParameter['maxWidth'] = maxWidth;
            }

            if (maxHeight !== undefined) {
                localVarQueryParameter['maxHeight'] = maxHeight;
            }

            if (percentPlayed !== undefined) {
                localVarQueryParameter['percentPlayed'] = percentPlayed;
            }

            if (unplayedCount !== undefined) {
                localVarQueryParameter['unplayedCount'] = unplayedCount;
            }

            if (width !== undefined) {
                localVarQueryParameter['width'] = width;
            }

            if (height !== undefined) {
                localVarQueryParameter['height'] = height;
            }

            if (quality !== undefined) {
                localVarQueryParameter['quality'] = quality;
            }

            if (cropWhitespace !== undefined) {
                localVarQueryParameter['cropWhitespace'] = cropWhitespace;
            }

            if (addPlayedIndicator !== undefined) {
                localVarQueryParameter['addPlayedIndicator'] = addPlayedIndicator;
            }

            if (blur !== undefined) {
                localVarQueryParameter['blur'] = blur;
            }

            if (backgroundColor !== undefined) {
                localVarQueryParameter['backgroundColor'] = backgroundColor;
            }

            if (foregroundLayer !== undefined) {
                localVarQueryParameter['foregroundLayer'] = foregroundLayer;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get person image by name.
         * @param {string} name Person name.
         * @param {ImageType} imageType Image type.
         * @param {number} imageIndex Image index.
         * @param {string} [tag] Optional. Supply the cache tag from the item object to receive strong caching headers.
         * @param {ImageFormat} [format] Determines the output format of the image - original,gif,jpg,png.
         * @param {number} [maxWidth] The maximum image width to return.
         * @param {number} [maxHeight] The maximum image height to return.
         * @param {number} [percentPlayed] Optional. Percent to render for the percent played overlay.
         * @param {number} [unplayedCount] Optional. Unplayed count overlay to render.
         * @param {number} [width] The fixed image width to return.
         * @param {number} [height] The fixed image height to return.
         * @param {number} [quality] Optional. Quality setting, from 0-100. Defaults to 90 and should suffice in most cases.
         * @param {boolean} [cropWhitespace] Optional. Specify if whitespace should be cropped out of the image. True/False. If unspecified, whitespace will be cropped from logos and clear art.
         * @param {boolean} [addPlayedIndicator] Optional. Add a played indicator.
         * @param {number} [blur] Optional. Blur image.
         * @param {string} [backgroundColor] Optional. Apply a background color for transparent images.
         * @param {string} [foregroundLayer] Optional. Apply a foreground layer on top of the image.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPersonImage: async (name: string, imageType: ImageType, imageIndex: number, tag?: string, format?: ImageFormat, maxWidth?: number, maxHeight?: number, percentPlayed?: number, unplayedCount?: number, width?: number, height?: number, quality?: number, cropWhitespace?: boolean, addPlayedIndicator?: boolean, blur?: number, backgroundColor?: string, foregroundLayer?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            if (name === null || name === undefined) {
                throw new RequiredError('name','Required parameter name was null or undefined when calling getPersonImage.');
            }
            // verify required parameter 'imageType' is not null or undefined
            if (imageType === null || imageType === undefined) {
                throw new RequiredError('imageType','Required parameter imageType was null or undefined when calling getPersonImage.');
            }
            // verify required parameter 'imageIndex' is not null or undefined
            if (imageIndex === null || imageIndex === undefined) {
                throw new RequiredError('imageIndex','Required parameter imageIndex was null or undefined when calling getPersonImage.');
            }
            const localVarPath = `/Persons/{name}/Images/{imageType}/{imageIndex}`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)))
                .replace(`{${"imageType"}}`, encodeURIComponent(String(imageType)))
                .replace(`{${"imageIndex"}}`, encodeURIComponent(String(imageIndex)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }

            if (tag !== undefined) {
                localVarQueryParameter['tag'] = tag;
            }

            if (format !== undefined) {
                localVarQueryParameter['format'] = format;
            }

            if (maxWidth !== undefined) {
                localVarQueryParameter['maxWidth'] = maxWidth;
            }

            if (maxHeight !== undefined) {
                localVarQueryParameter['maxHeight'] = maxHeight;
            }

            if (percentPlayed !== undefined) {
                localVarQueryParameter['percentPlayed'] = percentPlayed;
            }

            if (unplayedCount !== undefined) {
                localVarQueryParameter['unplayedCount'] = unplayedCount;
            }

            if (width !== undefined) {
                localVarQueryParameter['width'] = width;
            }

            if (height !== undefined) {
                localVarQueryParameter['height'] = height;
            }

            if (quality !== undefined) {
                localVarQueryParameter['quality'] = quality;
            }

            if (cropWhitespace !== undefined) {
                localVarQueryParameter['cropWhitespace'] = cropWhitespace;
            }

            if (addPlayedIndicator !== undefined) {
                localVarQueryParameter['addPlayedIndicator'] = addPlayedIndicator;
            }

            if (blur !== undefined) {
                localVarQueryParameter['blur'] = blur;
            }

            if (backgroundColor !== undefined) {
                localVarQueryParameter['backgroundColor'] = backgroundColor;
            }

            if (foregroundLayer !== undefined) {
                localVarQueryParameter['foregroundLayer'] = foregroundLayer;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get studio image by name.
         * @param {string} name Studio name.
         * @param {ImageType} imageType Image type.
         * @param {string} tag Optional. Supply the cache tag from the item object to receive strong caching headers.
         * @param {ImageFormat} format Determines the output format of the image - original,gif,jpg,png.
         * @param {number} imageIndex Image index.
         * @param {number} [maxWidth] The maximum image width to return.
         * @param {number} [maxHeight] The maximum image height to return.
         * @param {number} [percentPlayed] Optional. Percent to render for the percent played overlay.
         * @param {number} [unplayedCount] Optional. Unplayed count overlay to render.
         * @param {number} [width] The fixed image width to return.
         * @param {number} [height] The fixed image height to return.
         * @param {number} [quality] Optional. Quality setting, from 0-100. Defaults to 90 and should suffice in most cases.
         * @param {boolean} [cropWhitespace] Optional. Specify if whitespace should be cropped out of the image. True/False. If unspecified, whitespace will be cropped from logos and clear art.
         * @param {boolean} [addPlayedIndicator] Optional. Add a played indicator.
         * @param {number} [blur] Optional. Blur image.
         * @param {string} [backgroundColor] Optional. Apply a background color for transparent images.
         * @param {string} [foregroundLayer] Optional. Apply a foreground layer on top of the image.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStudioImage: async (name: string, imageType: ImageType, tag: string, format: ImageFormat, imageIndex: number, maxWidth?: number, maxHeight?: number, percentPlayed?: number, unplayedCount?: number, width?: number, height?: number, quality?: number, cropWhitespace?: boolean, addPlayedIndicator?: boolean, blur?: number, backgroundColor?: string, foregroundLayer?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            if (name === null || name === undefined) {
                throw new RequiredError('name','Required parameter name was null or undefined when calling getStudioImage.');
            }
            // verify required parameter 'imageType' is not null or undefined
            if (imageType === null || imageType === undefined) {
                throw new RequiredError('imageType','Required parameter imageType was null or undefined when calling getStudioImage.');
            }
            // verify required parameter 'tag' is not null or undefined
            if (tag === null || tag === undefined) {
                throw new RequiredError('tag','Required parameter tag was null or undefined when calling getStudioImage.');
            }
            // verify required parameter 'format' is not null or undefined
            if (format === null || format === undefined) {
                throw new RequiredError('format','Required parameter format was null or undefined when calling getStudioImage.');
            }
            // verify required parameter 'imageIndex' is not null or undefined
            if (imageIndex === null || imageIndex === undefined) {
                throw new RequiredError('imageIndex','Required parameter imageIndex was null or undefined when calling getStudioImage.');
            }
            const localVarPath = `/Studios/{name}/Images/{imageType}/{imageIndex}`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)))
                .replace(`{${"imageType"}}`, encodeURIComponent(String(imageType)))
                .replace(`{${"tag"}}`, encodeURIComponent(String(tag)))
                .replace(`{${"format"}}`, encodeURIComponent(String(format)))
                .replace(`{${"imageIndex"}}`, encodeURIComponent(String(imageIndex)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }

            if (maxWidth !== undefined) {
                localVarQueryParameter['maxWidth'] = maxWidth;
            }

            if (maxHeight !== undefined) {
                localVarQueryParameter['maxHeight'] = maxHeight;
            }

            if (percentPlayed !== undefined) {
                localVarQueryParameter['percentPlayed'] = percentPlayed;
            }

            if (unplayedCount !== undefined) {
                localVarQueryParameter['unplayedCount'] = unplayedCount;
            }

            if (width !== undefined) {
                localVarQueryParameter['width'] = width;
            }

            if (height !== undefined) {
                localVarQueryParameter['height'] = height;
            }

            if (quality !== undefined) {
                localVarQueryParameter['quality'] = quality;
            }

            if (cropWhitespace !== undefined) {
                localVarQueryParameter['cropWhitespace'] = cropWhitespace;
            }

            if (addPlayedIndicator !== undefined) {
                localVarQueryParameter['addPlayedIndicator'] = addPlayedIndicator;
            }

            if (blur !== undefined) {
                localVarQueryParameter['blur'] = blur;
            }

            if (backgroundColor !== undefined) {
                localVarQueryParameter['backgroundColor'] = backgroundColor;
            }

            if (foregroundLayer !== undefined) {
                localVarQueryParameter['foregroundLayer'] = foregroundLayer;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get user profile image.
         * @param {string} userId User id.
         * @param {ImageType} imageType Image type.
         * @param {number} imageIndex Image index.
         * @param {string} [tag] Optional. Supply the cache tag from the item object to receive strong caching headers.
         * @param {ImageFormat} [format] Determines the output format of the image - original,gif,jpg,png.
         * @param {number} [maxWidth] The maximum image width to return.
         * @param {number} [maxHeight] The maximum image height to return.
         * @param {number} [percentPlayed] Optional. Percent to render for the percent played overlay.
         * @param {number} [unplayedCount] Optional. Unplayed count overlay to render.
         * @param {number} [width] The fixed image width to return.
         * @param {number} [height] The fixed image height to return.
         * @param {number} [quality] Optional. Quality setting, from 0-100. Defaults to 90 and should suffice in most cases.
         * @param {boolean} [cropWhitespace] Optional. Specify if whitespace should be cropped out of the image. True/False. If unspecified, whitespace will be cropped from logos and clear art.
         * @param {boolean} [addPlayedIndicator] Optional. Add a played indicator.
         * @param {number} [blur] Optional. Blur image.
         * @param {string} [backgroundColor] Optional. Apply a background color for transparent images.
         * @param {string} [foregroundLayer] Optional. Apply a foreground layer on top of the image.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserImage: async (userId: string, imageType: ImageType, imageIndex: number, tag?: string, format?: ImageFormat, maxWidth?: number, maxHeight?: number, percentPlayed?: number, unplayedCount?: number, width?: number, height?: number, quality?: number, cropWhitespace?: boolean, addPlayedIndicator?: boolean, blur?: number, backgroundColor?: string, foregroundLayer?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId','Required parameter userId was null or undefined when calling getUserImage.');
            }
            // verify required parameter 'imageType' is not null or undefined
            if (imageType === null || imageType === undefined) {
                throw new RequiredError('imageType','Required parameter imageType was null or undefined when calling getUserImage.');
            }
            // verify required parameter 'imageIndex' is not null or undefined
            if (imageIndex === null || imageIndex === undefined) {
                throw new RequiredError('imageIndex','Required parameter imageIndex was null or undefined when calling getUserImage.');
            }
            const localVarPath = `/Users/{userId}/Images/{imageType}/{imageIndex}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"imageType"}}`, encodeURIComponent(String(imageType)))
                .replace(`{${"imageIndex"}}`, encodeURIComponent(String(imageIndex)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }

            if (tag !== undefined) {
                localVarQueryParameter['tag'] = tag;
            }

            if (format !== undefined) {
                localVarQueryParameter['format'] = format;
            }

            if (maxWidth !== undefined) {
                localVarQueryParameter['maxWidth'] = maxWidth;
            }

            if (maxHeight !== undefined) {
                localVarQueryParameter['maxHeight'] = maxHeight;
            }

            if (percentPlayed !== undefined) {
                localVarQueryParameter['percentPlayed'] = percentPlayed;
            }

            if (unplayedCount !== undefined) {
                localVarQueryParameter['unplayedCount'] = unplayedCount;
            }

            if (width !== undefined) {
                localVarQueryParameter['width'] = width;
            }

            if (height !== undefined) {
                localVarQueryParameter['height'] = height;
            }

            if (quality !== undefined) {
                localVarQueryParameter['quality'] = quality;
            }

            if (cropWhitespace !== undefined) {
                localVarQueryParameter['cropWhitespace'] = cropWhitespace;
            }

            if (addPlayedIndicator !== undefined) {
                localVarQueryParameter['addPlayedIndicator'] = addPlayedIndicator;
            }

            if (blur !== undefined) {
                localVarQueryParameter['blur'] = blur;
            }

            if (backgroundColor !== undefined) {
                localVarQueryParameter['backgroundColor'] = backgroundColor;
            }

            if (foregroundLayer !== undefined) {
                localVarQueryParameter['foregroundLayer'] = foregroundLayer;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get artist image by name.
         * @param {string} name Artist name.
         * @param {ImageType} imageType Image type.
         * @param {number} imageIndex Image index.
         * @param {string} [tag] Optional. Supply the cache tag from the item object to receive strong caching headers.
         * @param {ImageFormat} [format] Determines the output format of the image - original,gif,jpg,png.
         * @param {number} [maxWidth] The maximum image width to return.
         * @param {number} [maxHeight] The maximum image height to return.
         * @param {number} [percentPlayed] Optional. Percent to render for the percent played overlay.
         * @param {number} [unplayedCount] Optional. Unplayed count overlay to render.
         * @param {number} [width] The fixed image width to return.
         * @param {number} [height] The fixed image height to return.
         * @param {number} [quality] Optional. Quality setting, from 0-100. Defaults to 90 and should suffice in most cases.
         * @param {boolean} [cropWhitespace] Optional. Specify if whitespace should be cropped out of the image. True/False. If unspecified, whitespace will be cropped from logos and clear art.
         * @param {boolean} [addPlayedIndicator] Optional. Add a played indicator.
         * @param {number} [blur] Optional. Blur image.
         * @param {string} [backgroundColor] Optional. Apply a background color for transparent images.
         * @param {string} [foregroundLayer] Optional. Apply a foreground layer on top of the image.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        headArtistImage: async (name: string, imageType: ImageType, imageIndex: number, tag?: string, format?: ImageFormat, maxWidth?: number, maxHeight?: number, percentPlayed?: number, unplayedCount?: number, width?: number, height?: number, quality?: number, cropWhitespace?: boolean, addPlayedIndicator?: boolean, blur?: number, backgroundColor?: string, foregroundLayer?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            if (name === null || name === undefined) {
                throw new RequiredError('name','Required parameter name was null or undefined when calling headArtistImage.');
            }
            // verify required parameter 'imageType' is not null or undefined
            if (imageType === null || imageType === undefined) {
                throw new RequiredError('imageType','Required parameter imageType was null or undefined when calling headArtistImage.');
            }
            // verify required parameter 'imageIndex' is not null or undefined
            if (imageIndex === null || imageIndex === undefined) {
                throw new RequiredError('imageIndex','Required parameter imageIndex was null or undefined when calling headArtistImage.');
            }
            const localVarPath = `/Artists/{name}/Images/{imageType}/{imageIndex}`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)))
                .replace(`{${"imageType"}}`, encodeURIComponent(String(imageType)))
                .replace(`{${"imageIndex"}}`, encodeURIComponent(String(imageIndex)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'HEAD', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }

            if (tag !== undefined) {
                localVarQueryParameter['tag'] = tag;
            }

            if (format !== undefined) {
                localVarQueryParameter['format'] = format;
            }

            if (maxWidth !== undefined) {
                localVarQueryParameter['maxWidth'] = maxWidth;
            }

            if (maxHeight !== undefined) {
                localVarQueryParameter['maxHeight'] = maxHeight;
            }

            if (percentPlayed !== undefined) {
                localVarQueryParameter['percentPlayed'] = percentPlayed;
            }

            if (unplayedCount !== undefined) {
                localVarQueryParameter['unplayedCount'] = unplayedCount;
            }

            if (width !== undefined) {
                localVarQueryParameter['width'] = width;
            }

            if (height !== undefined) {
                localVarQueryParameter['height'] = height;
            }

            if (quality !== undefined) {
                localVarQueryParameter['quality'] = quality;
            }

            if (cropWhitespace !== undefined) {
                localVarQueryParameter['cropWhitespace'] = cropWhitespace;
            }

            if (addPlayedIndicator !== undefined) {
                localVarQueryParameter['addPlayedIndicator'] = addPlayedIndicator;
            }

            if (blur !== undefined) {
                localVarQueryParameter['blur'] = blur;
            }

            if (backgroundColor !== undefined) {
                localVarQueryParameter['backgroundColor'] = backgroundColor;
            }

            if (foregroundLayer !== undefined) {
                localVarQueryParameter['foregroundLayer'] = foregroundLayer;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get genre image by name.
         * @param {string} name Genre name.
         * @param {ImageType} imageType Image type.
         * @param {number} imageIndex Image index.
         * @param {string} [tag] Optional. Supply the cache tag from the item object to receive strong caching headers.
         * @param {ImageFormat} [format] Determines the output format of the image - original,gif,jpg,png.
         * @param {number} [maxWidth] The maximum image width to return.
         * @param {number} [maxHeight] The maximum image height to return.
         * @param {number} [percentPlayed] Optional. Percent to render for the percent played overlay.
         * @param {number} [unplayedCount] Optional. Unplayed count overlay to render.
         * @param {number} [width] The fixed image width to return.
         * @param {number} [height] The fixed image height to return.
         * @param {number} [quality] Optional. Quality setting, from 0-100. Defaults to 90 and should suffice in most cases.
         * @param {boolean} [cropWhitespace] Optional. Specify if whitespace should be cropped out of the image. True/False. If unspecified, whitespace will be cropped from logos and clear art.
         * @param {boolean} [addPlayedIndicator] Optional. Add a played indicator.
         * @param {number} [blur] Optional. Blur image.
         * @param {string} [backgroundColor] Optional. Apply a background color for transparent images.
         * @param {string} [foregroundLayer] Optional. Apply a foreground layer on top of the image.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        headGenreImage: async (name: string, imageType: ImageType, imageIndex: number, tag?: string, format?: ImageFormat, maxWidth?: number, maxHeight?: number, percentPlayed?: number, unplayedCount?: number, width?: number, height?: number, quality?: number, cropWhitespace?: boolean, addPlayedIndicator?: boolean, blur?: number, backgroundColor?: string, foregroundLayer?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            if (name === null || name === undefined) {
                throw new RequiredError('name','Required parameter name was null or undefined when calling headGenreImage.');
            }
            // verify required parameter 'imageType' is not null or undefined
            if (imageType === null || imageType === undefined) {
                throw new RequiredError('imageType','Required parameter imageType was null or undefined when calling headGenreImage.');
            }
            // verify required parameter 'imageIndex' is not null or undefined
            if (imageIndex === null || imageIndex === undefined) {
                throw new RequiredError('imageIndex','Required parameter imageIndex was null or undefined when calling headGenreImage.');
            }
            const localVarPath = `/Genres/{name}/Images/{imageType}/{imageIndex}`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)))
                .replace(`{${"imageType"}}`, encodeURIComponent(String(imageType)))
                .replace(`{${"imageIndex"}}`, encodeURIComponent(String(imageIndex)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'HEAD', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }

            if (tag !== undefined) {
                localVarQueryParameter['tag'] = tag;
            }

            if (format !== undefined) {
                localVarQueryParameter['format'] = format;
            }

            if (maxWidth !== undefined) {
                localVarQueryParameter['maxWidth'] = maxWidth;
            }

            if (maxHeight !== undefined) {
                localVarQueryParameter['maxHeight'] = maxHeight;
            }

            if (percentPlayed !== undefined) {
                localVarQueryParameter['percentPlayed'] = percentPlayed;
            }

            if (unplayedCount !== undefined) {
                localVarQueryParameter['unplayedCount'] = unplayedCount;
            }

            if (width !== undefined) {
                localVarQueryParameter['width'] = width;
            }

            if (height !== undefined) {
                localVarQueryParameter['height'] = height;
            }

            if (quality !== undefined) {
                localVarQueryParameter['quality'] = quality;
            }

            if (cropWhitespace !== undefined) {
                localVarQueryParameter['cropWhitespace'] = cropWhitespace;
            }

            if (addPlayedIndicator !== undefined) {
                localVarQueryParameter['addPlayedIndicator'] = addPlayedIndicator;
            }

            if (blur !== undefined) {
                localVarQueryParameter['blur'] = blur;
            }

            if (backgroundColor !== undefined) {
                localVarQueryParameter['backgroundColor'] = backgroundColor;
            }

            if (foregroundLayer !== undefined) {
                localVarQueryParameter['foregroundLayer'] = foregroundLayer;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets the item\'s image.
         * @param {string} itemId Item id.
         * @param {ImageType} imageType Image type.
         * @param {number} imageIndex Image index.
         * @param {number} [maxWidth] The maximum image width to return.
         * @param {number} [maxHeight] The maximum image height to return.
         * @param {number} [width] The fixed image width to return.
         * @param {number} [height] The fixed image height to return.
         * @param {number} [quality] Optional. Quality setting, from 0-100. Defaults to 90 and should suffice in most cases.
         * @param {string} [tag] Optional. Supply the cache tag from the item object to receive strong caching headers.
         * @param {boolean} [cropWhitespace] Optional. Specify if whitespace should be cropped out of the image. True/False. If unspecified, whitespace will be cropped from logos and clear art.
         * @param {ImageFormat} [format] Optional. The MediaBrowser.Model.Drawing.ImageFormat of the returned image.
         * @param {boolean} [addPlayedIndicator] Optional. Add a played indicator.
         * @param {number} [percentPlayed] Optional. Percent to render for the percent played overlay.
         * @param {number} [unplayedCount] Optional. Unplayed count overlay to render.
         * @param {number} [blur] Optional. Blur image.
         * @param {string} [backgroundColor] Optional. Apply a background color for transparent images.
         * @param {string} [foregroundLayer] Optional. Apply a foreground layer on top of the image.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        headItemImage: async (itemId: string, imageType: ImageType, imageIndex: number, maxWidth?: number, maxHeight?: number, width?: number, height?: number, quality?: number, tag?: string, cropWhitespace?: boolean, format?: ImageFormat, addPlayedIndicator?: boolean, percentPlayed?: number, unplayedCount?: number, blur?: number, backgroundColor?: string, foregroundLayer?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'itemId' is not null or undefined
            if (itemId === null || itemId === undefined) {
                throw new RequiredError('itemId','Required parameter itemId was null or undefined when calling headItemImage.');
            }
            // verify required parameter 'imageType' is not null or undefined
            if (imageType === null || imageType === undefined) {
                throw new RequiredError('imageType','Required parameter imageType was null or undefined when calling headItemImage.');
            }
            // verify required parameter 'imageIndex' is not null or undefined
            if (imageIndex === null || imageIndex === undefined) {
                throw new RequiredError('imageIndex','Required parameter imageIndex was null or undefined when calling headItemImage.');
            }
            const localVarPath = `/Items/{itemId}/Images/{imageType}`
                .replace(`{${"itemId"}}`, encodeURIComponent(String(itemId)))
                .replace(`{${"imageType"}}`, encodeURIComponent(String(imageType)))
                .replace(`{${"imageIndex"}}`, encodeURIComponent(String(imageIndex)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'HEAD', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }

            if (maxWidth !== undefined) {
                localVarQueryParameter['maxWidth'] = maxWidth;
            }

            if (maxHeight !== undefined) {
                localVarQueryParameter['maxHeight'] = maxHeight;
            }

            if (width !== undefined) {
                localVarQueryParameter['width'] = width;
            }

            if (height !== undefined) {
                localVarQueryParameter['height'] = height;
            }

            if (quality !== undefined) {
                localVarQueryParameter['quality'] = quality;
            }

            if (tag !== undefined) {
                localVarQueryParameter['tag'] = tag;
            }

            if (cropWhitespace !== undefined) {
                localVarQueryParameter['cropWhitespace'] = cropWhitespace;
            }

            if (format !== undefined) {
                localVarQueryParameter['format'] = format;
            }

            if (addPlayedIndicator !== undefined) {
                localVarQueryParameter['addPlayedIndicator'] = addPlayedIndicator;
            }

            if (percentPlayed !== undefined) {
                localVarQueryParameter['percentPlayed'] = percentPlayed;
            }

            if (unplayedCount !== undefined) {
                localVarQueryParameter['unplayedCount'] = unplayedCount;
            }

            if (blur !== undefined) {
                localVarQueryParameter['blur'] = blur;
            }

            if (backgroundColor !== undefined) {
                localVarQueryParameter['backgroundColor'] = backgroundColor;
            }

            if (foregroundLayer !== undefined) {
                localVarQueryParameter['foregroundLayer'] = foregroundLayer;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets the item\'s image.
         * @param {string} itemId Item id.
         * @param {ImageType} imageType Image type.
         * @param {number} imageIndex Image index.
         * @param {number} [maxWidth] The maximum image width to return.
         * @param {number} [maxHeight] The maximum image height to return.
         * @param {number} [width] The fixed image width to return.
         * @param {number} [height] The fixed image height to return.
         * @param {number} [quality] Optional. Quality setting, from 0-100. Defaults to 90 and should suffice in most cases.
         * @param {string} [tag] Optional. Supply the cache tag from the item object to receive strong caching headers.
         * @param {boolean} [cropWhitespace] Optional. Specify if whitespace should be cropped out of the image. True/False. If unspecified, whitespace will be cropped from logos and clear art.
         * @param {ImageFormat} [format] Optional. The MediaBrowser.Model.Drawing.ImageFormat of the returned image.
         * @param {boolean} [addPlayedIndicator] Optional. Add a played indicator.
         * @param {number} [percentPlayed] Optional. Percent to render for the percent played overlay.
         * @param {number} [unplayedCount] Optional. Unplayed count overlay to render.
         * @param {number} [blur] Optional. Blur image.
         * @param {string} [backgroundColor] Optional. Apply a background color for transparent images.
         * @param {string} [foregroundLayer] Optional. Apply a foreground layer on top of the image.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        headItemImage2: async (itemId: string, imageType: ImageType, imageIndex: number, maxWidth?: number, maxHeight?: number, width?: number, height?: number, quality?: number, tag?: string, cropWhitespace?: boolean, format?: ImageFormat, addPlayedIndicator?: boolean, percentPlayed?: number, unplayedCount?: number, blur?: number, backgroundColor?: string, foregroundLayer?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'itemId' is not null or undefined
            if (itemId === null || itemId === undefined) {
                throw new RequiredError('itemId','Required parameter itemId was null or undefined when calling headItemImage2.');
            }
            // verify required parameter 'imageType' is not null or undefined
            if (imageType === null || imageType === undefined) {
                throw new RequiredError('imageType','Required parameter imageType was null or undefined when calling headItemImage2.');
            }
            // verify required parameter 'imageIndex' is not null or undefined
            if (imageIndex === null || imageIndex === undefined) {
                throw new RequiredError('imageIndex','Required parameter imageIndex was null or undefined when calling headItemImage2.');
            }
            const localVarPath = `/Items/{itemId}/Images/{imageType}/{imageIndex}`
                .replace(`{${"itemId"}}`, encodeURIComponent(String(itemId)))
                .replace(`{${"imageType"}}`, encodeURIComponent(String(imageType)))
                .replace(`{${"imageIndex"}}`, encodeURIComponent(String(imageIndex)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'HEAD', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }

            if (maxWidth !== undefined) {
                localVarQueryParameter['maxWidth'] = maxWidth;
            }

            if (maxHeight !== undefined) {
                localVarQueryParameter['maxHeight'] = maxHeight;
            }

            if (width !== undefined) {
                localVarQueryParameter['width'] = width;
            }

            if (height !== undefined) {
                localVarQueryParameter['height'] = height;
            }

            if (quality !== undefined) {
                localVarQueryParameter['quality'] = quality;
            }

            if (tag !== undefined) {
                localVarQueryParameter['tag'] = tag;
            }

            if (cropWhitespace !== undefined) {
                localVarQueryParameter['cropWhitespace'] = cropWhitespace;
            }

            if (format !== undefined) {
                localVarQueryParameter['format'] = format;
            }

            if (addPlayedIndicator !== undefined) {
                localVarQueryParameter['addPlayedIndicator'] = addPlayedIndicator;
            }

            if (percentPlayed !== undefined) {
                localVarQueryParameter['percentPlayed'] = percentPlayed;
            }

            if (unplayedCount !== undefined) {
                localVarQueryParameter['unplayedCount'] = unplayedCount;
            }

            if (blur !== undefined) {
                localVarQueryParameter['blur'] = blur;
            }

            if (backgroundColor !== undefined) {
                localVarQueryParameter['backgroundColor'] = backgroundColor;
            }

            if (foregroundLayer !== undefined) {
                localVarQueryParameter['foregroundLayer'] = foregroundLayer;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets the item\'s image.
         * @param {string} itemId Item id.
         * @param {ImageType} imageType Image type.
         * @param {number} maxWidth The maximum image width to return.
         * @param {number} maxHeight The maximum image height to return.
         * @param {string} tag Optional. Supply the cache tag from the item object to receive strong caching headers.
         * @param {ImageFormat} format Determines the output format of the image - original,gif,jpg,png.
         * @param {number} percentPlayed Optional. Percent to render for the percent played overlay.
         * @param {number} unplayedCount Optional. Unplayed count overlay to render.
         * @param {number} imageIndex Image index.
         * @param {number} [width] The fixed image width to return.
         * @param {number} [height] The fixed image height to return.
         * @param {number} [quality] Optional. Quality setting, from 0-100. Defaults to 90 and should suffice in most cases.
         * @param {boolean} [cropWhitespace] Optional. Specify if whitespace should be cropped out of the image. True/False. If unspecified, whitespace will be cropped from logos and clear art.
         * @param {boolean} [addPlayedIndicator] Optional. Add a played indicator.
         * @param {number} [blur] Optional. Blur image.
         * @param {string} [backgroundColor] Optional. Apply a background color for transparent images.
         * @param {string} [foregroundLayer] Optional. Apply a foreground layer on top of the image.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        headItemImage2_2: async (itemId: string, imageType: ImageType, maxWidth: number, maxHeight: number, tag: string, format: ImageFormat, percentPlayed: number, unplayedCount: number, imageIndex: number, width?: number, height?: number, quality?: number, cropWhitespace?: boolean, addPlayedIndicator?: boolean, blur?: number, backgroundColor?: string, foregroundLayer?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'itemId' is not null or undefined
            if (itemId === null || itemId === undefined) {
                throw new RequiredError('itemId','Required parameter itemId was null or undefined when calling headItemImage2_2.');
            }
            // verify required parameter 'imageType' is not null or undefined
            if (imageType === null || imageType === undefined) {
                throw new RequiredError('imageType','Required parameter imageType was null or undefined when calling headItemImage2_2.');
            }
            // verify required parameter 'maxWidth' is not null or undefined
            if (maxWidth === null || maxWidth === undefined) {
                throw new RequiredError('maxWidth','Required parameter maxWidth was null or undefined when calling headItemImage2_2.');
            }
            // verify required parameter 'maxHeight' is not null or undefined
            if (maxHeight === null || maxHeight === undefined) {
                throw new RequiredError('maxHeight','Required parameter maxHeight was null or undefined when calling headItemImage2_2.');
            }
            // verify required parameter 'tag' is not null or undefined
            if (tag === null || tag === undefined) {
                throw new RequiredError('tag','Required parameter tag was null or undefined when calling headItemImage2_2.');
            }
            // verify required parameter 'format' is not null or undefined
            if (format === null || format === undefined) {
                throw new RequiredError('format','Required parameter format was null or undefined when calling headItemImage2_2.');
            }
            // verify required parameter 'percentPlayed' is not null or undefined
            if (percentPlayed === null || percentPlayed === undefined) {
                throw new RequiredError('percentPlayed','Required parameter percentPlayed was null or undefined when calling headItemImage2_2.');
            }
            // verify required parameter 'unplayedCount' is not null or undefined
            if (unplayedCount === null || unplayedCount === undefined) {
                throw new RequiredError('unplayedCount','Required parameter unplayedCount was null or undefined when calling headItemImage2_2.');
            }
            // verify required parameter 'imageIndex' is not null or undefined
            if (imageIndex === null || imageIndex === undefined) {
                throw new RequiredError('imageIndex','Required parameter imageIndex was null or undefined when calling headItemImage2_2.');
            }
            const localVarPath = `/Items/{itemId}/Images/{imageType}/{imageIndex}/{tag}/{format}/{maxWidth}/{maxHeight}/{percentPlayed}/{unplayedCount}`
                .replace(`{${"itemId"}}`, encodeURIComponent(String(itemId)))
                .replace(`{${"imageType"}}`, encodeURIComponent(String(imageType)))
                .replace(`{${"maxWidth"}}`, encodeURIComponent(String(maxWidth)))
                .replace(`{${"maxHeight"}}`, encodeURIComponent(String(maxHeight)))
                .replace(`{${"tag"}}`, encodeURIComponent(String(tag)))
                .replace(`{${"format"}}`, encodeURIComponent(String(format)))
                .replace(`{${"percentPlayed"}}`, encodeURIComponent(String(percentPlayed)))
                .replace(`{${"unplayedCount"}}`, encodeURIComponent(String(unplayedCount)))
                .replace(`{${"imageIndex"}}`, encodeURIComponent(String(imageIndex)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'HEAD', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }

            if (width !== undefined) {
                localVarQueryParameter['width'] = width;
            }

            if (height !== undefined) {
                localVarQueryParameter['height'] = height;
            }

            if (quality !== undefined) {
                localVarQueryParameter['quality'] = quality;
            }

            if (cropWhitespace !== undefined) {
                localVarQueryParameter['cropWhitespace'] = cropWhitespace;
            }

            if (addPlayedIndicator !== undefined) {
                localVarQueryParameter['addPlayedIndicator'] = addPlayedIndicator;
            }

            if (blur !== undefined) {
                localVarQueryParameter['blur'] = blur;
            }

            if (backgroundColor !== undefined) {
                localVarQueryParameter['backgroundColor'] = backgroundColor;
            }

            if (foregroundLayer !== undefined) {
                localVarQueryParameter['foregroundLayer'] = foregroundLayer;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get music genre image by name.
         * @param {string} name Music genre name.
         * @param {ImageType} imageType Image type.
         * @param {number} imageIndex Image index.
         * @param {string} [tag] Optional. Supply the cache tag from the item object to receive strong caching headers.
         * @param {ImageFormat} [format] Determines the output format of the image - original,gif,jpg,png.
         * @param {number} [maxWidth] The maximum image width to return.
         * @param {number} [maxHeight] The maximum image height to return.
         * @param {number} [percentPlayed] Optional. Percent to render for the percent played overlay.
         * @param {number} [unplayedCount] Optional. Unplayed count overlay to render.
         * @param {number} [width] The fixed image width to return.
         * @param {number} [height] The fixed image height to return.
         * @param {number} [quality] Optional. Quality setting, from 0-100. Defaults to 90 and should suffice in most cases.
         * @param {boolean} [cropWhitespace] Optional. Specify if whitespace should be cropped out of the image. True/False. If unspecified, whitespace will be cropped from logos and clear art.
         * @param {boolean} [addPlayedIndicator] Optional. Add a played indicator.
         * @param {number} [blur] Optional. Blur image.
         * @param {string} [backgroundColor] Optional. Apply a background color for transparent images.
         * @param {string} [foregroundLayer] Optional. Apply a foreground layer on top of the image.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        headMusicGenreImage: async (name: string, imageType: ImageType, imageIndex: number, tag?: string, format?: ImageFormat, maxWidth?: number, maxHeight?: number, percentPlayed?: number, unplayedCount?: number, width?: number, height?: number, quality?: number, cropWhitespace?: boolean, addPlayedIndicator?: boolean, blur?: number, backgroundColor?: string, foregroundLayer?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            if (name === null || name === undefined) {
                throw new RequiredError('name','Required parameter name was null or undefined when calling headMusicGenreImage.');
            }
            // verify required parameter 'imageType' is not null or undefined
            if (imageType === null || imageType === undefined) {
                throw new RequiredError('imageType','Required parameter imageType was null or undefined when calling headMusicGenreImage.');
            }
            // verify required parameter 'imageIndex' is not null or undefined
            if (imageIndex === null || imageIndex === undefined) {
                throw new RequiredError('imageIndex','Required parameter imageIndex was null or undefined when calling headMusicGenreImage.');
            }
            const localVarPath = `/MusicGenres/{name}/Images/{imageType}/{imageIndex}`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)))
                .replace(`{${"imageType"}}`, encodeURIComponent(String(imageType)))
                .replace(`{${"imageIndex"}}`, encodeURIComponent(String(imageIndex)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'HEAD', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }

            if (tag !== undefined) {
                localVarQueryParameter['tag'] = tag;
            }

            if (format !== undefined) {
                localVarQueryParameter['format'] = format;
            }

            if (maxWidth !== undefined) {
                localVarQueryParameter['maxWidth'] = maxWidth;
            }

            if (maxHeight !== undefined) {
                localVarQueryParameter['maxHeight'] = maxHeight;
            }

            if (percentPlayed !== undefined) {
                localVarQueryParameter['percentPlayed'] = percentPlayed;
            }

            if (unplayedCount !== undefined) {
                localVarQueryParameter['unplayedCount'] = unplayedCount;
            }

            if (width !== undefined) {
                localVarQueryParameter['width'] = width;
            }

            if (height !== undefined) {
                localVarQueryParameter['height'] = height;
            }

            if (quality !== undefined) {
                localVarQueryParameter['quality'] = quality;
            }

            if (cropWhitespace !== undefined) {
                localVarQueryParameter['cropWhitespace'] = cropWhitespace;
            }

            if (addPlayedIndicator !== undefined) {
                localVarQueryParameter['addPlayedIndicator'] = addPlayedIndicator;
            }

            if (blur !== undefined) {
                localVarQueryParameter['blur'] = blur;
            }

            if (backgroundColor !== undefined) {
                localVarQueryParameter['backgroundColor'] = backgroundColor;
            }

            if (foregroundLayer !== undefined) {
                localVarQueryParameter['foregroundLayer'] = foregroundLayer;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get person image by name.
         * @param {string} name Person name.
         * @param {ImageType} imageType Image type.
         * @param {number} imageIndex Image index.
         * @param {string} [tag] Optional. Supply the cache tag from the item object to receive strong caching headers.
         * @param {ImageFormat} [format] Determines the output format of the image - original,gif,jpg,png.
         * @param {number} [maxWidth] The maximum image width to return.
         * @param {number} [maxHeight] The maximum image height to return.
         * @param {number} [percentPlayed] Optional. Percent to render for the percent played overlay.
         * @param {number} [unplayedCount] Optional. Unplayed count overlay to render.
         * @param {number} [width] The fixed image width to return.
         * @param {number} [height] The fixed image height to return.
         * @param {number} [quality] Optional. Quality setting, from 0-100. Defaults to 90 and should suffice in most cases.
         * @param {boolean} [cropWhitespace] Optional. Specify if whitespace should be cropped out of the image. True/False. If unspecified, whitespace will be cropped from logos and clear art.
         * @param {boolean} [addPlayedIndicator] Optional. Add a played indicator.
         * @param {number} [blur] Optional. Blur image.
         * @param {string} [backgroundColor] Optional. Apply a background color for transparent images.
         * @param {string} [foregroundLayer] Optional. Apply a foreground layer on top of the image.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        headPersonImage: async (name: string, imageType: ImageType, imageIndex: number, tag?: string, format?: ImageFormat, maxWidth?: number, maxHeight?: number, percentPlayed?: number, unplayedCount?: number, width?: number, height?: number, quality?: number, cropWhitespace?: boolean, addPlayedIndicator?: boolean, blur?: number, backgroundColor?: string, foregroundLayer?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            if (name === null || name === undefined) {
                throw new RequiredError('name','Required parameter name was null or undefined when calling headPersonImage.');
            }
            // verify required parameter 'imageType' is not null or undefined
            if (imageType === null || imageType === undefined) {
                throw new RequiredError('imageType','Required parameter imageType was null or undefined when calling headPersonImage.');
            }
            // verify required parameter 'imageIndex' is not null or undefined
            if (imageIndex === null || imageIndex === undefined) {
                throw new RequiredError('imageIndex','Required parameter imageIndex was null or undefined when calling headPersonImage.');
            }
            const localVarPath = `/Persons/{name}/Images/{imageType}/{imageIndex}`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)))
                .replace(`{${"imageType"}}`, encodeURIComponent(String(imageType)))
                .replace(`{${"imageIndex"}}`, encodeURIComponent(String(imageIndex)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'HEAD', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }

            if (tag !== undefined) {
                localVarQueryParameter['tag'] = tag;
            }

            if (format !== undefined) {
                localVarQueryParameter['format'] = format;
            }

            if (maxWidth !== undefined) {
                localVarQueryParameter['maxWidth'] = maxWidth;
            }

            if (maxHeight !== undefined) {
                localVarQueryParameter['maxHeight'] = maxHeight;
            }

            if (percentPlayed !== undefined) {
                localVarQueryParameter['percentPlayed'] = percentPlayed;
            }

            if (unplayedCount !== undefined) {
                localVarQueryParameter['unplayedCount'] = unplayedCount;
            }

            if (width !== undefined) {
                localVarQueryParameter['width'] = width;
            }

            if (height !== undefined) {
                localVarQueryParameter['height'] = height;
            }

            if (quality !== undefined) {
                localVarQueryParameter['quality'] = quality;
            }

            if (cropWhitespace !== undefined) {
                localVarQueryParameter['cropWhitespace'] = cropWhitespace;
            }

            if (addPlayedIndicator !== undefined) {
                localVarQueryParameter['addPlayedIndicator'] = addPlayedIndicator;
            }

            if (blur !== undefined) {
                localVarQueryParameter['blur'] = blur;
            }

            if (backgroundColor !== undefined) {
                localVarQueryParameter['backgroundColor'] = backgroundColor;
            }

            if (foregroundLayer !== undefined) {
                localVarQueryParameter['foregroundLayer'] = foregroundLayer;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get studio image by name.
         * @param {string} name Studio name.
         * @param {ImageType} imageType Image type.
         * @param {string} tag Optional. Supply the cache tag from the item object to receive strong caching headers.
         * @param {ImageFormat} format Determines the output format of the image - original,gif,jpg,png.
         * @param {number} imageIndex Image index.
         * @param {number} [maxWidth] The maximum image width to return.
         * @param {number} [maxHeight] The maximum image height to return.
         * @param {number} [percentPlayed] Optional. Percent to render for the percent played overlay.
         * @param {number} [unplayedCount] Optional. Unplayed count overlay to render.
         * @param {number} [width] The fixed image width to return.
         * @param {number} [height] The fixed image height to return.
         * @param {number} [quality] Optional. Quality setting, from 0-100. Defaults to 90 and should suffice in most cases.
         * @param {boolean} [cropWhitespace] Optional. Specify if whitespace should be cropped out of the image. True/False. If unspecified, whitespace will be cropped from logos and clear art.
         * @param {boolean} [addPlayedIndicator] Optional. Add a played indicator.
         * @param {number} [blur] Optional. Blur image.
         * @param {string} [backgroundColor] Optional. Apply a background color for transparent images.
         * @param {string} [foregroundLayer] Optional. Apply a foreground layer on top of the image.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        headStudioImage: async (name: string, imageType: ImageType, tag: string, format: ImageFormat, imageIndex: number, maxWidth?: number, maxHeight?: number, percentPlayed?: number, unplayedCount?: number, width?: number, height?: number, quality?: number, cropWhitespace?: boolean, addPlayedIndicator?: boolean, blur?: number, backgroundColor?: string, foregroundLayer?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            if (name === null || name === undefined) {
                throw new RequiredError('name','Required parameter name was null or undefined when calling headStudioImage.');
            }
            // verify required parameter 'imageType' is not null or undefined
            if (imageType === null || imageType === undefined) {
                throw new RequiredError('imageType','Required parameter imageType was null or undefined when calling headStudioImage.');
            }
            // verify required parameter 'tag' is not null or undefined
            if (tag === null || tag === undefined) {
                throw new RequiredError('tag','Required parameter tag was null or undefined when calling headStudioImage.');
            }
            // verify required parameter 'format' is not null or undefined
            if (format === null || format === undefined) {
                throw new RequiredError('format','Required parameter format was null or undefined when calling headStudioImage.');
            }
            // verify required parameter 'imageIndex' is not null or undefined
            if (imageIndex === null || imageIndex === undefined) {
                throw new RequiredError('imageIndex','Required parameter imageIndex was null or undefined when calling headStudioImage.');
            }
            const localVarPath = `/Studios/{name}/Images/{imageType}/{imageIndex}`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)))
                .replace(`{${"imageType"}}`, encodeURIComponent(String(imageType)))
                .replace(`{${"tag"}}`, encodeURIComponent(String(tag)))
                .replace(`{${"format"}}`, encodeURIComponent(String(format)))
                .replace(`{${"imageIndex"}}`, encodeURIComponent(String(imageIndex)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'HEAD', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }

            if (maxWidth !== undefined) {
                localVarQueryParameter['maxWidth'] = maxWidth;
            }

            if (maxHeight !== undefined) {
                localVarQueryParameter['maxHeight'] = maxHeight;
            }

            if (percentPlayed !== undefined) {
                localVarQueryParameter['percentPlayed'] = percentPlayed;
            }

            if (unplayedCount !== undefined) {
                localVarQueryParameter['unplayedCount'] = unplayedCount;
            }

            if (width !== undefined) {
                localVarQueryParameter['width'] = width;
            }

            if (height !== undefined) {
                localVarQueryParameter['height'] = height;
            }

            if (quality !== undefined) {
                localVarQueryParameter['quality'] = quality;
            }

            if (cropWhitespace !== undefined) {
                localVarQueryParameter['cropWhitespace'] = cropWhitespace;
            }

            if (addPlayedIndicator !== undefined) {
                localVarQueryParameter['addPlayedIndicator'] = addPlayedIndicator;
            }

            if (blur !== undefined) {
                localVarQueryParameter['blur'] = blur;
            }

            if (backgroundColor !== undefined) {
                localVarQueryParameter['backgroundColor'] = backgroundColor;
            }

            if (foregroundLayer !== undefined) {
                localVarQueryParameter['foregroundLayer'] = foregroundLayer;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get user profile image.
         * @param {string} userId User id.
         * @param {ImageType} imageType Image type.
         * @param {number} imageIndex Image index.
         * @param {string} [tag] Optional. Supply the cache tag from the item object to receive strong caching headers.
         * @param {ImageFormat} [format] Determines the output format of the image - original,gif,jpg,png.
         * @param {number} [maxWidth] The maximum image width to return.
         * @param {number} [maxHeight] The maximum image height to return.
         * @param {number} [percentPlayed] Optional. Percent to render for the percent played overlay.
         * @param {number} [unplayedCount] Optional. Unplayed count overlay to render.
         * @param {number} [width] The fixed image width to return.
         * @param {number} [height] The fixed image height to return.
         * @param {number} [quality] Optional. Quality setting, from 0-100. Defaults to 90 and should suffice in most cases.
         * @param {boolean} [cropWhitespace] Optional. Specify if whitespace should be cropped out of the image. True/False. If unspecified, whitespace will be cropped from logos and clear art.
         * @param {boolean} [addPlayedIndicator] Optional. Add a played indicator.
         * @param {number} [blur] Optional. Blur image.
         * @param {string} [backgroundColor] Optional. Apply a background color for transparent images.
         * @param {string} [foregroundLayer] Optional. Apply a foreground layer on top of the image.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        headUserImage: async (userId: string, imageType: ImageType, imageIndex: number, tag?: string, format?: ImageFormat, maxWidth?: number, maxHeight?: number, percentPlayed?: number, unplayedCount?: number, width?: number, height?: number, quality?: number, cropWhitespace?: boolean, addPlayedIndicator?: boolean, blur?: number, backgroundColor?: string, foregroundLayer?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId','Required parameter userId was null or undefined when calling headUserImage.');
            }
            // verify required parameter 'imageType' is not null or undefined
            if (imageType === null || imageType === undefined) {
                throw new RequiredError('imageType','Required parameter imageType was null or undefined when calling headUserImage.');
            }
            // verify required parameter 'imageIndex' is not null or undefined
            if (imageIndex === null || imageIndex === undefined) {
                throw new RequiredError('imageIndex','Required parameter imageIndex was null or undefined when calling headUserImage.');
            }
            const localVarPath = `/Users/{userId}/Images/{imageType}/{imageIndex}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"imageType"}}`, encodeURIComponent(String(imageType)))
                .replace(`{${"imageIndex"}}`, encodeURIComponent(String(imageIndex)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'HEAD', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }

            if (tag !== undefined) {
                localVarQueryParameter['tag'] = tag;
            }

            if (format !== undefined) {
                localVarQueryParameter['format'] = format;
            }

            if (maxWidth !== undefined) {
                localVarQueryParameter['maxWidth'] = maxWidth;
            }

            if (maxHeight !== undefined) {
                localVarQueryParameter['maxHeight'] = maxHeight;
            }

            if (percentPlayed !== undefined) {
                localVarQueryParameter['percentPlayed'] = percentPlayed;
            }

            if (unplayedCount !== undefined) {
                localVarQueryParameter['unplayedCount'] = unplayedCount;
            }

            if (width !== undefined) {
                localVarQueryParameter['width'] = width;
            }

            if (height !== undefined) {
                localVarQueryParameter['height'] = height;
            }

            if (quality !== undefined) {
                localVarQueryParameter['quality'] = quality;
            }

            if (cropWhitespace !== undefined) {
                localVarQueryParameter['cropWhitespace'] = cropWhitespace;
            }

            if (addPlayedIndicator !== undefined) {
                localVarQueryParameter['addPlayedIndicator'] = addPlayedIndicator;
            }

            if (blur !== undefined) {
                localVarQueryParameter['blur'] = blur;
            }

            if (backgroundColor !== undefined) {
                localVarQueryParameter['backgroundColor'] = backgroundColor;
            }

            if (foregroundLayer !== undefined) {
                localVarQueryParameter['foregroundLayer'] = foregroundLayer;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Sets the user image.
         * @param {string} userId User Id.
         * @param {ImageType} imageType (Unused) Image type.
         * @param {number} index (Unused) Image index.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postUserImage: async (userId: string, imageType: ImageType, index: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId','Required parameter userId was null or undefined when calling postUserImage.');
            }
            // verify required parameter 'imageType' is not null or undefined
            if (imageType === null || imageType === undefined) {
                throw new RequiredError('imageType','Required parameter imageType was null or undefined when calling postUserImage.');
            }
            // verify required parameter 'index' is not null or undefined
            if (index === null || index === undefined) {
                throw new RequiredError('index','Required parameter index was null or undefined when calling postUserImage.');
            }
            const localVarPath = `/Users/{userId}/Images/{imageType}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"imageType"}}`, encodeURIComponent(String(imageType)))
                .replace(`{${"index"}}`, encodeURIComponent(String(index)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Sets the user image.
         * @param {string} userId User Id.
         * @param {ImageType} imageType (Unused) Image type.
         * @param {number} index (Unused) Image index.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postUserImage2: async (userId: string, imageType: ImageType, index: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId','Required parameter userId was null or undefined when calling postUserImage2.');
            }
            // verify required parameter 'imageType' is not null or undefined
            if (imageType === null || imageType === undefined) {
                throw new RequiredError('imageType','Required parameter imageType was null or undefined when calling postUserImage2.');
            }
            // verify required parameter 'index' is not null or undefined
            if (index === null || index === undefined) {
                throw new RequiredError('index','Required parameter index was null or undefined when calling postUserImage2.');
            }
            const localVarPath = `/Users/{userId}/Images/{imageType}/{index}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"imageType"}}`, encodeURIComponent(String(imageType)))
                .replace(`{${"index"}}`, encodeURIComponent(String(index)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Set item image.
         * @param {string} itemId Item id.
         * @param {ImageType} imageType Image type.
         * @param {number} imageIndex (Unused) Image index.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setItemImage: async (itemId: string, imageType: ImageType, imageIndex: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'itemId' is not null or undefined
            if (itemId === null || itemId === undefined) {
                throw new RequiredError('itemId','Required parameter itemId was null or undefined when calling setItemImage.');
            }
            // verify required parameter 'imageType' is not null or undefined
            if (imageType === null || imageType === undefined) {
                throw new RequiredError('imageType','Required parameter imageType was null or undefined when calling setItemImage.');
            }
            // verify required parameter 'imageIndex' is not null or undefined
            if (imageIndex === null || imageIndex === undefined) {
                throw new RequiredError('imageIndex','Required parameter imageIndex was null or undefined when calling setItemImage.');
            }
            const localVarPath = `/Items/{itemId}/Images/{imageType}`
                .replace(`{${"itemId"}}`, encodeURIComponent(String(itemId)))
                .replace(`{${"imageType"}}`, encodeURIComponent(String(imageType)))
                .replace(`{${"imageIndex"}}`, encodeURIComponent(String(imageIndex)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Set item image.
         * @param {string} itemId Item id.
         * @param {ImageType} imageType Image type.
         * @param {number} imageIndex (Unused) Image index.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setItemImage2: async (itemId: string, imageType: ImageType, imageIndex: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'itemId' is not null or undefined
            if (itemId === null || itemId === undefined) {
                throw new RequiredError('itemId','Required parameter itemId was null or undefined when calling setItemImage2.');
            }
            // verify required parameter 'imageType' is not null or undefined
            if (imageType === null || imageType === undefined) {
                throw new RequiredError('imageType','Required parameter imageType was null or undefined when calling setItemImage2.');
            }
            // verify required parameter 'imageIndex' is not null or undefined
            if (imageIndex === null || imageIndex === undefined) {
                throw new RequiredError('imageIndex','Required parameter imageIndex was null or undefined when calling setItemImage2.');
            }
            const localVarPath = `/Items/{itemId}/Images/{imageType}/{imageIndex}`
                .replace(`{${"itemId"}}`, encodeURIComponent(String(itemId)))
                .replace(`{${"imageType"}}`, encodeURIComponent(String(imageType)))
                .replace(`{${"imageIndex"}}`, encodeURIComponent(String(imageIndex)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates the index for an item image.
         * @param {string} itemId Item id.
         * @param {ImageType} imageType Image type.
         * @param {number} imageIndex Old image index.
         * @param {number} [newIndex] New image index.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateItemImageIndex: async (itemId: string, imageType: ImageType, imageIndex: number, newIndex?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'itemId' is not null or undefined
            if (itemId === null || itemId === undefined) {
                throw new RequiredError('itemId','Required parameter itemId was null or undefined when calling updateItemImageIndex.');
            }
            // verify required parameter 'imageType' is not null or undefined
            if (imageType === null || imageType === undefined) {
                throw new RequiredError('imageType','Required parameter imageType was null or undefined when calling updateItemImageIndex.');
            }
            // verify required parameter 'imageIndex' is not null or undefined
            if (imageIndex === null || imageIndex === undefined) {
                throw new RequiredError('imageIndex','Required parameter imageIndex was null or undefined when calling updateItemImageIndex.');
            }
            const localVarPath = `/Items/{itemId}/Images/{imageType}/{imageIndex}/Index`
                .replace(`{${"itemId"}}`, encodeURIComponent(String(itemId)))
                .replace(`{${"imageType"}}`, encodeURIComponent(String(imageType)))
                .replace(`{${"imageIndex"}}`, encodeURIComponent(String(imageIndex)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }

            if (newIndex !== undefined) {
                localVarQueryParameter['newIndex'] = newIndex;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ImageApi - functional programming interface
 * @export
 */
export const ImageApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Delete an item\'s image.
         * @param {string} itemId Item id.
         * @param {ImageType} imageType Image type.
         * @param {number} imageIndex The image index.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteItemImage(itemId: string, imageType: ImageType, imageIndex: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ImageApiAxiosParamCreator(configuration).deleteItemImage(itemId, imageType, imageIndex, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Delete an item\'s image.
         * @param {string} itemId Item id.
         * @param {ImageType} imageType Image type.
         * @param {number} imageIndex The image index.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteItemImage2(itemId: string, imageType: ImageType, imageIndex: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ImageApiAxiosParamCreator(configuration).deleteItemImage2(itemId, imageType, imageIndex, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Delete the user\'s image.
         * @param {string} userId User Id.
         * @param {ImageType} imageType (Unused) Image type.
         * @param {number} index (Unused) Image index.
         * @param {string} itemType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteUserImage(userId: string, imageType: ImageType, index: number, itemType: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ImageApiAxiosParamCreator(configuration).deleteUserImage(userId, imageType, index, itemType, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Delete the user\'s image.
         * @param {string} userId User Id.
         * @param {ImageType} imageType (Unused) Image type.
         * @param {number} index (Unused) Image index.
         * @param {string} itemType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteUserImage2(userId: string, imageType: ImageType, index: number, itemType: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ImageApiAxiosParamCreator(configuration).deleteUserImage2(userId, imageType, index, itemType, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get artist image by name.
         * @param {string} name Artist name.
         * @param {ImageType} imageType Image type.
         * @param {number} imageIndex Image index.
         * @param {string} [tag] Optional. Supply the cache tag from the item object to receive strong caching headers.
         * @param {ImageFormat} [format] Determines the output format of the image - original,gif,jpg,png.
         * @param {number} [maxWidth] The maximum image width to return.
         * @param {number} [maxHeight] The maximum image height to return.
         * @param {number} [percentPlayed] Optional. Percent to render for the percent played overlay.
         * @param {number} [unplayedCount] Optional. Unplayed count overlay to render.
         * @param {number} [width] The fixed image width to return.
         * @param {number} [height] The fixed image height to return.
         * @param {number} [quality] Optional. Quality setting, from 0-100. Defaults to 90 and should suffice in most cases.
         * @param {boolean} [cropWhitespace] Optional. Specify if whitespace should be cropped out of the image. True/False. If unspecified, whitespace will be cropped from logos and clear art.
         * @param {boolean} [addPlayedIndicator] Optional. Add a played indicator.
         * @param {number} [blur] Optional. Blur image.
         * @param {string} [backgroundColor] Optional. Apply a background color for transparent images.
         * @param {string} [foregroundLayer] Optional. Apply a foreground layer on top of the image.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getArtistImage(name: string, imageType: ImageType, imageIndex: number, tag?: string, format?: ImageFormat, maxWidth?: number, maxHeight?: number, percentPlayed?: number, unplayedCount?: number, width?: number, height?: number, quality?: number, cropWhitespace?: boolean, addPlayedIndicator?: boolean, blur?: number, backgroundColor?: string, foregroundLayer?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await ImageApiAxiosParamCreator(configuration).getArtistImage(name, imageType, imageIndex, tag, format, maxWidth, maxHeight, percentPlayed, unplayedCount, width, height, quality, cropWhitespace, addPlayedIndicator, blur, backgroundColor, foregroundLayer, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get genre image by name.
         * @param {string} name Genre name.
         * @param {ImageType} imageType Image type.
         * @param {number} imageIndex Image index.
         * @param {string} [tag] Optional. Supply the cache tag from the item object to receive strong caching headers.
         * @param {ImageFormat} [format] Determines the output format of the image - original,gif,jpg,png.
         * @param {number} [maxWidth] The maximum image width to return.
         * @param {number} [maxHeight] The maximum image height to return.
         * @param {number} [percentPlayed] Optional. Percent to render for the percent played overlay.
         * @param {number} [unplayedCount] Optional. Unplayed count overlay to render.
         * @param {number} [width] The fixed image width to return.
         * @param {number} [height] The fixed image height to return.
         * @param {number} [quality] Optional. Quality setting, from 0-100. Defaults to 90 and should suffice in most cases.
         * @param {boolean} [cropWhitespace] Optional. Specify if whitespace should be cropped out of the image. True/False. If unspecified, whitespace will be cropped from logos and clear art.
         * @param {boolean} [addPlayedIndicator] Optional. Add a played indicator.
         * @param {number} [blur] Optional. Blur image.
         * @param {string} [backgroundColor] Optional. Apply a background color for transparent images.
         * @param {string} [foregroundLayer] Optional. Apply a foreground layer on top of the image.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getGenreImage(name: string, imageType: ImageType, imageIndex: number, tag?: string, format?: ImageFormat, maxWidth?: number, maxHeight?: number, percentPlayed?: number, unplayedCount?: number, width?: number, height?: number, quality?: number, cropWhitespace?: boolean, addPlayedIndicator?: boolean, blur?: number, backgroundColor?: string, foregroundLayer?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await ImageApiAxiosParamCreator(configuration).getGenreImage(name, imageType, imageIndex, tag, format, maxWidth, maxHeight, percentPlayed, unplayedCount, width, height, quality, cropWhitespace, addPlayedIndicator, blur, backgroundColor, foregroundLayer, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Gets the item\'s image.
         * @param {string} itemId Item id.
         * @param {ImageType} imageType Image type.
         * @param {number} imageIndex Image index.
         * @param {number} [maxWidth] The maximum image width to return.
         * @param {number} [maxHeight] The maximum image height to return.
         * @param {number} [width] The fixed image width to return.
         * @param {number} [height] The fixed image height to return.
         * @param {number} [quality] Optional. Quality setting, from 0-100. Defaults to 90 and should suffice in most cases.
         * @param {string} [tag] Optional. Supply the cache tag from the item object to receive strong caching headers.
         * @param {boolean} [cropWhitespace] Optional. Specify if whitespace should be cropped out of the image. True/False. If unspecified, whitespace will be cropped from logos and clear art.
         * @param {ImageFormat} [format] Optional. The MediaBrowser.Model.Drawing.ImageFormat of the returned image.
         * @param {boolean} [addPlayedIndicator] Optional. Add a played indicator.
         * @param {number} [percentPlayed] Optional. Percent to render for the percent played overlay.
         * @param {number} [unplayedCount] Optional. Unplayed count overlay to render.
         * @param {number} [blur] Optional. Blur image.
         * @param {string} [backgroundColor] Optional. Apply a background color for transparent images.
         * @param {string} [foregroundLayer] Optional. Apply a foreground layer on top of the image.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getItemImage(itemId: string, imageType: ImageType, imageIndex: number, maxWidth?: number, maxHeight?: number, width?: number, height?: number, quality?: number, tag?: string, cropWhitespace?: boolean, format?: ImageFormat, addPlayedIndicator?: boolean, percentPlayed?: number, unplayedCount?: number, blur?: number, backgroundColor?: string, foregroundLayer?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await ImageApiAxiosParamCreator(configuration).getItemImage(itemId, imageType, imageIndex, maxWidth, maxHeight, width, height, quality, tag, cropWhitespace, format, addPlayedIndicator, percentPlayed, unplayedCount, blur, backgroundColor, foregroundLayer, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Gets the item\'s image.
         * @param {string} itemId Item id.
         * @param {ImageType} imageType Image type.
         * @param {number} imageIndex Image index.
         * @param {number} [maxWidth] The maximum image width to return.
         * @param {number} [maxHeight] The maximum image height to return.
         * @param {number} [width] The fixed image width to return.
         * @param {number} [height] The fixed image height to return.
         * @param {number} [quality] Optional. Quality setting, from 0-100. Defaults to 90 and should suffice in most cases.
         * @param {string} [tag] Optional. Supply the cache tag from the item object to receive strong caching headers.
         * @param {boolean} [cropWhitespace] Optional. Specify if whitespace should be cropped out of the image. True/False. If unspecified, whitespace will be cropped from logos and clear art.
         * @param {ImageFormat} [format] Optional. The MediaBrowser.Model.Drawing.ImageFormat of the returned image.
         * @param {boolean} [addPlayedIndicator] Optional. Add a played indicator.
         * @param {number} [percentPlayed] Optional. Percent to render for the percent played overlay.
         * @param {number} [unplayedCount] Optional. Unplayed count overlay to render.
         * @param {number} [blur] Optional. Blur image.
         * @param {string} [backgroundColor] Optional. Apply a background color for transparent images.
         * @param {string} [foregroundLayer] Optional. Apply a foreground layer on top of the image.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getItemImage2(itemId: string, imageType: ImageType, imageIndex: number, maxWidth?: number, maxHeight?: number, width?: number, height?: number, quality?: number, tag?: string, cropWhitespace?: boolean, format?: ImageFormat, addPlayedIndicator?: boolean, percentPlayed?: number, unplayedCount?: number, blur?: number, backgroundColor?: string, foregroundLayer?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await ImageApiAxiosParamCreator(configuration).getItemImage2(itemId, imageType, imageIndex, maxWidth, maxHeight, width, height, quality, tag, cropWhitespace, format, addPlayedIndicator, percentPlayed, unplayedCount, blur, backgroundColor, foregroundLayer, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Gets the item\'s image.
         * @param {string} itemId Item id.
         * @param {ImageType} imageType Image type.
         * @param {number} maxWidth The maximum image width to return.
         * @param {number} maxHeight The maximum image height to return.
         * @param {string} tag Optional. Supply the cache tag from the item object to receive strong caching headers.
         * @param {ImageFormat} format Determines the output format of the image - original,gif,jpg,png.
         * @param {number} percentPlayed Optional. Percent to render for the percent played overlay.
         * @param {number} unplayedCount Optional. Unplayed count overlay to render.
         * @param {number} imageIndex Image index.
         * @param {number} [width] The fixed image width to return.
         * @param {number} [height] The fixed image height to return.
         * @param {number} [quality] Optional. Quality setting, from 0-100. Defaults to 90 and should suffice in most cases.
         * @param {boolean} [cropWhitespace] Optional. Specify if whitespace should be cropped out of the image. True/False. If unspecified, whitespace will be cropped from logos and clear art.
         * @param {boolean} [addPlayedIndicator] Optional. Add a played indicator.
         * @param {number} [blur] Optional. Blur image.
         * @param {string} [backgroundColor] Optional. Apply a background color for transparent images.
         * @param {string} [foregroundLayer] Optional. Apply a foreground layer on top of the image.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getItemImage2_1(itemId: string, imageType: ImageType, maxWidth: number, maxHeight: number, tag: string, format: ImageFormat, percentPlayed: number, unplayedCount: number, imageIndex: number, width?: number, height?: number, quality?: number, cropWhitespace?: boolean, addPlayedIndicator?: boolean, blur?: number, backgroundColor?: string, foregroundLayer?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await ImageApiAxiosParamCreator(configuration).getItemImage2_1(itemId, imageType, maxWidth, maxHeight, tag, format, percentPlayed, unplayedCount, imageIndex, width, height, quality, cropWhitespace, addPlayedIndicator, blur, backgroundColor, foregroundLayer, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get item image infos.
         * @param {string} itemId Item id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getItemImageInfos(itemId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ImageInfo>>> {
            const localVarAxiosArgs = await ImageApiAxiosParamCreator(configuration).getItemImageInfos(itemId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get music genre image by name.
         * @param {string} name Music genre name.
         * @param {ImageType} imageType Image type.
         * @param {number} imageIndex Image index.
         * @param {string} [tag] Optional. Supply the cache tag from the item object to receive strong caching headers.
         * @param {ImageFormat} [format] Determines the output format of the image - original,gif,jpg,png.
         * @param {number} [maxWidth] The maximum image width to return.
         * @param {number} [maxHeight] The maximum image height to return.
         * @param {number} [percentPlayed] Optional. Percent to render for the percent played overlay.
         * @param {number} [unplayedCount] Optional. Unplayed count overlay to render.
         * @param {number} [width] The fixed image width to return.
         * @param {number} [height] The fixed image height to return.
         * @param {number} [quality] Optional. Quality setting, from 0-100. Defaults to 90 and should suffice in most cases.
         * @param {boolean} [cropWhitespace] Optional. Specify if whitespace should be cropped out of the image. True/False. If unspecified, whitespace will be cropped from logos and clear art.
         * @param {boolean} [addPlayedIndicator] Optional. Add a played indicator.
         * @param {number} [blur] Optional. Blur image.
         * @param {string} [backgroundColor] Optional. Apply a background color for transparent images.
         * @param {string} [foregroundLayer] Optional. Apply a foreground layer on top of the image.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMusicGenreImage(name: string, imageType: ImageType, imageIndex: number, tag?: string, format?: ImageFormat, maxWidth?: number, maxHeight?: number, percentPlayed?: number, unplayedCount?: number, width?: number, height?: number, quality?: number, cropWhitespace?: boolean, addPlayedIndicator?: boolean, blur?: number, backgroundColor?: string, foregroundLayer?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await ImageApiAxiosParamCreator(configuration).getMusicGenreImage(name, imageType, imageIndex, tag, format, maxWidth, maxHeight, percentPlayed, unplayedCount, width, height, quality, cropWhitespace, addPlayedIndicator, blur, backgroundColor, foregroundLayer, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get person image by name.
         * @param {string} name Person name.
         * @param {ImageType} imageType Image type.
         * @param {number} imageIndex Image index.
         * @param {string} [tag] Optional. Supply the cache tag from the item object to receive strong caching headers.
         * @param {ImageFormat} [format] Determines the output format of the image - original,gif,jpg,png.
         * @param {number} [maxWidth] The maximum image width to return.
         * @param {number} [maxHeight] The maximum image height to return.
         * @param {number} [percentPlayed] Optional. Percent to render for the percent played overlay.
         * @param {number} [unplayedCount] Optional. Unplayed count overlay to render.
         * @param {number} [width] The fixed image width to return.
         * @param {number} [height] The fixed image height to return.
         * @param {number} [quality] Optional. Quality setting, from 0-100. Defaults to 90 and should suffice in most cases.
         * @param {boolean} [cropWhitespace] Optional. Specify if whitespace should be cropped out of the image. True/False. If unspecified, whitespace will be cropped from logos and clear art.
         * @param {boolean} [addPlayedIndicator] Optional. Add a played indicator.
         * @param {number} [blur] Optional. Blur image.
         * @param {string} [backgroundColor] Optional. Apply a background color for transparent images.
         * @param {string} [foregroundLayer] Optional. Apply a foreground layer on top of the image.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPersonImage(name: string, imageType: ImageType, imageIndex: number, tag?: string, format?: ImageFormat, maxWidth?: number, maxHeight?: number, percentPlayed?: number, unplayedCount?: number, width?: number, height?: number, quality?: number, cropWhitespace?: boolean, addPlayedIndicator?: boolean, blur?: number, backgroundColor?: string, foregroundLayer?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await ImageApiAxiosParamCreator(configuration).getPersonImage(name, imageType, imageIndex, tag, format, maxWidth, maxHeight, percentPlayed, unplayedCount, width, height, quality, cropWhitespace, addPlayedIndicator, blur, backgroundColor, foregroundLayer, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get studio image by name.
         * @param {string} name Studio name.
         * @param {ImageType} imageType Image type.
         * @param {string} tag Optional. Supply the cache tag from the item object to receive strong caching headers.
         * @param {ImageFormat} format Determines the output format of the image - original,gif,jpg,png.
         * @param {number} imageIndex Image index.
         * @param {number} [maxWidth] The maximum image width to return.
         * @param {number} [maxHeight] The maximum image height to return.
         * @param {number} [percentPlayed] Optional. Percent to render for the percent played overlay.
         * @param {number} [unplayedCount] Optional. Unplayed count overlay to render.
         * @param {number} [width] The fixed image width to return.
         * @param {number} [height] The fixed image height to return.
         * @param {number} [quality] Optional. Quality setting, from 0-100. Defaults to 90 and should suffice in most cases.
         * @param {boolean} [cropWhitespace] Optional. Specify if whitespace should be cropped out of the image. True/False. If unspecified, whitespace will be cropped from logos and clear art.
         * @param {boolean} [addPlayedIndicator] Optional. Add a played indicator.
         * @param {number} [blur] Optional. Blur image.
         * @param {string} [backgroundColor] Optional. Apply a background color for transparent images.
         * @param {string} [foregroundLayer] Optional. Apply a foreground layer on top of the image.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStudioImage(name: string, imageType: ImageType, tag: string, format: ImageFormat, imageIndex: number, maxWidth?: number, maxHeight?: number, percentPlayed?: number, unplayedCount?: number, width?: number, height?: number, quality?: number, cropWhitespace?: boolean, addPlayedIndicator?: boolean, blur?: number, backgroundColor?: string, foregroundLayer?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await ImageApiAxiosParamCreator(configuration).getStudioImage(name, imageType, tag, format, imageIndex, maxWidth, maxHeight, percentPlayed, unplayedCount, width, height, quality, cropWhitespace, addPlayedIndicator, blur, backgroundColor, foregroundLayer, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get user profile image.
         * @param {string} userId User id.
         * @param {ImageType} imageType Image type.
         * @param {number} imageIndex Image index.
         * @param {string} [tag] Optional. Supply the cache tag from the item object to receive strong caching headers.
         * @param {ImageFormat} [format] Determines the output format of the image - original,gif,jpg,png.
         * @param {number} [maxWidth] The maximum image width to return.
         * @param {number} [maxHeight] The maximum image height to return.
         * @param {number} [percentPlayed] Optional. Percent to render for the percent played overlay.
         * @param {number} [unplayedCount] Optional. Unplayed count overlay to render.
         * @param {number} [width] The fixed image width to return.
         * @param {number} [height] The fixed image height to return.
         * @param {number} [quality] Optional. Quality setting, from 0-100. Defaults to 90 and should suffice in most cases.
         * @param {boolean} [cropWhitespace] Optional. Specify if whitespace should be cropped out of the image. True/False. If unspecified, whitespace will be cropped from logos and clear art.
         * @param {boolean} [addPlayedIndicator] Optional. Add a played indicator.
         * @param {number} [blur] Optional. Blur image.
         * @param {string} [backgroundColor] Optional. Apply a background color for transparent images.
         * @param {string} [foregroundLayer] Optional. Apply a foreground layer on top of the image.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserImage(userId: string, imageType: ImageType, imageIndex: number, tag?: string, format?: ImageFormat, maxWidth?: number, maxHeight?: number, percentPlayed?: number, unplayedCount?: number, width?: number, height?: number, quality?: number, cropWhitespace?: boolean, addPlayedIndicator?: boolean, blur?: number, backgroundColor?: string, foregroundLayer?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await ImageApiAxiosParamCreator(configuration).getUserImage(userId, imageType, imageIndex, tag, format, maxWidth, maxHeight, percentPlayed, unplayedCount, width, height, quality, cropWhitespace, addPlayedIndicator, blur, backgroundColor, foregroundLayer, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get artist image by name.
         * @param {string} name Artist name.
         * @param {ImageType} imageType Image type.
         * @param {number} imageIndex Image index.
         * @param {string} [tag] Optional. Supply the cache tag from the item object to receive strong caching headers.
         * @param {ImageFormat} [format] Determines the output format of the image - original,gif,jpg,png.
         * @param {number} [maxWidth] The maximum image width to return.
         * @param {number} [maxHeight] The maximum image height to return.
         * @param {number} [percentPlayed] Optional. Percent to render for the percent played overlay.
         * @param {number} [unplayedCount] Optional. Unplayed count overlay to render.
         * @param {number} [width] The fixed image width to return.
         * @param {number} [height] The fixed image height to return.
         * @param {number} [quality] Optional. Quality setting, from 0-100. Defaults to 90 and should suffice in most cases.
         * @param {boolean} [cropWhitespace] Optional. Specify if whitespace should be cropped out of the image. True/False. If unspecified, whitespace will be cropped from logos and clear art.
         * @param {boolean} [addPlayedIndicator] Optional. Add a played indicator.
         * @param {number} [blur] Optional. Blur image.
         * @param {string} [backgroundColor] Optional. Apply a background color for transparent images.
         * @param {string} [foregroundLayer] Optional. Apply a foreground layer on top of the image.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async headArtistImage(name: string, imageType: ImageType, imageIndex: number, tag?: string, format?: ImageFormat, maxWidth?: number, maxHeight?: number, percentPlayed?: number, unplayedCount?: number, width?: number, height?: number, quality?: number, cropWhitespace?: boolean, addPlayedIndicator?: boolean, blur?: number, backgroundColor?: string, foregroundLayer?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await ImageApiAxiosParamCreator(configuration).headArtistImage(name, imageType, imageIndex, tag, format, maxWidth, maxHeight, percentPlayed, unplayedCount, width, height, quality, cropWhitespace, addPlayedIndicator, blur, backgroundColor, foregroundLayer, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get genre image by name.
         * @param {string} name Genre name.
         * @param {ImageType} imageType Image type.
         * @param {number} imageIndex Image index.
         * @param {string} [tag] Optional. Supply the cache tag from the item object to receive strong caching headers.
         * @param {ImageFormat} [format] Determines the output format of the image - original,gif,jpg,png.
         * @param {number} [maxWidth] The maximum image width to return.
         * @param {number} [maxHeight] The maximum image height to return.
         * @param {number} [percentPlayed] Optional. Percent to render for the percent played overlay.
         * @param {number} [unplayedCount] Optional. Unplayed count overlay to render.
         * @param {number} [width] The fixed image width to return.
         * @param {number} [height] The fixed image height to return.
         * @param {number} [quality] Optional. Quality setting, from 0-100. Defaults to 90 and should suffice in most cases.
         * @param {boolean} [cropWhitespace] Optional. Specify if whitespace should be cropped out of the image. True/False. If unspecified, whitespace will be cropped from logos and clear art.
         * @param {boolean} [addPlayedIndicator] Optional. Add a played indicator.
         * @param {number} [blur] Optional. Blur image.
         * @param {string} [backgroundColor] Optional. Apply a background color for transparent images.
         * @param {string} [foregroundLayer] Optional. Apply a foreground layer on top of the image.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async headGenreImage(name: string, imageType: ImageType, imageIndex: number, tag?: string, format?: ImageFormat, maxWidth?: number, maxHeight?: number, percentPlayed?: number, unplayedCount?: number, width?: number, height?: number, quality?: number, cropWhitespace?: boolean, addPlayedIndicator?: boolean, blur?: number, backgroundColor?: string, foregroundLayer?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await ImageApiAxiosParamCreator(configuration).headGenreImage(name, imageType, imageIndex, tag, format, maxWidth, maxHeight, percentPlayed, unplayedCount, width, height, quality, cropWhitespace, addPlayedIndicator, blur, backgroundColor, foregroundLayer, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Gets the item\'s image.
         * @param {string} itemId Item id.
         * @param {ImageType} imageType Image type.
         * @param {number} imageIndex Image index.
         * @param {number} [maxWidth] The maximum image width to return.
         * @param {number} [maxHeight] The maximum image height to return.
         * @param {number} [width] The fixed image width to return.
         * @param {number} [height] The fixed image height to return.
         * @param {number} [quality] Optional. Quality setting, from 0-100. Defaults to 90 and should suffice in most cases.
         * @param {string} [tag] Optional. Supply the cache tag from the item object to receive strong caching headers.
         * @param {boolean} [cropWhitespace] Optional. Specify if whitespace should be cropped out of the image. True/False. If unspecified, whitespace will be cropped from logos and clear art.
         * @param {ImageFormat} [format] Optional. The MediaBrowser.Model.Drawing.ImageFormat of the returned image.
         * @param {boolean} [addPlayedIndicator] Optional. Add a played indicator.
         * @param {number} [percentPlayed] Optional. Percent to render for the percent played overlay.
         * @param {number} [unplayedCount] Optional. Unplayed count overlay to render.
         * @param {number} [blur] Optional. Blur image.
         * @param {string} [backgroundColor] Optional. Apply a background color for transparent images.
         * @param {string} [foregroundLayer] Optional. Apply a foreground layer on top of the image.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async headItemImage(itemId: string, imageType: ImageType, imageIndex: number, maxWidth?: number, maxHeight?: number, width?: number, height?: number, quality?: number, tag?: string, cropWhitespace?: boolean, format?: ImageFormat, addPlayedIndicator?: boolean, percentPlayed?: number, unplayedCount?: number, blur?: number, backgroundColor?: string, foregroundLayer?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await ImageApiAxiosParamCreator(configuration).headItemImage(itemId, imageType, imageIndex, maxWidth, maxHeight, width, height, quality, tag, cropWhitespace, format, addPlayedIndicator, percentPlayed, unplayedCount, blur, backgroundColor, foregroundLayer, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Gets the item\'s image.
         * @param {string} itemId Item id.
         * @param {ImageType} imageType Image type.
         * @param {number} imageIndex Image index.
         * @param {number} [maxWidth] The maximum image width to return.
         * @param {number} [maxHeight] The maximum image height to return.
         * @param {number} [width] The fixed image width to return.
         * @param {number} [height] The fixed image height to return.
         * @param {number} [quality] Optional. Quality setting, from 0-100. Defaults to 90 and should suffice in most cases.
         * @param {string} [tag] Optional. Supply the cache tag from the item object to receive strong caching headers.
         * @param {boolean} [cropWhitespace] Optional. Specify if whitespace should be cropped out of the image. True/False. If unspecified, whitespace will be cropped from logos and clear art.
         * @param {ImageFormat} [format] Optional. The MediaBrowser.Model.Drawing.ImageFormat of the returned image.
         * @param {boolean} [addPlayedIndicator] Optional. Add a played indicator.
         * @param {number} [percentPlayed] Optional. Percent to render for the percent played overlay.
         * @param {number} [unplayedCount] Optional. Unplayed count overlay to render.
         * @param {number} [blur] Optional. Blur image.
         * @param {string} [backgroundColor] Optional. Apply a background color for transparent images.
         * @param {string} [foregroundLayer] Optional. Apply a foreground layer on top of the image.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async headItemImage2(itemId: string, imageType: ImageType, imageIndex: number, maxWidth?: number, maxHeight?: number, width?: number, height?: number, quality?: number, tag?: string, cropWhitespace?: boolean, format?: ImageFormat, addPlayedIndicator?: boolean, percentPlayed?: number, unplayedCount?: number, blur?: number, backgroundColor?: string, foregroundLayer?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await ImageApiAxiosParamCreator(configuration).headItemImage2(itemId, imageType, imageIndex, maxWidth, maxHeight, width, height, quality, tag, cropWhitespace, format, addPlayedIndicator, percentPlayed, unplayedCount, blur, backgroundColor, foregroundLayer, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Gets the item\'s image.
         * @param {string} itemId Item id.
         * @param {ImageType} imageType Image type.
         * @param {number} maxWidth The maximum image width to return.
         * @param {number} maxHeight The maximum image height to return.
         * @param {string} tag Optional. Supply the cache tag from the item object to receive strong caching headers.
         * @param {ImageFormat} format Determines the output format of the image - original,gif,jpg,png.
         * @param {number} percentPlayed Optional. Percent to render for the percent played overlay.
         * @param {number} unplayedCount Optional. Unplayed count overlay to render.
         * @param {number} imageIndex Image index.
         * @param {number} [width] The fixed image width to return.
         * @param {number} [height] The fixed image height to return.
         * @param {number} [quality] Optional. Quality setting, from 0-100. Defaults to 90 and should suffice in most cases.
         * @param {boolean} [cropWhitespace] Optional. Specify if whitespace should be cropped out of the image. True/False. If unspecified, whitespace will be cropped from logos and clear art.
         * @param {boolean} [addPlayedIndicator] Optional. Add a played indicator.
         * @param {number} [blur] Optional. Blur image.
         * @param {string} [backgroundColor] Optional. Apply a background color for transparent images.
         * @param {string} [foregroundLayer] Optional. Apply a foreground layer on top of the image.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async headItemImage2_2(itemId: string, imageType: ImageType, maxWidth: number, maxHeight: number, tag: string, format: ImageFormat, percentPlayed: number, unplayedCount: number, imageIndex: number, width?: number, height?: number, quality?: number, cropWhitespace?: boolean, addPlayedIndicator?: boolean, blur?: number, backgroundColor?: string, foregroundLayer?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await ImageApiAxiosParamCreator(configuration).headItemImage2_2(itemId, imageType, maxWidth, maxHeight, tag, format, percentPlayed, unplayedCount, imageIndex, width, height, quality, cropWhitespace, addPlayedIndicator, blur, backgroundColor, foregroundLayer, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get music genre image by name.
         * @param {string} name Music genre name.
         * @param {ImageType} imageType Image type.
         * @param {number} imageIndex Image index.
         * @param {string} [tag] Optional. Supply the cache tag from the item object to receive strong caching headers.
         * @param {ImageFormat} [format] Determines the output format of the image - original,gif,jpg,png.
         * @param {number} [maxWidth] The maximum image width to return.
         * @param {number} [maxHeight] The maximum image height to return.
         * @param {number} [percentPlayed] Optional. Percent to render for the percent played overlay.
         * @param {number} [unplayedCount] Optional. Unplayed count overlay to render.
         * @param {number} [width] The fixed image width to return.
         * @param {number} [height] The fixed image height to return.
         * @param {number} [quality] Optional. Quality setting, from 0-100. Defaults to 90 and should suffice in most cases.
         * @param {boolean} [cropWhitespace] Optional. Specify if whitespace should be cropped out of the image. True/False. If unspecified, whitespace will be cropped from logos and clear art.
         * @param {boolean} [addPlayedIndicator] Optional. Add a played indicator.
         * @param {number} [blur] Optional. Blur image.
         * @param {string} [backgroundColor] Optional. Apply a background color for transparent images.
         * @param {string} [foregroundLayer] Optional. Apply a foreground layer on top of the image.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async headMusicGenreImage(name: string, imageType: ImageType, imageIndex: number, tag?: string, format?: ImageFormat, maxWidth?: number, maxHeight?: number, percentPlayed?: number, unplayedCount?: number, width?: number, height?: number, quality?: number, cropWhitespace?: boolean, addPlayedIndicator?: boolean, blur?: number, backgroundColor?: string, foregroundLayer?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await ImageApiAxiosParamCreator(configuration).headMusicGenreImage(name, imageType, imageIndex, tag, format, maxWidth, maxHeight, percentPlayed, unplayedCount, width, height, quality, cropWhitespace, addPlayedIndicator, blur, backgroundColor, foregroundLayer, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get person image by name.
         * @param {string} name Person name.
         * @param {ImageType} imageType Image type.
         * @param {number} imageIndex Image index.
         * @param {string} [tag] Optional. Supply the cache tag from the item object to receive strong caching headers.
         * @param {ImageFormat} [format] Determines the output format of the image - original,gif,jpg,png.
         * @param {number} [maxWidth] The maximum image width to return.
         * @param {number} [maxHeight] The maximum image height to return.
         * @param {number} [percentPlayed] Optional. Percent to render for the percent played overlay.
         * @param {number} [unplayedCount] Optional. Unplayed count overlay to render.
         * @param {number} [width] The fixed image width to return.
         * @param {number} [height] The fixed image height to return.
         * @param {number} [quality] Optional. Quality setting, from 0-100. Defaults to 90 and should suffice in most cases.
         * @param {boolean} [cropWhitespace] Optional. Specify if whitespace should be cropped out of the image. True/False. If unspecified, whitespace will be cropped from logos and clear art.
         * @param {boolean} [addPlayedIndicator] Optional. Add a played indicator.
         * @param {number} [blur] Optional. Blur image.
         * @param {string} [backgroundColor] Optional. Apply a background color for transparent images.
         * @param {string} [foregroundLayer] Optional. Apply a foreground layer on top of the image.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async headPersonImage(name: string, imageType: ImageType, imageIndex: number, tag?: string, format?: ImageFormat, maxWidth?: number, maxHeight?: number, percentPlayed?: number, unplayedCount?: number, width?: number, height?: number, quality?: number, cropWhitespace?: boolean, addPlayedIndicator?: boolean, blur?: number, backgroundColor?: string, foregroundLayer?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await ImageApiAxiosParamCreator(configuration).headPersonImage(name, imageType, imageIndex, tag, format, maxWidth, maxHeight, percentPlayed, unplayedCount, width, height, quality, cropWhitespace, addPlayedIndicator, blur, backgroundColor, foregroundLayer, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get studio image by name.
         * @param {string} name Studio name.
         * @param {ImageType} imageType Image type.
         * @param {string} tag Optional. Supply the cache tag from the item object to receive strong caching headers.
         * @param {ImageFormat} format Determines the output format of the image - original,gif,jpg,png.
         * @param {number} imageIndex Image index.
         * @param {number} [maxWidth] The maximum image width to return.
         * @param {number} [maxHeight] The maximum image height to return.
         * @param {number} [percentPlayed] Optional. Percent to render for the percent played overlay.
         * @param {number} [unplayedCount] Optional. Unplayed count overlay to render.
         * @param {number} [width] The fixed image width to return.
         * @param {number} [height] The fixed image height to return.
         * @param {number} [quality] Optional. Quality setting, from 0-100. Defaults to 90 and should suffice in most cases.
         * @param {boolean} [cropWhitespace] Optional. Specify if whitespace should be cropped out of the image. True/False. If unspecified, whitespace will be cropped from logos and clear art.
         * @param {boolean} [addPlayedIndicator] Optional. Add a played indicator.
         * @param {number} [blur] Optional. Blur image.
         * @param {string} [backgroundColor] Optional. Apply a background color for transparent images.
         * @param {string} [foregroundLayer] Optional. Apply a foreground layer on top of the image.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async headStudioImage(name: string, imageType: ImageType, tag: string, format: ImageFormat, imageIndex: number, maxWidth?: number, maxHeight?: number, percentPlayed?: number, unplayedCount?: number, width?: number, height?: number, quality?: number, cropWhitespace?: boolean, addPlayedIndicator?: boolean, blur?: number, backgroundColor?: string, foregroundLayer?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await ImageApiAxiosParamCreator(configuration).headStudioImage(name, imageType, tag, format, imageIndex, maxWidth, maxHeight, percentPlayed, unplayedCount, width, height, quality, cropWhitespace, addPlayedIndicator, blur, backgroundColor, foregroundLayer, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get user profile image.
         * @param {string} userId User id.
         * @param {ImageType} imageType Image type.
         * @param {number} imageIndex Image index.
         * @param {string} [tag] Optional. Supply the cache tag from the item object to receive strong caching headers.
         * @param {ImageFormat} [format] Determines the output format of the image - original,gif,jpg,png.
         * @param {number} [maxWidth] The maximum image width to return.
         * @param {number} [maxHeight] The maximum image height to return.
         * @param {number} [percentPlayed] Optional. Percent to render for the percent played overlay.
         * @param {number} [unplayedCount] Optional. Unplayed count overlay to render.
         * @param {number} [width] The fixed image width to return.
         * @param {number} [height] The fixed image height to return.
         * @param {number} [quality] Optional. Quality setting, from 0-100. Defaults to 90 and should suffice in most cases.
         * @param {boolean} [cropWhitespace] Optional. Specify if whitespace should be cropped out of the image. True/False. If unspecified, whitespace will be cropped from logos and clear art.
         * @param {boolean} [addPlayedIndicator] Optional. Add a played indicator.
         * @param {number} [blur] Optional. Blur image.
         * @param {string} [backgroundColor] Optional. Apply a background color for transparent images.
         * @param {string} [foregroundLayer] Optional. Apply a foreground layer on top of the image.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async headUserImage(userId: string, imageType: ImageType, imageIndex: number, tag?: string, format?: ImageFormat, maxWidth?: number, maxHeight?: number, percentPlayed?: number, unplayedCount?: number, width?: number, height?: number, quality?: number, cropWhitespace?: boolean, addPlayedIndicator?: boolean, blur?: number, backgroundColor?: string, foregroundLayer?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await ImageApiAxiosParamCreator(configuration).headUserImage(userId, imageType, imageIndex, tag, format, maxWidth, maxHeight, percentPlayed, unplayedCount, width, height, quality, cropWhitespace, addPlayedIndicator, blur, backgroundColor, foregroundLayer, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Sets the user image.
         * @param {string} userId User Id.
         * @param {ImageType} imageType (Unused) Image type.
         * @param {number} index (Unused) Image index.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postUserImage(userId: string, imageType: ImageType, index: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ImageApiAxiosParamCreator(configuration).postUserImage(userId, imageType, index, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Sets the user image.
         * @param {string} userId User Id.
         * @param {ImageType} imageType (Unused) Image type.
         * @param {number} index (Unused) Image index.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postUserImage2(userId: string, imageType: ImageType, index: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ImageApiAxiosParamCreator(configuration).postUserImage2(userId, imageType, index, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Set item image.
         * @param {string} itemId Item id.
         * @param {ImageType} imageType Image type.
         * @param {number} imageIndex (Unused) Image index.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setItemImage(itemId: string, imageType: ImageType, imageIndex: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ImageApiAxiosParamCreator(configuration).setItemImage(itemId, imageType, imageIndex, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Set item image.
         * @param {string} itemId Item id.
         * @param {ImageType} imageType Image type.
         * @param {number} imageIndex (Unused) Image index.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setItemImage2(itemId: string, imageType: ImageType, imageIndex: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ImageApiAxiosParamCreator(configuration).setItemImage2(itemId, imageType, imageIndex, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Updates the index for an item image.
         * @param {string} itemId Item id.
         * @param {ImageType} imageType Image type.
         * @param {number} imageIndex Old image index.
         * @param {number} [newIndex] New image index.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateItemImageIndex(itemId: string, imageType: ImageType, imageIndex: number, newIndex?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ImageApiAxiosParamCreator(configuration).updateItemImageIndex(itemId, imageType, imageIndex, newIndex, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * ImageApi - factory interface
 * @export
 */
export const ImageApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Delete an item\'s image.
         * @param {string} itemId Item id.
         * @param {ImageType} imageType Image type.
         * @param {number} imageIndex The image index.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteItemImage(itemId: string, imageType: ImageType, imageIndex: number, options?: any): AxiosPromise<void> {
            return ImageApiFp(configuration).deleteItemImage(itemId, imageType, imageIndex, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete an item\'s image.
         * @param {string} itemId Item id.
         * @param {ImageType} imageType Image type.
         * @param {number} imageIndex The image index.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteItemImage2(itemId: string, imageType: ImageType, imageIndex: number, options?: any): AxiosPromise<void> {
            return ImageApiFp(configuration).deleteItemImage2(itemId, imageType, imageIndex, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete the user\'s image.
         * @param {string} userId User Id.
         * @param {ImageType} imageType (Unused) Image type.
         * @param {number} index (Unused) Image index.
         * @param {string} itemType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserImage(userId: string, imageType: ImageType, index: number, itemType: string, options?: any): AxiosPromise<void> {
            return ImageApiFp(configuration).deleteUserImage(userId, imageType, index, itemType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete the user\'s image.
         * @param {string} userId User Id.
         * @param {ImageType} imageType (Unused) Image type.
         * @param {number} index (Unused) Image index.
         * @param {string} itemType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserImage2(userId: string, imageType: ImageType, index: number, itemType: string, options?: any): AxiosPromise<void> {
            return ImageApiFp(configuration).deleteUserImage2(userId, imageType, index, itemType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get artist image by name.
         * @param {string} name Artist name.
         * @param {ImageType} imageType Image type.
         * @param {number} imageIndex Image index.
         * @param {string} [tag] Optional. Supply the cache tag from the item object to receive strong caching headers.
         * @param {ImageFormat} [format] Determines the output format of the image - original,gif,jpg,png.
         * @param {number} [maxWidth] The maximum image width to return.
         * @param {number} [maxHeight] The maximum image height to return.
         * @param {number} [percentPlayed] Optional. Percent to render for the percent played overlay.
         * @param {number} [unplayedCount] Optional. Unplayed count overlay to render.
         * @param {number} [width] The fixed image width to return.
         * @param {number} [height] The fixed image height to return.
         * @param {number} [quality] Optional. Quality setting, from 0-100. Defaults to 90 and should suffice in most cases.
         * @param {boolean} [cropWhitespace] Optional. Specify if whitespace should be cropped out of the image. True/False. If unspecified, whitespace will be cropped from logos and clear art.
         * @param {boolean} [addPlayedIndicator] Optional. Add a played indicator.
         * @param {number} [blur] Optional. Blur image.
         * @param {string} [backgroundColor] Optional. Apply a background color for transparent images.
         * @param {string} [foregroundLayer] Optional. Apply a foreground layer on top of the image.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getArtistImage(name: string, imageType: ImageType, imageIndex: number, tag?: string, format?: ImageFormat, maxWidth?: number, maxHeight?: number, percentPlayed?: number, unplayedCount?: number, width?: number, height?: number, quality?: number, cropWhitespace?: boolean, addPlayedIndicator?: boolean, blur?: number, backgroundColor?: string, foregroundLayer?: string, options?: any): AxiosPromise<any> {
            return ImageApiFp(configuration).getArtistImage(name, imageType, imageIndex, tag, format, maxWidth, maxHeight, percentPlayed, unplayedCount, width, height, quality, cropWhitespace, addPlayedIndicator, blur, backgroundColor, foregroundLayer, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get genre image by name.
         * @param {string} name Genre name.
         * @param {ImageType} imageType Image type.
         * @param {number} imageIndex Image index.
         * @param {string} [tag] Optional. Supply the cache tag from the item object to receive strong caching headers.
         * @param {ImageFormat} [format] Determines the output format of the image - original,gif,jpg,png.
         * @param {number} [maxWidth] The maximum image width to return.
         * @param {number} [maxHeight] The maximum image height to return.
         * @param {number} [percentPlayed] Optional. Percent to render for the percent played overlay.
         * @param {number} [unplayedCount] Optional. Unplayed count overlay to render.
         * @param {number} [width] The fixed image width to return.
         * @param {number} [height] The fixed image height to return.
         * @param {number} [quality] Optional. Quality setting, from 0-100. Defaults to 90 and should suffice in most cases.
         * @param {boolean} [cropWhitespace] Optional. Specify if whitespace should be cropped out of the image. True/False. If unspecified, whitespace will be cropped from logos and clear art.
         * @param {boolean} [addPlayedIndicator] Optional. Add a played indicator.
         * @param {number} [blur] Optional. Blur image.
         * @param {string} [backgroundColor] Optional. Apply a background color for transparent images.
         * @param {string} [foregroundLayer] Optional. Apply a foreground layer on top of the image.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGenreImage(name: string, imageType: ImageType, imageIndex: number, tag?: string, format?: ImageFormat, maxWidth?: number, maxHeight?: number, percentPlayed?: number, unplayedCount?: number, width?: number, height?: number, quality?: number, cropWhitespace?: boolean, addPlayedIndicator?: boolean, blur?: number, backgroundColor?: string, foregroundLayer?: string, options?: any): AxiosPromise<any> {
            return ImageApiFp(configuration).getGenreImage(name, imageType, imageIndex, tag, format, maxWidth, maxHeight, percentPlayed, unplayedCount, width, height, quality, cropWhitespace, addPlayedIndicator, blur, backgroundColor, foregroundLayer, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets the item\'s image.
         * @param {string} itemId Item id.
         * @param {ImageType} imageType Image type.
         * @param {number} imageIndex Image index.
         * @param {number} [maxWidth] The maximum image width to return.
         * @param {number} [maxHeight] The maximum image height to return.
         * @param {number} [width] The fixed image width to return.
         * @param {number} [height] The fixed image height to return.
         * @param {number} [quality] Optional. Quality setting, from 0-100. Defaults to 90 and should suffice in most cases.
         * @param {string} [tag] Optional. Supply the cache tag from the item object to receive strong caching headers.
         * @param {boolean} [cropWhitespace] Optional. Specify if whitespace should be cropped out of the image. True/False. If unspecified, whitespace will be cropped from logos and clear art.
         * @param {ImageFormat} [format] Optional. The MediaBrowser.Model.Drawing.ImageFormat of the returned image.
         * @param {boolean} [addPlayedIndicator] Optional. Add a played indicator.
         * @param {number} [percentPlayed] Optional. Percent to render for the percent played overlay.
         * @param {number} [unplayedCount] Optional. Unplayed count overlay to render.
         * @param {number} [blur] Optional. Blur image.
         * @param {string} [backgroundColor] Optional. Apply a background color for transparent images.
         * @param {string} [foregroundLayer] Optional. Apply a foreground layer on top of the image.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getItemImage(itemId: string, imageType: ImageType, imageIndex: number, maxWidth?: number, maxHeight?: number, width?: number, height?: number, quality?: number, tag?: string, cropWhitespace?: boolean, format?: ImageFormat, addPlayedIndicator?: boolean, percentPlayed?: number, unplayedCount?: number, blur?: number, backgroundColor?: string, foregroundLayer?: string, options?: any): AxiosPromise<any> {
            return ImageApiFp(configuration).getItemImage(itemId, imageType, imageIndex, maxWidth, maxHeight, width, height, quality, tag, cropWhitespace, format, addPlayedIndicator, percentPlayed, unplayedCount, blur, backgroundColor, foregroundLayer, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets the item\'s image.
         * @param {string} itemId Item id.
         * @param {ImageType} imageType Image type.
         * @param {number} imageIndex Image index.
         * @param {number} [maxWidth] The maximum image width to return.
         * @param {number} [maxHeight] The maximum image height to return.
         * @param {number} [width] The fixed image width to return.
         * @param {number} [height] The fixed image height to return.
         * @param {number} [quality] Optional. Quality setting, from 0-100. Defaults to 90 and should suffice in most cases.
         * @param {string} [tag] Optional. Supply the cache tag from the item object to receive strong caching headers.
         * @param {boolean} [cropWhitespace] Optional. Specify if whitespace should be cropped out of the image. True/False. If unspecified, whitespace will be cropped from logos and clear art.
         * @param {ImageFormat} [format] Optional. The MediaBrowser.Model.Drawing.ImageFormat of the returned image.
         * @param {boolean} [addPlayedIndicator] Optional. Add a played indicator.
         * @param {number} [percentPlayed] Optional. Percent to render for the percent played overlay.
         * @param {number} [unplayedCount] Optional. Unplayed count overlay to render.
         * @param {number} [blur] Optional. Blur image.
         * @param {string} [backgroundColor] Optional. Apply a background color for transparent images.
         * @param {string} [foregroundLayer] Optional. Apply a foreground layer on top of the image.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getItemImage2(itemId: string, imageType: ImageType, imageIndex: number, maxWidth?: number, maxHeight?: number, width?: number, height?: number, quality?: number, tag?: string, cropWhitespace?: boolean, format?: ImageFormat, addPlayedIndicator?: boolean, percentPlayed?: number, unplayedCount?: number, blur?: number, backgroundColor?: string, foregroundLayer?: string, options?: any): AxiosPromise<any> {
            return ImageApiFp(configuration).getItemImage2(itemId, imageType, imageIndex, maxWidth, maxHeight, width, height, quality, tag, cropWhitespace, format, addPlayedIndicator, percentPlayed, unplayedCount, blur, backgroundColor, foregroundLayer, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets the item\'s image.
         * @param {string} itemId Item id.
         * @param {ImageType} imageType Image type.
         * @param {number} maxWidth The maximum image width to return.
         * @param {number} maxHeight The maximum image height to return.
         * @param {string} tag Optional. Supply the cache tag from the item object to receive strong caching headers.
         * @param {ImageFormat} format Determines the output format of the image - original,gif,jpg,png.
         * @param {number} percentPlayed Optional. Percent to render for the percent played overlay.
         * @param {number} unplayedCount Optional. Unplayed count overlay to render.
         * @param {number} imageIndex Image index.
         * @param {number} [width] The fixed image width to return.
         * @param {number} [height] The fixed image height to return.
         * @param {number} [quality] Optional. Quality setting, from 0-100. Defaults to 90 and should suffice in most cases.
         * @param {boolean} [cropWhitespace] Optional. Specify if whitespace should be cropped out of the image. True/False. If unspecified, whitespace will be cropped from logos and clear art.
         * @param {boolean} [addPlayedIndicator] Optional. Add a played indicator.
         * @param {number} [blur] Optional. Blur image.
         * @param {string} [backgroundColor] Optional. Apply a background color for transparent images.
         * @param {string} [foregroundLayer] Optional. Apply a foreground layer on top of the image.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getItemImage2_1(itemId: string, imageType: ImageType, maxWidth: number, maxHeight: number, tag: string, format: ImageFormat, percentPlayed: number, unplayedCount: number, imageIndex: number, width?: number, height?: number, quality?: number, cropWhitespace?: boolean, addPlayedIndicator?: boolean, blur?: number, backgroundColor?: string, foregroundLayer?: string, options?: any): AxiosPromise<any> {
            return ImageApiFp(configuration).getItemImage2_1(itemId, imageType, maxWidth, maxHeight, tag, format, percentPlayed, unplayedCount, imageIndex, width, height, quality, cropWhitespace, addPlayedIndicator, blur, backgroundColor, foregroundLayer, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get item image infos.
         * @param {string} itemId Item id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getItemImageInfos(itemId: string, options?: any): AxiosPromise<Array<ImageInfo>> {
            return ImageApiFp(configuration).getItemImageInfos(itemId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get music genre image by name.
         * @param {string} name Music genre name.
         * @param {ImageType} imageType Image type.
         * @param {number} imageIndex Image index.
         * @param {string} [tag] Optional. Supply the cache tag from the item object to receive strong caching headers.
         * @param {ImageFormat} [format] Determines the output format of the image - original,gif,jpg,png.
         * @param {number} [maxWidth] The maximum image width to return.
         * @param {number} [maxHeight] The maximum image height to return.
         * @param {number} [percentPlayed] Optional. Percent to render for the percent played overlay.
         * @param {number} [unplayedCount] Optional. Unplayed count overlay to render.
         * @param {number} [width] The fixed image width to return.
         * @param {number} [height] The fixed image height to return.
         * @param {number} [quality] Optional. Quality setting, from 0-100. Defaults to 90 and should suffice in most cases.
         * @param {boolean} [cropWhitespace] Optional. Specify if whitespace should be cropped out of the image. True/False. If unspecified, whitespace will be cropped from logos and clear art.
         * @param {boolean} [addPlayedIndicator] Optional. Add a played indicator.
         * @param {number} [blur] Optional. Blur image.
         * @param {string} [backgroundColor] Optional. Apply a background color for transparent images.
         * @param {string} [foregroundLayer] Optional. Apply a foreground layer on top of the image.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMusicGenreImage(name: string, imageType: ImageType, imageIndex: number, tag?: string, format?: ImageFormat, maxWidth?: number, maxHeight?: number, percentPlayed?: number, unplayedCount?: number, width?: number, height?: number, quality?: number, cropWhitespace?: boolean, addPlayedIndicator?: boolean, blur?: number, backgroundColor?: string, foregroundLayer?: string, options?: any): AxiosPromise<any> {
            return ImageApiFp(configuration).getMusicGenreImage(name, imageType, imageIndex, tag, format, maxWidth, maxHeight, percentPlayed, unplayedCount, width, height, quality, cropWhitespace, addPlayedIndicator, blur, backgroundColor, foregroundLayer, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get person image by name.
         * @param {string} name Person name.
         * @param {ImageType} imageType Image type.
         * @param {number} imageIndex Image index.
         * @param {string} [tag] Optional. Supply the cache tag from the item object to receive strong caching headers.
         * @param {ImageFormat} [format] Determines the output format of the image - original,gif,jpg,png.
         * @param {number} [maxWidth] The maximum image width to return.
         * @param {number} [maxHeight] The maximum image height to return.
         * @param {number} [percentPlayed] Optional. Percent to render for the percent played overlay.
         * @param {number} [unplayedCount] Optional. Unplayed count overlay to render.
         * @param {number} [width] The fixed image width to return.
         * @param {number} [height] The fixed image height to return.
         * @param {number} [quality] Optional. Quality setting, from 0-100. Defaults to 90 and should suffice in most cases.
         * @param {boolean} [cropWhitespace] Optional. Specify if whitespace should be cropped out of the image. True/False. If unspecified, whitespace will be cropped from logos and clear art.
         * @param {boolean} [addPlayedIndicator] Optional. Add a played indicator.
         * @param {number} [blur] Optional. Blur image.
         * @param {string} [backgroundColor] Optional. Apply a background color for transparent images.
         * @param {string} [foregroundLayer] Optional. Apply a foreground layer on top of the image.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPersonImage(name: string, imageType: ImageType, imageIndex: number, tag?: string, format?: ImageFormat, maxWidth?: number, maxHeight?: number, percentPlayed?: number, unplayedCount?: number, width?: number, height?: number, quality?: number, cropWhitespace?: boolean, addPlayedIndicator?: boolean, blur?: number, backgroundColor?: string, foregroundLayer?: string, options?: any): AxiosPromise<any> {
            return ImageApiFp(configuration).getPersonImage(name, imageType, imageIndex, tag, format, maxWidth, maxHeight, percentPlayed, unplayedCount, width, height, quality, cropWhitespace, addPlayedIndicator, blur, backgroundColor, foregroundLayer, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get studio image by name.
         * @param {string} name Studio name.
         * @param {ImageType} imageType Image type.
         * @param {string} tag Optional. Supply the cache tag from the item object to receive strong caching headers.
         * @param {ImageFormat} format Determines the output format of the image - original,gif,jpg,png.
         * @param {number} imageIndex Image index.
         * @param {number} [maxWidth] The maximum image width to return.
         * @param {number} [maxHeight] The maximum image height to return.
         * @param {number} [percentPlayed] Optional. Percent to render for the percent played overlay.
         * @param {number} [unplayedCount] Optional. Unplayed count overlay to render.
         * @param {number} [width] The fixed image width to return.
         * @param {number} [height] The fixed image height to return.
         * @param {number} [quality] Optional. Quality setting, from 0-100. Defaults to 90 and should suffice in most cases.
         * @param {boolean} [cropWhitespace] Optional. Specify if whitespace should be cropped out of the image. True/False. If unspecified, whitespace will be cropped from logos and clear art.
         * @param {boolean} [addPlayedIndicator] Optional. Add a played indicator.
         * @param {number} [blur] Optional. Blur image.
         * @param {string} [backgroundColor] Optional. Apply a background color for transparent images.
         * @param {string} [foregroundLayer] Optional. Apply a foreground layer on top of the image.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStudioImage(name: string, imageType: ImageType, tag: string, format: ImageFormat, imageIndex: number, maxWidth?: number, maxHeight?: number, percentPlayed?: number, unplayedCount?: number, width?: number, height?: number, quality?: number, cropWhitespace?: boolean, addPlayedIndicator?: boolean, blur?: number, backgroundColor?: string, foregroundLayer?: string, options?: any): AxiosPromise<any> {
            return ImageApiFp(configuration).getStudioImage(name, imageType, tag, format, imageIndex, maxWidth, maxHeight, percentPlayed, unplayedCount, width, height, quality, cropWhitespace, addPlayedIndicator, blur, backgroundColor, foregroundLayer, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get user profile image.
         * @param {string} userId User id.
         * @param {ImageType} imageType Image type.
         * @param {number} imageIndex Image index.
         * @param {string} [tag] Optional. Supply the cache tag from the item object to receive strong caching headers.
         * @param {ImageFormat} [format] Determines the output format of the image - original,gif,jpg,png.
         * @param {number} [maxWidth] The maximum image width to return.
         * @param {number} [maxHeight] The maximum image height to return.
         * @param {number} [percentPlayed] Optional. Percent to render for the percent played overlay.
         * @param {number} [unplayedCount] Optional. Unplayed count overlay to render.
         * @param {number} [width] The fixed image width to return.
         * @param {number} [height] The fixed image height to return.
         * @param {number} [quality] Optional. Quality setting, from 0-100. Defaults to 90 and should suffice in most cases.
         * @param {boolean} [cropWhitespace] Optional. Specify if whitespace should be cropped out of the image. True/False. If unspecified, whitespace will be cropped from logos and clear art.
         * @param {boolean} [addPlayedIndicator] Optional. Add a played indicator.
         * @param {number} [blur] Optional. Blur image.
         * @param {string} [backgroundColor] Optional. Apply a background color for transparent images.
         * @param {string} [foregroundLayer] Optional. Apply a foreground layer on top of the image.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserImage(userId: string, imageType: ImageType, imageIndex: number, tag?: string, format?: ImageFormat, maxWidth?: number, maxHeight?: number, percentPlayed?: number, unplayedCount?: number, width?: number, height?: number, quality?: number, cropWhitespace?: boolean, addPlayedIndicator?: boolean, blur?: number, backgroundColor?: string, foregroundLayer?: string, options?: any): AxiosPromise<any> {
            return ImageApiFp(configuration).getUserImage(userId, imageType, imageIndex, tag, format, maxWidth, maxHeight, percentPlayed, unplayedCount, width, height, quality, cropWhitespace, addPlayedIndicator, blur, backgroundColor, foregroundLayer, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get artist image by name.
         * @param {string} name Artist name.
         * @param {ImageType} imageType Image type.
         * @param {number} imageIndex Image index.
         * @param {string} [tag] Optional. Supply the cache tag from the item object to receive strong caching headers.
         * @param {ImageFormat} [format] Determines the output format of the image - original,gif,jpg,png.
         * @param {number} [maxWidth] The maximum image width to return.
         * @param {number} [maxHeight] The maximum image height to return.
         * @param {number} [percentPlayed] Optional. Percent to render for the percent played overlay.
         * @param {number} [unplayedCount] Optional. Unplayed count overlay to render.
         * @param {number} [width] The fixed image width to return.
         * @param {number} [height] The fixed image height to return.
         * @param {number} [quality] Optional. Quality setting, from 0-100. Defaults to 90 and should suffice in most cases.
         * @param {boolean} [cropWhitespace] Optional. Specify if whitespace should be cropped out of the image. True/False. If unspecified, whitespace will be cropped from logos and clear art.
         * @param {boolean} [addPlayedIndicator] Optional. Add a played indicator.
         * @param {number} [blur] Optional. Blur image.
         * @param {string} [backgroundColor] Optional. Apply a background color for transparent images.
         * @param {string} [foregroundLayer] Optional. Apply a foreground layer on top of the image.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        headArtistImage(name: string, imageType: ImageType, imageIndex: number, tag?: string, format?: ImageFormat, maxWidth?: number, maxHeight?: number, percentPlayed?: number, unplayedCount?: number, width?: number, height?: number, quality?: number, cropWhitespace?: boolean, addPlayedIndicator?: boolean, blur?: number, backgroundColor?: string, foregroundLayer?: string, options?: any): AxiosPromise<any> {
            return ImageApiFp(configuration).headArtistImage(name, imageType, imageIndex, tag, format, maxWidth, maxHeight, percentPlayed, unplayedCount, width, height, quality, cropWhitespace, addPlayedIndicator, blur, backgroundColor, foregroundLayer, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get genre image by name.
         * @param {string} name Genre name.
         * @param {ImageType} imageType Image type.
         * @param {number} imageIndex Image index.
         * @param {string} [tag] Optional. Supply the cache tag from the item object to receive strong caching headers.
         * @param {ImageFormat} [format] Determines the output format of the image - original,gif,jpg,png.
         * @param {number} [maxWidth] The maximum image width to return.
         * @param {number} [maxHeight] The maximum image height to return.
         * @param {number} [percentPlayed] Optional. Percent to render for the percent played overlay.
         * @param {number} [unplayedCount] Optional. Unplayed count overlay to render.
         * @param {number} [width] The fixed image width to return.
         * @param {number} [height] The fixed image height to return.
         * @param {number} [quality] Optional. Quality setting, from 0-100. Defaults to 90 and should suffice in most cases.
         * @param {boolean} [cropWhitespace] Optional. Specify if whitespace should be cropped out of the image. True/False. If unspecified, whitespace will be cropped from logos and clear art.
         * @param {boolean} [addPlayedIndicator] Optional. Add a played indicator.
         * @param {number} [blur] Optional. Blur image.
         * @param {string} [backgroundColor] Optional. Apply a background color for transparent images.
         * @param {string} [foregroundLayer] Optional. Apply a foreground layer on top of the image.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        headGenreImage(name: string, imageType: ImageType, imageIndex: number, tag?: string, format?: ImageFormat, maxWidth?: number, maxHeight?: number, percentPlayed?: number, unplayedCount?: number, width?: number, height?: number, quality?: number, cropWhitespace?: boolean, addPlayedIndicator?: boolean, blur?: number, backgroundColor?: string, foregroundLayer?: string, options?: any): AxiosPromise<any> {
            return ImageApiFp(configuration).headGenreImage(name, imageType, imageIndex, tag, format, maxWidth, maxHeight, percentPlayed, unplayedCount, width, height, quality, cropWhitespace, addPlayedIndicator, blur, backgroundColor, foregroundLayer, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets the item\'s image.
         * @param {string} itemId Item id.
         * @param {ImageType} imageType Image type.
         * @param {number} imageIndex Image index.
         * @param {number} [maxWidth] The maximum image width to return.
         * @param {number} [maxHeight] The maximum image height to return.
         * @param {number} [width] The fixed image width to return.
         * @param {number} [height] The fixed image height to return.
         * @param {number} [quality] Optional. Quality setting, from 0-100. Defaults to 90 and should suffice in most cases.
         * @param {string} [tag] Optional. Supply the cache tag from the item object to receive strong caching headers.
         * @param {boolean} [cropWhitespace] Optional. Specify if whitespace should be cropped out of the image. True/False. If unspecified, whitespace will be cropped from logos and clear art.
         * @param {ImageFormat} [format] Optional. The MediaBrowser.Model.Drawing.ImageFormat of the returned image.
         * @param {boolean} [addPlayedIndicator] Optional. Add a played indicator.
         * @param {number} [percentPlayed] Optional. Percent to render for the percent played overlay.
         * @param {number} [unplayedCount] Optional. Unplayed count overlay to render.
         * @param {number} [blur] Optional. Blur image.
         * @param {string} [backgroundColor] Optional. Apply a background color for transparent images.
         * @param {string} [foregroundLayer] Optional. Apply a foreground layer on top of the image.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        headItemImage(itemId: string, imageType: ImageType, imageIndex: number, maxWidth?: number, maxHeight?: number, width?: number, height?: number, quality?: number, tag?: string, cropWhitespace?: boolean, format?: ImageFormat, addPlayedIndicator?: boolean, percentPlayed?: number, unplayedCount?: number, blur?: number, backgroundColor?: string, foregroundLayer?: string, options?: any): AxiosPromise<any> {
            return ImageApiFp(configuration).headItemImage(itemId, imageType, imageIndex, maxWidth, maxHeight, width, height, quality, tag, cropWhitespace, format, addPlayedIndicator, percentPlayed, unplayedCount, blur, backgroundColor, foregroundLayer, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets the item\'s image.
         * @param {string} itemId Item id.
         * @param {ImageType} imageType Image type.
         * @param {number} imageIndex Image index.
         * @param {number} [maxWidth] The maximum image width to return.
         * @param {number} [maxHeight] The maximum image height to return.
         * @param {number} [width] The fixed image width to return.
         * @param {number} [height] The fixed image height to return.
         * @param {number} [quality] Optional. Quality setting, from 0-100. Defaults to 90 and should suffice in most cases.
         * @param {string} [tag] Optional. Supply the cache tag from the item object to receive strong caching headers.
         * @param {boolean} [cropWhitespace] Optional. Specify if whitespace should be cropped out of the image. True/False. If unspecified, whitespace will be cropped from logos and clear art.
         * @param {ImageFormat} [format] Optional. The MediaBrowser.Model.Drawing.ImageFormat of the returned image.
         * @param {boolean} [addPlayedIndicator] Optional. Add a played indicator.
         * @param {number} [percentPlayed] Optional. Percent to render for the percent played overlay.
         * @param {number} [unplayedCount] Optional. Unplayed count overlay to render.
         * @param {number} [blur] Optional. Blur image.
         * @param {string} [backgroundColor] Optional. Apply a background color for transparent images.
         * @param {string} [foregroundLayer] Optional. Apply a foreground layer on top of the image.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        headItemImage2(itemId: string, imageType: ImageType, imageIndex: number, maxWidth?: number, maxHeight?: number, width?: number, height?: number, quality?: number, tag?: string, cropWhitespace?: boolean, format?: ImageFormat, addPlayedIndicator?: boolean, percentPlayed?: number, unplayedCount?: number, blur?: number, backgroundColor?: string, foregroundLayer?: string, options?: any): AxiosPromise<any> {
            return ImageApiFp(configuration).headItemImage2(itemId, imageType, imageIndex, maxWidth, maxHeight, width, height, quality, tag, cropWhitespace, format, addPlayedIndicator, percentPlayed, unplayedCount, blur, backgroundColor, foregroundLayer, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets the item\'s image.
         * @param {string} itemId Item id.
         * @param {ImageType} imageType Image type.
         * @param {number} maxWidth The maximum image width to return.
         * @param {number} maxHeight The maximum image height to return.
         * @param {string} tag Optional. Supply the cache tag from the item object to receive strong caching headers.
         * @param {ImageFormat} format Determines the output format of the image - original,gif,jpg,png.
         * @param {number} percentPlayed Optional. Percent to render for the percent played overlay.
         * @param {number} unplayedCount Optional. Unplayed count overlay to render.
         * @param {number} imageIndex Image index.
         * @param {number} [width] The fixed image width to return.
         * @param {number} [height] The fixed image height to return.
         * @param {number} [quality] Optional. Quality setting, from 0-100. Defaults to 90 and should suffice in most cases.
         * @param {boolean} [cropWhitespace] Optional. Specify if whitespace should be cropped out of the image. True/False. If unspecified, whitespace will be cropped from logos and clear art.
         * @param {boolean} [addPlayedIndicator] Optional. Add a played indicator.
         * @param {number} [blur] Optional. Blur image.
         * @param {string} [backgroundColor] Optional. Apply a background color for transparent images.
         * @param {string} [foregroundLayer] Optional. Apply a foreground layer on top of the image.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        headItemImage2_2(itemId: string, imageType: ImageType, maxWidth: number, maxHeight: number, tag: string, format: ImageFormat, percentPlayed: number, unplayedCount: number, imageIndex: number, width?: number, height?: number, quality?: number, cropWhitespace?: boolean, addPlayedIndicator?: boolean, blur?: number, backgroundColor?: string, foregroundLayer?: string, options?: any): AxiosPromise<any> {
            return ImageApiFp(configuration).headItemImage2_2(itemId, imageType, maxWidth, maxHeight, tag, format, percentPlayed, unplayedCount, imageIndex, width, height, quality, cropWhitespace, addPlayedIndicator, blur, backgroundColor, foregroundLayer, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get music genre image by name.
         * @param {string} name Music genre name.
         * @param {ImageType} imageType Image type.
         * @param {number} imageIndex Image index.
         * @param {string} [tag] Optional. Supply the cache tag from the item object to receive strong caching headers.
         * @param {ImageFormat} [format] Determines the output format of the image - original,gif,jpg,png.
         * @param {number} [maxWidth] The maximum image width to return.
         * @param {number} [maxHeight] The maximum image height to return.
         * @param {number} [percentPlayed] Optional. Percent to render for the percent played overlay.
         * @param {number} [unplayedCount] Optional. Unplayed count overlay to render.
         * @param {number} [width] The fixed image width to return.
         * @param {number} [height] The fixed image height to return.
         * @param {number} [quality] Optional. Quality setting, from 0-100. Defaults to 90 and should suffice in most cases.
         * @param {boolean} [cropWhitespace] Optional. Specify if whitespace should be cropped out of the image. True/False. If unspecified, whitespace will be cropped from logos and clear art.
         * @param {boolean} [addPlayedIndicator] Optional. Add a played indicator.
         * @param {number} [blur] Optional. Blur image.
         * @param {string} [backgroundColor] Optional. Apply a background color for transparent images.
         * @param {string} [foregroundLayer] Optional. Apply a foreground layer on top of the image.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        headMusicGenreImage(name: string, imageType: ImageType, imageIndex: number, tag?: string, format?: ImageFormat, maxWidth?: number, maxHeight?: number, percentPlayed?: number, unplayedCount?: number, width?: number, height?: number, quality?: number, cropWhitespace?: boolean, addPlayedIndicator?: boolean, blur?: number, backgroundColor?: string, foregroundLayer?: string, options?: any): AxiosPromise<any> {
            return ImageApiFp(configuration).headMusicGenreImage(name, imageType, imageIndex, tag, format, maxWidth, maxHeight, percentPlayed, unplayedCount, width, height, quality, cropWhitespace, addPlayedIndicator, blur, backgroundColor, foregroundLayer, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get person image by name.
         * @param {string} name Person name.
         * @param {ImageType} imageType Image type.
         * @param {number} imageIndex Image index.
         * @param {string} [tag] Optional. Supply the cache tag from the item object to receive strong caching headers.
         * @param {ImageFormat} [format] Determines the output format of the image - original,gif,jpg,png.
         * @param {number} [maxWidth] The maximum image width to return.
         * @param {number} [maxHeight] The maximum image height to return.
         * @param {number} [percentPlayed] Optional. Percent to render for the percent played overlay.
         * @param {number} [unplayedCount] Optional. Unplayed count overlay to render.
         * @param {number} [width] The fixed image width to return.
         * @param {number} [height] The fixed image height to return.
         * @param {number} [quality] Optional. Quality setting, from 0-100. Defaults to 90 and should suffice in most cases.
         * @param {boolean} [cropWhitespace] Optional. Specify if whitespace should be cropped out of the image. True/False. If unspecified, whitespace will be cropped from logos and clear art.
         * @param {boolean} [addPlayedIndicator] Optional. Add a played indicator.
         * @param {number} [blur] Optional. Blur image.
         * @param {string} [backgroundColor] Optional. Apply a background color for transparent images.
         * @param {string} [foregroundLayer] Optional. Apply a foreground layer on top of the image.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        headPersonImage(name: string, imageType: ImageType, imageIndex: number, tag?: string, format?: ImageFormat, maxWidth?: number, maxHeight?: number, percentPlayed?: number, unplayedCount?: number, width?: number, height?: number, quality?: number, cropWhitespace?: boolean, addPlayedIndicator?: boolean, blur?: number, backgroundColor?: string, foregroundLayer?: string, options?: any): AxiosPromise<any> {
            return ImageApiFp(configuration).headPersonImage(name, imageType, imageIndex, tag, format, maxWidth, maxHeight, percentPlayed, unplayedCount, width, height, quality, cropWhitespace, addPlayedIndicator, blur, backgroundColor, foregroundLayer, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get studio image by name.
         * @param {string} name Studio name.
         * @param {ImageType} imageType Image type.
         * @param {string} tag Optional. Supply the cache tag from the item object to receive strong caching headers.
         * @param {ImageFormat} format Determines the output format of the image - original,gif,jpg,png.
         * @param {number} imageIndex Image index.
         * @param {number} [maxWidth] The maximum image width to return.
         * @param {number} [maxHeight] The maximum image height to return.
         * @param {number} [percentPlayed] Optional. Percent to render for the percent played overlay.
         * @param {number} [unplayedCount] Optional. Unplayed count overlay to render.
         * @param {number} [width] The fixed image width to return.
         * @param {number} [height] The fixed image height to return.
         * @param {number} [quality] Optional. Quality setting, from 0-100. Defaults to 90 and should suffice in most cases.
         * @param {boolean} [cropWhitespace] Optional. Specify if whitespace should be cropped out of the image. True/False. If unspecified, whitespace will be cropped from logos and clear art.
         * @param {boolean} [addPlayedIndicator] Optional. Add a played indicator.
         * @param {number} [blur] Optional. Blur image.
         * @param {string} [backgroundColor] Optional. Apply a background color for transparent images.
         * @param {string} [foregroundLayer] Optional. Apply a foreground layer on top of the image.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        headStudioImage(name: string, imageType: ImageType, tag: string, format: ImageFormat, imageIndex: number, maxWidth?: number, maxHeight?: number, percentPlayed?: number, unplayedCount?: number, width?: number, height?: number, quality?: number, cropWhitespace?: boolean, addPlayedIndicator?: boolean, blur?: number, backgroundColor?: string, foregroundLayer?: string, options?: any): AxiosPromise<any> {
            return ImageApiFp(configuration).headStudioImage(name, imageType, tag, format, imageIndex, maxWidth, maxHeight, percentPlayed, unplayedCount, width, height, quality, cropWhitespace, addPlayedIndicator, blur, backgroundColor, foregroundLayer, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get user profile image.
         * @param {string} userId User id.
         * @param {ImageType} imageType Image type.
         * @param {number} imageIndex Image index.
         * @param {string} [tag] Optional. Supply the cache tag from the item object to receive strong caching headers.
         * @param {ImageFormat} [format] Determines the output format of the image - original,gif,jpg,png.
         * @param {number} [maxWidth] The maximum image width to return.
         * @param {number} [maxHeight] The maximum image height to return.
         * @param {number} [percentPlayed] Optional. Percent to render for the percent played overlay.
         * @param {number} [unplayedCount] Optional. Unplayed count overlay to render.
         * @param {number} [width] The fixed image width to return.
         * @param {number} [height] The fixed image height to return.
         * @param {number} [quality] Optional. Quality setting, from 0-100. Defaults to 90 and should suffice in most cases.
         * @param {boolean} [cropWhitespace] Optional. Specify if whitespace should be cropped out of the image. True/False. If unspecified, whitespace will be cropped from logos and clear art.
         * @param {boolean} [addPlayedIndicator] Optional. Add a played indicator.
         * @param {number} [blur] Optional. Blur image.
         * @param {string} [backgroundColor] Optional. Apply a background color for transparent images.
         * @param {string} [foregroundLayer] Optional. Apply a foreground layer on top of the image.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        headUserImage(userId: string, imageType: ImageType, imageIndex: number, tag?: string, format?: ImageFormat, maxWidth?: number, maxHeight?: number, percentPlayed?: number, unplayedCount?: number, width?: number, height?: number, quality?: number, cropWhitespace?: boolean, addPlayedIndicator?: boolean, blur?: number, backgroundColor?: string, foregroundLayer?: string, options?: any): AxiosPromise<any> {
            return ImageApiFp(configuration).headUserImage(userId, imageType, imageIndex, tag, format, maxWidth, maxHeight, percentPlayed, unplayedCount, width, height, quality, cropWhitespace, addPlayedIndicator, blur, backgroundColor, foregroundLayer, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Sets the user image.
         * @param {string} userId User Id.
         * @param {ImageType} imageType (Unused) Image type.
         * @param {number} index (Unused) Image index.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postUserImage(userId: string, imageType: ImageType, index: number, options?: any): AxiosPromise<void> {
            return ImageApiFp(configuration).postUserImage(userId, imageType, index, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Sets the user image.
         * @param {string} userId User Id.
         * @param {ImageType} imageType (Unused) Image type.
         * @param {number} index (Unused) Image index.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postUserImage2(userId: string, imageType: ImageType, index: number, options?: any): AxiosPromise<void> {
            return ImageApiFp(configuration).postUserImage2(userId, imageType, index, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Set item image.
         * @param {string} itemId Item id.
         * @param {ImageType} imageType Image type.
         * @param {number} imageIndex (Unused) Image index.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setItemImage(itemId: string, imageType: ImageType, imageIndex: number, options?: any): AxiosPromise<void> {
            return ImageApiFp(configuration).setItemImage(itemId, imageType, imageIndex, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Set item image.
         * @param {string} itemId Item id.
         * @param {ImageType} imageType Image type.
         * @param {number} imageIndex (Unused) Image index.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setItemImage2(itemId: string, imageType: ImageType, imageIndex: number, options?: any): AxiosPromise<void> {
            return ImageApiFp(configuration).setItemImage2(itemId, imageType, imageIndex, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates the index for an item image.
         * @param {string} itemId Item id.
         * @param {ImageType} imageType Image type.
         * @param {number} imageIndex Old image index.
         * @param {number} [newIndex] New image index.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateItemImageIndex(itemId: string, imageType: ImageType, imageIndex: number, newIndex?: number, options?: any): AxiosPromise<void> {
            return ImageApiFp(configuration).updateItemImageIndex(itemId, imageType, imageIndex, newIndex, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for deleteItemImage operation in ImageApi.
 * @export
 * @interface ImageApiDeleteItemImageRequest
 */
export interface ImageApiDeleteItemImageRequest {
    /**
     * Item id.
     * @type {string}
     * @memberof ImageApiDeleteItemImage
     */
    readonly itemId: string

    /**
     * Image type.
     * @type {ImageType}
     * @memberof ImageApiDeleteItemImage
     */
    readonly imageType: ImageType

    /**
     * The image index.
     * @type {number}
     * @memberof ImageApiDeleteItemImage
     */
    readonly imageIndex: number
}

/**
 * Request parameters for deleteItemImage2 operation in ImageApi.
 * @export
 * @interface ImageApiDeleteItemImage2Request
 */
export interface ImageApiDeleteItemImage2Request {
    /**
     * Item id.
     * @type {string}
     * @memberof ImageApiDeleteItemImage2
     */
    readonly itemId: string

    /**
     * Image type.
     * @type {ImageType}
     * @memberof ImageApiDeleteItemImage2
     */
    readonly imageType: ImageType

    /**
     * The image index.
     * @type {number}
     * @memberof ImageApiDeleteItemImage2
     */
    readonly imageIndex: number
}

/**
 * Request parameters for deleteUserImage operation in ImageApi.
 * @export
 * @interface ImageApiDeleteUserImageRequest
 */
export interface ImageApiDeleteUserImageRequest {
    /**
     * User Id.
     * @type {string}
     * @memberof ImageApiDeleteUserImage
     */
    readonly userId: string

    /**
     * (Unused) Image type.
     * @type {ImageType}
     * @memberof ImageApiDeleteUserImage
     */
    readonly imageType: ImageType

    /**
     * (Unused) Image index.
     * @type {number}
     * @memberof ImageApiDeleteUserImage
     */
    readonly index: number

    /**
     * 
     * @type {string}
     * @memberof ImageApiDeleteUserImage
     */
    readonly itemType: string
}

/**
 * Request parameters for deleteUserImage2 operation in ImageApi.
 * @export
 * @interface ImageApiDeleteUserImage2Request
 */
export interface ImageApiDeleteUserImage2Request {
    /**
     * User Id.
     * @type {string}
     * @memberof ImageApiDeleteUserImage2
     */
    readonly userId: string

    /**
     * (Unused) Image type.
     * @type {ImageType}
     * @memberof ImageApiDeleteUserImage2
     */
    readonly imageType: ImageType

    /**
     * (Unused) Image index.
     * @type {number}
     * @memberof ImageApiDeleteUserImage2
     */
    readonly index: number

    /**
     * 
     * @type {string}
     * @memberof ImageApiDeleteUserImage2
     */
    readonly itemType: string
}

/**
 * Request parameters for getArtistImage operation in ImageApi.
 * @export
 * @interface ImageApiGetArtistImageRequest
 */
export interface ImageApiGetArtistImageRequest {
    /**
     * Artist name.
     * @type {string}
     * @memberof ImageApiGetArtistImage
     */
    readonly name: string

    /**
     * Image type.
     * @type {ImageType}
     * @memberof ImageApiGetArtistImage
     */
    readonly imageType: ImageType

    /**
     * Image index.
     * @type {number}
     * @memberof ImageApiGetArtistImage
     */
    readonly imageIndex: number

    /**
     * Optional. Supply the cache tag from the item object to receive strong caching headers.
     * @type {string}
     * @memberof ImageApiGetArtistImage
     */
    readonly tag?: string

    /**
     * Determines the output format of the image - original,gif,jpg,png.
     * @type {ImageFormat}
     * @memberof ImageApiGetArtistImage
     */
    readonly format?: ImageFormat

    /**
     * The maximum image width to return.
     * @type {number}
     * @memberof ImageApiGetArtistImage
     */
    readonly maxWidth?: number

    /**
     * The maximum image height to return.
     * @type {number}
     * @memberof ImageApiGetArtistImage
     */
    readonly maxHeight?: number

    /**
     * Optional. Percent to render for the percent played overlay.
     * @type {number}
     * @memberof ImageApiGetArtistImage
     */
    readonly percentPlayed?: number

    /**
     * Optional. Unplayed count overlay to render.
     * @type {number}
     * @memberof ImageApiGetArtistImage
     */
    readonly unplayedCount?: number

    /**
     * The fixed image width to return.
     * @type {number}
     * @memberof ImageApiGetArtistImage
     */
    readonly width?: number

    /**
     * The fixed image height to return.
     * @type {number}
     * @memberof ImageApiGetArtistImage
     */
    readonly height?: number

    /**
     * Optional. Quality setting, from 0-100. Defaults to 90 and should suffice in most cases.
     * @type {number}
     * @memberof ImageApiGetArtistImage
     */
    readonly quality?: number

    /**
     * Optional. Specify if whitespace should be cropped out of the image. True/False. If unspecified, whitespace will be cropped from logos and clear art.
     * @type {boolean}
     * @memberof ImageApiGetArtistImage
     */
    readonly cropWhitespace?: boolean

    /**
     * Optional. Add a played indicator.
     * @type {boolean}
     * @memberof ImageApiGetArtistImage
     */
    readonly addPlayedIndicator?: boolean

    /**
     * Optional. Blur image.
     * @type {number}
     * @memberof ImageApiGetArtistImage
     */
    readonly blur?: number

    /**
     * Optional. Apply a background color for transparent images.
     * @type {string}
     * @memberof ImageApiGetArtistImage
     */
    readonly backgroundColor?: string

    /**
     * Optional. Apply a foreground layer on top of the image.
     * @type {string}
     * @memberof ImageApiGetArtistImage
     */
    readonly foregroundLayer?: string
}

/**
 * Request parameters for getGenreImage operation in ImageApi.
 * @export
 * @interface ImageApiGetGenreImageRequest
 */
export interface ImageApiGetGenreImageRequest {
    /**
     * Genre name.
     * @type {string}
     * @memberof ImageApiGetGenreImage
     */
    readonly name: string

    /**
     * Image type.
     * @type {ImageType}
     * @memberof ImageApiGetGenreImage
     */
    readonly imageType: ImageType

    /**
     * Image index.
     * @type {number}
     * @memberof ImageApiGetGenreImage
     */
    readonly imageIndex: number

    /**
     * Optional. Supply the cache tag from the item object to receive strong caching headers.
     * @type {string}
     * @memberof ImageApiGetGenreImage
     */
    readonly tag?: string

    /**
     * Determines the output format of the image - original,gif,jpg,png.
     * @type {ImageFormat}
     * @memberof ImageApiGetGenreImage
     */
    readonly format?: ImageFormat

    /**
     * The maximum image width to return.
     * @type {number}
     * @memberof ImageApiGetGenreImage
     */
    readonly maxWidth?: number

    /**
     * The maximum image height to return.
     * @type {number}
     * @memberof ImageApiGetGenreImage
     */
    readonly maxHeight?: number

    /**
     * Optional. Percent to render for the percent played overlay.
     * @type {number}
     * @memberof ImageApiGetGenreImage
     */
    readonly percentPlayed?: number

    /**
     * Optional. Unplayed count overlay to render.
     * @type {number}
     * @memberof ImageApiGetGenreImage
     */
    readonly unplayedCount?: number

    /**
     * The fixed image width to return.
     * @type {number}
     * @memberof ImageApiGetGenreImage
     */
    readonly width?: number

    /**
     * The fixed image height to return.
     * @type {number}
     * @memberof ImageApiGetGenreImage
     */
    readonly height?: number

    /**
     * Optional. Quality setting, from 0-100. Defaults to 90 and should suffice in most cases.
     * @type {number}
     * @memberof ImageApiGetGenreImage
     */
    readonly quality?: number

    /**
     * Optional. Specify if whitespace should be cropped out of the image. True/False. If unspecified, whitespace will be cropped from logos and clear art.
     * @type {boolean}
     * @memberof ImageApiGetGenreImage
     */
    readonly cropWhitespace?: boolean

    /**
     * Optional. Add a played indicator.
     * @type {boolean}
     * @memberof ImageApiGetGenreImage
     */
    readonly addPlayedIndicator?: boolean

    /**
     * Optional. Blur image.
     * @type {number}
     * @memberof ImageApiGetGenreImage
     */
    readonly blur?: number

    /**
     * Optional. Apply a background color for transparent images.
     * @type {string}
     * @memberof ImageApiGetGenreImage
     */
    readonly backgroundColor?: string

    /**
     * Optional. Apply a foreground layer on top of the image.
     * @type {string}
     * @memberof ImageApiGetGenreImage
     */
    readonly foregroundLayer?: string
}

/**
 * Request parameters for getItemImage operation in ImageApi.
 * @export
 * @interface ImageApiGetItemImageRequest
 */
export interface ImageApiGetItemImageRequest {
    /**
     * Item id.
     * @type {string}
     * @memberof ImageApiGetItemImage
     */
    readonly itemId: string

    /**
     * Image type.
     * @type {ImageType}
     * @memberof ImageApiGetItemImage
     */
    readonly imageType: ImageType

    /**
     * Image index.
     * @type {number}
     * @memberof ImageApiGetItemImage
     */
    readonly imageIndex: number

    /**
     * The maximum image width to return.
     * @type {number}
     * @memberof ImageApiGetItemImage
     */
    readonly maxWidth?: number

    /**
     * The maximum image height to return.
     * @type {number}
     * @memberof ImageApiGetItemImage
     */
    readonly maxHeight?: number

    /**
     * The fixed image width to return.
     * @type {number}
     * @memberof ImageApiGetItemImage
     */
    readonly width?: number

    /**
     * The fixed image height to return.
     * @type {number}
     * @memberof ImageApiGetItemImage
     */
    readonly height?: number

    /**
     * Optional. Quality setting, from 0-100. Defaults to 90 and should suffice in most cases.
     * @type {number}
     * @memberof ImageApiGetItemImage
     */
    readonly quality?: number

    /**
     * Optional. Supply the cache tag from the item object to receive strong caching headers.
     * @type {string}
     * @memberof ImageApiGetItemImage
     */
    readonly tag?: string

    /**
     * Optional. Specify if whitespace should be cropped out of the image. True/False. If unspecified, whitespace will be cropped from logos and clear art.
     * @type {boolean}
     * @memberof ImageApiGetItemImage
     */
    readonly cropWhitespace?: boolean

    /**
     * Optional. The MediaBrowser.Model.Drawing.ImageFormat of the returned image.
     * @type {ImageFormat}
     * @memberof ImageApiGetItemImage
     */
    readonly format?: ImageFormat

    /**
     * Optional. Add a played indicator.
     * @type {boolean}
     * @memberof ImageApiGetItemImage
     */
    readonly addPlayedIndicator?: boolean

    /**
     * Optional. Percent to render for the percent played overlay.
     * @type {number}
     * @memberof ImageApiGetItemImage
     */
    readonly percentPlayed?: number

    /**
     * Optional. Unplayed count overlay to render.
     * @type {number}
     * @memberof ImageApiGetItemImage
     */
    readonly unplayedCount?: number

    /**
     * Optional. Blur image.
     * @type {number}
     * @memberof ImageApiGetItemImage
     */
    readonly blur?: number

    /**
     * Optional. Apply a background color for transparent images.
     * @type {string}
     * @memberof ImageApiGetItemImage
     */
    readonly backgroundColor?: string

    /**
     * Optional. Apply a foreground layer on top of the image.
     * @type {string}
     * @memberof ImageApiGetItemImage
     */
    readonly foregroundLayer?: string
}

/**
 * Request parameters for getItemImage2 operation in ImageApi.
 * @export
 * @interface ImageApiGetItemImage2Request
 */
export interface ImageApiGetItemImage2Request {
    /**
     * Item id.
     * @type {string}
     * @memberof ImageApiGetItemImage2
     */
    readonly itemId: string

    /**
     * Image type.
     * @type {ImageType}
     * @memberof ImageApiGetItemImage2
     */
    readonly imageType: ImageType

    /**
     * Image index.
     * @type {number}
     * @memberof ImageApiGetItemImage2
     */
    readonly imageIndex: number

    /**
     * The maximum image width to return.
     * @type {number}
     * @memberof ImageApiGetItemImage2
     */
    readonly maxWidth?: number

    /**
     * The maximum image height to return.
     * @type {number}
     * @memberof ImageApiGetItemImage2
     */
    readonly maxHeight?: number

    /**
     * The fixed image width to return.
     * @type {number}
     * @memberof ImageApiGetItemImage2
     */
    readonly width?: number

    /**
     * The fixed image height to return.
     * @type {number}
     * @memberof ImageApiGetItemImage2
     */
    readonly height?: number

    /**
     * Optional. Quality setting, from 0-100. Defaults to 90 and should suffice in most cases.
     * @type {number}
     * @memberof ImageApiGetItemImage2
     */
    readonly quality?: number

    /**
     * Optional. Supply the cache tag from the item object to receive strong caching headers.
     * @type {string}
     * @memberof ImageApiGetItemImage2
     */
    readonly tag?: string

    /**
     * Optional. Specify if whitespace should be cropped out of the image. True/False. If unspecified, whitespace will be cropped from logos and clear art.
     * @type {boolean}
     * @memberof ImageApiGetItemImage2
     */
    readonly cropWhitespace?: boolean

    /**
     * Optional. The MediaBrowser.Model.Drawing.ImageFormat of the returned image.
     * @type {ImageFormat}
     * @memberof ImageApiGetItemImage2
     */
    readonly format?: ImageFormat

    /**
     * Optional. Add a played indicator.
     * @type {boolean}
     * @memberof ImageApiGetItemImage2
     */
    readonly addPlayedIndicator?: boolean

    /**
     * Optional. Percent to render for the percent played overlay.
     * @type {number}
     * @memberof ImageApiGetItemImage2
     */
    readonly percentPlayed?: number

    /**
     * Optional. Unplayed count overlay to render.
     * @type {number}
     * @memberof ImageApiGetItemImage2
     */
    readonly unplayedCount?: number

    /**
     * Optional. Blur image.
     * @type {number}
     * @memberof ImageApiGetItemImage2
     */
    readonly blur?: number

    /**
     * Optional. Apply a background color for transparent images.
     * @type {string}
     * @memberof ImageApiGetItemImage2
     */
    readonly backgroundColor?: string

    /**
     * Optional. Apply a foreground layer on top of the image.
     * @type {string}
     * @memberof ImageApiGetItemImage2
     */
    readonly foregroundLayer?: string
}

/**
 * Request parameters for getItemImage2_1 operation in ImageApi.
 * @export
 * @interface ImageApiGetItemImage20Request
 */
export interface ImageApiGetItemImage20Request {
    /**
     * Item id.
     * @type {string}
     * @memberof ImageApiGetItemImage20
     */
    readonly itemId: string

    /**
     * Image type.
     * @type {ImageType}
     * @memberof ImageApiGetItemImage20
     */
    readonly imageType: ImageType

    /**
     * The maximum image width to return.
     * @type {number}
     * @memberof ImageApiGetItemImage20
     */
    readonly maxWidth: number

    /**
     * The maximum image height to return.
     * @type {number}
     * @memberof ImageApiGetItemImage20
     */
    readonly maxHeight: number

    /**
     * Optional. Supply the cache tag from the item object to receive strong caching headers.
     * @type {string}
     * @memberof ImageApiGetItemImage20
     */
    readonly tag: string

    /**
     * Determines the output format of the image - original,gif,jpg,png.
     * @type {ImageFormat}
     * @memberof ImageApiGetItemImage20
     */
    readonly format: ImageFormat

    /**
     * Optional. Percent to render for the percent played overlay.
     * @type {number}
     * @memberof ImageApiGetItemImage20
     */
    readonly percentPlayed: number

    /**
     * Optional. Unplayed count overlay to render.
     * @type {number}
     * @memberof ImageApiGetItemImage20
     */
    readonly unplayedCount: number

    /**
     * Image index.
     * @type {number}
     * @memberof ImageApiGetItemImage20
     */
    readonly imageIndex: number

    /**
     * The fixed image width to return.
     * @type {number}
     * @memberof ImageApiGetItemImage20
     */
    readonly width?: number

    /**
     * The fixed image height to return.
     * @type {number}
     * @memberof ImageApiGetItemImage20
     */
    readonly height?: number

    /**
     * Optional. Quality setting, from 0-100. Defaults to 90 and should suffice in most cases.
     * @type {number}
     * @memberof ImageApiGetItemImage20
     */
    readonly quality?: number

    /**
     * Optional. Specify if whitespace should be cropped out of the image. True/False. If unspecified, whitespace will be cropped from logos and clear art.
     * @type {boolean}
     * @memberof ImageApiGetItemImage20
     */
    readonly cropWhitespace?: boolean

    /**
     * Optional. Add a played indicator.
     * @type {boolean}
     * @memberof ImageApiGetItemImage20
     */
    readonly addPlayedIndicator?: boolean

    /**
     * Optional. Blur image.
     * @type {number}
     * @memberof ImageApiGetItemImage20
     */
    readonly blur?: number

    /**
     * Optional. Apply a background color for transparent images.
     * @type {string}
     * @memberof ImageApiGetItemImage20
     */
    readonly backgroundColor?: string

    /**
     * Optional. Apply a foreground layer on top of the image.
     * @type {string}
     * @memberof ImageApiGetItemImage20
     */
    readonly foregroundLayer?: string
}

/**
 * Request parameters for getItemImageInfos operation in ImageApi.
 * @export
 * @interface ImageApiGetItemImageInfosRequest
 */
export interface ImageApiGetItemImageInfosRequest {
    /**
     * Item id.
     * @type {string}
     * @memberof ImageApiGetItemImageInfos
     */
    readonly itemId: string
}

/**
 * Request parameters for getMusicGenreImage operation in ImageApi.
 * @export
 * @interface ImageApiGetMusicGenreImageRequest
 */
export interface ImageApiGetMusicGenreImageRequest {
    /**
     * Music genre name.
     * @type {string}
     * @memberof ImageApiGetMusicGenreImage
     */
    readonly name: string

    /**
     * Image type.
     * @type {ImageType}
     * @memberof ImageApiGetMusicGenreImage
     */
    readonly imageType: ImageType

    /**
     * Image index.
     * @type {number}
     * @memberof ImageApiGetMusicGenreImage
     */
    readonly imageIndex: number

    /**
     * Optional. Supply the cache tag from the item object to receive strong caching headers.
     * @type {string}
     * @memberof ImageApiGetMusicGenreImage
     */
    readonly tag?: string

    /**
     * Determines the output format of the image - original,gif,jpg,png.
     * @type {ImageFormat}
     * @memberof ImageApiGetMusicGenreImage
     */
    readonly format?: ImageFormat

    /**
     * The maximum image width to return.
     * @type {number}
     * @memberof ImageApiGetMusicGenreImage
     */
    readonly maxWidth?: number

    /**
     * The maximum image height to return.
     * @type {number}
     * @memberof ImageApiGetMusicGenreImage
     */
    readonly maxHeight?: number

    /**
     * Optional. Percent to render for the percent played overlay.
     * @type {number}
     * @memberof ImageApiGetMusicGenreImage
     */
    readonly percentPlayed?: number

    /**
     * Optional. Unplayed count overlay to render.
     * @type {number}
     * @memberof ImageApiGetMusicGenreImage
     */
    readonly unplayedCount?: number

    /**
     * The fixed image width to return.
     * @type {number}
     * @memberof ImageApiGetMusicGenreImage
     */
    readonly width?: number

    /**
     * The fixed image height to return.
     * @type {number}
     * @memberof ImageApiGetMusicGenreImage
     */
    readonly height?: number

    /**
     * Optional. Quality setting, from 0-100. Defaults to 90 and should suffice in most cases.
     * @type {number}
     * @memberof ImageApiGetMusicGenreImage
     */
    readonly quality?: number

    /**
     * Optional. Specify if whitespace should be cropped out of the image. True/False. If unspecified, whitespace will be cropped from logos and clear art.
     * @type {boolean}
     * @memberof ImageApiGetMusicGenreImage
     */
    readonly cropWhitespace?: boolean

    /**
     * Optional. Add a played indicator.
     * @type {boolean}
     * @memberof ImageApiGetMusicGenreImage
     */
    readonly addPlayedIndicator?: boolean

    /**
     * Optional. Blur image.
     * @type {number}
     * @memberof ImageApiGetMusicGenreImage
     */
    readonly blur?: number

    /**
     * Optional. Apply a background color for transparent images.
     * @type {string}
     * @memberof ImageApiGetMusicGenreImage
     */
    readonly backgroundColor?: string

    /**
     * Optional. Apply a foreground layer on top of the image.
     * @type {string}
     * @memberof ImageApiGetMusicGenreImage
     */
    readonly foregroundLayer?: string
}

/**
 * Request parameters for getPersonImage operation in ImageApi.
 * @export
 * @interface ImageApiGetPersonImageRequest
 */
export interface ImageApiGetPersonImageRequest {
    /**
     * Person name.
     * @type {string}
     * @memberof ImageApiGetPersonImage
     */
    readonly name: string

    /**
     * Image type.
     * @type {ImageType}
     * @memberof ImageApiGetPersonImage
     */
    readonly imageType: ImageType

    /**
     * Image index.
     * @type {number}
     * @memberof ImageApiGetPersonImage
     */
    readonly imageIndex: number

    /**
     * Optional. Supply the cache tag from the item object to receive strong caching headers.
     * @type {string}
     * @memberof ImageApiGetPersonImage
     */
    readonly tag?: string

    /**
     * Determines the output format of the image - original,gif,jpg,png.
     * @type {ImageFormat}
     * @memberof ImageApiGetPersonImage
     */
    readonly format?: ImageFormat

    /**
     * The maximum image width to return.
     * @type {number}
     * @memberof ImageApiGetPersonImage
     */
    readonly maxWidth?: number

    /**
     * The maximum image height to return.
     * @type {number}
     * @memberof ImageApiGetPersonImage
     */
    readonly maxHeight?: number

    /**
     * Optional. Percent to render for the percent played overlay.
     * @type {number}
     * @memberof ImageApiGetPersonImage
     */
    readonly percentPlayed?: number

    /**
     * Optional. Unplayed count overlay to render.
     * @type {number}
     * @memberof ImageApiGetPersonImage
     */
    readonly unplayedCount?: number

    /**
     * The fixed image width to return.
     * @type {number}
     * @memberof ImageApiGetPersonImage
     */
    readonly width?: number

    /**
     * The fixed image height to return.
     * @type {number}
     * @memberof ImageApiGetPersonImage
     */
    readonly height?: number

    /**
     * Optional. Quality setting, from 0-100. Defaults to 90 and should suffice in most cases.
     * @type {number}
     * @memberof ImageApiGetPersonImage
     */
    readonly quality?: number

    /**
     * Optional. Specify if whitespace should be cropped out of the image. True/False. If unspecified, whitespace will be cropped from logos and clear art.
     * @type {boolean}
     * @memberof ImageApiGetPersonImage
     */
    readonly cropWhitespace?: boolean

    /**
     * Optional. Add a played indicator.
     * @type {boolean}
     * @memberof ImageApiGetPersonImage
     */
    readonly addPlayedIndicator?: boolean

    /**
     * Optional. Blur image.
     * @type {number}
     * @memberof ImageApiGetPersonImage
     */
    readonly blur?: number

    /**
     * Optional. Apply a background color for transparent images.
     * @type {string}
     * @memberof ImageApiGetPersonImage
     */
    readonly backgroundColor?: string

    /**
     * Optional. Apply a foreground layer on top of the image.
     * @type {string}
     * @memberof ImageApiGetPersonImage
     */
    readonly foregroundLayer?: string
}

/**
 * Request parameters for getStudioImage operation in ImageApi.
 * @export
 * @interface ImageApiGetStudioImageRequest
 */
export interface ImageApiGetStudioImageRequest {
    /**
     * Studio name.
     * @type {string}
     * @memberof ImageApiGetStudioImage
     */
    readonly name: string

    /**
     * Image type.
     * @type {ImageType}
     * @memberof ImageApiGetStudioImage
     */
    readonly imageType: ImageType

    /**
     * Optional. Supply the cache tag from the item object to receive strong caching headers.
     * @type {string}
     * @memberof ImageApiGetStudioImage
     */
    readonly tag: string

    /**
     * Determines the output format of the image - original,gif,jpg,png.
     * @type {ImageFormat}
     * @memberof ImageApiGetStudioImage
     */
    readonly format: ImageFormat

    /**
     * Image index.
     * @type {number}
     * @memberof ImageApiGetStudioImage
     */
    readonly imageIndex: number

    /**
     * The maximum image width to return.
     * @type {number}
     * @memberof ImageApiGetStudioImage
     */
    readonly maxWidth?: number

    /**
     * The maximum image height to return.
     * @type {number}
     * @memberof ImageApiGetStudioImage
     */
    readonly maxHeight?: number

    /**
     * Optional. Percent to render for the percent played overlay.
     * @type {number}
     * @memberof ImageApiGetStudioImage
     */
    readonly percentPlayed?: number

    /**
     * Optional. Unplayed count overlay to render.
     * @type {number}
     * @memberof ImageApiGetStudioImage
     */
    readonly unplayedCount?: number

    /**
     * The fixed image width to return.
     * @type {number}
     * @memberof ImageApiGetStudioImage
     */
    readonly width?: number

    /**
     * The fixed image height to return.
     * @type {number}
     * @memberof ImageApiGetStudioImage
     */
    readonly height?: number

    /**
     * Optional. Quality setting, from 0-100. Defaults to 90 and should suffice in most cases.
     * @type {number}
     * @memberof ImageApiGetStudioImage
     */
    readonly quality?: number

    /**
     * Optional. Specify if whitespace should be cropped out of the image. True/False. If unspecified, whitespace will be cropped from logos and clear art.
     * @type {boolean}
     * @memberof ImageApiGetStudioImage
     */
    readonly cropWhitespace?: boolean

    /**
     * Optional. Add a played indicator.
     * @type {boolean}
     * @memberof ImageApiGetStudioImage
     */
    readonly addPlayedIndicator?: boolean

    /**
     * Optional. Blur image.
     * @type {number}
     * @memberof ImageApiGetStudioImage
     */
    readonly blur?: number

    /**
     * Optional. Apply a background color for transparent images.
     * @type {string}
     * @memberof ImageApiGetStudioImage
     */
    readonly backgroundColor?: string

    /**
     * Optional. Apply a foreground layer on top of the image.
     * @type {string}
     * @memberof ImageApiGetStudioImage
     */
    readonly foregroundLayer?: string
}

/**
 * Request parameters for getUserImage operation in ImageApi.
 * @export
 * @interface ImageApiGetUserImageRequest
 */
export interface ImageApiGetUserImageRequest {
    /**
     * User id.
     * @type {string}
     * @memberof ImageApiGetUserImage
     */
    readonly userId: string

    /**
     * Image type.
     * @type {ImageType}
     * @memberof ImageApiGetUserImage
     */
    readonly imageType: ImageType

    /**
     * Image index.
     * @type {number}
     * @memberof ImageApiGetUserImage
     */
    readonly imageIndex: number

    /**
     * Optional. Supply the cache tag from the item object to receive strong caching headers.
     * @type {string}
     * @memberof ImageApiGetUserImage
     */
    readonly tag?: string

    /**
     * Determines the output format of the image - original,gif,jpg,png.
     * @type {ImageFormat}
     * @memberof ImageApiGetUserImage
     */
    readonly format?: ImageFormat

    /**
     * The maximum image width to return.
     * @type {number}
     * @memberof ImageApiGetUserImage
     */
    readonly maxWidth?: number

    /**
     * The maximum image height to return.
     * @type {number}
     * @memberof ImageApiGetUserImage
     */
    readonly maxHeight?: number

    /**
     * Optional. Percent to render for the percent played overlay.
     * @type {number}
     * @memberof ImageApiGetUserImage
     */
    readonly percentPlayed?: number

    /**
     * Optional. Unplayed count overlay to render.
     * @type {number}
     * @memberof ImageApiGetUserImage
     */
    readonly unplayedCount?: number

    /**
     * The fixed image width to return.
     * @type {number}
     * @memberof ImageApiGetUserImage
     */
    readonly width?: number

    /**
     * The fixed image height to return.
     * @type {number}
     * @memberof ImageApiGetUserImage
     */
    readonly height?: number

    /**
     * Optional. Quality setting, from 0-100. Defaults to 90 and should suffice in most cases.
     * @type {number}
     * @memberof ImageApiGetUserImage
     */
    readonly quality?: number

    /**
     * Optional. Specify if whitespace should be cropped out of the image. True/False. If unspecified, whitespace will be cropped from logos and clear art.
     * @type {boolean}
     * @memberof ImageApiGetUserImage
     */
    readonly cropWhitespace?: boolean

    /**
     * Optional. Add a played indicator.
     * @type {boolean}
     * @memberof ImageApiGetUserImage
     */
    readonly addPlayedIndicator?: boolean

    /**
     * Optional. Blur image.
     * @type {number}
     * @memberof ImageApiGetUserImage
     */
    readonly blur?: number

    /**
     * Optional. Apply a background color for transparent images.
     * @type {string}
     * @memberof ImageApiGetUserImage
     */
    readonly backgroundColor?: string

    /**
     * Optional. Apply a foreground layer on top of the image.
     * @type {string}
     * @memberof ImageApiGetUserImage
     */
    readonly foregroundLayer?: string
}

/**
 * Request parameters for headArtistImage operation in ImageApi.
 * @export
 * @interface ImageApiHeadArtistImageRequest
 */
export interface ImageApiHeadArtistImageRequest {
    /**
     * Artist name.
     * @type {string}
     * @memberof ImageApiHeadArtistImage
     */
    readonly name: string

    /**
     * Image type.
     * @type {ImageType}
     * @memberof ImageApiHeadArtistImage
     */
    readonly imageType: ImageType

    /**
     * Image index.
     * @type {number}
     * @memberof ImageApiHeadArtistImage
     */
    readonly imageIndex: number

    /**
     * Optional. Supply the cache tag from the item object to receive strong caching headers.
     * @type {string}
     * @memberof ImageApiHeadArtistImage
     */
    readonly tag?: string

    /**
     * Determines the output format of the image - original,gif,jpg,png.
     * @type {ImageFormat}
     * @memberof ImageApiHeadArtistImage
     */
    readonly format?: ImageFormat

    /**
     * The maximum image width to return.
     * @type {number}
     * @memberof ImageApiHeadArtistImage
     */
    readonly maxWidth?: number

    /**
     * The maximum image height to return.
     * @type {number}
     * @memberof ImageApiHeadArtistImage
     */
    readonly maxHeight?: number

    /**
     * Optional. Percent to render for the percent played overlay.
     * @type {number}
     * @memberof ImageApiHeadArtistImage
     */
    readonly percentPlayed?: number

    /**
     * Optional. Unplayed count overlay to render.
     * @type {number}
     * @memberof ImageApiHeadArtistImage
     */
    readonly unplayedCount?: number

    /**
     * The fixed image width to return.
     * @type {number}
     * @memberof ImageApiHeadArtistImage
     */
    readonly width?: number

    /**
     * The fixed image height to return.
     * @type {number}
     * @memberof ImageApiHeadArtistImage
     */
    readonly height?: number

    /**
     * Optional. Quality setting, from 0-100. Defaults to 90 and should suffice in most cases.
     * @type {number}
     * @memberof ImageApiHeadArtistImage
     */
    readonly quality?: number

    /**
     * Optional. Specify if whitespace should be cropped out of the image. True/False. If unspecified, whitespace will be cropped from logos and clear art.
     * @type {boolean}
     * @memberof ImageApiHeadArtistImage
     */
    readonly cropWhitespace?: boolean

    /**
     * Optional. Add a played indicator.
     * @type {boolean}
     * @memberof ImageApiHeadArtistImage
     */
    readonly addPlayedIndicator?: boolean

    /**
     * Optional. Blur image.
     * @type {number}
     * @memberof ImageApiHeadArtistImage
     */
    readonly blur?: number

    /**
     * Optional. Apply a background color for transparent images.
     * @type {string}
     * @memberof ImageApiHeadArtistImage
     */
    readonly backgroundColor?: string

    /**
     * Optional. Apply a foreground layer on top of the image.
     * @type {string}
     * @memberof ImageApiHeadArtistImage
     */
    readonly foregroundLayer?: string
}

/**
 * Request parameters for headGenreImage operation in ImageApi.
 * @export
 * @interface ImageApiHeadGenreImageRequest
 */
export interface ImageApiHeadGenreImageRequest {
    /**
     * Genre name.
     * @type {string}
     * @memberof ImageApiHeadGenreImage
     */
    readonly name: string

    /**
     * Image type.
     * @type {ImageType}
     * @memberof ImageApiHeadGenreImage
     */
    readonly imageType: ImageType

    /**
     * Image index.
     * @type {number}
     * @memberof ImageApiHeadGenreImage
     */
    readonly imageIndex: number

    /**
     * Optional. Supply the cache tag from the item object to receive strong caching headers.
     * @type {string}
     * @memberof ImageApiHeadGenreImage
     */
    readonly tag?: string

    /**
     * Determines the output format of the image - original,gif,jpg,png.
     * @type {ImageFormat}
     * @memberof ImageApiHeadGenreImage
     */
    readonly format?: ImageFormat

    /**
     * The maximum image width to return.
     * @type {number}
     * @memberof ImageApiHeadGenreImage
     */
    readonly maxWidth?: number

    /**
     * The maximum image height to return.
     * @type {number}
     * @memberof ImageApiHeadGenreImage
     */
    readonly maxHeight?: number

    /**
     * Optional. Percent to render for the percent played overlay.
     * @type {number}
     * @memberof ImageApiHeadGenreImage
     */
    readonly percentPlayed?: number

    /**
     * Optional. Unplayed count overlay to render.
     * @type {number}
     * @memberof ImageApiHeadGenreImage
     */
    readonly unplayedCount?: number

    /**
     * The fixed image width to return.
     * @type {number}
     * @memberof ImageApiHeadGenreImage
     */
    readonly width?: number

    /**
     * The fixed image height to return.
     * @type {number}
     * @memberof ImageApiHeadGenreImage
     */
    readonly height?: number

    /**
     * Optional. Quality setting, from 0-100. Defaults to 90 and should suffice in most cases.
     * @type {number}
     * @memberof ImageApiHeadGenreImage
     */
    readonly quality?: number

    /**
     * Optional. Specify if whitespace should be cropped out of the image. True/False. If unspecified, whitespace will be cropped from logos and clear art.
     * @type {boolean}
     * @memberof ImageApiHeadGenreImage
     */
    readonly cropWhitespace?: boolean

    /**
     * Optional. Add a played indicator.
     * @type {boolean}
     * @memberof ImageApiHeadGenreImage
     */
    readonly addPlayedIndicator?: boolean

    /**
     * Optional. Blur image.
     * @type {number}
     * @memberof ImageApiHeadGenreImage
     */
    readonly blur?: number

    /**
     * Optional. Apply a background color for transparent images.
     * @type {string}
     * @memberof ImageApiHeadGenreImage
     */
    readonly backgroundColor?: string

    /**
     * Optional. Apply a foreground layer on top of the image.
     * @type {string}
     * @memberof ImageApiHeadGenreImage
     */
    readonly foregroundLayer?: string
}

/**
 * Request parameters for headItemImage operation in ImageApi.
 * @export
 * @interface ImageApiHeadItemImageRequest
 */
export interface ImageApiHeadItemImageRequest {
    /**
     * Item id.
     * @type {string}
     * @memberof ImageApiHeadItemImage
     */
    readonly itemId: string

    /**
     * Image type.
     * @type {ImageType}
     * @memberof ImageApiHeadItemImage
     */
    readonly imageType: ImageType

    /**
     * Image index.
     * @type {number}
     * @memberof ImageApiHeadItemImage
     */
    readonly imageIndex: number

    /**
     * The maximum image width to return.
     * @type {number}
     * @memberof ImageApiHeadItemImage
     */
    readonly maxWidth?: number

    /**
     * The maximum image height to return.
     * @type {number}
     * @memberof ImageApiHeadItemImage
     */
    readonly maxHeight?: number

    /**
     * The fixed image width to return.
     * @type {number}
     * @memberof ImageApiHeadItemImage
     */
    readonly width?: number

    /**
     * The fixed image height to return.
     * @type {number}
     * @memberof ImageApiHeadItemImage
     */
    readonly height?: number

    /**
     * Optional. Quality setting, from 0-100. Defaults to 90 and should suffice in most cases.
     * @type {number}
     * @memberof ImageApiHeadItemImage
     */
    readonly quality?: number

    /**
     * Optional. Supply the cache tag from the item object to receive strong caching headers.
     * @type {string}
     * @memberof ImageApiHeadItemImage
     */
    readonly tag?: string

    /**
     * Optional. Specify if whitespace should be cropped out of the image. True/False. If unspecified, whitespace will be cropped from logos and clear art.
     * @type {boolean}
     * @memberof ImageApiHeadItemImage
     */
    readonly cropWhitespace?: boolean

    /**
     * Optional. The MediaBrowser.Model.Drawing.ImageFormat of the returned image.
     * @type {ImageFormat}
     * @memberof ImageApiHeadItemImage
     */
    readonly format?: ImageFormat

    /**
     * Optional. Add a played indicator.
     * @type {boolean}
     * @memberof ImageApiHeadItemImage
     */
    readonly addPlayedIndicator?: boolean

    /**
     * Optional. Percent to render for the percent played overlay.
     * @type {number}
     * @memberof ImageApiHeadItemImage
     */
    readonly percentPlayed?: number

    /**
     * Optional. Unplayed count overlay to render.
     * @type {number}
     * @memberof ImageApiHeadItemImage
     */
    readonly unplayedCount?: number

    /**
     * Optional. Blur image.
     * @type {number}
     * @memberof ImageApiHeadItemImage
     */
    readonly blur?: number

    /**
     * Optional. Apply a background color for transparent images.
     * @type {string}
     * @memberof ImageApiHeadItemImage
     */
    readonly backgroundColor?: string

    /**
     * Optional. Apply a foreground layer on top of the image.
     * @type {string}
     * @memberof ImageApiHeadItemImage
     */
    readonly foregroundLayer?: string
}

/**
 * Request parameters for headItemImage2 operation in ImageApi.
 * @export
 * @interface ImageApiHeadItemImage2Request
 */
export interface ImageApiHeadItemImage2Request {
    /**
     * Item id.
     * @type {string}
     * @memberof ImageApiHeadItemImage2
     */
    readonly itemId: string

    /**
     * Image type.
     * @type {ImageType}
     * @memberof ImageApiHeadItemImage2
     */
    readonly imageType: ImageType

    /**
     * Image index.
     * @type {number}
     * @memberof ImageApiHeadItemImage2
     */
    readonly imageIndex: number

    /**
     * The maximum image width to return.
     * @type {number}
     * @memberof ImageApiHeadItemImage2
     */
    readonly maxWidth?: number

    /**
     * The maximum image height to return.
     * @type {number}
     * @memberof ImageApiHeadItemImage2
     */
    readonly maxHeight?: number

    /**
     * The fixed image width to return.
     * @type {number}
     * @memberof ImageApiHeadItemImage2
     */
    readonly width?: number

    /**
     * The fixed image height to return.
     * @type {number}
     * @memberof ImageApiHeadItemImage2
     */
    readonly height?: number

    /**
     * Optional. Quality setting, from 0-100. Defaults to 90 and should suffice in most cases.
     * @type {number}
     * @memberof ImageApiHeadItemImage2
     */
    readonly quality?: number

    /**
     * Optional. Supply the cache tag from the item object to receive strong caching headers.
     * @type {string}
     * @memberof ImageApiHeadItemImage2
     */
    readonly tag?: string

    /**
     * Optional. Specify if whitespace should be cropped out of the image. True/False. If unspecified, whitespace will be cropped from logos and clear art.
     * @type {boolean}
     * @memberof ImageApiHeadItemImage2
     */
    readonly cropWhitespace?: boolean

    /**
     * Optional. The MediaBrowser.Model.Drawing.ImageFormat of the returned image.
     * @type {ImageFormat}
     * @memberof ImageApiHeadItemImage2
     */
    readonly format?: ImageFormat

    /**
     * Optional. Add a played indicator.
     * @type {boolean}
     * @memberof ImageApiHeadItemImage2
     */
    readonly addPlayedIndicator?: boolean

    /**
     * Optional. Percent to render for the percent played overlay.
     * @type {number}
     * @memberof ImageApiHeadItemImage2
     */
    readonly percentPlayed?: number

    /**
     * Optional. Unplayed count overlay to render.
     * @type {number}
     * @memberof ImageApiHeadItemImage2
     */
    readonly unplayedCount?: number

    /**
     * Optional. Blur image.
     * @type {number}
     * @memberof ImageApiHeadItemImage2
     */
    readonly blur?: number

    /**
     * Optional. Apply a background color for transparent images.
     * @type {string}
     * @memberof ImageApiHeadItemImage2
     */
    readonly backgroundColor?: string

    /**
     * Optional. Apply a foreground layer on top of the image.
     * @type {string}
     * @memberof ImageApiHeadItemImage2
     */
    readonly foregroundLayer?: string
}

/**
 * Request parameters for headItemImage2_2 operation in ImageApi.
 * @export
 * @interface ImageApiHeadItemImage20Request
 */
export interface ImageApiHeadItemImage20Request {
    /**
     * Item id.
     * @type {string}
     * @memberof ImageApiHeadItemImage20
     */
    readonly itemId: string

    /**
     * Image type.
     * @type {ImageType}
     * @memberof ImageApiHeadItemImage20
     */
    readonly imageType: ImageType

    /**
     * The maximum image width to return.
     * @type {number}
     * @memberof ImageApiHeadItemImage20
     */
    readonly maxWidth: number

    /**
     * The maximum image height to return.
     * @type {number}
     * @memberof ImageApiHeadItemImage20
     */
    readonly maxHeight: number

    /**
     * Optional. Supply the cache tag from the item object to receive strong caching headers.
     * @type {string}
     * @memberof ImageApiHeadItemImage20
     */
    readonly tag: string

    /**
     * Determines the output format of the image - original,gif,jpg,png.
     * @type {ImageFormat}
     * @memberof ImageApiHeadItemImage20
     */
    readonly format: ImageFormat

    /**
     * Optional. Percent to render for the percent played overlay.
     * @type {number}
     * @memberof ImageApiHeadItemImage20
     */
    readonly percentPlayed: number

    /**
     * Optional. Unplayed count overlay to render.
     * @type {number}
     * @memberof ImageApiHeadItemImage20
     */
    readonly unplayedCount: number

    /**
     * Image index.
     * @type {number}
     * @memberof ImageApiHeadItemImage20
     */
    readonly imageIndex: number

    /**
     * The fixed image width to return.
     * @type {number}
     * @memberof ImageApiHeadItemImage20
     */
    readonly width?: number

    /**
     * The fixed image height to return.
     * @type {number}
     * @memberof ImageApiHeadItemImage20
     */
    readonly height?: number

    /**
     * Optional. Quality setting, from 0-100. Defaults to 90 and should suffice in most cases.
     * @type {number}
     * @memberof ImageApiHeadItemImage20
     */
    readonly quality?: number

    /**
     * Optional. Specify if whitespace should be cropped out of the image. True/False. If unspecified, whitespace will be cropped from logos and clear art.
     * @type {boolean}
     * @memberof ImageApiHeadItemImage20
     */
    readonly cropWhitespace?: boolean

    /**
     * Optional. Add a played indicator.
     * @type {boolean}
     * @memberof ImageApiHeadItemImage20
     */
    readonly addPlayedIndicator?: boolean

    /**
     * Optional. Blur image.
     * @type {number}
     * @memberof ImageApiHeadItemImage20
     */
    readonly blur?: number

    /**
     * Optional. Apply a background color for transparent images.
     * @type {string}
     * @memberof ImageApiHeadItemImage20
     */
    readonly backgroundColor?: string

    /**
     * Optional. Apply a foreground layer on top of the image.
     * @type {string}
     * @memberof ImageApiHeadItemImage20
     */
    readonly foregroundLayer?: string
}

/**
 * Request parameters for headMusicGenreImage operation in ImageApi.
 * @export
 * @interface ImageApiHeadMusicGenreImageRequest
 */
export interface ImageApiHeadMusicGenreImageRequest {
    /**
     * Music genre name.
     * @type {string}
     * @memberof ImageApiHeadMusicGenreImage
     */
    readonly name: string

    /**
     * Image type.
     * @type {ImageType}
     * @memberof ImageApiHeadMusicGenreImage
     */
    readonly imageType: ImageType

    /**
     * Image index.
     * @type {number}
     * @memberof ImageApiHeadMusicGenreImage
     */
    readonly imageIndex: number

    /**
     * Optional. Supply the cache tag from the item object to receive strong caching headers.
     * @type {string}
     * @memberof ImageApiHeadMusicGenreImage
     */
    readonly tag?: string

    /**
     * Determines the output format of the image - original,gif,jpg,png.
     * @type {ImageFormat}
     * @memberof ImageApiHeadMusicGenreImage
     */
    readonly format?: ImageFormat

    /**
     * The maximum image width to return.
     * @type {number}
     * @memberof ImageApiHeadMusicGenreImage
     */
    readonly maxWidth?: number

    /**
     * The maximum image height to return.
     * @type {number}
     * @memberof ImageApiHeadMusicGenreImage
     */
    readonly maxHeight?: number

    /**
     * Optional. Percent to render for the percent played overlay.
     * @type {number}
     * @memberof ImageApiHeadMusicGenreImage
     */
    readonly percentPlayed?: number

    /**
     * Optional. Unplayed count overlay to render.
     * @type {number}
     * @memberof ImageApiHeadMusicGenreImage
     */
    readonly unplayedCount?: number

    /**
     * The fixed image width to return.
     * @type {number}
     * @memberof ImageApiHeadMusicGenreImage
     */
    readonly width?: number

    /**
     * The fixed image height to return.
     * @type {number}
     * @memberof ImageApiHeadMusicGenreImage
     */
    readonly height?: number

    /**
     * Optional. Quality setting, from 0-100. Defaults to 90 and should suffice in most cases.
     * @type {number}
     * @memberof ImageApiHeadMusicGenreImage
     */
    readonly quality?: number

    /**
     * Optional. Specify if whitespace should be cropped out of the image. True/False. If unspecified, whitespace will be cropped from logos and clear art.
     * @type {boolean}
     * @memberof ImageApiHeadMusicGenreImage
     */
    readonly cropWhitespace?: boolean

    /**
     * Optional. Add a played indicator.
     * @type {boolean}
     * @memberof ImageApiHeadMusicGenreImage
     */
    readonly addPlayedIndicator?: boolean

    /**
     * Optional. Blur image.
     * @type {number}
     * @memberof ImageApiHeadMusicGenreImage
     */
    readonly blur?: number

    /**
     * Optional. Apply a background color for transparent images.
     * @type {string}
     * @memberof ImageApiHeadMusicGenreImage
     */
    readonly backgroundColor?: string

    /**
     * Optional. Apply a foreground layer on top of the image.
     * @type {string}
     * @memberof ImageApiHeadMusicGenreImage
     */
    readonly foregroundLayer?: string
}

/**
 * Request parameters for headPersonImage operation in ImageApi.
 * @export
 * @interface ImageApiHeadPersonImageRequest
 */
export interface ImageApiHeadPersonImageRequest {
    /**
     * Person name.
     * @type {string}
     * @memberof ImageApiHeadPersonImage
     */
    readonly name: string

    /**
     * Image type.
     * @type {ImageType}
     * @memberof ImageApiHeadPersonImage
     */
    readonly imageType: ImageType

    /**
     * Image index.
     * @type {number}
     * @memberof ImageApiHeadPersonImage
     */
    readonly imageIndex: number

    /**
     * Optional. Supply the cache tag from the item object to receive strong caching headers.
     * @type {string}
     * @memberof ImageApiHeadPersonImage
     */
    readonly tag?: string

    /**
     * Determines the output format of the image - original,gif,jpg,png.
     * @type {ImageFormat}
     * @memberof ImageApiHeadPersonImage
     */
    readonly format?: ImageFormat

    /**
     * The maximum image width to return.
     * @type {number}
     * @memberof ImageApiHeadPersonImage
     */
    readonly maxWidth?: number

    /**
     * The maximum image height to return.
     * @type {number}
     * @memberof ImageApiHeadPersonImage
     */
    readonly maxHeight?: number

    /**
     * Optional. Percent to render for the percent played overlay.
     * @type {number}
     * @memberof ImageApiHeadPersonImage
     */
    readonly percentPlayed?: number

    /**
     * Optional. Unplayed count overlay to render.
     * @type {number}
     * @memberof ImageApiHeadPersonImage
     */
    readonly unplayedCount?: number

    /**
     * The fixed image width to return.
     * @type {number}
     * @memberof ImageApiHeadPersonImage
     */
    readonly width?: number

    /**
     * The fixed image height to return.
     * @type {number}
     * @memberof ImageApiHeadPersonImage
     */
    readonly height?: number

    /**
     * Optional. Quality setting, from 0-100. Defaults to 90 and should suffice in most cases.
     * @type {number}
     * @memberof ImageApiHeadPersonImage
     */
    readonly quality?: number

    /**
     * Optional. Specify if whitespace should be cropped out of the image. True/False. If unspecified, whitespace will be cropped from logos and clear art.
     * @type {boolean}
     * @memberof ImageApiHeadPersonImage
     */
    readonly cropWhitespace?: boolean

    /**
     * Optional. Add a played indicator.
     * @type {boolean}
     * @memberof ImageApiHeadPersonImage
     */
    readonly addPlayedIndicator?: boolean

    /**
     * Optional. Blur image.
     * @type {number}
     * @memberof ImageApiHeadPersonImage
     */
    readonly blur?: number

    /**
     * Optional. Apply a background color for transparent images.
     * @type {string}
     * @memberof ImageApiHeadPersonImage
     */
    readonly backgroundColor?: string

    /**
     * Optional. Apply a foreground layer on top of the image.
     * @type {string}
     * @memberof ImageApiHeadPersonImage
     */
    readonly foregroundLayer?: string
}

/**
 * Request parameters for headStudioImage operation in ImageApi.
 * @export
 * @interface ImageApiHeadStudioImageRequest
 */
export interface ImageApiHeadStudioImageRequest {
    /**
     * Studio name.
     * @type {string}
     * @memberof ImageApiHeadStudioImage
     */
    readonly name: string

    /**
     * Image type.
     * @type {ImageType}
     * @memberof ImageApiHeadStudioImage
     */
    readonly imageType: ImageType

    /**
     * Optional. Supply the cache tag from the item object to receive strong caching headers.
     * @type {string}
     * @memberof ImageApiHeadStudioImage
     */
    readonly tag: string

    /**
     * Determines the output format of the image - original,gif,jpg,png.
     * @type {ImageFormat}
     * @memberof ImageApiHeadStudioImage
     */
    readonly format: ImageFormat

    /**
     * Image index.
     * @type {number}
     * @memberof ImageApiHeadStudioImage
     */
    readonly imageIndex: number

    /**
     * The maximum image width to return.
     * @type {number}
     * @memberof ImageApiHeadStudioImage
     */
    readonly maxWidth?: number

    /**
     * The maximum image height to return.
     * @type {number}
     * @memberof ImageApiHeadStudioImage
     */
    readonly maxHeight?: number

    /**
     * Optional. Percent to render for the percent played overlay.
     * @type {number}
     * @memberof ImageApiHeadStudioImage
     */
    readonly percentPlayed?: number

    /**
     * Optional. Unplayed count overlay to render.
     * @type {number}
     * @memberof ImageApiHeadStudioImage
     */
    readonly unplayedCount?: number

    /**
     * The fixed image width to return.
     * @type {number}
     * @memberof ImageApiHeadStudioImage
     */
    readonly width?: number

    /**
     * The fixed image height to return.
     * @type {number}
     * @memberof ImageApiHeadStudioImage
     */
    readonly height?: number

    /**
     * Optional. Quality setting, from 0-100. Defaults to 90 and should suffice in most cases.
     * @type {number}
     * @memberof ImageApiHeadStudioImage
     */
    readonly quality?: number

    /**
     * Optional. Specify if whitespace should be cropped out of the image. True/False. If unspecified, whitespace will be cropped from logos and clear art.
     * @type {boolean}
     * @memberof ImageApiHeadStudioImage
     */
    readonly cropWhitespace?: boolean

    /**
     * Optional. Add a played indicator.
     * @type {boolean}
     * @memberof ImageApiHeadStudioImage
     */
    readonly addPlayedIndicator?: boolean

    /**
     * Optional. Blur image.
     * @type {number}
     * @memberof ImageApiHeadStudioImage
     */
    readonly blur?: number

    /**
     * Optional. Apply a background color for transparent images.
     * @type {string}
     * @memberof ImageApiHeadStudioImage
     */
    readonly backgroundColor?: string

    /**
     * Optional. Apply a foreground layer on top of the image.
     * @type {string}
     * @memberof ImageApiHeadStudioImage
     */
    readonly foregroundLayer?: string
}

/**
 * Request parameters for headUserImage operation in ImageApi.
 * @export
 * @interface ImageApiHeadUserImageRequest
 */
export interface ImageApiHeadUserImageRequest {
    /**
     * User id.
     * @type {string}
     * @memberof ImageApiHeadUserImage
     */
    readonly userId: string

    /**
     * Image type.
     * @type {ImageType}
     * @memberof ImageApiHeadUserImage
     */
    readonly imageType: ImageType

    /**
     * Image index.
     * @type {number}
     * @memberof ImageApiHeadUserImage
     */
    readonly imageIndex: number

    /**
     * Optional. Supply the cache tag from the item object to receive strong caching headers.
     * @type {string}
     * @memberof ImageApiHeadUserImage
     */
    readonly tag?: string

    /**
     * Determines the output format of the image - original,gif,jpg,png.
     * @type {ImageFormat}
     * @memberof ImageApiHeadUserImage
     */
    readonly format?: ImageFormat

    /**
     * The maximum image width to return.
     * @type {number}
     * @memberof ImageApiHeadUserImage
     */
    readonly maxWidth?: number

    /**
     * The maximum image height to return.
     * @type {number}
     * @memberof ImageApiHeadUserImage
     */
    readonly maxHeight?: number

    /**
     * Optional. Percent to render for the percent played overlay.
     * @type {number}
     * @memberof ImageApiHeadUserImage
     */
    readonly percentPlayed?: number

    /**
     * Optional. Unplayed count overlay to render.
     * @type {number}
     * @memberof ImageApiHeadUserImage
     */
    readonly unplayedCount?: number

    /**
     * The fixed image width to return.
     * @type {number}
     * @memberof ImageApiHeadUserImage
     */
    readonly width?: number

    /**
     * The fixed image height to return.
     * @type {number}
     * @memberof ImageApiHeadUserImage
     */
    readonly height?: number

    /**
     * Optional. Quality setting, from 0-100. Defaults to 90 and should suffice in most cases.
     * @type {number}
     * @memberof ImageApiHeadUserImage
     */
    readonly quality?: number

    /**
     * Optional. Specify if whitespace should be cropped out of the image. True/False. If unspecified, whitespace will be cropped from logos and clear art.
     * @type {boolean}
     * @memberof ImageApiHeadUserImage
     */
    readonly cropWhitespace?: boolean

    /**
     * Optional. Add a played indicator.
     * @type {boolean}
     * @memberof ImageApiHeadUserImage
     */
    readonly addPlayedIndicator?: boolean

    /**
     * Optional. Blur image.
     * @type {number}
     * @memberof ImageApiHeadUserImage
     */
    readonly blur?: number

    /**
     * Optional. Apply a background color for transparent images.
     * @type {string}
     * @memberof ImageApiHeadUserImage
     */
    readonly backgroundColor?: string

    /**
     * Optional. Apply a foreground layer on top of the image.
     * @type {string}
     * @memberof ImageApiHeadUserImage
     */
    readonly foregroundLayer?: string
}

/**
 * Request parameters for postUserImage operation in ImageApi.
 * @export
 * @interface ImageApiPostUserImageRequest
 */
export interface ImageApiPostUserImageRequest {
    /**
     * User Id.
     * @type {string}
     * @memberof ImageApiPostUserImage
     */
    readonly userId: string

    /**
     * (Unused) Image type.
     * @type {ImageType}
     * @memberof ImageApiPostUserImage
     */
    readonly imageType: ImageType

    /**
     * (Unused) Image index.
     * @type {number}
     * @memberof ImageApiPostUserImage
     */
    readonly index: number
}

/**
 * Request parameters for postUserImage2 operation in ImageApi.
 * @export
 * @interface ImageApiPostUserImage2Request
 */
export interface ImageApiPostUserImage2Request {
    /**
     * User Id.
     * @type {string}
     * @memberof ImageApiPostUserImage2
     */
    readonly userId: string

    /**
     * (Unused) Image type.
     * @type {ImageType}
     * @memberof ImageApiPostUserImage2
     */
    readonly imageType: ImageType

    /**
     * (Unused) Image index.
     * @type {number}
     * @memberof ImageApiPostUserImage2
     */
    readonly index: number
}

/**
 * Request parameters for setItemImage operation in ImageApi.
 * @export
 * @interface ImageApiSetItemImageRequest
 */
export interface ImageApiSetItemImageRequest {
    /**
     * Item id.
     * @type {string}
     * @memberof ImageApiSetItemImage
     */
    readonly itemId: string

    /**
     * Image type.
     * @type {ImageType}
     * @memberof ImageApiSetItemImage
     */
    readonly imageType: ImageType

    /**
     * (Unused) Image index.
     * @type {number}
     * @memberof ImageApiSetItemImage
     */
    readonly imageIndex: number
}

/**
 * Request parameters for setItemImage2 operation in ImageApi.
 * @export
 * @interface ImageApiSetItemImage2Request
 */
export interface ImageApiSetItemImage2Request {
    /**
     * Item id.
     * @type {string}
     * @memberof ImageApiSetItemImage2
     */
    readonly itemId: string

    /**
     * Image type.
     * @type {ImageType}
     * @memberof ImageApiSetItemImage2
     */
    readonly imageType: ImageType

    /**
     * (Unused) Image index.
     * @type {number}
     * @memberof ImageApiSetItemImage2
     */
    readonly imageIndex: number
}

/**
 * Request parameters for updateItemImageIndex operation in ImageApi.
 * @export
 * @interface ImageApiUpdateItemImageIndexRequest
 */
export interface ImageApiUpdateItemImageIndexRequest {
    /**
     * Item id.
     * @type {string}
     * @memberof ImageApiUpdateItemImageIndex
     */
    readonly itemId: string

    /**
     * Image type.
     * @type {ImageType}
     * @memberof ImageApiUpdateItemImageIndex
     */
    readonly imageType: ImageType

    /**
     * Old image index.
     * @type {number}
     * @memberof ImageApiUpdateItemImageIndex
     */
    readonly imageIndex: number

    /**
     * New image index.
     * @type {number}
     * @memberof ImageApiUpdateItemImageIndex
     */
    readonly newIndex?: number
}

/**
 * ImageApi - object-oriented interface
 * @export
 * @class ImageApi
 * @extends {BaseAPI}
 */
export class ImageApi extends BaseAPI {
    /**
     * 
     * @summary Delete an item\'s image.
     * @param {ImageApiDeleteItemImageRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImageApi
     */
    public deleteItemImage(requestParameters: ImageApiDeleteItemImageRequest, options?: any) {
        return ImageApiFp(this.configuration).deleteItemImage(requestParameters.itemId, requestParameters.imageType, requestParameters.imageIndex, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete an item\'s image.
     * @param {ImageApiDeleteItemImage2Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImageApi
     */
    public deleteItemImage2(requestParameters: ImageApiDeleteItemImage2Request, options?: any) {
        return ImageApiFp(this.configuration).deleteItemImage2(requestParameters.itemId, requestParameters.imageType, requestParameters.imageIndex, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete the user\'s image.
     * @param {ImageApiDeleteUserImageRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImageApi
     */
    public deleteUserImage(requestParameters: ImageApiDeleteUserImageRequest, options?: any) {
        return ImageApiFp(this.configuration).deleteUserImage(requestParameters.userId, requestParameters.imageType, requestParameters.index, requestParameters.itemType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete the user\'s image.
     * @param {ImageApiDeleteUserImage2Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImageApi
     */
    public deleteUserImage2(requestParameters: ImageApiDeleteUserImage2Request, options?: any) {
        return ImageApiFp(this.configuration).deleteUserImage2(requestParameters.userId, requestParameters.imageType, requestParameters.index, requestParameters.itemType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get artist image by name.
     * @param {ImageApiGetArtistImageRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImageApi
     */
    public getArtistImage(requestParameters: ImageApiGetArtistImageRequest, options?: any) {
        return ImageApiFp(this.configuration).getArtistImage(requestParameters.name, requestParameters.imageType, requestParameters.imageIndex, requestParameters.tag, requestParameters.format, requestParameters.maxWidth, requestParameters.maxHeight, requestParameters.percentPlayed, requestParameters.unplayedCount, requestParameters.width, requestParameters.height, requestParameters.quality, requestParameters.cropWhitespace, requestParameters.addPlayedIndicator, requestParameters.blur, requestParameters.backgroundColor, requestParameters.foregroundLayer, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get genre image by name.
     * @param {ImageApiGetGenreImageRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImageApi
     */
    public getGenreImage(requestParameters: ImageApiGetGenreImageRequest, options?: any) {
        return ImageApiFp(this.configuration).getGenreImage(requestParameters.name, requestParameters.imageType, requestParameters.imageIndex, requestParameters.tag, requestParameters.format, requestParameters.maxWidth, requestParameters.maxHeight, requestParameters.percentPlayed, requestParameters.unplayedCount, requestParameters.width, requestParameters.height, requestParameters.quality, requestParameters.cropWhitespace, requestParameters.addPlayedIndicator, requestParameters.blur, requestParameters.backgroundColor, requestParameters.foregroundLayer, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets the item\'s image.
     * @param {ImageApiGetItemImageRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImageApi
     */
    public getItemImage(requestParameters: ImageApiGetItemImageRequest, options?: any) {
        return ImageApiFp(this.configuration).getItemImage(requestParameters.itemId, requestParameters.imageType, requestParameters.imageIndex, requestParameters.maxWidth, requestParameters.maxHeight, requestParameters.width, requestParameters.height, requestParameters.quality, requestParameters.tag, requestParameters.cropWhitespace, requestParameters.format, requestParameters.addPlayedIndicator, requestParameters.percentPlayed, requestParameters.unplayedCount, requestParameters.blur, requestParameters.backgroundColor, requestParameters.foregroundLayer, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets the item\'s image.
     * @param {ImageApiGetItemImage2Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImageApi
     */
    public getItemImage2(requestParameters: ImageApiGetItemImage2Request, options?: any) {
        return ImageApiFp(this.configuration).getItemImage2(requestParameters.itemId, requestParameters.imageType, requestParameters.imageIndex, requestParameters.maxWidth, requestParameters.maxHeight, requestParameters.width, requestParameters.height, requestParameters.quality, requestParameters.tag, requestParameters.cropWhitespace, requestParameters.format, requestParameters.addPlayedIndicator, requestParameters.percentPlayed, requestParameters.unplayedCount, requestParameters.blur, requestParameters.backgroundColor, requestParameters.foregroundLayer, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets the item\'s image.
     * @param {ImageApiGetItemImage20Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImageApi
     */
    public getItemImage2_1(requestParameters: ImageApiGetItemImage20Request, options?: any) {
        return ImageApiFp(this.configuration).getItemImage2_1(requestParameters.itemId, requestParameters.imageType, requestParameters.maxWidth, requestParameters.maxHeight, requestParameters.tag, requestParameters.format, requestParameters.percentPlayed, requestParameters.unplayedCount, requestParameters.imageIndex, requestParameters.width, requestParameters.height, requestParameters.quality, requestParameters.cropWhitespace, requestParameters.addPlayedIndicator, requestParameters.blur, requestParameters.backgroundColor, requestParameters.foregroundLayer, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get item image infos.
     * @param {ImageApiGetItemImageInfosRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImageApi
     */
    public getItemImageInfos(requestParameters: ImageApiGetItemImageInfosRequest, options?: any) {
        return ImageApiFp(this.configuration).getItemImageInfos(requestParameters.itemId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get music genre image by name.
     * @param {ImageApiGetMusicGenreImageRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImageApi
     */
    public getMusicGenreImage(requestParameters: ImageApiGetMusicGenreImageRequest, options?: any) {
        return ImageApiFp(this.configuration).getMusicGenreImage(requestParameters.name, requestParameters.imageType, requestParameters.imageIndex, requestParameters.tag, requestParameters.format, requestParameters.maxWidth, requestParameters.maxHeight, requestParameters.percentPlayed, requestParameters.unplayedCount, requestParameters.width, requestParameters.height, requestParameters.quality, requestParameters.cropWhitespace, requestParameters.addPlayedIndicator, requestParameters.blur, requestParameters.backgroundColor, requestParameters.foregroundLayer, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get person image by name.
     * @param {ImageApiGetPersonImageRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImageApi
     */
    public getPersonImage(requestParameters: ImageApiGetPersonImageRequest, options?: any) {
        return ImageApiFp(this.configuration).getPersonImage(requestParameters.name, requestParameters.imageType, requestParameters.imageIndex, requestParameters.tag, requestParameters.format, requestParameters.maxWidth, requestParameters.maxHeight, requestParameters.percentPlayed, requestParameters.unplayedCount, requestParameters.width, requestParameters.height, requestParameters.quality, requestParameters.cropWhitespace, requestParameters.addPlayedIndicator, requestParameters.blur, requestParameters.backgroundColor, requestParameters.foregroundLayer, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get studio image by name.
     * @param {ImageApiGetStudioImageRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImageApi
     */
    public getStudioImage(requestParameters: ImageApiGetStudioImageRequest, options?: any) {
        return ImageApiFp(this.configuration).getStudioImage(requestParameters.name, requestParameters.imageType, requestParameters.tag, requestParameters.format, requestParameters.imageIndex, requestParameters.maxWidth, requestParameters.maxHeight, requestParameters.percentPlayed, requestParameters.unplayedCount, requestParameters.width, requestParameters.height, requestParameters.quality, requestParameters.cropWhitespace, requestParameters.addPlayedIndicator, requestParameters.blur, requestParameters.backgroundColor, requestParameters.foregroundLayer, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get user profile image.
     * @param {ImageApiGetUserImageRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImageApi
     */
    public getUserImage(requestParameters: ImageApiGetUserImageRequest, options?: any) {
        return ImageApiFp(this.configuration).getUserImage(requestParameters.userId, requestParameters.imageType, requestParameters.imageIndex, requestParameters.tag, requestParameters.format, requestParameters.maxWidth, requestParameters.maxHeight, requestParameters.percentPlayed, requestParameters.unplayedCount, requestParameters.width, requestParameters.height, requestParameters.quality, requestParameters.cropWhitespace, requestParameters.addPlayedIndicator, requestParameters.blur, requestParameters.backgroundColor, requestParameters.foregroundLayer, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get artist image by name.
     * @param {ImageApiHeadArtistImageRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImageApi
     */
    public headArtistImage(requestParameters: ImageApiHeadArtistImageRequest, options?: any) {
        return ImageApiFp(this.configuration).headArtistImage(requestParameters.name, requestParameters.imageType, requestParameters.imageIndex, requestParameters.tag, requestParameters.format, requestParameters.maxWidth, requestParameters.maxHeight, requestParameters.percentPlayed, requestParameters.unplayedCount, requestParameters.width, requestParameters.height, requestParameters.quality, requestParameters.cropWhitespace, requestParameters.addPlayedIndicator, requestParameters.blur, requestParameters.backgroundColor, requestParameters.foregroundLayer, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get genre image by name.
     * @param {ImageApiHeadGenreImageRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImageApi
     */
    public headGenreImage(requestParameters: ImageApiHeadGenreImageRequest, options?: any) {
        return ImageApiFp(this.configuration).headGenreImage(requestParameters.name, requestParameters.imageType, requestParameters.imageIndex, requestParameters.tag, requestParameters.format, requestParameters.maxWidth, requestParameters.maxHeight, requestParameters.percentPlayed, requestParameters.unplayedCount, requestParameters.width, requestParameters.height, requestParameters.quality, requestParameters.cropWhitespace, requestParameters.addPlayedIndicator, requestParameters.blur, requestParameters.backgroundColor, requestParameters.foregroundLayer, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets the item\'s image.
     * @param {ImageApiHeadItemImageRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImageApi
     */
    public headItemImage(requestParameters: ImageApiHeadItemImageRequest, options?: any) {
        return ImageApiFp(this.configuration).headItemImage(requestParameters.itemId, requestParameters.imageType, requestParameters.imageIndex, requestParameters.maxWidth, requestParameters.maxHeight, requestParameters.width, requestParameters.height, requestParameters.quality, requestParameters.tag, requestParameters.cropWhitespace, requestParameters.format, requestParameters.addPlayedIndicator, requestParameters.percentPlayed, requestParameters.unplayedCount, requestParameters.blur, requestParameters.backgroundColor, requestParameters.foregroundLayer, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets the item\'s image.
     * @param {ImageApiHeadItemImage2Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImageApi
     */
    public headItemImage2(requestParameters: ImageApiHeadItemImage2Request, options?: any) {
        return ImageApiFp(this.configuration).headItemImage2(requestParameters.itemId, requestParameters.imageType, requestParameters.imageIndex, requestParameters.maxWidth, requestParameters.maxHeight, requestParameters.width, requestParameters.height, requestParameters.quality, requestParameters.tag, requestParameters.cropWhitespace, requestParameters.format, requestParameters.addPlayedIndicator, requestParameters.percentPlayed, requestParameters.unplayedCount, requestParameters.blur, requestParameters.backgroundColor, requestParameters.foregroundLayer, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets the item\'s image.
     * @param {ImageApiHeadItemImage20Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImageApi
     */
    public headItemImage2_2(requestParameters: ImageApiHeadItemImage20Request, options?: any) {
        return ImageApiFp(this.configuration).headItemImage2_2(requestParameters.itemId, requestParameters.imageType, requestParameters.maxWidth, requestParameters.maxHeight, requestParameters.tag, requestParameters.format, requestParameters.percentPlayed, requestParameters.unplayedCount, requestParameters.imageIndex, requestParameters.width, requestParameters.height, requestParameters.quality, requestParameters.cropWhitespace, requestParameters.addPlayedIndicator, requestParameters.blur, requestParameters.backgroundColor, requestParameters.foregroundLayer, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get music genre image by name.
     * @param {ImageApiHeadMusicGenreImageRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImageApi
     */
    public headMusicGenreImage(requestParameters: ImageApiHeadMusicGenreImageRequest, options?: any) {
        return ImageApiFp(this.configuration).headMusicGenreImage(requestParameters.name, requestParameters.imageType, requestParameters.imageIndex, requestParameters.tag, requestParameters.format, requestParameters.maxWidth, requestParameters.maxHeight, requestParameters.percentPlayed, requestParameters.unplayedCount, requestParameters.width, requestParameters.height, requestParameters.quality, requestParameters.cropWhitespace, requestParameters.addPlayedIndicator, requestParameters.blur, requestParameters.backgroundColor, requestParameters.foregroundLayer, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get person image by name.
     * @param {ImageApiHeadPersonImageRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImageApi
     */
    public headPersonImage(requestParameters: ImageApiHeadPersonImageRequest, options?: any) {
        return ImageApiFp(this.configuration).headPersonImage(requestParameters.name, requestParameters.imageType, requestParameters.imageIndex, requestParameters.tag, requestParameters.format, requestParameters.maxWidth, requestParameters.maxHeight, requestParameters.percentPlayed, requestParameters.unplayedCount, requestParameters.width, requestParameters.height, requestParameters.quality, requestParameters.cropWhitespace, requestParameters.addPlayedIndicator, requestParameters.blur, requestParameters.backgroundColor, requestParameters.foregroundLayer, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get studio image by name.
     * @param {ImageApiHeadStudioImageRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImageApi
     */
    public headStudioImage(requestParameters: ImageApiHeadStudioImageRequest, options?: any) {
        return ImageApiFp(this.configuration).headStudioImage(requestParameters.name, requestParameters.imageType, requestParameters.tag, requestParameters.format, requestParameters.imageIndex, requestParameters.maxWidth, requestParameters.maxHeight, requestParameters.percentPlayed, requestParameters.unplayedCount, requestParameters.width, requestParameters.height, requestParameters.quality, requestParameters.cropWhitespace, requestParameters.addPlayedIndicator, requestParameters.blur, requestParameters.backgroundColor, requestParameters.foregroundLayer, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get user profile image.
     * @param {ImageApiHeadUserImageRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImageApi
     */
    public headUserImage(requestParameters: ImageApiHeadUserImageRequest, options?: any) {
        return ImageApiFp(this.configuration).headUserImage(requestParameters.userId, requestParameters.imageType, requestParameters.imageIndex, requestParameters.tag, requestParameters.format, requestParameters.maxWidth, requestParameters.maxHeight, requestParameters.percentPlayed, requestParameters.unplayedCount, requestParameters.width, requestParameters.height, requestParameters.quality, requestParameters.cropWhitespace, requestParameters.addPlayedIndicator, requestParameters.blur, requestParameters.backgroundColor, requestParameters.foregroundLayer, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Sets the user image.
     * @param {ImageApiPostUserImageRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImageApi
     */
    public postUserImage(requestParameters: ImageApiPostUserImageRequest, options?: any) {
        return ImageApiFp(this.configuration).postUserImage(requestParameters.userId, requestParameters.imageType, requestParameters.index, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Sets the user image.
     * @param {ImageApiPostUserImage2Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImageApi
     */
    public postUserImage2(requestParameters: ImageApiPostUserImage2Request, options?: any) {
        return ImageApiFp(this.configuration).postUserImage2(requestParameters.userId, requestParameters.imageType, requestParameters.index, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Set item image.
     * @param {ImageApiSetItemImageRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImageApi
     */
    public setItemImage(requestParameters: ImageApiSetItemImageRequest, options?: any) {
        return ImageApiFp(this.configuration).setItemImage(requestParameters.itemId, requestParameters.imageType, requestParameters.imageIndex, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Set item image.
     * @param {ImageApiSetItemImage2Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImageApi
     */
    public setItemImage2(requestParameters: ImageApiSetItemImage2Request, options?: any) {
        return ImageApiFp(this.configuration).setItemImage2(requestParameters.itemId, requestParameters.imageType, requestParameters.imageIndex, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates the index for an item image.
     * @param {ImageApiUpdateItemImageIndexRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImageApi
     */
    public updateItemImageIndex(requestParameters: ImageApiUpdateItemImageIndexRequest, options?: any) {
        return ImageApiFp(this.configuration).updateItemImageIndex(requestParameters.itemId, requestParameters.imageType, requestParameters.imageIndex, requestParameters.newIndex, options).then((request) => request(this.axios, this.basePath));
    }
}
