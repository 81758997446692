/* tslint:disable */
/* eslint-disable */
/**
 * Jellyfin API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { SearchHintResult } from '../models';
/**
 * SearchApi - axios parameter creator
 * @export
 */
export const SearchApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Gets the search hint result.
         * @param {string} searchTerm The search term to filter on.
         * @param {number} [startIndex] Optional. The record index to start at. All items with a lower index will be dropped from the results.
         * @param {number} [limit] Optional. The maximum number of records to return.
         * @param {string} [userId] Optional. Supply a user id to search within a user\&#39;s library or omit to search all.
         * @param {string} [includeItemTypes] If specified, only results with the specified item types are returned. This allows multiple, comma delimeted.
         * @param {string} [excludeItemTypes] If specified, results with these item types are filtered out. This allows multiple, comma delimeted.
         * @param {string} [mediaTypes] If specified, only results with the specified media types are returned. This allows multiple, comma delimeted.
         * @param {string} [parentId] If specified, only children of the parent are returned.
         * @param {boolean} [isMovie] Optional filter for movies.
         * @param {boolean} [isSeries] Optional filter for series.
         * @param {boolean} [isNews] Optional filter for news.
         * @param {boolean} [isKids] Optional filter for kids.
         * @param {boolean} [isSports] Optional filter for sports.
         * @param {boolean} [includePeople] Optional filter whether to include people.
         * @param {boolean} [includeMedia] Optional filter whether to include media.
         * @param {boolean} [includeGenres] Optional filter whether to include genres.
         * @param {boolean} [includeStudios] Optional filter whether to include studios.
         * @param {boolean} [includeArtists] Optional filter whether to include artists.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get: async (searchTerm: string, startIndex?: number, limit?: number, userId?: string, includeItemTypes?: string, excludeItemTypes?: string, mediaTypes?: string, parentId?: string, isMovie?: boolean, isSeries?: boolean, isNews?: boolean, isKids?: boolean, isSports?: boolean, includePeople?: boolean, includeMedia?: boolean, includeGenres?: boolean, includeStudios?: boolean, includeArtists?: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'searchTerm' is not null or undefined
            if (searchTerm === null || searchTerm === undefined) {
                throw new RequiredError('searchTerm','Required parameter searchTerm was null or undefined when calling get.');
            }
            const localVarPath = `/Search/Hints`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CustomAuthentication required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("X-Emby-Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["X-Emby-Authorization"] = localVarApiKeyValue;
            }

            if (startIndex !== undefined) {
                localVarQueryParameter['startIndex'] = startIndex;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }

            if (searchTerm !== undefined) {
                localVarQueryParameter['searchTerm'] = searchTerm;
            }

            if (includeItemTypes !== undefined) {
                localVarQueryParameter['includeItemTypes'] = includeItemTypes;
            }

            if (excludeItemTypes !== undefined) {
                localVarQueryParameter['excludeItemTypes'] = excludeItemTypes;
            }

            if (mediaTypes !== undefined) {
                localVarQueryParameter['mediaTypes'] = mediaTypes;
            }

            if (parentId !== undefined) {
                localVarQueryParameter['parentId'] = parentId;
            }

            if (isMovie !== undefined) {
                localVarQueryParameter['isMovie'] = isMovie;
            }

            if (isSeries !== undefined) {
                localVarQueryParameter['isSeries'] = isSeries;
            }

            if (isNews !== undefined) {
                localVarQueryParameter['isNews'] = isNews;
            }

            if (isKids !== undefined) {
                localVarQueryParameter['isKids'] = isKids;
            }

            if (isSports !== undefined) {
                localVarQueryParameter['isSports'] = isSports;
            }

            if (includePeople !== undefined) {
                localVarQueryParameter['includePeople'] = includePeople;
            }

            if (includeMedia !== undefined) {
                localVarQueryParameter['includeMedia'] = includeMedia;
            }

            if (includeGenres !== undefined) {
                localVarQueryParameter['includeGenres'] = includeGenres;
            }

            if (includeStudios !== undefined) {
                localVarQueryParameter['includeStudios'] = includeStudios;
            }

            if (includeArtists !== undefined) {
                localVarQueryParameter['includeArtists'] = includeArtists;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SearchApi - functional programming interface
 * @export
 */
export const SearchApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Gets the search hint result.
         * @param {string} searchTerm The search term to filter on.
         * @param {number} [startIndex] Optional. The record index to start at. All items with a lower index will be dropped from the results.
         * @param {number} [limit] Optional. The maximum number of records to return.
         * @param {string} [userId] Optional. Supply a user id to search within a user\&#39;s library or omit to search all.
         * @param {string} [includeItemTypes] If specified, only results with the specified item types are returned. This allows multiple, comma delimeted.
         * @param {string} [excludeItemTypes] If specified, results with these item types are filtered out. This allows multiple, comma delimeted.
         * @param {string} [mediaTypes] If specified, only results with the specified media types are returned. This allows multiple, comma delimeted.
         * @param {string} [parentId] If specified, only children of the parent are returned.
         * @param {boolean} [isMovie] Optional filter for movies.
         * @param {boolean} [isSeries] Optional filter for series.
         * @param {boolean} [isNews] Optional filter for news.
         * @param {boolean} [isKids] Optional filter for kids.
         * @param {boolean} [isSports] Optional filter for sports.
         * @param {boolean} [includePeople] Optional filter whether to include people.
         * @param {boolean} [includeMedia] Optional filter whether to include media.
         * @param {boolean} [includeGenres] Optional filter whether to include genres.
         * @param {boolean} [includeStudios] Optional filter whether to include studios.
         * @param {boolean} [includeArtists] Optional filter whether to include artists.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async get(searchTerm: string, startIndex?: number, limit?: number, userId?: string, includeItemTypes?: string, excludeItemTypes?: string, mediaTypes?: string, parentId?: string, isMovie?: boolean, isSeries?: boolean, isNews?: boolean, isKids?: boolean, isSports?: boolean, includePeople?: boolean, includeMedia?: boolean, includeGenres?: boolean, includeStudios?: boolean, includeArtists?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SearchHintResult>> {
            const localVarAxiosArgs = await SearchApiAxiosParamCreator(configuration).get(searchTerm, startIndex, limit, userId, includeItemTypes, excludeItemTypes, mediaTypes, parentId, isMovie, isSeries, isNews, isKids, isSports, includePeople, includeMedia, includeGenres, includeStudios, includeArtists, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * SearchApi - factory interface
 * @export
 */
export const SearchApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Gets the search hint result.
         * @param {string} searchTerm The search term to filter on.
         * @param {number} [startIndex] Optional. The record index to start at. All items with a lower index will be dropped from the results.
         * @param {number} [limit] Optional. The maximum number of records to return.
         * @param {string} [userId] Optional. Supply a user id to search within a user\&#39;s library or omit to search all.
         * @param {string} [includeItemTypes] If specified, only results with the specified item types are returned. This allows multiple, comma delimeted.
         * @param {string} [excludeItemTypes] If specified, results with these item types are filtered out. This allows multiple, comma delimeted.
         * @param {string} [mediaTypes] If specified, only results with the specified media types are returned. This allows multiple, comma delimeted.
         * @param {string} [parentId] If specified, only children of the parent are returned.
         * @param {boolean} [isMovie] Optional filter for movies.
         * @param {boolean} [isSeries] Optional filter for series.
         * @param {boolean} [isNews] Optional filter for news.
         * @param {boolean} [isKids] Optional filter for kids.
         * @param {boolean} [isSports] Optional filter for sports.
         * @param {boolean} [includePeople] Optional filter whether to include people.
         * @param {boolean} [includeMedia] Optional filter whether to include media.
         * @param {boolean} [includeGenres] Optional filter whether to include genres.
         * @param {boolean} [includeStudios] Optional filter whether to include studios.
         * @param {boolean} [includeArtists] Optional filter whether to include artists.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get(searchTerm: string, startIndex?: number, limit?: number, userId?: string, includeItemTypes?: string, excludeItemTypes?: string, mediaTypes?: string, parentId?: string, isMovie?: boolean, isSeries?: boolean, isNews?: boolean, isKids?: boolean, isSports?: boolean, includePeople?: boolean, includeMedia?: boolean, includeGenres?: boolean, includeStudios?: boolean, includeArtists?: boolean, options?: any): AxiosPromise<SearchHintResult> {
            return SearchApiFp(configuration).get(searchTerm, startIndex, limit, userId, includeItemTypes, excludeItemTypes, mediaTypes, parentId, isMovie, isSeries, isNews, isKids, isSports, includePeople, includeMedia, includeGenres, includeStudios, includeArtists, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for get operation in SearchApi.
 * @export
 * @interface SearchApiGetRequest
 */
export interface SearchApiGetRequest {
    /**
     * The search term to filter on.
     * @type {string}
     * @memberof SearchApiGet
     */
    readonly searchTerm: string

    /**
     * Optional. The record index to start at. All items with a lower index will be dropped from the results.
     * @type {number}
     * @memberof SearchApiGet
     */
    readonly startIndex?: number

    /**
     * Optional. The maximum number of records to return.
     * @type {number}
     * @memberof SearchApiGet
     */
    readonly limit?: number

    /**
     * Optional. Supply a user id to search within a user\&#39;s library or omit to search all.
     * @type {string}
     * @memberof SearchApiGet
     */
    readonly userId?: string

    /**
     * If specified, only results with the specified item types are returned. This allows multiple, comma delimeted.
     * @type {string}
     * @memberof SearchApiGet
     */
    readonly includeItemTypes?: string

    /**
     * If specified, results with these item types are filtered out. This allows multiple, comma delimeted.
     * @type {string}
     * @memberof SearchApiGet
     */
    readonly excludeItemTypes?: string

    /**
     * If specified, only results with the specified media types are returned. This allows multiple, comma delimeted.
     * @type {string}
     * @memberof SearchApiGet
     */
    readonly mediaTypes?: string

    /**
     * If specified, only children of the parent are returned.
     * @type {string}
     * @memberof SearchApiGet
     */
    readonly parentId?: string

    /**
     * Optional filter for movies.
     * @type {boolean}
     * @memberof SearchApiGet
     */
    readonly isMovie?: boolean

    /**
     * Optional filter for series.
     * @type {boolean}
     * @memberof SearchApiGet
     */
    readonly isSeries?: boolean

    /**
     * Optional filter for news.
     * @type {boolean}
     * @memberof SearchApiGet
     */
    readonly isNews?: boolean

    /**
     * Optional filter for kids.
     * @type {boolean}
     * @memberof SearchApiGet
     */
    readonly isKids?: boolean

    /**
     * Optional filter for sports.
     * @type {boolean}
     * @memberof SearchApiGet
     */
    readonly isSports?: boolean

    /**
     * Optional filter whether to include people.
     * @type {boolean}
     * @memberof SearchApiGet
     */
    readonly includePeople?: boolean

    /**
     * Optional filter whether to include media.
     * @type {boolean}
     * @memberof SearchApiGet
     */
    readonly includeMedia?: boolean

    /**
     * Optional filter whether to include genres.
     * @type {boolean}
     * @memberof SearchApiGet
     */
    readonly includeGenres?: boolean

    /**
     * Optional filter whether to include studios.
     * @type {boolean}
     * @memberof SearchApiGet
     */
    readonly includeStudios?: boolean

    /**
     * Optional filter whether to include artists.
     * @type {boolean}
     * @memberof SearchApiGet
     */
    readonly includeArtists?: boolean
}

/**
 * SearchApi - object-oriented interface
 * @export
 * @class SearchApi
 * @extends {BaseAPI}
 */
export class SearchApi extends BaseAPI {
    /**
     * 
     * @summary Gets the search hint result.
     * @param {SearchApiGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SearchApi
     */
    public get(requestParameters: SearchApiGetRequest, options?: any) {
        return SearchApiFp(this.configuration).get(requestParameters.searchTerm, requestParameters.startIndex, requestParameters.limit, requestParameters.userId, requestParameters.includeItemTypes, requestParameters.excludeItemTypes, requestParameters.mediaTypes, requestParameters.parentId, requestParameters.isMovie, requestParameters.isSeries, requestParameters.isNews, requestParameters.isKids, requestParameters.isSports, requestParameters.includePeople, requestParameters.includeMedia, requestParameters.includeGenres, requestParameters.includeStudios, requestParameters.includeArtists, options).then((request) => request(this.axios, this.basePath));
    }
}
