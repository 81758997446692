/* tslint:disable */
/* eslint-disable */
/**
 * Jellyfin API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { ImageSavingConvention } from './image-saving-convention';
import { MetadataOptions } from './metadata-options';
import { NameValuePair } from './name-value-pair';
import { PathSubstitution } from './path-substitution';
import { RepositoryInfo } from './repository-info';
import { Version } from './version';

/**
 * Represents the server configuration.
 * @export
 * @interface ServerConfiguration
 */
export interface ServerConfiguration {
    /**
     * Gets or sets the number of days we should retain log files.
     * @type {number}
     * @memberof ServerConfiguration
     */
    LogFileRetentionDays?: number;
    /**
     * Gets or sets a value indicating whether this instance is first run.
     * @type {boolean}
     * @memberof ServerConfiguration
     */
    IsStartupWizardCompleted?: boolean;
    /**
     * Gets or sets the cache path.
     * @type {string}
     * @memberof ServerConfiguration
     */
    CachePath?: string | null;
    /**
     * 
     * @type {Version}
     * @memberof ServerConfiguration
     */
    PreviousVersion?: Version;
    /**
     * Gets or sets the stringified PreviousVersion to be stored/loaded,  because System.Version itself isn\'t xml-serializable.
     * @type {string}
     * @memberof ServerConfiguration
     */
    PreviousVersionStr?: string | null;
    /**
     * Gets or sets a value indicating whether to enable automatic port forwarding.
     * @type {boolean}
     * @memberof ServerConfiguration
     */
    EnableUPnP?: boolean;
    /**
     * Gets or sets a value indicating whether to enable prometheus metrics exporting.
     * @type {boolean}
     * @memberof ServerConfiguration
     */
    EnableMetrics?: boolean;
    /**
     * Gets or sets the public mapped port.
     * @type {number}
     * @memberof ServerConfiguration
     */
    PublicPort?: number;
    /**
     * Gets or sets the public HTTPS port.
     * @type {number}
     * @memberof ServerConfiguration
     */
    PublicHttpsPort?: number;
    /**
     * Gets or sets the HTTP server port number.
     * @type {number}
     * @memberof ServerConfiguration
     */
    HttpServerPortNumber?: number;
    /**
     * Gets or sets the HTTPS server port number.
     * @type {number}
     * @memberof ServerConfiguration
     */
    HttpsPortNumber?: number;
    /**
     * Gets or sets a value indicating whether to use HTTPS.
     * @type {boolean}
     * @memberof ServerConfiguration
     */
    EnableHttps?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ServerConfiguration
     */
    EnableNormalizedItemByNameIds?: boolean;
    /**
     * Gets or sets the filesystem path of an X.509 certificate to use for SSL.
     * @type {string}
     * @memberof ServerConfiguration
     */
    CertificatePath?: string | null;
    /**
     * Gets or sets the password required to access the X.509 certificate data in the file specified by MediaBrowser.Model.Configuration.ServerConfiguration.CertificatePath.
     * @type {string}
     * @memberof ServerConfiguration
     */
    CertificatePassword?: string | null;
    /**
     * Gets or sets a value indicating whether this instance is port authorized.
     * @type {boolean}
     * @memberof ServerConfiguration
     */
    IsPortAuthorized?: boolean;
    /**
     * Gets or sets if quick connect is available for use on this server.
     * @type {boolean}
     * @memberof ServerConfiguration
     */
    QuickConnectAvailable?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ServerConfiguration
     */
    EnableRemoteAccess?: boolean;
    /**
     * Gets or sets a value indicating whether [enable case sensitive item ids].
     * @type {boolean}
     * @memberof ServerConfiguration
     */
    EnableCaseSensitiveItemIds?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ServerConfiguration
     */
    DisableLiveTvChannelUserDataName?: boolean;
    /**
     * Gets or sets the metadata path.
     * @type {string}
     * @memberof ServerConfiguration
     */
    MetadataPath?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ServerConfiguration
     */
    MetadataNetworkPath?: string | null;
    /**
     * Gets or sets the preferred metadata language.
     * @type {string}
     * @memberof ServerConfiguration
     */
    PreferredMetadataLanguage?: string | null;
    /**
     * Gets or sets the metadata country code.
     * @type {string}
     * @memberof ServerConfiguration
     */
    MetadataCountryCode?: string | null;
    /**
     * Characters to be replaced with a \' \' in strings to create a sort name.
     * @type {Array<string>}
     * @memberof ServerConfiguration
     */
    SortReplaceCharacters?: Array<string> | null;
    /**
     * Characters to be removed from strings to create a sort name.
     * @type {Array<string>}
     * @memberof ServerConfiguration
     */
    SortRemoveCharacters?: Array<string> | null;
    /**
     * Words to be removed from strings to create a sort name.
     * @type {Array<string>}
     * @memberof ServerConfiguration
     */
    SortRemoveWords?: Array<string> | null;
    /**
     * Gets or sets the minimum percentage of an item that must be played in order for playstate to be updated.
     * @type {number}
     * @memberof ServerConfiguration
     */
    MinResumePct?: number;
    /**
     * Gets or sets the maximum percentage of an item that can be played while still saving playstate. If this percentage is crossed playstate will be reset to the beginning and the item will be marked watched.
     * @type {number}
     * @memberof ServerConfiguration
     */
    MaxResumePct?: number;
    /**
     * Gets or sets the minimum duration that an item must have in order to be eligible for playstate updates..
     * @type {number}
     * @memberof ServerConfiguration
     */
    MinResumeDurationSeconds?: number;
    /**
     * The delay in seconds that we will wait after a file system change to try and discover what has been added/removed  Some delay is necessary with some items because their creation is not atomic.  It involves the creation of several  different directories and files.
     * @type {number}
     * @memberof ServerConfiguration
     */
    LibraryMonitorDelay?: number;
    /**
     * Gets or sets a value indicating whether [enable dashboard response caching].  Allows potential contributors without visual studio to modify production dashboard code and test changes.
     * @type {boolean}
     * @memberof ServerConfiguration
     */
    EnableDashboardResponseCaching?: boolean;
    /**
     * 
     * @type {ImageSavingConvention}
     * @memberof ServerConfiguration
     */
    ImageSavingConvention?: ImageSavingConvention;
    /**
     * 
     * @type {Array<MetadataOptions>}
     * @memberof ServerConfiguration
     */
    MetadataOptions?: Array<MetadataOptions> | null;
    /**
     * 
     * @type {boolean}
     * @memberof ServerConfiguration
     */
    SkipDeserializationForBasicTypes?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ServerConfiguration
     */
    ServerName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ServerConfiguration
     */
    BaseUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ServerConfiguration
     */
    UICulture?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ServerConfiguration
     */
    SaveMetadataHidden?: boolean;
    /**
     * 
     * @type {Array<NameValuePair>}
     * @memberof ServerConfiguration
     */
    ContentTypes?: Array<NameValuePair> | null;
    /**
     * 
     * @type {number}
     * @memberof ServerConfiguration
     */
    RemoteClientBitrateLimit?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ServerConfiguration
     */
    EnableFolderView?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ServerConfiguration
     */
    EnableGroupingIntoCollections?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ServerConfiguration
     */
    DisplaySpecialsWithinSeasons?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof ServerConfiguration
     */
    LocalNetworkSubnets?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ServerConfiguration
     */
    LocalNetworkAddresses?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ServerConfiguration
     */
    CodecsUsed?: Array<string> | null;
    /**
     * 
     * @type {Array<RepositoryInfo>}
     * @memberof ServerConfiguration
     */
    PluginRepositories?: Array<RepositoryInfo> | null;
    /**
     * 
     * @type {boolean}
     * @memberof ServerConfiguration
     */
    IgnoreVirtualInterfaces?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ServerConfiguration
     */
    EnableExternalContentInSuggestions?: boolean;
    /**
     * Gets or sets a value indicating whether the server should force connections over HTTPS.
     * @type {boolean}
     * @memberof ServerConfiguration
     */
    RequireHttps?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ServerConfiguration
     */
    EnableNewOmdbSupport?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof ServerConfiguration
     */
    RemoteIPFilter?: Array<string> | null;
    /**
     * 
     * @type {boolean}
     * @memberof ServerConfiguration
     */
    IsRemoteIPFilterBlacklist?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ServerConfiguration
     */
    ImageExtractionTimeoutMs?: number;
    /**
     * 
     * @type {Array<PathSubstitution>}
     * @memberof ServerConfiguration
     */
    PathSubstitutions?: Array<PathSubstitution> | null;
    /**
     * 
     * @type {boolean}
     * @memberof ServerConfiguration
     */
    EnableSimpleArtistDetection?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof ServerConfiguration
     */
    UninstalledPlugins?: Array<string> | null;
    /**
     * Gets or sets a value indicating whether slow server responses should be logged as a warning.
     * @type {boolean}
     * @memberof ServerConfiguration
     */
    EnableSlowResponseWarning?: boolean;
    /**
     * Gets or sets the threshold for the slow response time warning in ms.
     * @type {number}
     * @memberof ServerConfiguration
     */
    SlowResponseThresholdMs?: number;
    /**
     * Gets or sets the cors hosts.
     * @type {Array<string>}
     * @memberof ServerConfiguration
     */
    CorsHosts?: Array<string> | null;
    /**
     * Gets or sets the known proxies.
     * @type {Array<string>}
     * @memberof ServerConfiguration
     */
    KnownProxies?: Array<string> | null;
}


