/* tslint:disable */
/* eslint-disable */
/**
 * Jellyfin API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * Class CultureDto.
 * @export
 * @interface CultureDto
 */
export interface CultureDto {
    /**
     * Gets or sets the name.
     * @type {string}
     * @memberof CultureDto
     */
    Name?: string | null;
    /**
     * Gets or sets the display name.
     * @type {string}
     * @memberof CultureDto
     */
    DisplayName?: string | null;
    /**
     * Gets or sets the name of the two letter ISO language.
     * @type {string}
     * @memberof CultureDto
     */
    TwoLetterISOLanguageName?: string | null;
    /**
     * Gets or sets the name of the three letter ISO language.
     * @type {string}
     * @memberof CultureDto
     */
    ThreeLetterISOLanguageName?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof CultureDto
     */
    ThreeLetterISOLanguageNames?: Array<string> | null;
}


