/* tslint:disable */
/* eslint-disable */
/**
 * Jellyfin API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * Stores the result of an incoming quick connect request.
 * @export
 * @interface QuickConnectResult
 */
export interface QuickConnectResult {
    /**
     * Gets a value indicating whether this request is authorized.
     * @type {boolean}
     * @memberof QuickConnectResult
     */
    Authenticated?: boolean;
    /**
     * Gets or sets the secret value used to uniquely identify this request. Can be used to retrieve authentication information.
     * @type {string}
     * @memberof QuickConnectResult
     */
    Secret?: string | null;
    /**
     * Gets or sets the user facing code used so the user can quickly differentiate this request from others.
     * @type {string}
     * @memberof QuickConnectResult
     */
    Code?: string | null;
    /**
     * Gets or sets the private access token.
     * @type {string}
     * @memberof QuickConnectResult
     */
    Authentication?: string | null;
    /**
     * Gets or sets an error message.
     * @type {string}
     * @memberof QuickConnectResult
     */
    Error?: string | null;
    /**
     * Gets or sets the DateTime that this request was created.
     * @type {string}
     * @memberof QuickConnectResult
     */
    DateAdded?: string | null;
}


