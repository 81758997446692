/* tslint:disable */
/* eslint-disable */
/**
 * Jellyfin API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * Enum VideoType.
 * @export
 * @enum {string}
 */
export enum VideoType {
    VideoFile = 'VideoFile',
    Iso = 'Iso',
    Dvd = 'Dvd',
    BluRay = 'BluRay'
}



