/* tslint:disable */
/* eslint-disable */
/**
 * Jellyfin API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { LibraryOptions } from './library-options';

/**
 * Add virtual folder dto.
 * @export
 * @interface AddVirtualFolderDto
 */
export interface AddVirtualFolderDto {
    /**
     * 
     * @type {LibraryOptions}
     * @memberof AddVirtualFolderDto
     */
    LibraryOptions?: LibraryOptions;
}


