/* tslint:disable */
/* eslint-disable */
/**
 * Jellyfin API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * Enum ItemFilter.
 * @export
 * @enum {string}
 */
export enum ItemFilter {
    IsFolder = 'IsFolder',
    IsNotFolder = 'IsNotFolder',
    IsUnplayed = 'IsUnplayed',
    IsPlayed = 'IsPlayed',
    IsFavorite = 'IsFavorite',
    IsResumable = 'IsResumable',
    Likes = 'Likes',
    Dislikes = 'Dislikes',
    IsFavoriteOrLikes = 'IsFavoriteOrLikes'
}



