/* tslint:disable */
/* eslint-disable */
/**
 * Jellyfin API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { BaseItemDtoImageBlurHashes } from './base-item-dto-image-blur-hashes';
import { BaseItemDtoImageTags } from './base-item-dto-image-tags';
import { BaseItemPerson } from './base-item-person';
import { ChannelType } from './channel-type';
import { ChapterInfo } from './chapter-info';
import { DayOfWeek } from './day-of-week';
import { ExternalUrl } from './external-url';
import { ImageOrientation } from './image-orientation';
import { IsoType } from './iso-type';
import { LocationType } from './location-type';
import { MediaSourceInfo } from './media-source-info';
import { MediaStream } from './media-stream';
import { MediaUrl } from './media-url';
import { MetadataField } from './metadata-field';
import { NameGuidPair } from './name-guid-pair';
import { PlayAccess } from './play-access';
import { ProgramAudio } from './program-audio';
import { UserItemDataDto } from './user-item-data-dto';
import { Video3DFormat } from './video3-dformat';
import { VideoType } from './video-type';

/**
 * This is strictly used as a data transfer object from the api layer.  This holds information about a BaseItem in a format that is convenient for the client.
 * @export
 * @interface BaseItemDto
 */
export interface BaseItemDto {
    /**
     * Gets or sets the name.
     * @type {string}
     * @memberof BaseItemDto
     */
    Name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BaseItemDto
     */
    OriginalTitle?: string | null;
    /**
     * Gets or sets the server identifier.
     * @type {string}
     * @memberof BaseItemDto
     */
    ServerId?: string | null;
    /**
     * Gets or sets the id.
     * @type {string}
     * @memberof BaseItemDto
     */
    Id?: string;
    /**
     * Gets or sets the etag.
     * @type {string}
     * @memberof BaseItemDto
     */
    Etag?: string | null;
    /**
     * Gets or sets the type of the source.
     * @type {string}
     * @memberof BaseItemDto
     */
    SourceType?: string | null;
    /**
     * Gets or sets the playlist item identifier.
     * @type {string}
     * @memberof BaseItemDto
     */
    PlaylistItemId?: string | null;
    /**
     * Gets or sets the date created.
     * @type {string}
     * @memberof BaseItemDto
     */
    DateCreated?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BaseItemDto
     */
    DateLastMediaAdded?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BaseItemDto
     */
    ExtraType?: string | null;
    /**
     * 
     * @type {number}
     * @memberof BaseItemDto
     */
    AirsBeforeSeasonNumber?: number | null;
    /**
     * 
     * @type {number}
     * @memberof BaseItemDto
     */
    AirsAfterSeasonNumber?: number | null;
    /**
     * 
     * @type {number}
     * @memberof BaseItemDto
     */
    AirsBeforeEpisodeNumber?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof BaseItemDto
     */
    CanDelete?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof BaseItemDto
     */
    CanDownload?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof BaseItemDto
     */
    HasSubtitles?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof BaseItemDto
     */
    PreferredMetadataLanguage?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BaseItemDto
     */
    PreferredMetadataCountryCode?: string | null;
    /**
     * Gets or sets a value indicating whether [supports synchronize].
     * @type {boolean}
     * @memberof BaseItemDto
     */
    SupportsSync?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof BaseItemDto
     */
    Container?: string | null;
    /**
     * Gets or sets the name of the sort.
     * @type {string}
     * @memberof BaseItemDto
     */
    SortName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BaseItemDto
     */
    ForcedSortName?: string | null;
    /**
     * 
     * @type {Video3DFormat}
     * @memberof BaseItemDto
     */
    Video3DFormat?: Video3DFormat;
    /**
     * Gets or sets the premiere date.
     * @type {string}
     * @memberof BaseItemDto
     */
    PremiereDate?: string | null;
    /**
     * Gets or sets the external urls.
     * @type {Array<ExternalUrl>}
     * @memberof BaseItemDto
     */
    ExternalUrls?: Array<ExternalUrl> | null;
    /**
     * Gets or sets the media versions.
     * @type {Array<MediaSourceInfo>}
     * @memberof BaseItemDto
     */
    MediaSources?: Array<MediaSourceInfo> | null;
    /**
     * Gets or sets the critic rating.
     * @type {number}
     * @memberof BaseItemDto
     */
    CriticRating?: number | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof BaseItemDto
     */
    ProductionLocations?: Array<string> | null;
    /**
     * Gets or sets the path.
     * @type {string}
     * @memberof BaseItemDto
     */
    Path?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof BaseItemDto
     */
    EnableMediaSourceDisplay?: boolean | null;
    /**
     * Gets or sets the official rating.
     * @type {string}
     * @memberof BaseItemDto
     */
    OfficialRating?: string | null;
    /**
     * Gets or sets the custom rating.
     * @type {string}
     * @memberof BaseItemDto
     */
    CustomRating?: string | null;
    /**
     * Gets or sets the channel identifier.
     * @type {string}
     * @memberof BaseItemDto
     */
    ChannelId?: string;
    /**
     * 
     * @type {string}
     * @memberof BaseItemDto
     */
    ChannelName?: string | null;
    /**
     * Gets or sets the overview.
     * @type {string}
     * @memberof BaseItemDto
     */
    Overview?: string | null;
    /**
     * Gets or sets the taglines.
     * @type {Array<string>}
     * @memberof BaseItemDto
     */
    Taglines?: Array<string> | null;
    /**
     * Gets or sets the genres.
     * @type {Array<string>}
     * @memberof BaseItemDto
     */
    Genres?: Array<string> | null;
    /**
     * Gets or sets the community rating.
     * @type {number}
     * @memberof BaseItemDto
     */
    CommunityRating?: number | null;
    /**
     * Gets or sets the cumulative run time ticks.
     * @type {number}
     * @memberof BaseItemDto
     */
    CumulativeRunTimeTicks?: number | null;
    /**
     * Gets or sets the run time ticks.
     * @type {number}
     * @memberof BaseItemDto
     */
    RunTimeTicks?: number | null;
    /**
     * 
     * @type {PlayAccess}
     * @memberof BaseItemDto
     */
    PlayAccess?: PlayAccess;
    /**
     * Gets or sets the aspect ratio.
     * @type {string}
     * @memberof BaseItemDto
     */
    AspectRatio?: string | null;
    /**
     * Gets or sets the production year.
     * @type {number}
     * @memberof BaseItemDto
     */
    ProductionYear?: number | null;
    /**
     * Gets or sets a value indicating whether this instance is place holder.
     * @type {boolean}
     * @memberof BaseItemDto
     */
    IsPlaceHolder?: boolean | null;
    /**
     * Gets or sets the number.
     * @type {string}
     * @memberof BaseItemDto
     */
    Number?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BaseItemDto
     */
    ChannelNumber?: string | null;
    /**
     * Gets or sets the index number.
     * @type {number}
     * @memberof BaseItemDto
     */
    IndexNumber?: number | null;
    /**
     * Gets or sets the index number end.
     * @type {number}
     * @memberof BaseItemDto
     */
    IndexNumberEnd?: number | null;
    /**
     * Gets or sets the parent index number.
     * @type {number}
     * @memberof BaseItemDto
     */
    ParentIndexNumber?: number | null;
    /**
     * Gets or sets the trailer urls.
     * @type {Array<MediaUrl>}
     * @memberof BaseItemDto
     */
    RemoteTrailers?: Array<MediaUrl> | null;
    /**
     * Gets or sets the provider ids.
     * @type {{ [key: string]: string; }}
     * @memberof BaseItemDto
     */
    ProviderIds?: { [key: string]: string; } | null;
    /**
     * Gets or sets a value indicating whether this instance is HD.
     * @type {boolean}
     * @memberof BaseItemDto
     */
    IsHD?: boolean | null;
    /**
     * Gets or sets a value indicating whether this instance is folder.
     * @type {boolean}
     * @memberof BaseItemDto
     */
    IsFolder?: boolean | null;
    /**
     * Gets or sets the parent id.
     * @type {string}
     * @memberof BaseItemDto
     */
    ParentId?: string;
    /**
     * Gets or sets the type.
     * @type {string}
     * @memberof BaseItemDto
     */
    Type?: string | null;
    /**
     * Gets or sets the people.
     * @type {Array<BaseItemPerson>}
     * @memberof BaseItemDto
     */
    People?: Array<BaseItemPerson> | null;
    /**
     * Gets or sets the studios.
     * @type {Array<NameGuidPair>}
     * @memberof BaseItemDto
     */
    Studios?: Array<NameGuidPair> | null;
    /**
     * 
     * @type {Array<NameGuidPair>}
     * @memberof BaseItemDto
     */
    GenreItems?: Array<NameGuidPair> | null;
    /**
     * If the item does not have a logo, this will hold the Id of the Parent that has one.
     * @type {string}
     * @memberof BaseItemDto
     */
    ParentLogoItemId?: string | null;
    /**
     * If the item does not have any backdrops, this will hold the Id of the Parent that has one.
     * @type {string}
     * @memberof BaseItemDto
     */
    ParentBackdropItemId?: string | null;
    /**
     * Gets or sets the parent backdrop image tags.
     * @type {Array<string>}
     * @memberof BaseItemDto
     */
    ParentBackdropImageTags?: Array<string> | null;
    /**
     * Gets or sets the local trailer count.
     * @type {number}
     * @memberof BaseItemDto
     */
    LocalTrailerCount?: number | null;
    /**
     * 
     * @type {UserItemDataDto}
     * @memberof BaseItemDto
     */
    UserData?: UserItemDataDto;
    /**
     * Gets or sets the recursive item count.
     * @type {number}
     * @memberof BaseItemDto
     */
    RecursiveItemCount?: number | null;
    /**
     * Gets or sets the child count.
     * @type {number}
     * @memberof BaseItemDto
     */
    ChildCount?: number | null;
    /**
     * Gets or sets the name of the series.
     * @type {string}
     * @memberof BaseItemDto
     */
    SeriesName?: string | null;
    /**
     * Gets or sets the series id.
     * @type {string}
     * @memberof BaseItemDto
     */
    SeriesId?: string;
    /**
     * Gets or sets the season identifier.
     * @type {string}
     * @memberof BaseItemDto
     */
    SeasonId?: string;
    /**
     * Gets or sets the special feature count.
     * @type {number}
     * @memberof BaseItemDto
     */
    SpecialFeatureCount?: number | null;
    /**
     * Gets or sets the display preferences id.
     * @type {string}
     * @memberof BaseItemDto
     */
    DisplayPreferencesId?: string | null;
    /**
     * Gets or sets the status.
     * @type {string}
     * @memberof BaseItemDto
     */
    Status?: string | null;
    /**
     * Gets or sets the air time.
     * @type {string}
     * @memberof BaseItemDto
     */
    AirTime?: string | null;
    /**
     * Gets or sets the air days.
     * @type {Array<DayOfWeek>}
     * @memberof BaseItemDto
     */
    AirDays?: Array<DayOfWeek> | null;
    /**
     * Gets or sets the tags.
     * @type {Array<string>}
     * @memberof BaseItemDto
     */
    Tags?: Array<string> | null;
    /**
     * Gets or sets the primary image aspect ratio, after image enhancements.
     * @type {number}
     * @memberof BaseItemDto
     */
    PrimaryImageAspectRatio?: number | null;
    /**
     * Gets or sets the artists.
     * @type {Array<string>}
     * @memberof BaseItemDto
     */
    Artists?: Array<string> | null;
    /**
     * Gets or sets the artist items.
     * @type {Array<NameGuidPair>}
     * @memberof BaseItemDto
     */
    ArtistItems?: Array<NameGuidPair> | null;
    /**
     * Gets or sets the album.
     * @type {string}
     * @memberof BaseItemDto
     */
    Album?: string | null;
    /**
     * Gets or sets the type of the collection.
     * @type {string}
     * @memberof BaseItemDto
     */
    CollectionType?: string | null;
    /**
     * Gets or sets the display order.
     * @type {string}
     * @memberof BaseItemDto
     */
    DisplayOrder?: string | null;
    /**
     * Gets or sets the album id.
     * @type {string}
     * @memberof BaseItemDto
     */
    AlbumId?: string;
    /**
     * Gets or sets the album image tag.
     * @type {string}
     * @memberof BaseItemDto
     */
    AlbumPrimaryImageTag?: string | null;
    /**
     * Gets or sets the series primary image tag.
     * @type {string}
     * @memberof BaseItemDto
     */
    SeriesPrimaryImageTag?: string | null;
    /**
     * Gets or sets the album artist.
     * @type {string}
     * @memberof BaseItemDto
     */
    AlbumArtist?: string | null;
    /**
     * Gets or sets the album artists.
     * @type {Array<NameGuidPair>}
     * @memberof BaseItemDto
     */
    AlbumArtists?: Array<NameGuidPair> | null;
    /**
     * Gets or sets the name of the season.
     * @type {string}
     * @memberof BaseItemDto
     */
    SeasonName?: string | null;
    /**
     * Gets or sets the media streams.
     * @type {Array<MediaStream>}
     * @memberof BaseItemDto
     */
    MediaStreams?: Array<MediaStream> | null;
    /**
     * 
     * @type {VideoType}
     * @memberof BaseItemDto
     */
    VideoType?: VideoType;
    /**
     * Gets or sets the part count.
     * @type {number}
     * @memberof BaseItemDto
     */
    PartCount?: number | null;
    /**
     * 
     * @type {number}
     * @memberof BaseItemDto
     */
    MediaSourceCount?: number | null;
    /**
     * 
     * @type {BaseItemDtoImageTags}
     * @memberof BaseItemDto
     */
    ImageTags?: BaseItemDtoImageTags | null;
    /**
     * Gets or sets the backdrop image tags.
     * @type {Array<string>}
     * @memberof BaseItemDto
     */
    BackdropImageTags?: Array<string> | null;
    /**
     * Gets or sets the screenshot image tags.
     * @type {Array<string>}
     * @memberof BaseItemDto
     */
    ScreenshotImageTags?: Array<string> | null;
    /**
     * Gets or sets the parent logo image tag.
     * @type {string}
     * @memberof BaseItemDto
     */
    ParentLogoImageTag?: string | null;
    /**
     * If the item does not have a art, this will hold the Id of the Parent that has one.
     * @type {string}
     * @memberof BaseItemDto
     */
    ParentArtItemId?: string | null;
    /**
     * Gets or sets the parent art image tag.
     * @type {string}
     * @memberof BaseItemDto
     */
    ParentArtImageTag?: string | null;
    /**
     * Gets or sets the series thumb image tag.
     * @type {string}
     * @memberof BaseItemDto
     */
    SeriesThumbImageTag?: string | null;
    /**
     * 
     * @type {BaseItemDtoImageBlurHashes}
     * @memberof BaseItemDto
     */
    ImageBlurHashes?: BaseItemDtoImageBlurHashes | null;
    /**
     * Gets or sets the series studio.
     * @type {string}
     * @memberof BaseItemDto
     */
    SeriesStudio?: string | null;
    /**
     * Gets or sets the parent thumb item id.
     * @type {string}
     * @memberof BaseItemDto
     */
    ParentThumbItemId?: string | null;
    /**
     * Gets or sets the parent thumb image tag.
     * @type {string}
     * @memberof BaseItemDto
     */
    ParentThumbImageTag?: string | null;
    /**
     * Gets or sets the parent primary image item identifier.
     * @type {string}
     * @memberof BaseItemDto
     */
    ParentPrimaryImageItemId?: string | null;
    /**
     * Gets or sets the parent primary image tag.
     * @type {string}
     * @memberof BaseItemDto
     */
    ParentPrimaryImageTag?: string | null;
    /**
     * Gets or sets the chapters.
     * @type {Array<ChapterInfo>}
     * @memberof BaseItemDto
     */
    Chapters?: Array<ChapterInfo> | null;
    /**
     * 
     * @type {LocationType}
     * @memberof BaseItemDto
     */
    LocationType?: LocationType;
    /**
     * 
     * @type {IsoType}
     * @memberof BaseItemDto
     */
    IsoType?: IsoType;
    /**
     * Gets or sets the type of the media.
     * @type {string}
     * @memberof BaseItemDto
     */
    MediaType?: string | null;
    /**
     * Gets or sets the end date.
     * @type {string}
     * @memberof BaseItemDto
     */
    EndDate?: string | null;
    /**
     * Gets or sets the locked fields.
     * @type {Array<MetadataField>}
     * @memberof BaseItemDto
     */
    LockedFields?: Array<MetadataField> | null;
    /**
     * Gets or sets the trailer count.
     * @type {number}
     * @memberof BaseItemDto
     */
    TrailerCount?: number | null;
    /**
     * Gets or sets the movie count.
     * @type {number}
     * @memberof BaseItemDto
     */
    MovieCount?: number | null;
    /**
     * Gets or sets the series count.
     * @type {number}
     * @memberof BaseItemDto
     */
    SeriesCount?: number | null;
    /**
     * 
     * @type {number}
     * @memberof BaseItemDto
     */
    ProgramCount?: number | null;
    /**
     * Gets or sets the episode count.
     * @type {number}
     * @memberof BaseItemDto
     */
    EpisodeCount?: number | null;
    /**
     * Gets or sets the song count.
     * @type {number}
     * @memberof BaseItemDto
     */
    SongCount?: number | null;
    /**
     * Gets or sets the album count.
     * @type {number}
     * @memberof BaseItemDto
     */
    AlbumCount?: number | null;
    /**
     * 
     * @type {number}
     * @memberof BaseItemDto
     */
    ArtistCount?: number | null;
    /**
     * Gets or sets the music video count.
     * @type {number}
     * @memberof BaseItemDto
     */
    MusicVideoCount?: number | null;
    /**
     * Gets or sets a value indicating whether [enable internet providers].
     * @type {boolean}
     * @memberof BaseItemDto
     */
    LockData?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof BaseItemDto
     */
    Width?: number | null;
    /**
     * 
     * @type {number}
     * @memberof BaseItemDto
     */
    Height?: number | null;
    /**
     * 
     * @type {string}
     * @memberof BaseItemDto
     */
    CameraMake?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BaseItemDto
     */
    CameraModel?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BaseItemDto
     */
    Software?: string | null;
    /**
     * 
     * @type {number}
     * @memberof BaseItemDto
     */
    ExposureTime?: number | null;
    /**
     * 
     * @type {number}
     * @memberof BaseItemDto
     */
    FocalLength?: number | null;
    /**
     * 
     * @type {ImageOrientation}
     * @memberof BaseItemDto
     */
    ImageOrientation?: ImageOrientation;
    /**
     * 
     * @type {number}
     * @memberof BaseItemDto
     */
    Aperture?: number | null;
    /**
     * 
     * @type {number}
     * @memberof BaseItemDto
     */
    ShutterSpeed?: number | null;
    /**
     * 
     * @type {number}
     * @memberof BaseItemDto
     */
    Latitude?: number | null;
    /**
     * 
     * @type {number}
     * @memberof BaseItemDto
     */
    Longitude?: number | null;
    /**
     * 
     * @type {number}
     * @memberof BaseItemDto
     */
    Altitude?: number | null;
    /**
     * 
     * @type {number}
     * @memberof BaseItemDto
     */
    IsoSpeedRating?: number | null;
    /**
     * Gets or sets the series timer identifier.
     * @type {string}
     * @memberof BaseItemDto
     */
    SeriesTimerId?: string | null;
    /**
     * Gets or sets the program identifier.
     * @type {string}
     * @memberof BaseItemDto
     */
    ProgramId?: string | null;
    /**
     * Gets or sets the channel primary image tag.
     * @type {string}
     * @memberof BaseItemDto
     */
    ChannelPrimaryImageTag?: string | null;
    /**
     * The start date of the recording, in UTC.
     * @type {string}
     * @memberof BaseItemDto
     */
    StartDate?: string | null;
    /**
     * Gets or sets the completion percentage.
     * @type {number}
     * @memberof BaseItemDto
     */
    CompletionPercentage?: number | null;
    /**
     * Gets or sets a value indicating whether this instance is repeat.
     * @type {boolean}
     * @memberof BaseItemDto
     */
    IsRepeat?: boolean | null;
    /**
     * Gets or sets the episode title.
     * @type {string}
     * @memberof BaseItemDto
     */
    EpisodeTitle?: string | null;
    /**
     * 
     * @type {ChannelType}
     * @memberof BaseItemDto
     */
    ChannelType?: ChannelType;
    /**
     * 
     * @type {ProgramAudio}
     * @memberof BaseItemDto
     */
    Audio?: ProgramAudio;
    /**
     * Gets or sets a value indicating whether this instance is movie.
     * @type {boolean}
     * @memberof BaseItemDto
     */
    IsMovie?: boolean | null;
    /**
     * Gets or sets a value indicating whether this instance is sports.
     * @type {boolean}
     * @memberof BaseItemDto
     */
    IsSports?: boolean | null;
    /**
     * Gets or sets a value indicating whether this instance is series.
     * @type {boolean}
     * @memberof BaseItemDto
     */
    IsSeries?: boolean | null;
    /**
     * Gets or sets a value indicating whether this instance is live.
     * @type {boolean}
     * @memberof BaseItemDto
     */
    IsLive?: boolean | null;
    /**
     * Gets or sets a value indicating whether this instance is news.
     * @type {boolean}
     * @memberof BaseItemDto
     */
    IsNews?: boolean | null;
    /**
     * Gets or sets a value indicating whether this instance is kids.
     * @type {boolean}
     * @memberof BaseItemDto
     */
    IsKids?: boolean | null;
    /**
     * Gets or sets a value indicating whether this instance is premiere.
     * @type {boolean}
     * @memberof BaseItemDto
     */
    IsPremiere?: boolean | null;
    /**
     * Gets or sets the timer identifier.
     * @type {string}
     * @memberof BaseItemDto
     */
    TimerId?: string | null;
    /**
     * 
     * @type {BaseItemDto}
     * @memberof BaseItemDto
     */
    CurrentProgram?: BaseItemDto;
}


